import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { MessageApp } from "../models/gestione/message-app";

@Injectable()
export class MessageAppService {

  messages: Observable<MessageApp> = new ReplaySubject<MessageApp>(1);

  reportMessage(msg: MessageApp): void {
    (this.messages as Subject<MessageApp>).next(msg);
  }
}
