import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api/public_api';
import { SortEvent } from 'primeng/api/sortevent';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataSearch } from 'src/app/models/data-search';
import { ToastMessageData } from 'src/app/models/message';
import { IEntity } from '../../../models/entity';
import { CmdEvent, CmdField, CmdFieldKey, CmdFieldMode, CmdFieldTarget, EntityManager, FormField, FormFieldTypeHtml } from '../../../models/entity-config';
import { AppManagerService, SESSION_STORAGE_NAV } from '../../../services/app-manager.service';
import { EntityRefresh, IEntityService } from '../../../services/entity.service';
import { Resources, TranslateService } from '../../../services/translate.service';
import { compare, dataBind, isNullOrUndefined } from "../../../utils/util";


@Component({
  selector: 'entity-list',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.scss']
})
export class EntityListComponent implements OnInit, OnDestroy {
  cmdFieldTarget = CmdFieldTarget;
  cmdFieldMode = CmdFieldMode;
  cmdFieldKey = CmdFieldKey;

  @Input() entityManager: EntityManager;
  @Input() dataSearch: any;

  @Output() addEvent = new EventEmitter<CmdEvent>();
  @Output() editEvent = new EventEmitter<CmdEvent>();
  @Output() selectEvent = new EventEmitter<CmdEvent>();
  @Output() deleteEvent = new EventEmitter<CmdEvent>();
  @Output() printEvent = new EventEmitter<CmdEvent>();
  @Output() actionEvent = new EventEmitter<CmdEvent>();

  entityRefresh$: Observable<EntityRefresh>;
  entities: IEntity[] = [];
  entitiesSelected: IEntity[] = [];
  // searchEntity: any;
  textSelected: string;
  listFields: FormField[];
  entityInfo: any;
  actionMenuItems: MenuItem[] = [];
  entityModal: any;
  baseDataSearch: DataSearch;

  paginatorFirst: number;
  paginatorRows: number;
  paginatorRowsTot: number;

  textHeader: string;
  formFieldTypeHtml = FormFieldTypeHtml;

  translateItems: Resources;
  translatedItems$: Observable<Resources>;

  @ViewChild('dataTable') dataTable: Table;

  private fieldLoad: CmdField = { key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader };
  private subscription: Subscription;
  private entityService: IEntityService<IEntity>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appManagerService: AppManagerService,
    private translateService: TranslateService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.listFields = this.entityManager.getListFields().filter(field => field.typeHtml != FormFieldTypeHtml.hidden);
    this.textHeader = this.entityManager.getTextComponent("listHeader");
    this.paginatorFirst = this.entityManager.paginatorFirst ?? this.paginatorFirst;
    this.paginatorRows = this.entityManager.paginatorRows ?? this.paginatorRows;
    if (this.dataSearch) {
      this.entityManager.setEntitySearch(this.dataSearch);
    }

    this.baseDataSearch = this.entityManager.getDataSearch();
    this.entityInfo = this.entityManager.getEntity();
    this.entityService = this.entityManager.getEntityService();
    this.entityRefresh$ = this.entityService.entityRefresh$;

    // translations
    this.translatedItems$ = this.translateService.translatedItems$(this.entityManager.translateSuffixs)
      .pipe(
        tap(items => {
          this.translateItems = items;
        })
      );

    this.subscription.add(this.entityService.entityEvent$.subscribe(
      entityEvent => {
        if (!entityEvent) return;
        this.showToastMessage(entityEvent.event);

        this.entities = this.entityManager.convertDateFieldToLocalDateArray(entityEvent.entities);
        this.paginatorRowsTot = entityEvent.numRowsTot;

        if (this.actionEvent.observers.length > 0) {
          this.actionEvent.emit({ field: entityEvent.event, data: this.entities });
        }
      }
      // ,(error: ResultMessage) => {
      //   if (error) {
      //     const messageData = buildFromResultMessage(error, MessageLevel.Error);
      //     this.appManagerService.showMessage(messageData);
      //   }
      // }
    ));

    this.loadData(this.fieldLoad, this.entityManager.advancedSearch);
  }

  /**
   * Table pagination event
   * For us this method is rilevant only when lazy load is off
   *
   * @param event
   */
  onPage(event) {
    if (this.entityManager.loadLazy)
      return;

    this.paginatorFirst = event.first;
    this.paginatorRows = event.rows;
    this.entityManager.setPaginator(event.first, event.rows);
  }

  /**
   * Sort event handler
   * This event regards sorting interactions when lazy load is off
   *
   * @param event
   */
  sortFunction(event: SortEvent) {
    event.data.sort((data1, data2) => {
      return compare(data1[event.field], data2[event.field], event.order);
    });
  }

  /**
   * Table event in a lazy load context
   * This event is fired when paginator or sort event occurs
   *
   * @param event
   */
  onLazyLoad(event: TableLazyLoadEvent) {
    this.paginatorFirst = event.first;
    this.paginatorRows = event.rows;
    this.entityManager.setPaginator(event.first, event.rows);
    this.entityManager.setSort(event.sortField, event.sortOrder);
    if (this.dataTable?.initialized) {
      this.loadData(this.fieldLoad, this.entityManager.advancedSearch);
    }
  }

  onRowSelect(event) {
    if (this.actionEvent.observers.length > 0) {
      this.actionEvent.emit({ field: undefined, data: this.entitiesSelected });
    }
  }

  onRowUnselect(event) {
    if (this.actionEvent.observers.length > 0) {
      this.actionEvent.emit({ field: undefined, data: this.entitiesSelected });
    }
  }

  onAllRowSelectUnselect(event) {
    if (this.actionEvent.observers.length > 0) {
      this.actionEvent.emit({ field: undefined, data: this.entitiesSelected });
    }
  }

  onTbl(field: CmdField, entity?: IEntity): void {
    if (this.entityManager.onCmd({ field: field, data: entity })) return;

    // if (field.hEvent) {
    //   field.hEvent();
    // }

    switch (field.key) {
      case CmdFieldKey.navigateGuid:
        if (entity['guid']) {
          this.router.navigate([entity['guid'], field.entityManagerInfo.url], { relativeTo: this.activatedRoute });
        }
        else {
          this.router.navigate([field.entityManagerInfo.url], { relativeTo: this.activatedRoute });
        }
        break;
      case CmdFieldKey.navigate:
        if (entity.id) {
          this.router.navigate([entity.id, field.entityManagerInfo.url], { relativeTo: this.activatedRoute });
        }
        else {
          this.router.navigate([field.entityManagerInfo.url], { relativeTo: this.activatedRoute });
        }
        break;

      case CmdFieldKey.modalListEditable:
      case CmdFieldKey.modalList:
        this.modalEMShow(field, entity);
        break;

      case CmdFieldKey.select:
        if (this.selectEvent.observers.length > 0) {
          this.selectEvent.emit({ field: field, data: entity });
        }
        break;

      case CmdFieldKey.edit:
        this.entitiesSelected = [entity];
        if (this.editEvent.observers.length > 0) {
          this.editEvent.emit({ field: field, data: entity });
        }
        break;

      case CmdFieldKey.print:
        this.entitiesSelected = [entity];
        if (this.printEvent.observers.length > 0) {
          this.printEvent.emit({ field: field, data: entity });
        }
        else {
          this.print(field);
        }
        break;
    }
  }

  onCmd(field: CmdField): void {
    if (this.entityManager.onCmd({ field: field, data: this.entitiesSelected })) return;

    // if (field.hEvent) {
    //   field.hEvent();
    // }

    switch (field.key) {
      case CmdFieldKey.add:
        if (this.addEvent.observers.length > 0) {
          this.addEvent.emit({ field: field, data: this.entityManager.getEntity() });
          // this.addEvent.emit({field: field, data: this.data});
        }
        break;

      case CmdFieldKey.select:
        if (this.selectEvent.observers.length > 0) {
          this.selectEvent.emit({ field: field, data: this.entitiesSelected });
        }
        break;

      case CmdFieldKey.delete:
        if (this.deleteEvent.observers.length > 0) {
          this.deleteEvent.emit({ field: field, data: this.entitiesSelected });
        }
        else {
          this.delete(field);
        }
        break;

      case CmdFieldKey.print:
        if (this.printEvent.observers.length > 0) {
          this.printEvent.emit({ field: field, data: this.entitiesSelected });
        }
        else {
          this.print(field);
        }
        break;
    }
  }

  modalEMShow(field: CmdField, entity: IEntity) {
    const data = dataBind(entity, field.fieldsBind);
    const entityManager: EntityManager = this.appManagerService.instantiateEntityManager(field.entityManagerInfo);
    entityManager.setEntity(data);

    this.entityModal = {}; // {...entity};
    this.entityModal['entityManager'] = entityManager;
    this.entityModal['field'] = field;
    this.entityModal['header'] = `${this.entityManager.name.toLowerCase()}.${entityManager.id}`;
    this.entityModal['visible'] = true;
    this.entityModal['dataSearch'] = data;
  }

  modalEMHide() {
    let entityManager: EntityManager = this.entityModal['entityManager'];
    entityManager.dispose();
    this.entityModal = undefined;

    this.loadData(this.fieldLoad, this.entityManager.advancedSearch);
  }

  public search(data?: any, advancedSearch: boolean = false, paginatorFirst?: number, field?: CmdField) {
    this.paginatorFirst = isNullOrUndefined(paginatorFirst) ? 0 : paginatorFirst;
    this.entityManager.setPaginator(this.paginatorFirst, this.paginatorRows);
    // this.searchEntity = isNullOrUndefined(data) ? this.searchEntity : data;
    if (data) {
      this.entityManager.setEntitySearch(data);
    }
    this.loadData(field ?? this.fieldLoad, advancedSearch);
  }

  public getEntitiesSelected() {
    return this.entitiesSelected;
  }

  public clearEntitiesSelected() {
    this.entitiesSelected = [];
    if (this.actionEvent.observers.length > 0) {
      this.actionEvent.emit({ field: undefined, data: this.entitiesSelected });
    }
  }

  public getEntities() {
    return this.entities;
  }

  private loadData(field: CmdField, advancedSearch: boolean = false) {
    let dataSearch = this.entityManager.getDataSearch();
    if (isNullOrUndefined(dataSearch))
      return;

    // store filter data
    const data = this.entityManager.getFilterData();
    this.appManagerService.setStorageData(SESSION_STORAGE_NAV, this.entityManager.id, data);

    this.entityService.searchEntities(field, dataSearch);
  }

  private delete(field: CmdField) {
    if (this.entitiesSelected.length == 0) return;

    let ids = this.entitiesSelected.map(t => t.id);
    this.entityService.deleteEntities(field, ids);
  }

  private print(field: CmdField) {
    if (this.entitiesSelected.length == 0) return;

    let ids = this.entitiesSelected.map(t => t.id);
    this.subscription.add(this.entityService.printEntities(field, ids)
      .subscribe(
        (file: File) => {
          // this.fileSaverService.save(file, file.name);
        },
        error => {
          // this.messageDataService.open(
          //   this.modalMessageDataId, {
          //   mode: 'close', error: error, title: 'error_generic', classTitle: 'msg-error'
          // }
          // );
        }
      ));
  }

  setActionMenuItems(event, cmdFields: CmdField[]) {
    this.actionMenuItems = [];
    cmdFields.forEach(field => {
      this.actionMenuItems.push({
        label: field.label,
        title: field.title,
        icon: field.icon,
        command: () => this.onCmd(field),
        disabled: (this.entityManager.isCmdFieldDisabled(field, { entitiesSelCount: this.entitiesSelected.length })),
        styleClass: field.class,
      })
    });
    this.actionMenuItems;
  }

  showToastMessage(field: CmdField) {
    let message: string;
    switch (field.key) {
      case CmdFieldKey.delete:
        message = this.translateItems?.['generic.deletesuccessmsg'];
        break;
      case CmdFieldKey.save:
        message = this.translateItems?.['generic.savesuccessmsg'];
        break;
    }

    if (message) {
      this.appManagerService.showToastMessage(new ToastMessageData('success', message));
    }
  }
}
