import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, EntityManagerConfig, CmdField } from "../entity-config";
import { AppManagerRoutes } from "../../app-manager-routes";

export class AnaRazzeEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GenGruppoFciList, label: 'menu.GenGruppoFciList.label', title: 'menu.GenGruppoFciList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GenClasseList, label: 'menu.GenClasseList.label', title: 'menu.GenClasseList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GenCartellinoList, label: 'menu.GenCartellinoList.label', title: 'menu.GenCartellinoList.title'
      }
    ];
  }
}
