import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { DataService } from 'src/app/services/data.service';
import { dateTimeFormat } from 'src/app/utils/util';
import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from 'src/app/models/data-search';
import { Router } from '@angular/router';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { tap, map } from 'rxjs/operators';
import { CalCaleManiAnomAll } from 'src/app/models/calendario/cal-cale-mani-anom-all';
import { MenuItem } from 'primeng/api/menuitem';

@Component({
  selector: 'app-anomalie-widget',
  templateUrl: './anomalie-widget.component.html',
  styleUrls: ['./anomalie-widget.component.scss']
})

export class AnomalieWidgetComponent implements OnInit {
  dateTimeFormat: string = dateTimeFormat;
  T$: Observable<Resources>;
  data$: Observable<CalCaleManiAnomAll[]>;
  anomaliesQty: number = 3;
  unmanagedAnomaliesCount: number;
  actionMenuItems: MenuItem[];
  resourceList: Resources;

  constructor(
    private router: Router,
    private dataService: DataService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    // Etichette
    this.T$ = this.translateService.translatedItems$(['generic.', 'anomaliewidget.']).pipe(
      tap(data => this.resourceList = data)
    );

    const ds = new DataSearch(
      [new DataSearchValue(['1'], ['anomaliaStatoId'], ComparisonOperator.Equals)],
      [new DataSortValue('anomaliaData', SortMode.Desc)],
      1,
      this.anomaliesQty
    );

    this.data$ = this.dataService.searchElements<CalCaleManiAnomAll>(`${this.dataService.configSettings.restCalendarioUrl}/calcalemanianomall`, ds).pipe(
      tap(data => this.unmanagedAnomaliesCount = data.numRowsTot),
      map(data => data.entities)
    )
  }

  navigateToAnomalies(tipo: string): void {
    switch (tipo) {
      case 'KM':
        this.router.navigate([AppManagerRoutes.Calendario.url, AppManagerRoutes.CalCaleManiAnomList.url]);
        break;
      case 'VA':
        this.router.navigate([AppManagerRoutes.Calendario.url, AppManagerRoutes.CalCaleManiAnomVarieList.url]);
        break;
    }
  }

  navigateToAnomaly(maniId: number, tipo: string): void {
    switch (tipo) {
      case 'KM':
        this.router.navigate([AppManagerRoutes.Calendario.url, AppManagerRoutes.CalCaleManiAnomList.url, maniId.toString(), AppManagerRoutes.CalCaleManiEdit.url]);
        break;
      case 'VA':
        this.router.navigate([AppManagerRoutes.Calendario.url, AppManagerRoutes.CalCaleManiAnomVarieList.url, maniId.toString(), AppManagerRoutes.CalCaleManiSettings.url]);
        break;
    }
  }

  setActionMenuItems(): void {
    this.actionMenuItems = [{
      label: this.translateService.translate(this.resourceList, 'anomaliewidget.vedianomaliekm'), title: this.translateService.translate(this.resourceList, 'anomaliewidget.vedianomaliekm'), command: () => { this.navigateToAnomalies('KM') }
    }, {
      label: this.translateService.translate(this.resourceList, 'anomaliewidget.vedianomalievarie'), title: this.translateService.translate(this.resourceList, 'anomaliewidget.vedianomalievarie'), command: () => { this.navigateToAnomalies('VA') }
    }];
  }
}
