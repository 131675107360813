import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from "../../data-search";
import { Entity } from "../../entity";
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, Icons, CmdField, CmdFieldStyle, FormFieldStyle } from "../../entity-config";
import { AppManagerRoutes } from "../../../app-manager-routes";
import { Validators } from "@angular/forms";


// CalDefVincolo
export class CalDefVincolo extends Entity {
  calTipoCalId: number;
  tipoManiId: number;
  applicazione: string;
  cod: string;
  tipo: string;
  valoreDa: string;
  valoreA: string;
  unitaMisuraId: number;
  tabella: string;

  des: string;
  calTipoCalCod: string;
  calTipoCalDes: string;
  unitaMisuraDes: string;
  tipoManiDes: string
  note: string;
  // calTipoCal: CalTipoCal;
}

export class CalDefVincoloEMC extends EntityManagerConfig {
  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = {
      windowListHeader: `${this.name}.windowlistheader`,
      windowInsertHeader: `${this.name}.windowinsertheader`,
      windowEditHeader: `${this.name}.windoweditheader`
    };

    this.dataSortValue = [new DataSortValue('des', SortMode.Asc)];

    const ds = new DataSearch();
    //ds.dataSearchValues = [new DataSearchValue([true], ['visible'], ComparisonOperator.Equals)];
    ds.dataSortValues = [new DataSortValue('des', SortMode.Asc)];

    this.attributes['caltipocal'] = this.getEntityService('caltipocal').searchEntitiesAsync(this.fLoad, ds);

    const dsUm = new DataSearch();
    dsUm.dataSortValues = [new DataSortValue('des', SortMode.Asc)];

    this.attributes['genunitamisura'] = this.getEntityService('genunitamisura').searchEntitiesAsync(this.fLoad, dsUm);

    this.attributes['caltipomani'] = this.getEntityService('caltipomani').searchEntitiesAsync(this.fLoad, ds);

    this.attributes['tabella'] = [{ key: 'CalCale', label: 'CalCale' }, { key: 'CalCaleMani', label: 'CalCaleMani' }];

    this.attributes['applicazione'] = [{ key: 'A', label: 'Anomalia' }, { key: 'P', label: 'Profilo' }, { key: 'N', label: 'Nessuno' }];
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // List
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        entityManagerInfo: AppManagerRoutes.CalDefVincoloNew,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      // {
      //   key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
      //   title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary,
      // },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        entityManagerInfo: AppManagerRoutes.CalDefVincoloEdit,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.modalListEditable, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        entityManagerInfo: AppManagerRoutes.CalDefVincoloLangList, title: 'generic.translations', icon: Icons.globe, class: CmdFieldStyle.btnSecondary,
        fieldsBind: { id: 'defVincoloId' }, fieldsBindSearch: { id: 'defVincoloId' }
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: `${this.name}.CalDefVincoloProfiloList`, icon: Icons.userCheck, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.CalDefVincoloProfiloList
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger,
      },
      // {
      //   key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
      //   title: 'generic.cancel', icon: Icons.back,
      //   class: CmdFieldStyle.btnSecondary,
      // },
      // {
      //   key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
      //   title: 'generic.save', icon: Icons.save,
      //   class: CmdFieldStyle.btnPrimary,
      // }

      // Detail
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        label: 'generic.back', title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        label: 'generic.save', title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'calTipoCalId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'unitaMisuraId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'cod', type: FormFieldType.string, label: `${this.name}.cod`, sortable: true },
      { key: 'tipo', type: FormFieldType.string, label: `${this.name}.tipo`, sortable: true },
      { key: 'applicazione', type: FormFieldType.string, label: `${this.name}.applicazione`, sortable: true },
      { key: 'tabella', type: FormFieldType.string, label: `${this.name}.tabella`, sortable: true },
      { key: 'calTipoCalDes', type: FormFieldType.string, label: `${this.name}.caltipocal`, sortable: true },
      { key: 'tipoManiDes', type: FormFieldType.string, label: `${this.name}.tipomani`, sortable: true },
      { key: 'unitaMisuraDes', type: FormFieldType.string, label: `${this.name}.unitamisura`, sortable: true },
      { key: 'valoreDa', type: FormFieldType.string, label: `${this.name}.valoreda`, sortable: true },
      { key: 'valoreA', type: FormFieldType.string, label: `${this.name}.valorea`, sortable: true },
      { key: 'des', type: FormFieldType.string, label: `${this.name}.des`, sortable: true }
    ];

    this.detailFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.cod`, placeholder: `${this.name}.cod`,
        validators: [Validators.required], class: FormFieldStyle.default
      },
      {
        key: 'tipo', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.tipo`, placeholder: `${this.name}.tipo`,
        options: {
          data: [{ key: 'DINAMICO', label: 'Dinamico' }, { key: 'STATICO', label: 'Statico' }], dataKey: 'key', dataLabel: 'label'
        },
        validators: [Validators.required], class: FormFieldStyle.default
      },
      {
        key: 'applicazione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.applicazione`, placeholder: `${this.name}.applicazione`,
        options: {
          data: this.attributes['applicazione'], dataKey: 'key', dataLabel: 'label'
        },
        validators: [Validators.required], class: FormFieldStyle.default
      },
      {
        key: 'tabella', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.tabella`, placeholder: `${this.name}.tabella`,
        options: {
          data: this.attributes['tabella'], dataKey: 'key', dataLabel: 'label'
        }, class: FormFieldStyle.default
      },
      {
        key: 'calTipoCalId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.calTipoCal`, placeholder: `${this.name}.calTipoCal`,
        options: {
          data: this.attributes['caltipocal'], dataKey: 'id', dataLabel: 'des'
        },
        validators: [Validators.required], class: FormFieldStyle.default
      },
      {
        key: 'tipoManiId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.tipomani`, placeholder: `${this.name}.tipomani`,
        options: {
          data: this.attributes['caltipomani'], dataKey: 'id', dataLabel: 'des'
        },
        validators: [Validators.required], class: FormFieldStyle.default
      },
      {
        key: 'unitaMisuraId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.unitamisura`, placeholder: `${this.name}.unitamisura`,
        options: {
          data: this.attributes['genunitamisura'], dataKey: 'id', dataLabel: 'des'
        },
        validators: [Validators.required], class: FormFieldStyle.default
      },
      {
        key: 'valoreDa', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.valoreda`, placeholder: `${this.name}.valoreda`
        , class: FormFieldStyle.default
      },
      {
        key: 'valoreA', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.valorea`, placeholder: `${this.name}.valorea`
        , class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.des`, placeholder: `${this.name}.des`,
        validators: [Validators.required], class: FormFieldStyle.full
      },
      {
        key: 'note', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.textarea,
        label: `${this.name}.note`, placeholder: `${this.name}.note`,
        class: FormFieldStyle.full
      },
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.sImpleSearchPlaceholder`,
        options: { searchFields: 'cod,tipo,tabella,calTipoCalDes,tipoManiDes,unitaMisuraDes,valoreDa,valoreA,des, note', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.cod`, options: { searchFields: 'cod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'tipo', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.tipo`, options: { searchFields: 'tipo', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'applicazione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.applicazione`,
        options: {
          data: this.attributes['applicazione'], dataKey: 'key', dataLabel: 'label', searchFields: 'applicazione', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'tabella', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.tabella`,
        options: {
          data: this.attributes['tabella'], dataKey: 'key', dataLabel: 'label', searchFields: 'tabella', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'calTipoCalId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.caltipocal`, options: {
          data: this.attributes['caltipocal'], dataKey: 'id',
          dataLabel: 'des', searchFields: 'calTipoCalId', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'tipoManiId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.tipomani`, options: {
          data: this.attributes['caltipomani'], dataKey: 'id',
          dataLabel: 'des', searchFields: 'tipoManiId', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'unitaMisuraId', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.unitamisura`,
        options: {
          data: this.attributes['genunitamisura'], dataKey: 'id', dataLabel: 'des', searchFields: 'unitaMisuraId', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'valoreDa', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.valoreDa`, options: { searchFields: 'valoreDa', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'valoreA', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.valoreA`, options: { searchFields: 'valoreA', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`, options: { searchFields: 'des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }

}

export class CalDefVincoloModalEMC extends EntityManagerConfig {

  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = {};
    this.dataSortValue = [new DataSortValue('des', SortMode.Asc)];

    const ds = new DataSearch();
    ds.dataSearchValues = [new DataSearchValue([true], ['visible'], ComparisonOperator.Equals)];
    ds.dataSortValues = [new DataSortValue('des', SortMode.Asc)];

    this.attributes['caltipocal'] = this.getEntityService('caltipocal').searchEntitiesAsync(this.fLoad, ds);

    const dsUm = new DataSearch();
    dsUm.dataSortValues = [new DataSortValue('des', SortMode.Asc)];

    this.attributes['genunitamisura'] = this.getEntityService('genunitamisura').searchEntitiesAsync(this.fLoad, dsUm);

    this.attributes['caltipomani'] = this.getEntityService('caltipomani').searchEntitiesAsync(this.fLoad, ds);

    this.attributes['tabella'] = [{ key: 'CalCale', label: 'CalCale' }, { key: 'CalCaleMani', label: 'CalCaleMani' }];

    this.attributes['applicazione'] = [{ key: 'A', label: 'Anomalia' }, { key: 'P', label: 'Profilo' }, { key: 'N', label: 'Nessuno' }];
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // List
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.modalListEditable, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        entityManagerInfo: AppManagerRoutes.CalDefVincoloLangList, title: 'generic.translations', icon: Icons.globe, class: CmdFieldStyle.btnSecondary,
        fieldsBind: { id: 'defVincoloId' }, fieldsBindSearch: { id: 'defVincoloId' }
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger,
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save,
        class: CmdFieldStyle.btnPrimary,
      },
      {
        key: CmdFieldKey.select, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.select', icon: Icons.select, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'calTipoCalId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'unitaMisuraId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'cod', type: FormFieldType.string, label: `${this.name}.cod`, sortable: true },
      { key: 'tipo', type: FormFieldType.string, label: `${this.name}.tipo`, sortable: true },
      { key: 'unitaMisuraDes', type: FormFieldType.string, label: `${this.name}.unitamisura`, sortable: true },
      { key: 'tabella', type: FormFieldType.string, label: `${this.name}.tabella`, sortable: true },
      { key: 'calTipoCalDes', type: FormFieldType.string, label: `${this.name}.caltipocal`, sortable: true },
      { key: 'tipoManiDes', type: FormFieldType.string, label: `${this.name}.tipomani`, sortable: true },
      { key: 'applicazione', type: FormFieldType.string, label: `${this.name}.applicazione`, sortable: true },
      { key: 'valoreDa', type: FormFieldType.string, label: `${this.name}.valoreda`, sortable: true },
      { key: 'valoreA', type: FormFieldType.string, label: `${this.name}.valorea`, sortable: true },
      { key: 'des', type: FormFieldType.string, label: `${this.name}.des`, sortable: true },
    ];

    this.detailFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.cod`, placeholder: `${this.name}.cod`,
        validators: [Validators.required]
      },
      {
        key: 'tipo', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.tipo`, placeholder: `${this.name}.tipo`,
        options: {
          data: [{ key: 'DINAMICO', label: 'Dinamico' }, { key: 'STATICO', label: 'Statico' }], dataKey: 'key', dataLabel: 'label'
        },
        validators: [Validators.required]
      },
      {
        key: 'applicazione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.applicazione`, placeholder: `${this.name}.applicazione`,
        options: {
          data: this.attributes['applicazione'], dataKey: 'key', dataLabel: 'label'
        },
        validators: [Validators.required]
      },
      {
        key: 'tabella', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.tabella`, placeholder: `${this.name}.tabella`,
        options: {
          data: this.attributes['tabella'], dataKey: 'key', dataLabel: 'label'
        }
      },
      {
        key: 'calTipoCalId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.calTipoCal`, placeholder: `${this.name}.calTipoCal`,
        options: {
          data: this.attributes['caltipocal'], dataKey: 'id', dataLabel: 'des'
        },
        validators: [Validators.required]
      },
      {
        key: 'tipoManiId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.tipoMani`, placeholder: `${this.name}.tipoMani`,
        options: {
          data: this.attributes['caltipomani'], dataKey: 'id', dataLabel: 'des'
        },
        validators: [Validators.required]
      },
      {
        key: 'unitaMisuraId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.unitaMisura`, placeholder: `${this.name}.unitaMisura`,
        options: {
          data: this.attributes['genunitamisura'], dataKey: 'id', dataLabel: 'des'
        },
        validators: [Validators.required]
      },
      {
        key: 'valoreDa', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.valoreDa`, placeholder: `${this.name}.valoreDa`
      },
      {
        key: 'valoreA', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.valoreA`, placeholder: `${this.name}.valoreA`
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.des`, placeholder: `${this.name}.des`,
        validators: [Validators.required]
      }
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.sImpleSearchPlaceholder`,
        options: { searchFields: 'cod,tipo,tabella,calTipoCalDes,tipoManiDes,unitaMisuraDes,valoreDa,valoreA,des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.cod`, options: { searchFields: 'cod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'tipo', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.tipo`, options: { searchFields: 'tipo', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'applicazione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.applicazione`,
        options: {
          data: this.attributes['applicazione'], dataKey: 'key', dataLabel: 'label', searchFields: 'applicazione', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'tabella', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.tabella`,
        options: {
          data: this.attributes['tabella'], dataKey: 'key', dataLabel: 'label', searchFields: 'tabella', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'calTipoCalId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.caltipocal`, options: {
          data: this.attributes['caltipocal'], dataKey: 'id',
          dataLabel: 'des', searchFields: 'calTipoCalId', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'tipoManiId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.tipomani`, options: {
          data: this.attributes['caltipomani'], dataKey: 'id',
          dataLabel: 'des', searchFields: 'tipoManiId', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'unitaMisuraId', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.unitamisura`,
        options: {
          data: this.attributes['genunitamisura'], dataKey: 'id', dataLabel: 'des', searchFields: 'unitaMisuraId', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'valoreDa', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.valoreDa`, options: { searchFields: 'valoreDa', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'valoreA', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.valoreA`, options: { searchFields: 'valoreA', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`, options: { searchFields: 'des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }

}

// CalDefVincoloLang
export class CalDefVincoloLang extends Entity {
  defVincoloId: number;
  lang: string;
  des: string;
  note: string;
}

export class CalDefVincoloLangEMC extends EntityManagerConfig {
  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = {};
    this.dataSortValue = [new DataSortValue('des', SortMode.Asc)];

    const ds: DataSearch = new DataSearch();
    ds.dataSortValues = [new DataSortValue('id', SortMode.Asc)];

    this.attributes['genlang'] = this.getEntityService('genlang').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {
    this.cmdFields = [
      // Container

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
        // hEvent: () => {
        //   let field = this.getCmdField(CmdFieldTarget.listSearch, CmdFieldMode.btn, CmdFieldKey.toggle);
        //   this.toggleField(field, false, true, 'generic.searchAdvanced', 'generic.searchBasic')
        // }
      },

      // List
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger,
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save,
        class: CmdFieldStyle.btnPrimary,
      }
    ];

    this.listFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      {
        key: 'lang', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        label: `${this.name}.lang`, placeholder: `${this.name}.lang`
      },
      {
        key: 'des', type: FormFieldType.string,
        label: `${this.name}.des`, sortable: true
      },
      {
        key: 'note', type: FormFieldType.string,
        label: `${this.name}.note`, sortable: true
      }
    ];

    this.detailFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'defVincoloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden },
      {
        key: 'lang', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        label: `${this.name}.lang`, placeholder: `${this.name}.lang`,
        options: { data: this.attributes['genlang'], dataKey: 'lang', dataLabel: 'des' },
        validators: [Validators.required]
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.des`, placeholder: `${this.name}.des`,
        validators: [Validators.required]
      },
      {
        key: 'note', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.note`, placeholder: `${this.name}.note`
      }
    ];

    this.searchFields = [
      {
        key: 'defVincoloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'defVincoloId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.simpleSearchPlaceholder`, options: { searchFields: 'cod,des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'defVincoloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'defVincoloId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'lang', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.lang`, options: {
          data: this.attributes['genlang'], dataKey: 'lang', dataLabel: 'des', searchFields: 'lang', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.cod`, options: { searchFields: 'cod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`, options: { searchFields: 'des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }
}
