import { Entity } from "../entity";

export class GiudImpegnoEstero extends Entity {
  giudiceId: number;
  tipoManiId: number;
  nazioneId: number;
  localita?: string;
  dataInizio: Date;
  dataFine: Date;

  tipoManiCod: string;
  tipoManiDes: string;
  tipoCaliCod: string;
  nazioneCod: string;
  nazioneDes: string;
  nazioneLabel: string;
  giudiceNome: string;
  giudiceCognome: string;
  giudiceNomeCompleto: string;
}
