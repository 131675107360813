import { Entity } from "../../entity";

export class GiudGiudiceSuggestionDto extends Entity {
  baseId: number;
  nome: string;
  cognome: string;
  nomeCompleto: string;
  nazioneId: number;
  nazioneCod: string;
  nazioneDes: string;
  nazioneLabel: string;
  giudiceLabel: string;
};
