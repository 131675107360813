import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DataSearch } from '../models/data-search';
import { ExportListPars } from '../models/gestione/list-export-pars';

@Injectable({
  providedIn: 'root'
})
export class ListExportService {
  private _modalSubject: Subject<ExportListPars> = new Subject();

  constructor(private httpClient: HttpClient) { }

  getSubject() {
    return this._modalSubject.asObservable();
  }

  openModal(entityName: string, dataSearch: DataSearch, nameSpace?: string) {
    const pars = new ExportListPars();
    pars.open = true;
    pars.entityName = entityName;
    pars.dataSearch = dataSearch;
    pars.nameSpace = nameSpace;

    this._modalSubject.next(pars);
  }

  closeModal() {
    const pars = new ExportListPars();
    pars.open = false;
    pars.entityName = undefined;
    pars.dataSearch = undefined;
    pars.nameSpace = undefined;

    this._modalSubject.next(pars);
  }

  public exportFile(urlRoute: string, data: any) {
    return this.httpClient.request<any>("POST", urlRoute, { responseType: 'blob' as 'json', body: data });
  }

  public defaultFileName(): string {
    return `Export_${Date.now()}`;
  }
}
