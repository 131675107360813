<ng-container *ngIf="T$ | async as T">
  <div class="h-full flex flex-column">
    <div
      *ngIf="this.entityManager.id !== appManagerRoutes.GiudRichiestaDetail.id && this.entityManager.id !== appManagerRoutes.CalCaleManiSettings.id">
      <div class="flex flex-row justify-content-between">
        <div class="text-header">
          {{T | translateItem : (this.entityManager.id | i18nSelect : titleMap)}}
        </div>
        <div class="buttons-cnt">
          <app-button [outlined]="true" (onClick)="navigateToHelp()" [disabled]="elaboration"
            icon="fa-solid fa-circle-question" [tooltip]="T | translateItem: 'generic.help'" />
        </div>
      </div>

      <p-divider />
    </div>

    <app-giud-assistentato-grouped-list-search [elaboration]="elaboration" />

    <div class="flex-grow-1 overflow-y-hidden">
      <div class="h-full overflow-y-auto">
        <p-table [value]="data$ | async" [totalRecords]="paginatorRowsTot" [lazy]="true" [paginator]="true"
          [first]="paginatorFirst" [rows]="paginatorRows" [rowsPerPageOptions]="rowsPerPageOptions"
          [lazyLoadOnInit]="false" (onLazyLoad)="onLazyLoad($event)" (onPage)="onPage($event)"
          [showCurrentPageReport]="true"
          [currentPageReportTemplate]="T | translateItem: paginatorRowsTot ? 'generic.listReport' : 'generic.listEmpty'"
          sortMode="multiple" [multiSortMeta]="multiSortMeta$ | async" responsiveLayout="stack" breakpoint="960px"
          styleClass="p-datatable-striped p-datatable-md" paginatorPosition="bottom" [loading]="elaboration"
          loadingIcon="fa-solid fa-spinner fa-spin" dataKey="dataKey">

          <ng-template pTemplate="header">
            <tr>
              <th class="white-space-nowrap" style="width: 3rem"></th>

              <th class="white-space-nowrap" pSortableColumn="caleManiLabel">
                {{T | translateItem: 'giudassistentato.caleMani'}}
                <p-sortIcon field="caleManiLabel" />
              </th>

              <th class="white-space-nowrap" pSortableColumn="caleManiDataInizio">
                {{T | translateItem: 'giudassistentato.caleManiDataInizio'}}
                <p-sortIcon field="caleManiDataInizio" />
              </th>

              <th class="white-space-nowrap" pSortableColumn="caleManiDataFine">
                {{T | translateItem: 'giudassistentato.caleManiDataFine'}}
                <p-sortIcon field="caleManiDataFine" />
              </th>

              <th class="white-space-nowrap" pSortableColumn="giudiceNome">
                {{T | translateItem: 'giudassistentato.giudiceNome'}}
                <p-sortIcon field="giudiceNome" />
              </th>

              <th class="white-space-nowrap" pSortableColumn="giudiceCognome">
                {{T | translateItem: 'giudassistentato.giudiceCognome'}}
                <p-sortIcon field="giudiceCognome" />
              </th>

              <th class="white-space-nowrap">
                {{T | translateItem: 'giudassistentato.assistentatiGiudiceManiCount'}}
              </th>

              <!-- <th class="white-space-nowrap"></th> -->

              <!-- <th class="white-space-nowrap buttons-column"></th> -->
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-ri="rowIndex" let-expanded>
            <tr [tableRowHilight]="rowData.dataKey" [(currentDataKey)]="hilightedRowDataKey">
              <td>
                <button type="button" pButton pRipple [pRowToggler]="rowData"
                  class="p-button-text p-button-rounded p-button-plain"
                  [icon]="expanded ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-right'"
                  [disabled]="!rowData?.assistentati?.length"></button>
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.calemani'}}
                </span>
                {{rowData.caleManiLabel}}
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.caleManiDataInizio'}}
                </span>
                {{rowData.caleManiDataInizio | date: dateFormat}}
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.caleManiDataFine'}}
                </span>
                {{rowData.caleManiDataFine | date: dateFormat}}
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.giudiceNome'}}
                </span>
                {{rowData.giudiceNome}}
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.giudiceCognome'}}
                </span>
                {{rowData.giudiceCognome}}
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.assistentatiGiudiceManiCount'}}
                </span>
                {{rowData.assistentatiGiudiceManiCount}}
              </td>

              <!-- <td></td> -->

              <!-- <td class="buttons-column"></td> -->
            </tr>
          </ng-template>

          <ng-template pTemplate="rowexpansion" let-rowData>
            <tr>
              <td colspan="100%">
                <p-table [value]="rowData.assistentati" dataKey="id" [lazy]="true"
                  styleClass="p-datatable-striped p-datatable-md" responsiveLayout="stack" breakpoint="960px">
                  <ng-template pTemplate="header">
            <tr>
              <th class="white-space-nowrap" style="width: 5%">
                {{T | translateItem: 'giudassistentato.id'}}
              </th>

              <th class="white-space-nowrap" style="width: 10%">
                {{T | translateItem: 'giudassistentato.giudiceRichiedenteNome'}}
              </th>

              <th class="white-space-nowrap" style="width: 10%">
                {{T | translateItem: 'giudassistentato.giudiceRichiedenteCognome'}}
              </th>

              <!-- <th class="white-space-nowrap" style="width: 7.5%">
                {{T | translateItem: 'giudassistentato.dataRichiesta'}}
              </th> -->

              <!-- <ng-container *ngIf="rowData.tipoCalCod === tipoCalCodEnum.esposizioni">
                <th class="white-space-nowrap" style="width: 25%">
                  {{T | translateItem: 'giudassistentato.gruppoFci'}}
                </th>

                <th class="white-space-nowrap" style="width: 15%">
                  {{T | translateItem: 'giudassistentato.razza'}}
                </th>
              </ng-container> -->

              <!-- <ng-container *ngIf="rowData.tipoCalCod === tipoCalCodEnum.prove">
                <th class="white-space-nowrap" style="width: 20%">
                  {{T | translateItem: 'giudassistentato.tipoMani'}}
                </th>

                <th class="white-space-nowrap" style="width: 5%">
                  {{T | translateItem: 'giudassistentato.codProtipo'}}
                </th>

                <th class="white-space-nowrap" style="width: 15%">
                  {{T | translateItem: 'giudassistentato.sottoProva'}}
                </th>
              </ng-container> -->

              <th class="white-space-nowrap" style="width: 25%">
                {{T | translateItem: 'giudassistentato.richiestaDetailLabel'}}
              </th>

              <th *ngIf="rowData.tipoCalCod === tipoCalCodEnum.prove" class="white-space-nowrap"
                class="white-space-nowrap" style="width: 15%">
                {{T | translateItem: 'giudassistentato.sottoProva'}}
              </th>


              <th class="white-space-nowrap" style="width: 10%">
                {{T | translateItem: 'giudassistentato.data'}}
              </th>

              <th class="white-space-nowrap" style="width: 10%">
                {{T | translateItem: 'giudassistentato.esito'}}
              </th>

              <th class="white-space-nowrap" style="width: 10%">
                {{T | translateItem: 'giudassistentato.statoAssistentato'}}
              </th>

              <th style="width: 5%"></th>

              <th class="buttons-column white-space-nowrap"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowDataDetail>
            <tr>
              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.id'}}
                </span>
                {{rowDataDetail.id}}
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.giudiceRichiedenteNome'}}
                </span>
                {{rowDataDetail.giudiceRichiedenteNome}}
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.giudiceRichiedenteCognome'}}
                </span>
                {{rowDataDetail.giudiceRichiedenteCognome}}
              </td>

              <!-- <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.dataRichiesta'}}
                </span>
                {{rowDataDetail.dataRichiesta | date: dateFormat}}
              </td> -->

              <!-- <ng-container *ngIf="rowData.tipoCalCod === tipoCalCodEnum.esposizioni">
                <td>
                  <span class="p-column-title">
                    {{T | translateItem: 'giudassistentato.gruppoFci'}}
                  </span>
                  {{rowDataDetail.gruppoFciLabel}}
                </td>

                <td>
                  <span class="p-column-title">
                    {{T | translateItem: 'giudassistentato.razza'}}
                  </span>
                  {{rowDataDetail.razzaDes}}
                </td>
              </ng-container> -->

              <!-- <ng-container *ngIf="rowData.tipoCalCod === tipoCalCodEnum.prove">
                <td>
                  <span class="p-column-title">
                    {{T | translateItem: 'giudassistentato.tipoMani'}}
                  </span>
                  {{rowDataDetail.tipoManiDes}}
                </td>

                <td>
                  <span class="p-column-title">
                    {{T | translateItem: 'giudassistentato.codProtipo'}}
                  </span>
                  {{rowDataDetail.codProtipo}}
                </td>

                <td>
                  <span class="p-column-title">
                    {{T | translateItem: 'giudassistentato.sottoProva'}}
                  </span>
                  {{rowDataDetail.sottoProvaLabel}}
                </td>
              </ng-container> -->

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.richiestaDetailLabel'}}
                </span>
                {{rowDataDetail.richiestaDetailLabel}}
              </td>

              <td *ngIf="rowData.tipoCalCod === tipoCalCodEnum.prove">
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.sottoProva'}}
                </span>
                {{rowDataDetail.sottoProvaLabel}}
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.data'}}
                </span>
                {{rowDataDetail.data | date : dateFormat | noValuePlaceholder}}
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.esito'}}
                </span>
                <span class="white-space-nowrap"
                  [ngClass]="{'badge-success': rowDataDetail.esitoAssistentatoCod === esitoAssistentatoCod.Superato, 'badge-danger': rowDataDetail.esitoAssistentatoCod === esitoAssistentatoCod.NonSuperato}">
                  {{rowDataDetail.esitoAssistentatoDes | titlecase | noValuePlaceholder}}</span>
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.statoAssistentato'}}
                </span>
                <span entityState classPrefix="giud-assistentato-state-badge"
                  [entityStateCod]="rowDataDetail.statoAssistentatoCod" [label]="rowDataDetail.statoAssistentatoDes">
                </span>
              </td>

              <td>
                <div class="white-space-nowrap generic-cnt text-lg">
                  <i *ngIf="rowDataDetail.flRipetizioneConGiudiceDiverso" class="fa-solid fa-people-arrows warning"
                    [pTooltip]="T | translateItem: 'giudassistentato.ripetizioneConGiudiceDiverso'"
                    tooltipPosition="top"></i>

                  <i *ngIf="rowDataDetail.flRaggiuntoLimiteAssistentatiRazzaMani"
                    class="fa-solid fa-triangle-exclamation warning"
                    [pTooltip]="T | translateItem: 'giudassistentato.raggiuntoLimiteAssistentatiRazzaMani'"
                    tooltipPosition="top"></i>

                  <i *ngIf="rowDataDetail.flSuPiuGiorni" class="fa-solid fa-calendar-week warning"
                    [pTooltip]="T | translateItem: 'giudassistentato.razzaPiuGiorniMessage'" tooltipPosition="top"></i>
                </div>
              </td>

              <td class="buttons-column">
                <div class="buttons-cnt">
                  <ng-container *ngIf="this.entityManager.id === appManagerRoutes.GiudRichiestaDetail.id">
                    <app-button *grantVisible="grants; key 'edit'" [outlined]="true"
                      [disabled]="elaboration || (! utente.isAdmin && rowData.statoAssistentatoLivello >= statoAssistentatoLivello.AccettatoEnci)"
                      (onClick)="editEntity(rowData)" [tooltip]="T | translateItem: 'generic.edit'"
                      icon="fa-solid fa-pencil" />
                  </ng-container>

                  <!-- Approvazione ENCI -->
                  <ng-container
                    *ngIf="rowData.statoAssistentatoLivello === statoAssistentatoLivello.Inserito && utente?.isAdmin">
                    <app-button *grantVisible="grants; key 'acceptEnci'" [outlined]="true" severity="success"
                      [disabled]="elaboration || rowData.statoAssistentatoLivello > statoAssistentatoLivello.AccettatoEnci"
                      (onClick)="accept([rowData.id], 'ENCI')" [tooltip]="T | translateItem: 'generic.accept'"
                      icon="fa-solid fa-thumbs-up" />

                    <app-button *grantVisible="grants; key 'rejectEnci'" [outlined]="true" severity="danger"
                      [disabled]="elaboration || rowData.statoAssistentatoLivello > statoAssistentatoLivello.AccettatoEnci"
                      (onClick)="reject([rowData.id],'ENCI')" [tooltip]="T | translateItem: 'generic.reject'"
                      icon="fa-solid fa-thumbs-down" />
                  </ng-container>

                  <!-- Approvazione giudice -->
                  <ng-container
                    *ngIf="(rowData.statoAssistentatoLivello >= statoAssistentatoLivello.AccettatoEnci && utente?.isAdmin) || rowData.giudiceId === giudiceId">
                    <app-button *grantVisible="grants; key 'acceptGiud'" [outlined]="true" severity="success"
                      [disabled]="elaboration || rowData.statoAssistentatoLivello != statoAssistentatoLivello.AccettatoEnci"
                      (onClick)="accept([rowData.id], 'GIUD')" [tooltip]="T | translateItem: 'generic.accept'"
                      icon="fa-solid fa-thumbs-up" />

                    <app-button *grantVisible="grants; key 'rejectGiud'" [outlined]="true" severity="danger"
                      [disabled]="elaboration || rowData.statoAssistentatoLivello != statoAssistentatoLivello.AccettatoEnci"
                      (onClick)="reject([rowData.id],'GIUD')" [tooltip]="T | translateItem: 'generic.reject'"
                      icon="fa-solid fa-thumbs-down" />
                  </ng-container>

                  <ng-container *ngIf="rowData.giudiceRichiedenteId === giudiceId || utente.isAdmin">
                    <app-button *grantVisible="grants; key 'repeat'" [outlined]="true"
                      [disabled]="elaboration || !rowData.flPossibileRipetizione" (onClick)="addRepetition(rowData)"
                      [tooltip]="T | translateItem: 'generic.repeat'" icon="fa-solid fa-repeat" />
                  </ng-container>

                  <!-- Esito -->
                  <ng-container *ngIf="rowData.giudiceId === giudiceId || utente.isAdmin">
                    <app-button *grantVisible="grants; key 'rilascioEsito'" [outlined]="true"
                      [disabled]="elaboration || rowData.statoAssistentatoLivello != statoAssistentatoLivello.AccettatoGiudice || (rowData.giudiceId !== giudiceId && !utente?.isAdmin) || (rowData.esitoAssistentatoId && !utente?.isAdmin)"
                      (onClick)="editEntity(rowData, true)"
                      [tooltip]="T | translateItem: 'giudassistentato.rilascioEsito'" icon="fa-solid fa-award" />
                  </ng-container>

                  <app-button *grantVisible="grants; key 'delete'" [outlined]="true" severity="danger"
                    [disabled]="elaboration || entitiesSelected?.length > 1 || rowData.statoAssistentatoLivello >= statoAssistentatoLivello.AccettatoEnci"
                    (onClick)="deleteEntities([rowData.id])" [tooltip]="T | translateItem: 'generic.delete'"
                    icon="fa-solid fa-trash" />
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="100%" class="text-center">
                {{T | translateItem: 'generic.listEmpty'}}
              </td>
            </tr>
          </ng-template>
        </p-table>
        </td>
        </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="100%" class="text-center danger">
              {{T | translateItem: 'generic.noRecordsFound'}}
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="loadingbody">
          <tr style="height: 2.5rem;">
          </tr>
        </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <!-- Modale add exp -->
  <app-giud-assistentato-exp-add *ngIf="modalAdd?.visible && modalAdd?.tipoCalCod === 'EXP'"
    [visible]="modalAdd?.visible" [entity]="modalAdd.entity" [richiestaId]="modalAdd.richiestaId"
    [giudiceRichiedenteId]="modalAdd.giudiceRichiedenteId" (onHide)="modalAddHide($event)" />

  <!-- Modale add prv -->
  <app-giud-assistentato-prv-add *ngIf="modalAdd?.visible && modalAdd?.tipoCalCod === 'PRV'"
    [visible]="modalAdd?.visible" [entity]="modalAdd.entity" [richiestaId]="modalAdd.richiestaId"
    [giudiceRichiedenteId]="modalAdd.giudiceRichiedenteId" (onHide)="modalAddHide($event)" />

  <!-- Modale edit -->
  <app-giud-assistentato-edit *ngIf="modalEdit?.visible" [visible]="modalEdit?.visible" [entity]="modalEdit.entity"
    [richiestaId]="modalEdit.richiestaId" [giudiceRichiedenteId]="modalEdit.giudiceRichiedenteId"
    [rilascioEsito]="modalEdit.rilascioEsito" (onHide)="modalEditHide($event)" />
</ng-container>