import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { Observable } from "rxjs";
import { DataSearch } from "../../models/data-search";
import { EntityManager, EntityManagerInfo } from "../../models/entity-config";
import { AppManagerService } from "../../services/app-manager.service";
import { dataBind, isNullOrUndefined } from "../../utils/util";

@Component({
  selector: 'list-detail-control',
  templateUrl: "./list-detail-control.component.html",
  styleUrls: ["./list-detail-control.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, // we are adding the component to the list of known value accessors
      useExisting: ListDetailControlComponent,
      multi: true, // this dependency provides a list of values, and not only one value
    }
    // {
    //   provide: NG_VALIDATORS,
    //   useExisting: ListDetailControlComponent,
    //   // useExisting: forwardRef(() => ListDetailControlComponent),
    //   multi: true,
    // }
  ]
})
export class ListDetailControlComponent implements ControlValueAccessor { // Validator

  @Input() styleClass: string;
  @Input() icon: string;
  @Input() required: boolean;

  @Input() modalHeader: string;
  @Input() modalClass: string;
  @Input() modalResizable: boolean;
  @Input() modalCloseOnEscape: boolean;

  @Input() fieldLabel: string;
  @Input() fieldsBindSearch: any;
  @Input() fieldsBindNew: any;
  @Input() entityManagerInfo: EntityManagerInfo;

  @Input() translatedItems: any;

  @Output() onHide = new EventEmitter();

  touched = false;
  disabled = false;
  editable = false;
  data: any;
  fieldValue: string;

  entityManager: EntityManager;
  // dataSearch: DataSearch;

  constructor(
    private appManagerService: AppManagerService
  ) { }

  onChange = (data) => { };

  onTouched = () => { };

  /**
   * Parent set value of the control (that is a child control)
   * 
   * @param data 
   */
  writeValue(data: any) {
    this.data = data;
    this.fieldValue = this.data?.[this.fieldLabel];
  }

  /**
   * Parent set disabled status of the control (that is a child control)
   * 
   * @param disabled 
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  /**
   * Child control can report its new value back to the parent form
   * 
   * @param onChange 
   */
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  /**
   * Child control can report its touched status back to the parent form
   * 
   * @param onTouched 
   */
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  enableEdit() {
    const entitySearch = this?.fieldsBindSearch
      ? (this.data ? dataBind(this.data, this.fieldsBindSearch) : null)
      : null;

    const entity = this?.fieldsBindNew
      ? (this.data ? dataBind(this.data, this.fieldsBindNew) : null)
      : null;

    this.entityManager = this.appManagerService.instantiateEntityManager(this.entityManagerInfo, { entity: entity, entitySearch: entitySearch }, false);

    this.editable = true;
  }

  selectEvent(event) {
    this.markAsTouched();
    this.fieldValue = event?.data[this.fieldLabel];
    this.onChange(event.data);

    this.editable = false;
  }

  hide() {
    this.editable = false;
  }

  /**
   * When the form is initialized, every form control (and the form group as well) is considered to be in status untouched, 
   * and the ng-untouched CSS class is applied to the form group and also to each of its individual child controls.
   * But when a child control gets touched by the user, meaning the user has tried to interact with it at least once, 
   * then the whole form is considered touched as well, meaning that the ng-touched CSS class gets applied to the form.
   */
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  // validate(control: AbstractControl): ValidationErrors | null {
  //   return control.invalid ? { internal: true } : null;
  // }

  // registerOnValidatorChange?(fn: () => void): void {}

}