import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { Observable, ReplaySubject, Subject, combineLatest, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { CalCaleManiGiudAssistentatoDto } from 'src/app/models/calendario/dto/cal-cale-mani-giud-assistentato-dto';
import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from 'src/app/models/data-search';
import { IEntityList } from 'src/app/models/entity';
import { CalCaleManiGiudAssistentatoPrvPars, TipoManiIdCodProtipo } from 'src/app/models/giud-richiesta/dto/cal-cale-mani-giud-assistentato-prv-pars';
import { GiudRichiestaPrvSelectionDto } from 'src/app/models/giud-richiesta/dto/giud-richiesta-prv-selection-dto';
import { GiudRichiestaDetail } from 'src/app/models/giud-richiesta/giud-richiesta-detail';
import { GiudAssistentatoNewDto } from 'src/app/models/giudici/dto/giud-assistentato-new-dto';
import { GiudGiudiceSuggestionDto } from 'src/app/models/giudici/dto/giud-giudice-suggestion-dto';
import { GiudEsitoAssistentato } from 'src/app/models/giudici/giud-esito-assistentato';
import { ToastMessageData } from 'src/app/models/message';
import { SecUtente } from 'src/app/models/security/sec-utente';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { BaseDataService } from 'src/app/services/base-data.service';
import { DataService } from 'src/app/services/data.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { dateGreatherThan, dateLessThan } from 'src/app/utils/formValidators';
import { dateFormat, dateFormatPrNg } from 'src/app/utils/util';

@Component({
  selector: 'app-giud-assistentato-prv-add',
  templateUrl: './giud-assistentato-prv-add.component.html',
  styleUrls: ['./giud-assistentato-prv-add.component.scss']
})
export class GiudAssistentatoPrvAddComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject();

  // Etichette
  T$: Observable<Resources> = this.translateService.translatedItems$(AppManagerRoutes.GiudAssistentatoAdd.translateSuffixs ?? ['generic.']).pipe(
    tap(translateItems => this.resourceList = translateItems)
  );

  resourceList: Resources;

  dateFormat: string = dateFormat;

  dateFormatPrNg: string = dateFormatPrNg;

  elaboration: boolean = true;

  utente: SecUtente;

  // Form
  dataForm = this.fb.group({
    id: this.fb.control<number | null>(0, [Validators.required]),
    richiestaDetailId: this.fb.control<number | null>(null, [Validators.required]),
    assistentatoIdPadre: this.fb.control<number | null>(null),
    giudiceId: this.fb.control<number | null>(null, [Validators.required]),
    giudiceRaw: this.fb.control<Partial<GiudGiudiceSuggestionDto> | null>({ value: null, disabled: true }, [Validators.required]),
    caleManiDataInizio: this.fb.control<Date | null>(null),
    caleManiDataFine: this.fb.control<Date | null>(null),
    CaleManiSpGiudIds: this.fb.control<number[] | null>(null, [Validators.required]),
  }, { validators: [dateLessThan('caleManiDataInizio', 'caleManiDataFine'), dateGreatherThan('caleManiDataInizio', 'caleManiDataFine')] });

  // Date
  dataInizioMin: Date = new Date();

  get dataFineMin(): Date {
    return this.dataForm.controls.caleManiDataInizio.value;
  }

  // Dropdowns
  esitoAssistentatoList$: Observable<GiudEsitoAssistentato[]> = this.baseDataService.getGiudEsitoAssistentatoList();

  richiestaDetailList$: Observable<GiudRichiestaDetail[]>;

  // Ricerca giudici
  private giudiceQuery$: Subject<string> = new Subject();

  private richiestaPrvSelection$: ReplaySubject<GiudRichiestaPrvSelectionDto[]> = new ReplaySubject(1);

  private giudiceId$: ReplaySubject<number> = new ReplaySubject(1);

  giudiciSuggestions$: Observable<GiudGiudiceSuggestionDto[]> = combineLatest([
    this.richiestaPrvSelection$.asObservable().pipe(
      startWith(),
      distinctUntilChanged()
    ),
    this.giudiceQuery$.asObservable().pipe(
      startWith(),
      distinctUntilChanged()
    )
  ]).pipe(
    switchMap(([richiestaPrvSelection, query]: [richiestaPrvSelection: GiudRichiestaPrvSelectionDto[], query: string]) => {
      const data = richiestaPrvSelection.map(this.tipoManiIdCodProtipoStr);
      const ds = new DataSearch(
        [new DataSearchValue([query], ['nomeCompleto'], ComparisonOperator.Contains),
        new DataSearchValue([data], ['tipoManiIdCodProtipo'], ComparisonOperator.Custom),
        new DataSearchValue([this.giudiceRichiedenteId.toString()], ['lingueComuni'], ComparisonOperator.Custom)],
        [new DataSortValue('nomeCompleto', SortMode.Asc)]
      );

      return this.dataService.searchElements<GiudGiudiceSuggestionDto>(`${this.dataService.configSettings.restCommonUrl}/giudgiudicesuggestion`, ds);
    }),
    catchError(() => of({
      entities: [],
      numRowsTot: 0
    })),
    map((data: IEntityList<GiudGiudiceSuggestionDto>) => data.entities)
  );

  // Ricerca mani
  filterAndSortEntities$: Observable<CalCaleManiGiudAssistentatoPrvPars> = combineLatest([
    this.richiestaPrvSelection$.asObservable().pipe(
      distinctUntilChanged(),
      filter(data => !!data?.length)
    ),
    this.giudiceId$.asObservable().pipe(
      distinctUntilChanged(),
      filter(data => !!data)
    ),
    this.dataForm.controls.caleManiDataInizio.valueChanges.pipe(
      distinctUntilChanged(),
      filter(data => !!data)
    ),
    this.dataForm.controls.caleManiDataFine.valueChanges.pipe(
      distinctUntilChanged(),
      filter(data => !!data)
    ),
  ]).pipe(
    map(([richiestaPrvSelection, giudiceId, dataInizio, dataFine]: [richiestaPrvSelection: GiudRichiestaPrvSelectionDto[], giudiceId: number, dataInizio: Date, dataFine: Date]) => {
      const data = richiestaPrvSelection.map(this.tipoManiIdCodProtipo);

      return {
        giudiceId: giudiceId,
        tipoManiIdCodProtipoList: data,
        dataInizio: DateTime.fromJSDate(dataInizio).toISODate(),
        dataFine: DateTime.fromJSDate(dataFine).toISODate(),
        giudiceRichiedenteId: this.giudiceRichiedenteId
      }
    })
  );

  maniSuggestions$: Observable<CalCaleManiGiudAssistentatoDto[]> = this.filterAndSortEntities$.pipe(
    tap(() => this.elaboration = true),
    switchMap((data) => {
      return this.dataService.postGeneric(`${this.dataService.configSettings.restCalendarioUrl}/calcalemanigiudassistentatoprv`, data).pipe(
        catchError((err) => {
          this.elaboration = false;

          return throwError(() => err);
        })
      )
    }),
    tap(() => this.elaboration = false)
  );

  isRiprtizione: boolean = false;

  @Input()
  visible: boolean = false;

  @Input()
  entity: GiudAssistentatoNewDto = new GiudAssistentatoNewDto();

  @Input()
  richiestaId: number;

  @Input()
  giudiceRichiedenteId: number;

  @Output()
  onHide = new EventEmitter<boolean>();

  constructor(
    private translateService: TranslateService,
    private baseDataService: BaseDataService,
    private fb: FormBuilder,
    private dataService: DataService,
    private appManagerService: AppManagerService,
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    // Utente
    this.appManagerService.utente$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (utente) => {
        this.utente = utente;
      }
    );

    // Se sono in una ripetizione la razza non deve essere modificabile e il giudice non deve essere modificabile solo se l'utente è admin
    if (this.entity.assistentatoIdPadre) {
      this.isRiprtizione = true;
      this.dataForm.controls.richiestaDetailId.disable();
    }

    // Carico le richieste di ampliamento
    this.richiestaDetailList$ = this.getGiudRichiestaDetail(this.richiestaId);

    // Eventi forms
    combineLatest([
      this.richiestaDetailList$,
      this.dataForm.controls.richiestaDetailId.valueChanges.pipe(
        startWith(null),
        distinctUntilChanged()
      )
    ]).pipe(
      takeUntil(this.destroy$),
      map(([list, filter]: [list: any[], filter: number]) => {
        return list.filter(t => t.id === filter).map(t => { return { gruppoId: t.gruppoId, tipoManiId: t.tipoManiId, codProtipo: t.codProtipo } as GiudRichiestaPrvSelectionDto });
      })
    ).subscribe(this.richiestaPrvSelection$);

    // Quando seleziono un giudice dall'autocomplete vado a settare il suo id
    // Se cancello il giudice resetto il campo manifestazione, il giudice e il giudce mani
    this.dataForm.controls.giudiceRaw.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (data: GiudGiudiceSuggestionDto) => {
        const giudiceIdFiled = this.dataForm.controls.giudiceId;
        const CaleManiSpGiudIdsField = this.dataForm.controls.CaleManiSpGiudIds;

        if (data?.id) {
          giudiceIdFiled.setValue(data?.id);
        }
        else if (!data) {
          giudiceIdFiled.reset();
          CaleManiSpGiudIdsField.reset();
        }
      }
    );

    // Quando setto l'id della prova si abilita il selettore del giudice
    this.dataForm.controls.richiestaDetailId.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (richiestaDetailId: number) => {
        const giudiceRawField = this.dataForm.controls.giudiceRaw;
        const CaleManiSpGiudIdsField = this.dataForm.controls.CaleManiSpGiudIds;
        const giudiceIdField = this.dataForm.controls.giudiceId;

        CaleManiSpGiudIdsField.reset();

        if (richiestaDetailId && (!this.isRiprtizione || (this.isRiprtizione && this.utente.isAdmin))) {
          giudiceRawField.enable();
        }
        else {
          giudiceRawField.disable();

          giudiceIdField.reset();
          giudiceRawField.reset();
        }
      }
    );

    // Quando setto l'id del giudice si abilita il selettore della manifestazione
    this.dataForm.controls.giudiceId.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (giudiceId: number) => {
        this.giudiceId$.next(giudiceId);

        const CaleManiSpGiudIdsField = this.dataForm.controls.CaleManiSpGiudIds;

        if (giudiceId) {
          CaleManiSpGiudIdsField.enable();
        }
        else {
          CaleManiSpGiudIdsField.disable();
        }
      }
    );

    // Popolo il form
    const data = { ...this.entity, ...{ richiestaDetailId: this.entity.richiestaDetailIds[0] } };
    this.dataForm.patchValue(data);
  }

  hideModal(reloadData: boolean = false): void {
    this.visible = false;
    this.onHide.emit(reloadData);
  }

  save(): void {
    const { caleManiDataInizio, caleManiDataFine, richiestaDetailId, ...formData } = this.dataForm.getRawValue();

    const data = { ...formData, ...{ richiestaDetailIds: [richiestaDetailId] } };

    this.dataService.postGeneric(`${this.dataService.configSettings.restCommonUrl}/giudassistentatobulk`, data as GiudAssistentatoNewDto).subscribe({
      next: () => {
        this.elaboration = false;

        this.appManagerService.showToastMessage(
          new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.savesuccessmsg')));

        this.dataForm.markAsPristine();
        this.hideModal(true);
      },
      error: () => {
        this.elaboration = false;
      }
    });
  }

  getGiudRichiestaDetail(richiestaId: number): Observable<GiudRichiestaDetail[]> {
    const dataSearch = new DataSearch(
      [new DataSearchValue(['1'], ['flAccettata'], ComparisonOperator.Equals)],
      [new DataSortValue('tipoManiDes', SortMode.Asc), new DataSortValue('codProtipo', SortMode.Asc)]
    );

    return this.dataService.searchElements<GiudRichiestaDetail>(`${this.dataService.configSettings.restCommonUrl}/giudrichiesta/${richiestaId.toString()}/giudrichiestadetail`, dataSearch).pipe(
      catchError((err) => {
        return throwError(() => err);
      }),
      map((data) => {
        data.entities.forEach((ra) => ra['disabled'] = false);
        // data.entities.forEach((ra) => ra['disabled'] = (!this.entity.assistentatoIdPadre && ra.flAssistentatoInserito && !(ra.id === richiestaDetailId)) || (this.entity.assistentatoIdPadre && !(this.entity.richiestaDetailIdList.includes(ra.id))));

        return data.entities;
      })
    )
  }

  giudiceSearch(event): void {
    this.giudiceQuery$.next(event.query.trim());
  }

  private tipoManiIdCodProtipoStr(richiestaPrvSelection: GiudRichiestaPrvSelectionDto): string {
    return `${richiestaPrvSelection.tipoManiId}_${richiestaPrvSelection.codProtipo}`;
  }

  private tipoManiIdCodProtipo(richiestaPrvSelection: GiudRichiestaPrvSelectionDto): TipoManiIdCodProtipo {
    return {
      tipoManiId: richiestaPrvSelection.tipoManiId,
      codProtipo: richiestaPrvSelection.codProtipo
    };
  }
}
