<div *ngIf="showSpinner; else content" class="flex flex-column text-center p-6 text-600">
  <div>
    <i class="fa fa-3x fa-spin fa-spinner"></i>
  </div>
  <div *ngIf="message" class="pt-2">
    {{message}}
  </div>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>