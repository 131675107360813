import { Component } from '@angular/core';
import { SortMeta } from 'primeng/api';
import { map, Observable, takeUntil } from 'rxjs';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { TipoCalCod } from 'src/app/models/anagrafiche/calendario/cal-tipo-cal';
import { ComparisonOperator, SearchFieldType, SortMode } from 'src/app/models/data-search';
import { GiudAssistentatoGroupedDto } from 'src/app/models/giudici/dto/giud-assistentato-grouped-dto';
import { GiudAssistentatoListBaseComponent } from '../giud-assistentato-list/giud-assistentato-list-base.component';

@Component({
  selector: 'app-giud-assistentato-grouped-list',
  templateUrl: './giud-assistentato-grouped-list.component.html',
  styleUrls: ['./giud-assistentato-grouped-list.component.scss']
})
export class GiudAssistentatoGroupedListComponent extends GiudAssistentatoListBaseComponent<GiudAssistentatoGroupedDto> {
  // Sorting
  defaultMultiSortMeta: SortMeta[] = [
    { field: 'caleManiLabel', order: SortMode.Asc }
  ];

  // Filters
  searchFieldsConf = [
    // Simple
    { key: 'giudiceId', searchFields: 'giudiceId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: false },
    { key: 'tipoCalCod', searchFields: 'tipoCalCod', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: false },
    { key: 'simpleSearch', searchFields: 'caleManiLabel,giudiceNome,giudiceCognome,giudiceNomeCompleto', type: SearchFieldType.multiField, searchCompOp: ComparisonOperator.Contains, advancedSearch: false },
    // Advanced
    { key: 'giudiceId', searchFields: 'giudiceId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'tipoCalCod', searchFields: 'tipoCalCod', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'caleManiLabel', searchFields: 'caleManiLabel', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
    { key: 'caleManiDataInizio', searchFields: 'caleManiDataInizio', type: SearchFieldType.date, searchCompOp: ComparisonOperator.GreaterThanOrEqual, advancedSearch: true },
    { key: 'caleManiDataFine', searchFields: 'caleManiDataFine', type: SearchFieldType.date, searchCompOp: ComparisonOperator.LessThanOrEqual, advancedSearch: true },
    { key: 'giudiceNome', searchFields: 'giudiceNome', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
    { key: 'giudiceCognome', searchFields: 'giudiceCognome', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true }
  ];

  titleMap: { [key: string]: string } = {
    [AppManagerRoutes.GiudAssistentatoGroupedList.id]: 'giudassistentato.windowListHeaderGiudice',
    'other': 'giudassistentato.windowListHeader'
  };

  // Devo costruirmi un dataKeyUnivoco per gestire l'espansione della tabella
  data$: Observable<GiudAssistentatoGroupedDto[]> = this.entities$.pipe(
    map((entities) => {
      entities.forEach(e => e['dataKey'] = `${e.caleManiId}_${e.giudiceId}}`);
      return entities;
    })
  );

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Utente
    this.appManagerService.utente$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (utente) => this.utente = utente
    );

    // Init filters
    this.initFilters();

    // Capisco in quale route mi trovo ed imposto i filtri
    this.setFilterOnRouteBase();
  }

  private setFilterOnRouteBase(): void {
    switch (this.entityManager.id) {
      case AppManagerRoutes.GiudAssistentatoGroupedList.id:
        this.entityServiceUrl = AppManagerRoutes.GiudAssistentatoGroupedList.name;

        this.persistFilters = true;
        break;

      case AppManagerRoutes.GiudAssistentatoGroupedExpList.id:
        this.entityServiceUrl = AppManagerRoutes.GiudAssistentatoGroupedExpList.name;

        this.persistFilters = true;
        this.defaultFilters = { tipoCalCod: TipoCalCod.esposizioni };
        break;

      case AppManagerRoutes.GiudAssistentatoGroupedPrvList.id:
        this.entityServiceUrl = AppManagerRoutes.GiudAssistentatoGroupedPrvList.name;

        this.persistFilters = true;
        this.defaultFilters = { tipoCalCod: TipoCalCod.prove };
        break;

      default:
        this.entityServiceUrl = AppManagerRoutes.GiudAssistentatoGroupedList.name;

        this.persistFilters = false;
    }

    this.baseWindowListService.setDefaultFilters(this.defaultFilters);
  }
}
