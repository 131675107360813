import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, SimpleChanges, } from '@angular/core';
import { AppManagerService, SESSION_STORAGE_SELECTION, StorageType } from '../services/app-manager.service';

@Directive({
  selector: 'tr[tableRowHilight]'
})
export class TableRowHilightDirective {
  @Input('tableRowHilight') public dataKey: string;
  @Input() currentDataKey: string;
  @Input() entityManagerId: string;
  @Output() currentDataKeyChange: EventEmitter<string> = new EventEmitter();

  @HostBinding('class.table-hilighted-row') hilightedRow: boolean = false;

  @HostListener('click', ['$event.target']) hilightRow(target) {
    if ((target.type === 'button' || target.parentNode?.type === 'button' ||
      target.role === 'checkbox' || target.parentNode?.role === 'checkbox')
      && this.currentDataKey == this.dataKey) {
      return;
    };

    this.currentDataKey = this.currentDataKey == this.dataKey ? undefined : this.dataKey;
    this.setStorageHilightedRowDataKey(this.currentDataKey);
    this.currentDataKeyChange.emit(this.currentDataKey);
  }

  constructor(private appManagerService: AppManagerService, private el: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentDataKey) {
      const change = changes.currentDataKey;
      this.hilightedRow = change.currentValue == this.dataKey;
    }
  }

  private setStorageHilightedRowDataKey(dataKey: string): void {
    this.appManagerService.setStorageData(SESSION_STORAGE_SELECTION, this.entityManagerId, { dataKey }, StorageType.Session);
  }
}

// import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, Output, SimpleChanges, } from '@angular/core';

// @Directive({
//   selector: 'tr[tableRowHilight]'
// })
// export class TableRowHilightDirective implements OnChanges {
//   @Input('tableRowHilight') dataKey: string;
//   @Input() currentDataKey: string;

//   @Output() currentDataKeyChange: EventEmitter<string> = new EventEmitter();

//   @HostBinding('class.table-hilighted-row') hilightedRow: boolean = false;
//   @HostBinding('id') id: string;

//   @HostListener('click', ['$event.target']) hilightRow(target) {
//     this.id = this.dataKey;
//     if ((target.type === 'button' || target.parentNode?.type === 'button' ||
//       target.role === 'checkbox' || target.parentNode?.role === 'checkbox')
//       && this.currentDataKey == this.dataKey) {
//       return;
//     };

//     this.currentDataKey = this.currentDataKey == this.dataKey ? undefined : this.dataKey;
//     this.currentDataKeyChange.emit(this.currentDataKey);
//   }

//   constructor(private el: ElementRef) { }

//   ngOnChanges(changes: SimpleChanges): void {

//     if (changes.currentDataKey) {
//       const change = changes.currentDataKey;
//       this.hilightedRow = change.currentValue == this.dataKey;
//       if (this.hilightedRow)
//         this.el.nativeElement.focus();
//     }
//   }
// }