import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmdField, CmdFieldKey, CmdFieldTarget, CmdFieldMode, EntityManager } from '../../../../models/entity-config';
import { AppManagerService } from '../../../../services/app-manager.service';

@Component({
  selector: 'browser-window-help-home',
  templateUrl: './browser-window-home.component.html',
  styleUrls: ['./browser-window-home.component.scss']
})
export class BrowserWindowHomeComponent implements OnInit, OnDestroy {

  cmdFieldTarget = CmdFieldTarget;
  cmdFieldMode = CmdFieldMode;
  cmdFieldKey = CmdFieldKey;

  entityManager: EntityManager;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appManagerService: AppManagerService
  ) { }

  ngOnInit(): void {
    this.entityManager = this.appManagerService.getEntityManager(this.activatedRoute);
  }

  ngOnDestroy(): void {
  }

  onCmd(field: CmdField) {
    switch (field.key) {
      case CmdFieldKey.back:
        const navItem = this.appManagerService.getBackUrl();
        this.router.navigate([navItem.url],{queryParams: navItem.queryParams});
        break;

      case CmdFieldKey.navigate:
        if (field.entityManagerInfo.id) {
          this.router.navigate([field.entityManagerInfo.id, field.entityManagerInfo.url], { relativeTo: this.activatedRoute });
        }
        else {
          this.router.navigate([field.entityManagerInfo.url], { relativeTo: this.activatedRoute });
        }
        break;
    }
  }

}
