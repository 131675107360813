
import { AppManagerRoutes } from "../../app-manager-routes";
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, EntityManagerConfig, CmdField } from "../entity-config";

export class SecEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu,
        label: 'menu.SecProfiloList.label', title: 'menu.SecProfiloList.title',
        entityManagerInfo: AppManagerRoutes.SecProfiloList
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu,
        label: 'menu.SecRuoloList.label', title: 'menu.SecRuoloList.title',
        entityManagerInfo: AppManagerRoutes.SecRuoloList
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu,
        label: 'menu.SecTagList.label', title: 'menu.SecTagList.title',
        entityManagerInfo: AppManagerRoutes.SecTagList
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu,
        label: 'menu.SecUtenteList.label', title: 'menu.SecUtenteList.title',
        entityManagerInfo: AppManagerRoutes.SecUtenteList,
      }
    ];
  }

}