import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[entityState]'
})
export class EntityStateDirective implements OnInit, OnChanges {
  private baseClass: string = 'state-badge';

  @Input() classPrefix: string;
  @Input() entityStateCod: string;

  @Input() label: string = '';

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.setClass(this.baseClass);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entityStateCod) {
      const classToRemove = this.getClassName(this.classPrefix, changes.entityStateCod?.previousValue);
      this.removeClass(classToRemove);

      const classToAdd = this.getClassName(this.classPrefix, changes.entityStateCod?.currentValue);
      this.setClass(classToAdd);
    }

    if (changes.label) {
      this.setLabel(changes.label?.currentValue);
    }
  }

  private setClass(className): void {
    if (className && !this.el.nativeElement.classList.contains(className)) {
      this.el.nativeElement.classList.add(className);
    }
  }

  private removeClass(className): void {
    if (className && this.el.nativeElement.classList?.contains(className)) {
      this.el.nativeElement.classList?.remove(className);
    }
  }

  private setLabel(label: string): void {
    this.el.nativeElement.innerHTML = label ?? '';
  }

  private getClassName(classPrefix: string, entityStateCod: string): string {
    return classPrefix && entityStateCod ? `${classPrefix?.toLowerCase()}-${entityStateCod?.toLowerCase()}` : '';
  }
}