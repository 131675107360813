import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { BaseWindowListSearchComponent } from 'src/app/components/base-window-list/base-window-list-search.component';
import { GiudStatoRichiesta } from 'src/app/models/giud-richiesta/giud-stato-richiesta';
import { GiudSubTipoRichiesta } from 'src/app/models/giud-richiesta/giud-sub-tipo-richiesta';
import { GiudTipoRichiesta } from 'src/app/models/giud-richiesta/giud-tipo-richiesta';

@Component({
  selector: 'app-giud-richiesta-list-search',
  templateUrl: './giud-richiesta-list-search.component.html',
  styleUrls: ['./giud-richiesta-list-search.component.scss']
})
export class GiudRichiestaListSearchComponent extends BaseWindowListSearchComponent implements OnInit, OnDestroy {
  // Form
  dataForm = this.fb.group({
    simpleSearch: null,
    id: null,
    protocollo: null,
    giudiceCognome: null,
    giudiceNome: null,
    tipoRichiestaId: null,
    subTipoRichiestaId: null,
    statoRichiestaId: null,
    giudiceId: null
  });

  // Dropdowns
  tipoRichiestaList$: Observable<GiudTipoRichiesta[]> = this.baseDataService.getGiudTipoRichiestaList();

  subTipoRichiestaList$: Observable<GiudSubTipoRichiesta[]> = this.baseDataService.getGiudSubTipoRichiestaList();

  statoRichiestaList$: Observable<GiudStatoRichiesta[]> = this.baseDataService.getGiudStatoRichiestaList();

  appManagerRoutes: typeof AppManagerRoutes = AppManagerRoutes;

  simpleSearchMap: { [key: string]: string } = {
    [AppManagerRoutes.GiudRichiestaGiudiceList.id]: 'giudrichiesta.simpleSearchPlaceholderGiudice',
    'other': 'giudrichiesta.simpleSearchPlaceholder'
  };

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
