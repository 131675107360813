<div class="p-5 overflow-y-auto bg-white" style="height: calc(100vh - 4rem);">
  <div class="container">
    <ng-container *ngIf="utente?.flHoRilasciDaAccettare; else home">
      <app-gen-rilascio-list [isInHome]="true" />
    </ng-container>

    <ng-template #home>
      <!-- Messaggio rilasci -->
      <app-message-app [styleClasses]="'mb-5'" />

      <div class="flex flex-column md:flex-row flex-wrap justify-content-center card-menu mb-5 gap-5">
        <ng-container *ngFor="let item of cardMenuItems">
          <div class="surface-50 border-1 border-300 border-solid widget-card flex flex-column md:w-16rem">
            <div
              class="flex flex-row align-items-center column-gap-2 surface-200 text-2xl font-medium text-primary p-3 header">
              <i [class]="item.icon" class="text-3xl font-medium text-primary"></i>
              <span>{{translateItems | translateItem: item.title}}</span>
            </div>

            <div class="flex flex-column justify-content-between px-4 py-3 flex-grow-1">
              <div class="flex-grow-1 mb-3">
                {{translateItems | translateItem: item.label}}
              </div>

              <div class="flex flex-row justify-content-end">
                <button pButton type="button" [label]="translateItems | translateItem: 'generic.navigate'"
                  [title]="translateItems | translateItem: 'generic.navigate'" icon="fa-solid fa-chevron-right"
                  iconPos="right" (click)="item.command(undefined)"
                  class="p-button p-button-sm p-button-outlined"></button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <p-divider class="block md:hidden" />

      <div *ngIf="grants" class="mx-auto widget-grid">
        <app-notifiche-widget *grantVisible="grants; key 'notificheWidgetHome'" />

        <app-alert-giud-widget *grantVisible="grants; key 'alertGiudWidgetHome'" />

        <app-calendario-anomalie-widget *grantVisible="grants; key 'calendarioAnomalieWidgetHome'" />

        <app-calendario-stati-widget *grantVisible="grants; key 'calendarioStatiWidgetHome'" />

        <app-anomalie-widget *grantVisible="grants; key 'anomalieWidgetHome'" />
      </div>
    </ng-template>
  </div>
</div>