import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from "../data-search";
import { Entity } from "../entity";
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, CmdFieldStyle, Icons, CmdField, CmdEvent, FormField, FormFieldStyle } from "../entity-config";
import { AppManagerRoutes } from "../../app-manager-routes";
import { Validators } from "@angular/forms";
import { dateFormat, dateTimeFormat } from "src/app/utils/util";
import { IEntityService } from "../../services/entity.service";
import { Location } from '@angular/common';
import { AppInjector } from "../../app.module";
import { ActivatedRoute, Router } from "@angular/router";

export class GenNotifica extends Entity {
  utenteId: number;
  data: Date;
  notificaTipoId: number;
  notificaTipoInvioId: number;
  gest: number;
  subject?: string;
  body?: string;
  err?: string;
  dataAccettazione?: Date;
  notificaMadreId?: number;

  sezione: string;
  notificaTipoDes: string;
  notificaTipoInvioDes: string;
  replyBody: string;
  utenteFromEmail: string;
  flNotificaAccettata: boolean;
  flRispostaInviata: boolean;
}

export class GenNotificaEMC extends EntityManagerConfig {
  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.none, target: CmdFieldTarget.none };
  entityService: IEntityService<GenNotifica>;
  location: Location;

  init() {
    this.clearCache = true;
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = {
      windowListHeader: `${this.name}.windowlistheader`,
      windowEditHeader: `${this.name}.windowreplyheader`
    };

    this.location = AppInjector.get(Location);

    this.dataSortValue = [new DataSortValue('data', SortMode.Desc), new DataSortValue('dataAccettazione', SortMode.Desc)];

    this.entityService = this.getEntityService(this.name) as IEntityService<GenNotifica>;

    const ds = new DataSearch();
    ds.dataSearchValues = [new DataSearchValue([true], ['visible'], ComparisonOperator.Equals)];
    ds.dataSortValues = [new DataSortValue('des', SortMode.Asc)];

    this.attributes['genNotificaTipoList'] = this.getEntityService('gennotificatipo').searchEntitiesAsync(this.fLoad, ds);
    this.attributes['genNotificaTipoInvioList'] = this.getEntityService('gennotificatipoinvio').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        label: 'generic.back', title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchreset',
        title: 'generic.searchreset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchbasic',
        title: 'generic.searchbasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // List
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        entityManagerInfo: AppManagerRoutes.GenNotificaNew,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      // {
      //   key: CmdFieldKey.set, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
      //   entityManagerInfo: AppManagerRoutes.GenNotificaReply,
      //   title: 'generic.set', icon: Icons.inbox, class: CmdFieldStyle.btnSecondary
      // },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        entityManagerInfo: AppManagerRoutes.GenNotificaDetail,
        title: 'generic.detail', icon: Icons.detail, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.set, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        entityManagerInfo: AppManagerRoutes.GenNotificaReply,
        title: 'generic.markAsRead', icon: Icons.envelopeOpen, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        entityManagerInfo: AppManagerRoutes.GenNotificaReply,
        title: 'generic.reply', icon: Icons.reply, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger
      },

      // Detail
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        label: 'generic.back', title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        label: 'generic.send', title: 'generic.send', icon: Icons.send, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'utenteFromEmail', type: FormFieldType.string, label: `${this.name}.utentefromemail`, sortable: true },
      { key: 'notificaTipoDes', type: FormFieldType.string, label: `${this.name}.notificatipodes`, sortable: true },
      { key: 'subject', type: FormFieldType.string, label: `${this.name}.subject`, sortable: true },
      { key: 'data', type: FormFieldType.date, label: `${this.name}.data`, sortable: true, format: { format: dateTimeFormat } },
      { key: 'dataAccettazione', type: FormFieldType.date, label: `${this.name}.dataaccettazione`, sortable: true, format: { format: dateTimeFormat } },
      {
        key: 'flNotificaAccettata', type: FormFieldType.boolean, label: `${this.name}.flnotificaaccettata`,
        sortable: true, format: { icon: { false: `${Icons.envelope} text-lg danger`, true: '' } },
      },
      {
        key: 'flRispostaInviata', type: FormFieldType.boolean, label: `${this.name}.flrispostainviata`,
        sortable: true, format: { icon: { false: '', true: `${Icons.reply} text-lg text-600` } },
      },
    ];

    this.detailFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'dtIns', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'userIns', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'data', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'notificaTipoId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'notificaTipoInvioId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'utenteId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      {
        key: 'utenteFromEmail', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.view,
        label: `${this.name}.utentetoemail`,
        class: FormFieldStyle.full
      },
      {
        key: 'subject', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.htmlView,
        label: `${this.name}.subject`,
        class: FormFieldStyle.full
      },
      {
        key: 'body', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.htmlView,
        label: `${this.name}.body`,
        class: FormFieldStyle.full
      },
      {
        key: 'replyBody', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.editor,
        label: `${this.name}.replybody`, placeholder: `${this.name}.replybody`,
        class: FormFieldStyle.full,
        validators: [Validators.required]
      }
    ];

    this.searchFields = [
      {
        key: 'notificaTipoInvioId', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'notificaTipoInvioId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'sezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'sezione', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simplesearchplaceholder`,
        options: { searchFields: 'utenteFromEmail,notificaTipoDes,subject,body', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'notificaTipoInvioId', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'notificaTipoInvioId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'utentefromemail', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.utentefromemail`,
        options: {
          searchFields: 'utenteFromEmail', searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.default
      },
      {
        key: 'notificaTipoId', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.notificatipodes`,
        options: {
          data: this.attributes['genNotificaTipoList'], dataKey: 'id', dataLabel: 'des', searchFields: 'notificaTipoId', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'subject', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.subject`,
        options: {
          searchFields: 'subject', searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.default
      },
      {
        key: 'body', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.body`,
        options: {
          searchFields: 'body', searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.default
      }
    ];
  }

  onCmd(event: CmdEvent): boolean {
    switch (event.field.key) {
      case CmdFieldKey.set:
        // const entityService = this.getEntityService(this.name);
        if (event.field.target == CmdFieldTarget.listBody) {
          const entity = event.data as GenNotifica;
          entity.dataAccettazione = new Date(Date.now());
          entity.flNotificaAccettata = true;
          this.entityService.updateEntities(event.field, [entity]);
        }
        else if (event.field.target == CmdFieldTarget.listHeader) {
          const entities = event.data as GenNotifica[];
          entities.forEach(entity => {
            entity.dataAccettazione = new Date(Date.now());
            entity.flNotificaAccettata = true;
          });
          this.entityService.updateEntities(event.field, event.data);
        }
        // event will not be propagate forward
        return true;

      //per salvare la data di accettazione alla lettura della notifica - PROBLEMA: troppo rapida apertura della pg rispetto a salvataggio
      // case CmdFieldKey.navigate:
      //   const entity = event.data as GenNotifica;
      //   if (!entity.dataAccettazione || entity.dataAccettazione == null) {
      //     entity.dataAccettazione = new Date(Date.now());
      //     entity.flNotificaAccettata = true;
      //     this.entityService.updateEntities(event.field, [entity]);
      //     event.data = entity;
      //   }
      //   return super.onCmd(event);

      case CmdFieldKey.back:
        this.location.back();
        return true;

      default:
        return super.onCmd(event);
    }
  }

  isCmdFieldVisible(field: CmdField, args: any): boolean {
    switch (field.key) {
      case CmdFieldKey.set:
        return !args?.entity?.flNotificaAccettata;
      case CmdFieldKey.edit:
        return !args?.entity?.flRispostaInviata;
      default:
        return super.isCmdFieldVisible(field, args);
    }
  }

  getDetailFields(args?: any, allowNull: boolean = true): FormField[] {
    const fields: FormField[] = this.detailFields;
    const field: FormField = fields.find(t => t.key == 'replyBody');
    if (args?.[field.key]) {
      field.typeHtml = FormFieldTypeHtml.htmlView;
    }
    return fields;
  }

  // isFormFieldDisabled(field: FormField, args: any): boolean {
  //   switch (field.key) {
  //     case 'replyBody':
  //       if(args?.entity?.[field.key])
  //       {
  //         field.typeHtml = FormFieldTypeHtml.view;
  //       }
  //       return false;

  //     default:
  //       return super.isFormFieldDisabled(field, args);
  //   }
  // }

}

export class GenNotificaDetailEMC extends EntityManagerConfig {
  entityService: IEntityService<GenNotifica>;
  location: Location;
  router: Router;
  activatedRoute: ActivatedRoute;

  init() {
    this.clearCache = true;
    this.textComponent = {
      windowEditHeader: `${this.name}.windowdetailheader`
    };

    this.location = AppInjector.get(Location);

    this.entityService = this.getEntityService(this.name) as IEntityService<GenNotifica>;

    this.router = AppInjector.get(Router);
    this.activatedRoute = AppInjector.get(ActivatedRoute);
  }

  initFields() {
    this.cmdFields = [
      // Detail
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        label: 'generic.back', title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        entityManagerInfo: AppManagerRoutes.GenNotificaDetailReply,
        label: 'generic.reply', title: 'generic.reply', icon: Icons.reply, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },
    ];

    this.detailFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'dtIns', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'userIns', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'notificaTipoId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'notificaTipoInvioId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'utenteId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      {
        key: 'utenteFromEmail', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.view,
        label: `${this.name}.utentefromemail`, class: FormFieldStyle.default
      },
      // {
      //   key: 'notificaTipoInvioDes', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.view, label: `${this.name}.notificatipoinviodes`, class: FormFieldStyle.default
      // },
      {
        key: 'notificaTipoDes', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.view, label: `${this.name}.notificatipodes`, class: FormFieldStyle.default
      },
      {
        key: 'data', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.view, label: `${this.name}.data`, format: { format: dateFormat }, class: FormFieldStyle.default
      },
      {
        key: 'dataAccettazione', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.view, label: `${this.name}.dataaccettazione`, format: { format: dateTimeFormat }, class: FormFieldStyle.default
      },
      {
        key: 'flNotificaAccettata', type: FormFieldType.boolean, typeHtml: FormFieldTypeHtml.view, label: `${this.name}.flnotificaaccettata`, format: { icon: { false: `${Icons.envelope} text-lg danger`, true: `${Icons.envelopeOpen} text-lg text-600` } }, class: FormFieldStyle.default
      },
      {
        key: 'flrispostainviata', type: FormFieldType.boolean, typeHtml: FormFieldTypeHtml.view, label: `${this.name}.flrispostainviata`, format: { icon: { false: `${Icons.cross} text-lg text-600`, true: `${Icons.reply} text-lg text-600` } }, class: FormFieldStyle.default
      },
      {
        key: 'subject', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.htmlView,
        label: `${this.name}.subject`, placeholder: `${this.name}.subject`,
        class: FormFieldStyle.full
      },
      {
        key: 'body', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.htmlView,
        label: `${this.name}.body`, placeholder: `${this.name}.body`,
        class: FormFieldStyle.full
      },
      {
        key: 'replyBody', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.htmlView,
        label: `${this.name}.replybody`, placeholder: `${this.name}.replybody`,
        class: FormFieldStyle.full
      }
    ];
  }

  isFormFieldVisible(field: FormField, args: any): boolean {
    switch (field.key) {
      case 'replyBody':
        return args?.flRispostaInviata;
      case 'dataAccettazione':
        return args?.flNotificaAccettata;
      default:
        return true;
    }
  }

  onCmd(event: CmdEvent): boolean {
    switch (event.field.key) {
      case CmdFieldKey.back:
        this.location.back();
        return true;
      case CmdFieldKey.navigate:
        if (event.field.target == CmdFieldTarget.detailHeader) {
          this.router.navigate([AppManagerRoutes.Gestione.url, AppManagerRoutes.GenNotificaList.url, this.entity.id.toString(), AppManagerRoutes.GenNotificaDetail.url, event.field.entityManagerInfo.url]);
          // event will not be propagate forward
          return true;
        }
        return false;

      default:
        return super.onCmd(event);
    }
  }

}
