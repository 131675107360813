import { Entity } from "../entity";

export class GiudEsitoAssistentato extends Entity {
  cod: string;
  visible: boolean;
  system: boolean;

  des: string;
}

export enum EsitoAssistentatoCod {
  Superato = 'SUP',
  NonSuperato = 'NON_SUP'
}
