<ng-container *ngIf="T$ | async as T">
  <p-dialog [(visible)]="visible" [modal]="true" styleClass="modal-window-size-small" [resizable]="true"
    [closeOnEscape]="true" (onHide)="hideModal()">

    <ng-template pTemplate="header">
      <span class="p-dialog-title">
        {{T | translateItem: 'giudassistentato.assistentatoInsertModalTitle' }}
      </span>
    </ng-template>

    <ng-template pTemplate="content">
      <form [formGroup]="dataForm">
        <div class="grid p-fluid">
          <div class="col-12 md:col-6">
            <label class="text-sm required">{{T | translateItem: 'giudassistentato.prova'}}</label>
            <!-- <p-multiSelect [options]="richiestaDetailList$ | async" formControlName="richiestaDetailIds"
              [placeholder]="T | translateItem: 'giudassistentato.prova'" optionValue="id" optionLabel="label"
              optionDisabled="disabled" [emptyMessage]="T | translateItem: 'generic.dropdownEmpty'"
              [maxSelectedLabels]="1" [selectedItemsLabel]="T | translateItem: 'generic.selecteditems'" [filter]="true"
              [autofocusFilter]="true" [resetFilterOnHide]="true" [showToggleAll]="true" styleClass="p-multiselect-sm"
              appendTo="body" /> -->

            <p-dropdown [options]="richiestaDetailList$ | async" formControlName="richiestaDetailId"
              [placeholder]="T | translateItem: 'giudassistentato.prova'" optionValue="id" optionLabel="label"
              optionDisabled="disabled" [emptyMessage]="T | translateItem: 'generic.dropdownEmpty'" [filter]="true"
              [autofocusFilter]="true" [resetFilterOnHide]="true" styleClass="p-inputexts-sm" appendTo="body" />
          </div>

          <div class="col-12 md:col-6">
            <label class="text-sm required">{{T | translateItem: 'giudassistentato.giudice'}}</label>
            <p-autoComplete [suggestions]="giudiciSuggestions$ | async" formControlName="giudiceRaw" type="text"
              field="nomeCompleto" [lazy]="true" [minLength]="3"
              [placeholder]="T | translateItem: 'giudassistentato.giudice'" (completeMethod)="giudiceSearch($event)"
              class="p-inputtext-sm" />
          </div>

          <div class="col-12 md:col-6">
            <label class="text-sm required">{{T | translateItem: 'giudassistentato.dataInizio'}}</label>
            <p-calendar formControlName="caleManiDataInizio" [showIcon]="true" [dateFormat]="dateFormatPrNg"
              [firstDayOfWeek]="1" class="p-inputtext-sm"
              [placeholder]="T | translateItem: 'giudassistentato.dataInizio'" appendTo="body"
              [minDate]="dataInizioMin" />
          </div>

          <div class="col-12 md:col-6">
            <label class="text-sm required">{{T | translateItem: 'giudassistentato.dataFine'}}</label>
            <p-calendar formControlName="caleManiDataFine" [showIcon]="true" [dateFormat]="dateFormatPrNg"
              [firstDayOfWeek]="1" class="p-inputtext-sm" [placeholder]="T | translateItem: 'giudassistentato.dataFine'"
              appendTo="body" [minDate]="dataFineMin" />
          </div>

          <!--  Selettore manifestazioni -->
          <div class="col-12 w-full">
            <label class="text-sm required">{{T | translateItem: 'giudassistentato.caleMani'}}</label>
            <app-giud-assistentato-mani-selector formControlName="CaleManiSpGiudIds" [elaboration]="elaboration"
              [options]="maniSuggestions$ | async"
              [startMessage]="T | translateItem: 'giudassistentato.caleManiListStartMessage'"
              [emptyListMessage]="T | translateItem: 'giudassistentato.caleManiListEmptyListMessage'"
              [assistLimitReachedMessage]="T | translateItem: 'giudassistentato.assistLimitReachedMessage'"
              [assistAlreadyRequestedMessage]="T | translateItem: 'giudassistentato.assistAlreadyRequestedMessage'"
              [assistSameTimeMessage]="T | translateItem: 'giudassistentato.assistSameTimeMessage'"
              [assistAlreadyRequestedDayMessage]="T | translateItem: 'giudassistentato.assistAlreadyRequestedDayMessage'"
              [parteGiornoResources]="T" [giornoLabel]="T | translateItem: 'generic.giorno'" />
          </div>
        </div>
      </form>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="flex justify-content-end">
        <div class="buttons-cnt">
          <app-button size="medium" [outlined]="true" [disabled]="elaboration" (onClick)="hideModal()"
            [tooltip]="T | translateItem: 'generic.back'" [label]="T | translateItem: 'generic.back'"
            icon="fa-solid fa-xmark" />

          <app-button size="medium" [disabled]="elaboration || dataForm.pristine || !dataForm.valid" (onClick)="save()"
            [tooltip]="T | translateItem: 'generic.save'" [label]="T | translateItem: 'generic.save'"
            icon="fa-solid fa-floppy-disk" />
        </div>
      </div>
    </ng-template>
  </p-dialog>
</ng-container>
