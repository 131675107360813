<div *ngIf="lastMessage" class="w-full text-center p-3 flex align-items-center justify-content-between gap-2"
  [ngClass]="'badge-outlined-' + (lastMessage.severity | lowercase) + ' ' +  (lastMessage.severity | lowercase) + ' ' + styleClasses">
  <div class="flex align-content-center gap-2">
    <i *ngIf="lastMessage.icon" [ngClass]="lastMessage.icon" class="text-xl"></i>
    <span class="font-medium"> {{lastMessage.message}}</span>
  </div>

  <button pButton type="button" *ngIf="lastMessage.action" icon="fa-solid fa-chevron-right" iconPos="right"
    (click)="lastMessage.action()" class="p-button p-button-sm p-button-text"
    [ngClass]=" (lastMessage.severity | lowercase)"></button>
</div>