import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from "../../data-search";
import { Entity } from "../../entity";
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, CmdFieldStyle, Icons, FormFieldStyle, CmdField } from "../../entity-config";
import { AppManagerRoutes } from "../../../app-manager-routes";
import { Validators } from "@angular/forms";


// CalDefExpNum
export class CalDefSociFamiRazze extends Entity {
  enciIdAnag: number;
  razzaId: number;
  famiRazzeId?: number;
  famiRazzeCod: string;
  famiRazzeDes: string;
  socioDes: string;
  razzaDes: string;


  // sociDeleGruppi: VuxSociDeleGruppi;
  // famiRazze: CalFamiRazze;
  // razzeVarieta: VuxRazzeVarieta;
}

export class CalDefSociFamiRazzeEMC extends EntityManagerConfig {

  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  init() {

    this.textComponent = { windowListHeader: `${this.name}.windowListHeader` };
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('famiRazzeCod', SortMode.Asc)];

    const ds: DataSearch = new DataSearch();
    ds.dataSortValues = [new DataSortValue('razzaId', SortMode.Asc)];

    this.attributes['genrazzalang'] = this.getEntityService('genrazzalang').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // List
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger,
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save,
        class: CmdFieldStyle.btnPrimary,
      }
    ];

    this.listFields = [
      { key: 'id', type: FormFieldType.number, label: `${this.name}.id`, sortable: true, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'famiRazzeDes', type: FormFieldType.number, label: `${this.name}.famiRazzeDes`, sortable: true },
      { key: 'socioDes', type: FormFieldType.number, label: `${this.name}.socioDes`, sortable: true },
      { key: 'razzaDes', type: FormFieldType.number, label: `${this.name}.razzaDes`, sortable: true }
    ];

    this.detailFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'enciIdAnag', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'razzaId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'famiRazzeId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden },
      {
        key: 'objFamiRazze', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.modalListEditable,
        label: `${this.name}.famiRazzeDes`, placeholder: `${this.name}.famiRazzeDes`,
        options: {
          entityManagerInfo: AppManagerRoutes.CalFamiRazzeModal, title: `${this.name}.famiRazzeSelectionModalHeader`,
          dataLabel: 'des', fieldsBind: { famiRazzeId: 'id', famiRazzeDes: 'des' }, fieldsBindSearch: { id: 'hiddenId' },
          icon: Icons.lookup
        }, validators: [Validators.required]
      },
      {
        key: 'objSocio', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.modalList,
        label: `${this.name}.socioDes`, placeholder: `${this.name}.socioDes`,
        options: {
          entityManagerInfo: AppManagerRoutes.VuxSociDeleGruppiList, title: `${this.name}.socioSelectionModalHeader`,
          dataLabel: 'ragioneSociale', fieldsBind: { enciIdAnag: 'enciIdAnag', socioDes: 'ragioneSociale' }, fieldsBindSearch: { enciIdAnag: 'hiddenEnciIdAnag' },
          icon: Icons.lookup
        }, validators: [Validators.required]
      },
      {
        key: 'razzaId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.select,
        label: `${this.name}.razzaDes`,
        placeholder: `${this.name}.razzaDes`,
        options: {
          data: this.attributes['genrazzalang'],
          dataKey: 'razzaId', dataLabel: 'des'
        }
      }
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'famiRazzeDes,socioDes,razzaDes', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      },
    ];

    this.searchAdvancedFields = [
      {
        key: 'famiRazzeDes', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.famiRazzeDes`,
        options: { searchFields: 'famiRazzeDes', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'socioDes', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.socioDes`,
        options: { searchFields: 'socioDes', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'razzaDes', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.razzaDes`,
        options: { searchFields: 'razzaDes', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }

}
