import { Component, Input } from '@angular/core';
import { MenuItem, SortMeta } from 'primeng/api';
import { Observable, combineLatest, filter, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { BaseWindowListComponent } from 'src/app/components/base-window-list/base-window-list.component';
import { ComparisonOperator, SearchFieldType, SearchFieldsConf, SortMode } from 'src/app/models/data-search';
import { GiudRichiesta } from 'src/app/models/giud-richiesta/giud-richiesta';
import { GiudTipoRichiesta } from 'src/app/models/giud-richiesta/giud-tipo-richiesta';
interface abilitazione {
  cod: string;
  abilitato: boolean;
}

@Component({
  selector: 'app-giud-richiesta-list',
  templateUrl: './giud-richiesta-list.component.html',
  styleUrls: ['./giud-richiesta-list.component.scss']
})
export class GiudRichiestaListComponent extends BaseWindowListComponent<GiudRichiesta> {
  // Sorting
  defaultMultiSortMeta: SortMeta[] = [
    { field: 'id', order: SortMode.Asc }
  ];

  // Filters
  searchFieldsConf: SearchFieldsConf[] = [
    // Simple
    { key: 'giudiceId', searchFields: 'giudiceId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: false },
    { key: 'simpleSearch', searchFields: 'id,protocollo,giudiceNome,giudiceCognome,giudiceNomeCompleto,tipoRichiestaDes,subTipoRichiestaDes,statoRichiestaDes', type: SearchFieldType.multiField, searchCompOp: ComparisonOperator.Contains, advancedSearch: false },
    // Advanced
    { key: 'giudiceId', searchFields: 'giudiceId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'id', searchFields: 'id', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'protocollo', searchFields: 'protocollo', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
    { key: 'giudiceCognome', searchFields: 'giudiceCognome', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
    { key: 'giudiceNome', searchFields: 'giudiceNome', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
    { key: 'tipoRichiestaId', searchFields: 'tipoRichiestaId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true },
    { key: 'subTipoRichiestaId', searchFields: 'subTipoRichiestaId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true },
    { key: 'statoRichiestaId', searchFields: 'statoRichiestaId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true }
  ];

  appManagerRoutes: typeof AppManagerRoutes = AppManagerRoutes;

  // Add menu items
  addMenuItems$: Observable<MenuItem[]> = combineLatest([
    this.baseDataService.getGiudTipoRichiestaList(),
    this.appManagerService.utente$.pipe(
      filter((utente) => utente.livelloProfiloCod === 'GIUDICE'),
      switchMap((utente) => {
        return this.dataService.getGeneric(`${this.dataService.configSettings.restCommonUrl}/giudabilitazionerichiesta`, `giudiceId=${utente.enciIdAnag}`)
      })
    )
  ]).pipe(
    map(([list, abilitazioni]: [list: GiudTipoRichiesta[], abilitazioni: abilitazione[]]) => {
      return list.filter((v) => abilitazioni.some((a) => a.cod === v.cod && a.abilitato)).map((v) => ({
        label: v.des,
        title: v.des,
        routerLink: [AppManagerRoutes.GiudRichiestaNew.url], queryParams: { tipoRichiestaId: v.id, tipoRichiestaCod: v.cod }
      }))
    }),
    shareReplay()
  );

  get isTab(): boolean {
    return this.tabIndex != null && this.tabIndex != undefined;
  }

  @Input()
  giudiceId!: number;

  @Input()
  tabIndex!: number;

  @Input()
  readOnly: boolean = false;

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.restServiceUrl = this.dataService.configSettings.restCommonUrl;

    this.entityServiceUrl = AppManagerRoutes.GiudRichiestaList.name;

    super.ngOnInit();

    // Init filters
    this.initFilters();

    // Capisco in quale route mi trovo
    switch (this.entityManager.id) {
      case AppManagerRoutes.GiudSettings.id:
      case AppManagerRoutes.GiudDetail.id:
        this.persistFilters = false;

        this.defaultFilters = {
          giudiceId: this.giudiceId
        };

        this.baseWindowListService.setDefaultFilters(this.defaultFilters);
    }

    this.appManagerService.utente$.pipe(
      takeUntil(this.destroy$),
      tap((utente) => {
        if (utente.livelloProfiloCod === 'GIUDICE') {
          this.defaultFilters = { giudiceId: utente.enciIdAnag };
        }

        this.baseWindowListService.setDefaultFilters(this.defaultFilters);
      })
    ).subscribe();
  }

  delete(): void {
    // TODO
  }

  navigateToDetail(id: number): void {
    this.setStorageHilightedRowDataKey(id.toString());

    this.router.navigate(
      [id, AppManagerRoutes.GiudRichiestaDetail.url],
      { relativeTo: this.activatedRoute }
    );
  }

  navigateToNewAssistentato(id: number): void {
    this.setStorageHilightedRowDataKey(id.toString());

    this.router.navigate(
      [id, AppManagerRoutes.GiudAssistentatoNew.url],
      { relativeTo: this.activatedRoute }
    );
  }

  addEntity(): void {
    this.router.navigate(
      [AppManagerRoutes.GiudRichiestaNew.url],
      { relativeTo: this.activatedRoute }
    );
  }
}
