<div class="flex flex-column md:flex-row md:flex-wrap gap-2 mb-3 align-items-center">
  <div class="flex-grow-1">
    <div class="grid grid-nogutter p-fluid">
      <!-- Advanced search -->
      <ng-container *ngIf="advancedSearch; else simpleSearch">
        <ng-content select="[advanced]" />
      </ng-container>

      <!-- Simple search -->
      <ng-template #simpleSearch>
        <ng-content select="[simple]" />
      </ng-template>
    </div>
  </div>

  <div class="buttons-cnt" [ngClass]="{'buttons-cnt-end w-full': advancedSearch}">
    <app-button type="submit" [outlined]="true" size="medium" [icon]="searchButtonIcon" [tooltip]="searchButtonTooltip"
      [label]="searchButtonLabel" (onClick)="search()" [disabled]="elaboration" />

    <app-button [outlined]="true" size="medium" [icon]="resetButtonIcon" [tooltip]="resetButtonTooltip"
      [label]="resetButtonLabel" (onClick)="resetSearch()" [disabled]="elaboration" />

    <app-button [outlined]="true" size="medium"
      [icon]="advancedSearch ? toggleButtonSimpleIcon :toggleButtonAdvancedIcon"
      [tooltip]="advancedSearch ? toggleButtonSimpleLabel : toggleButtonAdvancedLabel"
      [label]="advancedSearch ? toggleButtonSimpleTooltip : toggleButtonAdvancedTooltip" (onClick)="toggleSearch()"
      [disabled]="elaboration" />
  </div>
</div>