<label class="text-sm">{{label}}</label>
<div class="font-medium mt-1" [ngSwitch]="layout">
  <ng-container *ngSwitchCase="'yesNo'">
    <app-yes-no [value]="$any(value)" [labels]="{yes: yesLabel, no: noLabel}" />
  </ng-container>

  <ng-container *ngSwitchCase="'date'">
    {{$any(value) | date: dateFormat | noValuePlaceholder}}
  </ng-container>

  <ng-container *ngSwitchDefault>
    {{value | noValuePlaceholder}}
  </ng-container>
</div>
