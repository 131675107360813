import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[requiredField]'
})
export class RequiredFieldDirective implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject();

  constructor(
    private el: ElementRef,
    private control: NgControl,
    private renderer: Renderer2
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.checkRequiredValidator();

    if (this.control.control) {
      this.control.control.statusChanges.pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.checkRequiredValidator();
      });
    }
  }

  private checkRequiredValidator(): void {
    const control = this.control.control;

    if (control) {
      const validator = control.validator ? control.validator({} as AbstractControl) : null;
      const isRequired = validator && validator.required;

      if (isRequired) {
        this.addRequiredClass();
      } else {
        this.removeRequiredClass();
      }
    }
  }

  private addRequiredClass(): void {
    const label = this.el.nativeElement.parentElement.querySelector('label');

    if (label) {
      this.renderer.addClass(label, 'required');
    }
  }

  private removeRequiredClass(): void {
    const label = this.el.nativeElement.parentElement.querySelector('label');

    if (label) {
      this.renderer.removeClass(label, 'required');
    }
  }
}
