import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { WindowSaveData } from '../../../guards/data-saved-guard';
import { IEntity } from '../../../models/entity';
import { CmdEvent, CmdField, CmdFieldKey, CmdFieldMode, CmdFieldTarget, EntityManager, FormField } from '../../../models/entity-config';
import { AppManagerService, SESSION_STORAGE_NAV } from '../../../services/app-manager.service';
import { EntityRefresh, IEntityService } from '../../../services/entity.service';
import { Resources, TranslateService } from '../../../services/translate.service';
import { EntityFormComponent } from '../../entity/entity-form/entity-form.component';
import { EntityListComponent } from '../../entity/entity-list/entity-list.component';


@Component({
  selector: 'window-list-detail-search',
  templateUrl: './window-list-detail-search.component.html',
  styleUrls: ['./window-list-detail-search.component.scss']
})
export class WindowListDetailSearchComponent implements WindowSaveData, OnInit, OnDestroy {
  cmdFieldTarget = CmdFieldTarget;
  cmdFieldMode = CmdFieldMode;
  cmdFieldKey = CmdFieldKey;

  private entityService: IEntityService<IEntity>;
  entityRefresh$: Observable<EntityRefresh>;
  dataForm: FormGroup;
  detailFields: FormField[];
  entity: IEntity;
  textHeader: string;
  textHeaderDetail: string;
  entityInfo: string;

  // translateItems: Resources;
  translatedItems$: Observable<Resources>;

  @Input() entityManager: EntityManager;
  @Input() dataSearch: any;
  @Output() selectEvent = new EventEmitter<CmdEvent>();
  // @Output() showEvent = new EventEmitter<CmdEvent>();

  @ViewChild(EntityListComponent) entityListComponent;
  @ViewChild(EntityFormComponent) entityFormComponent;

  private subscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appManagerService: AppManagerService,
    private translateService: TranslateService

  ) {
    this.subscription = new Subscription();
    this.dataForm = new FormGroup({});
  }

  ngOnDestroy(): void {
    if (this.entityManager.getClearCache()) {
      this.appManagerService.clearStorageData(SESSION_STORAGE_NAV, this.entityManager.id);
    }
    this.appManagerService.clearBrowserTab();
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.appManagerService.initialized$
        .subscribe(appInitialized => {
          if (!appInitialized.initialized) {
            return EMPTY;
          }

          if (!this.entityManager) {
            this.entityManager = this.appManagerService.getEntityManager(this.activatedRoute);
          }

          this.textHeader = this.entityManager.getTextComponent("windowListHeader");
          this.textHeaderDetail = this.entityManager.getTextComponent("windowDetailHeader");
          if (this.dataSearch) {
            this.entityManager.setEntitySearch(this.dataSearch);
          }
          this.entityInfo = this.dataSearch ?? this.entityManager.getEntity();
          this.entityService = this.entityManager.getEntityService();
          this.entityRefresh$ = this.entityService.entityRefresh$;

          this.listenToEntities();

          this.translatedItems$ = this.translateService.translatedItems$(this.entityManager.translateSuffixs);
        })
    );

    // this.subscription.add(
    //   this.appManagerService.initialized$
    //   .pipe(
    //     switchMap(appInitialized => {
    //       if (!appInitialized.initialized) {
    //         return EMPTY;
    //       }

    //       if (!this.entityManager) {
    //         this.entityManager = this.appManagerService.getEntityManager(this.activatedRoute);
    //       }

    //       this.textHeader = this.entityManager.getTextComponent("windowListHeader");
    //       this.textHeaderDetail = this.entityManager.getTextComponent("windowDetailHeader");
    //       if(this.dataSearch){
    //         this.entityManager.setEntitySearch(this.dataSearch);
    //       }
    //       this.entityInfo = this.dataSearch ?? this.entityManager.getEntity();
    //       this.entityService = this.entityManager.getEntityService();
    //       this.entityRefresh$ = this.entityService.entityRefresh$;

    //       this.listenToEntities();

    //       return this.translateService.translatedItems$(this.entityManager.translateSuffixs);
    //     })
    //   )
    //   .subscribe(translateItems => {
    //     this.translateItems = translateItems;

    //     // if (this.showEvent.observers.length > 0) {
    //     //   this.showEvent.emit({
    //     //     field: { key: CmdFieldKey.none, mode: CmdFieldMode.none, target: CmdFieldTarget.none },
    //     //     data: { translations: items }
    //     //   });
    //     // }

    //   })
    // );

    this.entityManager.isCompleted = () => {
      if (!this.entityFormComponent) {
        return false;
      }
      return this.dataForm.pristine;
    }

  }

  onCmd(field: CmdField) {
    if (this.entityManager.onCmd({ field: field, data: this.entity })) return;

    switch (field.key) {
      case CmdFieldKey.back:
        this.reset();
        break;

      case CmdFieldKey.cancel:
        this.reset();
        break;

      case CmdFieldKey.save:
        this.save(field);
        break;

    }
  }

  onAddEntity(event: CmdEvent) {
    this.entity = { ...this.entityManager.getEntity() };
    this.detailFields = this.entityManager.getDetailFields(this.entity);
  }

  onEditEntity(event: CmdEvent) {
    this.entity = event.data;
    this.detailFields = this.entityManager.getDetailFields(this.entity);
  }

  onSelectEntity(event: CmdEvent) {
    if (this.selectEvent.observers.length > 0) {
      this.selectEvent.emit(event);
    }
  }

  private reset() {
    if (this.entity) {
      this.dataForm.reset();
      this.dataForm.patchValue(this.entityManager.getEntity());
      this.entity = null;
    }
  }

  private save(field: CmdField) {
    this.entity = this.entityFormComponent.getData();

    if (!this.entity.id) {
      // add new entity item into database
      this.entityService.insertEntity(field, this.entity);
    }
    else {
      // update existing entity into database
      this.entityService.updateEntity(field, this.entity);
    }
  }

  private listenToEntities() {
    this.subscription.add(this.entityService.entityEvent$
      .subscribe(
        (entityEvent) => {
          this.reset();
        }
        // ,(error: ResultMessage) => {
        //   if(error){
        //     const messageData = buildFromResultMessage(error, MessageLevel.Error);
        //     this.appManagerService.showMessage(messageData);
        //   }
        // }
      ));
  }

  onDeleteEntities(event: CmdEvent) {
    let ids = event.data.map(t => t.id);
    this.entityService.deleteEntities(event.field, ids);
  }

  onPrintEntities(event: CmdEvent) {
    let ids = event.data.map(t => t.id);
    this.subscription.add(this.entityService.printEntities(event.field, ids)
      .subscribe(
        (file: File) => {
          // this.fileSaverService.save(file, file.name);
        },
        error => {
          // this.messageDataService.open(
          //   this.modalMessageDataId, {
          //   mode: 'close', error: error, title: 'error_generic', classTitle: 'msg-error'
          // }
          // );
        }
      ));
  }

  onReset() {
    this.dataForm.reset();
  }

  onSearch(event: CmdEvent) {
    this.entityListComponent.search(event.data.data, event.data.advancedSearch)
  }

}
