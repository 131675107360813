import { Observable, Subject } from "rxjs";
import { CmdFieldStyle, Icons } from "./entity-config";

export interface ResultMessage {
  level: MessageLevel;
  message: string;
  title: string;
  data: any;
}

export enum MessageLevel {
  Exception = 1,
  Error,
  Warning
  // Info
}

export enum MessageMode {
  None = 0,
  Close,
  OkKo
}

export type MessageOptions = {
  btnLabelClose?: string;
  btnLabelOk?: string;
  btnLabelKo?: string;
  btnIconOk?: string;
  btnIconKo?: string;
  btnClassOk?: string;
  btnClassKo?: string;
  notTranslateTitle?: boolean;
  notTranslateMessage?: boolean;
};

export type MessageDataConfig = {
  mode: MessageMode,
  level: MessageLevel,
  message: string,
  title: string,
  options?: MessageOptions
}

export const MessageDataConfig: { [key: string]: MessageDataConfig } = {
  Delete: {
    mode: MessageMode.OkKo,
    level: MessageLevel.Error,
    message: 'generic.deleteMsg',
    title: 'generic.delete',
    options: {
      btnClassOk: CmdFieldStyle.btnPrimaryDanger, btnIconOk: Icons.delete, btnLabelOk: 'generic.delete'
    }
  },
  UnsavedData: {
    mode: MessageMode.OkKo,
    level: MessageLevel.Warning,
    message: 'generic.unsavedData',
    title: 'generic.warning'
  }
}

export class MessageData {
  mode: MessageMode;
  level: MessageLevel;
  message: string;
  data: any;
  options: MessageOptions;

  hEventClose?: (data?: any) => any;
  hEventOk?: (data?: any) => any;
  hEventKo?: (data?: any) => any

  private eventSubject: Subject<string>;
  get event$(): Observable<string> {
    return this.eventSubject.asObservable();
  }

  notify(event: string) {
    this.eventSubject.next(event);
  }

  private _title?: string;
  set title(val: string) {
    this._title = val;
  }
  get title(): string {
    if (this._title) {
      return this._title;
    }

    switch (this.level) {
      case MessageLevel.Exception:
      case MessageLevel.Error:
        this._title = 'generic.error.title';
        break;

      case MessageLevel.Warning:
        this._title = 'generic.warning.title';
        break;

      // case MessageLevel.Info:
      //   this._title = 'generic.info.title';
      //   break;

    }
    return this._title;
  }

  private _classTitle: string;
  set classTitle(val: string) {
    this._classTitle = val;
  }
  get classTitle(): string {
    if (this._classTitle) {
      return this._classTitle;
    }

    switch (this.level) {
      case MessageLevel.Exception:
      case MessageLevel.Error:
        this._classTitle = 'danger';
        break;

      case MessageLevel.Warning:
        this._classTitle = 'warning';
        break;

      // case MessageLevel.Info:
      //   this._classTitle = 'info';
      //   break;

    }
    return this._classTitle;
  }

  private _classMessage?: string;
  set classMessage(val: string) {
    this._classMessage = val;
  }
  get classMessage(): string {
    if (this._classMessage) {
      return this._classMessage;
    }

    switch (this.level) {
      case MessageLevel.Exception:
      case MessageLevel.Error:
        this._classMessage = 'danger';
        break;

      case MessageLevel.Warning:
        this._classMessage = 'warning';
        break;

      // case MessageLevel.Info:
      //   this._classMessage = 'info';
      //   break;

    }
    return this._classMessage;
  }

  constructor(mode: MessageMode, level: MessageLevel, message: string, title?: string, data?: any, options?: MessageOptions) {
    this.mode = mode;
    this.level = level;
    this.message = message;
    this._title = title;
    this.data = data;
    this.options = options;

    this.eventSubject = new Subject<string>();
  }

}

export function buildFromResultMessage(resultMessage: ResultMessage, messageLevel: MessageLevel): MessageData {
  return new MessageData(
    MessageMode.Close,
    messageLevel,
    resultMessage?.message ?? 'generic.error.message',
    resultMessage?.title ?? 'generic.error.title',
    resultMessage?.data
  );
}

export class MessageDataFromConfig extends MessageData {
  constructor(config: any, hEventOk?: (data?: any) => any, hEventKo?: (data?: any) => any) {
    super(config.mode, config.level, config.message, config.title, config.args, config.options);
    this.hEventOk = hEventOk;
    this.hEventKo = hEventKo;
  }
}

export class ToastMessageData {
  severity: string;
  summary: string;
  closable: boolean;
  sticky: boolean;
  life: number;

  constructor(severity: string, summary: string, closable?: boolean, sticky?: boolean, life?: number) {
    this.severity = severity;
    this.summary = summary;
    this.closable = closable ?? true;
    this.sticky = sticky ?? false;
    this.life = life ?? 2999;
  }

}
