<span [class]="styleClass" class="field flex flex-row">
  <span class="block p-input-icon-right flex-grow-1">
    <!-- fieldValue: <pre>{{fieldValue}}</pre> -->

    <input pInputText type="text" class="inputfield p-inputtext-sm" [attr.disabled]="true" [placeholder]="fieldValue">
    <i [class]="icon" *ngIf="icon && (!disabled)" (click)="enableEdit()"></i>
    <!-- [formControlName]="formControlName" -->

    <!-- <span>      
      <label  class="field flex flex-row inputfield p-inputtext-sm">{{fieldValue}}</label>  
    </span>
    <span>
      <i [class]="icon" *ngIf="icon && (!disabled)" (click)="setEditable(true)"></i>
    </span> -->
  </span>
  <span *ngIf="required" class="ml-1 required"></span>
</span>

<p-dialog *ngIf="editable && translatedItems" [modal]="true" [header]="translatedItems | translateItem: modalHeader"
  [styleClass]="modalClass" [resizable]="modalResizable" [closeOnEscape]="modalCloseOnEscape" [(visible)]="editable"
  (onHide)="hide()">

  <window-list-editable-search [entityManager]="entityManager" (selectEvent)="selectEvent($event)">
  </window-list-editable-search>
  <!-- [dataSearch]="dataSearch" -->
</p-dialog>