import { AppManagerRoutes } from "../../app-manager-routes";
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, EntityManagerConfig, CmdField, } from "../entity-config";

export class DtoNormSearch {
  normSearchRows: DtoNormSearchRow[] = [];
  pageFirst: number;
  pageSize: number;
  //dataSortValues: DataSortValue[] = [];
}

export class DtoNormSearchRow {
  flNomeCognome: boolean;
  nomeCognome: string;
  flCodiceFiscale: boolean;
  CodiceFiscale: string;
  flIndirizzo: boolean;
  indirizzo: string;
  flTelefono: boolean;
  telefono: string;
  flDtNascita: boolean;
  dtNascita: string;
  flLocalita: boolean;
  localita: string;
  flProvincia: boolean;
  provincia: string;
}

export class DtoAllAnagraficheEnci {
  anagraficaEnci: AnagraficaEnci;
  socioIndirizzo: SocioIndirizzo;
  espertoEnci: EspertoEnci;
  proprietarioEnci: ProprietarioEnci;
}

export class DettagliAnagraficaNormalizzataDraft {

  idsAnagraficheToMerge: number[];

  tessere: Tessera[];
  aggregazioni: Tessera[];
  activePanelAggregazioni: boolean;
  activePanelTessere: boolean;

  abilitazioni: EspertoAbilitazione[];
  activePanelAbilitazioni: boolean;
  activePanelProvvedimenti: boolean;

  cani: Cane[];
  denunce: Denuncia[];
  activePanelCani: boolean;
  activePanelDenunce: boolean;
}

export class AnagraficaEnci {
  idAnagrafi: number;
  nome: string;
  indirizzo1: string;
  localita1: string;
  cap1: string;
  provincia1: string;
  telefono1: string;
  telefono1bis: string;
  cdNazione1: string;
  fax1: string;
  flDeceduto: string;
  dataDecesso?: Date;
  dataNascita?: Date;
  codiceFiscale: string;
  partitaIva: string;
  tpAnagrafi: string;
  note: string;
  pratica: string;
  flProprie: string;
  flEsperto: string;
  flSocio: string;
  nomeIdx: string;
  presso: string;
  email: string;
  sesso: string;
  luogoNascita: string;
  lorzaturaCodfisc: string;
  flAt: string;
  numeAlleEste: string;
  codiceFiscaleEstero: string;
  flAllevamento: string;
  flConsensoRecapiti: string;
  dataPrivacy?: Date;
  flConsensoInvioInfo: string;
  flConsensoImmagini: string;
  flConsensoMarketing: string;
  flFirmaPrivacy: string;
  datainse?: Date;
  userinse?: number;
  datamodi?: Date;
  usermodi?: number;

  flAnagPrincipale: boolean;
  flConsideraPerNorm: boolean;

  collapsedAnagrafi: boolean;  //inizializzo a true perchè vogliono che le anagrafiche arrivino su tutte chiuse

  isAnagAllevamento: boolean;

  hasAffissi: boolean;
  affissi: Affisso[];
  activePanelAffissi: boolean;
}

export class SocioEnci {
  idSocio?: number;
  idAnagrafi?: number;
  idSocioprinci?: number;
  tpSocio: string;
  tpSociocolle: string;
  flOnorario: string;
  dataInizio?: Date;
  dataFine?: Date;
  dataDomanda?: Date;
  stDomanda: string;
  dataApprovazione?: Date;
  nrPratica: string;
  note: string;
  datainse?: Date;
  userinse?: number;
  datamodi?: Date;
  usermodi?: number;
  flFondatore: string;
  passata?: number;
  flAutorDati: string;
  dataAutorDati?: Date;
  nrTesseraAppo?: number;
  indirizzo2: string;
  localita2: string;
  cap2: string;
  provincia2: string;
  telefono2: string;
  telefono2bis: string;
  cdNazione2: string;
  fax2: string;

}

export class SocioIndirizzo {
  idAnagrafi?: number;
  indirizzo2: string;
  localita2: string;
  cap2: string;
  provincia2: string;
  telefono2: string;
  telefono2bis: string;
  cdNazione2: string;
  fax2: string;
  datainse?: Date;
  userinse?: number;
  datamodi?: Date;
  usermodi?: number;
  flProvvedimenti: string;
  noteProvvedimenti: string;

  hasAggregazioni: boolean;
  hasTessere: boolean;

  tessere: Tessera[];
  aggregazioni: Tessera[];
  activePanelAggregazioni: boolean;
  activePanelTessere: boolean;
  activePanelProvvedimenti: boolean;

  flMantieniScioIndirizzo: boolean;

  grCinAnnoList: GrCinAnno[];  //per verifica stop normalizzazione
}

export class GrCinAnno {
  idSocioPrinci: number;
  anno: number;
}

export class EspertoEnci {

  idEsperto: number;
  indirizzo2: string;
  localita2: string;
  cap2: string;
  provincia2: string;
  telefono2: string;
  telefono2bis: string;
  cdNazione2: string;
  fax2: string;
  note: string;
  flIterincorso: string;
  flAbilitato: string;
  flSospeso: string;
  dataIniziososp?: Date;
  dataFinesosp?: Date;
  idProprie?: number;
  flProvvedimenti: string;
  noteProvvedimenti: string;
  flAspirante: string;
  datainse?: Date;
  userinse?: number;
  datamodi?: Date;
  usermodi?: number;

  hasAbilitazioni: boolean;

  abilitazioni: EspertoAbilitazione[];
  activePanelAbilitazioni: boolean;
  activePanelProvvedimenti: boolean;

  flMantieniEsperto: boolean;
}

export class ProprietarioEnci {
  idAnagrafi?: number;
  indirizzo2: string;
  localita2: string;
  cap2: string;
  provincia2: string;
  telefono2: string;
  telefono2bis: string;
  cdNazione2: string;
  fax2: string;
  note: string;
  datainse?: Date;
  userinse?: number;
  datamodi?: Date;
  usermodi?: number;
  flAllevatore: string;

  hasCani: boolean;
  hasDenunce: boolean;
  hasDenunciaPiuRecente: boolean;

  cani: Cane[];
  denunce: Denuncia[];
  activePanelCani: boolean;
  activePanelDenunce: boolean;

  flMantieniProprietario: boolean;

}

export class Cane {
  idCane?: number;
  idDelegazione?: number;
  idProprie?: number;
  idAllevatore?: number;
  idAllevatoreEst?: number;
  llCane?: string;
  nomeCane: string;
  annoElabor: number;
  cdNazione: string
  chip: string;
  codiceEstero: string;
  dataDeceduto?: Date;
  dataImport?: Date;
  dataIscrizione?: Date;
  dataNascita?: Date;
  dataPostale?: Date;
  dataRegistrazione?: Date;
  dataRipSel?: Date;
  dataStaori?: Date;
  dataStaoriSing?: Date;
  dataStaprovvi?: Date;
  datasbloc?: Date;
  dsMantello: string;
  dsMantelloAgg: string;
  fistolaDermoide: string;
  flCertVeterFistola: string;
  flCertificatoTipicita: string;
  flConfermata: string;
  flCopiaCarnetGiudizio: string;
  flCopiaCatalogoEsposizione: string;
  flDeceduto: string;
  flDocIdentita: string;
  flDuplicato: string;
  flMarca: string;
  flModificaXPedigree: string;
  flOriginali: string;
  flOriginaliSing: string;
  flPostale: string;
  flProvaLavoroRazza: string;
  flRientroPedigree: string;
  flRiproduttore: string;
  flRiproduttoreSelezionato: string;
  flRistampa: string;
  flStaori: string;
  flagCertifMicrochip: string;
  flagNochipNotatu: string;
  flagOmessoExport: string;
  flagPraticaBloccata: string;
  flagSbloccoRadiato: string;
  flagVerificaRispond: string;
  idDenuncia?: number;
  idFatt?: number;
  idMarca: null
  idRazza: string;
  idStall?: number;
  idStatoModb?: number;
  idVarieta: null
  llFatt: string;
  llStall: string;
  misura: string;
  nomeCaneOriginale: string;
  nomeIdx: string;
  note: string;
  nrElabor?: number;
  nrMantello?: number;
  numPostale: string;
  ridge: string;
  sesso: string;
  terminale: null
  tpArrivo: string;
  tpCane: string;
  usersbloc?: number;

  datainse?: Date;
  userinse?: number;
  datamodi?: Date;
  usermodi?: number;

  razzaDesc: string;
}

export class Denuncia {

  idDenuncia: number;
  idDelegazione: number;
  idProprieFatt: number;
  idProprieStall: number;
  dataDenuncia: Date;
  tpCane: string;
  tpIscrizione: string;
  idFatt: number;
  idStall: number;
  dataMonta: Date;
  dataNascita: Date;
  flControllo: string;
  totNati: number;
  totMorti: number;
  maschiVivi: number;
  femmineVivi: number;
  maschiIscri: number;
  femmineIscri: number;
  flModb: string;
  flAnomalia: string;
  flGenealogiaEst: string;
  datainse: Date;
  userinse: number;
  datamodi: Date;
  usermodi: number;
  dataRegistrazione: Date;
  tipoSocio: string;
  flagDaValidareAmm: string;
  flagDaValidareUgr: string;
  flagFirma: string;
  flagFirmaIscr: string;
  flagEsitoCtrl: string;
  flagInserdenuncia: string;
  flagInseriscriz: string;
  flagFirmaStall: string;
  flagFirmaIscrStall: string;
  flagPrivacy: string;
  flagFirmaDelegazione: string;
  flagPrivacyIscr: string;
  flagFirmaDelegazioneIscr: string;
  flagCertifStall: string;
  flagMontaFortuita: string;
  flagSbloccoRadiato: string;
  flagInseminazione: string;
  flagStazioneMonta: string;
  flagPraticaBloccata: string;
  flDocIdentita: string;
  flagNoPivaRicevutaDmn: string;
  flagNoPivaRicevutaIscr: string;
  flagAuthFinicommercialiDmn: string;
  flagAuthFinicommercialiIscr: string;
  flPostale: string;
  flModificaXPedigree: string;
  numPostale: string;
  dataPostale: Date;
  flCertIdoneitaRiprod: string;
  flCertIdoneitaRiprodStall: string;
  coiInbreeding: number;
  idVarietaFattAuto: string;
  idVarietaStalAuto: string;
  idStatoModa: number;
  flSaltaControlloFatt: string;
  flSaltaControlloStall: string;
  flSaltaCtrlInbreeding: string;

  llCaneFattrice: string;
  nomeCaneFattrice: string;
  razzaDescFattrice: string;
  llCaneStallone: string;
  nomeCaneStallone: string;
  razzaDescStallone: string;

  activePanelCaniDenuncia: boolean;

  cani: Cane[];
}

export class Tessera {
  idSocio: number;
  anno: number;
  nrTessera: number;
  quota: number;
  dataPagamento: Date;
  dataRegistrazionepg: Date;
  dataRegistrazionets: Date;
  dataConsegna: Date;
  dataScadenza: Date;
  datainse: Date;
  userinse: number;
  datamodi: Date;
  usermodi: number;

  nomeClub: string;
}

export class EspertoAbilitazione {
  idPratica: number;
  idEsperto: number;
  idAbilitazione: string;
  ampRichiesto: number;
  dataAbilitazione: Date;
  dataRichiesta: Date;
  stAbilitazione: string;
  stPratica: string;
  dataApproaspirante: Date;
  flRazza: string;
  note: string;
  dataStabilitazione: Date;
  tpEsperto: string;
  datainse: Date;
  userinse: number;
  datamodi: Date;
  usermodi: number;
  flAbilitatoCac: string;

  abilitazioneDesc: string;
}

export class Affisso {
  idAffisso: number;
  dsAffisso: string;
  tpAffisso: string;
  dataAttribuzione: Date;
  dataInizio: Date;
  dataDecadenza: Date;
  dataInizioSospensione: Date;
  dataFineSospensione: Date;
  flFondatore: string;
  flFirmatario: string;
  flSecondoTitolare: string;
}

export class NormControlliAnag {
  idAnag: number
  principale: boolean
}

export class NormControlloRet {
  tipoControllo: string;
  modalitaSegnalazione: string;    //WARN, ERR
  segnalazione: string;
}

export class LogAnagNormMaster {
  id: number;
  dataNorm: Date;
  username: string;
  email: string;
  idAnagrafi: number;
  idAnagrafiNorm: string;
  req: string;
  res: string;
  esito: boolean;
  err: string;
  desAnagrafi: string;
  desUsername: string;
  desEsito: string;

  logOperationsDetails: LogAnagNormDett[];
}

export class LogAnagNormDett {
  id: number;
  tmst: Date;
  step: string;
}

export class RetMerge {
  anagFinale: DtoAllAnagraficheEnci;
  logMaster: LogAnagNormMaster;
  ex: string;
}

export class AnagNormProposte {
  tipo: string;
  valore: string;
  conteggio: number;
  score: number;
  jsonSearch: string;
  codTipo: string;
}

export class NormalizzazioneEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.NormSearchList, label: 'menu.normsearchlist.label', title: 'menu.normsearchlist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.NormLogList, label: 'menu.normloglist.label', title: 'menu.normloglist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.NormProposteList, label: 'menu.normpropostelist.label', title: 'menu.normpropostelist.title'
      }
    ];
  }
}


