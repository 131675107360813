import { CmdField, EntityManagerConfig } from "../entity-config";

export class ManualiEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      // Add here
    ];
  }
}
