import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { EntityManager } from 'src/app/models/entity-config';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { BaseDataService } from 'src/app/services/base-data.service';
import { BaseWindowListService } from 'src/app/services/base-window-list.service';
import { DataService } from 'src/app/services/data.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-giud-richiesta-list-search',
  template: ''
})
export class BaseWindowListSearchComponent implements OnInit, OnDestroy {
  protected destroy$: Subject<void> = new Subject();

  private _advancedSearch: boolean = this.baseWindowlistService.getAdvancedSearch();

  set advancedSearch(val: boolean) {
    this._advancedSearch = val;
    this.baseWindowlistService.setAdvancedSearch(val);
  }

  get advancedSearch() {
    return this._advancedSearch;
  }

  entityManager: EntityManager = this.appManagerService.getEntityManager(this.activatedRoute);

  // Etichette
  T$: Observable<Resources> = this.translateService.translatedItems$(this.entityManager.translateSuffixs);

  // Form
  dataForm: FormGroup;

  @Input()
  elaboration: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appManagerService: AppManagerService,
    protected baseWindowlistService: BaseWindowListService,
    protected translateService: TranslateService,
    protected fb: FormBuilder,
    protected baseDataService: BaseDataService,
    protected dataService: DataService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    const value = this.baseWindowlistService.getSearchFilters();
    this.dataForm.patchValue(value);
  }

  search(): void {
    this.baseWindowlistService.setSearchFilters(this.dataForm.value);
  }

  resetSearch(): void {
    const defaultFilters = this.baseWindowlistService.getDefaultFilters();
    this.dataForm.reset(defaultFilters);
    this.baseWindowlistService.reset(true);
  }
}
