<ng-container *ngIf="translatedItems$ | async as translateItems">
    <div class="flex flex-column h-full">
        <div>
            <div class="flex flex-row justify-content-between">
                <p *ngIf="textHeader" class="text-header"
                    [innerHtml]="translateItems | translateItem: textHeader: entityInfo">
                </p>
                <div *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.containerHeader, fieldType:cmdFieldMode.btn}) as cmdFields"
                    class="buttons-cnt">
                    <ng-container *ngFor="let field of cmdFields">
                        <button pButton [type]="field.type || 'button'" [class]="field.class"
                            *ngIf="entityManager | isCmdFieldVisible:field"
                            [disabled]="(entityManager | isCmdFieldDisabled:field:{field: field})"
                            (click)="onCmd(field)" [icon]="field.icon"
                            [title]="translateItems | translateItem: field.title"
                            [label]="translateItems | translateItem: field.label">
                        </button>
                    </ng-container>
                </div>
            </div>

            <p-divider></p-divider>
        </div>
        <div class="flex-grow-1 overflow-y-auto align-content-start page-detail-container">

            <app-entity-search [entityManager]="entityManager" [dataSearch]="dataSearch"
                (searchEvent)="onSearchEvent($event)" class="mb-3">
            </app-entity-search>

            <ng-content #headerContent></ng-content>

            <p-tabView #tabview *ngIf="tabList.length > 0" (onChange)="onChangeTab($event)">
                <p-tabPanel [header]="translateItems | translateItem: tab.headerTextKey" class="h-full"
                    *ngFor="let tab of tabList; let i = index" [selected]="tabIndex == i">
                    <div class="mb-3">
                        <app-entity-search [entityManager]="tab.entityManager" (searchEvent)="onSearchTab(tab, $event)">
                        </app-entity-search>
                    </div>

                    <ng-container [ngSwitch]="tab.field.key">
                        <ng-template pTemplate="content" *ngSwitchCase="cmdFieldKey.mngList">
                            <div class="panel-height">
                                <entity-list [entityManager]="tab.entityManager" [dataSearch]="tab.dataSearch">
                                </entity-list>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content" *ngSwitchCase="cmdFieldKey.mngListEditable">
                            <div class="panel-height">
                                <entity-list-editable [entityManager]="tab.entityManager" [dataSearch]="tab.dataSearch"
                                    (deleteEvent)="onDeleteEntities(tab, $event)">
                                </entity-list-editable>
                            </div>
                        </ng-template>

                    </ng-container>

                </p-tabPanel>
            </p-tabView>

            <!-- <div class=" modal-spinner" *ngIf="entityRefresh$ | async | refreshing">
                            <p-progressSpinner></p-progressSpinner>
                    </div> -->
        </div>
    </div>
</ng-container>