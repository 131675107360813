import { AppManagerRoutes } from "../../app-manager-routes";
import { CmdField, CmdFieldKey, CmdFieldMode, CmdFieldTarget, EntityManagerConfig } from "../entity-config";

export class CalendarioEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleList, label: 'menu.calcalelist.label', title: 'menu.calcalelist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleManiWizard, label: 'menu.calcalemaniwizard.label', title: 'menu.calcalemaniwizard.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleManiList, label: 'menu.calcalemanilist.label', title: 'menu.calcalemanilist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleManiExpoList, label: 'menu.calcalemaniexpolist.label', title: 'menu.calcalemaniexpolist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleManiProvList, label: 'menu.calcalemaniprovlist.label', title: 'menu.calcalemaniprovlist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleManiRadList, label: 'menu.calcalemaniradlist.label', title: 'menu.calcalemaniradlist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleManiSpecAsList, label: 'menu.calcalemanispecaslist.label', title: 'menu.calcalemanispecaslist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleManiSpecList, label: 'menu.calcalemanispeclist.label', title: 'menu.calcalemanispeclist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleGestSpecList, label: 'menu.calcalegestspeclist.label', title: 'menu.calcalegestspeclist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleGestSpecPropList, label: 'menu.calcalegestspecproplist.label', title: 'menu.calcalegestspecproplist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleGestSpecRichList, label: 'menu.calcalegestspecrichlist.label', title: 'menu.calcalegestspecrichlist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleEventoList, label: 'menu.calcaleeventolist.label', title: 'menu.calcaleeventolist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleManiAnomList, label: 'menu.calcalemanianomlist.label', title: 'menu.calcalemanianomlist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleManiAnomVarieList, label: 'menu.calcalemanianomvarielist.label', title: 'menu.calcalemanianomvarielist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleManiAnomView, label: 'menu.calcalemanianomview.label', title: 'menu.calcalemanianomview.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleManiVerificaList, label: 'menu.calcalemaniverificalist.label', title: 'menu.calcalemaniverificalist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleUtils, label: 'menu.calcalegenutilities.label', title: 'menu.calcalegenutilities.title'
      }
    ];
  }
}
