import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from "../data-search";
import { Entity } from "../entity";
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, CmdFieldStyle, Icons, CmdField, FormFieldStyle } from "../entity-config";
import { AppManagerRoutes } from "../../app-manager-routes";
import { Validators } from "@angular/forms";
import { optionsSINO } from "src/app/utils/util";
import { SecProfilo } from "../security/sec-profilo";

export class GenNotificaTipo extends Entity {
  tabella?: string;
  cod: string;
  visible: boolean;
  system: boolean;
  generica: boolean;
  sezione: string;

  des: string;
  tipoInvioCod: string;
  notificaTipoInvioCod: string;
  notificaTipoInvioId: number;
  notificaTipoInvioDes: string;
  secProfiloList: SecProfilo[];
}

export class GenNotificaTipoLang extends Entity {
  notificaTipoId: number;
  lang: string
  des: string;
  emailSubject: string;
  emailBody: string;
  piattMsg: string;

  langDes: string;
}

export class GenNotificaTipoEMC extends EntityManagerConfig {

  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = {
      windowListHeader: `${this.name}.windowlistheader`
    };
    this.dataSortValue = [new DataSortValue('cod', SortMode.Asc)];

    this.attributes['gensezione'] = AppManagerRoutes.getSezioneList();

    const ds: DataSearch = new DataSearch();
    ds.dataSearchValues = [new DataSearchValue([true], ['visible'], ComparisonOperator.Equals)];
    ds.dataSortValues = [new DataSortValue('des', SortMode.Asc)];

    this.attributes['gennotificatipoinvio'] = this.getEntityService('gennotificatipoinvio').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchReset',
        title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // Editable
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader, entityManagerInfo: AppManagerRoutes.GenNotificaTipoNew,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary,
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody, entityManagerInfo: AppManagerRoutes.GenNotificaTipoEdit,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: `${this.name}.GenNotificaTipoLang`, icon: Icons.message, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenNotificaTipoLangList, fieldsBind: { id: 'notificaTipoId' }, fieldsBindSearch: { id: 'notificaTipoId' }
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger,
      },

      // Detail
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        label: 'generic.back', title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        label: 'generic.save', title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'notificaTipoInvioId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'cod', type: FormFieldType.string, label: `${this.name}.cod`, sortable: true },
      {
        key: 'generica', type: FormFieldType.boolean, label: `${this.name}.generica`, sortable: true, format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } }
      },
      { key: 'tabella', type: FormFieldType.string, label: `${this.name}.tabella`, sortable: true },

      { key: 'notificaTipoInvioDes', type: FormFieldType.number, label: `${this.name}.notificatipoinvio`, sortable: true },
      {
        key: 'visible', type: FormFieldType.boolean, label: `${this.name}.visible`, sortable: true, format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } }
      },
      {
        key: 'system', type: FormFieldType.boolean, label: `${this.name}.system`, sortable: true, format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } }
      },
      { key: 'des', type: FormFieldType.string, label: `${this.name}.des`, sortable: true },
    ];

    this.detailFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'notificaTipoInvioId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'dtIns', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'userIns', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.cod`, placeholder: `${this.name}.cod`, validators: [Validators.required], class: FormFieldStyle.default
      },
      {
        key: 'generica', type: FormFieldType.boolean, typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.generica`, options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' }, class: FormFieldStyle.default,
        validators: [Validators.required],
      },
      {
        key: 'tabella', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.tabella`, placeholder: `${this.name}.tabella`, validators: [Validators.required],
        class: FormFieldStyle.default
      },
      {
        key: 'notificaTipoInvioId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.select,
        label: `${this.name}.notificaTipoInvio`, placeholder: `${this.name}.notificatipoinvio`,
        options: { data: this.attributes['gennotificatipoinvio'], dataKey: 'id', dataLabel: 'des' }, class: FormFieldStyle.default,
        validators: [Validators.required]
      },
      {
        key: 'visible', type: FormFieldType.boolean, typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.visible`, options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' }, class: FormFieldStyle.default,
        validators: [Validators.required],
      },
      {
        key: 'system', type: FormFieldType.boolean, typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.system`, options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' }, class: FormFieldStyle.default,
        validators: [Validators.required],
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`, label: `${this.name}.des`, class: FormFieldStyle.default,
        validators: [Validators.required],
      },
      {
        key: 'sezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.sezione`, placeholder: `${this.name}.sezione`,
        options: { data: this.attributes['gensezione'], dataKey: 'cod', dataLabel: 'des' },
        validators: [Validators.required], class: FormFieldStyle.default
      }
      // {
      //   key: 'messageDivider', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.divider, label: `${this.name}.messageDivider`, class: FormFieldStyle.full
      // },
      // {
      //   key: 'subject', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
      //   label: `${this.name}.subject`, placeholder: `${this.name}.subject`, class: FormFieldStyle.full
      // },
      // {
      //   key: 'body', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.textarea,
      //   label: `${this.name}.body`, placeholder: `${this.name}.body`, class: FormFieldStyle.full
      // }
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'cod,tabella,des,notificaTipoInvioDes', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.cod`,
        options: { searchFields: 'cod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'tabella', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.tabella`,
        options: { searchFields: 'tabella', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'notificaTipoInvioId', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.notificatipoinvio`,
        options: {
          data: this.attributes['gennotificatipoinvio'], dataKey: 'id', dataLabel: 'des',
          searchFields: 'notificaTipoInvioId', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.des`,
        options: { searchFields: 'des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }

}


export class GenNotificaTipoLangModalEMC extends EntityManagerConfig {

  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none }

  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = {
      windowListHeader: `${this.name}.windowlistheader`,
      windowInsertHeader: `${this.name}.windowinsertheader`,
      windowEditHeader: `${this.name}.windoweditheader`
    };
    this.dataSortValue = [new DataSortValue('lang', SortMode.Asc)];

    const ds: DataSearch = new DataSearch();
    ds.dataSortValues = [new DataSortValue('id', SortMode.Asc)];

    this.attributes['genlang'] = this.getEntityService('genlang').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        label: 'generic.back', title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        type: 'submit', label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchAdvanced', title: 'generic.searchAdvanced', icon: Icons.filters,
        class: CmdFieldStyle.btnSecondary, state: false
      },

      // Editable
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader, entityManagerInfo: AppManagerRoutes.GenNotificaTipoLangNew,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary,
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody, entityManagerInfo: AppManagerRoutes.GenNotificaTipoLangEdit,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger,
      },

      // Detail
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        label: 'generic.back', title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        label: 'generic.save', title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      { key: 'lang', type: FormFieldType.string, label: `${this.name}.lang`, sortable: true, },
      { key: 'des', type: FormFieldType.string, label: `${this.name}.des`, sortable: true, },
      { key: 'emailSubject', type: FormFieldType.string, label: `${this.name}.emailSubject`, sortable: true, },
    ];

    this.detailFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden, },
      { key: 'dtIns', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden, },
      { key: 'userIns', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden, },
      { key: 'notificaTipoId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden, },
      {
        key: 'lang', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        label: `${this.name}.lang`, placeholder: `${this.name}.lang`,
        options: {
          data: this.attributes['genlang'],
          dataKey: 'lang',
          dataLabel: 'des',
        },
        validators: [Validators.required], class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`, label: `${this.name}.des`, validators: [Validators.required], class: FormFieldStyle.default
      },
      {
        key: 'emailSubject', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.emailsubject`, label: `${this.name}.emailsubject`, class: FormFieldStyle.full
      },
      {
        key: 'emailBody', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.editor,
        placeholder: `${this.name}.emailbody`, label: `${this.name}.emailbody`, class: FormFieldStyle.full
      },
      {
        key: 'piattMsg', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.editor,
        placeholder: `${this.name}.piattmsg`, label: `${this.name}.piattmsg`, class: FormFieldStyle.full
      }
    ];

    this.searchFields = [
      {
        key: 'notificaTipoId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'notificaTipoId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simplesearchplaceholder`,
        options: {
          searchFields: 'lang,des,emailSubject,emailBody,piattMsg',
          searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.full
      },
    ];

    this.searchAdvancedFields = [
      {
        key: 'notificaTipoId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'notificaTipoId', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.default
      },
      {
        key: 'lang', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.lang`,
        options: {
          data: this.attributes['genlang'], dataKey: 'lang', dataLabel: 'des',
          searchFields: 'lang', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.des`,
        options: {
          searchFields: 'des',
          searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.default
      },
      {
        key: 'emailSubject', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.emailsubject`,
        options: {
          searchFields: 'emailSubject',
          searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.default
      },
    ];
  }
}
