import { Component, Input } from "@angular/core";
import { MessageAppService } from "src/app/services/message-app.service";
import { MessageApp } from "src/app/models/gestione/message-app";

@Component({
  selector: "app-message-app",
  templateUrl: "./message-app.component.html",
  styleUrls: ['./message-app.component.scss']
})
export class MessageAppComponent {
  lastMessage?: MessageApp;

  @Input()
  styleClasses: string = '';

  constructor(messageService: MessageAppService) {
    messageService.messages.subscribe(msg => this.lastMessage = msg);
  }
}