import { Component, Input } from '@angular/core';
import { Observable, combineLatest, distinctUntilChanged, filter, map, startWith, takeUntil } from 'rxjs';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { BaseWindowListSearchComponent } from 'src/app/components/base-window-list/base-window-list-search.component';
import { CalTipoMani } from 'src/app/models/anagrafiche/calendario/cal-tipo-mani';
import { GenGruppoFci } from 'src/app/models/anagrafiche/gen-gruppo-fci';
import { GiudEsitoAssistentato } from 'src/app/models/giudici/giud-esito-assistentato';
import { GiudStatoAssistentato } from 'src/app/models/giudici/giud-stato-assistentato';
import { VeeRazze } from 'src/app/models/vee/vee-razze';

@Component({
  selector: 'app-giud-assistentato-list-search',
  templateUrl: './giud-assistentato-list-search.component.html',
  styleUrls: ['./giud-assistentato-list-search.component.scss']
})
export class GiudAssistentatoListSearchComponent extends BaseWindowListSearchComponent {
  // Form
  dataForm = this.fb.group({
    richiestaId: this.fb.control<number | null>(null),
    giudiceId: this.fb.control<number | null>(null),
    caleManiId: this.fb.control<number | null>(null),
    simpleSearch: this.fb.control<string | null>(null),
    id: this.fb.control<number | null>(null),
    giudiceRichiedenteNome: this.fb.control<string | null>(null),
    giudiceRichiedenteCognome: this.fb.control<string | null>(null),
    gruppoFciId: this.fb.control<number[] | null>(null),
    razzaId: this.fb.control<number[] | null>(null),
    tipoManiId: this.fb.control<number[] | null>(null),
    caleManiLabel: this.fb.control<string | null>(null),
    codProtipo: this.fb.control<string | null>(null),
    giudiceNome: this.fb.control<string | null>(null),
    giudiceCognome: this.fb.control<string | null>(null),
    esitoAssistentatoId: this.fb.control<number[] | null>(null),
    statoAssistentatoId: this.fb.control<number[] | null>(null)
  });

  // Dropdowns
  esitoAssistentatoList$: Observable<GiudEsitoAssistentato[]> = this.baseDataService.getGiudEsitoAssistentatoList();

  statoAssistentatoList$: Observable<GiudStatoAssistentato[]> = this.baseDataService.getGiudStatoAssistentatoList();

  gruppoFciList$: Observable<GenGruppoFci[]> = this.baseDataService.getGenGruppoFciList();

  razzeList$: Observable<VeeRazze[]> = combineLatest([
    this.baseDataService.getVeeRazzeList(),
    this.dataForm.get('gruppoFciId').valueChanges.pipe(
      startWith([]),
      distinctUntilChanged(),
      filter(data => !!data)
    )
  ]).pipe(
    map(([list, ids]: [list: VeeRazze[], ids: number[]]) => !ids.length ? list : list.filter((r) => ids.includes(r.gruppoFciId)))
  );

  tipoManiList$: Observable<CalTipoMani[]> = this.baseDataService.getCalTipoManiList().pipe(
    map(data => {
      if (!this.tipoCalCod) {
        return data;
      }

      return data.filter(t => t.tipoCalCod === this.tipoCalCod)
    })
  );

  simpleSearchMap: { [key: string]: string } = {
    [AppManagerRoutes.GiudAssistentatoGiudiceList.id]: 'giudassistentato.simpleSearchPlaceholderGiudice',
    [AppManagerRoutes.CalCaleManiSettings.id]: 'giudassistentato.simpleSearchPlaceholderCaleMani',
    'other': 'giudassistentato.simpleSearchPlaceholder'
  };

  appManagerRoutes: typeof AppManagerRoutes = AppManagerRoutes;

  @Input()
  tipoCalCod!: 'EXP' | 'PRV';

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    // Eventi form
    // Se seleziono dei gruppi FCI faccio vedere le sole razze di quei gruppi ed elinino dalla selezione delle razze che non appartengono ai gruppi selezionati
    this.razzeList$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((data) => {
      const razzaIdField = this.dataForm.get('razzaId');
      razzaIdField.setValue(razzaIdField.value?.filter((id) => data.some((r) => r.id === id)));
    });

    super.ngOnInit();
  }
}
