import { Component, HostBinding, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  selector: 'app-template-note',
  templateUrl: './template-note.component.html',
  styleUrls: ['./template-note.component.scss']
})

export class TemplateNoteComponent {
  template: string;

  messageVisible: boolean = false;

  @Input()
  formGroup: FormGroup;

  @Input()
  controlName: string;

  @Input()
  templates: unknown[];

  @Input()
  optionLabel: string = 'des';

  @Input()
  templatePlaceholder: string = '';

  @Input()
  notePlaceholder: string = 'Note';

  @Input()
  noteLabel: string = 'Note';

  @Input()
  okLabel: string = 'Ok';

  @Input()
  cancelLabel: string = 'Cancel';

  @Input()
  okTitle: string = this.okLabel;

  @Input()
  cancelTitle: string = this.cancelLabel;

  @Input()
  styleClass: string = null;

  @Input()
  warningMessage: string = '';

  @HostBinding('class') get class() {
    return this.styleClass;
  }

  constructor() { }

  onTemplateChange(event: DropdownChangeEvent): void {
    const noteField = this.formGroup.get('note');

    if (noteField.value && event.value) {
      this.toggleMessageVisibility();
    }
    else {
      this.setNotaSospensione(event.value);
    }
  }

  private setNotaSospensione(value: string): void {
    const noteField = this.formGroup.get('note');
    noteField.setValue(value);
    noteField.markAsDirty();
  }

  cancelSetNotaSospensione(): void {
    this.toggleMessageVisibility();
    this.template = undefined;
  }

  confirmSetNotaSospensione(): void {
    this.setNotaSospensione(this.template);
    this.toggleMessageVisibility();
    this.template = undefined;
  }

  private toggleMessageVisibility(): void {
    this.messageVisible = !this.messageVisible;
  }
}
