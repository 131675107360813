<div *ngIf="translatedItems$ | async as translateItems"
  class=" flex flex-row justify-content-between surface-200 px-3 py-2 flex align-items-center h-4rem">

  <div class="flex flex-row flex-nowrap align-items-center">
    <!-- <p-tieredMenu #headermenu [popup]="true" [model]="menuItems" [appendTo]="'body'"></p-tieredMenu> -->
    <!-- <button type="button" pButton icon="fa-solid fa-bars text-xl" class="p-button-text text-primary md:hidden mr-2"
      [title]="translateItems | translateItem: 'generic.menu'" (click)="toggleandLoadMenu($event,headermenu)"></button> -->
    <img src="assets/images/enci-logo.svg" class="h-3rem mr-2 logo" (click)="navigateToHome()"
      [title]="translateItems | translateItem: 'menu.Home'">
    <div class="flex align-items-end" [title]="appTitle + ' ' + appVersion"><span
        class="text-xl font-bold text-primary ml-2 mr-1">{{appTitle}}</span>
      <span class="text-xs text-600">{{appVersion}}</span>
    </div>
  </div>

  <div class="flex flex-row flex-nowrap align-items-center">
    <span *ngIf="!appInitialized.dtoUtente.utente?.flHoRilasciDaAccettare">
      <button *grantVisible="grants | async; key 'profiloutente'" type="button" pButton
        class="mx-2 p-button-text text-600" (click)="navigateToProfiloUtente()" icon="fa-solid fa-user-gear text-xl"
        [title]="translateItems | translateItem: 'menu.profiloUtente.title'">
      </button>

      <button type="button" pButton class="p-button-text mx-2"
        [title]="translateItems | translateItem: 'generic.notifications'" (click)="notificationsEvent($event)">
        <span pBadge class="fa-solid fa-bell text-xl text-600" severity="danger"
          [value]="notificationNotReadCount?.toString()"></span>
      </button>
    </span>

    <p-chip [label]="appInitialized.dtoUtente.utente.email" icon="fa-solid fa-user text-600"
      class="hidden md:block mx-2"
      pTooltip="{{appInitialized.dtoUtente.utente.nome ? appInitialized.dtoUtente.utente.nome + ' ' + appInitialized.dtoUtente.utente.cognome : ''}}"
      tooltipPosition="bottom">
    </p-chip>
    <div class="hidden md:block mx-2">
      <p-dropdown *ngIf="userProfiles.length > 1" [options]="userProfiles" optionLabel="des" optionValue="id"
        styleClass="p-inputtext-sm" appendTo="body"
        [emptyMessage]="translateItems | translateItem: 'generic.dropdownEmpty'" (onChange)="onProfileChange($event)">
      </p-dropdown>
      <span *ngIf="userProfiles.length === 1">{{currentProfile.des}}</span>
    </div>
    <button type="button" pButton class="p-button-text text-600 hidden md:flex ml-2 text-xl" (click)="logout()"
      icon="fa-solid fa-arrow-right-from-bracket text-xl" [title]="translateItems | translateItem: 'generic.logout'">
    </button>

    <p-overlayPanel #headerusermenu>
      <ng-template pTemplate>
        <p-chip [label]="appInitialized.dtoUtente.utente.email" icon="fa-solid fa-user"
          pTooltip="{{appInitialized.dtoUtente.utente.nome ? appInitialized.dtoUtente.utente.nome + ' ' + appInitialized.dtoUtente.utente.cognome : ''}}"
          tooltipPosition="bottom"></p-chip>
        <p-dropdown *ngIf="userProfiles.length > 1" [options]="userProfiles" optionLabel="des" optionValue="id"
          styleClass="p-inputtext-sm" appendTo="body" class="block my-3"
          [emptyMessage]="translateItems | translateItem: 'generic.dropdownEmpty'">
        </p-dropdown>
        <span *ngIf="userProfiles.length === 1">{{currentProfile.des}}</span>
        <p-divider></p-divider>
        <button type="button" pButton class="p-button-text p-button-danger" (click)="logout()"
          icon="fa-solid fa-arrow-right-from-bracket text-xl" [label]="translateItems | translateItem: 'generic.logout'"
          [title]="translateItems | translateItem: 'generic.logout'">
        </button>
      </ng-template>
    </p-overlayPanel>
    <button type="settings" pButton class="md:hidden p-button-text text-600 ml-2"
      (click)="headerusermenu.toggle($event)" icon="fa-solid fa-user text-xl"
      [title]="translateItems | translateItem: 'generic.userSettings'">
    </button>
  </div>
</div>