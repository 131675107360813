import { Entity } from "../entity";

export class GiudBloccoExp extends Entity {
  giudiceId: number;
  razzaId: number;
  dataInizio: Date;
  dataFine: Date;
  note: string;
  temaIdPratica?: number;

  razzaDes: string;
  flBloccoInCorso: boolean;
}