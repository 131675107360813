import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-text-block',
  templateUrl: './text-block.component.html',
  styleUrls: ['./text-block.component.scss']
})

export class TextBlockComponent {

  @Input()
  label: string = '';

  @Input()
  value: string | number | Date | boolean;

  @Input()
  layout: 'default' | 'yesNo' | 'date' = 'default';

  @Input()
  dateFormat: string;

  @Input()
  yesLabel: string = 'Yes';

  @Input()
  noLabel: string = 'No';

  @Input()
  @HostBinding('class')
  styleClass: string = 'app-col-base';
}
