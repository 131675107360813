import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SortMeta } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Observable, Subscription, map, tap } from 'rxjs';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { TipoCalCod, TipoCalCodType } from 'src/app/models/anagrafiche/calendario/cal-tipo-cal';
import { CalTipoMani } from 'src/app/models/anagrafiche/calendario/cal-tipo-mani';
import { ComparisonOperator, DataSearch, DataSortValue, SearchFieldType, SearchFieldsConf, SortMode, buildDataSearch } from 'src/app/models/data-search';
import { EntityManagerGrant } from 'src/app/models/entity-config';
import { GeoNazione } from 'src/app/models/geo/geo-nazione';
import { GiudImpegnoEstero } from 'src/app/models/giudici/giud-impegno-estero';
import { MessageDataConfig, MessageDataFromConfig, ToastMessageData } from 'src/app/models/message';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { BaseDataService } from 'src/app/services/base-data.service';
import { DataService } from 'src/app/services/data.service';
import { TriggersService } from 'src/app/services/extension/triggers.service';
import { ListExportService } from 'src/app/services/list-export.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { convertDateFieldToLocalDate, convertSortMetaArrayToDataSortValueArray, dateFormat, dateFormatPrNg } from 'src/app/utils/util';

@Component({
  selector: 'app-giud-impegno-estero-list',
  templateUrl: './giud-impegno-estero-list.component.html',
  styleUrls: ['./giud-impegno-estero-list.component.scss'],
})
export class GiudImpegnoEsteroListComponent implements OnInit, OnDestroy {
  dateFormat: string = dateFormat;
  dateFormatPrNg: string = dateFormatPrNg;
  T$: Observable<Resources>;
  resourceList: Resources;
  entities$: Observable<GiudImpegnoEstero[]>;
  dataForm: FormGroup;
  basePaginatorFirst: number = 0;
  basePaginatorRows: number = 10;
  paginatorFirst: number = this.basePaginatorFirst;
  paginatorRows: number = this.basePaginatorRows;
  dataSortValues: DataSortValue[];
  paginatorRowsTot: number;
  dataSearch: DataSearch;
  baseMultiSortMeta: SortMeta[] = [
    { field: 'dataInizio', order: SortMode.Desc },
    { field: 'dataFine', order: SortMode.Desc },
    { field: 'tipoManiDes', order: SortMode.Asc }
  ];
  multiSortMeta: SortMeta[] = [...this.baseMultiSortMeta];
  elaboration: boolean = false;
  advancedSearch: boolean = false;
  calTipoManiList$: Observable<CalTipoMani[]>;
  geoNazioneList$: Observable<GeoNazione[]>;
  modalListExport: { visible: boolean, data: any } = { visible: false, data: undefined };
  modalEdit: { visible: boolean, entity: GiudImpegnoEstero, giudiceId: number } = { visible: false, entity: undefined, giudiceId: undefined };
  grants$: Observable<EntityManagerGrant>;
  searchFieldsConf: SearchFieldsConf[] = [
    { key: 'giudiceId', searchFields: 'giudiceId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: false },
    { key: 'tipoCalCod', searchFields: 'tipoCalCod', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: false },
    { key: 'dataInizioLimite', searchFields: 'dataInizio', type: SearchFieldType.date, searchCompOp: ComparisonOperator.GreaterThanOrEqual, advancedSearch: false },
    { key: 'dataFineLimite', searchFields: 'dataFine', type: SearchFieldType.date, searchCompOp: ComparisonOperator.LessThanOrEqual, advancedSearch: false },
    { key: 'simpleSearch', searchFields: 'tipoManiDes,nazioneLabel,localita', type: SearchFieldType.multiField, searchCompOp: ComparisonOperator.Contains, advancedSearch: false },
    { key: 'giudiceId', searchFields: 'giudiceId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'tipoCalCod', searchFields: 'tipoCalCod', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'dataInizioLimite', searchFields: 'dataInizio', type: SearchFieldType.date, searchCompOp: ComparisonOperator.GreaterThanOrEqual, advancedSearch: true },
    { key: 'dataInizio', searchFields: 'dataInizio', type: SearchFieldType.date, searchCompOp: ComparisonOperator.GreaterThanOrEqual, advancedSearch: true },
    { key: 'dataFineLimite', searchFields: 'dataFine', type: SearchFieldType.date, searchCompOp: ComparisonOperator.LessThanOrEqual, advancedSearch: true },
    { key: 'dataFine', searchFields: 'dataFine', type: SearchFieldType.date, searchCompOp: ComparisonOperator.LessThanOrEqual, advancedSearch: true },
    { key: 'tipoManiId', searchFields: 'tipoManiId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true },
    { key: 'nazioneId', searchFields: 'nazioneId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true },
    { key: 'localita', searchFields: 'localita', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
  ];

  @ViewChild('dataTable') dataTable: Table;

  private subscription: Subscription;

  @Input() giudiceId: number;
  @Input() tipoCalCod: TipoCalCodType;
  @Input() dataInizioLimite: Date;
  @Input() dataFineLimite: Date;
  @Input() readOnly: boolean = false;

  @Input() tabIndex: number;

  constructor(
    private dataService: DataService,
    private translateService: TranslateService,
    private triggersService: TriggersService,
    private fb: FormBuilder,
    private baseDataService: BaseDataService,
    private listExportService: ListExportService,
    private appManagerService: AppManagerService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    // Etichette
    this.T$ = this.translateService.translatedItems$(AppManagerRoutes.GiudImpegnoEstero.translateSuffixs ?? ['generic.']).pipe(
      map(data => this.resourceList = data)
    );

    // Grants
    this.grants$ = this.appManagerService.getGrants(AppManagerRoutes.GiudImpegnoEstero.section ?? AppManagerRoutes.GiudImpegnoEstero.id);

    // Filtri
    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(this.multiSortMeta);

    this.dataForm = this.fb.group({
      giudiceId: this.giudiceId,
      dataInizioLimite: this.dataInizioLimite,
      dataInizio: null,
      dataFineLimite: this.dataFineLimite,
      dataFine: null,
      simpleSearch: null,
      tipoManiId: null,
      nazioneId: null,
      localita: null,
      tipoCalCod: this.tipoCalCod
    });

    // Caricamento dati all'inzializzazione
    this.loadData();

    // Refresh dati al cambio tab
    if (this.tabIndex >= 0) {
      this.subscription.add(
        this.triggersService.triggerTabChange$.subscribe(
          (currTabIndex: number) => {
            if (currTabIndex === this.tabIndex) {
              this.loadData();
            }
          }
        )
      );
    }
  }

  onLazyLoad(event: TableLazyLoadEvent): void {
    this.paginatorFirst = event.first;
    this.paginatorRows = event.rows;

    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(event.multiSortMeta);

    if (this.dataTable?.initialized) {
      this.loadData();
    }
  }

  onPage(event): void {
    this.paginatorFirst = event.first;
    this.paginatorRows = event.rows;
  }

  private loadData(): void {
    this.dataSearch = buildDataSearch(this.dataForm.value, this.searchFieldsConf, this.advancedSearch, this.dataSortValues, this.paginatorFirst, this.paginatorRows);

    this.elaboration = true;

    this.entities$ = this.dataService.searchElements<GiudImpegnoEstero>(`${this.dataService.configSettings.restCommonUrl}/giudimpegnoestero`, this.dataSearch).pipe(
      tap(data => {
        this.elaboration = false;
        this.paginatorRowsTot = data.numRowsTot;
      }),
      map(data => data.entities)
    );
  }

  search(): void {
    this.paginatorFirst = this.basePaginatorFirst;
    this.loadData();
  }

  resetSearch(): void {
    this.dataForm.reset({ giudiceId: this.giudiceId, dataInizioLimite: this.dataInizioLimite, dataFineLimite: this.dataFineLimite });
    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(this.baseMultiSortMeta);
    this.paginatorFirst = this.basePaginatorFirst;
    this.paginatorRows = this.basePaginatorRows;
    this.multiSortMeta = [...this.baseMultiSortMeta];
    this.loadData();
  }

  toggleSearch(): void {
    this.advancedSearch = !this.advancedSearch;
    this.dataForm.reset({ giudiceId: this.giudiceId, dataInizioLimite: this.dataInizioLimite, dataFineLimite: this.dataFineLimite });

    // Carico le liste per la ricerca avanzata
    if (this.advancedSearch) {
      // Tipi mani
      if (!this.calTipoManiList$) {
        switch (this.tipoCalCod) {
          case TipoCalCod.esposizioni:
            this.calTipoManiList$ = this.baseDataService.getCalTipoManiExpList();
            break;
          case TipoCalCod.prove:
            this.calTipoManiList$ = this.baseDataService.getCalTipoManiPrvList();
            break;
          default:
            this.calTipoManiList$ = this.baseDataService.getCalTipoManiList();
            break;
        }
      }
      // Nazioni
      if (!this.geoNazioneList$) {
        this.geoNazioneList$ = this.baseDataService.getGeoNazioneList();
      }
    }
  }

  resetFilterControl(controlName: string, defaultValue: [] | null = null): void {
    this.dataForm.get(controlName)?.reset(defaultValue);
  }

  modalListExportShow(): void {
    this.listExportService.openModal('GiudImpegnoEstero', this.dataSearch, 'Giud');
  }

  modalListExportHide(): void {
    this.modalListExport.data = undefined;
    this.modalListExport.visible = false;
  }

  modalEditShow(entity: GiudImpegnoEstero): void {
    this.modalEdit = {
      visible: true,
      entity: convertDateFieldToLocalDate(entity, ['dataInizio', 'dataFine', 'dtIns', 'dtUpd']),
      giudiceId: this.giudiceId
    };
  }

  modalInsertShow(): void {
    const entity = new GiudImpegnoEstero();

    this.modalEdit = {
      visible: true,
      entity,
      giudiceId: this.giudiceId
    };
  }

  modalEditHide(reloadData: boolean): void {
    this.modalEdit = {
      visible: false,
      entity: undefined,
      giudiceId: undefined
    };

    if (reloadData) {
      this.loadData();
    }
  }

  delete(entities: GiudImpegnoEstero[]): void {
    if (entities.length == 0) {
      return;
    }

    const hEventOk = () => {
      const ids = entities.map(t => t.id);

      this.elaboration = true;

      this.subscription.add(
        this.dataService.deleteElements<GiudImpegnoEstero>(`${this.dataService.configSettings.restCommonUrl}/giudimpegnoestero`, ids).subscribe(
          (count: number) => {
            this.elaboration = false;

            if (count > 0) {
              this.appManagerService.showToastMessage(new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.deleteSuccessMsg')));

              this.loadData();
            }
          },
          () => {
            this.elaboration = false;
          }
        )
      );
    }

    const messageData = new MessageDataFromConfig(MessageDataConfig.Delete, hEventOk);

    this.appManagerService.showMessage(messageData);
  }
}
