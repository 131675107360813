<ng-container *ngIf="translatedItems$ | async as translateItems">
    <div class="flex flex-row justify-between bg-white round-border-bottom shadow">
        <ng-container *ngIf="!menuSticky">
            <i [ngClass]="{'fa-xmark': menuExpanded, 'fa-bars': !menuExpanded}" style="width: 1.25rem;"
                class="fa-solid text-xl mx-3 my-auto text-primary cursor-pointer"
                [title]="translateItems | translateItem: 'generic.menu'" (click)="toggleMenu($event)"></i>
            <div class="my-2 vertical-divider"></div>
        </ng-container>
        <app-breadcrumb></app-breadcrumb>
    </div>

    <div class="flex flex-row flex-grow-1 m-2 app-container">
        <app-sidemenu [menuExpanded]="menuExpanded" (menuStickyEvent)="onMenuStickyEvent($event)"
            (menuExpandedEvent)="onMenuExpandedEvent($event)"></app-sidemenu>
        <div class="flex-grow-1 bg-white px-4 py-3 overflow-x-auto round-border shadow">
            <router-outlet></router-outlet>
        </div>
    </div>
</ng-container>