import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ExtEntityInfoPars } from '../models/gestione/ext-entity-info-pars';

@Injectable({
  providedIn: 'root'
})
export class GenNotaService {
  private _modalNotaSubject: Subject<ExtEntityInfoPars> = new Subject();

  constructor() { }

  getNotaSubject() {
    return this._modalNotaSubject.asObservable();
  }

  openModalNota(extTableName: string, extId: number) {
    const pars = new ExtEntityInfoPars();
    pars.open = true;
    pars.extTableName = extTableName;
    pars.extId = extId;

    this._modalNotaSubject.next(pars);
  }

  closeModalNota() {
    this._modalNotaSubject.next({
      open: false,
      extTableName: null,
      extId: null
    });
  }
}