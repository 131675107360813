import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { GenExportTemplate } from 'src/app/models/anagrafiche/gen-export-template';
import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from 'src/app/models/data-search';
import { ColumnExportDto } from 'src/app/models/gestione/dto/column-export-dto';
import { EntityExportDto } from 'src/app/models/gestione/dto/entity-export-dto';
import { ExportListPars } from 'src/app/models/gestione/list-export-pars';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { DataService } from 'src/app/services/data.service';
import { ListExportService } from 'src/app/services/list-export.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-list-export',
  templateUrl: './list-export.component.html',
  styleUrls: ['./list-export.component.scss']
})

export class ListExportComponent implements OnInit {
  T$: Observable<Resources>;
  moadlListExport$: Observable<ExportListPars>;
  exportTemplateList$: Observable<GenExportTemplate[]>;
  columnList$: Observable<ColumnExportDto[]>;
  visible: boolean = false;
  dataForm: FormGroup;
  dataSearch: DataSearch;
  columnList: any;
  entityName: string;
  elaboration: boolean = false;

  private subscription: Subscription;

  constructor(
    private dataService: DataService,
    private translateService: TranslateService,
    private listExportService: ListExportService,
    private fb: FormBuilder,
    private appManagerService: AppManagerService) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.T$ = this.translateService.translatedItems$(['generic.', 'listexport.']);
    this.moadlListExport$ = this.listExportService.getSubject();

    // Recupera le colonne dell'entità alla richiesta di apertura della modale
    this.columnList$ = this.moadlListExport$.pipe(
      tap(data => {
        this.visible = data.open;
      }),
      filter(data => data.open),
      tap(data => {
        this.entityName = data.entityName;

        // Data search senza paginazione
        this.dataSearch = data.dataSearch
        delete this.dataSearch.pageFirst;
        delete this.dataSearch.pageSize;

        this.dataForm.get('fileName').setValue(this.listExportService.defaultFileName());
      }),
      switchMap(data => this.dataService.getGeneric(`${this.dataService.configSettings.restCommonUrl}/GetEntityExportColumns`, `entityName=${data.entityName}${data.nameSpace ? `&nameSpace=${data.nameSpace}` : ''}`)),
      tap(data => {
        this.columnList = data;
        this.dataForm.get('columns').setValue(data);
      })
    );

    this.exportTemplateList$ = this.appManagerService.initialized$.pipe(
      filter(data => data.initialized),
      switchMap(data => {
        const profiloId = data.dtoUtente.utente.currentProfiloId;
        const ds = new DataSearch(
          [
            new DataSearchValue([this.entityName], ['entity'], ComparisonOperator.Equals),
            new DataSearchValue([profiloId.toString()], ['profiloId'], ComparisonOperator.Equals),
            new DataSearchValue([true], ['visible'], ComparisonOperator.Equals)
          ],
          [new DataSortValue('name', SortMode.Asc)]
        );

        return this.dataService.searchElements<GenExportTemplate>(`${this.dataService.configSettings.restCommonUrl}/genexporttemplate`, ds);
      }),
      map(data => data.entities.map((t: GenExportTemplate) => {
        t.columns = JSON.parse(String(t.columns));
        return t;
      }))
    )

    // Costruisco il data form
    this.dataForm = this.fb.group({
      exportTemplateId: null,
      fileName: [this.listExportService.defaultFileName(), Validators.required],
      columns: [[], Validators.required]
    });

    this.subscription.add(
      this.dataForm.get('exportTemplateId').valueChanges.subscribe(
        (data: GenExportTemplate) => {
          const cols = this.columnList.map(c => {
            c.selected = data?.columns?.[c.name] ?? false;
            return c;
          }).filter(c => c.selected);
          this.dataForm.get('columns').setValue(cols, { emitEvent: false });
        }
      )
    );
    this.subscription.add(
      this.dataForm.get('columns').valueChanges.subscribe(
        (val: ColumnExportDto[]) => this.dataForm.get('exportTemplateId').setValue(null, { emitEvent: false })
      )
    )
  }

  hideModal(): void {
    this.dataForm.reset();
    this.listExportService.closeModal();
  }

  onTemplateClear(): void {
  }

  exportExcel(): void {
    this.elaboration = true;

    const values = this.dataForm.value;

    const data = new EntityExportDto();
    data.entityName = this.entityName;
    data.columns = values.columns
    data.dataSearch = this.dataSearch;
    data.fileName = values.fileName;

    this.subscription.add(
      this.listExportService.exportFile(`${this.dataService.configSettings.restCommonUrl}/exportgridtoexcel`, data).subscribe(
        (blob) => {
          saveAs(blob, `${values.fileName}.xlsx`);
          this.elaboration = false;
          this.hideModal();
        },
        () => {
          this.elaboration = false;
        }
      )
    );
  }
}
