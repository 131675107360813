import { Component, Input } from '@angular/core';
import { takeUntil } from 'rxjs';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { BaseWindowListComponent } from 'src/app/components/base-window-list/base-window-list.component';
import { TipoCalCod, TipoCalCodType } from 'src/app/models/anagrafiche/calendario/cal-tipo-cal';
import { GiudAssistentatoNewDto } from 'src/app/models/giudici/dto/giud-assistentato-new-dto';
import { GiudGiudiceSuggestionDto } from 'src/app/models/giudici/dto/giud-giudice-suggestion-dto';
import { GiudAssistentato } from 'src/app/models/giudici/giud-assistentato';
import { EsitoAssistentatoCod } from 'src/app/models/giudici/giud-esito-assistentato';
import { StatoAssistentatoCod, StatoAssistentatoLivello } from 'src/app/models/giudici/giud-stato-assistentato';
import { MessageData, MessageLevel, MessageMode, ToastMessageData } from 'src/app/models/message';
import { SecUtente } from 'src/app/models/security/sec-utente';

@Component({
  selector: 'app-giud-assistentato-list-base',
  template: '',
})
export class GiudAssistentatoListBaseComponent<T> extends BaseWindowListComponent<T> {

  // Modali
  modalEdit: { visible: boolean, entity: GiudAssistentato, richiestaId: number, giudiceRichiedenteId: number, rilascioEsito: boolean } = { visible: false, entity: undefined, richiestaId: undefined, giudiceRichiedenteId: undefined, rilascioEsito: false };

  modalAdd: { visible: boolean, entity: GiudAssistentatoNewDto, richiestaId: number, giudiceRichiedenteId: number, tipoCalCod: TipoCalCodType } = { visible: false, entity: undefined, richiestaId: undefined, giudiceRichiedenteId: undefined, tipoCalCod: undefined };

  statoAssistentatoCod: typeof StatoAssistentatoCod = StatoAssistentatoCod;

  statoAssistentatoLivello: typeof StatoAssistentatoLivello = StatoAssistentatoLivello;

  esitoAssistentatoCod: typeof EsitoAssistentatoCod = EsitoAssistentatoCod;

  tipoCalCodEnum: typeof TipoCalCod = TipoCalCod;

  // Utente
  giudiceId: number;

  utente: SecUtente

  appManagerRoutes: typeof AppManagerRoutes = AppManagerRoutes;

  get addButtonDisabled(): boolean {
    return this.elaboration || !['ACC', 'ACC_PARZ', 'IN_CORSO'].includes(this.statoRichiestaCod);
  }

  @Input()
  richiestaId!: number;

  @Input()
  statoRichiestaCod!: string;

  @Input()
  giudiceRichiedenteId!: number;

  @Input()
  caleManiId!: number;

  @Input({ required: true })
  tipoCalCod!: TipoCalCodType;

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.restServiceUrl = this.dataService.configSettings.restCommonUrl;

    // Utente
    this.appManagerService.utente$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (utente) => {
        this.utente = utente;
        this.giudiceId = utente.livelloProfiloCod === 'GIUDICE' ? utente.enciIdAnag : this.giudiceId;
      }
    );

    super.ngOnInit();
  }

  navigateToDetail(id: number): void {
    this.setStorageHilightedRowDataKey(id.toString());

    this.router.navigate(
      [id, AppManagerRoutes.GiudRichiestaDetail.url],
      { relativeTo: this.activatedRoute }
    );
  }

  navigateToNewAssistentato(id: number): void {
    this.setStorageHilightedRowDataKey(id.toString());

    this.router.navigate(
      [id, AppManagerRoutes.GiudAssistentatoNew.url],
      { relativeTo: this.activatedRoute }
    );
  }

  addEntity(): void {
    const entity = new GiudAssistentatoNewDto();

    this.modalAdd = {
      visible: true,
      entity,
      richiestaId: this.richiestaId,
      giudiceRichiedenteId: this.giudiceRichiedenteId,
      tipoCalCod: this.tipoCalCod
    };
  }

  modalAddHide(reloadData: boolean): void {
    this.modalAdd = {
      visible: false,
      entity: undefined,
      richiestaId: undefined,
      giudiceRichiedenteId: undefined,
      tipoCalCod: undefined
    };

    if (reloadData) {
      this.refreshEntities$.next();
    }
  }

  editEntity(entity: GiudAssistentato, rilascioEsito: boolean = false): void {
    this.modalEdit = {
      visible: true,
      entity,
      richiestaId: entity.richiestaId,
      giudiceRichiedenteId: entity.giudiceRichiedenteId,
      rilascioEsito: rilascioEsito
    };
  }

  modalEditHide(reloadData: boolean): void {
    this.modalEdit = {
      visible: false,
      entity: undefined,
      richiestaId: undefined,
      giudiceRichiedenteId: undefined,
      rilascioEsito: false
    };

    if (reloadData) {
      this.refreshEntities$.next();
    }
  }

  accept(ids: number[], type: 'ENCI' | 'GIUD'): void {
    if (ids.length == 0) {
      return;
    }

    const service = type === 'ENCI' ? 'accettaenci' : 'accettagiud';

    const options = {
      btnLabelOk: 'generic.accept',
      btnIconOk: 'fa-solid fa-thumbs-up',
      btnClassOk: 'p-button-success',
      notTranslateTitle: true,
      notTranslateMessage: true
    };

    const messageData = new MessageData(
      MessageMode.OkKo,
      MessageLevel.Warning,
      this.translateService.translate(this.resourceList, 'giudassistentato.assistentatoAcceptModalMessage'),
      this.translateService.translate(this.resourceList, 'giudassistentato.assistentatoAcceptModalTitle'),
      null,
      options
    );

    const hEventOk = () => {
      this.elaboration = true;

      this.dataService.postGeneric(`${this.restServiceUrl}/${this.entityServiceUrl ?? this.entityManager.name}/${service}`, ids).pipe(
        takeUntil(this.destroy$)
      ).subscribe({
        next: () => {
          this.elaboration = false;

          this.appManagerService.showToastMessage(new ToastMessageData('success', this.translateService.translate(this.resourceList, 'giudassistentato.assistentatoAcceptSuccessMessage')));
          this.refreshEntities();
        },
        error: () => {
          this.elaboration = false;

          this.appManagerService.showToastMessage(new ToastMessageData('error', this.translateService.translate(this.resourceList, 'giudassistentato.assistentatoAcceptErrorMessage')));
        }
      });
    }

    messageData.hEventOk = hEventOk;

    this.appManagerService.showMessage(messageData);
  }

  reject(ids: number[], type: 'ENCI' | 'GIUD'): void {
    if (ids.length == 0) {
      return;
    }

    const service = type === 'ENCI' ? 'rifiutaenci' : 'rifiutagiud';

    const options = {
      btnLabelOk: 'generic.reject',
      btnIconOk: 'fa-solid fa-thumbs-down',
      btnClassOk: 'p-button-danger',
      notTranslateTitle: true,
      notTranslateMessage: true
    };

    const messageData = new MessageData(
      MessageMode.OkKo,
      MessageLevel.Warning,
      this.translateService.translate(this.resourceList, 'giudassistentato.assistentatoRejectModalMessage'),
      this.translateService.translate(this.resourceList, 'giudassistentato.assistentatoRejectModalTitle'),
      null,
      options
    );

    const hEventOk = () => {
      this.elaboration = true;

      this.dataService.postGeneric(`${this.restServiceUrl}/${this.entityServiceUrl ?? this.entityManager.name}/${service}`, ids).pipe(
        takeUntil(this.destroy$)
      ).subscribe({
        next: () => {
          this.elaboration = false;

          this.appManagerService.showToastMessage(new ToastMessageData('success', this.translateService.translate(this.resourceList, 'giudassistentato.assistentatoRejectSuccessMessage')));
          this.refreshEntities();
        },
        error: () => {
          this.elaboration = false;

          this.appManagerService.showToastMessage(new ToastMessageData('error', this.translateService.translate(this.resourceList, 'giudassistentato.assistentatoRejectErrorMessage')));
        }
      });
    }

    messageData.hEventOk = hEventOk;

    this.appManagerService.showMessage(messageData);
  }

  addRepetition(parentEntity: GiudAssistentato): void {
    const giudice = new GiudGiudiceSuggestionDto();
    giudice.nomeCompleto = parentEntity.giudiceNomeCompleto;

    const entity = new GiudAssistentatoNewDto();
    entity.assistentatoIdPadre = parentEntity.assistentatoIdPadre ?? parentEntity.id;
    entity.richiestaDetailIds = [parentEntity.richiestaDetailId];
    entity.giudiceId = parentEntity.giudiceId;
    entity.giudiceRaw = giudice;

    this.modalAdd = {
      visible: true,
      entity,
      richiestaId: parentEntity.richiestaId,
      giudiceRichiedenteId: parentEntity.giudiceRichiedenteId,
      tipoCalCod: <TipoCalCodType>parentEntity.tipoCalCod
    };
  }
}
