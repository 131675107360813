import { Injectable } from "@angular/core";
import { SortMeta } from "primeng/api";
import { Observable, ReplaySubject } from "rxjs";
import { buildDataSearch, DataSearch, SearchFieldsConf } from "../models/data-search";
import { convertDataSortValueArrayToSortMetaArray, convertSortMetaArrayToDataSortValueArray } from "../utils/util";
import { AppManagerService, SESSION_STORAGE_NAV } from "./app-manager.service";

@Injectable({
  providedIn: 'root'
})
export class BaseWindowListService {
  private defaultFilters: Object;

  private searchFilters: Object;

  private searchFieldsConf: SearchFieldsConf[];

  private advancedSearch: boolean = false;

  private dataSearch: DataSearch;

  private persistFilters: boolean = true;

  private entityManagerId: string;

  private _dataSearch$: ReplaySubject<DataSearch> = new ReplaySubject(1);

  private _multiSortMeta$: ReplaySubject<SortMeta[]> = new ReplaySubject(1);

  dataSearch$: Observable<DataSearch> = this._dataSearch$.asObservable();

  defaultPaginatorFirst: number = 0;

  paginatorFirst: number = this.defaultPaginatorFirst;

  defaultPaginatorRows: number = 10;

  paginatorRows: number = this.defaultPaginatorRows;

  defaultMultiSortMeta!: SortMeta[];

  multiSortMeta!: SortMeta[];

  get multiSortMeta$(): Observable<SortMeta[]> {
    return this._multiSortMeta$.asObservable();
  }

  constructor(
    private appManagerService: AppManagerService
  ) { }

  init(
    searchFieldsConf: SearchFieldsConf[],
    defaultFilters: Object,
    persistFilters: boolean,
    entityManagerId: string,
    defaultMultiSortMeta: SortMeta[]
  ): void {
    if (this.dataSearch) {
      this.dataSearch = new DataSearch([], [], undefined, undefined);
    }

    this.searchFieldsConf = searchFieldsConf;
    this.defaultFilters = defaultFilters ?? {};
    this.persistFilters = persistFilters;
    this.entityManagerId = entityManagerId;
    this.defaultMultiSortMeta = defaultMultiSortMeta;
    this.searchFilters = { ...this.defaultFilters };

    const defaultSortValues = convertSortMetaArrayToDataSortValueArray(defaultMultiSortMeta);

    if (persistFilters) {
      const storeData = this.appManagerService.getStorageData(SESSION_STORAGE_NAV, entityManagerId);

      if (storeData && Object.keys(storeData)?.length) {
        this.advancedSearch = storeData.advancedSearch ?? this.advancedSearch;
        this.paginatorFirst = storeData.paginatorFirst ?? this.paginatorFirst;
        this.paginatorRows = storeData.paginatorRows ?? this.paginatorRows;
        this.multiSortMeta = convertDataSortValueArrayToSortMetaArray(storeData.sortValues);

        if (storeData.entitySearch) {
          this.searchFilters = storeData.entitySearch;
          this.dataSearch = buildDataSearch(this.searchFilters, this.searchFieldsConf, this.advancedSearch, storeData.sortValues ?? defaultSortValues, this.paginatorFirst, this.paginatorRows);
        }
      }
      else {
        this.multiSortMeta = defaultMultiSortMeta;

        this.dataSearch = buildDataSearch(defaultFilters ?? {}, this.searchFieldsConf, this.advancedSearch,
          defaultSortValues, this.paginatorFirst, this.paginatorRows);
      }
    }
    else {
      this.multiSortMeta = defaultMultiSortMeta;

      this.dataSearch = buildDataSearch(defaultFilters ?? {}, this.searchFieldsConf, this.advancedSearch, defaultSortValues, this.paginatorFirst, this.paginatorRows);
    }

    this._multiSortMeta$.next(this.multiSortMeta);
    this._dataSearch$.next(null);
  }

  setDefaultFilters(defaultFilters: Object, search: boolean = true): void {
    this.defaultFilters = defaultFilters
    this.searchFilters = { ...this.searchFilters, ...defaultFilters };
    this.dataSearch = buildDataSearch(this.searchFilters, this.searchFieldsConf, this.advancedSearch, this.dataSearch.dataSortValues, this.dataSearch.pageFirst, this.dataSearch.pageSize);
    if (search) {
      this.search();
    }
  }

  setSearchFilters(searchFilter: Object, search: boolean = true): void {
    this.searchFilters = !searchFilter ? { ...this.defaultFilters } : { ...searchFilter, ...this.defaultFilters };
    this.dataSearch = buildDataSearch(this.searchFilters, this.searchFieldsConf, this.advancedSearch, this.dataSearch?.dataSortValues ?? [], this.dataSearch?.pageFirst, this.dataSearch?.pageSize);

    if (search) {
      this.search();
    }
  }

  setPage(page: number, pageSize: number, search: boolean = true): void {
    this.paginatorFirst = page;
    this.paginatorRows = pageSize;

    this.dataSearch = buildDataSearch(this.searchFilters, this.searchFieldsConf, this.advancedSearch, this.dataSearch.dataSortValues, page, pageSize);

    if (search) {
      this.search();
    }
  }

  setSorting(multiSortMeta: SortMeta[], search: boolean = true): void {
    const dataSortValues = convertSortMetaArrayToDataSortValueArray(multiSortMeta);
    this.multiSortMeta = multiSortMeta;
    this.dataSearch = buildDataSearch(this.searchFilters, this.searchFieldsConf, this.advancedSearch, dataSortValues, this.dataSearch.pageFirst, this.dataSearch.pageSize);

    this._multiSortMeta$.next(this.multiSortMeta);

    if (search) {
      this.search();
    }
  }

  setAdvancedSearch(advancedSearch: boolean): void {
    this.advancedSearch = advancedSearch;
  }

  getDefaultFilters(): Object {
    return this.defaultFilters;
  }

  getSearchFilters(): Object {
    return this.searchFilters;
  }

  getAdvancedSearch(): boolean {
    return this.advancedSearch;
  }

  private saveFiltersInStore(): void {
    if (this.persistFilters) {
      const data = {
        advancedSearch: this.advancedSearch,
        entitySearch: this.searchFilters,
        paginatorFirst: this.paginatorFirst,
        paginatorRows: this.paginatorRows,
        sortValues: this.dataSearch.dataSortValues
      };

      this.appManagerService.setStorageData(SESSION_STORAGE_NAV, this.entityManagerId, data);
    }
  }

  private search() {
    this.saveFiltersInStore();
    this._dataSearch$.next(this.dataSearch);
  }

  reset(search: boolean = true): void {
    this.setSorting(this.defaultMultiSortMeta, false);
    this.setPage(this.defaultPaginatorFirst, this.defaultPaginatorRows, false);
    this.setSearchFilters(this.defaultFilters, false);

    if (search) {
      this.search();
    }
  }
}
