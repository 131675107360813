import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { GenNota } from 'src/app/models/anagrafiche/gen-nota';
import { GenNotaTipo } from 'src/app/models/anagrafiche/gen-nota-tipo';
import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from 'src/app/models/data-search';
import { IEntityList } from 'src/app/models/entity';
import { ExtEntityInfoPars } from 'src/app/models/gestione/ext-entity-info-pars';
import { ToastMessageData } from 'src/app/models/message';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { DataService } from 'src/app/services/data.service';
import { GenNotaService } from 'src/app/services/gennota.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { dateFormat } from 'src/app/utils/util';

@Component({
  selector: 'app-gen-nota-add',
  templateUrl: './gen-nota-add.component.html',
  styleUrls: ['./gen-nota-add.component.scss']
})
export class GenNotaAddComponent implements OnInit, OnDestroy {
  dateFormat: string = dateFormat;
  T$: Observable<Resources>;
  moadlNotaAdd$: Observable<ExtEntityInfoPars>;
  tipiNoteList$: Observable<GenNotaTipo[]>;
  dataForm: FormGroup;
  elaboration: boolean = false;
  resourceList: Resources;
  visible: boolean = false;

  private subscription: Subscription;

  @Output() onHide = new EventEmitter<boolean>();

  constructor(
    private dataService: DataService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private appManagerService: AppManagerService,
    private genNotaService: GenNotaService) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.moadlNotaAdd$ = this.genNotaService.getNotaSubject();

    // Etichette
    this.T$ = this.translateService.translatedItems$(['generic.', 'gennote.']).pipe(
      tap(translateItems => this.resourceList = translateItems)
    );

    // Carico i tipi di note
    this.tipiNoteList$ = this.moadlNotaAdd$.pipe(
      filter((data: ExtEntityInfoPars) => data.open),
      tap((data: ExtEntityInfoPars) => {
        const { extId, extTableName } = data;
        this.visible = data.open;
        this.dataForm.reset({ extId, extTableName, id: 0, ambitoId: 0, oggetto: '' }, { emitEvent: false });
      }),
      switchMap((data: ExtEntityInfoPars) => this.dataService.getGeneric(`${this.dataService.configSettings.restCommonUrl}/gennotatipobyexttablename`, `extTablename=${data.extTableName}`)),
      map((data: IEntityList<GenNotaTipo>) => data.entities)
    );

    // Costruisco il form
    this.dataForm = this.fb.group({
      id: 0,
      ambitoId: [0, Validators.required],
      extId: [null, Validators.required],
      notaTipoId: [null, Validators.required],
      oggetto: null,
      nota: [null, Validators.required],
      userIns: null,
      dtIns: null,
      userUpd: null,
      dtUpd: null,
      notaTipoDes: null,
      extTableName: [null, Validators.required]
    });
  }

  hideModal(): void {
    this.visible = false;
    this.onHide.emit(false);
  }

  save(): void {
    this.elaboration = true;
    const data = this.dataForm.value as GenNota;

    this.subscription.add(
      this.dataService.insertElement<GenNota>(`${this.dataService.configSettings.restCommonUrl}/gennota`, data).subscribe(
        (data: GenNota) => {
          this.elaboration = false;
          this.appManagerService.showToastMessage(new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.savesuccessmsg')));
          this.hideModal();
        },
        (error: HttpErrorResponse) => {
          this.elaboration = false;
        }
      )
    );
  }

}
