import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { dateFormat } from 'src/app/utils/util';
import { CalCaleStatiWidgetDto } from 'src/app/models/calendario/dto/cal-cale-stati-widget-dto';


@Component({
  selector: 'app-calendario-stati-widget',
  templateUrl: './calendario-stati-widget.component.html',
  styleUrls: ['./calendario-stati-widget.component.scss']
})

export class CalendarioStatiWidgetComponent implements OnInit {
  dateFormat = dateFormat;
  data$: Observable<CalCaleStatiWidgetDto[]>;
  T$: Observable<Resources>;
  yearsQty: number = 2;
  currYear: number;
  startYear: number;
  years: number[] = [];
  elaboration: boolean = false;
  chartOptions: any;
  datasets: any;
  statesColors: string[] = [
    '#00bfb2',
    '#ffeb74',
    '#f79563',
    '#f25d5a',
    '#006ad1',
    '#79c362'
  ];

  // 10% darken colour
  statesHoverColors: string[] = [
    '#008c82',
    '#ffe441',
    '#f57432',
    '#ee2f2b',
    '#00509e',
    '#5daf43'
  ];

  constructor(
    private dataService: DataService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    // Etichette
    this.T$ = this.translateService.translatedItems$(['generic.', 'calendariostatiwidget.']);

    // Recuperol'anno corrente e creo l'arrayy degli anni
    this.currYear = new Date().getFullYear();
    this.startYear = this.currYear + 1;

    for (let i = 0; i < this.yearsQty; i++) {
      this.years.push(this.startYear + i);
    }

    this.chartOptions = {
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
        },
        tooltips: {
          mode: 'index',
          intersect: false
        }
      }
    };

    this.elaboration = true;

    this.data$ = this.dataService.getElements<CalCaleStatiWidgetDto>(`${this.dataService.configSettings.restCalendarioUrl}/GetCalCaleStatiWidget`, `startYear=${this.startYear.toString()}&yearsQty=${this.yearsQty.toString()}`).pipe(
      tap(
        (data: any) => {
          this.datasets = this.generateDataSet(data, this.years);
        }
      )
    );
  }

  private generateDataSet(data, years) {
    const datasets = [];

    years.forEach(y => {
      const yearData = data.filter(d => d.anno === y);
      if (yearData.length > 0) {
        const dtset = {
          anno: y,
          labels: yearData.map(d => d.statoCalDes),
          datasets: [{
            data: yearData.map(d => d.totCalendari),
            backgroundColor: this.statesColors,
            hoverBackgroundColor: this.statesHoverColors
          }]
        };
        datasets.push(dtset);
      }
    })

    return datasets;
  }

}
