import { DatePipe } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  CmdFieldTarget,
  CmdFieldMode,
  CmdFieldKey,
  FormField,
  EntityManager,
  FormFieldType
} from '../../../models/entity-config';
import { Resources, TranslateService } from '../../../services/translate.service';

@Component({
  selector: 'entity-field-view',
  templateUrl: './entity-field-view.component.html',
  styleUrls: ['./entity-field-view.component.scss']
})

export class EntityFieldViewComponent implements OnInit, OnDestroy {

  cmdFieldTarget = CmdFieldTarget;
  cmdFieldMode = CmdFieldMode;
  cmdFieldKey = CmdFieldKey;

  @Input() entityManager: EntityManager;
  @Input() field: FormField;
  @Input() data: any;
  @Output() onPanelShow = new EventEmitter();

  fieldValue: string;
  fieldIcon: string;
  fieldClass: string;
  fieldYesNoValue: boolean | number;
  fieldYesNoLabels: { yes: string, no: string };

  translateItems: Resources;
  private subscription: Subscription;

  constructor(
    private datePipe: DatePipe,
    private translateService: TranslateService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    // translations
    this.subscription.add(
      this.translateService.translatedItems$(this.entityManager.translateSuffixs)
        .subscribe(items => {
          this.translateItems = items;
        })
    );

    let fieldValue = this.entityManager.getFormFieldValue(this.field, this.data);
    switch (this.field.type) {
      case FormFieldType.date:
        this.fieldValue = this.datePipe.transform(fieldValue, this.field.format.format);
        break;

      case FormFieldType.number:
      case FormFieldType.boolean:
        if (this.field?.format?.icon) {
          this.fieldIcon = this.field.format.icon[fieldValue];
        }
        else if (this.field?.format?.yesNo) {
          this.fieldYesNoValue = fieldValue;
          this.fieldYesNoLabels = {
            yes: this.translateService.translate(this.translateItems, this.field.format.yesNo.yesLbl),
            no: this.translateService.translate(this.translateItems, this.field.format.yesNo.noLbl)
          }
        }
        else {
          this.fieldValue = fieldValue;
        }
        break;

      case FormFieldType.enum:
        if (this.field?.format?.icon) {
          this.fieldIcon = this.field.format.icon[fieldValue];
        }
        else {
          this.fieldValue = fieldValue;
          if (this.translateItems && this.field.translatePrefix) {
            this.fieldValue = this.translateService.translate(
              this.translateItems, `${this.field.translatePrefix}.${this.fieldValue}`
            );
          }
        }
        break;

      case FormFieldType.stateBadge:
        if (this.field?.format?.stateBadge) {
          this.fieldClass = `${this.field.format.stateBadge['classPrefix']}-${this.data[this.field.format.stateBadge['stateIdField']]}`;
        }
        this.fieldValue = fieldValue;
        break;

      default:
        this.fieldValue = fieldValue;
        break;

    }
  }

}
