import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticatedResult, OidcSecurityService } from 'angular-auth-oidc-client';
import { PrimeNGConfig, Translation } from 'primeng/api';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { AppManagerRoutes } from './app-manager-routes';
import { AppInitialized, AppManagerService } from './services/app-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  // private subscription: Subscription;

  isAuthenticated$: Observable<AuthenticatedResult>;

  appInitialized$: Observable<AppInitialized>;

  it: Translation = {
    firstDayOfWeek: 1,
    dayNames: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
    dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
    monthNames: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
    monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
    today: 'Oggi',
    clear: 'Cancella',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Settimane',
    emptyFilterMessage: 'Valore non trovato',
    emptyMessage: 'Nessun elemento',
    weak: 'Debole',
    medium: 'Media',
    strong: 'Forte',
    passwordPrompt: 'Inserire una password'
  };

  get isHiddenHeader(): boolean {
    return this.router.url.includes(AppManagerRoutes.BrowserWindow.url);
  }

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private appManagerService: AppManagerService,
    private config: PrimeNGConfig,
    public router: Router
  ) {
    // this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.appManagerService.dispose();
    // this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.isAuthenticated$ = this.oidcSecurityService.isAuthenticated$;

    this.appInitialized$ = this.appManagerService.initialized$;

    this.oidcSecurityService.checkAuth()
      .pipe(
        filter(t => t.isAuthenticated),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: loginResponse => {
          this.appManagerService.initUser(loginResponse.userData, 'init')
          console.log('AppComponent - user authenticated');
        }
      });

    this.config.setTranslation(this.it);
    // Viene settato per ovviare ad un bug #13216 di PrimeNg sulle multiselect che alla deselzione di un elemento chiude l'overlay
    this.config.overlayOptions.appendTo = 'body';


    window.onbeforeunload = () => this.ngOnDestroy();
  }

}
