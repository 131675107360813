import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { MessageDataConfig, MessageDataFromConfig } from 'src/app/models/message';
import { WindowSaveData } from '../../../guards/data-saved-guard';
import { buildQueryStringFromActivatedRoute } from '../../../models/data-search';
import { IEntity } from '../../../models/entity';
import { CmdField, CmdFieldKey, CmdFieldTarget, CmdFieldMode, CmdEvent, EntityManager } from '../../../models/entity-config';
import { AppManagerService, SESSION_STORAGE_NAV } from '../../../services/app-manager.service';
import { EntityRefresh, IEntityService } from '../../../services/entity.service';
import { Resources, TranslateService } from '../../../services/translate.service';
import { EntityListEditableComponent } from '../../entity/entity-list-editable/entity-list-editable.component';


@Component({
  selector: 'window-list-editable-search',
  templateUrl: './window-list-editable-search.component.html',
  styleUrls: ['./window-list-editable-search.component.scss']
})

export class WindowListEditableSearchComponent implements WindowSaveData, OnInit, OnDestroy {
  cmdFieldTarget = CmdFieldTarget;
  cmdFieldMode = CmdFieldMode;
  cmdFieldKey = CmdFieldKey;

  private fieldLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.none, target: CmdFieldTarget.none };

  entityRefresh$: Observable<EntityRefresh>;
  // entityManager: EntityManager;
  // dataSearch: any;
  @Input() entityManager: EntityManager;
  @Input() dataSearch: any;
  @Output() selectEvent = new EventEmitter<any>();
  // @Output() showEvent = new EventEmitter<any>();
  textHeader: string;
  entityInfo: any;

  // translateItems: Resources;
  translatedItems$: Observable<Resources>;

  @ViewChild(EntityListEditableComponent) entityListEditableComponent;

  private subscription: Subscription;
  private entityService: IEntityService<IEntity>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appManagerService: AppManagerService,
    private translateService: TranslateService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    if (this.entityManager.getClearCache()) {
      this.appManagerService.clearStorageData(SESSION_STORAGE_NAV, this.entityManager.id);
    }
    this.appManagerService.clearBrowserTab();
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {

    this.subscription.add(
      this.appManagerService.initialized$
        .subscribe(appInitialized => {
          if (!appInitialized.initialized) {
            return EMPTY;
          }

          if (!this.entityManager) {
            this.entityManager = this.appManagerService.getEntityManager(this.activatedRoute);
          }

          this.entityManager.isCompleted = () => {
            if (!this.entityListEditableComponent) {
              return true;
            }

            const index = this.entityListEditableComponent?.entity?.index;
            const dataForm = this.entityListEditableComponent?.dataFormArray?.controls[index];
            return dataForm?.pristine ?? true;
          }

          this.entityService = this.entityManager.getEntityService();
          if (!this.entityService) {
          }
          this.entityRefresh$ = this.entityService.entityRefresh$;
          if (!this.dataSearch) {
            this.dataSearch = this.entityManager.getEntitySearch();
          }

          this.textHeader = this.entityManager.getTextComponent("windowListHeader");
          this.entityInfo = this.dataSearch ?? this.entityManager.getEntity();

          this.translatedItems$ = this.translateService.translatedItems$(this.entityManager.translateSuffixs);

          // get new instance template from service using parms in ActivatedRoute
          const newInstanceFields = this.entityManager.getNewInstanceFields();
          if (newInstanceFields.length > 0) {
            this.subscription.add(
              this.entityService.getNewInstance(this.fieldLoad, buildQueryStringFromActivatedRoute(this.activatedRoute, newInstanceFields))
                .subscribe(entityEvent => {
                  if (entityEvent.entity) {
                    this.entityManager.setEntity(entityEvent.entity);
                  }
                })
            );
          }

        })
    );

  }

  onCmd(field: CmdField) {
    if (this.entityManager.onCmd({ field: field })) return;

    switch (field.key) {
      case CmdFieldKey.back:
        const navItem = this.appManagerService.getBackUrl();
        this.router.navigate([navItem.url], { queryParams: navItem.queryParams });
        break;
      case CmdFieldKey.openBrowserWindow:
        this.appManagerService.openBrowserTab(field.entityManagerInfo, this.entityManager.getSection());
        break;
    }
  }

  onSearch(event: CmdEvent) {
    this.entityListEditableComponent.search(event.data.data, event.data.advancedSearch)
  }

  onSelectEvent(event: any) {
    if (this.selectEvent.observers.length > 0) {
      this.selectEvent.emit(event);
    }
  }

  onDeleteEntities(event: any) {
    const messageData = new MessageDataFromConfig(MessageDataConfig.Delete, () => {
      this.entityService.deleteEntities(event.field, [event.data.id]);
    });

    this.appManagerService.showMessage(messageData);
  }

}
