<ng-container *ngIf="T$ | async as T">
  <div *ngIf="data$ | async as calCaleAnomalieWidgetList"
    class="surface-50 border-1 border-300 border-solid h-full widget-card">
    <div class="surface-200 text-2xl font-medium text-primary p-3 header">
      {{T | translateItem: 'calendarioanomaliewidget.titolo'}}
    </div>
    <div class="flex flex-column justify-content-center">
      <div class="px-4 flex-grow-1">
        <p *ngIf="years?.length === 0; else tabView" class="font-medium text-center p-3 text-600">
          {{T | translateItem: 'generic.listEmpty'}}
        </p>
        <ng-template #tabView>
          <p-tabView>
            <p-tabPanel *ngFor="let year of years; let i = index" [header]="year.toString()">
              <ng-container *ngIf="(calCaleAnomalieWidgetList | filterbypar: year : 'anno') as yearData">
                <ng-container *ngIf="yearData.length > 0; else noYearData">
                  <div *ngFor="let cale of yearData; let i = index" class="chart-grid my-3">
                    <p-divider *ngIf="i > 0" class="my-0"></p-divider>
                    <div class="mb-2">
                      <span class="block font-medium mt-1">{{cale.tipoCalDes}}</span>
                      <span class="text-sm">{{cale.tipoPeriodoCalDes}}</span>
                    </div>
                    <p-chart *ngIf="cale.anomalieStatoList.length > 0; else noData" type="doughnut"
                      [data]="(datasets | filterbypar: cale.id : 'id')[0]" [responsive]="true" [options]="chartOptions"
                      height="140px">
                    </p-chart>
                    <ng-template #noData>
                      <div class="flex flex-column align-items-center mt-2">
                        <i class="fa-solid fa-circle-check text-3xl success"></i>
                        <p class="font-medium text-center mt-2 text-600">
                          {{T | translateItem: 'calendarioanomaliewidget.noanomalie'}}
                        </p>
                      </div>
                    </ng-template>
                  </div>
                </ng-container>
              </ng-container>
              <ng-template #noYearData>
                <p class="font-medium text-center mt-2 text-600">
                  {{T | translateItem: 'calendarioanomaliewidget.noyeardata'}}
                </p>
              </ng-template>
            </p-tabPanel>
          </p-tabView>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>