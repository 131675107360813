<ng-container *ngIf="T$ | async as T">
  <ng-container *ngIf="advancedSearch; else searchFields">
    <form [formGroup]="dataForm" novalidate
      class="flex flex-column md:flex-row md:flex-wrap column-gap-2 mb-3 row-gap-2">
      <div class="flex-grow-1">
        <div class="formgrid grid row-gap-2">
          <!-- Multiselect search -->
          <ng-template #multiselectSearch let-controlName="controlName" let-options="options" ,
            let-optionValue="optionValue" let-optionLabel="optionLabel" let-placeholderKey="placeholderKey"
            let-defaultValue="defaultValue" let-showToggleAll="showToggleAll">
            <div class="p-inputgroup">
              <p-multiSelect [options]="options" [formControlName]="controlName" [optionLabel]="optionLabel ?? 'des'"
                [optionValue]="optionValue ?? 'id'" styleClass="multiselect-inputgroup"
                [placeholder]="T | translateItem: placeholderKey" class="inputfield multiselect-sm"
                [emptyMessage]="T | translateItem: 'generic.dropdownempty'" [maxSelectedLabels]="1"
                [selectedItemsLabel]="T | translateItem: 'generic.selecteditems'" [filter]="true"
                [autofocusFilter]="true" [resetFilterOnHide]="true" [showToggleAll]="showToggleAll ?? true">
              </p-multiSelect>
              <button type="button" pButton icon="fa-solid fa-xmark" class="p-button-outlined p-button-plain"
                (click)="resetFilterControl(controlName, defaultValue)"></button>
            </div>
          </ng-template>

          <!-- Date search -->
          <ng-template #dateSearch let-controlName="controlName" let-placeholderKey="placeholderKey"
            let-defaultValue="defaultValue" let-minDate="minDate" let-maxDate="maxDate">
            <div class="p-inputgroup">
              <p-calendar [formControlName]="controlName" [showIcon]="true" [dateFormat]="dateFormatPrNg"
                [firstDayOfWeek]="1" styleClass="multiselect-inputgroup" class="inputfield p-inputtext-sm"
                [placeholder]="T | translateItem: placeholderKey" [minDate]="minDate" [maxDate]="maxDate">
              </p-calendar>
              <button type="button" pButton icon="fa-solid fa-xmark" class="p-button-outlined p-button-plain"
                (click)="resetFilterControl(controlName, defaultValue)"></button>
            </div>
          </ng-template>

          <div class="col-12 sm:col-6 md:col-4 lg:col-2">
            <ng-container
              *ngTemplateOutlet="dateSearch; context: {controlName: 'dataInizio', placeholderKey: 'giudicisospensioni.dataInizio'}" />
          </div>

          <div class="col-12 sm:col-6 md:col-4 lg:col-2">
            <ng-container
              *ngTemplateOutlet="dateSearch; context: {controlName: 'dataFine', placeholderKey: 'giudicisospensioni.dataFine'}" />
          </div>

          <div class="col-12 sm:col-6 md:col-4 lg:col-2">
            <ng-container
              *ngTemplateOutlet="multiselectSearch; context: {controlName: 'tipoSospensioneId', options: tipoSospensioniList$ | async,  optionValue:'id',  optionLabel:'des', placeholderKey: 'giudicisospensioni.tipoSospensione'}" />
          </div>

          <div class="col-12 sm:col-6 md:col-4 lg:col-2">
            <ng-container
              *ngTemplateOutlet="multiselectSearch; context: {controlName: 'disponenteSospensioneId', options: disponenteSospensioniList$ | async,  optionValue:'id',  optionLabel:'des', placeholderKey: 'giudicisospensioni.disponenteSospensione'}" />
          </div>

          <div class="col-12 sm:col-6 md:col-4 lg:col-2">
            <div class="p-inputgroup">
              <input pInputText type="text" formControlName="note"
                [placeholder]="T | translateItem: 'giudicisospensioni.note'" class="inputfield p-inputtext-sm">
              <button type="button" pButton icon="fa-solid fa-xmark" class="p-button-outlined p-button-plain"
                (click)="resetFilterControl('note', null)"></button>
            </div>
          </div>

          <div class="col-12 sm:col-6 md:col-2 lg:col-1 flex">
            <p-checkbox formControlName="flSospensioneIncorso"
              [label]="T | translateItem: 'giudicisospensioni.sospensioneIncorso'" [value]="true" [binary]="true"
              class="inputfield" />
          </div>
        </div>
      </div>

      <div class="buttons-cnt flex">
        <button pButton type="submit" class="p-button-outlined p-button-sm" icon="fa-solid fa-search"
          [title]="T | translateItem: 'generic.search'" [label]="T | translateItem: 'generic.search'" (click)="search()"
          [disabled]="elaboration || !dataForm.valid">
        </button>

        <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter-circle-xmark"
          [title]="T | translateItem: 'generic.searchReset'" [label]="T | translateItem: 'generic.searchReset'"
          (click)="resetSearch()" [disabled]="elaboration">
        </button>

        <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter"
          [title]="T | translateItem: 'generic.searchBasic'" [label]="T | translateItem: 'generic.searchBasic'"
          (click)="toggleSearch()" [disabled]="elaboration">
        </button>
      </div>
    </form>
  </ng-container>
  <ng-template #searchFields>
    <form [formGroup]="dataForm" novalidate
      class="flex flex-column md:flex-row md:flex-wrap column-gap-2 mb-3 row-gap-2">
      <div class="flex-grow-1">
        <div class="formgrid grid row-gap-2">
          <div class="col-12">
            <input pInputText type="text" formControlName="simpleSearch"
              [placeholder]="T | translateItem: 'giudicisospensioni.simpleSearchPlaceholder'"
              class="inputfield p-inputtext-sm">
          </div>
        </div>
      </div>

      <div class="buttons-cnt flex">
        <button pButton type="submit" class="p-button-outlined p-button-sm" icon="fa-solid fa-search"
          [title]="T | translateItem: 'generic.search'" [label]="T | translateItem: 'generic.search'" (click)="search()"
          [disabled]="elaboration || !dataForm.valid">
        </button>

        <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter-circle-xmark"
          [title]="T | translateItem: 'generic.searchReset'" [label]="T | translateItem: 'generic.searchReset'"
          (click)="resetSearch()" [disabled]="elaboration">
        </button>

        <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter"
          [title]="T | translateItem: 'generic.searchAdvanced'" [label]="T | translateItem: 'generic.searchAdvanced'"
          (click)="toggleSearch()" [disabled]="elaboration">
        </button>
      </div>
    </form>
  </ng-template>

  <p-table #dataTable [value]="entities$ | async" [totalRecords]="paginatorRowsTot" [lazy]="true" [paginator]="true"
    [first]="paginatorFirst" [rows]="paginatorRows" [rowsPerPageOptions]="[10, 25, 50, 100]"
    (onLazyLoad)="onLazyLoad($event)" (onPage)="onPage($event)" [showCurrentPageReport]="true"
    [currentPageReportTemplate]="T | translateItem: paginatorRowsTot ? 'generic.listReport' : 'generic.listEmpty'"
    sortMode="multiple" [multiSortMeta]="multiSortMeta" breakpoint="960px"
    styleClass="p-datatable-striped p-datatable-md" paginatorPosition="bottom">
    <ng-template pTemplate="header">
      <tr>
        <th class="white-space-nowrap" pSortableColumn="dataInizio">
          {{T | translateItem: 'giudicisospensioni.dataInizio'}}
          <p-sortIcon field="dataInizio" />
        </th>

        <th class="white-space-nowrap" pSortableColumn="dataFine">
          {{T | translateItem: 'giudicisospensioni.dataFine'}}
          <p-sortIcon field="dataFine" />
        </th>

        <th class="white-space-nowrap" pSortableColumn="tipoSospensioneDes">
          {{T | translateItem: 'giudicisospensioni.tipoSospensione'}}
          <p-sortIcon field="tipoSospensioneDes" />
        </th>

        <th class="white-space-nowrap" pSortableColumn="disponenteSospensioneDes">
          {{T | translateItem: 'giudicisospensioni.disponenteSospensione'}}
          <p-sortIcon field="disponenteSospensioneDes" />
        </th>

        <th class="white-space-nowrap" pSortableColumn="note">
          {{T | translateItem: 'giudicisospensioni.note'}}
          <p-sortIcon field="note" />
        </th>

        <th class="white-space-nowrap" pSortableColumn="flSospensioneIncorso">
          {{T | translateItem: 'giudicisospensioni.sospensioneIncorso'}}
          <p-sortIcon field="flSospensioneIncorso" />
        </th>

        <th *ngIf="!readOnly" class="white-space-nowrap buttons-column">
          <div class="buttons-cnt">
            <button *grantVisible="grants$ | async; key 'add'" pButton type="button" class="p-button-md"
              (click)="modalInsertShow()" [label]="T | translateItem: 'generic.add'" [disabled]="elaboration "
              [title]="T | translateItem: 'generic.add'" icon="fa-solid fa-plus"></button>
          </div>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudicisospensioni.dataInizio'}}
          </span>
          {{rowData.dataInizio | date: dateFormat}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudicisospensioni.dataFine'}}
          </span>
          {{rowData.dataFine | date: dateFormat}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudicisospensioni.tipoSospensione'}}
          </span>
          {{rowData.tipoSospensioneDes | titlecase}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudicisospensioni.disponenteSospensione'}}
          </span>
          {{rowData.disponenteSospensioneDes | titlecase}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudicisospensioni.note'}}
          </span>
          {{rowData.note}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudicisospensioni.sospensioneIncorso'}}
          </span>
          <span *ngIf="rowData.flSospensioneIncorso" class="state-badge danger-background">
            {{T | translateItem: 'giudicisospensioni.sospensioneIncorso'}}
          </span>
        </td>

        <td *ngIf="!readOnly" class="buttons-column">
          <div class="buttons-cnt">
            <button *grantVisible="grants$ | async; key 'edit'" pButton type="button"
              [title]="T | translateItem: 'generic.edit'" (click)="modalEditShow(rowData)"
              class="p-button-sm p-button-outlined" icon="fa-solid fa-pencil" [disabled]="elaboration"></button>

            <button *grantVisible="grants$ | async; key 'delete'" pButton type="button"
              class="p-button-outlined p-button-sm p-button-danger" [disabled]="elaboration" (click)="delete([rowData])"
              [title]="T | translateItem: 'generic.delete'" icon="fa-solid fa-trash"></button>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="100%" class="text-center danger">
          {{T | translateItem: 'generic.norecordsfound'}}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <!-- Modale edit -->
  <app-giud-sospensione-edit *ngIf="modalEdit?.visible" [visible]="modalEdit?.visible" [entity]="modalEdit.entity"
    [giudiceId]="modalEdit.giudiceId" (onHide)="modalEditHide($event)" />
</ng-container>