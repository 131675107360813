<div [formGroup]="formGroup">
  <label class="text-sm mb-1 block">{{noteLabel}}</label>

  <div *ngIf="messageVisible; else templateSelector;"
    class="flex justify-content-between align-items-center gap-2 badge-outlined-warning warning">
    <div>
      {{warningMessage}}
    </div>

    <div class="buttons-cnt">
      <p-button [label]="cancelLabel" [title]="cancelTitle" [text]="true" size="small" icon="fa-solid fa-times"
        (click)="cancelSetNotaSospensione()" />

      <p-button [label]="okLabel" [title]="okTitle" [text]="true" size="small" icon="fa-solid fa-check"
        (click)="confirmSetNotaSospensione()" />
    </div>
  </div>

  <ng-template #templateSelector>
    <p-dropdown [options]="templates" (onChange)="onTemplateChange($event)" [placeholder]="templatePlaceholder"
      [optionValue]="optionLabel" [optionLabel]="optionLabel" class="inputfield p-inputtext-sm" [filter]="true"
      [autofocusFilter]="true" [resetFilterOnHide]="true" appendTo="body" [(ngModel)]="template"
      [ngModelOptions]="{standalone: true}" />
  </ng-template>

  <div class="mt-1">
    <textarea pInputTextarea formControlName="note" [placeholder]="notePlaceholder"
      class="inputfield p-inputtext-sm"></textarea>
  </div>
</div>
