import { AppManagerRoutes } from "../../app-manager-routes";
import { CmdField, CmdFieldKey, CmdFieldMode, CmdFieldTarget, EntityManagerConfig, } from "../entity-config";

export class GiudiceProfiloEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      // Singolo giudice
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudiceProfiloDetail, label: 'menu.giudiceProfiloDetail.label', title: 'menu.giudiceProfiloDetail.title'
      },

      // Corsi giudice
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.EduCorsoList, label: 'menu.eduCorsoList.label', title: 'menu.eduCorsoList.title'
      },

      // Richieste giudice
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudRichiestaGiudiceList, label: 'menu.giudRichiestaGiudiceList.label', title: 'menu.giudRichiestaGiudiceList.title'
      },

      // Assistentati giudice
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudAssistentatoGiudiceList, label: 'menu.giudAssistentatoGiudiceList.label', title: 'menu.giudAssistentatoGiudiceList.title'
      },
    ];
  }
}
