import { Entity } from "../entity";

export class GiudBloccoPrv extends Entity {
  giudiceId: number;
  tipoManiId: number;
  codProtipo: string;
  dataInizio: Date;
  dataFine: Date;
  note: string;
  temaIdPratica?: number;

  tipoManiCod: string;
  tipoManiDes: string;
  prvSpConfigDes: string;
  flBloccoIncorso: boolean;
}