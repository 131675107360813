import { Entity } from "../entity";
import { CalCaleManiComi } from "./cal-cale-mani-comi";
import { CalCaleManiGiudRagg } from "./cal-cale-mani-giud-ragg";
import { CalCaleManiPare } from "./cal-cale-mani-pare";

export class CalCaleMani extends Entity {
  caleId: number;
  maniId: number;
  tipoManiId: number;
  dataInizio: Date;
  dataFine: Date;
  dataInizioAlt: Date;
  dataFineAlt: Date;
  dataInizioProp: Date;
  dataFineProp: Date;
  statoManiId: number;
  des: string;
  cap: string;
  localita: string;
  indirizzo: string;
  comuneId?: number;
  nazioneId: number;
  flCacib: boolean;
  flCac: boolean;
  flMondiale: boolean;
  flEuropea: boolean;
  flFissa: boolean;
  flRazzeItaliane: boolean;
  flAbilitaCampionatoSociale: boolean;
  note: string;
  desAgg1: string;
  desAgg2: string;

  caleEventoId?: number;
  coordinate: string;
  parteGiorno: string; // M = Mattina, P = Pomeriggio, G = Giornata intera
  circuitoId: number;

  circuitoDes: string;
  caleDes: string;
  caleEventoDes: string;
  tipoManiCod: string;
  tipoManiDes: string;
  statoManiCod: string;
  statoManiLivello: number;
  statoManiDes: string;
  comitatoOrganizzatorePrincipaleId?: number; // Variabile d'appogio usata per inserimento comitato
  comitatoOrganizzatorePrincipaleDes?: string;
  comitatoOrganizzatorePrincipaleIndirizzo?: string;
  respFiscaleId?: number; // Variabile d'appogio usata per inserimento comitato
  respFiscaleDes?: string;
  respFiscaleIndirizzo?: string;
  associazioneSpecializzataRadunoDes: string;
  comitatoOrganizzatoreCollaborazioneDes: string;
  comitatoOrganizzatorePrincipaleEnciIdAnagPadre: number;
  comuneDes: string;
  provinciaId?: number;
  provinciaDes: string;
  provinciaLabel: string;
  targa: string;
  luogoLabel: string;
  regioneId?: number;
  regioneDes: string;
  nazioneDes: string;
  tipoCalCod: string;
  tipoCalDes: string;
  anno: number;
  statoCalLivello: number;
  tipoCalId: number;
  // Flag warning
  flAnomalieBloccanti: boolean;
  flAnomalieNonBloccanti: boolean;

  flAssenzaAnomalie: boolean;
  flAssenzaAnomalieBloccanti: boolean;
  flAssenzaAnomalieNonBloccanti: boolean;

  flSpWarn: boolean;
  flListinoWarn: boolean;
  flRegioneDiversaWarn: boolean;
  flDelegati: boolean;
  flGiudici: boolean;
  flComitatoPrincipale: boolean;
  flApprCcrObbligatoria: boolean;
  flApprCoObbligatoria: boolean;
  flApprAsObbligatoria: boolean;
  flGiudiciItaWarn: boolean;
  flPresenzaSpeciali: boolean;
  flPresenzaSpecialiNonAutorizzate: boolean;
  flGestSpecialeInCorso: boolean;
  flOmologazioneInviata: boolean;
  flDocumentoRatificaGenerato: boolean;
  flDocumentoRatificaDelegato: boolean;
  flDocumentoTrasmissioneCartellini: boolean;
  idIbanLemonwayFinalizzazione: string;

  colTecCod: string;
  label: string
  flCacPrv: boolean;
  flCacitPrv: boolean;
  flPrevedeSpSpeciale: boolean;
  flFerma: boolean;

  dateList: Date[];
  calCaleManiComiList: CalCaleManiComi[];
  calCaleManiPareList: CalCaleManiPare[];
  calCaleManiGiudRaggList: CalCaleManiGiudRagg[];
  azioni: { [key: string]: boolean };
  socioCollettivoPrincipaleId?: number; // Variabile d'appogio usata per inserimento socio collettivo
  flComiWarn: boolean;
  flRichiestaModifica: boolean;
  gestioneFinalizzazione: string;
  flDocumentazioneAutorizzativa: boolean;
  flFinalizzazioneAvvenuta: boolean;
  flGestitaPortale: boolean;
  flWarningErroriRisultati: boolean;
  flRisultatiCaricati: boolean;
  spSpecialiCount: number;
  spCacCount: number;
  spCacitCount: number;
  spSezioniTot: number;
  flUnCacPerOgniClasse: boolean;

  eeManiId?: number;
  tipoAmbSvolgManiId?: number;
  tipoAmbSvolgManiCod: string;
  tipoAmbSvolgManiDes: string;
  flInternaC4: boolean;
  flPubblicaInPortaleIstituzionale: boolean;
  flPrevedeFigure: boolean;
  comitatiOrganizzatoriEmail: string;

  flGestionePsaCino: boolean;
  flPsaAccettazione: boolean;
  psaAccettazioneUtente: string;
  psaAccettazioneResp: string;
  psaAccettazioneTimestamp: Date;

  tipoRaduno: string;
  flHoSottoprove: boolean;
  esenzione: string;
}

export const ParteGiorno: { id: string, key: string }[] = [
  { id: 'M', key: 'generic.partigiorno.m' },
  { id: 'P', key: 'generic.partigiorno.p' },
  { id: 'G', key: 'generic.partigiorno.g' }
];
