<ng-container *ngIf="T$ | async as T">
  <p-dialog [(visible)]="visible" [modal]="true" styleClass="modal-window-size-small" [resizable]="true"
    [closeOnEscape]="true" (onHide)="hideModal()">

    <ng-template pTemplate="header">
      <span class="p-dialog-title">
        {{T | translateItem: (formMode === formModeType.insert ? 'giudiciblocchirazze.bloccoInsertModalTitle' :
        'giudiciblocchirazze.bloccoEditModalTitle')}}
      </span>
    </ng-template>

    <ng-template pTemplate="content">
      <form [formGroup]="dataForm">
        <div class="grid formgrid my-0">
          <span class="field col-12 md:col-6">
            <label class="text-sm required">{{T | translateItem: 'giudiciblocchirazze.razza'}}</label>
            <p-dropdown [options]="razzeList$ | async" formControlName="razzaId"
              [placeholder]="T | translateItem: 'giudiciblocchirazze.razza'" optionValue="id" optionLabel="razzaDes"
              class="inputfield p-inputtext-sm" [emptyMessage]="T | translateItem: 'generic.dropdownEmpty'"
              [filter]="true" [autofocusFilter]="true" [resetFilterOnHide]="true" appendTo="body">
            </p-dropdown>
          </span>

          <span class="field col-0 md:col-6">
          </span>

          <span class="field col-12 md:col-6">
            <label class="text-sm required">{{T | translateItem: 'giudiciblocchirazze.dataInizio'}}</label>
            <p-calendar formControlName="dataInizio" [showIcon]="true" [dateFormat]="dateFormatPrNg"
              [readonlyInput]="true" [placeholder]="T | translateItem: 'giudiciblocchirazze.dataInizio'"
              [firstDayOfWeek]="1" class="inputfield p-inputtext-sm" appendTo="body">
            </p-calendar>
          </span>

          <span class="field col-12 md:col-6">
            <label class="text-sm required">{{T | translateItem: 'giudiciblocchirazze.dataFine'}}</label>
            <p-calendar formControlName="dataFine" [showIcon]="true" [dateFormat]="dateFormatPrNg"
              [readonlyInput]="true" [placeholder]="T | translateItem: 'giudiciblocchirazze.dataFine'"
              [firstDayOfWeek]="1" class="inputfield p-inputtext-sm" appendTo="body">
            </p-calendar>
          </span>

          <span class="field col-12">
            <label class="text-sm">{{T | translateItem: 'giudiciblocchirazze.note'}}</label>
            <textarea pInputTextarea formControlName="note"
              [placeholder]="T | translateItem: 'giudiciblocchirazze.note'"
              class="inputfield p-inputtext-sm"></textarea>
          </span>
        </div>
      </form>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="flex justify-content-end">
        <div class="buttons-cnt">
          <app-button size="medium" [outlined]="true" [disabled]="elaboration" (onClick)="hideModal()"
            [tooltip]="T | translateItem: 'generic.back'" [label]="T | translateItem: 'generic.back'"
            icon="fa-solid fa-xmark" />

          <app-button size="medium" [disabled]="elaboration || dataForm.pristine || !dataForm.valid" (onClick)="save()"
            [tooltip]="T | translateItem: 'generic.save'" [label]="T | translateItem: 'generic.save'"
            icon="fa-solid fa-floppy-disk" />
        </div>
      </div>
    </ng-template>
  </p-dialog>
</ng-container>
