import { Validators } from "@angular/forms";
import { AppManagerRoutes } from "../../app-manager-routes";
import { optionsSINO } from "../../utils/util";
import { ComparisonOperator, DataSortValue, SortMode } from "../data-search";
import { Entity } from "../entity";
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, CmdFieldStyle, Icons, FormFieldStyle } from "../entity-config";


export class SecProfilo extends Entity {
  cod: string;
  tipologia: string;
  des: string;
  flPrevedeClub: boolean;
  regioneId: number;
  regioneCod: string;
  regioneDes: string;
  provinciaId: number;
  provinciaCod: string;
  provinciaDes: string;
  provinciaTarga: string;
  profiloCompletoDes: string;
  elementoId: number;
  ragioneSociale: string;
}

export class SecProfiloEMC extends EntityManagerConfig {

  init() {
    this.textComponent = {
      windowListHeader: `${this.name}.windowListHeader`, windowDetailHeader: `${this.name}.windowDetailHeader`
    };
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('cod', SortMode.Asc)];
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // List
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary,
      }
    ];

    this.listFields = [
      {
        key: 'id', type: FormFieldType.number, label: `${this.name}.id`, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'cod', type: FormFieldType.string, label: `${this.name}.cod`, sortable: true
      },
      {
        key: 'tipologia', type: FormFieldType.string, label: `${this.name}.tipologia`, sortable: true
      },
      {
        key: 'des', type: FormFieldType.string, label: `${this.name}.des`, sortable: true
      },
      {
        key: 'flPrevedeClub', type: FormFieldType.boolean, label: `${this.name}.prevedeclub`, sortable: true, format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } }
      }
    ];

    this.detailFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, label: `${this.name}.cod`, placeholder: `${this.name}.cod`, validators: [Validators.required]
      },
      {
        key: 'tipologia', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.cod`, placeholder: `${this.name}.tipologia`,
        validators: [Validators.required, Validators.maxLength(20)]
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, label: `${this.name}.des`, placeholder: `${this.name}.des`, validators: [Validators.required]
      },
      {
        key: 'flPrevedeClub', type: FormFieldType.boolean, typeHtml: FormFieldTypeHtml.radio, label: `${this.name}.prevedeclub`, placeholder: `${this.name}.prevedeclub`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' }, validators: [Validators.required]
      }
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'cod,des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.cod`,
        options: { searchFields: 'cod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.des`,
        options: { searchFields: 'des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }

}

export class SecProfiloModalEMC extends EntityManagerConfig {

  init() {
    this.textComponent = {};
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('cod', SortMode.Asc)];
  }

  initFields() {
    this.cmdFields = [
      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // ListEditable
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger,
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary,
      },
      {
        key: CmdFieldKey.select, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody, entityManagerInfo: AppManagerRoutes.SecTagList,
        title: 'generic.select', icon: Icons.select, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      {
        key: 'id', type: FormFieldType.number, label: `${this.name}.id`, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'cod', type: FormFieldType.string, label: `${this.name}.cod`, sortable: true
      },
      {
        key: 'tipologia', type: FormFieldType.string, label: `${this.name}.tipologia`, sortable: true
      },
      {
        key: 'des', type: FormFieldType.string, label: `${this.name}.des`, sortable: true
      },
      {
        key: 'flPrevedeClub', type: FormFieldType.boolean, label: `${this.name}.prevedeclub`, sortable: true, format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } }
      }
    ];

    this.detailFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, label: `${this.name}.cod`, placeholder: `${this.name}.cod`, validators: [Validators.required]
      },
      {
        key: 'tipologia', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.tipologia`, placeholder: `${this.name}.tipologia`,
        validators: [Validators.required, Validators.maxLength(20)]
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.textarea, label: `${this.name}.des`, placeholder: `${this.name}.des`, validators: [Validators.required]
      },
      {
        key: 'flPrevedeClub', type: FormFieldType.boolean, typeHtml: FormFieldTypeHtml.radio, label: `${this.name}.prevedeclub`, placeholder: `${this.name}.prevedeclub`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' }, validators: [Validators.required]
      }
    ];

    this.searchFields = [
      {
        key: 'hiddenId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: {
          searchFields: 'id', searchCompOp: ComparisonOperator.NotIn,
        }
      },
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'cod,des', searchCompOp: ComparisonOperator.Contains },
        class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.cod`,
        options: { searchFields: 'cod', searchCompOp: ComparisonOperator.Contains },
        class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.des`,
        options: { searchFields: 'des', searchCompOp: ComparisonOperator.Contains },
        class: FormFieldStyle.default
      }
    ];
  }

}