<ng-container *ngIf="T$ | async as T">
  <div class="sticky top-0 left-0 bg-white z-5 pb-1">
    <p-steps [model]="items" [readonly]="true" [(activeIndex)]="activeIndex" class="hidden md:block"></p-steps>
    <p class="text-header-sm">{{items[activeIndex]?.label}}</p>
    <p-divider></p-divider>
  </div>

  <form [formGroup]="dataForm">
    <ng-container *ngIf="activeIndex == 0">
      <!--  <p class="text-header-sm">{{T | translateItem: 'gennotificawizard.selezionaNotifica'}}</p>
      <p-divider></p-divider> -->
      <div class="formgrid grid" formGroupName="step0">
        <span class="field col-12 sm:col-6 md:col-4 lg:col-2">
          <label class="text-sm required">{{T | translateItem:
            'gennotificawizard.tipiNotifica'}}</label>
          <p-dropdown [options]="genNotificaTipoList" optionValue="cod" optionLabel="des" formControlName="codNotifica"
            [showClear]="true" class="inputfield p-inputtext-sm" [required]="true"
            [placeholder]="T | translateItem: 'gennotificawizard.tipiNotifica'"
            [emptyMessage]="T | translateItem: 'generic.dropdownEmpty'" name="tipoNotifica">
          </p-dropdown>
        </span>

        <span class="field col-12 sm:col-6 md:col-4 lg:col-2">
          <label class="text-sm" [ngClass]="{'required': !step0.value.notificaPiatt}">{{T |
            translateItem: 'gennotificawizard.notificaEmail'}}</label>
          <p-checkbox [trueValue]="true" [falseValue]="null" [binary]="true" formControlName="notificaEmail"
            class="inputfield">
          </p-checkbox>
        </span>

        <span class="field col-12 sm:col-6 md:col-4 lg:col-2">
          <label class="text-sm" [ngClass]="{'required': !step0.value.notificaEmail}">{{T |
            translateItem: 'gennotificawizard.notificaPiatt'}}</label>
          <p-checkbox [trueValue]="true" [falseValue]="null" [binary]="true" formControlName="notificaPiatt"
            class="inputfield">
          </p-checkbox>
        </span>

      </div>
    </ng-container>

    <ng-container *ngIf="activeIndex == 1">
      <!-- <p class="text-header-sm">{{T | translateItem:
        'gennotificawizard.modificatesti'}}</p>
      <p-divider></p-divider> -->
      <div formArrayName="step1" class="striped-fieldsets">
        <div *ngFor="let template of templatesList; let i = index">
          <p-fieldset [legend]="template.langDes">
            <div [formGroupName]="i" class="formgrid grid">

              <input pInputText type="number" formControlName="notificaTipoId" hidden>
              <input pInputText type="text" formControlName="lang" hidden>
              <input pInputText type="number" formControlName="langDes" hidden>

              <span class="field col-12">
                <label class="text-sm required">{{T | translateItem: 'gennotificawizard.emailSubject'}}</label>
                <input pInputText type="text" formControlName="emailSubject"
                  [placeholder]="T | translateItem: 'gennotificawizard.emailSubject'" class="inputfield p-inputtext-sm">
              </span>

              <span class="field col-12" *ngIf="notificaEmail">
                <label class="text-sm required">{{T | translateItem: 'gennotificawizard.emailBody'}}</label>
                <p-editor formControlName="emailBody" [style]="{'height':'120px'}"
                  [placeholder]="T | translateItem: 'gennotificawizard.emailBody'">
                </p-editor>
              </span>

              <span class="field col-12" *ngIf="notificaPiatt">
                <label class="text-sm required">{{T | translateItem: 'gennotificawizard.piattMsg'}}</label>
                <p-editor formControlName="piattMsg" [style]="{'height':'120px'}"
                  [placeholder]="T | translateItem: 'gennotificawizard.piattMsg'">
                </p-editor>
              </span>

            </div>
          </p-fieldset>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="activeIndex == 2">
      <!-- <p class="text-header-sm">{{T | translateItem: 'gennotificawizard.selezionautenti'}}</p>
      <p-divider></p-divider> -->
      <div class="grid">
        <span class="field col-12">
          <label class="text-sm">{{T | translateItem:
            'gennotificawizard.utentiSelezionati'}}</label>
          <span *ngIf="selectedUsersLabel.size === 0; else users" class="block font-medium mt-1 text-600">{{T |
            translateItem:
            'gennotificawizard.nessunUtenteSelezionato'}}</span>
          <ng-template #users>
            <span class="block tag-cnt">
              <span *ngFor="let user of selectedUsersLabel" class="mb-1 badge-outlined-primary">{{user}}</span>
            </span>
          </ng-template>
        </span>
        <span class="field col-12">
          <label class="text-sm">{{T | translateItem:
            'gennotificawizard.utenti'}}</label>
          <p-tree [value]="tree" selectionMode="checkbox" [(selection)]="selectedNode"
            (onNodeSelect)="onNodeSelect($event)" (onNodeUnselect)="onNodeUnselect($event)">
            <ng-template let-node pTemplate="default">
              {{ node.label }}
            </ng-template>
          </p-tree>
        </span>
      </div>
    </ng-container>

    <ng-container *ngIf="activeIndex == 3">
      <!-- <p class="text-header-sm">{{T | translateItem: 'gennotificawizard.conferma'}} </p>
      <p-divider></p-divider> -->
      <div class="grid">
        <span class="field col-12 sm:col-6 md:col-4 lg:col-2">
          <label class="text-sm">{{T | translateItem: 'gennotificawizard.tipiNotifica'}}</label>
          <span class="block font-medium mt-1">
            {{notificaLabel}}
          </span>
        </span>
        <span class="field col-12 sm:col-6 md:col-4 lg:col-2">
          <label class="text-sm block mb-1">{{T | translateItem: 'gennotificawizard.notificaEmail'}}</label>
          <app-yes-no [value]="notificaEmail"
            [labels]="{yes: T | translateItem: 'generic.si', no: T | translateItem: 'generic.no'}">
          </app-yes-no>
        </span>
        <span class="field col-12 sm:col-6 md:col-4 lg:col-2">
          <label class="text-sm block mb-1">{{T | translateItem: 'gennotificawizard.notificaPiatt'}}</label>
          <app-yes-no [value]="notificaPiatt"
            [labels]="{yes: T | translateItem: 'generic.si', no: T | translateItem: 'generic.no'}">
          </app-yes-no>
        </span>
        <div class="striped-fieldsets col-12">
          <div *ngFor="let template of step1.value as GenNotificaTipoLang">
            <p-fieldset [legend]="template.langDes">
              <div class="grid">

                <span class="field col-12">
                  <label class="text-sm">{{T | translateItem: 'gennotificawizard.emailSubject'}}</label>
                  <span class="block font-medium mt-1">{{template.emailSubject}}</span>
                </span>

                <span class="field col-12" *ngIf="notificaEmail">
                  <label class="text-sm">{{T | translateItem: 'gennotificawizard.emailBody'}}</label>
                  <span class="block font-medium mt-1" [innerHTML]="template.emailBody"></span>
                </span>

                <span class="field col-12" *ngIf="notificaPiatt">
                  <label class="text-sm">{{T | translateItem: 'gennotificawizard.piattMsg'}}</label>
                  <span class="block font-medium mt-1" [innerHTML]="template.piattMsg"></span>
                </span>

              </div>
            </p-fieldset>
          </div>
        </div>
        <span class="field col-12">
          <label class="text-sm">{{T | translateItem:
            'gennotificawizard.utenti'}}</label>
          <span class="block tag-cnt">
            <span *ngFor="let user of selectedUsersLabel" class="mb-1 badge-info">{{user}}</span>
          </span>
        </span>
      </div>
    </ng-container>
  </form>
</ng-container>