import { NgModule } from '@angular/core';
import { AnyPipe, BlockedOrFixedDatePipe, CacxByManiTypeCodePipe, CmdFieldsPipe, CountItemsPipe, CountNullPipe, DistinctKeyPipe, EditableDropdownPipe, EnumDescriptionPipe, EqualDates, FilterAllStringPipe, FilterByMultipleParPipe, FilterByParPipe, FilterKeyFormPipe, FilterKeyPipe, FormatDataPipe, GiorniToStringPipe, IncludesPipe, IsCmdFieldActivePipe, IsCmdFieldDisabledPipe, IsCmdFieldVisiblePipe, IsFormFieldVisiblePipe, IsObservablePipe, IsRequiredFieldPipe, JsonParsePipe, MapPipe, NoValuePlaceholderPipe, ParteGiornoPipe, RefreshingPipe, ReplaceNullPipe, SetDisabledFieldGiudRaggPipe, SetDisabledFieldPipe, SomePipe, SortKeyPipe, SubsscribeWithLoadingPipe, SumItemsPipe, TranslateItemPipe, TruncateStringPipe, } from './app.pipes';

@NgModule({
  declarations: [
    TranslateItemPipe,
    FilterKeyPipe,
    FilterKeyFormPipe,
    BlockedOrFixedDatePipe,
    TruncateStringPipe,
    NoValuePlaceholderPipe,
    ReplaceNullPipe,
    SortKeyPipe,
    CmdFieldsPipe,
    IsCmdFieldActivePipe,
    IsCmdFieldDisabledPipe,
    IsCmdFieldVisiblePipe,
    DistinctKeyPipe,
    EnumDescriptionPipe,
    IsObservablePipe,
    RefreshingPipe,
    IsRequiredFieldPipe,
    FilterByParPipe,
    FilterAllStringPipe,
    IsFormFieldVisiblePipe,
    IncludesPipe,
    CacxByManiTypeCodePipe,
    FormatDataPipe,
    GiorniToStringPipe,
    SubsscribeWithLoadingPipe,
    FilterByMultipleParPipe,
    SetDisabledFieldPipe,
    EditableDropdownPipe,
    CountNullPipe,
    SetDisabledFieldGiudRaggPipe,
    SumItemsPipe,
    JsonParsePipe,
    MapPipe,
    CountItemsPipe,
    SomePipe,
    EqualDates,
    AnyPipe,
    ParteGiornoPipe
  ],
  imports: [],
  providers: [],
  exports: [
    TranslateItemPipe,
    FilterKeyPipe,
    FilterKeyFormPipe,
    BlockedOrFixedDatePipe,
    TruncateStringPipe,
    NoValuePlaceholderPipe,
    ReplaceNullPipe,
    SortKeyPipe,
    CmdFieldsPipe,
    IsCmdFieldActivePipe,
    IsCmdFieldDisabledPipe,
    IsCmdFieldVisiblePipe,
    DistinctKeyPipe,
    EnumDescriptionPipe,
    IsObservablePipe,
    RefreshingPipe,
    IsRequiredFieldPipe,
    FilterByParPipe,
    FilterAllStringPipe,
    IsFormFieldVisiblePipe,
    IncludesPipe,
    CacxByManiTypeCodePipe,
    FormatDataPipe,
    GiorniToStringPipe,
    SubsscribeWithLoadingPipe,
    FilterByMultipleParPipe,
    SetDisabledFieldPipe,
    EditableDropdownPipe,
    CountNullPipe,
    SetDisabledFieldGiudRaggPipe,
    SumItemsPipe,
    JsonParsePipe,
    MapPipe,
    CountItemsPipe,
    SomePipe,
    EqualDates,
    AnyPipe,
    ParteGiornoPipe
  ]
})
export class PipesModule { }
