import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DataSearch } from '../models/data-search';
import { IEntityList } from '../models/entity';
import { FileData } from "../models/file-data";
import { isNullOrUndefined } from '../utils/util';

export const FIELD_EXCEPT_PREFIX = '_x_';

export type QueryParams = { [key: string]: string | string[] | number | number[] };

export interface ConfigSettings {
  restCommonUrl: string;
  restCommonUrlFapi: string;
  restCalendarioUrl: string;
  restIdentityUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {

  getEntityFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(0, Validators.required),
      dtIns: new FormControl(null),
      userIns: new FormControl(null),
      dtUpd: new FormControl(null),
      userUpd: new FormControl(null)
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  deepTrim(obj) {
    for (var prop in obj) {
      var value = obj[prop], type = typeof value;
      if (value != null && (type == "string" || type == "object") && obj.hasOwnProperty(prop)) {
        if (type == "object") {
          this.deepTrim(obj[prop]);
        } else {
          obj[prop] = obj[prop].trim();
        }
      }
    }
  }

  configSettings: ConfigSettings;
  // userData: any = undefined;

  constructor(private httpClient: HttpClient) {
  }

  getConfigUrl(serviceName: string) {
    return this.configSettings[`${serviceName}Url`];
  }

  /**
   * Call rest api method to get generici result
   *
   * @param urlRoute url of the rest api method
   * @param queryStringParam query string to append to the url
   */
  public getGeneric(urlRoute: string, queryStringParam?: string): Observable<any> {
    let url = urlRoute;
    if (!isNullOrUndefined(queryStringParam) && queryStringParam.length > 0) {
      url = `${url}?${queryStringParam}`;
    }

    return this.httpClient.request<any>("GET", url);
  }

  public get<T>(urlRoute: string, queryParams?: QueryParams): Observable<T> {
    let url = urlRoute;

    if (!isNullOrUndefined(queryParams)) {
      const queryStringParam = this.queryParamsToQueryString(queryParams);
      url = `${url}?${queryStringParam}`;
    }

    return this.httpClient.request<T>("GET", url);
  }

  public getGenericRoute(urlRoute: string, params: string[]): Observable<any> {
    let url = `${urlRoute}`;

    if (!isNullOrUndefined(params) && params.length > 0) {
      params.forEach(
        p => url += "/" + p
      );
    }
    return this.httpClient.request<any>("GET", url);
  }

  /**
   * Call rest api method to get the list of items of type T
   *
   * @param urlRoute url of the rest api method
   * @param queryStringParam query string to append to the url
   */
  public getElements<T>(urlRoute: string, queryStringParam?: string): Observable<IEntityList<T>> {
    let url = urlRoute;
    if (!isNullOrUndefined(queryStringParam) && queryStringParam.length > 0) {
      url = `${url}?${queryStringParam}`;
    }

    return this.httpClient.request<IEntityList<T>>("GET", url);
  }

  /**
   * Call rest api method to get the list of items of type T
   *
   * @param urlRoute url of the rest api method
   * @param dataSearch info data about search to perform
   */
  public searchElements<T>(urlRoute: string, dataSearch?: DataSearch, queryParams?: { [key: string]: string }): Observable<IEntityList<T>> {
    let url = urlRoute;

    if (!isNullOrUndefined(dataSearch)) {
      let queryStringParam = dataSearch.toQueryString();
      url = `${url}?${queryStringParam}`;
    }

    if (!isNullOrUndefined(queryParams)) {
      let queryStringParam = this.queryParamsToQueryString(queryParams);
      url = `${url}${isNullOrUndefined(dataSearch) ? '?' : '&'}${queryStringParam}`;
    }

    return this.httpClient.request<IEntityList<T>>("GET", url);
  }

  /**
   * Call rest api method to search for entity of type T by id
   *
   * @param urlRoute url of the rest api method
   * @param id id of element to search
   */
  public getElementById<T>(urlRoute: string, id?: number | string): Observable<T> {
    let url = urlRoute;
    if (!isNullOrUndefined(id)) {
      url = `${url}/${id}`;
    }

    return this.httpClient.request<T>("GET", url);
  }

  /**
   * Call rest api method to get the list of items of type T
   *
   * @param urlRoute url of the rest api method
   * @param queryStringParam query string to append to the url
   */
  public getElementByQuery<T>(urlRoute: string, queryStringParam?: string): Observable<T> {
    let url = urlRoute;
    if (!isNullOrUndefined(queryStringParam) && queryStringParam.length > 0) {
      url = `${url}?${queryStringParam}`;
    }

    return this.httpClient.request<T>("GET", url);
  }

  /**
   * Call rest api method to insert new item of type T into database
   *
   * @param urlRoute url of the rest api method
   * @param element element to insert
   */
  public insertElement<T>(urlRoute: string, element: T): Observable<any> {
    let url = urlRoute;

    if (element['isFormData']) {
      const formData = new FormData();
      Object.keys(element).forEach(key => {
        if (key === 'id' && !element[key]) {
          formData.append(key, '0');
        }
        else {
          if (element[key] != undefined && element[key] != null && element[key] != 'undefined' && element[key] != 'null') {
            if (element[key] instanceof Date) {
              // formData.append(key, (new Date(element[key])).toISOString());
              formData.append(key, (new Date(element[key])).toUTCString());
            }
            else {
              formData.append(key, element[key]);
            }
          }
        }
      })
      return this.httpClient.request<T>("POST", url, { body: formData });
    }
    else {
      return this.httpClient.request<T>("POST", url, { body: element });
    }

  }

  /**
   * Call rest api method to insert new items of type T into database
   *
   * @param urlRoute url of the rest api method
   * @param elements element to insert
   */
  public insertElements<T>(urlRoute: string, elements: T[]): Observable<IEntityList<T>> {
    return this.httpClient.request<IEntityList<T>>("POST", urlRoute, { body: elements });
  }

  /**
   * Call rest api method to upsert items of type T into database
   *
   * @param urlRoute url of the rest api method
   * @param elements element to insert
   */
  public upsertElements<T>(urlRoute: string, elements: T[]): Observable<IEntityList<T>> {
    return this.httpClient.request<IEntityList<T>>("PUT", urlRoute, { body: elements });
  }

  /**
   * Call rest api method to update item of type T in the database
   *
   * @param urlRoute url of the rest api method
   * @param element element to update
   */
  public updateElement<T>(urlRoute: string, element: T, id?: number | string, queryParams?: { [key: string]: string }): Observable<any> {
    let url = urlRoute;
    if (!isNullOrUndefined(id)) {
      url = `${url}/${id}`;
    }
    if (!isNullOrUndefined(queryParams)) {

      let queryStringParam = this.queryParamsToQueryString(queryParams);
      url = `${url}${queryStringParam}`;
    }
    if (element?.['isFormData']) {
      const formData = new FormData();
      Object.keys(element).forEach(key => {
        if (element[key] != undefined && element[key] != null && element[key] != 'undefined' && element[key] != 'null') {
          if (element[key] instanceof Date) {
            formData.append(key, (new Date(element[key])).toISOString());
            // formData.append(key, (new Date(element[key])).toUTCString());
          }
          else {
            formData.append(key, element[key]);
          }
        }
      })
      return this.httpClient.request<T>("PUT", url, { body: formData });
    }
    else {
      return this.httpClient.request<T>("PUT", url, { body: element });
    }
  }

  /**
 * Call controller service that put a generic element
 *
 * @param urlRoute url of the rest api method
 * @param id id of element to update
 * @param data optionally body content
 * @returns
 */
  public putGeneric(urlRoute: string, id?: number | string, data?: any) {
    let url = urlRoute;
    if (!isNullOrUndefined(id)) {
      url = `${url}/${id}`;
    }

    if (data) {
      return this.httpClient.request<any>("PUT", url, { body: data });
    }
    else {
      return this.httpClient.request<any>("PUT", url);
    }
  }

  /**
   * Call rest api method to insert new items of type T into database
   *
   * @param urlRoute url of the rest api method
   * @param elements element to insert
   */
  public updateElements<T>(urlRoute: string, elements: T[]): Observable<IEntityList<T>> {
    return this.httpClient.request<IEntityList<T>>("PUT", urlRoute, { body: elements });
  }

  /**
   * Delete item of type T from the database
   *
   * @param urlRoute url of the rest api method
   * @param element element to delete
   */
  public deleteElement<T>(urlRoute: string, id: number | string): Observable<number> {
    let url = urlRoute + '/' + id;

    return this.httpClient.request<number>("DELETE", url);
  }

  /**
   * Detete all entities that the id is in the ids array
   *
   * @param urlRoute url of the rest api method
   * @param ids array of ids of the entities to delete
   */
  public deleteElements<T>(urlRoute: string, ids: number[] | string[]): Observable<any> {
    return this.httpClient.request<number>("DELETE", urlRoute, { body: ids });
  }

  /**
   * Get File of entities print
   *
   * @param urlRoute  url of the rest api method
   * @param fileData info about file to retrieve
   * @returns
   */
  public printElements(urlRoute: string, ids: number[] | string[]): Observable<FileData> {
    let url = urlRoute;

    if (!isNullOrUndefined(ids)) {
      var queryStringParam = ids.map(id => `ids=${id}`).join('&');
      url = `${url}?${queryStringParam}`;
    }

    return this.httpClient.request<FileData>("GET", url);
  }

  /**
   * Get File from repository
   *
   * @param urlRoute  url of the rest api method
   * @param fileData info about file to retrieve
   * @returns
   */
  public getResourceRepository(urlRoute: string, fileData: FileData): Observable<FileData> {
    let url = urlRoute;

    let queryStringParam = fileData.toQueryString();
    url = `${url}?${queryStringParam}`;

    return this.httpClient.request<FileData>("GET", url);
  }

  /**
   * Get URL of resource in the repository
   *
   * @param urlRoute  url of the rest api method
   * @param fileData info about file to retrieve
   * @returns
   */
  public getUrlResourceRepository(urlRoute: string, fileData: FileData): Observable<string> {
    let url = urlRoute;
    let queryStringParam = fileData.toQueryString();
    url = `${url}?${queryStringParam}`;

    return this.httpClient.request<string>("GET", url);
  }

  /**
   * Delete resource from repository
   *
   * @param urlRoute url of the rest api method
   * @param fileData info about file to retrieve
   */
  public deleteResourceRepository<T>(urlRoute: string, fileData: FileData): Observable<boolean> {
    let url = urlRoute;
    let queryStringParam = fileData.toQueryString();
    url = `${url}?${queryStringParam}`;

    return this.httpClient.request<boolean>("DELETE", url);
  }

  /**
   * Call controller service that simulate notification messagge sending
   *
   * @param urlRoute url of the rest api method
   * @param data optionally body content
   * @returns
   */
  public simulateNotificationMessage(urlRoute: string, data?: any) {
    const url = `${this.configSettings.restCommonUrl}/${urlRoute}`;
    if (data) {

      return this.httpClient.request<any>("POST", url, { body: data });
    }
    else {

      return this.httpClient.request<any>("POST", url);
    }
  }

  /**
   * Call controller service that post a generic element
   *
   * @param urlRoute url of the rest api method
   * @param data optionally body content
   * @returns
   */
  public postGeneric(urlRoute: string, data?: any) {
    if (data) {
      return this.httpClient.request<any>("POST", urlRoute, { body: data });
    }
    else {
      return this.httpClient.request<any>("POST", urlRoute);
    }
  }

  /**
 * Convert queryParams object to a query string
 *
 * @param queryParams query params object key value
 */
  private queryParamsToQueryString(queryParams: QueryParams): string {
    const query = [];

    Object.keys(queryParams).forEach(key => {
      const queryParamVal = queryParams[key];
      if (Array.isArray(queryParamVal)) {
        queryParamVal.forEach(v => query.push(`${key}=${v}`));
      }
      else {
        query.push(`${key}=${queryParamVal}`);
      }
    });

    return query.join('&');
  }

}
