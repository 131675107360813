import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { Observable, ReplaySubject, Subject, combineLatest, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { CalCaleManiGiudAssistentatoDto } from 'src/app/models/calendario/dto/cal-cale-mani-giud-assistentato-dto';
import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from 'src/app/models/data-search';
import { GiudRichiestaDetail } from 'src/app/models/giud-richiesta/giud-richiesta-detail';
import { GiudAssistentatoNewDto } from 'src/app/models/giudici/dto/giud-assistentato-new-dto';
import { GiudGiudiceSuggestionDto } from 'src/app/models/giudici/dto/giud-giudice-suggestion-dto';
import { GiudEsitoAssistentato } from 'src/app/models/giudici/giud-esito-assistentato';
import { ToastMessageData } from 'src/app/models/message';
import { SecUtente } from 'src/app/models/security/sec-utente';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { BaseDataService } from 'src/app/services/base-data.service';
import { DataService, QueryParams } from 'src/app/services/data.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { dateGreatherThan, dateLessThan } from 'src/app/utils/formValidators';
import { dateFormat, dateFormatPrNg } from 'src/app/utils/util';

@Component({
  selector: 'app-giud-assistentato-exp-add',
  templateUrl: './giud-assistentato-exp-add.component.html',
  styleUrls: ['./giud-assistentato-exp-add.component.scss']
})
export class GiudAssistentatoExpAddComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject();

  // Etichette
  T$: Observable<Resources> = this.translateService.translatedItems$(AppManagerRoutes.GiudAssistentatoAdd.translateSuffixs ?? ['generic.']).pipe(
    tap(translateItems => this.resourceList = translateItems)
  );

  resourceList: Resources;

  dateFormat: string = dateFormat;

  dateFormatPrNg: string = dateFormatPrNg;

  elaboration: boolean = true;

  utente: SecUtente;

  // Form
  dataForm = this.fb.group({
    richiestaDetailIds: this.fb.control<number[] | null>(null, [Validators.required]),
    assistentatoIdPadre: this.fb.control<number | null>(null),
    giudiceId: this.fb.control<number | null>(null, [Validators.required]),
    giudiceRaw: this.fb.control<Partial<GiudGiudiceSuggestionDto> | null>({ value: null, disabled: true }, [Validators.required]),
    caleManiDataInizio: this.fb.control<Date | null>(null),
    caleManiDataFine: this.fb.control<Date | null>(null),
    caleManiGiudRazzeIds: this.fb.control<number[] | null>(null, [Validators.required]),
  }, { validators: [dateLessThan('caleManiDataInizio', 'caleManiDataFine'), dateGreatherThan('caleManiDataInizio', 'caleManiDataFine')] });

  // Date
  dataInizioMin: Date = new Date();

  get dataFineMin(): Date {
    return this.dataForm.controls.caleManiDataInizio.value;
  }

  // Dropdowns
  esitoAssistentatoList$: Observable<GiudEsitoAssistentato[]> = this.baseDataService.getGiudEsitoAssistentatoList();

  richiestaDetailList$: Observable<GiudRichiestaDetail[]>;

  // Ricerca giudici
  private giudiceQuery$: Subject<string> = new Subject();

  private razzaIds$: ReplaySubject<number[]> = new ReplaySubject(1);

  private giudiceId$: ReplaySubject<number> = new ReplaySubject(1);

  giudiciSuggestions$: Observable<GiudGiudiceSuggestionDto[]> = combineLatest([
    this.razzaIds$.asObservable().pipe(
      startWith(),
      distinctUntilChanged()
    ),
    this.giudiceQuery$.asObservable().pipe(
      startWith(),
      distinctUntilChanged()
    )
  ]).pipe(
    switchMap(([razzaIds, query]: [razzaIds: number[], query: string]) => {
      const ds = new DataSearch(
        [new DataSearchValue([query], ['nomeCompleto'], ComparisonOperator.Contains),
        new DataSearchValue([razzaIds.map(r => r.toString())], ['abilitazioniGiudRazze'], ComparisonOperator.Custom),
        new DataSearchValue([this.giudiceRichiedenteId.toString()], ['lingueComuni'], ComparisonOperator.Custom)],
        [new DataSortValue('nomeCompleto', SortMode.Asc)]
      );

      return this.dataService.searchElements<GiudGiudiceSuggestionDto>(`${this.dataService.configSettings.restCommonUrl}/giudgiudicesuggestion`, ds);
    }),
    catchError(() => of({
      entities: [],
      numRowsTot: 0
    })),
    map(data => data.entities)
  );

  // Ricerca mani
  filterAndSortEntities$: Observable<QueryParams> = combineLatest([
    this.razzaIds$.asObservable().pipe(
      distinctUntilChanged(),
      filter(data => !!data?.length)
    ),
    this.giudiceId$.asObservable().pipe(
      distinctUntilChanged(),
      filter(data => !!data)
    ),
    this.dataForm.controls.caleManiDataInizio.valueChanges.pipe(
      distinctUntilChanged(),
      filter(data => !!data)
    ),
    this.dataForm.controls.caleManiDataFine.valueChanges.pipe(
      distinctUntilChanged(),
      filter(data => !!data)
    ),
  ]).pipe(
    map(([razzaIds, giudiceId, dataInizio, dataFine]: [razzaIds: number[], giudiceId: number, dataInizio: Date, dataFine: Date]) => {
      return {
        giudiceId: giudiceId,
        razzeIds: razzaIds,
        dataInizio: DateTime.fromJSDate(dataInizio).toISODate(),
        dataFine: DateTime.fromJSDate(dataFine).toISODate(),
        giudiceRichiedenteId: this.giudiceRichiedenteId
      }
    })
  );

  maniSuggestions$: Observable<CalCaleManiGiudAssistentatoDto[]> = this.filterAndSortEntities$.pipe(
    tap(() => this.elaboration = true),
    switchMap((queryParams) => {
      return this.dataService.get<CalCaleManiGiudAssistentatoDto[]>(`${this.dataService.configSettings.restCalendarioUrl}/calcalemanigiudassistentatoexp`, queryParams).pipe(
        catchError((err) => {
          this.elaboration = false
          return throwError(() => err);
        }
        )
      )
    }),
    tap(() => this.elaboration = false)
  );

  isRiprtizione: boolean = false;

  @Input()
  visible: boolean = false;

  @Input()
  entity: GiudAssistentatoNewDto = new GiudAssistentatoNewDto();

  @Input()
  richiestaId: number;

  @Input()
  giudiceRichiedenteId: number;

  @Output()
  onHide = new EventEmitter<boolean>();

  constructor(
    private translateService: TranslateService,
    private baseDataService: BaseDataService,
    private fb: FormBuilder,
    private dataService: DataService,
    private appManagerService: AppManagerService,
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    // Utente
    this.appManagerService.utente$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (utente) => {
        this.utente = utente;
      }
    );

    // Se sono in una ripetizione la razza non deve essere modificabile e il giudice non deve essere modificabile solo se l'utente è admin
    if (this.entity.assistentatoIdPadre) {
      this.isRiprtizione = true;
      this.dataForm.controls.richiestaDetailIds.disable();
    }

    // Carico le richieste di ampliamento
    this.richiestaDetailList$ = this.getGiudRichiestaDetail(this.richiestaId);

    // Eventi forms
    combineLatest([
      this.richiestaDetailList$,
      this.dataForm.controls.richiestaDetailIds.valueChanges.pipe(
        startWith(null),
        distinctUntilChanged()
      )
    ]).pipe(
      takeUntil(this.destroy$),
      map(([list, filter]: [list: any[], filter: number[]]) => {
        return list.filter(r => filter?.includes(r.id)).map(r => r.razzaId);
      })
    ).subscribe(this.razzaIds$);

    // Quando seleziono un giudice dall'autocomplete vado a settare il suo id
    // Se cancello il giudice resetto il campo manifestazione, il giudice e il giudce mani
    this.dataForm.controls.giudiceRaw.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (data: GiudGiudiceSuggestionDto) => {
        const giudiceIdFiled = this.dataForm.controls.giudiceId;
        const caleManiGiudRazzeIdsField = this.dataForm.controls.caleManiGiudRazzeIds;

        if (data?.id) {
          giudiceIdFiled.setValue(data?.id);
        }
        else if (!data) {
          giudiceIdFiled.reset();
          caleManiGiudRazzeIdsField.reset();
        }
      }
    );

    // Quando setto l'id della razza si abilita il selettore del giudice
    this.dataForm.controls.richiestaDetailIds.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (richiestaDetailIds: number[]) => {
        const giudiceRawField = this.dataForm.controls.giudiceRaw;
        const caleManiGiudRazzeIdsField = this.dataForm.controls.caleManiGiudRazzeIds;
        const giudiceIdField = this.dataForm.controls.giudiceId;

        caleManiGiudRazzeIdsField.reset();

        if (richiestaDetailIds.length && (!this.isRiprtizione || (this.isRiprtizione && this.utente.isAdmin))) {
          giudiceRawField.enable();
        }
        else {
          giudiceRawField.disable();

          giudiceIdField.reset();
          giudiceRawField.reset();
        }
      }
    );

    // Quando setto l'id del giudice si abilita il selettore della manifestazione
    this.dataForm.controls.giudiceId.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (giudiceId: number) => {
        this.giudiceId$.next(giudiceId);

        const caleManiGiudRazzeIdsField = this.dataForm.controls.caleManiGiudRazzeIds;

        if (giudiceId) {
          caleManiGiudRazzeIdsField.enable();
        }
        else {
          caleManiGiudRazzeIdsField.disable();
        }
      }
    );

    // Popolo il form
    this.dataForm.patchValue(this.entity);
  }

  hideModal(reloadData: boolean = false): void {
    this.visible = false;
    this.onHide.emit(reloadData);
  }

  save(): void {
    const { caleManiDataInizio, caleManiDataFine, ...data } = this.dataForm.getRawValue();

    this.dataService.postGeneric(`${this.dataService.configSettings.restCommonUrl}/giudassistentatobulk`, data as GiudAssistentatoNewDto).subscribe({
      next: () => {
        this.elaboration = false;

        this.appManagerService.showToastMessage(
          new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.savesuccessmsg')));

        this.dataForm.markAsPristine();
        this.hideModal(true);
      },
      error: () => {
        this.elaboration = false;
      }
    });
  }

  getGiudRichiestaDetail(richiestaId: number): Observable<GiudRichiestaDetail[]> {
    const dataSearch = new DataSearch(
      [new DataSearchValue(['1'], ['flAccettata'], ComparisonOperator.Equals)],
      [new DataSortValue('gruppoFciId', SortMode.Asc), new DataSortValue('razzaDes', SortMode.Asc)]
    );

    return this.dataService.searchElements<GiudRichiestaDetail>(`${this.dataService.configSettings.restCommonUrl}/giudrichiesta/${richiestaId.toString()}/giudrichiestadetail`, dataSearch).pipe(
      catchError((err) => {
        return throwError(() => err);
      }),
      map((data) => {
        data.entities.forEach((ra) => ra['disabled'] = false);
        //  data.entities.forEach((ra) => ra['disabled'] = (!this.entity.assistentatoIdPadre && ra.flAssistentatoInserito && !(ra.id === richiestaDetailId)) || (this.entity.assistentatoIdPadre && !(this.entity.richiestaDetailIdList.includes(ra.id))));

        return data.entities;
      })
    )
  }

  giudiceSearch(event): void {
    this.giudiceQuery$.next(event.query.trim());
  }
}
