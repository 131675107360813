import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { TipoCalCod } from 'src/app/models/anagrafiche/calendario/cal-tipo-cal';
import { FormModeType } from 'src/app/models/entity-config';
import { GiudCertificato } from 'src/app/models/giudici/giud-certificato';
import { ToastMessageData } from 'src/app/models/message';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { BaseDataService } from 'src/app/services/base-data.service';
import { DataService } from 'src/app/services/data.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { dateGreatherThan } from 'src/app/utils/formValidators';
import { dateFormat, dateFormatPrNg } from 'src/app/utils/util';

@Component({
  selector: 'app-giud-certificato-edit',
  templateUrl: './giud-certificato-edit.component.html',
  styleUrls: ['./giud-certificato-edit.component.scss']
})
export class GiudCertificatoEditComponent implements OnInit, OnDestroy {
  private resourceList: Resources;
  private subscription: Subscription;

  dateFormat: string = dateFormat;
  dateFormatPrNg: string = dateFormatPrNg;
  T$: Observable<Resources>;
  dataForm: FormGroup;
  formModeType: typeof FormModeType = FormModeType;
  formMode: number = FormModeType.insert;
  elaboration: boolean = false;

  @Input() visible: boolean = false;
  @Input() entity: GiudCertificato;
  @Input() giudiceId: number;
  @Input() tipoCertificatoCod: TipoCalCod;

  @Output() onHide = new EventEmitter<boolean>();

  constructor(
    private translateService: TranslateService,
    private baseDataService: BaseDataService,
    private fb: FormBuilder,
    private dataService: DataService,
    private appManagerService: AppManagerService,
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    // Etichette
    this.T$ = this.translateService.translatedItems$(AppManagerRoutes.GiudCertificato.translateSuffixs ?? ['generic.']).pipe(
      tap(data => this.resourceList = data)
    );

    // Mode
    this.formMode = (this.entity.id ?? 0) === 0 ? FormModeType.insert : FormModeType.edit;

    // Form
    this.dataForm = this.buildForm(this.entity, this.giudiceId);

    // Valorizza l'Id del tipo certificato
    if (this.formMode === FormModeType.insert) {
      this.subscription.add(
        this.baseDataService.getGiudTipoCertificatoList().pipe(
          map(data => data.find(t => t.cod === this.tipoCertificatoCod)?.id)
        ).subscribe(val => {
          this.dataForm.get('tipoCertificatoId').setValue(val)
        })
      );
    }

  }

  hideModal(reloadData: boolean = false): void {
    this.visible = false;
    this.onHide.emit(reloadData);
  }

  save(): void {
    const data = this.dataForm.value as GiudCertificato;

    if (this.formMode === FormModeType.insert) {
      this.dataService.insertElement<GiudCertificato>(`${this.dataService.configSettings.restCommonUrl}/giudcertificato`, data).subscribe(
        () => {
          this.elaboration = false;

          this.appManagerService.showToastMessage(
            new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.savesuccessmsg')));

          this.dataForm.markAsPristine();
          this.hideModal(true);
        },
        () => {
          this.elaboration = false;
        }
      );
    }
    else if (this.formMode === FormModeType.edit) {
      this.dataService.updateElement<GiudCertificato>(`${this.dataService.configSettings.restCommonUrl}/giudcertificato`, data, this.entity.id.toString()).subscribe(
        () => {
          this.elaboration = false;

          this.appManagerService.showToastMessage(
            new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.savesuccessmsg')));

          this.dataForm.markAsPristine();
          this.hideModal(true);
        },
        () => {
          this.elaboration = false;
        }
      );
    }
  }

  private buildForm(certificato: GiudCertificato, giudiceId: number): FormGroup {
    const form = this.fb.group({
      id: this.fb.control<number | null>(0, [Validators.required]),
      giudiceId: this.fb.control<number | null>(giudiceId, [Validators.required]),
      tipoCertificatoId: this.fb.control<number | null>(null, [Validators.required]),
      dataInizio: this.fb.control<Date | null>(null, [Validators.required]),
      dataFine: this.fb.control<Date | null>(null, [Validators.required]),
      note: this.fb.control<string | null>(null),
      userIns: this.fb.control<string | null>(null),
      dtIns: this.fb.control<Date | null>(null),
      userUpd: this.fb.control<string | null>(null),
      dtUpd: this.fb.control<Date | null>(null)
    }, {
      validators: [dateGreatherThan('dataInizio', 'dataFine')]
    });

    if (certificato) {
      form.patchValue(certificato);
    }

    return form;
  }
}
