import { Validators } from "@angular/forms";
import { AppManagerRoutes } from "src/app/app-manager-routes";
import { DataSortValue, SortMode, ComparisonOperator, DataSearch } from "../../data-search";
import { EntityManagerConfig, CmdField, CmdFieldKey, CmdFieldMode, CmdFieldTarget, Icons, CmdFieldStyle, FormFieldType, FormFieldTypeHtml, FormFieldStyle } from "../../entity-config";

export class GenDomandaEMC extends EntityManagerConfig {

  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = { windowListHeader: `${this.name}.windowListHeader` };
    this.dataSortValue = [new DataSortValue('des', SortMode.Asc)];

    const ds: DataSearch = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    this.attributes['gendomandatipo'] = this.getEntityService('gendomandatipo').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchReset',
        title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // Editable
      {
        key: CmdFieldKey.add,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listHeader,
        label: 'generic.add',
        title: 'generic.add',
        icon: Icons.plus,
        class: CmdFieldStyle.btnPrimary,
      },
      {
        key: CmdFieldKey.edit,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.edit',
        icon: Icons.edit,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.cancel,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.cancel',
        icon: Icons.back,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.modalListEditable, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.translations', icon: Icons.globe, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenDomandaLangList,
        fieldsBind: { id: 'domandaId' }, fieldsBindSearch: { id: 'domandaId' }
      },
      {
        key: CmdFieldKey.delete,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.delete',
        icon: Icons.delete,
        class: CmdFieldStyle.btnSecondaryDanger,
      },
      {
        key: CmdFieldKey.save,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.save',
        icon: Icons.save,
        class: CmdFieldStyle.btnPrimary,
      },
    ];

    this.listFields = [
      {
        key: 'id',
        type: FormFieldType.number,
        sortable: true,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'domandaTipoId',
        type: FormFieldType.number,
        sortable: true,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'domandaTipoDes',
        type: FormFieldType.string,
        label: `${this.name}.domandaTipo`,
        sortable: true
      },
      {
        key: 'des',
        type: FormFieldType.string,
        label: `${this.name}.des`,
        sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'dtIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'userIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'domandaTipoId',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.select,
        label: `${this.name}.domandaTipo`,
        placeholder: `${this.name}.domandaTipo`,
        options: {
          data: this.attributes['gendomandatipo'],
          dataKey: 'id',
          dataLabel: 'des',
        },
        validators: [Validators.required],
      },
      {
        key: 'des',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`,
        label: `${this.name}.des`,
        validators: [Validators.required],
      }
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.cod`,
        options: { searchFields: 'cod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }
}