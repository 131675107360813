import { Directive, Input, OnChanges, QueryList, SimpleChange, SimpleChanges, TemplateRef, ViewChildren, ViewContainerRef } from "@angular/core";
import { Button } from "primeng/button";
import { checkGrant, EntityManagerGrant, FieldGrant } from "../models/entity-config";


@Directive({
  selector: '[grantVisible]'
})
export class GrantVisibleDirective implements OnChanges{
  /** List of pair key/value where key is the button or field and value is the grant */
  @Input() grantVisible: EntityManagerGrant;

  /** button or field for witch to check the grant */
  @Input() grantVisibleKey: string;

  @ViewChildren(Button) buttonElements: QueryList<Button>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  )
  {}

  ngOnChanges(changes:SimpleChanges):void{
    if (changes.grantVisible){
      const cng = changes.grantVisible;
      const prevVal = cng.previousValue?.[this.grantVisibleKey];
      const currVal = cng.currentValue?.[this.grantVisibleKey];

      if (cng.firstChange || prevVal !== currVal){
        this.setView(checkGrant(cng.currentValue, this.grantVisibleKey, FieldGrant.read, true));
      }
    }
  }

  private setView(val:boolean) :void {

    if(val) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else{
      this.viewContainer.clear();
    }
  } 
}

// @Directive({
//   selector: '[grantVisible]'
// })
// export class GrantVisibleDirective {

//   private fieldGrant: EntityManagerGrant;
//   private fieldKey: string;

//   /** List of pair key/value where key is the button or field and value is the grant */
//   @Input()
//   set grantVisible(val: EntityManagerGrant) {
//     this.fieldGrant = val;
//   }

//   /** button or field for witch to check the grant */
//   @Input()
//   set grantVisibleKey(val: string) {
//     this.fieldKey = val;
//     this.setView();
//   }

//   @ViewChildren(Button) buttonElements: QueryList<Button>;

//   constructor(
//     private templateRef: TemplateRef<any>,
//     private viewContainer: ViewContainerRef
//   ) { }

//   private setView() {
//     if (!this.fieldGrant || !this.fieldKey) {
//       this.viewContainer.clear();
//     }
//     else {
//       if (checkGrant(this.fieldGrant, this.fieldKey, FieldGrant.read, true)) {
//         this.viewContainer.createEmbeddedView(this.templateRef);
//       }
//       else {
//         this.viewContainer.clear();
//       }
//     }
//   }

// }


// /**  [grantGroupVisible]="grants" grantKey="'calcaleparametrical'" */
// @Directive({
//   selector: '[grantGroupVisible]'
// })
// export class GrantGroupVisibleDirective { // implements OnDestroy, OnInit, AfterViewInit
  
//   private fieldGrant: EntityManagerGrant;
//   private fieldKey: string;
//   // private subscription: Subscription;

//   /** List of pair key/value where key is the button or field and value is the grant */
//   @Input()
//   set grantGroupVisible(val: EntityManagerGrant) {
//     this.fieldGrant = val;
//   }

//   /** button or field for witch to check the grant */
//   @Input()
//   set grantKey(val: string) {
//     this.fieldKey = val;
//     this.setView();
//   }

//   @ViewChildren('p-button') buttonElements: QueryList<Button>;

//   constructor(
//     private elementRef: ElementRef
//   )
//   {
//     // this.subscription = new Subscription();

//     // this.elementRef.nativeElement.style.backgroundColor = 'yellow';
//   }

  

//   // ngOnDestroy(): void {
//   //   this.subscription.unsubscribe();
//   // }

//   // ngOnInit() {
//   //   this.subscription.add(
//   //     this.appManagerService.initialized$
//   //     .subscribe(
//   //       (appInitialized) => {
//   //         if (appInitialized.initialized) {
//   //           this.setView();
//   //         }
//   //       }
//   //     )
//   //   );
//   // }

//   ngAfterViewInit(): void {
    
//   //   // const elements1 = this.elementRef.nativeElement.querySelectorAll('button');
//   //   // const elements2 = this.templateRef.elementRef.nativeElement.querySelectorAll('button');
//     const aaa = this.buttonElements;
//     const elements = this.elementRef.nativeElement.querySelectorAll('p-button');
//   }

//   private setView() {
//     // if(!this.fieldGrant || !this.fieldKey ){
//     //   this.viewContainer.clear();
//     // }
//     // else{
//     //   if(hasFieldGrant(this.fieldGrant, this.fieldKey, FieldGrant.read, 'allowNull')){
//     //     this.viewContainer.createEmbeddedView(this.templateRef);
//     //   }
//     //   else{
//     //     this.viewContainer.clear();
//     //   }
//     // }
//   }

// }