import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { groupBy } from 'lodash-es';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { Severity } from 'src/app/enums/severity';
import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from 'src/app/models/data-search';
import { EntityManager } from 'src/app/models/entity-config';
import { GenRilascio } from 'src/app/models/gestione/gen-rilascio';
import { MessageApp } from 'src/app/models/gestione/message-app';
import { SecUtente } from 'src/app/models/security/sec-utente';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { DataService } from 'src/app/services/data.service';
import { MessageAppService } from 'src/app/services/message-app.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { dateTimeFormat } from 'src/app/utils/util';

@Component({
  selector: 'app-gen-rilascio-list',
  templateUrl: './gen-rilascio-list.component.html',
  styleUrls: ['./gen-rilascio-list.component.scss'],
  providers: [MessageAppService]
})
export class GenRilascioListComponent implements OnInit, OnDestroy {
  T$: Observable<Resources>;
  resourceList: Resources;
  entityManager: EntityManager;
  elaboration: boolean = false;
  entities: any[];
  dateTimeFormat: string = dateTimeFormat;

  @Input()
  isInHome = false;

  private subscription: Subscription;
  public utente: SecUtente;
  private currentDate: Date = new Date();

  constructor(
    private translateService: TranslateService,
    private appManagerService: AppManagerService,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private messageAppService: MessageAppService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.entityManager = this.appManagerService.getEntityManager(this.activatedRoute);

    // Etichette
    this.T$ = this.translateService.translatedItems$(AppManagerRoutes.GenRilascioList.translateSuffixs ?? ['generic.']).pipe(
      tap(translateItems => this.resourceList = translateItems)
    );

    // Utente
    this.subscription.add(
      this.appManagerService.utente$.subscribe(
        (utente) => {
          this.utente = utente;

          this.loadData();
        }
      )
    );
  }

  accettaRilascio(ids: number[]): void {
    this.dataService
      .postGeneric(`${this.dataService.configSettings.restCommonUrl}/accettagenrilascio`, ids)
      .pipe(
        catchError(err => throwError(() => err)),
        tap(() => {
          const profiloId = this.utente.currentProfiloId;
          this.appManagerService.initUser({ profiloId: profiloId }, 'init');
        }))
      .subscribe(() => {
        this.loadData();
      });
  }

  private loadData(): void {
    const filters = this.isInHome ? [
      new DataSearchValue(['0'], ['flAccettato'], ComparisonOperator.Equals),
      new DataSearchValue(['1'], ['flRilasciato'], ComparisonOperator.Equals)
    ] : [];

    const sorters = [
      new DataSortValue('flRilasciato', SortMode.Asc),
      new DataSortValue('dtAccettazione', SortMode.Asc),
      new DataSortValue('dtRilascio', SortMode.Asc)
    ];

    const ds = new DataSearch(filters, sorters);

    this.elaboration = true;
    this.dataService.searchElements<GenRilascio>(`${this.dataService.configSettings.restCommonUrl}/genrilascio`, ds)
      .pipe(
        map(data => {
          data.entities.map(r => {
            r.flInRitardo = (new Date(r.dtRilascio)).getTime() < this.currentDate.getTime();
            if (r.dettagli.filter(d => d.profili.length).length) { r.flHasProfili = true; } else { r.flHasProfili = false; }
            return r;
          })

          if (!this.utente?.isAdmin) {
            data.entities.map(r => {
              r.dettagli = r.dettagli.filter(d => d.flPertinente);
              return r;
            }).filter(r => r.flPertinente);
          }

          if (this.isInHome) {
            return data.entities.filter(val => val.flHasProfili);
          }
          else {
            return data.entities;
          }
        }),
        map(data => {
          data.map((r: any) => {
            r.dettagliCount = r.dettagli.length;
            r.dettagli = groupBy(r.dettagli, 'titolo');
            return r;
          })
          return data;
        })
      )
      .subscribe(
        data => {
          this.elaboration = false;
          this.entities = data;
          const options = {
            severity: Severity.Info,
            icon: 'fa-solid fa-circle-info'
          };
          const rilasciDaAccettare = data.filter(d => !d.flAccettato && d.flRilasciato && d.flHasProfili).length;
          const msg = new MessageApp(this.translateService.translate(this.resourceList, 'genrilascio.entitiestoaccept.message', { entitiesToAccept: rilasciDaAccettare }), options);
          this.messageAppService.reportMessage(msg);
        },
        () => {
          this.elaboration = false;
        }
      )
  }

  help(): void {
    this.appManagerService.openBrowserTab(AppManagerRoutes.GenHelpLang, this.entityManager.getSection());
  }
}
