import { AppManagerRoutes } from "../../app-manager-routes";
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, EntityManagerConfig, CmdField, } from "../entity-config";

export class PofiloUtenteEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GenRilascioList, label: 'menu.genRilascioList.label', title: 'menu.genRilascioList.title'
      }
    ];
  }
}