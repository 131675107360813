import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppManagerService } from '../services/app-manager.service';

@Injectable()
export class ResultResponseInterceptor implements HttpInterceptor {

  constructor(
    private appManagerService: AppManagerService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        map(
          (httpEvent) => {
            return httpEvent;
          }
        ),
        catchError((errorResponse: HttpErrorResponse) => {
          // const messageData = buildFromResultMessage(errorResponse.error, MessageLevel.Error);
          // this.appManagerService.showMessage(messageData);

          this.appManagerService.showToastErrorMessageTranslated(errorResponse.error?.message);

          // http error handled(message shown) globally by interceptor
          // so set error to null
          //return throwError(null);
          return throwError(() => errorResponse);
        })
      );
  }
}
