import { AppManagerRoutes } from "../../app-manager-routes";
import { CmdField, CmdFieldKey, CmdFieldMode, CmdFieldTarget, EntityManagerConfig, } from "../entity-config";

export class AnaClubEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      // Kennel Club
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.AnaKennelClubList, label: 'menu.anakennelclub.label', title: 'menu.anakennelclub.title'
      },
    ];
  }
}
