<ng-container *ngIf="T$ | async as T">
  <div class="h-full flex flex-column">
    <div *ngIf="!isTab">
      <div class="flex flex-row justify-content-between">
        <div class="text-header">
          {{T | translateItem: 'giudrichiesta.windowListHeader'}}
        </div>
        <div class="buttons-cnt">
          <app-button [outlined]="true" (onClick)="navigateToHelp()" [disabled]="elaboration"
            icon="fa-solid fa-circle-question" [tooltip]="T | translateItem: 'generic.help'" />
        </div>
      </div>

      <p-divider />
    </div>

    <app-giud-richiesta-list-search [elaboration]="elaboration" />

    <div class="flex-grow-1 overflow-y-hidden">
      <div class="h-full overflow-y-auto">
        <p-table [value]="entities$ | async" [totalRecords]="paginatorRowsTot" [lazy]="true" [paginator]="true"
          [first]="paginatorFirst" [rows]="paginatorRows" [rowsPerPageOptions]="rowsPerPageOptions"
          selectionMode="multiple" [(selection)]="entitiesSelected" [lazyLoadOnInit]="false"
          (onLazyLoad)="onLazyLoad($event)" (onPage)="onPage($event)" [showCurrentPageReport]="true"
          [currentPageReportTemplate]="T | translateItem: paginatorRowsTot ? 'generic.listReport' : 'generic.listEmpty'"
          sortMode="multiple" [multiSortMeta]="multiSortMeta$ | async" breakpoint="960px"
          styleClass="p-datatable-striped p-datatable-md" paginatorPosition="bottom" [loading]="elaboration"
          loadingIcon="fa-solid fa-spinner fa-spin">
          <ng-template pTemplate="header">
            <tr>
              <!-- <th class="white-space-nowrap check-column">
                <p-tableHeaderCheckbox />
              </th> -->

              <th class="white-space-nowrap" pSortableColumn="id">
                {{T | translateItem: 'giudrichiesta.id'}}
                <p-sortIcon field="id" />
              </th>

              <th class="white-space-nowrap" pSortableColumn="protocollo">
                {{T | translateItem: 'giudrichiesta.protocollo'}}
                <p-sortIcon field="protocollo" />
              </th>

              <th class="white-space-nowrap" pSortableColumn="dataRichiesta">
                {{T | translateItem: 'giudrichiesta.dataRichiesta'}}
                <p-sortIcon field="dataRichiesta" />
              </th>

              <ng-container *ngIf="entityManager.id != appManagerRoutes.GiudRichiestaGiudiceList.id">
                <th class="white-space-nowrap" pSortableColumn="giudiceCognome">
                  {{T | translateItem: 'giudrichiesta.giudiceCognome'}}
                  <p-sortIcon field="giudiceCognome" />
                </th>

                <th class="white-space-nowrap" pSortableColumn="giudiceNome">
                  {{T | translateItem: 'giudrichiesta.giudiceNome'}}
                  <p-sortIcon field="giudiceNome" />
                </th>
              </ng-container>

              <th class="white-space-nowrap" pSortableColumn="tipoRichiestaDes">
                {{T | translateItem: 'giudrichiesta.tipoRichiesta'}}
                <p-sortIcon field="tipoRichiestaDes" />
              </th>

              <th class="white-space-nowrap" pSortableColumn="subTipoRichiestaDes">
                {{T | translateItem: 'giudrichiesta.subTipoRichiesta'}}
                <p-sortIcon field="subTipoRichiestaDes" />
              </th>

              <th class="white-space-nowrap" pSortableColumn="statoRichiestaDes">
                {{T | translateItem: 'giudrichiesta.statoRichiesta'}}
                <p-sortIcon field="statoRichiestaDes" />
              </th>

              <th class="white-space-nowrap buttons-column">
                <div class="buttons-cnt">
                  <div *ngIf="!readOnly">
                    <p-menu #addMenu [popup]="true" appendTo="body" [model]="addMenuItems$ | async" />
                    <app-button *grantVisible="grants; key 'add'" (onClick)="addMenu.toggle($event)" size="medium"
                      [label]="T | translateItem: 'generic.add'"
                      [disabled]="elaboration || !(addMenuItems$ | async)?.length"
                      [tooltip]="T | translateItem: 'generic.add'" icon="fa-solid fa-plus" />
                  </div>
                  <!--  <app-button *grantVisible="grants; key 'exportdata'" [outlined]="true" size="medium" (onClick)="modalListExportShow()"
                  [disabled]="elaboration" icon="fa-solid fa-file-arrow-down" [tooltip]="T | translateItem: 'generic.export'" /> -->
                </div>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData>
            <tr [tableRowHilight]="rowData.id" [(currentDataKey)]="hilightedRowDataKey">
              <!-- <td class="check-column">
                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
              </td> -->

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudrichiesta.id'}}
                </span>
                {{rowData.id}}
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudrichiesta.protocollo'}}
                </span>
                {{rowData.protocollo}}
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudrichiesta.dataRichiesta'}}
                </span>
                {{rowData.dataRichiesta | date : dateFormat}}
              </td>

              <ng-container *ngIf="entityManager.id != appManagerRoutes.GiudRichiestaGiudiceList.id">
                <td>
                  <span class="p-column-title">
                    {{T | translateItem: 'giudrichiesta.giudiceCognome'}}
                  </span>
                  {{rowData.giudiceCognome}}
                </td>

                <td>
                  <span class="p-column-title">
                    {{T | translateItem: 'giudrichiesta.giudiceNome'}}
                  </span>
                  {{rowData.giudiceNome}}
                </td>
              </ng-container>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudrichiesta.tipoRichiesta'}}
                </span>
                {{rowData.tipoRichiestaDes}}
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudrichiesta.subTipoRichiesta'}}
                </span>
                {{rowData.subTipoRichiestaDes | noValuePlaceholder}}
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudrichiesta.statoRichiesta'}}
                </span>
                <span entityState classPrefix="giud-request-state-badge" [entityStateCod]="rowData.statoRichiestaCod"
                  [label]="rowData.statoRichiestaDes"></span>
              </td>

              <td class="buttons-column">
                <div class="buttons-cnt">
                  <!-- <app-button [outlined]="true" [disabled]="elaboration"
                    (onClick)="edit(rowData.id)" [tooltip]="T | translateItem: 'generic.edit'" icon="fa-solid fa-pencil" />

                  <app-button [outlined]="true" [disabled]="elaboration"
                    (onClick)="settings(rowData.id)" [tooltip]="T | translateItem: 'giudrichiesta.settings'"
                    icon="fa-solid fa-gear" />

                  <app-button [outlined]="true" [disabled]="elaboration"
                    (onClick)="navigateToContacts(rowData.id)" [tooltip]="T | translateItem: 'giudrichiesta.contacts'"
                    icon="fa-solid fa-address-book" /> -->

                  <app-button [outlined]="true" [disabled]="elaboration" (onClick)="navigateToDetail(rowData.id)"
                    [tooltip]="T | translateItem: 'generic.detail'" icon="fa-solid fa-file-lines" />

                  <app-button *ngIf="!readOnly" [outlined]="true" [disabled]="elaboration"
                    (onClick)="navigateToNewAssistentato(rowData.id)"
                    [tooltip]="T | translateItem: 'giudrichiesta.nuovoAssistentato'" icon="fa-solid fa-plus" />

                  <!-- <app-button [outlined]="true" [severity]="danger"
                    [disabled]="true || elaboration || entitiesSelected.length > 1"
                    (onClick)="delete([rowData.id], T['giudrichiesta.deletemessage'])"
                    [tooltip]="T | translateItem: 'generic.delete'" icon="fa-solid fa-trash" /> -->
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="100%" class="text-center danger">
                {{T | translateItem: 'generic.noRecordsFound'}}
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="loadingbody">
            <tr style="height: 2.5rem;">
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</ng-container>
