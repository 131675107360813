<ng-container *ngIf="(searchFields || searchAdvancedFields) && translatedItems$ | async as translateItems">
    <ng-container *ngIf="entityManager.advancedSearch; else search">
        <form [formGroup]="dataForm" novalidate
            class="flex flex-column md:flex-row md:flex-wrap column-gap-2 row-gap-2">
            <!-- (submit)="onSearch()" -->
            <!-- advanced search form -->
            <div class="flex-grow-1">
                <entity-form [formFields]="searchAdvancedFields" [entityManager]="entityManager" [dataForm]="dataForm"
                    [data]="dataSearch" [mode]="'search'">
                </entity-form>
            </div>
            <!-- advanced search buttons -->
            <ng-container
                *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.listSearch, fieldType:cmdFieldMode.btn}) as cmdFields">
                <div class="buttons-cnt flex">
                    <ng-container *ngFor="let field of cmdFields">
                        <button pButton [type]="field.type || 'button'" [class]="field.class"
                            [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field"
                            *ngIf="entityManager | isCmdFieldVisible:field"
                            [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{field: field})"
                            (click)="onCmd(field)" [icon]="field.icon"
                            [title]="translateItems | translateItem: field.title"
                            [label]="translateItems | translateItem: field.label">
                        </button>
                    </ng-container>
                </div>
            </ng-container>
        </form>
    </ng-container>
    <ng-template #search>
        <form [formGroup]="dataForm" novalidate
            class="flex flex-column md:flex-row md:flex-wrap column-gap-2 row-gap-2">
            <!-- (submit)="onSearch()" -->
            <!-- sumple search form -->
            <div class="flex-grow-1">
                <entity-form [formFields]="searchFields" [entityManager]="entityManager" [dataForm]="dataForm"
                    [data]="dataSearch" [mode]="'search'">
                </entity-form>
            </div>
            <!-- simple search buttons -->
            <ng-container
                *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.listSearch, fieldType:cmdFieldMode.btn}) as cmdFields">
                <div class="buttons-cnt flex">
                    <ng-container *ngFor="let field of cmdFields">
                        <button pButton [type]="field.type || 'button'" [class]="field.class"
                            [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field"
                            *ngIf="entityManager | isCmdFieldVisible:field"
                            [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{field: field})"
                            (click)="onCmd(field)" [icon]="field.icon"
                            [title]="translateItems | translateItem: field.title"
                            [label]="translateItems | translateItem: field.label">
                        </button>
                    </ng-container>
                </div>
            </ng-container>
        </form>
    </ng-template>
</ng-container>