import { Validators } from "@angular/forms";
import { AppManagerRoutes } from "../../app-manager-routes";
import { ComparisonOperator, DataSearch, DataSortValue, SortMode } from "../data-search";
import { Entity } from "../entity";
import { CmdField, CmdFieldKey, CmdFieldMode, CmdFieldStyle, CmdFieldTarget, EntityManagerConfig, FormField, FormFieldStyle, FormFieldType, FormFieldTypeHtml, Icons } from "../entity-config";


export class SecRuolo extends Entity {
  profiloId: number;
  cod: string;
  des: string;
  profiloCod: string;
  profiloDes: string;
}

export class SecRuoloEMC extends EntityManagerConfig {

  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none }

  init() {
    this.textComponent = {
      windowListHeader: `${this.name}.windowListHeader`, windowDetailHeader: `${this.name}.windowDetailHeader`
    };
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('cod', SortMode.Asc)];

    const ds: DataSearch = new DataSearch();
    ds.dataSortValues = [new DataSortValue('id', SortMode.Asc)];
    this.attributes['secprofilo'] = this.getEntityService('secprofilo').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // ListEditable
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger,
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary,
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: `${this.name}.SecTagRuoloList`, icon: Icons.security, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.SecTagRuoloList
      }
    ];

    this.listFields = [
      {
        key: 'id', type: FormFieldType.number, label: `${this.name}.id`, sortable: true
      },
      {
        key: 'cod', type: FormFieldType.string, label: `${this.name}.cod`, sortable: true
      },
      {
        key: 'des', type: FormFieldType.string, label: `${this.name}.des`, sortable: true
      },
      {
        key: 'profiloDes', type: FormFieldType.string, label: `${this.name}.profilo`, sortable: true
      }

    ];

    this.detailFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.readonly
      },
      {
        key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'profiloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.cod`, placeholder: `${this.name}.cod`, class: FormFieldStyle.default,
        validators: [Validators.required, Validators.maxLength(20)]
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.des`, placeholder: `${this.name}.des`, class: FormFieldStyle.default,
        validators: [Validators.required, Validators.maxLength(2000)]
      },
      {
        key: 'objProfilo', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.modalListEditable,
        label: `${this.name}.profiloDes`, placeholder: `${this.name}.profiloDes`,
        options: {
          entityManagerInfo: AppManagerRoutes.SecProfiloModal, title: `${this.name}.profiloModalHeader`,
          dataLabel: 'des', fieldsBind: { profiloId: 'id', profiloCod: 'cod', profiloDes: 'des' }, fieldsBindSearch: { id: 'hiddenId' },
          icon: Icons.lookup
        }, validators: [Validators.required]
      }
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'cod,des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'id', searchCompOp: ComparisonOperator.Equals },
      },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.cod`,
        options: { searchFields: 'cod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.des`,
        options: { searchFields: 'des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'profiloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.profilo`,
        options: {
          data: this.attributes['secprofilo'],
          dataKey: 'id',
          dataLabel: 'des',
          searchFields: 'profiloId',
          searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      }
    ];
  }

}

export class SecRuoloModalEMC extends EntityManagerConfig {
  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  init() {
    this.textComponent = {};
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('id', SortMode.Asc)];

    const ds: DataSearch = new DataSearch();
    ds.dataSortValues = [new DataSortValue('id', SortMode.Asc)];

    this.attributes['profili'] = this.getEntityService('secprofilo').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {
    this.cmdFields = [
      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // ListEditable
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger,
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary,
      },
      {
        key: CmdFieldKey.select, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody, entityManagerInfo: AppManagerRoutes.SecTagList,
        title: 'generic.select', icon: Icons.select, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      {
        key: 'id', type: FormFieldType.number, label: `${this.name}.id`, sortable: true, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'cod', type: FormFieldType.string, label: `${this.name}.cod`, sortable: true
      },
      {
        key: 'des', type: FormFieldType.string, label: `${this.name}.des`, sortable: true
      },
      {
        key: 'profiloCod', type: FormFieldType.string, label: `${this.name}.profilo`, sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'profiloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'objProfilo', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.modalList,
        placeholder: `${this.name}.profilo`,
        options: {
          entityManagerInfo: AppManagerRoutes.SecProfiloModal, title: `${this.name}.profiloModalHeader`,
          dataLabel: 'cod', fieldsBind: { profiloId: 'id', profiloCod: 'cod' }, fieldsBindSearch: { id: 'hiddenId' },
          icon: Icons.lookup
        }, validators: [Validators.required]
      },
      {
        key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.cod`, placeholder: `${this.name}.cod`, validators: [Validators.required]
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.textarea,
        label: `${this.name}.des`, placeholder: `${this.name}.des`, validators: [Validators.required]
      }
    ];

    this.searchFields = [
      {
        key: 'hiddenId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: {
          searchFields: 'id', searchCompOp: ComparisonOperator.NotIn,
        }
      },
      {
        key: 'profiloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'profiloId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'cod,des,profiloCod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'profiloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'profiloId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.cod`,
        options: { searchFields: 'cod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.des`,
        options: { searchFields: 'des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'profiloCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.profiloCod`,
        options: { searchFields: 'profiloCod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }

  getDetailFields(args: any): FormField[] {
    return this.detailFields.filter(field => {
      if ('profiloId' !== field.key) return true;

      if (args?.profiloId) {
        return field.typeHtml != FormFieldTypeHtml.select;
      }
      else {
        return field.typeHtml == FormFieldTypeHtml.select;
      }
    });
  }

}

export class SecRuoloByProfiloEMC extends EntityManagerConfig {

  init() {
    this.textComponent = {
      windowListHeader: `${this.name}.windowListHeader`, windowDetailHeader: `${this.name}.windowDetailHeader`
    };
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('cod', SortMode.Asc)];

  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        label: 'generic.back', title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // List Detail
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader, entityManagerInfo: AppManagerRoutes.SecRuoloDetailByProfilo,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody, entityManagerInfo: AppManagerRoutes.SecRuoloDetailByProfilo,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody, entityManagerInfo: AppManagerRoutes.SecRuoloTagList,
        title: 'secruolo.tag', icon: Icons.tag, class: CmdFieldStyle.btnSecondary,
        fieldsBind: { id: 'ruoloId' }, fieldsBindSearch: { id: 'ruoloId' }
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger
      },
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        label: 'generic.back', title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        label: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden, sortable: true
      },
      {
        key: 'cod', type: FormFieldType.string, label: `${this.name}.cod`, sortable: true
      },
      {
        key: 'des', type: FormFieldType.string, label: `${this.name}.des`, sortable: true
      },
      {
        key: 'profiloDes', type: FormFieldType.string, label: `${this.name}.profilo`, sortable: true
      }

    ];

    this.detailFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'profiloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, label: `${this.name}.cod`, placeholder: `${this.name}.cod`, validators: [Validators.required], class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.textarea, label: `${this.name}.des`, placeholder: `${this.name}.des`, validators: [Validators.required], class: FormFieldStyle.default
      }
    ];

    this.searchFields = [
      {
        key: 'profiloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'profiloId', searchCompOp: ComparisonOperator.Equals },
      },
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'cod,des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'id', searchCompOp: ComparisonOperator.Equals },
      },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.cod`,
        options: { searchFields: 'cod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.des`,
        options: { searchFields: 'des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'profilo', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.profilo`,
        options: { searchFields: 'profilo.cod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }

}
