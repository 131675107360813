import { Entity } from "../entity";

export class GiudCertificato extends Entity {
  giudiceId: number;
  tipoCertificatoId: number;
  dataInizio: Date;
  dataFine: Date;
  note: string;

  tipoCertificatoCod: string;
  tipoCertificatoDes: string;
  flCertificatoScaduto: boolean;
  flCertificatoValido: boolean;
}