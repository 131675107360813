import { Validators } from "@angular/forms";
import { AppManagerRoutes } from "../../app-manager-routes";
import { ComparisonOperator, DataSortValue, SortMode } from "../data-search";
import { Entity } from "../entity";
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, CmdFieldStyle, Icons, FormFieldStyle, } from "../entity-config";


export class SecTag extends Entity {
  sezione: string;
  cod: string;
  des: string;
}

export class SecTagEMC extends EntityManagerConfig {
  init() {
    this.textComponent = {
      windowListHeader: `${this.name}.windowlistheader`
    };
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('sezione', SortMode.Asc)];

    this.attributes['gensezione'] = (AppManagerRoutes.getSezioneList()).sort((a, b) => a.cod.localeCompare(b.cod));
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // ListEditable
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: `${this.name}.ruolo`, icon: Icons.tag, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.SecRuoloTagList
      }
    ];

    this.listFields = [
      {
        key: 'id', type: FormFieldType.number, label: `${this.name}.id`, sortable: true
      },
      {
        key: 'sezione', type: FormFieldType.string, label: `${this.name}.sezione`, sortable: true
      },
      {
        key: 'cod', type: FormFieldType.string, label: `${this.name}.cod`, sortable: true
      },
      {
        key: 'des', type: FormFieldType.string, label: `${this.name}.des`, sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.readonly
      },
      {
        key: 'sezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.sezione`, placeholder: `${this.name}.sezione`,
        options: { data: this.attributes['gensezione'], dataKey: 'cod', dataLabel: 'des' },
        validators: [Validators.required]
      },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, label: `${this.name}.cod`, placeholder: `${this.name}.cod`, validators: [Validators.required]
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, label: `${this.name}.des`, placeholder: `${this.name}.des`, validators: [Validators.required]
      }
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'sezione,cod,des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'sezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.sezione`,
        options: { data: this.attributes['gensezione'], dataKey: 'cod', dataLabel: 'des', searchFields: 'sezione', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.default
      },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.cod`,
        options: { searchFields: 'cod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.des`,
        options: { searchFields: 'des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }

}
export class SecTagModalEMC extends EntityManagerConfig {
  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('id', SortMode.Asc)];

    this.attributes['gensezione'] = AppManagerRoutes.getSezioneList();
  }

  initFields() {
    this.cmdFields = [
      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // ListEditable
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger,
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary,
      },
      {
        key: CmdFieldKey.select, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.select', icon: Icons.select, class: CmdFieldStyle.btnPrimary
      },
    ];

    this.listFields = [
      {
        key: 'id', type: FormFieldType.number, label: `${this.name}.id`, sortable: true, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'sezione', type: FormFieldType.string, label: `${this.name}.sezione`, sortable: true
      },
      {
        key: 'cod', type: FormFieldType.string, label: `${this.name}.cod`, sortable: true
      },
      {
        key: 'des', type: FormFieldType.string, label: `${this.name}.des`, sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'sezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.sezione`, placeholder: `${this.name}.sezione`,
        options: { data: this.attributes['gensezione'], dataKey: 'cod', dataLabel: 'des' },
        validators: [Validators.required]
      },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, label: `${this.name}.cod`, placeholder: `${this.name}.cod`, validators: [Validators.required]
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, label: `${this.name}.des`, placeholder: `${this.name}.des`, validators: [Validators.required]
      }
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'sezione,cod,des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'sezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.sezione`,
        options: { data: this.attributes['gensezione'], dataKey: 'cod', dataLabel: 'des', searchFields: 'sezione', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.default
      },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.cod`,
        options: { searchFields: 'cod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.des`,
        options: { searchFields: 'des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }

}
