import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SortMeta } from 'primeng/api';
import { TableLazyLoadEvent, TablePageEvent } from 'primeng/table';
import { BehaviorSubject, Observable, Subject, catchError, combineLatest, filter, map, switchMap, takeUntil, tap, throwError } from 'rxjs';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { DataSearch, SearchFieldsConf } from 'src/app/models/data-search';
import { EntityManager, EntityManagerGrant } from 'src/app/models/entity-config';
import { MessageData, MessageDataConfig, ToastMessageData } from 'src/app/models/message';
import { AppManagerService, SESSION_STORAGE_SELECTION, StorageType } from 'src/app/services/app-manager.service';
import { BaseDataService } from 'src/app/services/base-data.service';
import { BaseWindowListService } from 'src/app/services/base-window-list.service';
import { DataService } from 'src/app/services/data.service';
import { TriggersService } from 'src/app/services/extension/triggers.service';
import { ListExportService } from 'src/app/services/list-export.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { dateFormat } from 'src/app/utils/util';

@Component({
  selector: 'app-base-window-list',
  template: ''
})
export class BaseWindowListComponent<T> implements OnInit, OnDestroy {
  protected destroy$: Subject<void> = new Subject();

  entityManager: EntityManager = this.appManagerService.getEntityManager(this.activatedRoute);

  protected restServiceUrl: string;

  protected entityServiceUrl: string;

  // Etichette
  T$: Observable<Resources> = this.translateService.translatedItems$(this.entityManager.translateSuffixs).pipe(
    tap(translateItems => this.resourceList = translateItems)
  );

  resourceList: Resources;

  dataSearch: DataSearch;

  dateFormat: string = dateFormat;

  // Grants
  grants: EntityManagerGrant;

  // Pagination
  rowsPerPageOptions: number[] = [10, 25, 50, 100];

  // defaultPaginatorFirst: number = 0;

  // defaultPaginatorRows: number = 10;

  get paginatorFirst(): number {
    return this.baseWindowListService.paginatorFirst;
  };

  get paginatorRows(): number {
    return this.baseWindowListService.paginatorRows;
  };

  paginatorRowsTot: number;

  // Sorting
  defaultMultiSortMeta: SortMeta[] = [];

  // multiSortMeta!: SortMeta[];

  multiSortMeta$: Observable<SortMeta[]> = this.baseWindowListService.multiSortMeta$;

  // Filters
  searchFieldsConf: SearchFieldsConf[];

  defaultFilters: Object;

  persistFilters: boolean = true;

  // Selection
  entitiesSelected: T[] = [];

  hilightedRowDataKey: string;

  elaboration: boolean = true;

  // Actions
  refreshEntities$: BehaviorSubject<void> = new BehaviorSubject(null);

  // Entities
  entities$: Observable<T[]> = combineLatest([
    this.refreshEntities$.asObservable(),
    this.baseWindowListService.dataSearch$.pipe(
      filter((data) => data instanceof DataSearch)
    )
  ]).pipe(
    tap(() => this.elaboration = true),
    switchMap(([_, dataSearch]: [void, DataSearch]) => {
      return this.dataService.searchElements<T>(`${this.restServiceUrl}/${this.entityServiceUrl ?? this.entityManager.name}`, dataSearch).pipe(
        catchError((err) => {
          this.elaboration = false
          return throwError(() => err);
        })
      )
    }),
    tap((data) => this.paginatorRowsTot = data.numRowsTot),
    map((data) => data.entities),
    tap(() => this.elaboration = false)
  );

  // List export
  protected modalListExport: { visible: boolean, data: any } = { visible: false, data: undefined };

  protected exportEntityName: string;

  protected exportNameSpace: string;

  // Tabs
  @Input()
  tabIndex: number;

  constructor(
    private listExportService: ListExportService,
    private triggersService: TriggersService,
    protected baseWindowListService: BaseWindowListService,
    protected translateService: TranslateService,
    protected activatedRoute: ActivatedRoute,
    protected dataService: DataService,
    protected baseDataService: BaseDataService,
    protected router: Router,
    protected appManagerService: AppManagerService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    // Grants
    this.appManagerService.getGrants(this.entityManager.getSection() ?? this.entityManager.id).pipe(
      takeUntil(this.destroy$)
    ).subscribe(grants => this.grants = grants);

    // this.multiSortMeta$.pipe(
    //   takeUntil(this.destroy$)
    // ).subscribe(data => this.multiSortMeta = data);

    // Selezione
    this.hilightedRowDataKey = this.getStorageHilightedRowDataKey();

    // Refresh dati al cambio tab
    if (this.tabIndex >= 0) {
      this.triggersService.triggerTabChange$.pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        (currTabIndex: number) => {
          if (currTabIndex === this.tabIndex) {
            this.refreshEntities();
          }
        }
      );
    }
  }

  initFilters(): void {
    this.baseWindowListService.init(
      this.searchFieldsConf,
      this.defaultFilters,
      this.persistFilters,
      this.entityManager.id,
      this.defaultMultiSortMeta
    );
  }

  onPage(event: TablePageEvent): void {
    // this.paginatorFirst = event.first;
    // this.paginatorRows = event.rows;
    this.baseWindowListService.setPage(event.first, event.rows);
  }

  onLazyLoad(event: TableLazyLoadEvent): void {
    this.baseWindowListService.setSorting(event.multiSortMeta);
  }

  protected refreshEntities(): void {
    this.refreshEntities$.next();
  }

  navigateToHelp(): void {
    this.appManagerService.openBrowserTab(AppManagerRoutes.GenHelpLang, this.entityManager.getSection());
  }

  addEntity(): void { }

  modalListExportShow(): void {
    this.listExportService.openModal(this.exportEntityName, this.dataSearch, this.exportNameSpace);
  }

  modalListExportHide(): void {
    this.modalListExport.data = undefined;
    this.modalListExport.visible = false;
  }

  protected setStorageHilightedRowDataKey(dataKey: string): void {
    this.appManagerService.setStorageData(SESSION_STORAGE_SELECTION, this.entityManager.id, { dataKey }, StorageType.Session);
  }

  private getStorageHilightedRowDataKey(): string {
    const data = this.appManagerService.getStorageData(SESSION_STORAGE_SELECTION, this.entityManager.id, StorageType.Session);
    return data?.dataKey || undefined;
  }

  deleteEntities(ids: number[], messageKey?: string): void {
    if (ids.length == 0) {
      return;
    }

    const message = this.translateService.translate(this.resourceList, messageKey ?? 'generic.deleteMsg');

    const hEventOk = () => {
      this.elaboration = true;

      this.dataService.deleteElements<T>(`${this.restServiceUrl}/${this.entityServiceUrl ?? this.entityManager.name}`, ids).pipe(
        takeUntil(this.destroy$)
      ).subscribe({
        next: () => {
          this.elaboration = false;

          this.appManagerService.showToastMessage(new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.deleteSuccessMsg')));
          this.refreshEntities();
        },
        error: () => {
          this.elaboration = false;

          this.appManagerService.showToastMessage(new ToastMessageData('error', this.translateService.translate(this.resourceList, 'generic.deleteErrorMsg')));
        }
      });
    }

    const conf = MessageDataConfig.Delete;

    const messageData = new MessageData(conf.mode, conf.level, message, conf.title, null, { ...conf.options, notTranslateMessage: true });
    messageData.hEventOk = hEventOk;

    this.appManagerService.showMessage(messageData);
  }

  // resetFilters(): void {
  //   //this.searchFilters = this.defaultFilters;
  //   //  this.multiSortMeta = this.defaultMultiSortMeta;
  //   // this.dataSortValues = convertSortMetaArrayToDataSortValueArray(this.defaultMultiSortMeta);
  //   // this.paginatorFirst = this.defaultPaginatorFirst;
  //   // this.paginatorRows = this.defaultPaginatorRows;
  //   // this.baseWindowListService.setSorting(this.defaultMultiSortMeta);
  //   // this.baseWindowListService.setPage(this.defaultPaginatorFirst, this.defaultPaginatorRows);
  //   // this.baseWindowListService.setSearchFilters(this.defaultFilters);
  //   this.baseWindowListService.reset();
  // }
}


