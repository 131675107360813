import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { Observable, Subject, combineLatest, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { CalCaleManiGiudAssistentatoDto } from 'src/app/models/calendario/dto/cal-cale-mani-giud-assistentato-dto';
import { TipoManiIdCodProtipo } from 'src/app/models/giud-richiesta/dto/cal-cale-mani-giud-assistentato-prv-pars';
import { GiudTipoRichiestaCod } from 'src/app/models/giud-richiesta/giud-tipo-richiesta';
import { GiudAssistentato } from 'src/app/models/giudici/giud-assistentato';
import { GiudEsitoAssistentato } from 'src/app/models/giudici/giud-esito-assistentato';
import { GiudStatoAssistentato, StatoAssistentatoCod, StatoAssistentatoLivello } from 'src/app/models/giudici/giud-stato-assistentato';
import { ToastMessageData } from 'src/app/models/message';
import { SecUtente } from 'src/app/models/security/sec-utente';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { BaseDataService } from 'src/app/services/base-data.service';
import { DataService } from 'src/app/services/data.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { convertDateFieldToLocalDate, dateFormat, dateFormatPrNg } from 'src/app/utils/util';

@Component({
  selector: 'app-giud-assistentato-edit',
  templateUrl: './giud-assistentato-edit.component.html',
  styleUrls: ['./giud-assistentato-edit.component.scss']
})
export class GiudAssistentatoEditComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject();

  // Etichette
  T$: Observable<Resources> = this.translateService.translatedItems$(AppManagerRoutes.GiudAssistentatoEdit.translateSuffixs ?? ['generic.']).pipe(
    tap(translateItems => this.resourceList = translateItems)
  );

  resourceList: Resources;

  dateFormat: string = dateFormat;

  dateFormatPrNg: string = dateFormatPrNg;

  elaboration: boolean = true;

  giudTipoRichiestaCod: typeof GiudTipoRichiestaCod = GiudTipoRichiestaCod;

  // Form
  dataForm = this.fb.group({
    id: this.fb.control<number | null>(0, Validators.required),
    richiestaDetailId: this.fb.control<number | null>({ value: null, disabled: true }, Validators.required),
    dataRichiesta: this.fb.control<Date | null>(new Date(), Validators.required),
    statoAssistentatoId: this.fb.control<number | null>(null, Validators.required),
    giudiceId: this.fb.control<number | null>(null, Validators.required),
    caleManiId: this.fb.control<number | null>(null, Validators.required),
    caleManiSpId: this.fb.control<number | null>(null),
    esitoAssistentatoId: this.fb.control<number | null>(null),
    esitoNote: this.fb.control<string | null>(null),
    assistentatoIdPadre: this.fb.control<number | null>(null),
    userIns: this.fb.control<string | null>(null),
    dtIns: this.fb.control<Date | null>(null),
    userUpd: this.fb.control<string | null>(null),
    dtUpd: this.fb.control<Date | null>(null),
    tipoRichiestaCod: this.fb.control<string | null>(null),
    giudiceNomeCompleto: this.fb.control<string | null>(null),
    caleManiDataInizio: this.fb.control<Date | null>(null),
    caleManiDataFine: this.fb.control<Date | null>(null),
    razzaId: this.fb.control<number | null>(null),
    razzaDes: this.fb.control<string | null>(null),
    tipoManiId: this.fb.control<number | null>(null),
    tipoManiCod: this.fb.control<string | null>(null),
    tipoManiDes: this.fb.control<string | null>(null),
    codProtipo: this.fb.control<string | null>(null)
  });

  // Date
  dataInizioMin: Date = new Date();

  get dataFineMin(): Date {
    return this.dataForm.controls.caleManiDataInizio.value;
  }

  // Dropdowns
  statoAssistentatoList$: Observable<GiudStatoAssistentato[]> = this.baseDataService.getGiudStatoAssistentatoList();

  esitoAssistentatoList$: Observable<GiudEsitoAssistentato[]> = this.baseDataService.getGiudEsitoAssistentatoList();

  // Ricerca mani
  maniSuggestions$!: Observable<CalCaleManiGiudAssistentatoDto[]>;

  firstManiLoad: boolean = true;

  // Utente
  utente: SecUtente;

  // Stati
  statoAssistentatoCod: typeof StatoAssistentatoCod = StatoAssistentatoCod;

  statoAssistentatoLivello: typeof StatoAssistentatoLivello = StatoAssistentatoLivello;

  // Entity
  private _entity: GiudAssistentato = new GiudAssistentato();

  @Input()
  visible: boolean = false;

  @Input()
  get entity(): GiudAssistentato {
    return this._entity;
  }

  set entity(val: GiudAssistentato) {
    const data = convertDateFieldToLocalDate(val, ['dataRichiesta', 'caleManiDataInizio', 'caleManiDataFine', 'dtIns', 'dtUpd']);
    this._entity = data;
  }

  @Input()
  richiestaId!: number;

  @Input()
  giudiceRichiedenteId!: number;

  @Input()
  rilascioEsito: boolean = false;

  @Output()
  onHide = new EventEmitter<boolean>();

  constructor(
    private translateService: TranslateService,
    private baseDataService: BaseDataService,
    private fb: FormBuilder,
    private dataService: DataService,
    private appManagerService: AppManagerService,
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    // Verifico il profilo e le info dell'utente
    this.appManagerService.utente$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (utente) => this.utente = utente
    );

    // Se sono in modalità rilascio esito setto come obbligatorio il campo
    if (this.rilascioEsito) {
      this.elaboration = false;
      const esitoAssistentatoIdField = this.dataForm.controls.esitoAssistentatoId;
      esitoAssistentatoIdField.addValidators(Validators.required);
      esitoAssistentatoIdField.updateValueAndValidity();
    }

    // Manifestazioni esposizioni
    if (this.entity.tipoRichiestaCod === GiudTipoRichiestaCod.AmpliamentoEsposizioni) {
      this.maniSuggestions$ = combineLatest([
        this.dataForm.controls.razzaId.valueChanges.pipe(
          startWith(this.entity.razzaId
          ),
          distinctUntilChanged(),
          filter(data => !!data)
        ),
        this.dataForm.controls.giudiceId.valueChanges.pipe(
          startWith(this.entity.giudiceId),
          distinctUntilChanged(),
          filter(data => !!data)
        ),
        this.dataForm.controls.caleManiDataInizio.valueChanges.pipe(
          startWith(this.entity.caleManiDataInizio),
          distinctUntilChanged(),
          filter(data => !!data)
        ),
        this.dataForm.controls.caleManiDataFine.valueChanges.pipe(
          startWith(this.entity.caleManiDataFine),
          distinctUntilChanged(),
          filter(data => !!data)
        )
      ]).pipe(
        map(([razzaId, giudiceId, dataInizio, dataFine]: [number, number, Date, Date]) => {
          const x = {
            giudiceId: giudiceId,
            razzeIds: [razzaId],
            dataInizio: DateTime.fromJSDate(dataInizio).toISODate(),
            dataFine: DateTime.fromJSDate(dataFine).toISODate(),
            giudiceRichiedenteId: this.giudiceRichiedenteId
          };

          if (this.firstManiLoad) {
            x['caleManiId'] = this.dataForm.controls.caleManiId.value;
          }

          return x;
        }),
        tap(() => this.elaboration = true),
        switchMap((queryParams) => {
          return this.dataService.get<CalCaleManiGiudAssistentatoDto[]>(`${this.dataService.configSettings.restCalendarioUrl}/calcalemanigiudassistentatoexp`, queryParams).pipe(
            catchError((err) => {
              this.elaboration = false;

              return throwError(() => err);
            })
          )
        }),
        map((data) => {
          data.forEach(v => v.disabled = v.disabled && v.id !== this.entity.caleManiId);

          return data;
        }),
        tap(() => {
          this.elaboration = false;
          this.firstManiLoad = false;
        })
      );
    }

    // Manifestazioni prove
    if (this.entity.tipoRichiestaCod === GiudTipoRichiestaCod.AmpliamentoProve) {
      this.maniSuggestions$ = combineLatest([
        this.dataForm.controls.tipoManiId.valueChanges.pipe(
          startWith(this.entity.tipoManiId
          ),
          distinctUntilChanged(),
          filter(data => !!data)
        ),
        this.dataForm.controls.codProtipo.valueChanges.pipe(
          startWith(this.entity.codProtipo
          ),
          distinctUntilChanged(),
          filter(data => !!data)
        ),
        this.dataForm.controls.giudiceId.valueChanges.pipe(
          startWith(this.entity.giudiceId),
          distinctUntilChanged(),
          filter(data => !!data)
        ),
        this.dataForm.controls.caleManiDataInizio.valueChanges.pipe(
          startWith(this.entity.caleManiDataInizio),
          distinctUntilChanged(),
          filter(data => !!data)
        ),
        this.dataForm.controls.caleManiDataFine.valueChanges.pipe(
          startWith(this.entity.caleManiDataFine),
          distinctUntilChanged(),
          filter(data => !!data)
        )
      ]).pipe(
        map(([tipoManiId, codProtipo, giudiceId, dataInizio, dataFine]: [number, string, number, Date, Date]) => {
          const data = this.tipoManiIdCodProtipo(tipoManiId, codProtipo);

          const x = {
            giudiceId: giudiceId,
            tipoManiIdCodProtipoList: [data],
            dataInizio: DateTime.fromJSDate(dataInizio).toISODate(),
            dataFine: DateTime.fromJSDate(dataFine).toISODate(),
            giudiceRichiedenteId: this.giudiceRichiedenteId
          }

          if (this.firstManiLoad) {
            x['caleManiId'] = this.dataForm.controls.caleManiId.value;
          }

          return x;
        }),
        tap(() => this.elaboration = true),
        switchMap((data) => {
          return this.dataService.postGeneric(`${this.dataService.configSettings.restCalendarioUrl}/calcalemanigiudassistentatoprv`, data).pipe(
            catchError((err) => {
              this.elaboration = false;
              return throwError(() => err);
            })
          )
        }),
        map((data) => {
          data.forEach(v => v.disabled = v.disabled && v.id !== this.entity.caleManiId);

          return data;
        }),
        tap(() => {
          this.elaboration = false;
          this.firstManiLoad = false;
        })
      );
    }

    // Quando retrocedo lo stato ad uno che non prevede l'esito, cancello esito e nota
    combineLatest([
      this.statoAssistentatoList$,
      this.dataForm.controls.statoAssistentatoId.valueChanges
    ]).pipe(
      takeUntil(this.destroy$),
      map(([list, statoAssistentatoId]: [list: GiudStatoAssistentato[], statoAssistentatoId: number]) => list.find(s => s.id === statoAssistentatoId))
    ).subscribe(
      (statoAssistentato) => {
        if (!statoAssistentato.flRichiedeEsito) {
          this.dataForm.controls.esitoAssistentatoId.reset();
          this.dataForm.controls.esitoNote.reset();
        }
      }
    );

    // Se sono in edit valorizzo il form
    this.dataForm.patchValue(this.entity);
  }

  hideModal(reloadData: boolean = false): void {
    this.visible = false;
    this.onHide.emit(reloadData);
  }

  save(): void {
    const data = this.dataForm.getRawValue() as GiudAssistentato;

    this.dataService.updateElement<GiudAssistentato>(`${this.dataService.configSettings.restCommonUrl}/giudassistentato`, data, this.entity.id.toString()).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: () => {
        this.elaboration = false;

        this.appManagerService.showToastMessage(
          new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.savesuccessmsg')));

        this.dataForm.markAsPristine();

        this.hideModal(true);
      },
      error: () => {
        this.elaboration = false;
      }
    });
  }

  private tipoManiIdCodProtipo(tipoManiId: number, codProtipo: string): TipoManiIdCodProtipo {
    return {
      tipoManiId,
      codProtipo
    };
  }
}
