<ng-container *ngIf="T$ | async as T">
  <div class="h-full flex flex-column">
    <div
      *ngIf="this.entityManager.id !== appManagerRoutes.GiudRichiestaDetail.id && this.entityManager.id !== appManagerRoutes.CalCaleManiSettings.id">
      <div class="flex flex-row justify-content-between">
        <div class="text-header">
          {{T | translateItem : (this.entityManager.id | i18nSelect : titleMap)}}
        </div>
        <div class="buttons-cnt">
          <app-button [outlined]="true" (onClick)="navigateToHelp()" [disabled]="elaboration"
            icon="fa-solid fa-circle-question" [tooltip]="T | translateItem: 'generic.help'" />
        </div>
      </div>

      <p-divider />
    </div>

    <app-giud-assistentato-list-search [elaboration]="elaboration" [tipoCalCod]="tipoCalCod" />

    <div class="flex-grow-1 overflow-y-hidden">
      <div class="h-full overflow-y-auto">
        <p-table [value]="entities$ | async" [totalRecords]="paginatorRowsTot" [paginator]="true" [lazy]="true"
          [lazyLoadOnInit]="false" (onLazyLoad)="onLazyLoad($event)" (onPage)="onPage($event)" [first]="paginatorFirst"
          [rows]="paginatorRows" [rowsPerPageOptions]="rowsPerPageOptions" selectionMode="multiple"
          [(selection)]="entitiesSelected" [showCurrentPageReport]="true"
          [currentPageReportTemplate]="T | translateItem: paginatorRowsTot ? 'generic.listReport' : 'generic.listEmpty'"
          sortMode="multiple" [multiSortMeta]="multiSortMeta$ | async" responsiveLayout="stack" breakpoint="960px"
          styleClass="p-datatable-striped p-datatable-md" paginatorPosition="bottom" [loading]="elaboration"
          loadingIcon="fa-solid fa-spinner fa-spin">
          <ng-template pTemplate="header">
            <tr>
              <th class="white-space-nowrap check-column">
                <p-tableHeaderCheckbox />
              </th>

              <th class="white-space-nowrap" pSortableColumn="id">
                {{T | translateItem: 'giudassistentato.id'}}
                <p-sortIcon field="id" />
              </th>

              <ng-container *ngIf="this.entityManager.id !== appManagerRoutes.GiudRichiestaDetail.id">
                <th class="white-space-nowrap" pSortableColumn="giudiceRichiedenteNome">
                  {{T | translateItem: 'giudassistentato.giudiceRichiedenteNome'}}
                  <p-sortIcon field="giudiceRichiedenteNome" />
                </th>

                <th class="white-space-nowrap" pSortableColumn="giudiceRichiedenteCognome">
                  {{T | translateItem: 'giudassistentato.giudiceRichiedenteCognome'}}
                  <p-sortIcon field="giudiceRichiedenteCognome" />
                </th>
              </ng-container>

              <!-- <th class="white-space-nowrap" pSortableColumn="dataRichiesta">
                {{T | translateItem: 'giudassistentato.dataRichiesta'}}
                <p-sortIcon field="dataRichiesta" />
              </th> -->

              <!-- <ng-container *ngIf="tipoCalCod === 'EXP'">
                <th class="white-space-nowrap" pSortableColumn="gruppoFciId">
                  {{T | translateItem: 'giudassistentato.gruppoFci'}}
                  <p-sortIcon field="gruppoFciId" />
                </th>

                <th class="white-space-nowrap" pSortableColumn="razzaDes">
                  {{T | translateItem: 'giudassistentato.razza'}}
                  <p-sortIcon field="razzaDes" />
                </th>
              </ng-container> -->

              <!-- <ng-container *ngIf="tipoCalCod === 'PRV'">
                <th class="white-space-nowrap" pSortableColumn="tipoManiDes">
                  {{T | translateItem: 'giudassistentato.tipoMani'}}
                  <p-sortIcon field="tipoManiDes" />
                </th>

                <th class="white-space-nowrap" pSortableColumn="codProtipo">
                  {{T | translateItem: 'giudassistentato.codProtipo'}}
                  <p-sortIcon field="codProtipo" />
                </th>
              </ng-container> -->

              <th class="white-space-nowrap" pSortableColumn="richiestaDetailLabel">
                {{T | translateItem: 'giudassistentato.richiestaDetailLabel'}}
                <p-sortIcon field="richiestaDetailLabel" />
              </th>

              <ng-container *ngIf="this.entityManager.id !== appManagerRoutes.CalCaleManiSettings.id">
                <th class="white-space-nowrap" pSortableColumn="caleManiLabel">
                  {{T | translateItem: 'giudassistentato.caleMani'}}
                  <p-sortIcon field="caleManiLabel" />
                </th>
              </ng-container>

              <th class="white-space-nowrap" pSortableColumn="data">
                {{T | translateItem: 'giudassistentato.data'}}
                <p-sortIcon field="data" />
              </th>

              <th *ngIf="tipoCalCod === 'PRV'" class="white-space-nowrap" pSortableColumn="sottoProvaLabel">
                {{T | translateItem: 'giudassistentato.sottoProva'}}
                <p-sortIcon field="sottoProvaLabel" />
              </th>

              <ng-container *ngIf="this.entityManager.id !== appManagerRoutes.GiudAssistentatoGiudiceList.id">
                <th class="white-space-nowrap" pSortableColumn="giudiceNome">
                  {{T | translateItem: 'giudassistentato.giudiceNome'}}
                  <p-sortIcon field="giudiceNome" />
                </th>

                <th class="white-space-nowrap" pSortableColumn="giudiceCognome">
                  {{T | translateItem: 'giudassistentato.giudiceCognome'}}
                  <p-sortIcon field="giudiceCognome" />
                </th>
              </ng-container>

              <th class="white-space-nowrap" pSortableColumn="esitoAssistentatoDes">
                {{T | translateItem: 'giudassistentato.esitoAssistentato'}}
                <p-sortIcon field="esitoAssistentatoDes" />
              </th>

              <th class="white-space-nowrap" pSortableColumn="statoAssistentatoDes">
                {{T | translateItem: 'giudassistentato.statoAssistentato'}}
                <p-sortIcon field="statoAssistentatoDes" />
              </th>

              <th></th>

              <th class="white-space-nowrap buttons-column">
                <div class="buttons-cnt">
                  <div *ngIf="this.entityManager.id === appManagerRoutes.GiudRichiestaDetail.id">
                    <app-button *grantVisible="grants; key 'add'" (onClick)="addEntity()" size="medium"
                      [label]="T | translateItem: 'generic.add'" [disabled]="addButtonDisabled"
                      [tooltip]="T | translateItem: 'generic.add'" icon="fa-solid fa-plus" />
                  </div>
                </div>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData>
            <tr [tableRowHilight]="rowData.id" [(currentDataKey)]="hilightedRowDataKey">
              <td class="check-column">
                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.id'}}
                </span>
                {{rowData.id}}
              </td>

              <ng-container *ngIf="this.entityManager.id !== appManagerRoutes.GiudRichiestaDetail.id">
                <td>
                  <span class="p-column-title">
                    {{T | translateItem: 'giudassistentato.giudiceRichiedenteNome'}}
                  </span>
                  {{rowData.giudiceRichiedenteNome}}
                </td>

                <td>
                  <span class="p-column-title">
                    {{T | translateItem: 'giudassistentato.giudiceRichiedenteCognome'}}
                  </span>
                  {{rowData.giudiceRichiedenteCognome}}
                </td>
              </ng-container>

              <!-- <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.dataRichiesta'}}
                </span>
                {{rowData.dataRichiesta | date: dateFormat}}
              </td> -->

              <!-- <ng-container *ngIf="tipoCalCod === 'EXP'">
                <td>
                  <span class="p-column-title">
                    {{T | translateItem: 'giudassistentato.gruppoFci'}}
                  </span>
                  {{rowData.gruppoFciLabel}}
                </td>

                <td>
                  <span class="p-column-title">
                    {{T | translateItem: 'giudassistentato.razza'}}
                  </span>
                  {{rowData.razzaDes}}
                </td>
              </ng-container> -->

              <!-- <ng-container *ngIf="tipoCalCod === 'PRV'">
                <td>
                  <span class="p-column-title">
                    {{T | translateItem: 'giudassistentato.tipoMani'}}
                  </span>
                  {{rowData.tipoManiDes}}
                </td>

                <td>
                  <span class="p-column-title">
                    {{T | translateItem: 'giudassistentato.codProtipo'}}
                  </span>
                  {{rowData.codProtipo}}
                </td>
              </ng-container> -->

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.richiestaDetailLabel'}}
                </span>
                {{rowData.richiestaDetailLabel}}
              </td>

              <ng-container *ngIf="this.entityManager.id !== appManagerRoutes.CalCaleManiSettings.id">
                <td>
                  <span class="p-column-title">
                    {{T | translateItem: 'giudassistentato.caleMani'}}
                  </span>
                  {{rowData.caleManiLabel}}
                </td>
              </ng-container>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.giorno'}}
                </span>
                {{rowData.data | date: dateFormat}}
              </td>

              <td *ngIf="tipoCalCod === 'PRV'">
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.sottoProva'}}
                </span>
                {{rowData.sottoProvaLabel}}
              </td>

              <ng-container *ngIf="this.entityManager.id !== appManagerRoutes.GiudAssistentatoGiudiceList.id">
                <td>
                  <span class="p-column-title">
                    {{T | translateItem: 'giudassistentato.giudiceNome'}}
                  </span>
                  {{rowData.giudiceNome}}
                </td>

                <td>
                  <span class="p-column-title">
                    {{T | translateItem: 'giudassistentato.giudiceCognome'}}
                  </span>
                  {{rowData.giudiceCognome}}
                </td>
              </ng-container>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.esitoAssistentato'}}
                </span>
                <span class="white-space-nowrap"
                  [ngClass]="{'badge-success': rowData.esitoAssistentatoCod === esitoAssistentatoCod.Superato, 'badge-danger': rowData.esitoAssistentatoCod === esitoAssistentatoCod.NonSuperato}">
                  {{rowData.esitoAssistentatoDes | titlecase | noValuePlaceholder}}</span>
              </td>

              <td>
                <span class="p-column-title">
                  {{T | translateItem: 'giudassistentato.statoAssistentato'}}
                </span>
                <span entityState classPrefix="giud-assistentato-state-badge"
                  [entityStateCod]="rowData.statoAssistentatoCod" [label]="rowData.statoAssistentatoDes">
                </span>
              </td>

              <td>
                <div class="white-space-nowrap generic-cnt text-lg">
                  <i *ngIf="rowData.flRipetizioneConGiudiceDiverso" class="fa-solid fa-people-arrows warning"
                    [pTooltip]="T | translateItem: 'giudassistentato.ripetizioneConGiudiceDiverso'"
                    tooltipPosition="top"></i>

                  <i *ngIf="rowData.flRaggiuntoLimiteAssistentatiRazzaMani"
                    class="fa-solid fa-triangle-exclamation warning"
                    [pTooltip]="T | translateItem: 'giudassistentato.raggiuntoLimiteAssistentatiRazzaMani'"
                    tooltipPosition="top"></i>

                  <i *ngIf="rowData.flSuPiuGiorni" class="fa-solid fa-calendar-week warning"
                    [pTooltip]="T | translateItem: 'giudassistentato.razzaPiuGiorniMessage'" tooltipPosition="top"></i>
                </div>
              </td>

              <td class="buttons-column">
                <div class="buttons-cnt">
                  <ng-container *ngIf="this.entityManager.id === appManagerRoutes.GiudRichiestaDetail.id">
                    <app-button *grantVisible="grants; key 'edit'" [outlined]="true"
                      [disabled]="elaboration || (! utente.isAdmin && rowData.statoAssistentatoLivello >= statoAssistentatoLivello.AccettatoEnci)"
                      (onClick)="editEntity(rowData)" [tooltip]="T | translateItem: 'generic.edit'"
                      icon="fa-solid fa-pencil" />
                  </ng-container>

                  <!-- Approvazione ENCI -->
                  <ng-container
                    *ngIf="rowData.statoAssistentatoLivello === statoAssistentatoLivello.Inserito && utente?.isAdmin">
                    <app-button *grantVisible="grants; key 'acceptEnci'" [outlined]="true" severity="success"
                      [disabled]="elaboration || rowData.statoAssistentatoLivello > statoAssistentatoLivello.AccettatoEnci"
                      (onClick)="accept([rowData.id], 'ENCI')" [tooltip]="T | translateItem: 'generic.accept'"
                      icon="fa-solid fa-thumbs-up" />

                    <app-button *grantVisible="grants; key 'rejectEnci'" [outlined]="true" severity="danger"
                      [disabled]="elaboration || rowData.statoAssistentatoLivello > statoAssistentatoLivello.AccettatoEnci"
                      (onClick)="reject([rowData.id],'ENCI')" [tooltip]="T | translateItem: 'generic.reject'"
                      icon="fa-solid fa-thumbs-down" />
                  </ng-container>

                  <!-- Approvazione giudice -->
                  <ng-container
                    *ngIf="(rowData.statoAssistentatoLivello >= statoAssistentatoLivello.AccettatoEnci && utente?.isAdmin) || rowData.giudiceId === giudiceId">
                    <app-button *grantVisible="grants; key 'acceptGiud'" [outlined]="true" severity="success"
                      [disabled]="elaboration || rowData.statoAssistentatoLivello != statoAssistentatoLivello.AccettatoEnci"
                      (onClick)="accept([rowData.id], 'GIUD')" [tooltip]="T | translateItem: 'generic.accept'"
                      icon="fa-solid fa-thumbs-up" />

                    <app-button *grantVisible="grants; key 'rejectGiud'" [outlined]="true" severity="danger"
                      [disabled]="elaboration || rowData.statoAssistentatoLivello != statoAssistentatoLivello.AccettatoEnci"
                      (onClick)="reject([rowData.id],'GIUD')" [tooltip]="T | translateItem: 'generic.reject'"
                      icon="fa-solid fa-thumbs-down" />
                  </ng-container>

                  <ng-container *ngIf="rowData.giudiceRichiedenteId === giudiceId || utente.isAdmin">
                    <app-button *grantVisible="grants; key 'repeat'" [outlined]="true"
                      [disabled]="elaboration || !rowData.flPossibileRipetizione" (onClick)="addRepetition(rowData)"
                      [tooltip]="T | translateItem: 'generic.repeat'" icon="fa-solid fa-repeat" />
                  </ng-container>

                  <!-- Esito -->
                  <ng-container *ngIf="rowData.giudiceId === giudiceId || utente.isAdmin">
                    <app-button *grantVisible="grants; key 'rilascioEsito'" [outlined]="true"
                      [disabled]="elaboration || rowData.statoAssistentatoLivello != statoAssistentatoLivello.AccettatoGiudice || (rowData.giudiceId !== giudiceId && !utente?.isAdmin) || (rowData.esitoAssistentatoId && !utente?.isAdmin)"
                      (onClick)="editEntity(rowData, true)"
                      [tooltip]="T | translateItem: 'giudassistentato.rilascioEsito'" icon="fa-solid fa-award" />
                  </ng-container>

                  <app-button *grantVisible="grants; key 'delete'" [outlined]="true" severity="danger"
                    [disabled]="elaboration || entitiesSelected?.length > 1 || rowData.statoAssistentatoLivello >= statoAssistentatoLivello.AccettatoEnci"
                    (onClick)="deleteEntities([rowData.id])" [tooltip]="T | translateItem: 'generic.delete'"
                    icon="fa-solid fa-trash" />
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="100%" class="text-center danger">
                {{T | translateItem: 'generic.noRecordsFound'}}
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="loadingbody">
            <tr style="height: 2.5rem;">
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <!-- Modale add exp -->
  <app-giud-assistentato-exp-add *ngIf="modalAdd?.visible && modalAdd?.tipoCalCod === 'EXP'"
    [visible]="modalAdd?.visible" [entity]="modalAdd.entity" [richiestaId]="modalAdd.richiestaId"
    [giudiceRichiedenteId]="modalAdd.giudiceRichiedenteId" (onHide)="modalAddHide($event)" />

  <!-- Modale add prv -->
  <app-giud-assistentato-prv-add *ngIf="modalAdd?.visible && modalAdd?.tipoCalCod === 'PRV'"
    [visible]="modalAdd?.visible" [entity]="modalAdd.entity" [richiestaId]="modalAdd.richiestaId"
    [giudiceRichiedenteId]="modalAdd.giudiceRichiedenteId" (onHide)="modalAddHide($event)" />

  <!-- Modale edit -->
  <app-giud-assistentato-edit *ngIf="modalEdit?.visible" [visible]="modalEdit?.visible" [entity]="modalEdit.entity"
    [richiestaId]="modalEdit.richiestaId" [giudiceRichiedenteId]="modalEdit.giudiceRichiedenteId"
    [rilascioEsito]="modalEdit.rilascioEsito" (onHide)="modalEditHide($event)" />
</ng-container>