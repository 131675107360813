import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from 'src/app/models/data-search';
import { GenNotificheService } from 'src/app/services/gennotifiche.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { GenNotificaTipo, GenNotificaTipoLang } from '../../../../models/anagrafiche/gen-notifica-tipo';
import { TreeNode } from '../../../../models/gestione/treenode';
import { ToastMessageData } from '../../../../models/message';
import { AppManagerService } from '../../../../services/app-manager.service';
import { DataService } from '../../../../services/data.service';

@Component({
  selector: 'gennotifica-wizard',
  templateUrl: './gennotifica-wizard.component.html',
  styleUrls: ['./gennotifica-wizard.component.scss']
})

export class GenNotificaWizardComponent implements OnInit, OnDestroy {
  T$: Observable<Resources>;
  resourceList: Resources = [];
  dataForm: FormGroup;

  // Notifica
  genNotificaTipoList: GenNotificaTipo[];
  templatesList: GenNotificaTipoLang[];
  notificaEmail: boolean;
  notificaPiatt: boolean;
  notificaCod: string;
  notificaLabel: string;
  rowId: number;
  tabella: string;
  notificationSended: boolean = false;

  // Tree
  tree: TreeNode[];
  selectedNode: any; //TreeNode[];
  selectedUsers: Set<number> = new Set();
  selectedUsersLabel: Set<string> = new Set();

  // Steps
  items: MenuItem[];
  activeIndex: number = 0;
  step0Valid: boolean = false;
  step1Valid: boolean = false;

  loading: boolean;

  notificaSelezionabile: boolean = false;
  notificaGenerica: boolean = false;
  comiIdEnciPresel: number = null;

  step0: FormGroup;
  step1: FormArray;

  @Input() autostart = false;

  @Output() wizardState = new EventEmitter<{ activeIndex: number, canGoNext: boolean, notificationSended: boolean, loading: boolean }>();

  private subscription: Subscription = new Subscription();

  constructor(
    private dataService: DataService,
    private appManagerService: AppManagerService,
    private translateService: TranslateService,
    private genNotificheService: GenNotificheService
  ) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    // Etichette
    this.T$ = this.translateService.translatedItems$(AppManagerRoutes.GenNotificaNew.translateSuffixs)
      .pipe(
        tap((translateItems) => {
          this.resourceList = translateItems;

          this.items = [
            { label: translateItems['gennotificawizard.selezionanotifica'] },
            { label: translateItems['gennotificawizard.modificatesti'] },
            { label: translateItems['gennotificawizard.selezionautenti'] },
            { label: translateItems['gennotificawizard.conferma'] }
          ];
        })
      );

    // Setto il form
    this.dataForm = new FormGroup({});
    this.step0 = new FormGroup({});
    this.step0.addControl("codNotifica", new FormControl({ value: this.notificaCod, disabled: !this.notificaSelezionabile }, [Validators.required]));
    // this.step0.addControl("notificaEmail", new FormControl({ value: null, disabled: !this.notificaCod }, [conditionalValidator(() => { return !this.step0.value?.notificaPiatt }, Validators.required)]));
    // this.step0.addControl("notificaPiatt", new FormControl({ value: null, disabled: !this.notificaCod }, [conditionalValidator(() => { return !this.step0.value?.notificaEmail }, Validators.required)]));
    this.step0.addControl("notificaEmail", new FormControl({ value: null, disabled: !this.notificaCod }));
    this.step0.addControl("notificaPiatt", new FormControl({ value: null, disabled: !this.notificaCod }));

    this.dataForm.addControl('step0', this.step0);
    this.step1 = new FormArray([]);
    this.dataForm.addControl('step1', this.step1);
    // const step2 = new FormGroup({});
    // step2.addControl("selectedUsers", new FormControl(null, [Validators.required]));
    // this.dataForm.addControl('step2', step2);

    this.reset();

    // FormEvents
    this.subscription.add(
      this.step0.get('codNotifica').valueChanges.subscribe(val => {
        this.setNotificationCod(val);
      })
    );
    this.subscription.add(
      this.step0.get('notificaEmail').valueChanges.subscribe(val => {
        this.notificaEmail = !!val;
      })
    );
    this.subscription.add(
      this.step0.get('notificaPiatt').valueChanges.subscribe(val => {
        this.notificaPiatt = !!val;
      })
    );
    this.subscription.add(
      this.step0.statusChanges.subscribe((status) => {
        this.step0Valid = status === 'VALID';
        this.emitWizardState();
    }));
    this.subscription.add(
      this.step1.statusChanges.subscribe((status) => {
        this.step1Valid = status === 'VALID';
        this.emitWizardState();
      }));

    this.subscription.add(this.genNotificheService.modalNotifica$.subscribe(
      (result: any) => {
        if (result.open) {
          this.rowId = result.rowId;
          this.tabella = result.tabella;
          this.notificaCod = result.codNotifica;
          this.notificaSelezionabile = result.notificaSelezionabile;
          this.notificaGenerica = result.notificaGenerica;
          this.comiIdEnciPresel = result.comiIdEnciPresel;

          const ds = new DataSearch();
          ds.dataSortValues = [new DataSortValue('des', SortMode.Asc)];
          ds.dataSearchValues = this.notificaGenerica ? [new DataSearchValue([true], ['generica'], ComparisonOperator.Equals)] : [new DataSearchValue([this.tabella], ['tabella'], ComparisonOperator.Equals)];

          this.subscription.add(
            this.dataService.searchElements(`${this.dataService.configSettings.restCommonUrl}/GetNotificaTipo`, ds).subscribe(
              (data: any) => {
                this.genNotificaTipoList = data.entities;
                if (!this.notificaSelezionabile) {
                  this.step0.patchValue({ codNotifica: this.notificaCod });
                }
                else {
                  this.step0.get('codNotifica').enable();
                }
              }
            )
          );

        } else {
          this.reset();
        }
      })
    );

    if (this.autostart) {
      this.genNotificheService.openModalNotifica({
        open: true,
        rowId: null,
        tabella: null,
        codNotifica: null,
        notificaSelezionabile: true,
        notificaGenerica: true,
        comiIdEnciPresel: null
      });
    }
  }

  private setNotificationCod(val: string): void {
    if (val) {
      const notificaTipo = this.genNotificaTipoList.find((n) => { return n.cod.toUpperCase() === val.toUpperCase() })
      this.step0.get('notificaEmail').enable();
      this.step0.get('notificaPiatt').enable();
      this.notificaEmail = notificaTipo?.tipoInvioCod?.toUpperCase() === 'EMAIL' || notificaTipo?.tipoInvioCod?.toUpperCase() === 'ALL';
      this.notificaPiatt = notificaTipo?.tipoInvioCod?.toUpperCase() === 'PIAT' || notificaTipo?.tipoInvioCod?.toUpperCase() === 'ALL';

      this.notificaCod = val;
      this.notificaLabel = notificaTipo.des;

      this.step0.patchValue(
        { notificaEmail: this.notificaEmail || null, notificaPiatt: this.notificaPiatt || null }
      );

      // Cancella i tempplates allo step 1
      this.step1.clear();

      //const step1Form = this.step1 as FormArray;
      // while (step1Form.length > 0) {
      //   step1Form.removeAt(0);
      // }
    }
    else {
      this.step0.get('notificaEmail').disable();
      this.step0.get('notificaPiatt').disable();
      this.step0.patchValue({ notificaEmail: null, notificaPiatt: null });
    }
  }

  public stepZeroToOne(): void {
    let paramsTree = {
      codNotifica: this.notificaCod,
      notificaGenerica: this.notificaGenerica,
      tabella: null,
      comiIdEnciPresel: this.comiIdEnciPresel
    };

    if (!this.notificaGenerica) {
      paramsTree.tabella = this.tabella
    };

    this.loading = true;
    this.emitWizardState();

    this.subscription.add(
      forkJoin([
        this.dataService.getGeneric(`${this.dataService.configSettings.restCommonUrl}/GetTemplateNotificaByCod`, `cod=${this.notificaCod}`),
        this.dataService.insertElement(`${this.dataService.configSettings.restCommonUrl}/GetTreeUtenti`, paramsTree)
      ]).subscribe(
        ([templates, tree]) => {
          this.loading = false;
          this.emitWizardState();

          this.templatesList = templates.entities;

          this.templatesList.forEach((template: GenNotificaTipoLang) => {
            const templateForm = new FormGroup({
              notificaTipoId: new FormControl(null, [Validators.required]),
              lang: new FormControl(null, [Validators.required]),
              langDes: new FormControl(null),
              emailSubject: new FormControl(null, [Validators.required]),
              emailBody: new FormControl(null, [Validators.required]),
              piattMsg: new FormControl(null, [Validators.required])
            });
            templateForm.patchValue(template, { emitEvent: false });
            this.step1.push(templateForm);
          });

          // Costruisco l'albero
          this.tree = tree;
          this.selectedNode = [];

          // Preseleziono i nodi 
          this.tree.forEach((profilo) => {
            if (profilo.selected) {
              this.selectedNode.push(profilo);
            }
            profilo.children.forEach(node => {
              if (node.selected) {
                profilo.partialSelected = true;
                node.parent = profilo;
                this.selectedNode.push(node);
              }
            });
          });
          this.getSelectedUsers();
        }, (error: HttpErrorResponse) => {
          this.loading = false;
          this.emitWizardState();
        }
      )
    );
  }

  // getSelectedNodes(tree){
  //   var selectedNodes:any = [];
  //   tree.filter((t)=>{return t.selected}).map((node)=>{
  //     if(node.selected)
  //     {
  //       th
  //     }
  //     node.childs.map()

  //   })

  // }

  public reset(): void {
    this.activeIndex = 0;
    this.notificaEmail = true;
    this.notificaPiatt = true;
    this.loading = false;
    this.emitWizardState();
  }

  public procedi(): void {
    switch (this.activeIndex) {
      case 0:
        this.stepZeroToOne();
        break;
    }
    this.activeIndex++;
    this.emitWizardState();
  }

  indietro(): void {
    this.activeIndex = Math.max(this.activeIndex - 1, 0);
    this.emitWizardState();
  }

  public invia(): void {
    this.loading = true;
    this.emitWizardState();

    const data = {
      NotificaEmail: this.notificaEmail,
      NotificaPiattaforma: this.notificaPiatt,
      SelectedUsers: Array.from(this.selectedUsers),
      TemplateList: this.step1.value.map(t => {
        return {
          notificaTipoId: t.notificaTipoId,
          lang: t.lang,
          emailSubject: t.emailSubject,
          emailBody: t.emailBody,
          piattMsg: t.piattMsg
        }
      }),
      Data: new Date()
    };

    this.subscription.add(
      this.dataService.insertElement(`${this.dataService.configSettings.restCommonUrl}/InsertNotifica`, data).subscribe(
        (ret: any) => {
          this.loading = false;
          this.emitWizardState();

          this.appManagerService.showToastMessage(new ToastMessageData('success', this.translateService.translate(this.resourceList, 'gennotificawizard.successmessage')));
          this.notificationSended = true;
          this.emitWizardState();
        }, (error: HttpErrorResponse) => {
          this.loading = false;
          this.emitWizardState();
        }
      )
    );
  }

  getSelectedUsers(): void {
    this.selectedUsers = new Set();
    this.selectedUsersLabel = new Set();
    this.selectedNode.forEach(node => {
      if (node.children == null) {
        // Prendo solo le foglie
        this.selectedUsers.add(node.data);
        this.selectedUsersLabel.add(node.label);
      }
    });
  }

  onNodeSelect(event: any): void {
    this.getSelectedUsers();
    this.emitWizardState();
  }

  onNodeUnselect(event: any): void {
    this.getSelectedUsers();
    this.emitWizardState();
  }

  emitWizardState(): void {
    const step0Values = this.step0.value;
    let canGoNext;
    switch (this.activeIndex) {
      case 0:
        //  canGoNext = this.step0Valid && (step0Values.notificaEmail || step0Values.notificaPiatt);
        canGoNext = this.step0Valid;
        break;
      case 1:
        canGoNext = this.step1Valid;
        break;
      case 2:
        canGoNext = this.selectedUsersLabel.size > 0;
        break;
      default:
        canGoNext = false;
        break;
    };

    this.wizardState.emit({
      activeIndex: this.activeIndex,
      canGoNext: canGoNext,
      notificationSended: this.notificationSended,
      loading: this.loading
    });
  }

}
