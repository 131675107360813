import { AnagraficheEMC } from './models/anagrafiche/anagrafiche';
import { AnaCalEMC } from './models/anagrafiche/calendario/anagrafiche-calendario';
import { AnaCalStatiEMC } from './models/anagrafiche/calendario/anagrafiche-calendario-stati';
import { AnaCalTipiEMC } from './models/anagrafiche/calendario/anagrafiche-calendario-tipi';
import { CalDefExpNumEMC } from './models/anagrafiche/calendario/cal-def-exp-num';
import { CalDefRadMosNumEMC } from './models/anagrafiche/calendario/cal-def-rad-mos-num';
import { CalDefSociFamiRazzeEMC } from './models/anagrafiche/calendario/cal-def-soci-fami-razze';
import { CalDefVincoloEMC, CalDefVincoloLangEMC, CalDefVincoloModalEMC } from './models/anagrafiche/calendario/cal-def-vincolo';
import { CalFamiRazzeEMC, CalFamiRazzeLangEMC, CalFamiRazzeModalEMC } from './models/anagrafiche/calendario/cal-fami-razze';
import { CalMotivoBloccoDataEMC, CalMotivoBloccoDataLangModalEMC } from './models/anagrafiche/calendario/cal-motivo-blocco-data';
import { CalParereManiEMC, CalParereManiLangEMC } from './models/anagrafiche/calendario/cal-parere-mani';
import { CalStatoCalEMC, CalStatoCalLangModalEMC } from './models/anagrafiche/calendario/cal-stato-cal';
import { CalStatoEventoEMC, CalStatoEventoLangModalEMC } from './models/anagrafiche/calendario/cal-stato-evento';
import { CalStatoManiEMC, CalStatoManiLangModalEMC } from './models/anagrafiche/calendario/cal-stato-mani';
import { CalTipoCalEMC, CalTipoCalLangModalEMC, CalTipoCalModalEMC, TipoCal, TipoCalCod } from './models/anagrafiche/calendario/cal-tipo-cal';
import { CalTipoManiEMC, CalTipoManiLangModalEMC } from './models/anagrafiche/calendario/cal-tipo-mani';
import { CalTipoPeriodoCalEMC, CalTipoPeriodoCalLangEMC } from './models/anagrafiche/calendario/cal-tipo-periodo-cal';
import { GenAnomaliaStatoEMC, GenAnomaliaStatoLangModalEMC, GenAnomaliaStatoModalEMC } from './models/anagrafiche/gen-anomalia-stato';
import { GenNotificaTipoEMC, GenNotificaTipoLangModalEMC } from './models/anagrafiche/gen-notifica-tipo';
import { GenNotificaTipoInvioEMC, GenNotificaTipoInvioLangModalEMC } from './models/anagrafiche/gen-notifica-tipo-invio';
import { GenResourceEMC } from './models/anagrafiche/gen-resource';
import { CalendarioEMC } from './models/calendario/calendario';
import { EntityManagerInfo } from './models/entity-config';
import { GenNotificaDetailEMC, GenNotificaEMC } from './models/gestione/gen-notifica';
import { GenHelpLangEMC } from './models/gestione/help';
import { EMPTYEMC, HomeEMC } from './models/home';
import { SecProfiloEMC, SecProfiloModalEMC } from './models/security/sec-profilo';
import { SecRuoloByProfiloEMC, SecRuoloEMC, SecRuoloModalEMC } from './models/security/sec-ruolo';
import { SecRuoloTagEMC, SecTagRuoloEMC } from './models/security/sec-ruolo-tag';
import { SecTagEMC, SecTagModalEMC } from './models/security/sec-tag';
import { SecUtenteEMC } from './models/security/sec-utente';
import { SecEMC, } from './models/security/security';
import { VuxRazzeVarietaEMC } from './models/vux/vux-razze-varieta';
import { VuxSociDeleGruppiEMC, VuxSociDeleGruppiModalEMC } from './models/vux/vux-soci-dele-gruppi';
import { cloneObject, isNullOrUndefined } from './utils/util';
//import { CalCaleDateBloccateEMC } from './models/calendario/cal-cale-date-bloccate';
import { AnaClubEMC } from 'src/app/models/anagrafiche/ana-club';
import { AnaKennelClubEMC } from 'src/app/models/anagrafiche/ana-kennel-club';
import { AnaRazzeEMC } from './models/anagrafiche/anagrafiche-razze';
import { AnaRelazioneEMC } from './models/anagrafiche/anagrafiche-relazione';
import { CalCircuitoEMC, CalCircuitoLangModalEMC } from './models/anagrafiche/calendario/cal-circuito';
import { CalDefPrvSpecNumEMC } from './models/anagrafiche/calendario/cal-def-prv-spec-num';
import { CalDefVincoloProfiloEMC } from './models/anagrafiche/calendario/cal-def-vincolo-profilo';
import { CalParereTipoEMC, CalParereTipoLangEMC } from './models/anagrafiche/calendario/cal-parere-tipo';
import { GenAllegatoTipoEMC, GenAllegatoTipoListLangModalEMC } from './models/anagrafiche/gen-allegato-tipo';
import { GenAnomaliaEMC, GenAnomaliaLangModalEMC } from './models/anagrafiche/gen-anomalia';
import { GenAnomaliaCalDefVincoliEMC } from './models/anagrafiche/gen-anomalia-def-cale-vincoli';
import { GenAnomaliaProfiloEMC } from './models/anagrafiche/gen-anomalia-profilo';
import { GenCartellinoEMC, GenCartellinoLangModalEMC } from './models/anagrafiche/gen-cartellino';
import { GenClasseEMC, GenClasseLangModalEMC } from './models/anagrafiche/gen-classe';
import { GenGruppoFciEMC, GenGruppoFciLangModalEMC } from './models/anagrafiche/gen-gruppo-fci';
import { GenNotaTipoEMC, GenNotaTipoListLangModalEMC } from './models/anagrafiche/gen-nota-tipo';
import { CalendarioUtilsEMC } from './models/calendario/calendario-utils';
import { FattureEMC } from './models/fatt/fatture';
import { GenDomandaEMC } from './models/gestione/emc/gen-domanda-emc';
import { GenDomandaLangEMC } from './models/gestione/emc/gen-domanda-lang-emc';
import { GenDomandaTipoEMC } from './models/gestione/emc/gen-domanda-tipo-emc';
import { GenDomandaTipoLangEMC } from './models/gestione/emc/gen-domanda-tipo-lang-emc';
import { GenLinguaEMC } from './models/gestione/emc/gen-lingua-emc';
import { GenLinguaLangEMC } from './models/gestione/emc/gen-lingua-lang-emc';
import { GenRelazioneDomandaEMC } from './models/gestione/emc/gen-relazione-domanda-emc';
import { GenRelazioneEMC } from './models/gestione/emc/gen-relazione-emc';
import { GenRelazioneLangEMC } from './models/gestione/emc/gen-relazione-lang-emc';
import { GestioneEMC } from './models/gestione/gestione';
import { GiudiceProfiloEMC } from './models/giudici/giudice-profilo';
import { GiudiciEMC } from './models/giudici/giudici';
import { ManualiEMC } from './models/manuali/manuali';
import { NormalizzazioneEMC } from './models/normalizzazione/normalizzazione';
import { PofiloUtenteEMC } from './models/profilo-utente/profilo-utente';
import { SecUtenteProfiliEMC } from './models/security/sec-utente-profili';
import { SecUtenteProfiliAnagEMC } from './models/security/sec-utente-profili-anag';
import { SecUtenteProfiliRuoliEMC } from './models/security/sec-utente-profili-ruoli';
import { ZetaEMC, ZetaWindowTabEMC } from './models/zeta/zeta';

export class AppManagerRoutes {

  private static _entityManagerInfoList: EntityManagerInfo[];
    static CalCaleManiRisultatiList: any;
  static get entityManagerInfoList(): EntityManagerInfo[] {
    if (!this._entityManagerInfoList) {
      const keys = Object.keys(AppManagerRoutes);
      this._entityManagerInfoList = [];
      keys.forEach(key => {
        if ('_entityManagerInfoList' != key && 'entityManagerInfoList' != key) {
          this._entityManagerInfoList.push(AppManagerRoutes[key]);
        }
      });
    }
    return this._entityManagerInfoList;
  }

  static getSezioneList(): any[] {
    const result: any[] = [];

    const keys = Object.keys(AppManagerRoutes);
    this._entityManagerInfoList = [];
    keys.forEach(key => {
      if ('_entityManagerInfoList' != key && 'entityManagerInfoList' != key) {

        result.push({
          cod: AppManagerRoutes[key].id,
          des: AppManagerRoutes[key].id
        });

        this._entityManagerInfoList.push(AppManagerRoutes[key]);
      }
    });

    return result;
  }

  // static getEntityManagerInfoListByParent(entityManagerInfo: EntityManagerInfo): EntityManagerInfo
  // {
  //   let result: EntityManagerInfo = undefined;
  //   result = this.entityManagerInfoList.find(t => t.parent.id === entityManagerInfo.id);
  //   return result;
  // }

  static getEntityManagerInfoListByUrl(url: string | string[]): EntityManagerInfo[] {
    const result: EntityManagerInfo[] = [];
    let pathList;
    if (url instanceof Array) {
      pathList = url;
    }
    else {
      pathList = isNullOrUndefined(url) ? [] : url.split('/');
      // let urlTemp = url;
      // if(urlTemp.length > 0 && urlTemp.startsWith('/')){
      //   urlTemp = urlTemp.substring(1);
      // }
      // pathList = urlTemp.split('/');
    }
    pathList.forEach(path => {
      const entityManagerInfo = this.entityManagerInfoList.find(t => path == t.url || path.startsWith(`${t.url}?`));
      if (entityManagerInfo) {
        result.push(entityManagerInfo);
      }
    });
    return result;
  }

  static Home: EntityManagerInfo = {
    id: 'Home', name: 'home', translateSuffixs: ['generic.', 'menu.'],
    entityType: HomeEMC,
    url: '',
  };

  static Autologin: EntityManagerInfo = {
    id: 'Autologin', name: 'autologin', translateSuffixs: ['generic.'],
    url: 'autologin'
  };

  // Security
  static Security: EntityManagerInfo = {
    id: 'Security', name: 'security', translateSuffixs: ['generic.', 'menu.'],
    entityType: SecEMC,
    url: 'security', parent: AppManagerRoutes.Home
  };

  static SecUtenteList: EntityManagerInfo = {
    id: 'SecUtenteList', name: 'secutente', translateSuffixs: ['generic.', 'secutente.'],
    entityType: SecUtenteEMC,
    entityServices: [{ name: 'secutente', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' }, { name: 'secprofilo', serviceName: 'restCommon' }],
    url: 'secutentelist', parent: AppManagerRoutes.Security
  };
  static SecUtenteDetail: EntityManagerInfo = cloneObject(
    AppManagerRoutes.SecUtenteList,
    { id: 'SecUtenteDetail', url: 'secutentedetail', parent: AppManagerRoutes.SecUtenteList }
  );

  static SecProfiloList: EntityManagerInfo = {
    id: 'SecProfiloList', name: 'secprofilo', translateSuffixs: ['generic.', 'secprofilo.'],
    entityType: SecProfiloEMC,
    entityServices: [{ name: 'secprofilo', serviceName: 'restCommon' }],
    url: 'secprofilolist', parent: AppManagerRoutes.Security,
  };
  static SecProfiloModal: EntityManagerInfo = {
    id: 'SecProfiloModal', name: 'secprofilo', translateSuffixs: ['generic.', 'secprofilo.'],
    entityType: SecProfiloModalEMC,
    entityServices: [{ name: 'secprofilo', serviceName: 'restCommon' }]
  };

  static SecRuoloList: EntityManagerInfo = {
    id: 'SecRuoloList', name: 'secruolo', translateSuffixs: ['generic.', 'secruolo.'],
    entityType: SecRuoloEMC,
    entityServices: [{ name: 'secruolo', serviceName: 'restCommon' }, { name: 'secruolo', serviceName: 'restCommon' }, { name: 'secprofilo', serviceName: 'restCommon' }],
    url: 'secruololist', parent: AppManagerRoutes.Security
  };

  static SecRuoloListByProfilo: EntityManagerInfo = {
    id: 'SecRuoloListByProfilo', name: 'secruolo', translateSuffixs: ['generic.', 'secruolo.'],
    entityType: SecRuoloByProfiloEMC,
    entityServices: [{ name: 'secruolo', serviceName: 'restCommon' }],
    url: 'secruololist', parent: AppManagerRoutes.SecProfiloList
  };
  static SecRuoloDetailByProfilo: EntityManagerInfo = cloneObject(
    AppManagerRoutes.SecRuoloListByProfilo,
    { id: 'SecRuoloDetailByProfilo', url: 'secruolodetail', parent: AppManagerRoutes.SecRuoloListByProfilo }
  );
  static SecRuoloModal: EntityManagerInfo = {
    id: 'SecRuoloModal', name: 'secruolo', translateSuffixs: ['generic.', 'secruolo.'],
    entityType: SecRuoloModalEMC,
    entityServices: [{ name: 'secruolo', serviceName: 'restCommon' }, { name: 'secprofilo', serviceName: 'restCommon' }]
  };

  static SecTagList: EntityManagerInfo = {
    id: 'SecTagList', name: 'sectag', translateSuffixs: ['generic.', 'sectag.'],
    entityType: SecTagEMC,
    entityServices: [{ name: 'sectag', serviceName: 'restCommon' }],
    url: 'sectaglist', parent: AppManagerRoutes.Security
  };
  static SecTagModal: EntityManagerInfo = {
    id: 'SecTagModal', name: 'sectag', translateSuffixs: ['generic.', 'sectag.'],
    entityType: SecTagModalEMC,
    entityServices: [{ name: 'sectag', serviceName: 'restCommon' }],
  };
  static SecRuoloTagList: EntityManagerInfo = {
    id: 'SecRuoloTagList', name: 'secruolotag', translateSuffixs: ['generic.', 'secruolotag.'],
    entityType: SecRuoloTagEMC,
    entityServices: [
      { name: 'secruolotag', serviceName: 'restCommon' },
      { name: 'sectag', serviceName: 'restCommon' },
      { name: 'secprofilo', serviceName: 'restCommon' }
    ],
    url: 'secruolotaglist', parent: AppManagerRoutes.SecRuoloListByProfilo
  };

  static SecTagRuoloList: EntityManagerInfo = {
    id: 'SecTagRuoloList', name: 'sectagruolo', translateSuffixs: ['generic.', 'sectagruolo.'],
    entityType: SecTagRuoloEMC,
    entityServices: [{ name: 'sectagruolo', serviceName: 'restCommon' },
    { name: 'sectag', serviceName: 'restCommon' }],
    url: 'sectagruololist', parent: AppManagerRoutes.SecProfiloList
  };

  // Profilo anagrafiche club
  static AnaClub: EntityManagerInfo = {
    id: 'AnaClub', name: 'anaclub', translateSuffixs: ['generic.', 'menu.'],
    entityType: AnaClubEMC,
    url: 'anaclub', parent: AppManagerRoutes.Home
  };

  static SecUtenteProfiliList: EntityManagerInfo = {
    id: 'SecUtenteProfiliList', name: 'secutenteprofili', translateSuffixs: ['generic.', 'secutenteprofili.'],
    entityType: SecUtenteProfiliEMC,
    entityServices: [{ name: 'secutenteprofili', serviceName: 'restCommon' }],
    url: 'secutenteprofililist', parent: AppManagerRoutes.SecUtenteList
  };

  static SecUtenteRuoliPiattaformeList: EntityManagerInfo = {
    id: 'SecUtenteRuoliPiattaformeList', name: 'secutenteruolipiattaformelist', translateSuffixs: ['generic.', 'secutenteruolipiattaformelist.'],
    entityType: EMPTYEMC,
    url: 'secutenteruolipiattaformelist', parent: AppManagerRoutes.SecUtenteList
  };

  static SecUtenteProfiliRuoliList: EntityManagerInfo = {
    id: 'SecUtenteProfiliRuoliList', name: 'secutenteprofiliruoli', translateSuffixs: ['generic.', 'secutenteprofiliruoli.'],
    entityType: SecUtenteProfiliRuoliEMC,
    entityServices: [{ name: 'secutenteprofiliruoli', serviceName: 'restCommon' }],
    url: 'secutenteprofiliruolilist', parent: AppManagerRoutes.SecUtenteProfiliList
  };

  static SecUtenteProfiliAnagList: EntityManagerInfo = {
    id: 'SecUtenteProfiliAnagList', name: 'secutenteprofilianag', translateSuffixs: ['generic.', 'secutenteprofilianag.'],
    entityType: SecUtenteProfiliAnagEMC,
    entityServices: [{ name: 'secutenteprofilianag', serviceName: 'restCommon' }],
    url: 'secutenteprofilianaglist', parent: AppManagerRoutes.SecUtenteProfiliList
  };

  // static SecUtenteProfiliModal: EntityManagerInfo = {
  //   id: 'SecUtenteProfiliModal', name: 'secutenteprofili', translateSuffixs: ['generic.', 'secutenteprofili.'],
  //   entityType: SecUtenteProfiliModalEMC,
  //   entityServices: [{ name: 'secutenteprofili', serviceName: 'restCommon' }]
  // };


  // Gestione BrowserWindow
  static BrowserWindow: EntityManagerInfo = {
    id: 'BrowserWindow', name: 'browserwindow', translateSuffixs: ['generic.', 'menu.'],
    url: 'browserwindow', parent: AppManagerRoutes.Home
  };

  // Gestione
  static Gestione: EntityManagerInfo = {
    id: 'Gestione', name: 'gestione', translateSuffixs: ['generic.', 'menu.'],
    entityType: GestioneEMC,
    url: 'gestione', parent: AppManagerRoutes.Home
  };

  static GenNotificaList: EntityManagerInfo = {
    id: 'GenNotificaList', name: 'gennotifica', translateSuffixs: ['generic.', 'gennotifica.'],
    entityType: GenNotificaEMC,
    entityServices: [
      { name: 'gennotifica', serviceName: 'restCommon' }, { name: 'gennotificatipo', serviceName: 'restCommon' }, , { name: 'gennotificatipoinvio', serviceName: 'restCommon' }
    ],
    url: 'gennotificalist', parent: AppManagerRoutes.Home, queryParams: { notificaTipoInvioId: '1' }
  };

  static GenNotificaDetail: EntityManagerInfo = {
    id: 'GenNotificaDetail', name: 'gennotifica', translateSuffixs: ['generic.', 'gennotifica.'],
    entityType: GenNotificaDetailEMC,
    entityServices: [{ name: 'gennotifica', serviceName: 'restCommon' }],
    url: 'gennotificadetail', parent: AppManagerRoutes.GenNotificaList
  };

  static GenNotificaReply: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GenNotificaList,
    { id: 'GenNotificaReply', url: 'gennotificareply', parent: AppManagerRoutes.GenNotificaList }
  );

  static GenNotificaDetailReply: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GenNotificaList,
    { id: 'GenNotificadetailReply', url: 'gennotificadetailreply', parent: AppManagerRoutes.GenNotificaDetail }
  );

  // static GenNotificaDetail: EntityManagerInfo = cloneObject(
  //   AppManagerRoutes.GenNotificaList,
  //   { id: 'GenNotificaDetail', url: 'gennotificadetail', parent: AppManagerRoutes.GenNotificaList }
  // );

  static GenNotificaNew: EntityManagerInfo = {
    id: 'GenNotificaNew', name: 'gennotificanew', translateSuffixs: ['generic.', 'gennotificawizard.'],
    entityType: EMPTYEMC,
    url: 'gennotificanew', parent: AppManagerRoutes.GenNotificaList
  };


  // static GenHelp: EntityManagerInfo = {
  //   id: 'GenHelp', name: 'genhelp, translateSuffixs: ['generic.', 'genhelp.'],
  //   entityType: GenHelpEMC,
  //   entityServices: [{ name: 'genhelp', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' }],
  //   urlRoot: 'gestione', url: 'genhelp'
  // };

  static GenHelpLang: EntityManagerInfo = {
    id: 'GenHelpLang', name: 'genhelplang', translateSuffixs: ['generic.', 'genhelplang.'],
    entityType: GenHelpLangEMC,
    entityServices: [{ name: 'genhelplang', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' }],
    url: 'genhelplang', parent: AppManagerRoutes.BrowserWindow
  };

  // static DevCaseTab: EntityManagerInfo = {
  //   id: 'DevCaseTab', name: 'devcase', translateSuffixs: ['generic.', 'devcase.'],
  //   entityType: DevCaseEMC,
  //   entityServices: [{ name: 'devcase', serviceName: 'restCommon' }],
  //   urlRoot: 'gestione', url: 'devcasetab'
  // };

  // Anagrafiche
  static Anagrafiche: EntityManagerInfo = {
    id: 'Anagrafiche', name: 'anagrafiche', translateSuffixs: ['generic.', 'menu.'],
    entityType: AnagraficheEMC,
    url: 'anagrafiche', parent: AppManagerRoutes.Home
  };

  static GenResourceList: EntityManagerInfo = {
    id: 'GenResourceList', name: 'genresource', translateSuffixs: ['generic.', 'genresource.'],
    entityType: GenResourceEMC,
    entityServices: [{ name: 'genresource', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' },],
    url: 'genresourcelist', parent: AppManagerRoutes.Anagrafiche
  };


  static GenLinguaList: EntityManagerInfo = {
    id: 'GenLinguaList',
    name: 'genlingua',
    translateSuffixs: ['generic.', 'genlingua.'],
    entityType: GenLinguaEMC,
    entityServices: [{ name: 'genlingua', serviceName: 'restCommon' }, { name: 'secprofilo', serviceName: 'restCommon' }],
    url: 'GenLingualist',
    parent: AppManagerRoutes.Anagrafiche
  };

  static GenLinguaLangList: EntityManagerInfo = {
    id: 'GenLinguaLangList', name: 'genlingualang', translateSuffixs: ['generic.', 'genlingualang.'],
    entityType: GenLinguaLangEMC,
    entityServices: [{ name: 'genlingualang', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static GenNotificaTipoList: EntityManagerInfo = {
    id: 'GenNotificaTipoList', name: 'gennotificatipo', translateSuffixs: ['generic.', 'gennotificatipo.'],
    entityType: GenNotificaTipoEMC,
    entityServices: [
      { name: 'gennotificatipo', serviceName: 'restCommon' },
      { name: 'gennotificatipoinvio', serviceName: 'restCommon' }
    ],
    url: 'gennotificatipolist', parent: AppManagerRoutes.Anagrafiche
  };

  static GenNotificaTipoNew: EntityManagerInfo = {
    id: 'GenNotificaTipoNew', name: 'gennotificatipo', translateSuffixs: ['generic.', 'gennotificatipo.'],
    entityType: EMPTYEMC,
    url: 'gennotificatiponew', parent: AppManagerRoutes.GenNotificaTipoList
  };

  static SecUtenteNew: EntityManagerInfo = {
    id: 'SecUtenteNew', name: 'secutente', translateSuffixs: ['generic.', 'secutentenew.'],
    entityType: EMPTYEMC,
    url: 'secutentenew', parent: AppManagerRoutes.SecUtenteList
  };

  static GenNotificaTipoEdit: EntityManagerInfo = {
    id: 'GenNotificaTipoEdit', name: 'gennotificatipo', translateSuffixs: ['generic.', 'gennotificatipo.'],
    entityType: EMPTYEMC,
    url: 'gennotificatipoedit', parent: AppManagerRoutes.GenNotificaTipoList
  };

  static GenNotificaTipoLangList: EntityManagerInfo = {
    id: 'GenNotificaTipoLangList', name: 'gennotificatipolang', translateSuffixs: ['generic.', 'gennotificatipolang.'],
    entityType: GenNotificaTipoLangModalEMC,
    entityServices: [{ name: 'gennotificatipolang', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' }],
    url: 'gennotificatipolanglist', parent: AppManagerRoutes.GenNotificaTipoList
  };
  static GenNotificaTipoLangNew: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GenNotificaTipoLangList,
    { id: 'GenNotificaTipoLangNew', url: 'gennotificatipolangnew', parent: AppManagerRoutes.GenNotificaTipoLangList }
  );
  static GenNotificaTipoLangEdit: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GenNotificaTipoLangList,
    { id: 'GenNotificaTipoLangEdit', url: 'gennotificatipolangedit', parent: AppManagerRoutes.GenNotificaTipoLangList }
  );

  static GenNotificaTipoInvioList: EntityManagerInfo = {
    id: 'GenNotificaTipoInvioList', name: 'gennotificatipoinvio', translateSuffixs: ['generic.', 'gennotificatipoinvio.'],
    entityType: GenNotificaTipoInvioEMC,
    entityServices: [
      { name: 'gennotificatipoinvio', serviceName: 'restCommon' },
    ],
    url: 'gennotificatipoinviolist', parent: AppManagerRoutes.Anagrafiche
  };
  static GenNotificaTipoInvioLangList: EntityManagerInfo = {
    id: 'GenNotificaTipoInvioLangList', name: 'gennotificatipoinviolang', translateSuffixs: ['generic.', 'gennotificatipoinviolang.'],
    entityType: GenNotificaTipoInvioLangModalEMC,
    entityServices: [{ name: 'gennotificatipoinviolang', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static GenAnomaliaList: EntityManagerInfo = {
    id: 'GenAnomaliaList', name: 'genanomalia', translateSuffixs: ['generic.', 'genanomalia.'],
    entityType: GenAnomaliaEMC,
    entityServices: [{ name: 'genanomalia', serviceName: 'restCommon' }],
    url: 'genanomalia', parent: AppManagerRoutes.Anagrafiche
  };
  static GenAnomaliaLangList: EntityManagerInfo = {
    id: 'GenAnomaliaLangList', name: 'genanomalialang', translateSuffixs: ['generic.', 'genanomalialang.'],
    entityType: GenAnomaliaLangModalEMC,
    entityServices: [{ name: 'genanomalialang', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static GenAnomaliaProfiloList: EntityManagerInfo = {
    id: 'GenAnomaliaProfiloList', name: 'genanomaliaprofilo', translateSuffixs: ['generic.', 'genanomaliaprofilo.'],
    entityType: GenAnomaliaProfiloEMC,
    entityServices: [
      { name: 'genanomaliaprofilo', serviceName: 'restCommon' },
      { name: 'secprofilo', serviceName: 'restCommon' }
    ],
    url: 'genanomaliaprofilo', parent: AppManagerRoutes.GenAnomaliaList
  };

  static GenAnomaliaStatoList: EntityManagerInfo = {
    id: 'GenAnomaliaStatoList', name: 'genanomaliastato', translateSuffixs: ['generic.', 'genanomaliastato.'],
    entityType: GenAnomaliaStatoEMC,
    entityServices: [
      { name: 'genanomaliastato', serviceName: 'restCommon' },
    ],
    url: 'genanomaliastato', parent: AppManagerRoutes.Anagrafiche
  };
  static GenAnomaliaStatoLangList: EntityManagerInfo = {
    id: 'GenAnomaliaStatoLangList', name: 'genanomaliastatolang', translateSuffixs: ['generic.', 'genanomaliastatolang.'],
    entityType: GenAnomaliaStatoLangModalEMC,
    entityServices: [{ name: 'genanomaliastatolang', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static GenAnomaliaStatoModal: EntityManagerInfo = {
    id: 'GenAnomaliaStatoModal', name: 'genanomaliastato', translateSuffixs: ['generic.', 'genanomaliastato.'],
    entityType: GenAnomaliaStatoModalEMC,
    entityServices: [
      { name: 'genanomaliastato', serviceName: 'restCommon' },
    ]
  };

  static GenAnomaliaCalDefVincoliList: EntityManagerInfo = {
    id: 'GenAnomaliaCalDefVincoliList', name: 'genanomaliacaldefvincoli', translateSuffixs: ['generic.', 'genanomaliacaldefvincoli.'],
    entityType: GenAnomaliaCalDefVincoliEMC,
    entityServices: [
      { name: 'genanomaliacaldefvincoli', serviceName: 'restCommon' },
    ],
    url: 'genanomaliacaldefvincoli', parent: AppManagerRoutes.GenAnomaliaList
  };

  static AnaKennelClubList: EntityManagerInfo = {
    id: 'AnaKennelClubList', name: 'anakennelclub', translateSuffixs: ['generic.', 'anakennelclub.'],
    entityType: AnaKennelClubEMC,
    entityServices: [{ name: 'anakennelclub', serviceName: 'restCommon' }, { name: 'GetGeoNazioneList', serviceName: 'restCommon' }],
    url: 'anakennelclublist', parent: AppManagerRoutes.AnaClub
  };

  static AnaKennelClubEdit: EntityManagerInfo = cloneObject(
    AppManagerRoutes.AnaKennelClubList,
    { id: 'AnaKennelClubEdit', url: 'anakennelclubedit', parent: AppManagerRoutes.AnaKennelClubList }
  );

  static AnaKennelClubNew: EntityManagerInfo = cloneObject(
    AppManagerRoutes.AnaKennelClubList,
    { id: 'AnaKennelClubNew', url: 'anakennelclubnew', parent: AppManagerRoutes.AnaKennelClubList }
  );

  static GenNotaTipoList: EntityManagerInfo = {
    id: 'GenNotaTipoList', name: 'gennotatipo', translateSuffixs: ['generic.', 'gennotatipo.'],
    entityType: GenNotaTipoEMC,
    entityServices: [{ name: 'gennotatipo', serviceName: 'restCommon' }, { name: 'genambito', serviceName: 'restCommon' }, { name: 'secprofilo', serviceName: 'restCommon' }],
    url: 'gennotatipolist', parent: AppManagerRoutes.Anagrafiche
  };

  static GenNotaTipoLangList: EntityManagerInfo = {
    id: 'GenNotaTipoLangList', name: 'gennotatipolang', translateSuffixs: ['generic.', 'gennotatipolang.'],
    entityType: GenNotaTipoListLangModalEMC,
    entityServices: [{ name: 'gennotatipolang', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static GenAllegatoTipoList: EntityManagerInfo = {
    id: 'GenAllegatoTipoList', name: 'genallegatotipo', translateSuffixs: ['generic.', 'genallegatotipo.'],
    entityType: GenAllegatoTipoEMC,
    entityServices: [{ name: 'genallegatotipo', serviceName: 'restCommon' }, { name: 'genambito', serviceName: 'restCommon' }, { name: 'secprofilo', serviceName: 'restCommon' }],
    url: 'genallegatotipolist', parent: AppManagerRoutes.Anagrafiche
  };
  static GenAllegatoTipoLangList: EntityManagerInfo = {
    id: 'GenAllegatoTipoLangList', name: 'genallegatotipolang', translateSuffixs: ['generic.', 'genallegatotipolang.'],
    entityType: GenAllegatoTipoListLangModalEMC,
    entityServices: [{ name: 'genallegatotipolang', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  // Anagrafiche calendario
  static AnaCal: EntityManagerInfo = {
    id: 'AnaCal', name: 'anacal', translateSuffixs: ['menu.'],
    entityType: AnaCalEMC,
    url: 'anagrafiche', parent: AppManagerRoutes.Anagrafiche
  }

  // Anagrafiche calendario [tabelle Stati]
  static AnaCalStati: EntityManagerInfo = {
    id: 'AnaCalStati', name: 'anacalstati', translateSuffixs: ['menu.'],
    entityType: AnaCalStatiEMC,
    url: 'anagrafiche', parent: AppManagerRoutes.Anagrafiche
  }

  // Anagrafiche calendario [tabelle Tipi]
  static AnaCalTipi: EntityManagerInfo = {
    id: 'AnaCalTipi', name: 'anacaltipi', translateSuffixs: ['menu.'],
    entityType: AnaCalTipiEMC,
    url: 'anagrafiche', parent: AppManagerRoutes.Anagrafiche
  }

  static CalDefExpNumList: EntityManagerInfo = {
    id: 'CalDefExpNumList', name: 'caldefexpnum', translateSuffixs: ['generic.', 'caldefexpnum.'],
    entityType: CalDefExpNumEMC,
    entityServices: [{ name: 'caldefexpnum', serviceName: 'restCalendario' }],
    url: 'caldefexpnumlist', parent: AppManagerRoutes.AnaCal
  };

  static CalDefRadMosNumList: EntityManagerInfo = {
    id: 'CalDefRadMosNumList', name: 'caldefradmosnum', translateSuffixs: ['generic.', 'caldefradmosnum.'],
    entityType: CalDefRadMosNumEMC,
    entityServices: [{ name: 'caldefradmosnum', serviceName: 'restCalendario' }],
    url: 'caldefradmosnumlist', parent: AppManagerRoutes.AnaCal
  };

  static CalDefSociFamiRazzeList: EntityManagerInfo = {
    id: 'CalDefSociFamiRazzeList', name: 'caldefsocifamirazze', translateSuffixs: ['generic.', 'caldefsocifamirazze.'],
    entityType: CalDefSociFamiRazzeEMC,
    entityServices: [
      { name: 'caldefsocifamirazze', serviceName: 'restCalendario' },
      { name: 'genrazzalang', serviceName: 'restCommon' }
    ],
    url: 'caldefsocifamirazzelist', parent: AppManagerRoutes.AnaCal
  };

  static CalDefVincoloList: EntityManagerInfo = {
    id: 'CalDefVincoloList', name: 'caldefvincolo', translateSuffixs: ['generic.', 'caldefvincolo.'],
    entityType: CalDefVincoloEMC,
    entityServices: [
      { name: 'caldefvincolo', serviceName: 'restCalendario' },
      { name: 'caltipocal', serviceName: 'restCalendario' },
      { name: 'genunitamisura', serviceName: 'restCommon' },
      { name: 'caltipomani', serviceName: 'restCalendario' }
    ],
    url: 'caldefvincololist', parent: AppManagerRoutes.AnaCal
  };
  static CalDefVincoloEdit: EntityManagerInfo = cloneObject(
    AppManagerRoutes.CalDefVincoloList,
    { id: 'CalDefVincoloEdit', url: 'caldefvincoloedit', parent: AppManagerRoutes.CalDefVincoloList }
  );
  static CalDefVincoloNew: EntityManagerInfo = cloneObject(
    AppManagerRoutes.CalDefVincoloList,
    { id: 'CalDefVincoloNew', url: 'caldefvincolonew', parent: AppManagerRoutes.CalDefVincoloList }
  );

  static CalDefVincoloLangList: EntityManagerInfo = {
    id: 'CalDefVincoloLangList', name: 'caldefvincololang', translateSuffixs: ['generic.', 'caldefvincololang.'],
    entityType: CalDefVincoloLangEMC,
    entityServices: [{ name: 'caldefvincololang', serviceName: 'restCalendario' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static CalDefVincoloProfiloList: EntityManagerInfo = {
    id: 'CalDefVincoloProfiloList', name: 'caldefvincoloprofilo', translateSuffixs: ['generic.', 'caldefvincoloprofilo.'],
    entityType: CalDefVincoloProfiloEMC,
    entityServices: [
      { name: 'caldefvincoloprofilo', serviceName: 'restCalendario' },
      { name: 'secprofilo', serviceName: 'restCommon' }
    ],
    url: 'caldefvincoloprofilo', parent: AppManagerRoutes.CalDefVincoloList
  };

  static CalFamiRazzeList: EntityManagerInfo = {
    id: 'CalFamiRazzeList', name: 'calfamirazze', translateSuffixs: ['generic.', 'calfamirazze.'],
    entityType: CalFamiRazzeEMC,
    entityServices: [{ name: 'calfamirazze', serviceName: 'restCalendario' }],
    url: 'calfamirazzelist', parent: AppManagerRoutes.AnaCal
  };
  static CalFamiRazzeLangList: EntityManagerInfo = {
    id: 'CalFamiRazzeLangList', name: 'calfamirazzelang', translateSuffixs: ['generic.', 'calfamirazzelang.'],
    entityType: CalFamiRazzeLangEMC,
    entityServices: [{ name: 'calfamirazzelang', serviceName: 'restCalendario' }, { name: 'genlang', serviceName: 'restCommon' }]
  };
  static CalFamiRazzeModal: EntityManagerInfo = {
    id: 'CalFamiRazzeModal', name: 'calfamirazze', translateSuffixs: ['generic.', 'calfamirazze.'],
    entityType: CalFamiRazzeModalEMC,
    entityServices: [{ name: 'calfamirazze', serviceName: 'restCalendario' }]
  };

  static CalParereManiList: EntityManagerInfo = {
    id: 'CalParereManiList', name: 'calpareremani', translateSuffixs: ['generic.', 'calpareremani.'],
    entityType: CalParereManiEMC,
    entityServices: [{ name: 'calpareremani', serviceName: 'restCalendario' }],
    url: 'calpareremanilist', parent: AppManagerRoutes.AnaCal
  };

  static CalParereManiLangList: EntityManagerInfo = {
    id: 'CalParereManiLangList', name: 'calpareremanilang', translateSuffixs: ['generic.', 'calpareremanilang.'],
    entityType: CalParereManiLangEMC,
    entityServices: [{ name: 'calpareremanilang', serviceName: 'restCalendario' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static CalParereTipoList: EntityManagerInfo = {
    id: 'CalParereTipoList', name: 'calpareretipo', translateSuffixs: ['generic.', 'calpareretipo.'],
    entityType: CalParereTipoEMC,
    entityServices: [{ name: 'calpareretipo', serviceName: 'restCalendario' }],
    url: 'calpareretipolist', parent: AppManagerRoutes.AnaCal
  };

  static CalParereTipoLangList: EntityManagerInfo = {
    id: 'CalParereTipoLangList', name: 'calpareretipolang', translateSuffixs: ['generic.', 'calpareretipolang.'],
    entityType: CalParereTipoLangEMC,
    entityServices: [{ name: 'calpareretipolang', serviceName: 'restCalendario' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static CalStatoCalList: EntityManagerInfo = {
    id: 'CalStatoCalList', name: 'calstatocal', translateSuffixs: ['generic.', 'calstatocal.'],
    entityType: CalStatoCalEMC,
    entityServices: [{ name: 'calstatocal', serviceName: 'restCalendario' }],
    url: 'calstatocallist', parent: AppManagerRoutes.AnaCal
  };
  static CalStatoCalLangList: EntityManagerInfo = {
    id: 'CalStatoCalLangList', name: 'calstatocallang', translateSuffixs: ['generic.', 'calstatocallang.'],
    entityType: CalStatoCalLangModalEMC,
    entityServices: [{ name: 'calstatocallang', serviceName: 'restCalendario' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static CalStatoEventoList: EntityManagerInfo = {
    id: 'CalStatoEventoList', name: 'calstatoevento', translateSuffixs: ['generic.', 'calstatoevento.'],
    entityType: CalStatoEventoEMC,
    entityServices: [{ name: 'calstatoevento', serviceName: 'restCalendario' }],
    url: 'calstatoeventolist', parent: AppManagerRoutes.AnaCal
  };
  static CalStatoEventoLangList: EntityManagerInfo = {
    id: 'CalStatoEventoLangList', name: 'calstatoeventolang', translateSuffixs: ['generic.', 'calstatoeventolang.'],
    entityType: CalStatoEventoLangModalEMC,
    entityServices: [{ name: 'calstatoeventolang', serviceName: 'restCalendario' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static CalStatoManiList: EntityManagerInfo = {
    id: 'CalStatoManiList', name: 'calstatomani', translateSuffixs: ['generic.', 'calstatomani.'],
    entityType: CalStatoManiEMC,
    entityServices: [{ name: 'calstatomani', serviceName: 'restCalendario' }],
    url: 'calstatomanilist', parent: AppManagerRoutes.AnaCal
  };
  static CalStatoManiLangList: EntityManagerInfo = {
    id: 'CalStatoManiLangList', name: 'calstatomanilang', translateSuffixs: ['generic.', 'calstatomanilang.'],
    entityType: CalStatoManiLangModalEMC,
    entityServices: [{ name: 'calstatomanilang', serviceName: 'restCalendario' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static CalTipoCalList: EntityManagerInfo = {
    id: 'CalTipoCalList', name: 'caltipocal', translateSuffixs: ['generic.', 'caltipocal.'],
    entityType: CalTipoCalEMC,
    entityServices: [{ name: 'caltipocal', serviceName: 'restCalendario' }],
    url: 'caltipocallist', parent: AppManagerRoutes.AnaCal
  };

  static CalTipoCalModal: EntityManagerInfo = {
    id: 'CalTipoCalModal', name: 'caltipocal', translateSuffixs: ['generic.', 'caltipocal.'],
    entityType: CalTipoCalModalEMC,
    entityServices: [{ name: 'caltipocal', serviceName: 'restCalendario' }]
  };
  static CalTipoCalLangList: EntityManagerInfo = {
    id: 'CalTipoCalLangList', name: 'caltipocallang', translateSuffixs: ['generic.', 'caltipocallang.'],
    entityType: CalTipoCalLangModalEMC,
    entityServices: [{ name: 'caltipocallang', serviceName: 'restCalendario' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static CalTipoManiList: EntityManagerInfo = {
    id: 'CalTipoManiList', name: 'caltipomani', translateSuffixs: ['generic.', 'caltipomani.'],
    entityType: CalTipoManiEMC,
    entityServices: [{ name: 'caltipomani', serviceName: 'restCalendario' }, { name: 'caltipocal', serviceName: 'restCalendario' }],
    url: 'caltipomanilist', parent: AppManagerRoutes.AnaCal
  };
  static CalTipoManiLangList: EntityManagerInfo = {
    id: 'CalTipoManiLangList', name: 'caltipomanilang', translateSuffixs: ['generic.', 'caltipomanilang.'],
    entityType: CalTipoManiLangModalEMC,
    entityServices: [{ name: 'caltipomanilang', serviceName: 'restCalendario' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static CalTipoPeriodoCalList: EntityManagerInfo = {
    id: 'CalTipoPeriodoCalList', name: 'caltipoperiodocal', translateSuffixs: ['generic.', 'caltipoperiodocal.'],
    entityType: CalTipoPeriodoCalEMC,
    entityServices: [{ name: 'caltipoperiodocal', serviceName: 'restCalendario' }],
    url: 'caltipoperiodocallist', parent: AppManagerRoutes.AnaCal
  };
  static CalTipoPeriodoCalLangList: EntityManagerInfo = {
    id: 'CalTipoPeriodoCalLangList', name: 'caltipoperiodocallang', translateSuffixs: ['generic.', 'caltipoperiodocallang.'],
    entityType: CalTipoPeriodoCalLangEMC,
    entityServices: [{ name: 'caltipoperiodocallang', serviceName: 'restCalendario' }, { name: 'genlang', serviceName: 'restCommon' }]
  };
  static CalMotivoBloccoDataList: EntityManagerInfo = {
    id: 'CalMotivoBloccoDataList', name: 'calmotivobloccodata', translateSuffixs: ['generic.', 'calmotivobloccodata.'],
    entityType: CalMotivoBloccoDataEMC,
    entityServices: [{ name: 'calmotivobloccodata', serviceName: 'restCalendario' }, { name: 'caltipomani', serviceName: 'restCalendario' }],
    url: 'calmotivobloccodatalist', parent: AppManagerRoutes.AnaCal
  };
  static CalMotivoBloccoDataLangList: EntityManagerInfo = {
    id: 'CalMotivoBloccoDataLangList', name: 'calmotivobloccodatalang', translateSuffixs: ['generic.', 'calmotivobloccodatalang.'],
    entityType: CalMotivoBloccoDataLangModalEMC,
    entityServices: [{ name: 'calmotivobloccodatalang', serviceName: 'restCalendario' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static CalDefPrvSpecNumList: EntityManagerInfo = {
    id: 'CalDefPrvSpecNumList', name: 'caldefprvspecnum', translateSuffixs: ['generic.', 'caldefprvspecnum.'],
    entityType: CalDefPrvSpecNumEMC,
    entityServices: [{ name: 'caldefprvspecnum', serviceName: 'restCalendario' }],
    url: 'caldefprvspecnumlist', parent: AppManagerRoutes.AnaCal
  };

  static CalPrvSpConfigList: EntityManagerInfo = {
    id: 'CalPrvSpConfigList', name: 'calprvspconfig', translateSuffixs: ['generic.', 'calprvspconfig.'],
    entityType: EMPTYEMC,
    url: 'calprvspconfiglist', parent: AppManagerRoutes.Anagrafiche
  };

  static CalPrvSpConfigEdit: EntityManagerInfo = {
    id: 'CalPrvSpConfigEdit', name: 'calprvspconfig', translateSuffixs: ['generic.', 'calprvspconfig.'],
    entityType: EMPTYEMC,
    url: 'calprvspconfigedit', parent: AppManagerRoutes.CalPrvSpConfigList
  };

  static CalPrvSpConfigNew: EntityManagerInfo = {
    id: 'CalPrvSpConfigNew', name: 'calprvspconfig', translateSuffixs: ['generic.', 'calprvspconfig.'],
    entityType: EMPTYEMC,
    url: 'calprvspconfignew', parent: AppManagerRoutes.CalPrvSpConfigList
  };

  static CalCircuitoList: EntityManagerInfo = {
    id: 'CalCircuitoList', name: 'calcircuito', translateSuffixs: ['generic.', 'calcircuito.'],
    entityType: CalCircuitoEMC,
    entityServices: [{ name: 'calcircuito', serviceName: 'restCalendario' }, { name: 'caltipomani', serviceName: 'restCalendario' }],
    url: 'calcircuitolist', parent: AppManagerRoutes.AnaCal
  };
  static CalCircuitoLangList: EntityManagerInfo = {
    id: 'CalCircuitoLangList', name: 'calcircuitolang', translateSuffixs: ['generic.', 'calcircuitolang.'],
    entityType: CalCircuitoLangModalEMC,
    entityServices: [{ name: 'calcircuitolang', serviceName: 'restCalendario' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  // Anagrafiche razze
  static AnaRazze: EntityManagerInfo = {
    id: 'AnaRazze', name: 'AnaRazze', translateSuffixs: ['menu.'],
    entityType: AnaRazzeEMC,
    url: 'anagrafiche', parent: AppManagerRoutes.Anagrafiche
  }

  static GenClasseList: EntityManagerInfo = {
    id: 'GenClasseList', name: 'genclasse', translateSuffixs: ['generic.', 'genclasse.'],
    entityType: GenClasseEMC,
    entityServices: [{ name: 'genclasse', serviceName: 'restCommon' }],
    url: 'GenClasselist', parent: AppManagerRoutes.AnaCal
  };
  static GenClasseEdit: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GenClasseList,
    { id: 'GenClasseEdit', url: 'genclasseedit', parent: AppManagerRoutes.GenClasseList }
  );
  static GenClasseNew: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GenClasseList,
    { id: 'GenClasseNew', url: 'genclassenew', parent: AppManagerRoutes.GenClasseList }
  );
  static GenClasseLangList: EntityManagerInfo = {
    id: 'GenClasseLangList', name: 'genclasselang', translateSuffixs: ['generic.', 'genclasselang.'],
    entityType: GenClasseLangModalEMC,
    entityServices: [{ name: 'genclasselang', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static GenGruppoFciList: EntityManagerInfo = {
    id: 'GenGruppoFciList', name: 'gengruppofci', translateSuffixs: ['generic.', 'gengruppofci.'],
    entityType: GenGruppoFciEMC,
    entityServices: [{ name: 'gengruppofci', serviceName: 'restCommon' }],
    url: 'gengruppofcilist', parent: AppManagerRoutes.AnaCal
  };
  static GenGruppoFciLangList: EntityManagerInfo = {
    id: 'GenGruppoFciLangList', name: 'gengruppofcilang', translateSuffixs: ['generic.', 'gengruppofcilang.'],
    entityType: GenGruppoFciLangModalEMC,
    entityServices: [{ name: 'gengruppofcilang', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static GenCartellinoList: EntityManagerInfo = {
    id: 'GenCartellinoList', name: 'gencartellino', translateSuffixs: ['generic.', 'gencartellino.'],
    entityType: GenCartellinoEMC,
    entityServices: [{ name: 'gencartellino', serviceName: 'restCommon' }],
    url: 'GenCartellinolist', parent: AppManagerRoutes.AnaCal
  };
  static GenCartellinoEdit: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GenCartellinoList,
    { id: 'GenCartellinoEdit', url: 'gencartellinoedit', parent: AppManagerRoutes.GenCartellinoList }
  );
  static GenCartellinoNew: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GenCartellinoList,
    { id: 'GenCartellinoNew', url: 'gencartellinonew', parent: AppManagerRoutes.GenCartellinoList }
  );
  static GenCartellinoLangList: EntityManagerInfo = {
    id: 'GenCartellinoLangList', name: 'gencartellinolang', translateSuffixs: ['generic.', 'gencartellinolang.'],
    entityType: GenCartellinoLangModalEMC,
    entityServices: [{ name: 'gencartellinolang', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  // Anagrafiche relazione
  static AnaRelazione: EntityManagerInfo = {
    id: 'AnaRelazione', name: 'anarelazione', translateSuffixs: ['menu.'],
    entityType: AnaRelazioneEMC,
    url: 'anagrafiche', parent: AppManagerRoutes.Anagrafiche
  }

  static GenRelazioneList: EntityManagerInfo = {
    id: 'GenRelazioneList', name: 'genrelazione', translateSuffixs: ['generic.', 'genrelazione.'],
    entityType: GenRelazioneEMC,
    entityServices: [{ name: 'genrelazione', serviceName: 'restCommon' }, { name: 'secprofilo', serviceName: 'restCommon' }, { name: 'caltipomani', serviceName: 'restCalendario' }],
    url: 'GenRelazionelist', parent: AppManagerRoutes.AnaRelazione
  };
  static GenRelazioneLangList: EntityManagerInfo = {
    id: 'GenRelazioneLangList', name: 'genrelazionelang', translateSuffixs: ['generic.', 'genrelazionelang.'],
    entityType: GenRelazioneLangEMC,
    entityServices: [{ name: 'genrelazionelang', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static GenDomandaList: EntityManagerInfo = {
    id: 'GenDomandaList', name: 'gendomanda', translateSuffixs: ['generic.', 'gendomanda.'],
    entityType: GenDomandaEMC,
    entityServices: [{ name: 'gendomanda', serviceName: 'restCommon' }, { name: 'gendomandatipo', serviceName: 'restCommon' }],
    url: 'GenDomandalist', parent: AppManagerRoutes.AnaRelazione
  };
  static GenDomandaLangList: EntityManagerInfo = {
    id: 'GenDomandaLangList', name: 'gendomandalang', translateSuffixs: ['generic.', 'gendomandalang.'],
    entityType: GenDomandaLangEMC,
    entityServices: [{ name: 'gendomandalang', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static GenDomandaTipoList: EntityManagerInfo = {
    id: 'GenDomandaTipoList', name: 'gendomandatipo', translateSuffixs: ['generic.', 'gendomandatipo.'],
    entityType: GenDomandaTipoEMC,
    entityServices: [{ name: 'gendomandatipo', serviceName: 'restCommon' }],
    url: 'GenDomandaTipolist', parent: AppManagerRoutes.AnaRelazione
  };
  static GenDomandaTipoLangList: EntityManagerInfo = {
    id: 'GenDomandaTipoLangList', name: 'gendomandatipolang', translateSuffixs: ['generic.', 'gendomandatipolang.'],
    entityType: GenDomandaTipoLangEMC,
    entityServices: [{ name: 'gendomandatipolang', serviceName: 'restCommon' }, { name: 'genlang', serviceName: 'restCommon' }]
  };

  static GenRelazioneDomandaList: EntityManagerInfo = {
    id: 'GenRelazioneDomandaList', name: 'genrelazionedomanda', translateSuffixs: ['generic.', 'genrelazionedomanda.'],
    entityType: GenRelazioneDomandaEMC,
    entityServices: [{ name: 'genrelazionedomanda', serviceName: 'restCommon' }, { name: 'gendomanda', serviceName: 'restCommon' }],
    url: 'genrelazionedomandalist', parent: AppManagerRoutes.GenRelazioneList
  };

  // Calendario
  static Calendario: EntityManagerInfo = {
    id: 'Calendario', name: 'calendario', translateSuffixs: ['generic.', 'menu.'],
    entityType: CalendarioEMC,
    entityServices: [{ name: 'calendario', serviceName: 'restCalendario' }],
    url: 'calendario', parent: AppManagerRoutes.Home
  };

  static CalCaleList: EntityManagerInfo = {
    id: 'CalCaleList', name: 'calcale', translateSuffixs: ['generic.', 'calcale.'],
    entityType: EMPTYEMC,
    url: 'calcalelist', parent: AppManagerRoutes.Calendario
  };

  static CalCaleEdit: EntityManagerInfo = {
    id: 'CalCaleEdit', name: 'calcale', translateSuffixs: ['generic.', 'calcale.', 'calcale.', 'calcaleexpnum.'],
    entityType: EMPTYEMC,
    url: 'calcaleedit', parent: AppManagerRoutes.CalCaleList
  };

  static CalCaleNew: EntityManagerInfo = {
    id: 'CalCaleNew', name: 'calcale', translateSuffixs: ['generic.', 'calcale.', 'calcale.', 'calcaleexpnum.'],
    entityType: EMPTYEMC,
    url: 'calcalenew', parent: AppManagerRoutes.CalCaleList
  };

  static CalCaleHeader: EntityManagerInfo = {
    id: 'CalCaleHeader', name: 'calcale', translateSuffixs: ['generic.', 'calcale.'],
    entityType: EMPTYEMC
  };

  static CalCaleSettings: EntityManagerInfo = {
    id: 'CalCaleSettings', name: 'calcalesettings', translateSuffixs: ['generic.', 'calcale.', 'calcaleexpnum.', 'calcaleradmosnum.', 'calcaleradmosnumdett.', 'calcaledatebloccate.', 'calcalevincolo.', 'calcaleprvspecnum.', 'calcaleprint.'],
    entityType: EMPTYEMC,
    entityServices: [{ name: 'calcalesettings', serviceName: 'restCalendario' }],
    url: 'calcalesettings', parent: AppManagerRoutes.CalCaleList
  };

  static CalCaleDetail: EntityManagerInfo = {
    id: 'CalCaleDetail', name: 'calcale', translateSuffixs: ['generic.', 'calcale.', 'calcaleexpnum.', 'calcaleradmosnum.', 'calcaleradmosnumdett.', 'calcaledatebloccate.', 'calcalevincolo.', 'calcaleprvspecnum.', 'calcalemanilisttab.', 'calcaleanom.', 'calcaleprint.'],
    entityType: EMPTYEMC,
    url: 'calcaledetail', parent: AppManagerRoutes.CalCaleList
  };

  // static CalCaleVincoloList: EntityManagerInfo = {
  //   id: 'CalCaleVincoloList', name: 'calcalevincolo', translateSuffixs: ['generic.', 'calcalevincolo.'],
  //   entityType: CalCaleVincoloEMC,
  //   entityServices: [{ name: 'calcalevincolo', serviceName: 'restCalendario' }, { name: 'genunitamisura', serviceName: 'restCommon' }],
  //   url: 'calcalevincololist', parent: AppManagerRoutes.Calendario
  // };
  static CalDefVincoloModal: EntityManagerInfo = {
    id: 'CalDefVincoloModal', name: 'caldefvincolo', translateSuffixs: ['generic.', 'caldefvincolo.'],
    entityType: CalDefVincoloModalEMC,
    entityServices: [{ name: 'caldefvincolo', serviceName: 'restCalendario' }, { name: 'caltipocal', serviceName: 'restCalendario' }],
    url: 'caldefvincololist'
  };

  // static CalCaleExpNumList: EntityManagerInfo = {
  //   id: 'CalCaleExpNumList', name: 'calcaleexpnum', translateSuffixs: ['generic.', 'calcaleexpnum.'],
  //   entityType: CalCaleExpNumEMC,
  //   entityServices: [{ name: 'calcaleexpnum', serviceName: 'restCalendario' }],
  //   url: 'calcaleexpnumlist'
  // };

  // static CalCaleRadMosNumList: EntityManagerInfo = {
  //   id: 'CalCaleRadMosNumList', name: 'calcaleradmosnum', translateSuffixs: ['generic.', 'calcaleradmosnum.'],
  //   entityType: CalCaleRadMosNumEMC,
  //   entityServices: [{ name: 'calcaleradmosnum', serviceName: 'restCalendario' }],
  //   url: 'calcaleradmosnumlist'
  // };
  // static CalCaleRadMosNumDettList: EntityManagerInfo = {
  //   id: 'CalCaleRadMosNumDettList', name: 'calcaleradmosnum', translateSuffixs: ['generic.', 'calcaleradmosnumdett.'],
  //   entityType: CalCaleRadMosNumDettEMC,
  //   entityServices: [{ name: 'calcaleradmosnum', serviceName: 'restCalendario' }],
  //   url: 'calcaleradmosnumdettlist', parent: AppManagerRoutes.CalCaleParametri
  // };

  // static CalCalePrvSpecNumList: EntityManagerInfo = {
  //   id: 'CalCalePrvSpecNumList', name: 'calcaleprvspecnum', translateSuffixs: ['generic.', 'calcaleprvspecnum.'],
  //   entityType: CalPrvSpecNumEMC,
  //   entityServices: [{ name: 'calcaleprvspecnum', serviceName: 'restCalendario' }],
  //   url: 'calcaleprvspecnumlist'
  // };

  // static CalCaleDateBloccateList: EntityManagerInfo = {
  //   id: 'CalCaleDateBloccateList', name: 'calcaledatebloccate', translateSuffixs: ['generic.', 'calcaledatebloccate.'],
  //   entityType: CalCaleDateBloccateEMC,
  //   entityServices: [{ name: 'calcaledatebloccate', serviceName: 'restCalendario' }, { name: 'calmotivobloccodata', serviceName: 'restCalendario' }],
  //   url: 'calcaledatebloccatelist'
  // }

  // CalCaleMani
  static CalCaleManiList: EntityManagerInfo = {
    id: 'CalCaleManiList', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanianomalie.'],
    entityType: EMPTYEMC,
    url: 'calcalemanilist', parent: AppManagerRoutes.Calendario
  };

  static CalCaleManiEdit: EntityManagerInfo = {
    id: 'CalCaleManiEdit', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanianomalie.', 'calcaleevento.'],
    entityType: EMPTYEMC,
    url: 'calcalemaniedit', parent: AppManagerRoutes.CalCaleManiList
  };

  static CalCaleManiDateEditWizard: EntityManagerInfo = {
    id: 'CalCaleManiDateEditWizard', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calecalemanidateeditwizard.'],
    entityType: EMPTYEMC
  };

  static CalCaleManiNew: EntityManagerInfo = {
    id: 'CalCaleManiNew', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanianomalie.'],
    entityType: EMPTYEMC,
    url: 'calcalemaninew', parent: AppManagerRoutes.CalCaleManiList
  };

  static CalCaleManiHeader: EntityManagerInfo = {
    id: 'CalCaleManiHeader', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanipsa.'],
    entityType: EMPTYEMC
  };

  // CalCaleMani Expo
  static CalCaleManiExpoList: EntityManagerInfo = {
    id: 'CalCaleManiExpoList', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanianomalie.'],
    entityType: EMPTYEMC,
    url: 'calcalemaniexpolist', parent: AppManagerRoutes.Calendario, queryParams: { tipoCalId: TipoCal.esposizioni.toString() }
  };

  static CalCaleManiExpoEdit: EntityManagerInfo = {
    id: 'CalCaleManiExpoEdit', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanianomalie.'],
    entityType: EMPTYEMC,
    url: 'calcalemaniexpodit', parent: AppManagerRoutes.CalCaleManiExpoList
  };

  static CalCaleManiExpoNew: EntityManagerInfo = {
    id: 'CalCaleManiExpoNew', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanianomalie.'],
    entityType: EMPTYEMC,
    url: 'calcalemaniexponew', parent: AppManagerRoutes.CalCaleManiExpoList
  };

  // CalCaleMani Prov
  static CalCaleManiProvList: EntityManagerInfo = {
    id: 'CalCaleManiProvList', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanianomalie.'],
    entityType: EMPTYEMC,
    url: 'calcalemaniprovlist', parent: AppManagerRoutes.Calendario, queryParams: { tipoCalId: TipoCal.prove.toString() }
  };

  static CalCaleManiProvEdit: EntityManagerInfo = {
    id: 'CalCaleManiProvEdit', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanianomalie.'],
    entityType: EMPTYEMC,
    url: 'calcalemaniprovedit', parent: AppManagerRoutes.CalCaleManiProvList
  };

  static CalCaleManiProvNew: EntityManagerInfo = {
    id: 'CalCaleManiProvNew', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanianomalie.'],
    entityType: EMPTYEMC,
    url: 'calcalemaniprovnew', parent: AppManagerRoutes.CalCaleManiProvList
  };

  // CalCaleManiSpecAs
  static CalCaleManiSpecList: EntityManagerInfo = {
    id: 'CalCaleManiSpecList', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.'],
    entityType: EMPTYEMC,
    url: 'calcalemanispeclist', parent: AppManagerRoutes.Calendario, queryParams: { flGestSpecialeInCorso: '1' }
  };

  static CalCaleManiSpecAsList: EntityManagerInfo = {
    id: 'CalCaleManiSpecAsList', name: 'calcalemanispecas', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanispecas.', 'calcalemanianomalie.'],
    entityType: EMPTYEMC,
    url: 'calcalemanispecaslist', parent: AppManagerRoutes.Calendario
  };

  // CalCaleManiRad
  static CalCaleManiRadList: EntityManagerInfo = {
    id: 'CalCaleManiRadList', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanianomalie.'],
    entityType: EMPTYEMC,
    url: 'calcalemaniradlist', parent: AppManagerRoutes.Calendario, queryParams: { tipoCalId: TipoCal.esposizioni.toString(), tipoManiId: '2' }
  };

  static CalCaleManiRadNew: EntityManagerInfo = {
    id: 'CalCaleManiRadNew', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanianomalie.'],
    entityType: EMPTYEMC,
    url: 'calcalemaniradnew', parent: AppManagerRoutes.CalCaleManiRadList
  };

  static CalCaleUtils: EntityManagerInfo = {
    id: 'CalCaleUtils', name: 'calcaleutils', translateSuffixs: ['generic.', 'calcaleutils.'],
    entityType: CalendarioUtilsEMC,
    url: 'calendario', parent: AppManagerRoutes.Calendario
  };

  // CalCaleMani LogEvento
  static CalCaleManiLogList: EntityManagerInfo = {
    id: 'CalCaleManiLogList', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanianomalie.'],
    entityType: EMPTYEMC,
    url: 'calcalemaniloglist', parent: AppManagerRoutes.CalCaleUtils
  };

  static CalCaleManiLogPrvList: EntityManagerInfo = {
    id: 'CalCaleManiLogPrvList', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanianomalie.'],
    entityType: EMPTYEMC,
    url: 'calcalemanilogprvlist', parent: AppManagerRoutes.CalCaleUtils, queryParams: { tipoCalId: TipoCal.prove.toString() }
  };

  static CalCaleManiLogExpList: EntityManagerInfo = {
    id: 'CalCaleManiLogExpList', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanianomalie.'],
    entityType: EMPTYEMC,
    url: 'calcalemanilogexplist', parent: AppManagerRoutes.CalCaleUtils, queryParams: { tipoCalId: TipoCal.esposizioni.toString() }
  };

  static CalCaleManiRisultatiGlobList: EntityManagerInfo = {
    id: 'CalCaleManiRisultatiGlobList', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanirisultatigloblist.', 'calcalemanirisultati.'],
    entityType: EMPTYEMC,
    url: 'calcalemanirisultatigloblist', parent: AppManagerRoutes.CalCaleUtils
  };

  // Gestione richieste di prove speciali
  static CalCaleGestSpecList: EntityManagerInfo = {
    id: 'CalCaleGestSpecList', name: 'calcalemanigestspec', translateSuffixs: ['generic.', 'gestspec.'],
    entityType: EMPTYEMC,
    url: 'calcalemanigestspeclist', parent: AppManagerRoutes.Calendario
  };

  static CalCaleGestSpecPropList: EntityManagerInfo = {
    id: 'CalCaleGestSpecPropList', name: 'calcalemanigestspec', translateSuffixs: ['generic.', 'gestspec.'],
    entityType: EMPTYEMC,
    url: 'calcalemanigestspecproplist', parent: AppManagerRoutes.Calendario, queryParams: { codOperazione: 'PROPOSTA' }
  };

  static CalCaleGestSpecRichList: EntityManagerInfo = {
    id: 'CalCaleGestSpecRichList', name: 'calcalemanigestspec', translateSuffixs: ['generic.', 'gestspec.'],
    entityType: EMPTYEMC,
    url: 'calcalemanigestspecrichlist', parent: AppManagerRoutes.Calendario, queryParams: { codOperazione: 'RICHIESTA' }
  };

  static CalCaleManiDetail: EntityManagerInfo = {
    id: 'CalCaleManiDetail', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanicomi.', 'calcalemanipare.', 'calcalemanipsa.', 'calcalemanirazze.', 'calcalemanigiuria.', 'calcalemanisp.', 'calcalemanialle.', 'calcalemanidele.', 'calcalemanianomalie.', 'calcalemanigiudragg.', 'calcalemanigiudrazze.', 'calcalemaniprint.', 'calcalemanilistino.', 'calcalemanicosto.', 'calcalemanifigure.', 'calcalemanielab.'],
    entityType: EMPTYEMC,
    url: 'calcalemanidetail', parent: AppManagerRoutes.CalCaleManiList
  };



  // Tab dettaglio manifestazione
  static CalCaleManiDetailSp: EntityManagerInfo = {
    id: 'CalCaleManiDetailSp', name: 'calcalemanisp', translateSuffixs: ['generic.', 'calcalemanisp.'],
    entityType: EMPTYEMC
  };

  static CalCaleManiSettings: EntityManagerInfo = {
    id: 'CalCaleManiSettings', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanicomi.', 'calcalemanipare.', 'calcalemanirazze.', 'calcalemanigiuria.', 'calcalemanialle.', 'calcalemanidele.', 'calcalemanianomalie.', 'calcalemanigiudragg.', 'calcalemanigiudrazze.', 'calcalemaniparedelete.', 'calcalemaniwizardanom.', 'calcalemaniraggevento.', 'calcaleevento.', 'modalshowrazzecomuni.', 'calcalemaniprint.', 'calcalemanilistino.', 'calcalemanicosto.', 'calcalemanifigure.', 'calcalemanielab.', 'calcalemanisp.', 'giudassistentato.', 'calcalemanipsa.',],
    entityType: EMPTYEMC,
    url: 'calcalemanisettings', parent: AppManagerRoutes.CalCaleManiList
  };

  // Tab settings manifestazione
  static CalCaleManiSettingsSp: EntityManagerInfo = {
    id: 'CalCaleManiSettingsSp', name: 'calcalemanisp', translateSuffixs: ['generic.', 'calcalemanisp.', 'calcalemanigiuria.', 'calcalemani.'],
    entityType: EMPTYEMC
  };
  static CalCaleManiSettingsRelazione: EntityManagerInfo = {
    id: 'CalCaleManiSettingsRelazione', name: 'calcalemanisettingsrelazione', translateSuffixs: ['generic.', 'calcalemanirelazione.'],
    entityType: EMPTYEMC
  };
  static CalCaleManiSettingsRisultati: EntityManagerInfo = {
    id: 'CalCaleManiSettingsRisultati', name: 'calcalemanisettingsrisultati', translateSuffixs: ['generic.', 'calcalemanirisultati.'],
    entityType: EMPTYEMC
  };
  static CalCaleManiSettingsElab: EntityManagerInfo = {
    id: 'CalCaleManiSettingsElab', name: 'calcalemanisettingselab', translateSuffixs: ['generic.', 'calcalemanielab.', 'attachments.'],
    entityType: EMPTYEMC
  };
  static CalCaleManiSettingsGiuriaAddGiudice: EntityManagerInfo = {
    id: 'CalCaleManiSettingsGiuriaAddGiudice', name: 'calcalemanisettingsgiuriaaddgiudice', translateSuffixs: ['generic.', 'calcalemanigiuria.'],
    entityType: EMPTYEMC
  };
  static CalCaleManiSettingsFigureAdd: EntityManagerInfo = {
    id: 'CalCaleManiSettingsFigureAdd', name: 'calcalemanisettingsfigureadd', translateSuffixs: ['generic.', 'calcalemanifigure.'],
    entityType: EMPTYEMC
  };
  static GenAllegatoSendHistory: EntityManagerInfo = {
    id: 'GenAllegatoSendHistory', name: 'genallegatosendhistory', translateSuffixs: ['generic.', 'genallegatosendhistory.'],
    entityType: EMPTYEMC
  };
  static CalCaleManiSettingsElabRisLog: EntityManagerInfo = {
    id: 'CalCaleManiSettingsElabRisLog', name: 'calcalemanielabrislog', translateSuffixs: ['generic.', 'calcalemanielabrislog.'],
    entityType: EMPTYEMC
  };

  static CalCaleManiSettingsGrCinTerr: EntityManagerInfo = {
    id: 'CalCaleManiSettingsGrCinTerr', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanigiudrazze.', 'calcalemanigiuria.', 'calcalemanipare.', 'calcalemanipsa.'],
    entityType: EMPTYEMC,
    url: 'calcalemanisettingsgrcinterr', parent: AppManagerRoutes.CalCaleManiList
  };

  static CalCaleManiSettingsClassiList: EntityManagerInfo = {
    id: 'CalCaleManiSettingsClassiList', name: 'calcalemaniclassi', translateSuffixs: ['generic.', 'calcalemaniclassi.'],
    entityType: EMPTYEMC
  };
  static CalCaleManiSettingsClassiAdd: EntityManagerInfo = {
    id: 'CalCaleManiSettingsClassiAdd', name: 'calcalemaniclassi', translateSuffixs: ['generic.', 'calcalemaniclassi.'],
    entityType: EMPTYEMC
  };

  static CalCaleManiSpecAs: EntityManagerInfo = {
    id: 'CalCaleManiSpecAs', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcalemanispecas.', 'calcalemanirazze.', 'calcalemanisp.'],
    entityType: EMPTYEMC,
    url: 'calcalemanispecas', parent: AppManagerRoutes.CalCaleManiList
  };

  static CalCaleManiAddToEvent: EntityManagerInfo = {
    id: 'CalCaleManiAddToEvent', name: 'calcaleevento', translateSuffixs: ['generic.', 'calcaleevento.', 'calcalemani.'],
    entityType: EMPTYEMC,
  };

  static CalCaleManiAnomList: EntityManagerInfo = {
    id: 'CalCaleManiAnomList', name: 'calcalemanianom', translateSuffixs: ['generic.', 'calcalemanianom.'],
    entityType: EMPTYEMC,
    url: 'calcalemanianomlist', parent: AppManagerRoutes.Calendario
  };

  static CalCaleManiAnomView: EntityManagerInfo = {
    id: 'CalCaleManiAnomView', name: 'calcalemanianomview', translateSuffixs: ['generic.', 'calcalemanianom.', 'calcalemanianomview.'],
    entityType: EMPTYEMC,
    url: 'calcalemanianomview', parent: AppManagerRoutes.Calendario
  };

  // static CalCaleManiAnomEdit: EntityManagerInfo = {
  //   id: 'CalCaleManiAnomEdit', name: 'calcalemanianom', translateSuffixs: ['generic.', 'calcalemanianom.'],
  //   entityType: EMPTYEMC,
  //   url: 'calcalemanianomedit', parent: AppManagerRoutes.CalCaleManiAnomList
  // };

  static CalCaleManiAnomVarieList: EntityManagerInfo = {
    id: 'CalCaleManiAnomVarieList', name: 'calcalemanianomvarielist', translateSuffixs: ['generic.', 'calcalemanianomvarie.'],
    entityType: EMPTYEMC,
    url: 'calcalemanianomvarielist', parent: AppManagerRoutes.Calendario
  };

  static CalCaleManiWizard: EntityManagerInfo = {
    id: 'CalCaleManiWizard', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemaniwizard.', 'calcalemani.', 'calcalemanisp.', 'calcalemanirazze.', 'calcalemanicomi.', 'calcalemanipare.', 'calcalemanialle.', 'calcalemanianomalie.', 'calcalemaniwizardanom.', 'calcalemani.', 'calcalemaniwizardstatomani.'],
    entityType: EMPTYEMC,
    url: 'calcalemaniwizard', parent: AppManagerRoutes.Calendario
  };

  static CalCaleManiVerificaList: EntityManagerInfo = {
    id: 'CalCaleManiVerificaList', name: 'calcalemaniverifica', translateSuffixs: ['generic.', 'calcalemaniverifica.'],
    entityType: EMPTYEMC,
    url: 'calcalemaniverificalist', parent: AppManagerRoutes.Calendario
  };
  static GiudImpegnoList: EntityManagerInfo = {
    id: 'GiudImpegnoList', name: 'giudimpegno', translateSuffixs: ['generic.', 'giudimpegno.'],
    entityType: EMPTYEMC,
    url: 'giudimpegnolist', parent: AppManagerRoutes.CalCaleUtils
  };

  static CalCaleGenPrint: EntityManagerInfo = {
    id: 'CalCaleGenPrint', name: 'calcalegenprint', translateSuffixs: ['generic.', 'calcalegenprint.'],
    entityType: EMPTYEMC,
    url: 'calcalegenprint', parent: AppManagerRoutes.CalCaleUtils
  };

  // CalCaleEvento
  static CalCaleEventoList: EntityManagerInfo = {
    id: 'CalCaleEventoList', name: 'calcaleevento', translateSuffixs: ['generic.', 'calcaleevento.'],
    entityType: EMPTYEMC,
    url: 'calcaleeventolist', parent: AppManagerRoutes.Calendario
  };

  static CalCaleEventoEdit: EntityManagerInfo = {
    id: 'CalCaleEventoEdit', name: 'calcaleevento', translateSuffixs: ['generic.', 'calcaleevento.'],
    entityType: EMPTYEMC,
    url: 'calcaleeventoedit', parent: AppManagerRoutes.CalCaleEventoList
  };

  static CalCaleEventoHeader: EntityManagerInfo = {
    id: 'CalCaleEventoHeader', name: 'calcaleevento', translateSuffixs: ['generic.', 'calcaleevento.'],
    entityType: EMPTYEMC
  };

  static CalCaleEventoSettings: EntityManagerInfo = {
    id: 'CalCaleEventoSettings', name: 'calcaleeventosettings', translateSuffixs: ['generic.', 'calcaleevento.'],
    entityType: EMPTYEMC,
    entityServices: [{ name: 'calcaleevento', serviceName: 'restCalendario' }],
    url: 'calcaleeventosettings', parent: AppManagerRoutes.CalCaleEventoList
  };

  // Tabs CalCaleEventoEdit
  static CalCaleEventoEditRespFisc: EntityManagerInfo = {
    id: 'CalCaleEventoEditRespFisc', name: 'calcaleeventorespfisc', translateSuffixs: ['generic.', 'calcaleeventorespfisc.'],
    entityType: EMPTYEMC
  };
  static CalCaleEventoEditComi: EntityManagerInfo = {
    id: 'CalCaleEventoEditComi', name: 'calcaleeventocomi', translateSuffixs: ['generic.', 'calcaleeventocomi.'],
    entityType: EMPTYEMC
  };
  static CalCaleManiListTabComponent: EntityManagerInfo = {
    id: 'CalCaleManiListTabComponent', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemanilisttab.'],
    entityType: EMPTYEMC
  };
  static CalCaleManiRaggEventoComponent: EntityManagerInfo = {
    id: 'CalCaleManiRaggEventoComponent', name: 'calcalemaniragg', translateSuffixs: ['generic.', 'calcalemaniraggevento.', 'calcaleevento.'],
    entityType: EMPTYEMC
  };
  static CalCaleManiConteggiGiudiziComponent: EntityManagerInfo = {
    id: 'CalCaleManiConteggiGiudiziComponent', name: 'calcalemaniconteggigiudizi', translateSuffixs: ['generic.', 'calcalemaniconteggigiudizi.'],
    entityType: EMPTYEMC
  };
  static CalCaleEventoGiudSupreme: EntityManagerInfo = {
    id: 'CalCaleEventoGiudSupreme', name: 'calcaleeventogiudsupreme', translateSuffixs: ['generic.', 'calcaleeventogiudsupreme.'],
    entityType: EMPTYEMC
  };
  static CalCaleEventoDele: EntityManagerInfo = {
    id: 'CalCaleEventoDele', name: 'calcaleeventodele', translateSuffixs: ['generic.', 'calcaleeventodele.'],
    entityType: EMPTYEMC
  };

  static CalCaleEventoNew: EntityManagerInfo = {
    id: 'CalCaleEventoNew', name: 'calcaleevento', translateSuffixs: ['generic.', 'calcaleevento.'],
    entityType: EMPTYEMC,
    url: 'calcaleeventonew', parent: AppManagerRoutes.CalCaleEventoList
  };

  static CalCaleEventoDetail: EntityManagerInfo = {
    id: 'CalCaleEventoDetail', name: 'calcaleevento', translateSuffixs: ['generic.', 'calcalemanilisttab.', 'calcaleevento.'],
    entityType: EMPTYEMC,
    url: 'calcaleeventodetail', parent: AppManagerRoutes.CalCaleEventoList
  };

  static CalCaleEventoAddToMani: EntityManagerInfo = {
    id: 'CalCaleEventoAddToMani', name: 'calcalemani', translateSuffixs: ['generic.', 'calcalemani.', 'calcaleevento.', 'calcaleeventoaddtomani.', 'calcalemanicomi.'],
    entityType: EMPTYEMC,
  };

  // Zeta
  static Zeta: EntityManagerInfo = {
    id: 'Zeta', name: 'zeta', translateSuffixs: ['generic.'],
    entityType: ZetaEMC,
    url: 'zeta', parent: AppManagerRoutes.Home
  };

  // Normalizzazione
  static Normalizzazione: EntityManagerInfo = {
    id: 'Normalizzazione', name: 'normalizzazione', translateSuffixs: ['generic.', 'menu.'],
    entityType: NormalizzazioneEMC,
    url: 'normalizzazione', parent: AppManagerRoutes.Home
  };

  static NormSearchList: EntityManagerInfo = {
    id: 'NormSearchList', name: 'normsearch', translateSuffixs: ['generic.'],
    entityType: EMPTYEMC,
    url: 'normsearchlist', parent: AppManagerRoutes.Normalizzazione
  };

  static NormLogList: EntityManagerInfo = {
    id: 'NormLogList', name: 'normlog', translateSuffixs: ['generic.'],
    entityType: EMPTYEMC,
    url: 'normloglist', parent: AppManagerRoutes.Normalizzazione
  };

  static NormProposteList: EntityManagerInfo = {
    id: 'NormProposteList', name: 'normpropostelist', translateSuffixs: ['generic.'],
    entityType: EMPTYEMC,
    url: 'normpropostelist', parent: AppManagerRoutes.Normalizzazione
  };

  //Vux
  static VuxSociDeleGruppiList: EntityManagerInfo = {
    id: 'VuxSociDeleGruppiList', name: 'vuxsocidelegruppi', translateSuffixs: ['generic.', 'vuxsocidelegruppi.'],
    entityType: VuxSociDeleGruppiEMC,
    entityServices: [{ name: 'vuxsocidelegruppi', serviceName: 'restCommon' }],
    url: 'vuxsocidelegruppilist', parent: AppManagerRoutes.Zeta
  };
  static VuxSociDeleGruppiModal: EntityManagerInfo = {
    id: 'VuxSociDeleGruppiModal', name: 'vuxsocidelegruppi', translateSuffixs: ['generic.', 'vuxsocidelegruppi.'],
    entityType: VuxSociDeleGruppiModalEMC,
    entityServices: [{ name: 'vuxsocidelegruppi', serviceName: 'restCommon' }]
  };

  static VuxRazzeVarietaList: EntityManagerInfo = {
    id: 'VuxRazzeVarietaList', name: 'vuxrazzevarieta', translateSuffixs: ['generic.', 'vuxrazzevarieta.'],
    entityType: VuxRazzeVarietaEMC,
    entityServices: [{ name: 'vuxrazzevarieta', serviceName: 'restCommon' }],
    url: 'vuxrazzevarietalist', parent: AppManagerRoutes.Zeta
  };

  static ZetaUtenteList: EntityManagerInfo = {
    id: 'ZetaUtenteList', name: 'secutente', translateSuffixs: ['generic.'],
    entityType: SecUtenteEMC,
    entityServices: [{ name: 'secutente', serviceName: 'restCommon' }],
    url: 'zetautentelist', parent: AppManagerRoutes.Zeta
  };
  static ZetaUtenteDetail: EntityManagerInfo = cloneObject(
    AppManagerRoutes.ZetaUtenteList,
    { id: 'ZetaUtenteDetail', url: 'zetautentedetail', parent: AppManagerRoutes.ZetaUtenteList }
  );

  static ZetaWindowTab: EntityManagerInfo = {
    id: 'ZetaWindowTab', name: 'zetawindowtab', translateSuffixs: ['generic.'],
    entityType: ZetaWindowTabEMC,
    // entityServices: [{ name: 'zetawindowtab', serviceName: 'restCommon' }],
    url: 'zetawindowtab', parent: AppManagerRoutes.Zeta
  };

  // Giudici
  static Giudici: EntityManagerInfo = {
    id: 'Giudici', name: 'giudgiudice', translateSuffixs: ['generic.', 'menu.'],
    entityType: GiudiciEMC,
    url: 'giudici', parent: AppManagerRoutes.Home
  };

  static GiudList: EntityManagerInfo = {
    id: 'GiudList', name: 'giudgiudice', translateSuffixs: ['generic.', 'giudici.'],
    entityType: EMPTYEMC,
    url: 'giudlist', parent: AppManagerRoutes.Giudici
  };



  static GiudPrvItaList: EntityManagerInfo = {
    id: 'GiudPrvItaList', name: 'giudgiudice', translateSuffixs: ['generic.', 'giudici.'],
    entityType: EMPTYEMC,
    url: 'giudprvitalist', parent: AppManagerRoutes.Giudici, queryParams: { tipoCal: TipoCalCod.prove, nazionalita: 'ITA' }
  };

  static GiudPrvStrList: EntityManagerInfo = {
    id: 'GiudPrvStrList', name: 'giudgiudice', translateSuffixs: ['generic.', 'giudici.'],
    entityType: EMPTYEMC,
    url: 'giudprvstrlist', parent: AppManagerRoutes.Giudici, queryParams: { tipoCal: TipoCalCod.prove, nazionalita: 'STR' }
  };

  static GiudExpItaList: EntityManagerInfo = {
    id: 'GiudExpItaList', name: 'giudgiudice', translateSuffixs: ['generic.', 'giudici.'],
    entityType: EMPTYEMC,
    url: 'giudexpitalist', parent: AppManagerRoutes.Giudici, queryParams: { tipoCal: TipoCalCod.esposizioni, nazionalita: 'ITA' }
  };

  static GiudAmpliamentiExpList: EntityManagerInfo = {
    id: 'GiudAmpliamentiExpList', name: 'giudgiudice', translateSuffixs: ['generic.', 'giudici.'],
    entityType: EMPTYEMC,
    url: 'giudampliamentiexplist', parent: AppManagerRoutes.Giudici, queryParams: { tipoCal: TipoCalCod.esposizioni, richiesteAmpliamentioExp: '1' }
  };

  static GiudAmpliamentiPrvList: EntityManagerInfo = {
    id: 'GiudAmpliamentiPrvList', name: 'giudgiudice', translateSuffixs: ['generic.', 'giudici.'],
    entityType: EMPTYEMC,
    url: 'giudampliamentiprvlist', parent: AppManagerRoutes.Giudici, queryParams: { tipoCal: TipoCalCod.prove, richiesteAmpliamentioPrv: '1' }
  };

  static GiudExpStrList: EntityManagerInfo = {
    id: 'GiudExpStrList', name: 'giudgiudice', translateSuffixs: ['generic.', 'giudici.'],
    entityType: EMPTYEMC,
    url: 'giudexpstrlist', parent: AppManagerRoutes.Giudici, queryParams: { tipoCal: TipoCalCod.esposizioni, nazionalita: 'STR' }
  };

  static GiudDetail: EntityManagerInfo = {
    id: 'GiudiceDetail', name: 'giudgiudice', translateSuffixs: ['generic.', 'giudici.', 'giudrichiesta.'],
    entityType: EMPTYEMC,
    url: 'giuddetail', parent: AppManagerRoutes.GiudList
  };

  static GiudGiudiceDetail: EntityManagerInfo = {
    id: 'GiudGiudiceDetail', name: 'giudgiudice', translateSuffixs: ['generic.', 'giudici.'],
    entityType: EMPTYEMC,
    url: 'giudgiudicedetail', parent: AppManagerRoutes.Giudici
  };

  static GiudCorsi: EntityManagerInfo = {
    id: 'GiudCorsi', name: 'giudcorsi', translateSuffixs: ['generic.', 'giudicicorsi.', 'gestionecorsi.', 'educorso.'],
    entityType: EMPTYEMC
  };

  static GiudSituazioneCorsiList: EntityManagerInfo = {
    id: 'GiudSituazioneCorsiList', name: 'giudicicorsi', translateSuffixs: ['generic.', 'giudicicorsi.', 'giudici.'],
    entityType: EMPTYEMC,
    url: 'giudsituazionecorsilist', parent: AppManagerRoutes.Giudici
  };

  static GiudSettings: EntityManagerInfo = {
    id: 'GiudSettings', name: 'giudgiudice', translateSuffixs: ['generic.', 'giudici.', 'giudrichiesta.'],
    entityType: EMPTYEMC,
    url: 'giudsettings', parent: AppManagerRoutes.GiudList
  };

  static GiudContacts: EntityManagerInfo = {
    id: 'GiudContacts', name: 'giudgiudice', translateSuffixs: ['generic.', 'giudici.', 'anacontatto.'],
    entityType: EMPTYEMC,
    url: 'giudcontacts', parent: AppManagerRoutes.GiudList
  };

  static GiudNew: EntityManagerInfo = {
    id: 'GiudNew', name: 'giudgiudice', translateSuffixs: ['generic.', 'giudici.', 'anabase.', 'anabasedett', 'anabasenazione.', 'anabaselingua'],
    entityType: EMPTYEMC,
    url: 'giudnew', parent: AppManagerRoutes.GiudList
  };

  static GiudEdit: EntityManagerInfo = {
    id: 'GiudEdit', name: 'giudgiudice', translateSuffixs: ['generic.', 'giudici.', 'anabase.', 'anabasedett', 'anabasenazione.', 'anabaselingua', 'giudabilitazionenaz.'],
    entityType: EMPTYEMC,
    url: 'giudedit', parent: AppManagerRoutes.GiudList
  };

  // Sottosezioni giudici
  static GiudContatto: EntityManagerInfo = {
    id: 'GiudContatto', name: 'giudcontatto', translateSuffixs: ['generic.', 'anacontatto.'],
    entityType: EMPTYEMC
  };

  static GiudBloccoExp: EntityManagerInfo = {
    id: 'GiudBloccoExp', name: 'giudbloccoexp', translateSuffixs: ['generic.', 'giudiciblocchirazze.'],
    entityType: EMPTYEMC
  };

  static GiudBloccoPrv: EntityManagerInfo = {
    id: 'GiudBloccoPrv', name: 'giudbloccoprv', translateSuffixs: ['generic.', 'giudiciblocchiprv.'],
    entityType: EMPTYEMC
  };

  static GiudSospensione: EntityManagerInfo = {
    id: 'GiudSospensione', name: 'giudsospensione', translateSuffixs: ['generic.', 'giudicisospensioni.'],
    entityType: EMPTYEMC
  };

  static GiudImpegnoEstero: EntityManagerInfo = {
    id: 'GiudImpegnoEstero', name: 'giudimpegnoestero', translateSuffixs: ['generic.', 'giudiceimpegniesteri.'],
    entityType: EMPTYEMC
  };

  static GiudCertificato: EntityManagerInfo = {
    id: 'GiudCertificato', name: 'giudcertificato', translateSuffixs: ['generic.', 'giudicicertificati.'],
    entityType: EMPTYEMC
  };

  static GiudAbilitazione: EntityManagerInfo = {
    id: 'GiudAbilitazione', name: 'giudabilitazione', translateSuffixs: ['generic.', 'giudici.'],
    entityType: EMPTYEMC
  };

  static GiudImpegnoManiExp: EntityManagerInfo = {
    id: 'GiudImpegnoManiExp', name: 'giudimpegnomaniexp', translateSuffixs: ['generic.', 'giudiciimpegnimaniexp.'],
    entityType: EMPTYEMC
  };

  static GiudImpegnoManiExpRazza: EntityManagerInfo = {
    id: 'GiudImpegnoManiExpRazza', name: 'giudimpegnomaniexprazza', translateSuffixs: ['generic.', 'giudiciimpegnimaniexprazza.'],
    entityType: EMPTYEMC
  };

  static GiudImpegnoManiPrv: EntityManagerInfo = {
    id: 'GiudImpegnoManiPrv', name: 'giudimpegnomaniprv', translateSuffixs: ['generic.', 'giudiciimpegnimaniprv.'],
    entityType: EMPTYEMC
  };

  static GiudImpegnoManiPrvSp: EntityManagerInfo = {
    id: 'GiudImpegnoManiPrvSp', name: 'giudimpegnomaniprvsp', translateSuffixs: ['generic.', 'giudiciimpegnimaniprvsp.'],
    entityType: EMPTYEMC
  };


  // Profilo giudice
  static GiudiceProfilo: EntityManagerInfo = {
    id: 'GiudiceProfilo', name: 'giudgiudice', translateSuffixs: ['generic.', 'menu.'],
    entityType: GiudiceProfiloEMC,
    url: 'giudiceprofilo', parent: AppManagerRoutes.Home
  };

  static GiudiceProfiloDetail: EntityManagerInfo = {
    id: 'GiudiceProfiloDetail', name: 'giudgiudice', translateSuffixs: ['generic.', 'giudici.'],
    entityType: EMPTYEMC,
    url: 'giudiceprofilodetail', parent: AppManagerRoutes.GiudiceProfilo
  };

  static GiudRichiesteGiudice: EntityManagerInfo = {
    id: 'GiudRichiesteGiudice', name: 'giudrichiesta', translateSuffixs: ['generic.', 'giudrichiesta.'],
    entityType: EMPTYEMC,
    url: 'richieste', parent: AppManagerRoutes.GiudiceProfilo, prependParentOnUrl: true
  };

  static GiudAssistentatoGiudiceList: EntityManagerInfo = {
    id: 'GiudAssistentatoGiudiceList', name: 'giudassistentato', translateSuffixs: ['generic.', 'giudassistentato.'],
    entityType: EMPTYEMC,
    url: 'giudassistentatogiudicelist', parent: AppManagerRoutes.GiudiceProfilo
  };

  static GiudRichiestaGiudiceList: EntityManagerInfo = {
    id: 'GiudRichiestaGiudiceList', name: 'giudrichiesta', translateSuffixs: ['generic.', 'giudrichiesta.'],
    entityType: EMPTYEMC,
    url: 'giudrichiestagiudicelist', parent: AppManagerRoutes.GiudRichiesteGiudice
  };

  // Dettaglio giudice manifestazione
  static GiudiceManiDetail: EntityManagerInfo = {
    id: 'GiudiceManiDetail', name: 'giudicemanidetail', translateSuffixs: ['generic.', 'giudicemanidetail.'],
    entityType: EMPTYEMC
  };

  static GiudiziEsteriList: EntityManagerInfo = {
    id: 'GiudiziEsteriList', name: 'giudiziesteri', translateSuffixs: ['generic.', 'giudici.'],
    entityType: EMPTYEMC,
    url: 'giudiziesterilist', parent: AppManagerRoutes.Giudici
  };

  static GiudiziEsteriDetail: EntityManagerInfo = {
    id: 'GiudiziEsteriDetail', name: 'giudiziesteridetail', translateSuffixs: ['generic.', 'giudici.'],
    entityType: EMPTYEMC,
    url: 'giudiziesteridetail', parent: AppManagerRoutes.GiudiziEsteriList
  };

  static GenAllegatoList: EntityManagerInfo = {
    id: 'GenAllegatoList', name: 'genallegato', translateSuffixs: ['generic.', 'genallegato.', 'attachments.'],
    entityType: EMPTYEMC
  };

  // Richieste giudici
  static GiudRichieste: EntityManagerInfo = {
    id: 'GiudRichieste', name: 'giudrichiesta',
    entityType: EMPTYEMC,
    url: 'richieste', parent: AppManagerRoutes.Giudici, prependParentOnUrl: true
  };

  static GiudRichiestaList: EntityManagerInfo = {
    id: 'GiudRichiestaList', name: 'giudrichiesta', translateSuffixs: ['generic.', 'giudrichiesta.'],
    entityType: EMPTYEMC,
    url: 'giudrichiestalist', parent: AppManagerRoutes.GiudRichieste
  };
  static GiudRichiestaExpList: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GiudRichiestaList,
    { id: 'GiudRichiestaExpList', url: 'giudrichiestaexplist' }
  );
  static GiudRichiestaPrvList: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GiudRichiestaList,
    { id: 'GiudRichiestaPrvList', url: 'giudrichiestaprvlist' }
  );

  static GiudRichiestaNew: EntityManagerInfo = {
    id: 'GiudRichiestaNew', name: 'giudrichiesta', translateSuffixs: ['generic.', 'giudrichiesta.', 'giudrichiestawizard.'],
    entityType: EMPTYEMC,
    url: 'giudrichiestanew', parent: AppManagerRoutes.GiudRichiestaList
  };

  static GiudRichiestaDetail: EntityManagerInfo = {
    id: 'GiudRichiestaDetail', name: 'giudrichiesta', translateSuffixs: ['generic.', 'giudrichiesta.', 'giudassistentato.'],
    entityType: EMPTYEMC,
    url: 'giudrichiestadetail', parent: AppManagerRoutes.GiudRichiestaList
  };

  static GiudAssistentatoList: EntityManagerInfo = {
    id: 'GiudAssistentatoList', name: 'giudassistentato', translateSuffixs: ['generic.', 'giudassistentato.'],
    entityType: EMPTYEMC,
    url: 'giudassistentatolist', parent: AppManagerRoutes.GiudRichieste
  };

  static GiudAssistentatoGroupedList: EntityManagerInfo = {
    id: 'GiudAssistentatoGroupedList', name: 'giudassistentatogrouped', translateSuffixs: ['generic.', 'giudassistentato.'],
    entityType: EMPTYEMC,
    url: 'giudassistentatogroupedlist', parent: AppManagerRoutes.GiudRichieste
  };
  static GiudAssistentatoGroupedExpList: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GiudAssistentatoGroupedList,
    { id: 'GiudAssistentatoGroupedExpList', url: 'giudassistentatogroupedexplist' }
  );
  static GiudAssistentatoGroupedPrvList: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GiudAssistentatoGroupedList,
    { id: 'GiudAssistentatoGroupedPrvList', url: 'giudassistentatogroupedprvlist' }
  );

  static GiudAssistentatoEsameList: EntityManagerInfo = {
    id: 'GiudAssistentatoEsameList', name: 'giudassistentatoesamegrouped', translateSuffixs: ['generic.', 'giudassistentato.'],
    entityType: EMPTYEMC,
    url: 'giudassistentatoesamelist', parent: AppManagerRoutes.GiudRichieste
  };
  static GiudAssistentatoEsameExpList: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GiudAssistentatoEsameList,
    { id: 'GiudAssistentatoEsameExpList', url: 'giudassistentatoesameexplist' }
  );
  static GiudAssistentatoEsamePrvList: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GiudAssistentatoEsameList,
    { id: 'GiudAssistentatoEsamePrvList', url: 'giudassistentatoesameprvlist' }
  );

  static GiudAssistentatoEdit: EntityManagerInfo = {
    id: 'GiudAssistentatoEdit', name: 'giudassistentato', translateSuffixs: ['generic.', 'giudassistentato.'],
    entityType: EMPTYEMC,
    url: 'giudassistentatoedit', parent: AppManagerRoutes.GiudRichieste
  };

  static GiudAssistentatoAdd: EntityManagerInfo = {
    id: 'GiudAssistentatoAdd', name: 'giudassistentato', translateSuffixs: ['generic.', 'giudassistentato.'],
    entityType: EMPTYEMC,
    url: 'giudassistentatoadd', parent: AppManagerRoutes.GiudRichieste
  };

  static GiudAssistentatoNew: EntityManagerInfo = {
    id: 'GiudAssistentatoNew', name: 'giudassistentato', translateSuffixs: ['generic.', 'giudassistentato.', 'giudassistentatowizard.'],
    entityType: EMPTYEMC,
    url: 'giudassistentatonew', parent: AppManagerRoutes.GiudRichiestaDetail
  };

  // Richieste giudici

  static GiudEsami: EntityManagerInfo = {
    id: 'GiudEsami', name: 'giudesame',
    entityType: EMPTYEMC,
    url: 'esami', parent: AppManagerRoutes.Giudici, prependParentOnUrl: true
  };


  // Esami
  static GiudEsameSessioneList: EntityManagerInfo = {
    id: 'GiudEsameSessioneList', name: 'giudesamesessione', translateSuffixs: ['generic.', 'giudesamesessione.'],
    entityType: EMPTYEMC,
    url: 'giudesamesessionelist', parent: AppManagerRoutes.GiudEsami
  };
  static GiudEsameSessioneSettings: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GiudEsameSessioneList,
    { id: 'GiudEsameSessioneSettings', translateSuffixs: ['generic.', 'giudesamesessione.', 'giudesamecandidato.'], url: 'giudesamesessionesettings' }
  );
  static GiudEsameSessioneAdd: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GiudEsameSessioneList,
    { id: 'GiudEsameSessioneAdd', url: 'giudesamesessioneadd' }
  );

  static GiudEsameCandidatoList: EntityManagerInfo = {
    id: 'GiudEsameCandidatoList', name: 'giudesamecandidato', translateSuffixs: ['generic.', 'giudesamecandidato.'],
    entityType: EMPTYEMC,
    url: 'giudesamecandidatolist', parent: AppManagerRoutes.GiudEsami
  };
  static GiudEsameCandidatoEdit: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GiudEsameCandidatoList,
    { id: 'GiudEsameCandidatoEdit', url: 'giudesamecandidatoedit' }
  );
  static GiudEsameCandidatoAbilitazione: EntityManagerInfo = cloneObject(
    AppManagerRoutes.GiudEsameCandidatoList,
    { id: 'GiudEsameCandidatoAbilitazione', translateSuffixs: ['generic.', 'giudesamecandidato.', 'giudici'], url: 'giudesamecandidatoabilitazione' }
  );

  // Profilo Utente
  static ProfiloUtente: EntityManagerInfo = {
    id: 'ProfiloUtente', name: 'profiloutente', translateSuffixs: ['generic.', 'menu.'],
    entityType: PofiloUtenteEMC,
    url: 'profiloutente', parent: AppManagerRoutes.Home
  };

  static GenRilascioList: EntityManagerInfo = {
    id: 'GenRilascioList', name: 'genrilascio', translateSuffixs: ['generic.', 'genrilascio.'],
    entityType: EMPTYEMC,
    url: 'genrilasciolist', parent: AppManagerRoutes.ProfiloUtente
  };

  // Formazione
  static Formazione: EntityManagerInfo = {
    id: 'Formazione', name: 'formazione', translateSuffixs: ['generic.', 'menu.'],
    entityType: EMPTYEMC,
    url: 'formazione', parent: AppManagerRoutes.Home
  };

  static FormazioneEventoList: EntityManagerInfo = {
    id: 'FormazioneEventoList', name: 'formazioneevento', translateSuffixs: ['generic.'],
    entityType: EMPTYEMC,
    url: 'formazioneeventolist', parent: AppManagerRoutes.Normalizzazione
  };

  static FormazioneEventoEdit: EntityManagerInfo = {
    id: 'FormazioneEventoEdit', name: 'calcalemani', translateSuffixs: ['generic.', 'formazione.', 'formazioneevento.', 'calcaleevento.'],
    entityType: EMPTYEMC,
    url: 'formazioneeventoedit', parent: AppManagerRoutes.CalCaleManiList
  };

  // Pagine di supporto
  static UnauthorizedUser: EntityManagerInfo = {
    id: 'UnauthorizedUser', name: 'unauthorizeduser', translateSuffixs: ['generic.'],
    entityType: EMPTYEMC,
    url: 'unauthorizeduser'
  };

  // Log Eventi
  static GenLogEventoList: EntityManagerInfo = {
    id: 'GenLogEventoList', name: 'genlogevento', translateSuffixs: ['generic.', 'genlogevento.'],
    entityType: EMPTYEMC
  };

  // Approvazione abilitazioni
  static GiudAbilitazioneExpApprovazioneList: EntityManagerInfo = {
    id: 'GiudAbilitazioneExpApprovazioneList', name: 'giudabilitazioneexpapprovazione', translateSuffixs: ['generic.', 'giudabilitazioneapprovazione.'],
    entityType: EMPTYEMC,
    url: 'giudabilitazioneexpapprovazionelist', parent: AppManagerRoutes.Giudici
  };

  static GiudAbilitazionePrvApprovazioneList: EntityManagerInfo = {
    id: 'GiudAbilitazionePrvApprovazioneList', name: 'giudabilitazioneprvapprovazione', translateSuffixs: ['generic.', 'giudabilitazioneapprovazione.'],
    entityType: EMPTYEMC,
    url: 'giudabilitazioneprvapprovazionelist', parent: AppManagerRoutes.Giudici
  };

  // Edu Corsi Giudici
  static EduCorsi: EntityManagerInfo = {
    id: 'EduCorsi', name: 'educorso',
    entityType: EMPTYEMC,
    url: 'corsi', parent: AppManagerRoutes.Giudici, prependParentOnUrl: true
  };

  static EduCorsoList: EntityManagerInfo = {
    id: 'EduCorsoList', name: 'educorso', translateSuffixs: ['generic.', 'educorso.'],
    entityType: EMPTYEMC,
    url: 'educorsolist', parent: AppManagerRoutes.EduCorsi
  };

  static EduCorsoSettings: EntityManagerInfo = {
    id: 'EduCorsoSettings', name: 'educorso', translateSuffixs: ['generic.', 'educorso.'],
    entityType: EMPTYEMC,
    url: 'educorsosettings', parent: AppManagerRoutes.EduCorsoList
  };

  static EduCorsoNew: EntityManagerInfo = {
    id: 'EduCorsoNew', name: 'educorso', translateSuffixs: ['generic.', 'educorso.'],
    entityType: EMPTYEMC,
    url: 'educorsonew', parent: AppManagerRoutes.EduCorsoList
  };

  static EduCorsoEdit: EntityManagerInfo = {
    id: 'EduCorsoEdit', name: 'educorso', translateSuffixs: ['generic.', 'educorso.'],
    entityType: EMPTYEMC,
    url: 'educorsoedit', parent: AppManagerRoutes.EduCorsoList
  };

  // Manuali
  static Manuali: EntityManagerInfo = {
    id: 'Manuali', name: 'manuali', translateSuffixs: ['generic.'],
    entityType: ManualiEMC,
    url: 'manuali', parent: AppManagerRoutes.Home
  };

  // Fatturazione
  static Fatture: EntityManagerInfo = {
    id: 'Fatture', name: 'fatture', translateSuffixs: ['generic.', 'menu.'],
    entityType: FattureEMC,
    url: 'fatture', parent: AppManagerRoutes.Home
  };
  static FattFatturaList: EntityManagerInfo = {
    id: 'FattFatturaList', name: 'fattfattura', translateSuffixs: ['generic.', 'fattfattura.'],
    entityType: EMPTYEMC,
    url: 'fattfatturalist', parent: AppManagerRoutes.Fatture
  };

  // private static _entityManagerInfoList: {[key: string]: EntityManagerInfo};
  // static get entityManagerInfoList()
  // {
  //   if(!this._entityManagerInfoList)
  //   {
  //     const keys = Object.keys(AppManagerRoutes);
  //     this._entityManagerInfoList = {};
  //     keys.forEach(key => {
  //       if('_entityManagerInfoList' != key && 'entityManagerInfoList' != key){
  //         this._entityManagerInfoList[key] = AppManagerRoutes[key];
  //       }
  //     });
  //   }
  //   return this._entityManagerInfoList;
  // }
}
