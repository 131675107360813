<ng-container *ngIf="T$ | async as T">
  <div class="flex flex-column h-full">
    <div>
      <div class="flex flex-row justify-content-between">
        <p class="text-header">{{T | translateItem: 'gennotificawizard.windowHeader'}}</p>
        <div class="buttons-cnt">
          <button pButton type="button" (click)="wizardBack($event)"
            [icon]="wizardLoading ? 'fa-solid fa-circle-notch fa-spin' : 'fa-solid fa-chevron-left'"
            [disabled]="wizardLoading || wizardNotificationSended" [label]="T | translateItem: 'generic.back'"
            class="p-button-outlined p-button-sm">
          </button>

          <button pButton iconPos="right" type="button" (click)="wizardNext($event)" *ngIf="wizardActiveIndex < 3"
            [icon]="wizardLoading ? 'fa-solid fa-circle-notch fa-spin' : 'fa-solid fa-chevron-right'"
            [disabled]="!wizardCanGoNext" [label]="T | translateItem: 'generic.next'"
            class="p-button-primary p-button-sm">
          </button>

          <button pButton iconPos="right" type="button" (click)="wizardSend($event)" *ngIf="wizardActiveIndex === 3"
            [icon]="wizardLoading ? 'fa-solid fa-circle-notch fa-spin' : 'fa-solid fa-paper-plane'"
            [label]="T | translateItem: 'generic.send'" class="p-button-primary p-button-sm"
            [disabled]="wizardNotificationSended">
          </button>

          <button pButton type="button" class="p-button-outlined p-button-sm" (click)="help()"
            icon="fa-solid fa-circle-question" [title]="T | translateItem: 'generic.help'">
          </button>
        </div>
      </div>
      <p-divider></p-divider>
    </div>
    <div class="flex-grow-1 overflow-y-auto align-content-start page-detail-container">
      <gennotifica-wizard (wizardState)="onWizardStateChange($event)" [autostart]="true">
      </gennotifica-wizard>
    </div>
  </div>
</ng-container>