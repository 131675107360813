import { Component } from '@angular/core';
import { SortMeta } from 'primeng/api';
import { filter, takeUntil } from 'rxjs';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { ComparisonOperator, SearchFieldType, SortMode } from 'src/app/models/data-search';
import { GiudAssistentato } from 'src/app/models/giudici/giud-assistentato';
import { GiudAssistentatoListBaseComponent } from './giud-assistentato-list-base.component';

@Component({
  selector: 'app-giud-assistentato-list',
  templateUrl: './giud-assistentato-list.component.html',
  styleUrls: ['./giud-assistentato-list.component.scss']
})
export class GiudAssistentatoListComponent extends GiudAssistentatoListBaseComponent<GiudAssistentato> {
  // Sorting
  defaultMultiSortMeta: SortMeta[] = [
    { field: 'id', order: SortMode.Asc }
  ];

  // Filters
  searchFieldsConf = [
    // Simple
    { key: 'richiestaId', searchFields: 'richiestaId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: false },
    { key: 'caleManiId', searchFields: 'caleManiId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: false },
    { key: 'giudiceId', searchFields: 'giudiceId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: false },
    { key: 'simpleSearch', searchFields: 'id,giudiceRichiedenteNome,giudiceRichiedenteCognome,giudiceRichiedenteNomeCompleto,richiestaDetailLabel,caleManiLabel,giudiceNome,giudiceCognome,giudiceNomeCompleto,esitoAssistentatoDes,statoAssistentatoDes', type: SearchFieldType.multiField, searchCompOp: ComparisonOperator.Contains, advancedSearch: false },
    // Advanced
    { key: 'richiestaId', searchFields: 'richiestaId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'caleManiId', searchFields: 'caleManiId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'giudiceId', searchFields: 'giudiceId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'id', searchFields: 'id', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'giudiceRichiedenteNome', searchFields: 'giudiceRichiedenteNome', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
    { key: 'giudiceRichiedenteCognome', searchFields: 'giudiceRichiedenteCognome', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
    { key: 'gruppoFciId', searchFields: 'gruppoFciId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true },
    { key: 'razzaId', searchFields: 'razzaId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true },
    { key: 'tipoManiId', searchFields: 'tipoManiId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true },
    { key: 'caleManiLabel', searchFields: 'caleManiLabel', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
    { key: 'codProtipo', searchFields: 'codProtipo', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
    { key: 'giudiceNome', searchFields: 'giudiceNome', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
    { key: 'giudiceCognome', searchFields: 'giudiceCognome', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
    { key: 'esitoAssistentatoId', searchFields: 'esitoAssistentatoId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true },
    { key: 'statoAssistentatoId', searchFields: 'statoAssistentatoId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true }
  ];

  titleMap: { [key: string]: string } = {
    [AppManagerRoutes.GiudAssistentatoGiudiceList.id]: 'giudassistentato.windowListHeaderGiudice',
    'other': 'giudassistentato.windowListHeader'
  };

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.entityServiceUrl = AppManagerRoutes.GiudAssistentatoList.name;

    super.ngOnInit();

    // Init filters
    this.initFilters();

    // Capisco in quale route mi trovo
    switch (this.entityManager.id) {
      case AppManagerRoutes.GiudRichiestaDetail.id:
        this.persistFilters = false;

        this.defaultFilters = {
          richiestaId: this.richiestaId
        };

        this.baseWindowListService.setDefaultFilters(this.defaultFilters);

        this.appManagerService.utente$.pipe(
          takeUntil(this.destroy$)
        ).subscribe(
          (utente) => {
            this.utente = utente;
            this.giudiceId = utente.livelloProfiloCod === 'GIUDICE' ? utente.enciIdAnag : this.giudiceId;
          }
        );
        break;

      case AppManagerRoutes.GiudAssistentatoGiudiceList.id:
        this.appManagerService.utente$.pipe(
          takeUntil(this.destroy$),
          filter((utente) => utente.livelloProfiloCod === 'GIUDICE'),
        ).subscribe(
          (utente) => {
            this.utente = utente;
            this.giudiceId = utente.enciIdAnag;
            this.defaultFilters = { giudiceId: utente.enciIdAnag };

            this.baseWindowListService.setDefaultFilters(this.defaultFilters);
          }
        );
        break;

      case AppManagerRoutes.CalCaleManiSettings.id:
        this.defaultFilters = {
          caleManiId: this.caleManiId
        };

        this.baseWindowListService.setDefaultFilters(this.defaultFilters);

        this.appManagerService.utente$.pipe(
          takeUntil(this.destroy$)
        ).subscribe(
          (utente) => this.utente = utente
        );
        break;
    }
  }
}
