import { AppManagerRoutes } from "../../app-manager-routes";
import { CmdField, CmdFieldKey, CmdFieldMode, CmdFieldTarget, EntityManagerConfig } from "../entity-config";

export class CalendarioUtilsEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleGenPrint, label: 'menu.calcalegenprint.label', title: 'menu.calcalegenprint.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudImpegnoList, label: 'menu.giudimpegnolist.label', title: 'menu.giudimpegnolist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleManiLogList, label: 'menu.calcalemaniloglist.label', title: 'menu.calcalemaniloglist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleManiLogPrvList, label: 'menu.calcalemanilogprvlist.label', title: 'menu.calcalemanilogprvlist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleManiLogExpList, label: 'menu.calcalemanilogexplist.label', title: 'menu.calcalemanilogexplist.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCaleManiRisultatiGlobList, label: 'menu.calcalemanirisultatigloblist.label', title: 'menu.calcalemanirisultatigloblist.title'
      }
    ];
  }
}
