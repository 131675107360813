import { HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree } from "@angular/router";
import { MessageService } from "primeng/api";
import { BehaviorSubject, Observable, ReplaySubject, throwError } from "rxjs";
import { catchError, filter, map, take } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AppManagerRoutes } from "../app-manager-routes";
import { IEntity } from "../models/entity";
import { EntityManager, EntityManagerGrant, EntityManagerInfo, IEntityManagerConfig } from "../models/entity-config";
import { MessageData, ToastMessageData } from "../models/message";
import { SecUtente } from "../models/security/sec-utente";
import { DtoUtente, DtoUtenteGrant } from "../models/utente";
import { buildDataFromActivatedRoute, isNullOrUndefined, mergeObject } from "../utils/util";
import { DataService } from "./data.service";
import { EntityService, EntityServiceInfo, IEntityService } from "./entity.service";
import { TranslateService } from "./translate.service";

// export const SESSION_STORAGE_NAV_PREFIX = 'encinav_';
export const SESSION_STORAGE_NAV = 'encin';
export const SESSION_STORAGE_NAV_TAB = 'encint';
export const SESSION_STORAGE_GLOBAL = 'encig';
export const SESSION_STORAGE_SELECTION = 'encis';

export interface AppInitialized {
  initialized: boolean;
  dtoUtente: DtoUtente;
  caller: 'init' | 'profileChange';
  // i18n: {language: string, date: any, currency: any, decimal: any, percent: any};
}

export interface PathItem {
  url: string;
  entityManagerInfo: EntityManagerInfo;
}

export enum StorageType {
  Local,
  Session
}

/**
 * Main application service
 */
@Injectable({
  providedIn: 'root'
})
export class AppManagerService {

  protected entityManagerBrowserWindows: { [key: string]: { entityManager: EntityManager, window: Window } } = {};
  protected dtoUtente: DtoUtente;
  private navigationList: PathItem[] = [];
  private pathListSubject: ReplaySubject<string[]>;
  private appInitSubject: ReplaySubject<AppInitialized>;
  private messageDataSubject: BehaviorSubject<MessageData>;
  private appDisposeSubject: BehaviorSubject<any>;


  // private defaultFilters: Object;
  // private searchFilters: Object;
  // private searchFieldsConf: SearchFieldsConf[];
  // private advancedSearch: boolean;

  // private dataSearch: DataSearch;

  // private _dataSearch$: ReplaySubject<DataSearch> = new ReplaySubject(1);
  // dataSearch$: Observable<DataSearch> = this._dataSearch$.asObservable();

  // setDefaultFilter(dataFilter: Object, searchFieldsConf: SearchFieldsConf[]) {
  //   this.defaultFilters = dataFilter;
  //   this.searchFilters = dataFilter;
  //   this.searchFieldsConf = searchFieldsConf;

  //   this.dataSearch = new DataSearch([], [], 0, 10);

  //   this.dataSearch = buildDataSearch(dataFilter, this.searchFieldsConf, false, this.dataSearch.dataSortValues, this.dataSearch.pageFirst, this.dataSearch.pageSize);

  //   this._dataSearch$.next(this.dataSearch);
  // }

  // setSearchFilters(searchFilter: Object) {
  //   let filter: Object;
  //   if (!searchFilter) {
  //     filter = this.defaultFilters;
  //   } else {
  //     filter = { ...searchFilter, ...this.defaultFilters };
  //   }
  //   this.searchFilters = filter;

  //   this.dataSearch = buildDataSearch(filter, this.searchFieldsConf, this.advancedSearch, this.dataSearch.dataSortValues, this.dataSearch.pageFirst, this.dataSearch.pageSize);
  //   this._dataSearch$.next(this.dataSearch);
  // }

  // setPage(page: number, pageSize: number) {
  //   this.dataSearch = buildDataSearch(this.searchFilters, this.searchFieldsConf, this.advancedSearch, this.dataSearch.dataSortValues, page, pageSize);
  //   this._dataSearch$.next(this.dataSearch);
  // }

  // setAdvancedSearch(advancedSearch: boolean) {
  //   this.advancedSearch = advancedSearch;
  // }

  // getDefaultFilters(): Object {
  //   return this.defaultFilters;
  // }

  // getSearchFilter(): Object {
  //   return this.searchFilters;
  // }

  // resetSearch() {
  //   this.dataSearch = new DataSearch([], [], 0, 10);

  //   this.searchFilters = this.defaultFilters;

  //   this.dataSearch = buildDataSearch(this.defaultFilters, this.searchFieldsConf, this.advancedSearch, this.dataSearch.dataSortValues, this.dataSearch.pageFirst, this.dataSearch.pageSize);
  // }

  constructor(
    private router: Router,
    private dataService: DataService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
    this.appInitSubject = new ReplaySubject<AppInitialized>(1);
    this.pathListSubject = new ReplaySubject<string[]>(1);
    this.messageDataSubject = new BehaviorSubject<MessageData>(undefined);
    this.appDisposeSubject = new BehaviorSubject<MessageData>(undefined);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.urlAfterRedirects;
        const segments: UrlSegment[] = this.getSegments(currentUrl);
        const entityManagerInfoList = AppManagerRoutes.getEntityManagerInfoListByUrl(event.urlAfterRedirects);
        const currentEntityManagerInfo = entityManagerInfoList?.[entityManagerInfoList.length - 1];

        // console.log('A: ' + this.navigationList.map(t => `${t.entityManagerInfo.id} => ${t.url}\n` )); // JSON.stringify(this.navigationList)

        let idx = isNullOrUndefined(currentEntityManagerInfo)
          ? -1
          : this.navigationList.findIndex(t => t.entityManagerInfo.parent?.id === currentEntityManagerInfo.parent?.id);
        if (idx == -1) {
          idx = 0;
          this.navigationList = [];
        }
        else {
          this.navigationList.splice(idx, this.navigationList.length - idx);
        }

        let url = '';
        for (let i = idx; i < segments.length; i++) {
          url = `${url}/${segments[i].path}`;
          const emi = entityManagerInfoList.find(t => t.url === segments[i].path);
          if (emi) {
            this.navigationList.push({ url: url, entityManagerInfo: emi });
          }
        }

        this.pathListSubject.next(this.navigationList.map((u) => { return u.url }));

        // console.log('B: ' + this.navigationList.map(t => `${t.entityManagerInfo.id} => ${t.url}\n` ));

      }
    });
  }

  dispose() {
    this.appDisposeSubject.next({ disposed: true });
    this.clearBrowserTab();

    if (this.pathListSubject) {
      this.pathListSubject.unsubscribe();
    }

    if (this.messageDataSubject) {
      this.messageDataSubject.unsubscribe();
    }

    if (this.appInitSubject) {
      this.appInitSubject.unsubscribe();
    }

    if (this.appDisposeSubject) {
      this.appDisposeSubject.unsubscribe();
    }
  }

  /** Initalized application listener */
  get initialized$(): Observable<AppInitialized> {
    return this.appInitSubject.asObservable();
  }

  get disposed$(): Observable<any> {
    return this.appDisposeSubject.asObservable();
  }

  /** route (path list) changed listener */
  get pathList$(): Observable<string[]> {
    return this.pathListSubject.asObservable();
  }

  /** MessageData showed listener */
  get messageData$(): Observable<MessageData> {
    return this.messageDataSubject.asObservable();
  }

  get utente$(): Observable<SecUtente> {
    return this.initialized$.pipe(
      filter((data) => data.initialized),
      map((data) => Object.assign(new SecUtente, data.dtoUtente.utente))
    );
  }

  /**
   * Return grants of section as Observable of EntityManagerGrant.
   * This is asynchronous, so it is called within initialized$
   *
   * @param section section to which the grants belong
   * @returns an observable of EntityManagerGrant that is a list of {key: grant}
   */
  public getGrants(section: string): Observable<EntityManagerGrant> {
    return this.initialized$.pipe(
      map((appInitialize) => buildGrantList(appInitialize.dtoUtente.dtoUtenteGrantList, section.toLowerCase()))
    );
  }

  /** Set user info and trigger the application initialized event */
  public initUser(userData: any, caller: 'init' | 'profileChange') {
    const profiloId = userData?.profiloId ?? 0;
    this.dataService.getGeneric(`${this.dataService.configSettings.restCommonUrl}/utenteinfo`, `p=${profiloId}`).pipe(
      catchError(err => {
        if (err.status === HttpStatusCode.Unauthorized) {
          this.router.navigate([AppManagerRoutes.UnauthorizedUser.url]);
        }

        return throwError(() => err);
      })
    ).subscribe((dtoUtente: DtoUtente) => {
      // console.log(`utente: ${dtoUtente.utente.id} - ${dtoUtente.utente.livelloProfiloCod} ${dtoUtente.utente.profiloTipologia} ${dtoUtente.utente.guid}`);
      this.dtoUtente = dtoUtente;
      this.appInitSubject.next({ initialized: true, dtoUtente: dtoUtente, caller: caller });
      // i18n: {
      //   language: dtoUtente.utente.currentLanguage,
      //   date: {
      //     format:
      //   },
      //   currency: {},
      //   decimal: {},
      //   percent: {}
      // }
    });
  }

  /**
   * Return application parent route url
   * as last url in the navigationList (built from url).
   * This is not the last page visit,
   * for it you could use location.Back() instead
   *
   * @returns
   */
  public getBackUrl(): { url: string, queryParams?: { [k: string]: any } } {
    if (this.navigationList.length == 0) return { url: '/' };

    const navItem = this.navigationList[this.navigationList.length - 2];
    return { url: navItem.url, queryParams: navItem.entityManagerInfo.queryParams };
  }

  public showMessage(messageData: MessageData): void {
    this.messageDataSubject.next(messageData);
  }

  public showToastMessage(toastMessageData: ToastMessageData): void {
    this.messageService.add(toastMessageData);
  }

  public showToastErrorMessageTranslated(message: string): void {
    this.translateService.translatedItems$(['generic.', 'error.']).pipe(
      take(1)
    ).subscribe((items) => {
      const msg = this.translateService.translate(items, message ?? 'generic.error.message');
      const toastMessageData = new ToastMessageData('error', msg, true, true);

      this.showToastMessage(toastMessageData);
    });
  }

  /**
   * Set data to the local storage
   *
   * @param storage storage name (see const values with prefix name = 'SESSION_STORAGE_')
   * @param key page/window identifier (EntityManager id or other unique identifier)
   * @param data data to to store
   */
  public setStorageData(storage: string, key: string, data: any, type?: StorageType) {
    const storageVersion = environment.appVersion;
    const storageKey = this.buildStorageKey();
    const storageData: any = this.getStorage(storage, type);
    // let val = storageData;
    // const fKeys = [storageVersion, storageKey, key];
    // fKeys.forEach(fKey => {
    //   val = val[fKey] ?? {};
    // });

    if (!storageData[storageVersion]) {
      storageData[storageVersion] = {}
    }
    if (!storageData[storageVersion][storageKey]) {
      storageData[storageVersion][storageKey] = {}
    }
    storageData[storageVersion][storageKey][key] = data;
    this.setStorage(storage, storageData, type);
  }

  /**
   * Return data read from local storage
   *
   * @param storage storage name (see const values with prefix name = 'SESSION_STORAGE_')
   * @param key page/window identifier (EntityManager id or other unique identifier)
   * @returns data object
   */
  public getStorageData(storage: string, key: string, type?: StorageType): any {
    const storageVersion = environment.appVersion;
    const storageKey = this.buildStorageKey();
    const storageData: any = this.getStorage(storage, type);
    const fKeys = [storageVersion, storageKey, key];
    let val = storageData;
    fKeys.forEach(fKey => {
      val = val?.[fKey] ?? {};
    });
    return val;
  }

  /**
   * Delete data from local storage
   *
   * @param storage storage name (see const values with prefix name = 'SESSION_STORAGE_')
   * @param key page/window identifier (EntityManager id or other unique identifier)
   */
  public clearStorageData(storage: string, key: string, type?: StorageType) {
    const storageVersion = environment.appVersion;
    const storageKey = this.buildStorageKey();
    const storageData: any = this.getStorage(storage, type);
    delete storageData[storageVersion]?.[storageKey]?.[key];

    this.setStorage(storage, storageData, type);
  }

  /**
   * Build the key used to store the data.
   * The key is built using dtoUtente.utente information.
   *
   * @returns storage key as string or undefined if this.dtoUtente.utente is null
   */
  private buildStorageKey(): string {
    if (!this.dtoUtente?.utente) {
      return undefined;
    }
    return `${this.dtoUtente.utente.id}_${this.dtoUtente.utente.livelloProfiloCod}`;
  }

  private getLocalStorage(storage: string) {
    let storageData: any = undefined;
    const storageDataString = localStorage.getItem(storage);
    if (storageDataString) {
      storageData = JSON.parse(storageDataString);
    }
    return storageData;
  }

  private getSessionStorage(storage: string) {
    let storageData: any = undefined;
    const storageDataString = sessionStorage.getItem(storage);
    if (storageDataString) {
      storageData = JSON.parse(storageDataString);
    }
    return storageData;
  }

  private getStorage(storage: string, type?: StorageType) {
    switch (type) {
      case StorageType.Session:
        return this.getSessionStorage(storage) ?? {};
      default:
        return this.getLocalStorage(storage) ?? {};
    };
  }

  private setStorage(storage: string, storageData: any, type?: StorageType) {
    switch (type) {
      case StorageType.Session:
        sessionStorage.setItem(storage, JSON.stringify(storageData));
        break;
      default:
        localStorage.setItem(storage, JSON.stringify(storageData));
        break;
    };
  }

  /**
   * Open EntityManagerInfo in new browser window only if it is not already in entityManagerBrowserWindows
   *
   * @param entityManagerInfo entityManager to open
   */
  public openBrowserTab(entityManagerInfo: EntityManagerInfo, section: string) {
    const winKey = `${entityManagerInfo.id}-${section}`;
    const storageData: any = this.getLocalStorage(SESSION_STORAGE_NAV_TAB) ?? {};
    if (!storageData?.[winKey]) {
      const queryString = `/${section ?? ''}`;
      const windowRef = window.open(`${AppManagerRoutes.BrowserWindow.url}/${entityManagerInfo.url}${queryString}`, '_blank', 'width=800,height=400');
      storageData[winKey] = { status: 1 };
      localStorage.setItem(SESSION_STORAGE_NAV_TAB, JSON.stringify(storageData));
    }
  }

  /**
   * Close browser window associated to EntityManagerInfo
   *
   * @param entityManagerInfo entityManager to close
   */
  public closeBrowserTab(entityManagerInfo: EntityManagerInfo, section: string) {
    const winKey = `${entityManagerInfo.id}-${section}`;
    let storageData: any = this.getLocalStorage(SESSION_STORAGE_NAV_TAB) ?? {};
    if (storageData?.[winKey]) {
      delete storageData[winKey];
      localStorage.setItem(SESSION_STORAGE_NAV_TAB, JSON.stringify(storageData));
    }
  }

  public clearBrowserTab() {
    let storageData: any = this.getLocalStorage(SESSION_STORAGE_NAV_TAB) ?? {};
    if (storageData) {
      Object.keys(storageData).forEach((key: any) => storageData[key].status = 0);
      localStorage.setItem(SESSION_STORAGE_NAV_TAB, JSON.stringify(storageData));
    }
  }

  public getEntityManagerByUrl(urlList: string[], index: number): EntityManager {
    const entityManagerInfoList: EntityManagerInfo[] = AppManagerRoutes.getEntityManagerInfoListByUrl(urlList);

    if (entityManagerInfoList.length == 0) {
      if (urlList && urlList.length > 0) {
        console.error(`entityManagerInfo '${urlList.toString()}' not found`);
      }
      return null;
      // throw new Error(`entityManagerInfoList is empty`);
    }

    const idx = index ?? entityManagerInfoList.length - 1;
    return this.instantiateEntityManager(entityManagerInfoList[idx]);
  }

  public getEntityManager(activatedRoute: ActivatedRoute, readFromStorage: boolean = true): EntityManager {
    const urlList = activatedRoute.snapshot.url.map(t => t.path);
    const entityManagerInfoList: EntityManagerInfo[] = AppManagerRoutes.getEntityManagerInfoListByUrl(urlList);
    if (entityManagerInfoList.length == 0) {
      if (activatedRoute.snapshot.url && activatedRoute.snapshot.url.length > 0) {
        console.error(`entityManagerInfo '${activatedRoute.snapshot.url.toString()}' not found`);
      }
      return null;
    }

    const paramData = buildDataFromActivatedRoute(activatedRoute);
    const data = { entity: paramData, entitySearch: paramData };
    return this.instantiateEntityManager(entityManagerInfoList[entityManagerInfoList.length - 1], data, readFromStorage);
  }

  /**
   * Instantiate an object EntityManager
   *
   * @param entityManagerInfo entity manager descriptor
   * @param data must be in the form { entity: any, entitySearch: any } (see the method EntityManager.init(data: any))
   * @param readFromStorage attempt to retrieve search data from session storage
   * @returns
   */
  public instantiateEntityManager(entityManagerInfo: EntityManagerInfo, data?: any, readFromStorage: boolean = true): EntityManager {
    let initData = data;
    if (!initData) {
      initData = {};
    }

    if (readFromStorage) {
      const storageData = this.getStorageData(SESSION_STORAGE_NAV, entityManagerInfo.id);
      if (storageData) {
        mergeObject(initData, storageData, false);
      }
    }

    let entityManagerConfig = this.instantiateEntityManagerConfig(entityManagerInfo, initData?.entity);
    const grants = buildGrantList(this.dtoUtente?.dtoUtenteGrantList, entityManagerConfig.getSection().toLowerCase());
    let entityManager = new EntityManager(entityManagerConfig, grants, entityManagerInfo.url);
    entityManager.init(initData);
    return entityManager;
  }

  /**
   * Instantiate an object EntityManagerConfig
   *
   * @param entityManagerInfo entity manager descriptor
   * @param entity initial value
   * @returns
   */
  private instantiateEntityManagerConfig(entityManagerInfo: EntityManagerInfo, entity?: any): IEntityManagerConfig {
    const entityServices = entityManagerInfo.entityServices ? this.getEntityServices(entityManagerInfo.entityServices) : [];

    let result = Factory.getInstance<IEntityManagerConfig>(
      entityManagerInfo.entityType,
      entityManagerInfo,
      entityServices,
      entity
    );
    return result;
  }

  private getEntityServices(entityServiceInfos: EntityServiceInfo[]) {
    let entityServices: { [name: string]: IEntityService<IEntity> } = {};
    if (entityServiceInfos) {
      entityServiceInfos.forEach(entityServiceInfo => {
        entityServices[entityServiceInfo.name] = this.instantiateEntityService(entityServiceInfo)
      });
    }
    return entityServices;
  }

  /**
   * instantiate the correct entity-service releted to the entity type
   *
   * @param dataService data service manager
   * @param entityType type of the entity for which to instantiate the service
   */
  private instantiateEntityService(entityServiceInfo: EntityServiceInfo) {
    return new EntityService<IEntity>(this.dataService, entityServiceInfo);
  }

  /**
   * Return a list of UrlSegment built from url
   *
   * @param url
   * @returns
   */
  private getSegments(url: string): UrlSegment[] {
    const tree: UrlTree = this.router.parseUrl(url);
    const segmentGroup: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const segments: UrlSegment[] = segmentGroup?.segments ?? [];

    // const segments: UrlSegment[] = [];
    // const arrSegment = '/' == url ? [] : url.split('/');
    // arrSegment.forEach(t => segments.push(new UrlSegment(t, undefined)));

    return segments;
  }

}

export class Factory {
  static getInstance<T>(type: (new (...args: any[]) => T), ...args: any[]): T {
    let instance = new type(...args);
    return <T>instance;
  }
}

/**
 * Return grants as EntityManagerGrant builded from list of EntityManagerGrant
 *
 * @param dtoUtenteGrantList
 * @param section
 * @returns
 */
export function buildGrantList(dtoUtenteGrantList: DtoUtenteGrant[], section: string): EntityManagerGrant {
  if (!dtoUtenteGrantList) {
    return undefined;
  }

  const result: EntityManagerGrant = {};
  dtoUtenteGrantList
    .filter(t => !isNullOrUndefined(t.tagSezione) && t.tagSezione.toLowerCase() == section)
    .forEach(t => {
      result[t.tagCod] = t.grants;
    });
  return result;
}
