import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { BlockUIModule } from 'primeng/blockui';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { TreeSelectModule } from 'primeng/treeselect';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumb.component';
import { ButtonComponent } from '../components/button/button.component';
import { DialogComponent } from '../components/dialog/dialog.component';
import { EntityFieldViewComponent } from '../components/entity/entity-field-view/entity-field-view.component';
import { EntityFormComponent } from '../components/entity/entity-form/entity-form.component';
import { EntityListEditableComponent } from '../components/entity/entity-list-editable/entity-list-editable.component';
import { EntityListComponent } from '../components/entity/entity-list/entity-list.component';
import { EntitySearchComponent } from '../components/entity/entity-search/entity-search.component';
import { EntityViewComponent } from '../components/entity/entity-view/entity-view.component';
import { ListDetailControlComponent } from '../components/form-control/list-detail-control.component';
import { MapModule } from '../components/map/map.module';
import { ListSearchComponent } from '../components/search/list-search/list-search.component';
import { SearchFieldAutocompleteComponent } from '../components/search/search-field-autocomplete/search-field-autocomplete.component';
import { SearchFieldCalendarComponent } from '../components/search/search-field-calendar/search-field-calendar.component';
import { SearchFieldCheckboxComponent } from '../components/search/search-field-checkbox/search-field-checkbox.component';
import { SearchFieldDropdownComponent } from '../components/search/search-field-dropdown/search-field-dropdown.component';
import { SearchFieldInputnumberComponent } from '../components/search/search-field-inputnumber/search-field-inputnumber.component';
import { SearchFieldInputtextComponent } from '../components/search/search-field-inputtext/search-field-inputtext.component';
import { SearchFieldMultiselectComponent } from '../components/search/search-field-multiselect/search-field-multiselect.component';
import { SearchFieldYearComponent } from '../components/search/search-field-year/search-field-year.component';
import { SidemenuComponent } from '../components/sidemenu/sidemenu.component';
import { TemplateNoteComponent } from '../components/template-note/template-note.component';
import { TextBlockComponent } from '../components/text-block/text-block.component';
import { WindowDetailComponent } from '../components/window/window-detail/window-detail.component';
import { WindowHelpComponent } from '../components/window/window-help/window-help.component';
import { WindowListDetailSearchComponent } from '../components/window/window-list-detail-search/window-list-detail-search.component';
import { WindowListEditableSearchComponent } from '../components/window/window-list-editable-search/window-list-editable-search.component';
import { WindowListSearchComponent } from '../components/window/window-list-search/window-list-search.component';
import { WindowTabComponent } from '../components/window/window-tab/window-tab.component';
import { YesNoComponent } from '../components/yes-no/yes-no.component';
import { EntityStateDirective } from '../directives/entity-state.directive';
import { GrantVisibleDirective } from '../directives/grant-visible.directive';
import { RequiredFieldDirective } from '../directives/required-field.directive';
import { TableRowHilightDirective } from '../directives/table-row-hilight.directive';
import { PipesModule } from '../pipes/pipes.module';
import { TriggersService } from '../services/extension/triggers.service';
import { GenNotificheService } from '../services/gennotifiche.service';
import { UnauthorizedUserComponent } from './components/unauthorized-user/unauthorized-user.component';
import { AngularFileUploaderComponent } from './gestione/components/angular-file-uploader/angular-file-uploader.component';
import { GenAllegatoListComponent } from './gestione/components/gen-allegato-list/gen-allegato-list.component';
import { GenLogEventoListComponent } from './gestione/components/gen-log-evento-list/gen-log-evento-list.component';
import { GenNotaAddComponent } from './gestione/components/gen-nota-add/gen-nota-add.component';
import { GenNotaListComponent } from './gestione/components/gen-nota-list/gen-nota-list.component';
import { EntityLogComponent } from './gestione/components/genlog-list/entity-log.component';
import { GenNotificaNewComponent } from './gestione/components/gennotifica-new/gennotifica-new.component';
import { GenNotificaWizardComponent } from './gestione/components/gennotifica-wizard/gennotifica-wizard.component';
import { ListExportComponent } from './gestione/components/list-export/list-export.component';
import { LoadingSpinnerComponent } from './gestione/components/loading-spinner/loading-spinner.component';
import { MessageAppComponent } from './gestione/components/message-app/message-app.component';
import { GiudAssistentatoEditComponent } from './giudici-richieste/components/giud-assistentato-edit/giud-assistentato-edit.component';
import { GiudAssistentatoExpAddComponent } from './giudici-richieste/components/giud-assistentato-exp-add/giud-assistentato-exp-add.component';
import { GiudAssistentatoGroupedListSearchComponent } from './giudici-richieste/components/giud-assistentato-grouped-list/giud-assistentato-grouped-list-search/giud-assistentato-grouped-list-search.component';
import { GiudAssistentatoGroupedListComponent } from './giudici-richieste/components/giud-assistentato-grouped-list/giud-assistentato-grouped-list.component';
import { GiudAssistentatoListBaseComponent } from './giudici-richieste/components/giud-assistentato-list/giud-assistentato-list-base.component';
import { GiudAssistentatoListSearchComponent } from './giudici-richieste/components/giud-assistentato-list/giud-assistentato-list-search/giud-assistentato-list-search.component';
import { GiudAssistentatoListComponent } from './giudici-richieste/components/giud-assistentato-list/giud-assistentato-list.component';
import { GiudAssistentatoManiSelectorComponent } from './giudici-richieste/components/giud-assistentato-mani-selector/giud-assistentato-mani-selector.component';
import { GiudAssistentatoPrvAddComponent } from './giudici-richieste/components/giud-assistentato-prv-add/giud-assistentato-prv-add.component';
import { GiudRichiestaListSearchComponent } from './giudici-richieste/components/giud-richiesta-list/giud-richiesta-list-search/giud-richiesta-list-search.component';
import { GiudRichiestaListComponent } from './giudici-richieste/components/giud-richiesta-list/giud-richiesta-list.component';
import { GiudAbilitazioneComponent } from './giudici/components/giud-abilitazione/giud-abilitazione.component';
import { GiudBloccoExpEditComponent } from './giudici/components/giud-blocco-exp-edit/giud-blocco-exp-edit.component';
import { GiudBloccoExpListComponent } from './giudici/components/giud-blocco-exp-list/giud-blocco-exp-list.component';
import { GiudBloccoPrvEditComponent } from './giudici/components/giud-blocco-prv-edit/giud-blocco-prv-edit.component';
import { GiudBloccoPrvListComponent } from './giudici/components/giud-blocco-prv-list/giud-blocco-prv-list.component';
import { GiudCertificatoEditComponent } from './giudici/components/giud-certificato-edit/giud-certificato-edit.component';
import { GiudCertificatoListComponent } from './giudici/components/giud-certificato-list/giud-certificato-list.component';
import { GiudImpegnoEsteroEditComponent } from './giudici/components/giud-impegno-estero-edit/giud-impegno-estero-edit.component';
import { GiudImpegnoEsteroListComponent } from './giudici/components/giud-impegno-estero-list/giud-impegno-estero-list.component';
import { GiudImpegnoManiExpListComponent } from './giudici/components/giud-impegno-mani-exp-list/giud-impegno-mani-exp-list.component';
import { GiudImpegnoManiExpRazzaListComponent } from './giudici/components/giud-impegno-mani-exp-razza-list/giud-impegno-mani-exp-razza-list.component';
import { GiudImpegnoManiPrvListComponent } from './giudici/components/giud-impegno-mani-prv-list/giud-impegno-mani-prv-list.component';
import { GiudImpegnoManiPrvSpListComponent } from './giudici/components/giud-impegno-mani-prv-sp-list/giud-impegno-mani-prv-sp-list.component';
import { GiudSospensioneEditComponent } from './giudici/components/giud-sospensione-edit/giud-sospensione-edit.component';
import { GiudSospensioneListComponent } from './giudici/components/giud-sospensione-list/giud-sospensione-list.component';
import { GenRilascioListComponent } from './profilo-utente/components/gen-rilascio-list/gen-rilascio-list.component';

@NgModule({
  declarations: [
    GrantVisibleDirective,
    ListDetailControlComponent,
    EntityFieldViewComponent,
    EntityListComponent,
    EntityListEditableComponent,
    EntityFormComponent,
    EntitySearchComponent,
    EntityViewComponent,
    WindowListSearchComponent,
    WindowListEditableSearchComponent,
    WindowDetailComponent,
    WindowListDetailSearchComponent,
    WindowTabComponent,
    WindowHelpComponent,
    SidemenuComponent,
    BreadcrumbComponent,
    GenNotificaWizardComponent,
    GenNotificaNewComponent,
    YesNoComponent,
    EntityLogComponent,
    ListExportComponent,
    EntityListComponent,
    GenNotaListComponent,
    GenNotaAddComponent,
    GenAllegatoListComponent,
    AngularFileUploaderComponent,
    EntityStateDirective,
    GiudImpegnoEsteroListComponent,
    GiudBloccoExpListComponent,
    GiudBloccoPrvListComponent,
    GiudSospensioneListComponent,
    GiudImpegnoManiPrvListComponent,
    GiudImpegnoManiPrvSpListComponent,
    GiudImpegnoManiExpListComponent,
    GiudImpegnoManiExpRazzaListComponent,
    GiudCertificatoListComponent,
    GiudImpegnoEsteroEditComponent,
    GiudSospensioneEditComponent,
    GiudBloccoPrvEditComponent,
    GiudBloccoExpEditComponent,
    GiudCertificatoEditComponent,
    TableRowHilightDirective,
    LoadingSpinnerComponent,
    GenRilascioListComponent,
    MessageAppComponent,
    SearchFieldMultiselectComponent,
    SearchFieldCalendarComponent,
    SearchFieldDropdownComponent,
    SearchFieldInputtextComponent,
    SearchFieldInputnumberComponent,
    SearchFieldYearComponent,
    SearchFieldCheckboxComponent,
    SearchFieldAutocompleteComponent,
    ButtonComponent,
    TextBlockComponent,
    MessageAppComponent,
    TemplateNoteComponent,
    SearchFieldMultiselectComponent,
    SearchFieldCalendarComponent,
    SearchFieldDropdownComponent,
    SearchFieldInputtextComponent,
    SearchFieldInputnumberComponent,
    SearchFieldYearComponent,
    ButtonComponent,
    ListSearchComponent,
    DialogComponent,
    UnauthorizedUserComponent,
    GenLogEventoListComponent,
    GiudAssistentatoListBaseComponent,
    GiudAssistentatoListComponent,
    GiudAssistentatoListSearchComponent,
    GiudAssistentatoGroupedListComponent,
    GiudAssistentatoGroupedListSearchComponent,
    GiudAssistentatoExpAddComponent,
    GiudAssistentatoEditComponent,
    GiudAssistentatoManiSelectorComponent,
    RequiredFieldDirective,
    GiudAssistentatoPrvAddComponent,
    GiudRichiestaListComponent,
    GiudRichiestaListSearchComponent,
    // GiudEsameSessioneListComponent,
    // GiudEsameSessioneListSearchComponent
    GiudAbilitazioneComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    TabViewModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    CheckboxModule,
    InputNumberModule,
    TooltipModule,
    RadioButtonModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    AutoCompleteModule,
    FileUploadModule,
    DialogModule,
    ProgressSpinnerModule,
    DividerModule,
    MenuModule,
    PanelMenuModule,
    AccordionModule,
    BreadcrumbModule,
    FieldsetModule,
    PanelModule,
    EditorModule,
    TagModule,
    TreeSelectModule,
    StepsModule,
    PaginatorModule,
    SidebarModule,
    OverlayPanelModule,
    BadgeModule,
    ScrollTopModule,
    ListboxModule,
    DynamicDialogModule,
    ChipsModule,
    BlockUIModule,
    SelectButtonModule,
    ChipModule,
    CardModule,
    DragDropModule,
    AutoCompleteModule,
    LetDirective,
    TriStateCheckboxModule,
    MapModule
  ],
  exports: [
    CommonModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    // HttpClientModule,
    // TranslateModule,
    GrantVisibleDirective,
    SidemenuComponent,
    BreadcrumbComponent,
    WindowTabComponent,
    WindowHelpComponent,
    // EntityFieldViewComponent,
    // EntityListComponent,
    // EntityListEditableComponent,
    // EntityFormComponent,
    // EntitySearchComponent,
    // TooltipModule,
    // WindowListSearchComponent,
    // WindowListEditableSearchComponent,
    // WindowDetailComponent,
    // WindowListDetailSearchComponent
    TableModule,
    TabViewModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    CheckboxModule,
    InputNumberModule,
    RadioButtonModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    AutoCompleteModule,
    FileUploadModule,
    DialogModule,
    ProgressSpinnerModule,
    DividerModule,
    MenuModule,
    PanelMenuModule,
    AccordionModule,
    BreadcrumbModule,
    FieldsetModule,
    PanelModule,
    EditorModule,
    TagModule,
    WindowListSearchComponent,
    TreeSelectModule,
    StepsModule,
    PaginatorModule,
    GenNotificaWizardComponent,
    GenNotificaNewComponent,
    OverlayPanelModule,
    BadgeModule,
    ScrollTopModule,
    YesNoComponent,
    EntityLogComponent,
    ListExportComponent,
    ListboxModule,
    GenNotaListComponent,
    GenNotaAddComponent,
    GenAllegatoListComponent,
    AngularFileUploaderComponent,
    DynamicDialogModule,
    EntityStateDirective,
    GiudImpegnoEsteroListComponent,
    ChipsModule,
    GiudBloccoExpListComponent,
    GiudBloccoPrvListComponent,
    GiudSospensioneListComponent,
    GiudImpegnoManiPrvListComponent,
    GiudImpegnoManiPrvSpListComponent,
    GiudImpegnoManiExpListComponent,
    GiudImpegnoManiExpRazzaListComponent,
    GiudCertificatoListComponent,
    BlockUIModule,
    SelectButtonModule,
    TableRowHilightDirective,
    LoadingSpinnerComponent,
    GenRilascioListComponent,
    MessageAppComponent,
    ChipModule,
    CardModule,
    DragDropModule,
    SearchFieldMultiselectComponent,
    SearchFieldCalendarComponent,
    SearchFieldDropdownComponent,
    SearchFieldInputtextComponent,
    SearchFieldInputnumberComponent,
    SearchFieldYearComponent,
    ButtonComponent,
    TextBlockComponent,
    SearchFieldCheckboxComponent,
    SearchFieldAutocompleteComponent,
    AutoCompleteModule,
    DragDropModule,
    TemplateNoteComponent,
    SearchFieldMultiselectComponent,
    SearchFieldCalendarComponent,
    SearchFieldDropdownComponent,
    SearchFieldInputtextComponent,
    SearchFieldInputnumberComponent,
    SearchFieldYearComponent,
    ButtonComponent,
    TextBlockComponent,
    ListSearchComponent,
    DialogComponent,
    LetDirective,
    GenLogEventoListComponent,
    GiudAssistentatoListBaseComponent,
    GiudAssistentatoListComponent,
    GiudAssistentatoListSearchComponent,
    GiudAssistentatoGroupedListComponent,
    GiudAssistentatoGroupedListSearchComponent,
    GiudAssistentatoExpAddComponent,
    GiudAssistentatoEditComponent,
    GiudAssistentatoManiSelectorComponent,
    TriStateCheckboxModule,
    RequiredFieldDirective,
    GiudAssistentatoPrvAddComponent,
    MapModule,
    GiudRichiestaListComponent,
    GiudRichiestaListSearchComponent,
    // GiudEsameSessioneListComponent,
    // GiudEsameSessioneListSearchComponent
    GiudAbilitazioneComponent
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    GenNotificheService,
    TriggersService,
  ]
})
export class SharedModule { }
