import { ComparisonOperator, DataSearch, DataSortValue, SortMode } from '../data-search';
import { Entity } from '../entity';
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, CmdFieldStyle, Icons, FormFieldStyle, CmdField } from '../entity-config';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { Validators } from '@angular/forms';

export class GenAnomaliaProfilo extends Entity {
  anomaliaId: number;
  profiloId: number;

  anomaliaCod: string;
  profiloCod: string;
}

export class GenAnomaliaProfiloEMC extends EntityManagerConfig {
  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  init() {
    this.newInstanceFields = ['anomaliaId'];
    this.textComponent = {
      windowListHeader: `${this.name}.windowListHeader`,
    };
    this.paginatorRows = 10;
    this.loadLazy = true;

    this.dataSortValue = [new DataSortValue('profiloCod', SortMode.Asc)];

    const ds = new DataSearch();
    ds.dataSortValues = [new DataSortValue('des', SortMode.Asc)];

    this.attributes['secProfiloList'] = this.getEntityService('secprofilo').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // ListEditable
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'anomaliaId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'profiloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'anomaliaCod', type: FormFieldType.string, label: `${this.name}.anomaliaCod`, sortable: true
      },
      {
        key: 'profiloCod', type: FormFieldType.string, label: `${this.name}.profiloCod`, sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'anomaliaId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden, validators: [Validators.required]
      },
      {
        key: 'profiloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden, validators: [Validators.required]
      },
      {
        key: 'anomaliaCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly,
        label: `${this.name}.anomaliaCod`, placeholder: `${this.name}.anomaliaCod`, validators: [Validators.required]
      },
      {
        key: 'objProfilo', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.modalListEditable,
        label: `${this.name}.profiloCod`, placeholder: `${this.name}.profiloCod`, validators: [Validators.required],
        options: {
          entityManagerInfo: AppManagerRoutes.SecProfiloModal, title: `${this.name}.profiloSelectionModalHeader`,
          dataLabel: 'cod', fieldsBind: { profiloId: 'id', profiloCod: 'cod' },
          icon: Icons.lookup
        }
      }
    ];

    this.searchFields = [
      {
        key: 'anomaliaId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'anomaliaId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'profiloCod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'anomaliaId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'anomaliaId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'profiloId', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.profiloCod`, class: FormFieldStyle.default,
        options: {
          data: this.attributes['secProfiloList'], dataKey: 'id', dataLabel: 'des', searchFields: 'profiloId', searchCompOp: ComparisonOperator.Equals
        }
      }
    ]
  }

}
