import { Validators } from "@angular/forms";
import { AppManagerRoutes } from "src/app/app-manager-routes";
import { ComparisonOperator, DataSortValue, SortMode } from "../../data-search";
import { Entity } from "../../entity";
import { CmdFieldKey, CmdFieldMode, CmdFieldStyle, CmdFieldTarget, EntityManagerConfig, FormFieldStyle, FormFieldType, FormFieldTypeHtml, Icons } from "../../entity-config";


// CalDefPrvSpecNum
export class CalDefPrvSpecNum extends Entity {
  limiteSup: number;
  numPrvSpecCacit?: number;
  annoCale?: number;
  annoIscritti?: number;
}

export class CalDefPrvSpecNumEMC extends EntityManagerConfig {

  init() {
    this.textComponent = { windowListHeader: `${this.name}.windowListHeader` };
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('annoCale', SortMode.Desc), new DataSortValue('limiteSup', SortMode.Asc)];
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // List
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger,
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save,
        class: CmdFieldStyle.btnPrimary,
      }
    ];

    this.listFields = [
      { key: 'id', type: FormFieldType.number, label: `${this.name}.id`, sortable: true, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'annoCale', type: FormFieldType.number, label: `${this.name}.annoCale`, sortable: true },
      { key: 'annoIscritti', type: FormFieldType.number, label: `${this.name}.annoIscritti`, sortable: true },
      { key: 'limiteSup', type: FormFieldType.number, label: `${this.name}.limiteSup`, sortable: true },
      { key: 'numPrvSpecCacit', type: FormFieldType.number, label: `${this.name}.numPrvSpecCacit`, sortable: true }
    ];

    this.detailFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden },
      {
        key: 'annoCale', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.number,
        label: `${this.name}.annoCale`, placeholder: `${this.name}.annoCale`,
        validators: [Validators.required]
      },
      {
        key: 'annoIscritti', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.number,
        label: `${this.name}.annoIscritti`, placeholder: `${this.name}.annoIscritti`,
        validators: [Validators.required]
      },
      {
        key: 'limiteSup', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.number,
        label: `${this.name}.limiteSup`, placeholder: `${this.name}.limiteSup`,
        validators: [Validators.required]
      },
      {
        key: 'numPrvSpecCacit', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.number,
        label: `${this.name}.numPrvSpecCacit`, placeholder: `${this.name}.numPrvSpecCacit`,
      }
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'limiteSup,numPrvSpecCacit,annoCale,annoIscritti', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'annoCale', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.number, placeholder: `${this.name}.annoCale`,
        options: { searchFields: 'annoCale', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.default
      },
      {
        key: 'annoIscritti', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.number, placeholder: `${this.name}.annoIscritti`,
        options: { searchFields: 'annoIscritti', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.default
      },
      {
        key: 'limiteSup', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.number, placeholder: `${this.name}.limiteSup`,
        options: { searchFields: 'limiteSup', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.default
      },
      {
        key: 'numMostre', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.number, placeholder: `${this.name}.numMostre`,
        options: { searchFields: 'numMostre', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.default
      }
    ];
  }

}
