import { ActivatedRoute } from "@angular/router";
import { isNullOrUndefined } from "../utils/util";

export const ITEM_SEPARATOR = "||";
export const FIELD_SEPARATOR = "|";
export class DataSearch {
  dataSearchValues: DataSearchValue[] = [];
  dataSortValues: DataSortValue[] = [];
  pageFirst: number;
  pageSize: number;

  constructor(dataSearchValue: DataSearchValue[] = [], dataSortValues: DataSortValue[] = [], pageFirst: number = undefined, pageSize: number = undefined) {
    this.dataSearchValues = dataSearchValue;
    this.dataSortValues = dataSortValues;
    this.pageFirst = pageFirst;
    this.pageSize = pageSize;
  }

  clear() {
    this.dataSearchValues = [];
    this.dataSortValues = [];
    this.pageFirst = undefined;
    this.pageSize = undefined;
  }

  // URL SYNTAX

  toQueryString(mode: 'std' | 'custom' = 'custom') {
    const arrOut = [];

    if ('std' === mode) {
      // queryStringParam = "key=ciao"
      //  + "&dataSearchValue[0].value=v1&dataSearchValue[0].fields[0]=f1a&dataSearchValue[0].fields[1]=f1b"
      //  + "&dataSearchValue[1].value=v2&dataSearchValue[1].fields[0]=f2a&dataSearchValue[1].fields[1]=f2b";

      if (this.pageFirst)
        arrOut.push(`pageFirst=${encodeURIComponent(this.pageFirst)}`);

      if (this.pageSize)
        arrOut.push(`pageSize=${encodeURIComponent(this.pageSize)}`);

      let strOut1;
      if (this.dataSearchValues.length > 0)
        strOut1 = this.dataSearchValues.filter(t => DataSearchValue.hasValues(t)).map((t, index) => DataSearchValue.toQueryString(t, index)).join('&');

      if (strOut1 && strOut1.trim().length > 0)
        arrOut.push(strOut1);

      let strOut2;
      if (this.dataSortValues.length > 0)
        strOut2 = this.dataSortValues.filter(t => DataSortValue.hasValues(t)).map((t, index) => DataSortValue.toQueryString(t, index)).join('&');

      if (strOut2 && strOut2.trim().length > 0)
        arrOut.push(strOut2);
    }
    else if ('custom' === mode) {
      // pf=0
      // &ps=10
      // &sev=mario,gino|email,guid|3||lucia,mara|cod,des|2
      // &sov=email|1||cod|2

      if (this.pageFirst)
        arrOut.push(`pf=${encodeURIComponent(this.pageFirst)}`);

      if (this.pageSize)
        arrOut.push(`ps=${encodeURIComponent(this.pageSize)}`);

      let strDataSearchValues;
      if (this.dataSearchValues.length > 0) {
        strDataSearchValues = this.dataSearchValues.filter(t => DataSearchValue.hasValues(t)).map((t, index) => DataSearchValue.toQueryString(t, index)).join(ITEM_SEPARATOR);
      }
      if (strDataSearchValues && strDataSearchValues.trim().length > 0) {
        arrOut.push("sev=" + encodeURIComponent(strDataSearchValues));
      }

      let strDataSortValues;
      if (this.dataSortValues.length > 0) {
        strDataSortValues = this.dataSortValues.filter(t => DataSortValue.hasValues(t)).map((t, index) => DataSortValue.toQueryString(t, index)).join(ITEM_SEPARATOR);
      }
      if (strDataSortValues && strDataSortValues.trim().length > 0) {
        arrOut.push("sov=" + encodeURIComponent(strDataSortValues));
      }
    }

    return arrOut.join("&");
  }

}

export class DataSearchValue {
  values: any[];
  fields: string[];
  compOp: string | ComparisonOperator;

  constructor(values: any[], fields: string[], compOp: string | ComparisonOperator) {
    this.values = values.filter(t => t && t != ''); // && t != 0
    this.fields = fields.filter(t => t.length > 0);
    this.compOp = compOp;
  }

  // URL SYNTAX
  static toQueryString(dataSearchValue: DataSearchValue, index: number, mode: 'std' | 'custom' = 'custom'): string {
    if ('std' === mode) {
      // compOp=1 & dataSearchValue[0].value=v1 & dataSearchValue[0].fields[0]=f1a & dataSearchValue[0].fields[1]=f1b
      let strOut = `${encodeURIComponent('dataSearchValues[' + index + ']')}.compOp=${encodeURIComponent(dataSearchValue.compOp)}`;
      const strOutValues = dataSearchValue.values.map((t, indexValue) => `${encodeURIComponent('dataSearchValues[' + index + ']')}.${encodeURIComponent('values[' + indexValue + ']')}=${encodeURIComponent(t)}`).join('&');
      const strOutFields = dataSearchValue.fields.map((t, indexField) => `${encodeURIComponent('dataSearchValues[' + index + ']')}.${encodeURIComponent('fields[' + indexField + ']')}=${encodeURIComponent(t)}`).join('&');
      return `${strOut}&${strOutValues}&${strOutFields}`;
    }
    else if ('custom' === mode) {
      // mario,gino|email,guid|3
      return `${dataSearchValue.values}${FIELD_SEPARATOR}${dataSearchValue.fields}${FIELD_SEPARATOR}${dataSearchValue.compOp}`;
      // const strOutValues = dataSearchValue.values.map(t => encodeURIComponent(t)).join(',');
      // const strOutFields = dataSearchValue.fields.map(t => encodeURIComponent(t)).join(',');
      // const strCompOp = encodeURIComponent(dataSearchValue.compOp);
    }
    return null;
  }

  static hasValues(dataSearchValue: DataSearchValue): boolean {
    if (!(dataSearchValue.values) || dataSearchValue.values.length == 0 || !(dataSearchValue.fields) || dataSearchValue.fields.length == 0 || !(dataSearchValue.compOp))
      return false;

    let result: boolean = true;
    dataSearchValue.values.forEach(t => result = (t && t.length > 0));
    dataSearchValue.fields.forEach(t => result = (t && t.length > 0));

    return result;
  }

}

export class DataSortValue {
  field: string;
  sortMode: SortMode;

  constructor(field: string, sortMode: SortMode) {
    this.field = field?.length == 0 ? undefined : field;
    this.sortMode = sortMode;
  }

  // URL SYNTAX
  static toQueryString(dataSortValue: DataSortValue, index: number, mode: 'std' | 'custom' = 'custom'): string {
    if ('std' === mode) {
      // sortMode=1 & field=f1
      let strOut = `${encodeURIComponent('dataSortValues[' + index + ']')}.field=${encodeURIComponent(dataSortValue.field)}&${encodeURIComponent('dataSortValues[' + index + ']')}.sortMode=${encodeURIComponent(dataSortValue.sortMode)}`;
      return strOut;
    }
    else if ('custom' === mode) {
      // email|1
      return `${dataSortValue.field}${FIELD_SEPARATOR}${dataSortValue.sortMode}`;
    }
    return null;
  }

  static hasValues(dataSortValue: DataSortValue): boolean {
    if (!(dataSortValue.field) || dataSortValue.field.length === 0 || !(dataSortValue.sortMode))
      return false;

    return true;
  }

}

export enum ComparisonOperator {
  Equals = 1,
  StartsWith,
  Contains,
  NotEquals,
  GreaterThan,
  GreaterThanOrEqual,
  LessThan,
  LessThanOrEqual,
  In,
  NotIn,
  IsNull,
  IsNotNull,
  Custom,
  LargeIn,
  LargeNotIn
}

export enum SortMode {
  Asc = 1,
  Desc = -1
}

/**
 * Build DataSearch instance based on data
 *
 * @param data instance of object
 * @returns instance of DataSearch
 */
//  export function buildDataSearch(data: any, dataSortValues?: DataSortValue[], pageFirst: number = 0, pageSize: number = 0): DataSearch {
//   let dataSearch = new DataSearch();
//   dataSearch.dataSortValues = dataSortValues;
//   dataSearch.pageFirst = pageFirst;
//   dataSearch.pageSize = pageSize;

//   if (data) {
//     Object.keys(data).forEach(key => {
//       let val = data;
//       key.split(".").map(field => val = val[field]);
//       dataSearch.dataSearchValues.push(new DataSearchValue([val.toString()], [key], ComparisonOperator.Equals));
//     });
//   }
//   return dataSearch;
// }


/**
 * Build DataSearch instance based on data
 *
 * @param data instance of object
 * @param searchFieldsConf searchConfiguration
 * @param advancedSearch boolean advanced search
 * @param dataSortValues data sort values, optional
 * @param pageFirst first page, oprional default 0
 * @param pageSize page size, oprional default 0
 * @returns instance of DataSearch
 */
export function buildDataSearch(data: any, searchFieldsConf: SearchFieldsConf[], advancedSearch: boolean = false, dataSortValues?: DataSortValue[], pageFirst: number = 0, pageSize: number = 0): DataSearch {
  const dataSearch = new DataSearch();
  dataSearch.dataSortValues = dataSortValues;
  dataSearch.pageFirst = pageFirst;
  dataSearch.pageSize = pageSize;

  searchFieldsConf.filter((sf => {
    return sf.advancedSearch === advancedSearch;
  })).forEach((f) => {
    switch (f.type) {
      case SearchFieldType.singleValue:
        if (data[f.key]) {
          dataSearch.dataSearchValues.push(new DataSearchValue([data[f.key].toString()], [f.searchFields], f.searchCompOp));
        }
        break;
      case SearchFieldType.multiValue:
        if (Array.isArray(data[f.key]) && data[f.key]?.length > 0) {
          dataSearch.dataSearchValues.push(new DataSearchValue(data[f.key].map(v => v.toString()), [f.searchFields], f.searchCompOp));
        }
        break;
      case SearchFieldType.multiField:
        if (data[f.key]) {
          dataSearch.dataSearchValues.push(new DataSearchValue([data.simpleSearch.toString()], f.searchFields.split(','), f.searchCompOp));
        }
        break;
      case SearchFieldType.multiFieldBooleanValue:
        if (data[f.key]) {
          dataSearch.dataSearchValues.push(new DataSearchValue(['1'], data[f.searchFields], f.searchCompOp));
        }
        break;
      case SearchFieldType.date:
        if (data[f.key]) {
          const date = data[f.key].toString().substring(0, 15) + ' 00:00:00';
          dataSearch.dataSearchValues.push(new DataSearchValue([date], [f.searchFields], f.searchCompOp));
        }
        break;
      case SearchFieldType.custom:
        if (data[f.key]) {
          dataSearch.dataSearchValues.push(new DataSearchValue([data[f.key].toString()], [f.key], f.searchCompOp));
        }
        break;
    }
  });

  return dataSearch;
}

export type SearchFieldsConf = {
  key: string,
  searchFields: string,
  type: SearchFieldType,
  searchCompOp: ComparisonOperator,
  advancedSearch: boolean
};

export enum SearchFieldType {
  singleValue = 1,
  multiValue,
  multiField,
  multiFieldBooleanValue,
  date,
  custom
};

/**
 * Build queryString from object
 *
 * @param data
 * @returns queryString
 */
export function buildQueryString(data: any) {
  const result = Object.keys(data).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
  }).join('&');
  return result;
}

/**
 * Build queryString from params in ActivatedRoute
 *
 * @param activatedRoute
 * @returns queryString
 */
export function buildQueryStringFromActivatedRoute(activatedRoute: ActivatedRoute, fields: string[]): string {
  let result: string = undefined;
  const keys = fields ?? Object.keys(activatedRoute.snapshot.params);
  if (keys.length > 0) {
    result = keys.map((key) => {
      if (!isNullOrUndefined(activatedRoute.snapshot.params[key])) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(activatedRoute.snapshot.params[key])
      }
    }).join('&');
  }
  return result;
}
