import { Validators } from "@angular/forms";
import { AppManagerRoutes } from "../../app-manager-routes";
import { IEntityService } from "../../services/entity.service";
import { getEnumLabelValueList } from "../../utils/util";
import { ComparisonOperator, DataSearch, DataSortValue, SortMode } from "../data-search";
import { Entity } from "../entity";
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, CmdField, CmdFieldStyle, Icons, FieldGrant, FormFieldStyle } from "../entity-config";


export class SecRuoloTag extends Entity {
  ruoloId: number;
  tagId: number;
  grants: number;

  ruoloCod: string;
  tagSezione: string;
  tagCod: string;
  profiloId: number;
  profiloCod: string;
}

export class SecRuoloTagEMC extends EntityManagerConfig {
  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  init() {
    this.newInstanceFields = ['tagId'];
    this.textComponent = {
      windowListHeader: `${this.name}.windowListHeader`, windowDetailHeader: `${this.name}.windowDetailHeader`
    };
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('ruoloCod', SortMode.Asc)];

    const ds = new DataSearch();
    ds.dataSortValues = [new DataSortValue('des', SortMode.Asc)];

    this.attributes['secProfiloList'] = this.getEntityService('secprofilo').searchEntitiesAsync(this.fLoad, ds);

    this.attributes['grantList'] = getEnumLabelValueList(FieldGrant);

    this.attributes['gensezione'] = AppManagerRoutes.getSezioneList();
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        label: 'generic.back', title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
        // hEvent: () => {
        //   let field = this.getCmdField(CmdFieldTarget.listSearch, CmdFieldMode.btn, CmdFieldKey.toggle);
        //   this.toggleField(field, false, true, 'generic.searchAdvanced', 'generic.searchBasic')
        // }
      },

      // Lis editable      
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary,
      },

      // // List
      // {
      //   key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader, entityManagerInfo: AppManagerRoutes.SecRuoloTagDetail,
      //   label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      // },
      // {
      //   key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody, entityManagerInfo: AppManagerRoutes.SecRuoloTagDetail,
      //   title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      // },
      // {
      //   key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listFooter,
      //   label: 'generic.delete', title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger
      // },

      // // Detail
      // {
      //   key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
      //   label: 'generic.cancel', title: 'generic.cancel', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      // },
      // {
      //   key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
      //   label: 'generic.save', title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary
      // }

    ];

    this.listFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'tagSezione', type: FormFieldType.string, label: `${this.name}.tagSezione`, sortable: true
      },
      {
        key: 'tagCod', type: FormFieldType.string, label: `${this.name}.tagCod`, sortable: true
      },
      {
        key: 'ruoloCod', type: FormFieldType.string, label: `${this.name}.ruoloCod`, sortable: true
      },
      {
        key: 'profiloCod', type: FormFieldType.string, label: `${this.name}.profiloCod`, sortable: true
      },
      {
        key: 'grants', type: FormFieldType.enum, label: `${this.name}.grants`, translatePrefix: 'generic.grant', sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'ruoloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'tagId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'tagSezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly,
        label: `${this.name}.tagSezione`, placeholder: `${this.name}.tagSezione`
      },
      {
        key: 'tagCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly,
        label: `${this.name}.tagCod`, placeholder: `${this.name}.tagCod`
      },
      {
        key: 'objRuolo', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.modalListEditable,
        label: `${this.name}.ruoloCod`, placeholder: `${this.name}.ruoloCod`, validators: [Validators.required],
        options: {
          entityManagerInfo: AppManagerRoutes.SecRuoloModal, title: `${this.name}.ruoloSelectionModalHeader`,
          dataLabel: 'cod', fieldsBind: { ruoloId: 'id', ruoloCod: 'cod', profiloCod: 'profiloCod' },
          icon: Icons.lookup
        }
      },
      {
        key: 'profiloCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly,
        label: `${this.name}.profiloCod`, placeholder: `${this.name}.profiloCod`
      },
      {
        key: 'grants', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.select, validators: [Validators.required],
        label: `${this.name}.grants`, placeholder: `${this.name}.grants`,
        options: { data: this.attributes['grantList'], dataKey: 'value', dataLabel: 'label' }
      }
    ];

    this.searchFields = [
      {
        key: 'tagId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'tagId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'tagSezione,tagCod,ruoloCod,profiloCod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'tagId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'tagId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'tagSezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.tagSezione`,
        options: { data: this.attributes['gensezione'], dataKey: 'cod', dataLabel: 'des', searchFields: 'tagSezione', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.default
      },
      {
        key: 'tagCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.tagCod`,
        options: { searchFields: 'tagCod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'ruoloCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.ruoloCod`,
        options: { searchFields: 'ruoloCod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'profiloId', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.profiloCod`, class: FormFieldStyle.default,
        options: {
          data: this.attributes['secProfiloList'], dataKey: 'id', dataLabel: 'des', searchFields: 'profiloId', searchCompOp: ComparisonOperator.Equals
        }
      },
      {
        key: 'grants', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.grants`,
        options: { data: this.attributes['grantList'], dataKey: 'value', dataLabel: 'label', searchFields: 'grants', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.default
      },
    ];
  }

  filterTags(event) {
    let entityService: IEntityService<any> = this.entityServices['sectag'];
    let loadEvent: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerBody };
    let dataSearch: DataSearch = new DataSearch();
    if (event.query) {
      dataSearch.dataSearchValues = [
        { fields: ['sezione', 'cod'], values: [event.query], compOp: ComparisonOperator.Contains }
      ];
    }
    dataSearch.dataSortValues = [
      { field: 'id', sortMode: SortMode.Asc }
    ];
    entityService.searchEntities(loadEvent, dataSearch);
  }

}


export class SecTagRuoloEMC extends EntityManagerConfig {
  init() {
    this.newInstanceFields = ['ruoloId'];
    this.textComponent = {
      windowListHeader: `${this.name}.windowListHeader`
    };
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('ruoloCod', SortMode.Asc)];

    this.attributes['grantList'] = getEnumLabelValueList(FieldGrant);

    this.attributes['gensezione'] = AppManagerRoutes.getSezioneList();
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        label: 'generic.back', title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // Lis editable      
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary,
      }
    ];

    this.listFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'ruoloCod', type: FormFieldType.string, label: `${this.name}.ruoloCod`, sortable: true
      },
      {
        key: 'profiloCod', type: FormFieldType.string, label: `${this.name}.profiloCod`, sortable: true
      },
      {
        key: 'tagSezione', type: FormFieldType.string, label: `${this.name}.tagSezione`, sortable: true
      },
      {
        key: 'tagCod', type: FormFieldType.string, label: `${this.name}.tagCod`, sortable: true
      },
      {
        key: 'grants', type: FormFieldType.enum, label: `${this.name}.grants`, translatePrefix: 'generic.grant', sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'ruoloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'tagId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'ruoloCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly,
        label: `${this.name}.ruoloCod`, placeholder: `${this.name}.ruoloCod`
      },
      {
        key: 'profiloCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly,
        label: `${this.name}.profiloCod`, placeholder: `${this.name}.profiloCod`
      },
      {
        key: 'tagSezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly,
        label: `${this.name}.tagSezione`, placeholder: `${this.name}.tagSezione`
      },
      {
        key: 'objTag', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.modalListEditable,
        label: `${this.name}.ruoloCod`, placeholder: `${this.name}.tagCod`, validators: [Validators.required],
        options: {
          entityManagerInfo: AppManagerRoutes.SecTagModal, title: `${this.name}.tagSelectionModalHeader`,
          dataLabel: 'cod', fieldsBind: { tagId: 'id', tagCod: 'cod', tagSezione: 'sezione' },
          icon: Icons.lookup
        }
      },
      {
        key: 'grants', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.select, validators: [Validators.required],
        label: `${this.name}.grants`, placeholder: `${this.name}.grants`,
        options: { data: this.attributes['grantList'], dataKey: 'value', dataLabel: 'label' }
      }
    ];

    this.searchFields = [
      {
        key: 'ruoloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'ruoloId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'ruoloCod,tagSezione,tagCod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'ruoloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'ruoloId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'ruoloCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.ruoloCod`,
        options: { searchFields: 'ruoloCod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'tagSezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.tagSezione`,
        options: { data: this.attributes['gensezione'], dataKey: 'cod', dataLabel: 'des', searchFields: 'tagSezione', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.default
      },
      {
        key: 'tagCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.tagCod`,
        options: { searchFields: 'tagCod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'grants', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.grants`,
        options: { data: this.attributes['grantList'], dataKey: 'value', dataLabel: 'label', searchFields: 'grants', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.default
      }
    ];
  }

}
