import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CalCaleManiGiudAssistentatoDetailDto, CalCaleManiGiudAssistentatoDto, CalCaleManiGiudAssistentatoGiornoDto } from 'src/app/models/calendario/dto/cal-cale-mani-giud-assistentato-dto';
import { Resources } from 'src/app/services/translate.service';
import { dateFormat } from 'src/app/utils/util';

@Component({
  selector: 'app-giud-assistentato-mani-selector',
  templateUrl: './giud-assistentato-mani-selector.component.html',
  styleUrls: ['./giud-assistentato-mani-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GiudAssistentatoManiSelectorComponent),
      multi: true
    }
  ]
})
export class GiudAssistentatoManiSelectorComponent implements ControlValueAccessor {
  dateFormat: string = dateFormat;

  private _options: CalCaleManiGiudAssistentatoDto[];

  // Selection
  selection: number[] = [];

  maniSelection: number[] = [];

  onChange: any = () => { }

  onTouch: any = () => { }

  @Input()
  set options(val: CalCaleManiGiudAssistentatoDto[]) {
    // Per la gestione del flag della selezione parziale
    val?.forEach(o => o.selected = null);

    this._options = val;
  }

  get options(): CalCaleManiGiudAssistentatoDto[] {
    return this._options;
  }

  @Input()
  elaboration: boolean = false;

  @Input()
  startMessage: string = 'startMessage';

  @Input()
  emptyListMessage: string = 'emptyListMessage';

  @Input()
  assistLimitReachedMessage: string = 'assistLimitReachedMessage';

  @Input()
  assistAlreadyRequestedMessage: string = 'assistAlreadyRequestedMessage';

  @Input()
  assistSameTimeMessage: string = 'assistSameTimeMessage';

  @Input()
  assistAlreadyRequestedDayMessage: string = 'assistAlreadyRequestedDayMessage';

  @Input()
  razzaPiuGiorniMessage: string = 'razzaPiuGiorniMessage';

  @Input()
  giornoLabel: string = 'giornoLabel';

  @Input()
  parteGiornoResources!: Resources;

  writeValue(val: number[]): void {
    this.selection = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onSelectionChange(): void {
    this.selection = this.options.filter(o => o.selected !== null).reduce((ids, o) => ids = [...ids, ...this.reduceDetailsMani(o).filter(d => d.selected).map(d => d.id)], []);

    this.onChange(this.selection);
    this.onTouch(this.selection);
  }

  onCaleManiSelectionChange(event: boolean, caleManiId: number): void {
    const option = this.options.find(o => o.id === caleManiId);
    const details = this.reduceDetailsMani(option);

    details.forEach(d => {
      if (!d.disabled) {
        d.selected = event;
      }
    });

    // Gestisce la selezione a livello di manifestazione
    option.selected = this.caleManiSelected(details);

    this.onSelectionChange();
  }

  onChildSelectionChange(event: boolean, detail: CalCaleManiGiudAssistentatoDetailDto): void {
    const option = this.options.find(o => o.id === detail.caleManiId);
    const giorno = option.children.find(g => g.giorno === detail.giorno);
    const detailsMani = this.reduceDetailsMani(option);
    const detailsGiorno = this.reduceDetailsGiorno(giorno);

    // Gestisce la selezione a livello di giorno
    detailsGiorno.forEach(dg => dg.disabled = event && dg.id != detail.id);
    giorno.disabled = detailsGiorno.every(d => d.disabled);

    // Gestisce la selezione a livello di manifestazione
    option.selected = this.caleManiSelected(detailsMani);

    this.onSelectionChange();
  }

  private caleManiSelected(details: CalCaleManiGiudAssistentatoDetailDto[]): boolean | null {
    const fullSelected = details.filter(d => !d.disabled).every(d => d.selected);
    return fullSelected ? fullSelected : details.some(d => d.selected) ? false : null;
  }

  private reduceDetailsGiorno(giorno: CalCaleManiGiudAssistentatoGiornoDto): CalCaleManiGiudAssistentatoDetailDto[] {
    return giorno.children;
  }

  private reduceDetailsMani(option: CalCaleManiGiudAssistentatoDto): CalCaleManiGiudAssistentatoDetailDto[] {
    return option.children.reduce((d, g) => d = [...d, ...g.children], []);
  }
}
