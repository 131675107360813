<ng-container *ngIf="translatedItems$ | async as translateItems">
  <div *ngIf="textHeader">
    <p class="text-header" [innerHtml]="translateItems | translateItem: textHeader"></p>
  </div>

  <p-table #dataTable [value]="entities" [totalRecords]="paginatorRowsTot" [lazy]="entityManager.loadLazy"
    [paginator]="true" [first]="paginatorFirst" [rows]="paginatorRows" [rowsPerPageOptions]="[10, 25, 50, 100]"
    styleClass="p-datatable-striped p-datatable-md" [resizableColumns]="false" selectionMode="multiple"
    [(selection)]="entitiesSelected" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
    (onLazyLoad)="onLazyLoad($event)" (onPage)="onPage($event)" [customSort]="true"
    (sortFunction)="sortFunction($event)" [showCurrentPageReport]="true"
    [currentPageReportTemplate]="translateItems | translateItem: (paginatorRowsTot ? 'generic.listReport' : 'generic.listEmpty')"
    [sortField]="baseDataSearch?.dataSortValues[0].field" [sortOrder]="baseDataSearch?.dataSortValues[0].sortMode"
    responsiveLayout="stack" breakpoint="960px" styleClass="p-datatable-striped p-datatable-md"
    paginatorPosition="bottom">

    <ng-container
      *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.listCaption, fieldType:cmdFieldMode.btn}) as cmdFields">
      <ng-template pTemplate="caption" *ngIf="cmdFields.length > 0">
        <div class="buttons-cnt">
          <ng-container *ngFor="let field of cmdFields">
            <button pButton [type]="field.type || 'button'" [class]="field.class"
              [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field" (click)="onCmd(field)"
              [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{field: field, entitiesSelCount: entitiesSelected.length})"
              *ngIf="entityManager | isCmdFieldVisible:field" [icon]="field.icon"
              [title]="translateItems | translateItem: field.title"
              [label]="translateItems | translateItem: field.label"></button>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>

    <ng-template pTemplate="header">
      <tr>
        <th class="white-space-nowrap check-column">
          <p-tableHeaderCheckbox (click)="onAllRowSelectUnselect($event)"></p-tableHeaderCheckbox>
        </th>

        <ng-container *ngFor="let field of listFields">
          <ng-container *ngIf="field.sortable; else nosort">
            <th class="white-space-nowrap" [pSortableColumn]="field.key">
              {{translateItems | translateItem: field.label}}
              <p-sortIcon [field]="field.key"></p-sortIcon>
            </th>
          </ng-container>
          <ng-template #nosort>
            <th>
              {{translateItems | translateItem: field.label}}
            </th>
          </ng-template>
        </ng-container>

        <th class="white-space-nowrap buttons-column">
          <div class="buttons-cnt">
            <ng-container
              *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.listHeader, fieldType:cmdFieldMode.btn}) as cmdFields">
              <ng-container *ngFor="let field of cmdFields">
                <button pButton [type]="field.type || 'button'" [class]="field.class"
                  [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field" (click)="onCmd(field)"
                  [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{field: field, entitiesSelCount: entitiesSelected.length})"
                  *ngIf="entityManager | isCmdFieldVisible:field" [icon]="field.icon"
                  [title]="translateItems | translateItem: field.title"
                  [label]="translateItems | translateItem: field.label">
                </button>
              </ng-container>
            </ng-container>

            <ng-container
              *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.listHeaderMenu, fieldType:cmdFieldMode.btn}) as cmdFieldsMenu">
              <ng-container *ngIf="cmdFieldsMenu.length > 0">
                <p-menu #tableactionmenu [popup]="true" appendTo="body" [model]="actionMenuItems"> </p-menu>
                <button type="button" pButton icon="fa-solid fa-ellipsis-vertical"
                  [title]="translateItems | translateItem: 'generic.commands'" class="p-button-sm p-button-outlined"
                  (click)="setActionMenuItems($event, cmdFieldsMenu); tableactionmenu.toggle($event)"></button>
              </ng-container>
            </ng-container>
          </div>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="check-column">
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        <ng-container *ngFor="let field of listFields">
          <td><span class="p-column-title">{{translateItems | translateItem: field.label}}</span>
            <entity-field-view [entityManager]="entityManager" [field]="field" [data]="rowData"></entity-field-view>
          </td>
        </ng-container>
        <td class="buttons-column">
          <div class="buttons-cnt">
            <ng-container *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.listBody, fieldType:cmdFieldMode.btn}) as cmdFields">

              <ng-container *ngFor="let field of cmdFields">
                <button pButton [type]="field.type || 'button'" [class]="field.class"
                        [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field" (click)="onTbl(field,rowData)"
                        [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{field: field, entitiesSelCount: entitiesSelected.length}) || (rowData.id == 0 && field.key != cmdFieldKey.navigateGuid)"
                        *ngIf="entityManager | isCmdFieldVisible:field:{entity: rowData}" [icon]="field.icon"
                        [title]="translateItems | translateItem: field.title"
                        [label]="translateItems | translateItem: field.label">
                </button>
              </ng-container>
            </ng-container>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="100%" class="text-center danger">
          {{translateItems | translateItem: 'generic.norecordsfound'}}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-dialog *ngIf="entityModal?.['entityManager']" [header]="translateItems | translateItem: entityModal['header']"
    styleClass="modal-window-size" [resizable]="true" [(visible)]="entityModal['visible']" (onHide)="modalEMHide()"
    [modal]="true" [closeOnEscape]="true">
    <ng-container [ngSwitch]="entityModal['field'].key">
      <window-list-detail-search *ngSwitchCase="cmdFieldKey.modalList" [entityManager]="entityModal['entityManager']"
        [dataSearch]="entityModal['dataSearch']">
      </window-list-detail-search>
      <window-list-editable-search *ngSwitchCase="cmdFieldKey.modalListEditable"
        [entityManager]="entityModal['entityManager']" [dataSearch]="entityModal['dataSearch']">
      </window-list-editable-search>
    </ng-container>
  </p-dialog>

</ng-container>
