import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, EntityManagerConfig, CmdField } from "../entity-config";
import { AppManagerRoutes } from "../../app-manager-routes";

export class AnaRelazioneEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GenRelazioneList, label: 'menu.GenRelazioneList.label', title: 'menu.GenRelazioneList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GenDomandaList, label: 'menu.GenDomandaList.label', title: 'menu.GenDomandaList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GenDomandaTipoList, label: 'menu.GenDomandaTipoList.label', title: 'menu.GenDomandaTipoList.title'
      }
    ];
  }
}
