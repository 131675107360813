import { Validators } from "@angular/forms";
import { DataSortValue, SortMode, DataSearch, ComparisonOperator } from "../../data-search";
import { EntityManagerConfig, CmdField, CmdFieldKey, CmdFieldMode, CmdFieldTarget, Icons, CmdFieldStyle, FormFieldType, FormFieldTypeHtml, FormFieldStyle } from "../../entity-config";

export class GenRelazioneLangEMC extends EntityManagerConfig {

  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none }

  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = {};
    this.dataSortValue = [new DataSortValue('lang', SortMode.Asc)];

    const ds: DataSearch = new DataSearch(
      [],
      [new DataSortValue('id', SortMode.Asc)]
    );

    this.attributes['genlang'] = this.getEntityService('genlang').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {
    this.cmdFields = [
      // List search
      {
        key: CmdFieldKey.search,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        type: 'submit',
        label: 'generic.search',
        title: 'generic.search',
        icon: Icons.search,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.reset,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset',
        title: 'generic.searchReset',
        icon: Icons.reset,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // Editable
      {
        key: CmdFieldKey.add,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listHeader,
        label: 'generic.add',
        title: 'generic.add',
        icon: Icons.plus,
        class: CmdFieldStyle.btnPrimary,
      },
      {
        key: CmdFieldKey.edit,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.edit',
        icon: Icons.edit,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.delete,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.delete',
        icon: Icons.delete,
        class: CmdFieldStyle.btnSecondaryDanger,
      },
      {
        key: CmdFieldKey.cancel,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.cancel',
        icon: Icons.back,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.save,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.save',
        icon: Icons.save,
        class: CmdFieldStyle.btnPrimary,
      }
    ];

    this.listFields = [
      {
        key: 'lang',
        type: FormFieldType.string,
        label: `${this.name}.lang`,
        sortable: true,
      },
      {
        key: 'des',
        type: FormFieldType.string,
        label: `${this.name}.des`,
        sortable: true,
      },
    ];

    this.detailFields = [
      {
        key: 'id',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'dtIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'userIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'relazioneId',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'lang',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.select,
        label: `${this.name}.lang`,
        placeholder: `${this.name}.lang`,
        options: {
          data: this.attributes['genlang'],
          dataKey: 'lang',
          dataLabel: 'des',
        },
        validators: [Validators.required],
      },
      {
        key: 'des',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`,
        label: `${this.name}.des`,
        validators: [Validators.required],
      },
    ];

    this.searchFields = [
      {
        key: 'relazioneId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'relazioneId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'search',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: {
          searchFields: 'lang,langDes,des',
          searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'relazioneId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'relazioneId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'lang',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.lang`,
        options: {
          data: this.attributes['genlang'],
          dataKey: 'lang',
          dataLabel: 'des',
          searchFields: 'lang',
          searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'des',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`,
        options: {
          searchFields: 'des',
          searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.default
      }
    ];
  }
}
