import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AppManagerRoutes } from '../../../app-manager-routes';
import { IEntity } from '../../../models/entity';
import { CmdField, CmdFieldKey, CmdFieldTarget, CmdFieldMode, EntityManager, FormField, CmdEvent } from '../../../models/entity-config';
import { DtoUtente } from '../../../models/utente';
import { AppManagerService, SESSION_STORAGE_NAV_TAB } from '../../../services/app-manager.service';
import { EntityRefresh, IEntityService } from '../../../services/entity.service';
import { Resources, TranslateService } from '../../../services/translate.service';
import { EntityFormComponent } from '../../entity/entity-form/entity-form.component';


@Component({
  selector: 'window-help',
  templateUrl: './window-help.component.html',
  styleUrls: ['./window-help.component.scss']
})
export class WindowHelpComponent implements OnInit, OnDestroy {
  cmdFieldTarget = CmdFieldTarget;
  cmdFieldMode = CmdFieldMode;
  cmdFieldKey = CmdFieldKey;

  private fieldLoad: CmdField = { key: CmdFieldKey.search, mode: CmdFieldMode.none, target: CmdFieldTarget.none };
  private dtoUtente: DtoUtente;
  private entityService: IEntityService<IEntity>;
  entityRefresh$: Observable<EntityRefresh>;
  entityManager: EntityManager;
  dataForm: FormGroup;
  detailFields: FormField[];
  data: any;
  sezione: string;
  entity: IEntity;
  textHeader: string;
  textHeaderDetail: string;
  entityInfo: any;
  dataSearch: any;
  edit: boolean = false;

  // translateItems: Resources;
  translatedItems$: Observable<Resources>;

  private subscription: Subscription;
  private fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  @ViewChild(EntityFormComponent) entityFormComponent;


  constructor(
    private route: ActivatedRoute,
    private appManagerService: AppManagerService,
    private translateService: TranslateService
  ) {
    this.subscription = new Subscription();
    // this.dataForm = new FormGroup({});
  }

  ngOnDestroy(): void {
    this.appManagerService.closeBrowserTab(AppManagerRoutes.GenHelpLang, this.sezione);
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    // allow to call ngOnDestroy when window is closed outside angular lifecycle
    window.onbeforeunload = () => this.ngOnDestroy();

    // listen on storage event on SESSION_STORAGE_NAV_TAB
    window.addEventListener('storage', (event) => {
      if (event.storageArea != localStorage) return;
      if (SESSION_STORAGE_NAV_TAB != event.key) return;

      const winKey = `${AppManagerRoutes.GenHelpLang.id}-${this.sezione}`;
      const storageData: any = JSON.parse(event.newValue);
      if (storageData[winKey] && !storageData[winKey].status) {
        window.close();
      }
    });

    // we need the id only once
    this.sezione = this.route.snapshot.paramMap.get('sezione');

    this.subscription.add(
      this.appManagerService.initialized$
        .subscribe(appInitialized => {
          if (!appInitialized.initialized) {
            return EMPTY;
          }

          this.dtoUtente = appInitialized.dtoUtente;

          this.entityManager = this.appManagerService.instantiateEntityManager(AppManagerRoutes.GenHelpLang);
          this.textHeader = this.entityManager.getTextComponent("windowListHeader");
          this.textHeaderDetail = this.entityManager.getTextComponent("windowDetailHeader");
          this.entityInfo = this.data;
          this.entityService = this.entityManager.getEntityService();
          this.entityRefresh$ = this.entityService.entityRefresh$;

          this.listenToEntities();

          this.translatedItems$ = this.translateService.translatedItems$(this.entityManager.translateSuffixs);

          this.dataSearch = { sezione: this.sezione, lang: this.dtoUtente.utente.currentLanguage };
          this.loadData(this.dataSearch);
        })
    );

    // this.subscription.add(
    //   this.appManagerService.initialized$
    //   .pipe(
    //     switchMap(appInitialized => {
    //       if (!appInitialized.initialized) {
    //         return EMPTY;
    //       }

    //       this.dtoUtente = appInitialized.dtoUtente;

    //       this.entityManager = this.appManagerService.instantiateEntityManager(AppManagerRoutes.GenHelpLang);
    //       this.textHeader = this.entityManager.getTextComponent("windowListHeader");
    //       this.textHeaderDetail = this.entityManager.getTextComponent("windowDetailHeader");
    //       this.entityInfo = this.data;
    //       this.entityService = this.entityManager.getEntityService();
    //       this.entityRefresh$ = this.entityService.entityRefresh$;

    //       this.listenToEntities();

    //       return this.translateService.translatedItems$(this.entityManager.translateSuffixs);

    //     })
    //   )
    //   .subscribe(translateItems => {
    //     this.translateItems = translateItems;

    //     this.dataSearch = {sezione: this.sezione, lang: this.dtoUtente.utente.currentLanguage};
    //     this.loadData(this.dataSearch);
    //   })
    // );

  }

  private listenToEntities() {
    this.subscription.add(
      this.entityService.entityEvent$.subscribe(
        entityEvent => {
          if (entityEvent && entityEvent.entities.length == 1) {
            this.entity = entityEvent.entities[0];
          }
          else {
            this.entity = this.entityManager.getEntity();
          }
          this.detailFields = this.entityManager.getDetailFields(this.entity);
        }
        // ,(error: ResultMessage) => {
        //   if(error){
        //     const messageData = buildFromResultMessage(error, MessageLevel.Error);
        //     this.appManagerService.showMessage(messageData);
        //   }
        // }
      )
    );
  }

  onCmd(field: CmdField) {
    if (this.entityManager.onCmd({ field: field, data: this.entity })) return;

    switch (field.key) {
      case CmdFieldKey.edit:
        this.edit = true;
        break;

      case CmdFieldKey.cancel:
        this.edit = false;
        break;

      case CmdFieldKey.save:
        this.save(field);
        break;
    }
  }

  onEditEvent(event) {
    this.edit = true;
  }

  onSearchEvent(event: CmdEvent) {
    this.entityManager.setEntity(event.data.data);
    this.dataSearch = event.data.data;
    this.loadData(this.dataSearch);
  }

  private newFormGroup() {
    this.entity = null;
    this.dataForm = new FormGroup({});
  }

  private loadData(data: any) {
    this.newFormGroup();
    this.entityManager.setEntitySearch(data);
    const dataSearch = this.entityManager.getDataSearch();
    this.entityService.searchEntities(this.fieldLoad, dataSearch);
  }

  save(field: CmdField) {
    this.data = this.entityFormComponent.getData();

    if (!this.data.id) {
      // add new entity item into database
      this.entityService.insertEntity(field, this.data);
      this.dataForm.markAsPristine();
    }
    else {
      // update existing Candidate item into database
      this.entityService.updateEntity(field, this.data);
      this.dataForm.markAsPristine();
    }
  }

}
