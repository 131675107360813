import { ComparisonOperator, DataSortValue, SortMode } from '../data-search';
import { Entity } from '../entity';
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, CmdFieldStyle, Icons, CmdField, FormFieldStyle } from '../entity-config';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { Validators } from '@angular/forms';


export class GenAnomaliaCalDefVincoli extends Entity {
  anomaliaId: number;
  vincoloId: number;

  vincoloCod: string;
  anomaliaSezione: string;
  anomaliaCod: string;
}

export class GenAnomaliaCalDefVincoliEMC extends EntityManagerConfig {
  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  init() {
    this.newInstanceFields = ['anomaliaId'];
    this.textComponent = {
      windowListHeader: `${this.name}.windowListHeader`
    };
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('anomaliaCod', SortMode.Asc)];

  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        label: 'generic.back', title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // Lis editable      
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary,
      }
    ];

    this.listFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'anomaliaSezione', type: FormFieldType.string, label: `${this.name}.anomaliaSezione`, sortable: true
      },
      {
        key: 'anomaliaCod', type: FormFieldType.string, label: `${this.name}.anomaliaCod`, sortable: true
      },
      {
        key: 'vincoloCod', type: FormFieldType.string, label: `${this.name}.vincoloCod`, sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'anomaliaId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'vincoloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'anomaliaSezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly,
        label: `${this.name}.anomaliaSezione`, placeholder: `${this.name}.anomaliaSezione`
      },
      {
        key: 'anomaliaCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly,
        label: `${this.name}.anomaliaCod`, placeholder: `${this.name}.anomaliaCod`
      },
      {
        key: 'objVincolo', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.modalListEditable,
        label: `${this.name}.vincoloCod`, placeholder: `${this.name}.vincoloCod`, validators: [Validators.required],
        options: {
          entityManagerInfo: AppManagerRoutes.CalDefVincoloModal, title: `${this.name}.vincoloSelectionModalHeader`,
          dataLabel: 'cod', fieldsBind: { vincoloId: 'id', vincoloCod: 'cod' },
          icon: Icons.lookup
        }
      }
    ];

    this.searchFields = [
      {
        key: 'anomaliaId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'anomaliaId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'anomaliaCod,anomaliaSezione,vincoloCod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'anomaliaId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'anomaliaId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'anomaliaCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.anomaliaCod`,
        options: { searchFields: 'anomaliaCod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'anomaliaSezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.anomaliaSezione`,
        options: { searchFields: 'anomaliaSezione', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'vincoloCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.vincoloCod`,
        options: { searchFields: 'vincoloCod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }

}
