import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-list-search',
  templateUrl: './list-search.component.html',
  styleUrls: ['./list-search.component.scss']
})
export class ListSearchComponent {
  @Input()
  searchButtonLabel: string;

  @Input()
  searchButtonTooltip: string;

  @Input()
  searchButtonIcon: string = 'fa-solid fa-search';

  @Input()
  resetButtonLabel: string;

  @Input()
  resetButtonTooltip: string;

  @Input()
  resetButtonIcon: string = 'fa-solid fa-filter-circle-xmark';

  @Input()
  toggleButtonSimpleLabel: string;

  @Input()
  toggleButtonSimpleTooltip: string;

  @Input()
  toggleButtonSimpleIcon: string = 'fa-solid fa-filter';

  @Input()
  toggleButtonAdvancedLabel: string;

  @Input()
  toggleButtonAdvancedTooltip: string;

  @Input()
  toggleButtonAdvancedIcon: string = 'fa-solid fa-filter';

  @Input()
  advancedSearch: boolean = false;

  @Input()
  elaboration: boolean = false;

  @Output()
  onSearch: EventEmitter<void> = new EventEmitter();

  @Output()
  onReset: EventEmitter<void> = new EventEmitter();

  @Output()
  advancedSearchChange: EventEmitter<boolean> = new EventEmitter();

  search(): void {
    this.onSearch.emit();
  }

  resetSearch(): void {
    this.onReset.emit();
  }

  toggleSearch(): void {
    this.advancedSearch = !this.advancedSearch;
    this.advancedSearchChange.emit(this.advancedSearch);
  }
}
