import { Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AppManagerRoutes } from "../../app-manager-routes";
import { AppInjector } from "../../app.module";
import { ComparisonOperator, DataSortValue, SortMode } from "../data-search";
import { Entity } from "../entity";
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, CmdFieldStyle, Icons, CmdEvent, FormFieldStyle } from "../entity-config";


export class SecUtenteProfiliRuoli extends Entity {
  utenteProfiliId: number;
  ruoloId: number;

  utenteEMail: string;
  profiloId: number;
  profiloCod: string;
  ruoloCod: string;
  tagCod: string;

  utenteNome: string;
  utenteCognome: string;
  utenteCodFiscale: string;
}

export class SecUtenteProfiliRuoliEMC extends EntityManagerConfig {
  router: Router;
  activatedRoute: ActivatedRoute;

  init() {
    this.newInstanceFields = ['utenteProfiliId'];
    this.textComponent = {
      windowListHeader: `${this.name}.windowListHeader`
    };
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('utenteEMail', SortMode.Asc), new DataSortValue('ruoloCod', SortMode.Asc)];

    this.router = AppInjector.get(Router);
    this.activatedRoute = AppInjector.get(ActivatedRoute);
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        label: 'generic.back', title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // List
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger
      },
    ];

    this.listFields = [
      // {
      //   key: 'id', type: FormFieldType.number, label: `${this.name}.id`, sortable: true
      // },
      {
        key: 'utenteEMail', type: FormFieldType.string, label: `${this.name}.utenteEMail`, sortable: true
      },
      {
        key: 'profiloCod', type: FormFieldType.string, label: `${this.name}.profiloCod`, sortable: true
      },
      {
        key: 'ruoloCod', type: FormFieldType.string, label: `${this.name}.ruoloCod`, sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'utenteProfiliId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'ruoloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'utenteEMail', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly,
        label: `${this.name}.utenteEMail`, placeholder: `${this.name}.utenteEMail`
      },
      {
        key: 'profiloCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly,
        label: `${this.name}.profiloCod`, placeholder: `${this.name}.profiloCod`
      },
      {
        key: 'objRuolo', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.modalListEditable,
        label: `${this.name}.ruoloCod`, placeholder: `${this.name}.ruoloCod`,
        options: {
          entityManagerInfo: AppManagerRoutes.SecRuoloModal, title: `${this.name}.ruoloSelectionModalHeader`,
          dataLabel: 'cod', fieldsBind: { ruoloId: 'id', ruoloCod: 'cod', profiloId: 'profiloId' }, fieldsBindSearch: { id: 'hiddenId', profiloId: 'profiloId' },
          icon: Icons.lookup
        },
        validators: [Validators.required]
      }
    ];

    this.searchFields = [
      {
        key: 'utenteProfiliId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'utenteProfiliId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'ruoloCod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'utenteProfiliId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'utenteProfiliId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'ruoloCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.ruoloCod`,
        options: { searchFields: 'ruoloCod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }

}
