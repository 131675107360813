import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TriggersService {
  private _triggerSaveSubject: Subject<boolean> = new Subject();
  private _triggerSaveCompletedSubject: Subject<boolean> = new Subject();
  private _triggerMoveSubject: Subject<any[]> = new Subject();
  private _triggerTabChangeSubject: Subject<number> = new Subject();
  private _triggerRefreshManiHeaderSubject: Subject<boolean> = new Subject();

  public triggerSave$: Observable<boolean> = this._triggerSaveSubject.asObservable();
  public triggerSaveCompleted$: Observable<boolean> = this._triggerSaveCompletedSubject.asObservable();
  public triggerMove$: Observable<any[]> = this._triggerMoveSubject.asObservable();
  public triggerTabChange$: Observable<number> = this._triggerTabChangeSubject.asObservable();
  public triggerRefreshManiHeader$: Observable<boolean> = this._triggerRefreshManiHeaderSubject.asObservable();
  constructor() {
  }

  triggerSave() {
    this._triggerSaveSubject.next(true);
  }

  triggerSaveCompleted() {
    this._triggerSaveCompletedSubject.next(true);
  }

  triggerUpdAfterMove(movedElements: any[]) {
    this._triggerMoveSubject.next(movedElements);
  }

  triggerTabChange(tabIndex: number) {
    this._triggerTabChangeSubject.next(tabIndex);
  }

  triggerRefreshManiHeader() {
    this._triggerRefreshManiHeaderSubject.next(true);
  }

}
