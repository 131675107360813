import { Validators } from "@angular/forms";
import { AppManagerRoutes } from "src/app/app-manager-routes";
import { DataSortValue, SortMode, ComparisonOperator, DataSearch, DataSearchValue } from "../../data-search";
import { EntityManagerConfig, CmdField, CmdFieldKey, CmdFieldMode, CmdFieldTarget, Icons, CmdFieldStyle, FormFieldType, FormFieldTypeHtml, FormFieldStyle } from "../../entity-config";
import { map } from "rxjs/operators";

export class GenRelazioneDomandaEMC extends EntityManagerConfig {

  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  init() {
    this.newInstanceFields = ['relazioneId'];
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = { windowListHeader: `${this.name}.windowListHeader` };
    this.dataSortValue = [new DataSortValue('ord', SortMode.Asc), new DataSortValue('domandaDes', SortMode.Asc)];

    const dsGenDomanda: DataSearch = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    const dsGenDomandaMaster: DataSearch = new DataSearch(
      [new DataSearchValue([this.entity.relazioneId], ['relazioneId'], ComparisonOperator.Equals), new DataSearchValue([true], ['domandaDes'], ComparisonOperator.IsNotNull)],
      [new DataSortValue('domandaDes', SortMode.Asc)]
    );

    this.attributes['gendomanda'] = this.getEntityService('gendomanda').searchEntitiesAsync(this.fLoad, dsGenDomanda);
    this.attributes['gendomandamaster'] = this.getEntityService('genrelazionedomanda').searchEntitiesAsync(this.fLoad, dsGenDomandaMaster);
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        label: 'generic.back', title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchReset',
        title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // Editable
      {
        key: CmdFieldKey.add,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listHeader,
        label: 'generic.add',
        title: 'generic.add',
        icon: Icons.plus,
        class: CmdFieldStyle.btnPrimary,
      },
      {
        key: CmdFieldKey.edit,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.edit',
        icon: Icons.edit,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.cancel,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.cancel',
        icon: Icons.back,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.delete,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.delete',
        icon: Icons.delete,
        class: CmdFieldStyle.btnSecondaryDanger,
      },
      {
        key: CmdFieldKey.save,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.save',
        icon: Icons.save,
        class: CmdFieldStyle.btnPrimary,
      }
    ];

    this.listFields = [
      {
        key: 'id',
        type: FormFieldType.number,
        sortable: true,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'relazioneId',
        type: FormFieldType.number,
        sortable: true,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'domandaId',
        type: FormFieldType.number,
        sortable: true,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'relazioneDes',
        type: FormFieldType.string,
        label: `${this.name}.relazione`,
        sortable: true
      },
      {
        key: 'ord',
        type: FormFieldType.number,
        label: `${this.name}.ord`,
        sortable: true
      },
      {
        key: 'domandaDes',
        type: FormFieldType.string,
        label: `${this.name}.domanda`,
        sortable: true
      },
      {
        key: 'domandaMasterDes',
        type: FormFieldType.string,
        label: `${this.name}.domandaMaster`,
        sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'relazioneId',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'dtIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'userIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'relazioneDes',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.readonly,
      },
      {
        key: 'ord',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.number,
        label: `${this.name}.ord`,
        placeholder: `${this.name}.ord`,
        validators: [Validators.required],
      },
      {
        key: 'domandaId',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.select,
        label: `${this.name}.domanda`,
        placeholder: `${this.name}.domanda`,
        options: {
          data: this.attributes['gendomanda'],
          dataKey: 'id',
          dataLabel: 'des',
        },
        validators: [Validators.required],
      },
      {
        key: 'domandaMasterId',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.select,
        label: `${this.name}.domandaMaster`,
        placeholder: `${this.name}.domandaMaster`,
        options: {
          data: this.attributes['gendomandamaster'],
          dataKey: 'domandaId',
          dataLabel: 'domandaDes',
        }
      }
    ];

    this.searchFields = [
      {
        key: 'relazioneId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'relazioneId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'domandaDes', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'relazioneId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'relazioneId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'domandaDes', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.domanda`,
        options: { searchFields: 'domandaDes', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }
}