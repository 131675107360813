import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ToastMessageData } from 'src/app/models/message';
import { WindowSaveData } from '../../../guards/data-saved-guard';
import { CmdField, CmdFieldKey, CmdFieldTarget, CmdFieldMode, EntityManager, FormField, FormModeType } from '../../../models/entity-config';
import { AppManagerService, SESSION_STORAGE_NAV } from '../../../services/app-manager.service';
import { EntityRefresh, IEntityService } from '../../../services/entity.service';
import { Resources, TranslateService } from '../../../services/translate.service';
import { EntityFormComponent } from '../../entity/entity-form/entity-form.component';


@Component({
  selector: 'window-detail',
  templateUrl: './window-detail.component.html',
  styleUrls: ['./window-detail.component.scss']
})

export class WindowDetailComponent implements WindowSaveData, OnInit, OnDestroy {
  cmdFieldTarget = CmdFieldTarget;
  cmdFieldMode = CmdFieldMode;
  cmdFieldKey = CmdFieldKey;

  entityRefresh$: Observable<EntityRefresh>;
  entityManager: EntityManager;
  entityService: IEntityService<any>;
  data: any;
  dataForm: FormGroup;
  detailFields: FormField[];

  textHeader: string;
  entityInfo: any;

  translateItems: Resources;
  translatedItems$: Observable<Resources>;

  formModeType = FormModeType;
  formMode: number;

  @ViewChild(EntityFormComponent) entityFormComponent;

  private fieldLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.none, target: CmdFieldTarget.none };
  private subscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appManagerService: AppManagerService,
    private translateService: TranslateService
  ) {
    this.subscription = new Subscription();
    this.dataForm = new FormGroup({});
  }

  ngOnDestroy(): void {
    if (this.entityManager.getClearCache()) {
      this.appManagerService.clearStorageData(SESSION_STORAGE_NAV, this.entityManager.id);
    }
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.params.id;
    // const url = this.activatedRoute.snapshot.url[this.activatedRoute.snapshot.url.length-1].path;

    this.subscription.add(
      this.appManagerService.initialized$.pipe(
        switchMap(appInitialized => {
          if (!appInitialized.initialized) {
            return EMPTY;
          }

          this.entityManager = this.appManagerService.getEntityManager(this.activatedRoute);

          this.entityManager.isCompleted = () => {
            if (!this.entityFormComponent) {
              return false;
            }
            return this.dataForm.pristine;
          };

          // this.detailFields = this.entityManager.getDetailFields(this.data);
          this.formMode = this.entityManager.getFormMode();
          let textHeaderKey;
          switch (this.formMode) {
            case FormModeType.insert:
              textHeaderKey = 'windowInsertHeader';
              break;
            case FormModeType.edit:
              textHeaderKey = 'windowEditHeader';
              break;
            default:
              'windowDetailHeader';
              break;
          }

          this.textHeader = this.entityManager.getTextComponent(textHeaderKey);
          this.entityInfo = this.entityManager.getEntity();
          this.entityService = this.entityManager.getEntityService();
          this.entityRefresh$ = this.entityService.entityRefresh$;

          this.listenToEntities();
          return this.entityService.getEntityAsync(this.fieldLoad, id);
        })
      )
        .subscribe(
          entityEvent => {
            this.data = entityEvent.entity;
            this.detailFields = this.entityManager.getDetailFields(this.data);
            this.translatedItems$ = this.translateService.translatedItems$(this.entityManager.translateSuffixs).pipe(
              tap(translateItems => this.translateItems = translateItems)
            );
          }
        )
    );

    // this.subscription.add(
    //   this.appManagerService.initialized$.pipe(
    //     switchMap(appInitialized => {
    //       if (!appInitialized.initialized) {
    //         return EMPTY;
    //       }

    //       this.entityManager = this.appManagerService.getEntityManager(this.activatedRoute);
    //       this.entityManager.isCompleted = () => {
    //         if (!this.entityFormComponent) {
    //           return false;
    //         }
    //         return this.dataForm.pristine;
    //       };
    //       // this.data = this.entityManager.getEntity();
    //       this.detailFields = this.entityManager.getDetailFields(this.data);
    //       this.textHeader = this.entityManager.getTextComponent("windowDetailHeader");
    //       this.entityInfo = this.entityManager.getEntity();
    //       this.entityService = this.entityManager.getEntityService();
    //       this.entityRefresh$ = this.entityService.entityRefresh$;

    //       this.listenToEntities();
    //       return this.entityService.getEntityAsync(this.fieldLoad, id);
    //     }),
    //     switchMap(entityEvent => {
    //       this.data = entityEvent.entity;
    //       return this.translateService.translatedItems$(this.entityManager.translateSuffixs);
    //     })
    //   )
    //   .subscribe(translateItems => {
    //     this.translateItems = translateItems;
    //   })
    // );

  }

  private listenToEntities() {
    this.subscription.add(
      this.entityService.entityEvent$.subscribe(
        entityEvent => {
          this.data = entityEvent.entities[0];
          this.showToastMessage(entityEvent.event);
        }
        // ,(error: ResultMessage) => {
        //   if(error){
        //     const messageData = buildFromResultMessage(error, MessageLevel.Error);
        //     this.appManagerService.showMessage(messageData);
        //   }
        // }
      )
    );
  }

  onCmd(field: CmdField) {
    if (this.entityManager.onCmd({ field: field, data: this.data })) return;

    switch (field.key) {
      case CmdFieldKey.back:
      case CmdFieldKey.cancel:
        const navItem = this.appManagerService.getBackUrl();
        this.router.navigate([navItem.url], { queryParams: navItem.queryParams });
        break;
      case CmdFieldKey.save:
        this.save(field);
        break;
    }
  }

  save(field: CmdField) {
    this.data = this.entityFormComponent.getData();

    if (!this.data.id) {
      // add new entity
      this.entityService.insertEntity(field, this.data);
      this.dataForm.markAsPristine();
      // const navItem = this.appManagerService.getBackUrl();
      // this.router.navigate([navItem.url],{queryParams: navItem.queryParams});
    }
    else {
      // update existing entity
      this.entityService.updateEntity(field, this.data);
      this.dataForm.markAsPristine();
      // const navItem = this.appManagerService.getBackUrl();
      // this.router.navigate([navItem.url],{queryParams: navItem.queryParams});
    }
  }

  reset() {
    this.dataForm.reset();
  }

  showToastMessage(field: CmdField) {
    let message: string;
    switch (field.key) {
      case CmdFieldKey.delete:
        message = this.translateItems?.['generic.deletesuccessmsg'];
        break;
      case CmdFieldKey.save:
        message = this.translateItems?.['generic.savesuccessmsg'];
        break;
    }

    if (message) {
      this.appManagerService.showToastMessage(new ToastMessageData('success', message));      
    }

    if(field.backAfterClick)
    {
      // come back to the previous page, generally the entity list page
      const navItem = this.appManagerService.getBackUrl();
      this.router.navigate([navItem.url],{queryParams: navItem.queryParams});
    }
  }

}
