import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { NotificaPars } from '../models/gestione/notificapars';


@Injectable({
  providedIn: 'root'
})
export class GenNotificheService {

  private _modalNotificaSubject: Subject<NotificaPars> = new Subject();
  public modalNotifica$: Observable<NotificaPars> = this._modalNotificaSubject.asObservable();

  constructor() {
  }

  openModalNotifica(pars: NotificaPars) {
    this._modalNotificaSubject.next(pars);
  }

  closeModalNotifica() {
    this._modalNotificaSubject.next({
      open: false,
      rowId: null,
      tabella: null,
      codNotifica: null,
      notificaSelezionabile: false,
      notificaGenerica: false,
      comiIdEnciPresel: null
    });
  }
}



