import { Component, Input, EventEmitter, Output, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { CmdFieldMode, CmdFieldKey, CmdFieldTarget, CmdField, CmdEvent, EntityManager, FormFieldType, FormFieldTypeHtml, FormField } from '../../../models/entity-config';
import { EntityRefresh, IEntityService } from '../../../services/entity.service';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { MenuItem, SortEvent } from 'primeng/api/public_api';
import { DataSearch, DataSortValue, SortMode } from '../../../models/data-search';
import { isObservable, Observable, pipe, Subscription } from 'rxjs';
import { Entity, IEntity } from '../../../models/entity';
import { compare, dataBind, dataBindReverse, isNullOrUndefined } from '../../../utils/util';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AppManagerService, SESSION_STORAGE_NAV } from '../../../services/app-manager.service';
import { Resources, TranslateService } from '../../../services/translate.service';
import { MessageDataFromConfig, MessageDataConfig, ToastMessageData } from 'src/app/models/message';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowSaveData } from '../../../guards/data-saved-guard';
import { takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'entity-list-editable',
  templateUrl: './entity-list-editable.component.html',
  styleUrls: ['./entity-list-editable.component.scss']
})
export class EntityListEditableComponent implements WindowSaveData, OnInit, OnDestroy {
  cmdFieldTarget = CmdFieldTarget;
  cmdFieldMode = CmdFieldMode;
  cmdFieldKey = CmdFieldKey;

  formFieldType = FormFieldType;
  formFieldTypeHtml = FormFieldTypeHtml;

  @Input() entityManager: EntityManager;
  @Input() dataSearch: any;
  @Input() scrollHeight: string;

  @Output() selectEvent = new EventEmitter<CmdEvent>();
  @Output() actionEvent = new EventEmitter<CmdEvent>();
  @Output() deleteEvent = new EventEmitter<CmdEvent>();

  dataForm: FormGroup;
  // dataFormArray: FormArray;

  entityRefresh$: Observable<EntityRefresh>;
  entities: IEntity[] = [];
  entitiesSelected: IEntity[] = [];
  entity: IEntity;
  // searchEntity: any;
  textSelected: string;
  scrollable: boolean = false;
  highlightedRowIndex: number;
  listFields: FormField[];
  detailFields: FormField[]; // detail fields displayed on the page
  entityInfo: any;
  entityModal: any;
  baseDataSearch: DataSearch;

  paginatorFirst: number;
  paginatorRows: number;
  paginatorRowsTot: number;

  textHeader: string;
  actionMenuItems: MenuItem[] = [];

  translateItems: Resources;
  translatedItems$: Observable<Resources>;

  @ViewChild('dataTableEdit') dataTable: Table;
  @ViewChild('firstRow') editableRow: ElementRef;

  private fieldLoad: CmdField = { key: CmdFieldKey.none, mode: CmdFieldMode.none, target: CmdFieldTarget.none };
  private subscription: Subscription;
  // private subscriptionForm: Subscription;
  private entityService: IEntityService<IEntity>;
  translate$: Observable<Resources>;
  translationRequired: Resources;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appManagerService: AppManagerService,
    private translateService: TranslateService,
  ) {
    this.subscription = new Subscription();
    // this.subscriptionForm = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    // this.subscriptionForm.unsubscribe();
  }

  ngOnInit() {
    this.listFields = this.entityManager.getListFields().filter(field => field.typeHtml != FormFieldTypeHtml.hidden);
    // this.detailFields = this.entityManager.getDetailFields().filter(field => field.typeHtml != FormFieldTypeHtml.hidden);
    this.textHeader = this.entityManager.getTextComponent("listHeader");
    this.paginatorFirst = this.entityManager.paginatorFirst ?? this.paginatorFirst;
    this.paginatorRows = this.entityManager.paginatorRows ?? this.paginatorRows;
    if (this.dataSearch) {
      this.entityManager.setEntitySearch(this.dataSearch);
    }
    this.baseDataSearch = this.entityManager.getDataSearch();
    this.entityInfo = this.entityManager.getEntity();
    this.entityService = this.entityManager.getEntityService();
    this.entityRefresh$ = this.entityService.entityRefresh$;

    this.dataForm = new FormGroup({});

    // this.dataForm = new FormGroup({
    //   tableRows: new FormArray([])
    // });
    // this.dataFormArray = this.dataForm.get('tableRows') as FormArray;

    // translations
    this.translatedItems$ = this.translateService.translatedItems$(this.entityManager.translateSuffixs)
      .pipe(
        tap(items => this.translateItems = items)
      );

    this.subscription.add(
      this.entityService.entityEvent$.subscribe(
        entityEvent => {
          this.showToastMessage(entityEvent.event);
          this.entity = undefined;
          this.entities = this.entityManager.convertDateFieldToLocalDateArray(entityEvent.entities);
          this.paginatorRowsTot = entityEvent.numRowsTot;

          if (this.actionEvent.observers.length > 0) {
            this.actionEvent.emit({ field: entityEvent.event, data: this.entities });
          }
        }
        // ,(error: ResultMessage) => {
        //   if(error){
        //     const messageData = buildFromResultMessage(error, MessageLevel.Error);
        //     this.appManagerService.showMessage(messageData);
        //   }
        // }
      )
    );

    this.entityManager.isCompleted = () => {
      if (!this.entity) {
        return true;
      }
      return this.dataForm.pristine;
      // return this.dataFormArray.controls[this.entity['index']].pristine;
    }

    this.loadData(this.fieldLoad, this.entityManager.advancedSearch);
  }

  /**
   * Table pagination event
   * For us this metho is rilevant only when lazy load is off
   *
   * @param event
   */
  onPage(event) {
    if (this.entityManager.loadLazy)
      return;

    this.paginatorFirst = event.first;
    this.paginatorRows = event.rows;
    this.entityManager.setPaginator(event.first, event.rows);
  }

  /**
   * Sort event handler
   * This event regards sorting interactions when lazy load is off
   *
   * @param event
   */
  sortFunction(event: SortEvent) {
    event.data.sort((data1, data2) => {
      return compare(data1[event.field], data2[event.field], event.order);
    });
  }

  /**
   * Table event in a lazy load context
   * This event is fired when paginator or sort event occurs
   *
   * @param event
   */
  onLazyLoad(event: TableLazyLoadEvent) {
    this.paginatorFirst = event.first;
    this.paginatorRows = event.rows;
    this.entityManager.setPaginator(event.first, event.rows);
    this.entityManager.setSort(event.sortField, event.sortOrder);
    if (this.dataTable?.initialized) {
      this.loadData(this.fieldLoad);
    }
  }

  onTbl(field: CmdField, entity: IEntity, index: number): void {
    if (this.entityManager.onCmd({ field: field, data: entity })) return;

    // if (field.hEvent) {
    //   field.hEvent();
    // }

    switch (field.key) {
      case CmdFieldKey.navigate:
        if (entity.id) {
          this.router.navigate([entity.id, field.entityManagerInfo.url], { relativeTo: this.activatedRoute });
        }
        else {
          this.router.navigate([field.entityManagerInfo.url], { relativeTo: this.activatedRoute });
        }
        break;

      case CmdFieldKey.modalListEditable:
      case CmdFieldKey.modalList:
        this.modalEMShow(field, entity);
        break;

      case CmdFieldKey.edit:
        entity['editing'] = true;
        // entity['index'] = index;
        this.entity = entity;
        this.dataForm = this.buildFormGroup(entity);
        // this.dataFormArray.controls[index] = this.buildFormGroup(entity);
        break;

      case CmdFieldKey.cancel:
        if (this.dataForm.pristine) {
          this.cancel(entity, index);
          return;
        }

        // if (this.dataFormArray.controls[index].pristine) {
        //   this.cancel(entity, index);
        //   return;
        // }
        const hEventOk = () => { this.cancel(entity, index); };
        const hEventKo = () => { };
        const messageData = new MessageDataFromConfig(MessageDataConfig.UnsavedData, hEventOk, hEventKo);
        this.appManagerService.showMessage(messageData);
        break;

      case CmdFieldKey.save:
        this.save(field);
        this.cancel(entity, index);
        break;

      case CmdFieldKey.delete:
        if (this.deleteEvent.observers.length > 0) {
          this.deleteEvent.emit({ field: field, data: entity });
        }
        else {
          this.delete(field, entity);
        }
        break;

      case CmdFieldKey.up:
      case CmdFieldKey.down:
        this.moveUpDown(field, entity);
        break;

      case CmdFieldKey.navigate:
      case CmdFieldKey.select:
        if (this.selectEvent.observers.length > 0) {
          this.selectEvent.emit({ field: field, data: entity });
        }
        break;
    }
  }

  onCmd(field: CmdField): void {
    if (this.entityManager.onCmd({ field: field, data: this.entity })) return;

    // if (field.hEvent) {
    //   field.hEvent();
    // }

    switch (field.key) {
      case CmdFieldKey.add:
        if (!this.entity) {
          this.add(field);
          this.dataForm = this.buildFormGroup(this.entity);
          if (this.entityInfo) {
            this.dataForm.patchValue(this.entityInfo, { emitEvent: false });
          }
          // this.dataFormArray.controls[this.entity['index']] = this.buildFormGroup(this.entity);
          // this.dataFormArray.patchValue([this.entityInfo]);
          this.editableRow?.nativeElement.focus();
        }
        break;

      case CmdFieldKey.select:
        if (this.selectEvent.observers.length > 0) {
          this.selectEvent.emit({ field: field, data: this.entitiesSelected });
        }
        break;

      // case CmdFieldKey.up:
      // case CmdFieldKey.down:
      //   this.moveUpDown(field);
      //   break;

      // case CmdFieldKey.clone:
      //   this.clone(field);
      //   break;
    }
  }

  onAutoComplete(field: FormField, value: any) {
    // if (field.hComplete) {
    //   field.hComplete({ field: field, event: event });
    // }
  }

  modalEMShow(field: CmdField, entity: IEntity) {
    const data = dataBind(entity, field.fieldsBind);
    const entityManager: EntityManager = this.appManagerService.instantiateEntityManager(field.entityManagerInfo);
    entityManager.setEntity(data);

    this.entityModal = {};
    this.entityModal['mode'] = 'cmd';
    this.entityModal['entityManager'] = entityManager;
    this.entityModal['field'] = field;
    this.entityModal['header'] = `${this.entityManager.name.toLowerCase()}.${entityManager.id}`;
    this.entityModal['visible'] = true;
    const dataSearch = field?.fieldsBindSearch ? dataBind(entity, field?.fieldsBindSearch) : {};

    this.entityModal['dataSearch'] = dataSearch;
  }

  modalEMHide() {
    let entityManager: EntityManager = this.entityModal['entityManager'];
    entityManager.dispose();
    this.entityModal = undefined;
    this.loadData(this.fieldLoad, this.entityManager.advancedSearch);
  }

  /**
   * Set form control value.
   * In case of field?.options?.data (select, radio, checkbox, autocomplete)
   * set the value of form control with the item selected (object any)
   * or with a field value of an item selected (item[field.key])
   * 
   * @param field field changed
   * @param value new value
   */
  onChange(field: FormField, value: any) {
    // const formGroup = <FormGroup>this.dataFormArray.controls[this.entity['index']];

    switch (field.typeHtml) {
      case FormFieldTypeHtml.modalList:
      case FormFieldTypeHtml.modalListEditable: {
        const data = dataBindReverse(value, field.options.fieldsBind);
        this.dataForm.patchValue(data, { emitEvent: false });
      }
        break;

      default: {
        if (field?.options?.data) {
          const valObj = this.dataForm.controls[field.options['dataObj']].value;
          if (field.type === FormFieldType.object) {
            this.dataForm.controls[field.key].setValue(valObj, { emitEvent: false });
          }
          else {
            if (field.options.multiselect) {
              let arr = valObj.map(t => t[field.options.dataKey]);
              this.dataForm.controls[field.key].setValue(arr.join(","), { emitEvent: false });
            }
            else {
              this.dataForm.controls[field.key].setValue(isNullOrUndefined(valObj) ? undefined : valObj[field.options.dataKey], { emitEvent: false });
            }
          }
        }
      }
        break;
    }

    if (field.hChange) {
      field.hChange({ field: field, value: value });
    }
  }

  private buildFormGroup(entity: IEntity): FormGroup {
    this.detailFields = this.entityManager.getDetailFields(entity);
    const formGroup = new FormGroup({});
    this.detailFields.forEach(field => {
      field['disabled'] = this.entityManager.isFormFieldDisabled(field, { entity: entity }) || field.typeHtml === FormFieldTypeHtml.readonly

      let fieldValue = entity[field.key];

      // for modalList and modalListEditable the fieldValue is a projection of fields "fieldsBind" in entity
      if (field.typeHtml === FormFieldTypeHtml.modalList || field.typeHtml === FormFieldTypeHtml.modalListEditable) {
        fieldValue = isNullOrUndefined(entity) ? undefined : dataBind(entity, field.options.fieldsBind);
      }

      // add control to the dataForm
      formGroup.addControl(field.key, new FormControl({ value: fieldValue, disabled: field['disabled'] }, field.validators));

      if (field.typeHtml != FormFieldTypeHtml.hidden) {
        // attach listener to the valueChange event
        this.subscription.add(
          formGroup.get(field.key).valueChanges.subscribe(
            value => this.onChange(field, value)
          )
        )

        if (field?.options?.data) {
          // add temporary control to the dataForm with suffix 'Obj'
          // this control is used with the field field.options['dataList']
          // for selection (select, radio, checkbox, autocomplete)
          field.options['dataObj'] = `${field.key}Obj`;
          formGroup.addControl(field.options['dataObj'], new FormControl({ value: undefined, disabled: field['disabled'] }));
          // attach listener to the valueChange event
          this.subscription.add(
            formGroup.get(field.options['dataObj']).valueChanges.subscribe(
              value => this.onChange(field, value)
            )
          )
        }
      }

    });

    this.detailFields.filter(field => field.options?.data)
      .forEach(field => {
        if (isObservable(field.options.data)) {
          this.subscription.add(
            field.options.data.subscribe(
              (entityEvent: any) => {
                field.options['dataList'] = entityEvent.entities;
                this.setDataObj(field, formGroup);

                // let val = entityEvent.entities.find(t => t[field.options.dataKey] === formGroup.controls[field.key].value);
                // formGroup.controls[field.options['dataObj']].setValue(val, { emitEvent: false });
              },
              error => { }
            )
          );
        }
        else {
          field.options['dataList'] = field.options.data;
          this.setDataObj(field, formGroup);

          // let val = field.options.data.find(t => t[field.options.dataKey] === formGroup.controls[field.key].value);
          // formGroup.controls[field.options['dataObj']].setValue(val, { emitEvent: false });
        }
      });

    // inject Form Control into EntityManager
    this.entityManager.setFormControl(formGroup);

    return formGroup;
  }

  /**
   * Set the value of control 'dataObj' with the item (or array of itmes) in 'dataList'
   * that match the value in formGroup.controls[field.key].
   * 
   * @param field
   * @param formGroup 
   */
  private setDataObj(field: FormField, formGroup: FormGroup) {
    const data = <any[]>field.options['dataList'];
    if (field.options.multiselect) {
      if (field.type === FormFieldType.object) {
        const val = data.filter(t => formGroup.controls[field.key].value.map(v => v[field.options.dataKey]).includes(t[field.options.dataKey]));
        formGroup.controls[field.options['dataObj']].setValue(val, { emitEvent: false });
      }
      else {
        const val = data.filter(t => formGroup.controls[field.key].value.includes(t));
        formGroup.controls[field.options['dataObj']].setValue(val, { emitEvent: false });
      }
    }
    else {
      if (field.type === FormFieldType.object) {
        let val = data.find(t => t[field.options.dataKey] === formGroup.controls[field.key][field.options.dataKey].value);
        formGroup.controls[field.options['dataObj']].setValue(val, { emitEvent: false });
      }
      else {
        let val = data.find(t => t[field.options.dataKey] === formGroup.controls[field.key].value);
        formGroup.controls[field.options['dataObj']].setValue(val, { emitEvent: false });
      }
    }
  }

  private loadData(field: CmdField, advancedSearch: boolean = false) {
    let dataSearch = this.entityManager.getDataSearch();
    if (isNullOrUndefined(dataSearch))
      return;

    // store filter data
    const data = this.entityManager.getFilterData();
    this.appManagerService.setStorageData(SESSION_STORAGE_NAV, this.entityManager.id, data);
    this.entityService.searchEntities(field, dataSearch);
  }

  private moveUpDown(field: CmdField, entity: IEntity) {
    // if (isNullOrUndefined(this.entitySelected)) return;

    // clone list of entities and sort ascending
    let tmpEntities = [...this.entities];
    // let sorting: DataSortValue = this.entityManager.getSort();
    tmpEntities.sort((data1, data2) => {
      return compare(data1[field.fieldRef], data2[field.fieldRef], SortMode.Asc);
    });

    // get entity selected index in the ordered list of entities
    let entity1 = entity;
    let index = tmpEntities.findIndex(t => t.id === entity1.id);

    // get previous entity
    let entity2;

    if (field.key === CmdFieldKey.up) {
      if (index < 1)
        return;

      entity2 = tmpEntities[index - 1];
    }
    else if (field.key === CmdFieldKey.down) {
      if (index >= (tmpEntities.length - 1))
        return;

      entity2 = tmpEntities[index + 1];
    }
    else return;

    // exchange the value of field "refField" between two entities
    let tmpVal = entity2[field.fieldRef];
    entity2[field.fieldRef] = entity1[field.fieldRef];
    entity1[field.fieldRef] = tmpVal;

    // save both two entities
    let dataSortValue: DataSortValue = new DataSortValue(field.fieldRef, SortMode.Asc);
    this.entityService.updateEntities(field, [entity1, entity2], dataSortValue);
  }

  private save(field: CmdField) {
    let entity = this.getData();

    if (!this.entity.id) {
      // remove new entity from the list
      // it will be inserted after insert operation will be completed
      this.entities.splice(0, 1);
      // add new entity item into database
      this.entityService.insertEntity(field, entity);
    }
    else {
      // update existing entity into database
      this.entityService.updateEntity(field, entity);
    }
  }

  private delete(field: CmdField, entity: IEntity) {
    this.subscription.add(this.entityService.deleteEntities(field, [entity.id]));
  }

  private add(field: CmdField) {
    // initialize new entity, setting field with search value
    this.entity = this.entityManager.getEntity() ?? new Entity();
    this.entity['editing'] = true;
    // this.entity['index'] = 0;
    this.entities.splice(0, 0, this.entity);
  }

  public search(data?: any, advancedSearch: boolean = false, paginatorFirst?: number, field?: CmdField) {
    this.paginatorFirst = isNullOrUndefined(paginatorFirst) ? 0 : paginatorFirst;
    this.entityManager.setPaginator(this.paginatorFirst, this.paginatorRows);
    if (data) {
      this.entityManager.setEntitySearch(data);
    }
    this.loadData(field ?? this.fieldLoad, advancedSearch);
  }

  public getEntitiesSelected() {
    return this.entitiesSelected;
  }

  public clearEntitiesSelected() {
    this.entitiesSelected = [];
    if (this.actionEvent.observers.length > 0) {
      this.actionEvent.emit({ field: undefined, data: this.entitiesSelected });
    }
  }

  private getData(): any {
    // let formGroup = <FormGroup>this.dataFormArray.controls[this.entity['index']];
    // let dataResult = formGroup.value;
    const dataResult = this.dataForm.value;

    // delete all temporary fields with key = ${f.key}Obj
    this.detailFields.filter(field => field?.options?.data)
      .forEach(f => {
        delete dataResult[`${f.key}Obj`];
      });

    // copy original values for field of type Hidden or Readonly
    // this.entityManager.getDetailFields().filter(field => field.typeHtml === FormFieldTypeHtml.hidden || field.typeHtml === FormFieldTypeHtml.readonly)
    this.detailFields.filter(field => field.typeHtml === FormFieldTypeHtml.readonly)
      .forEach(f => {
        dataResult[f.key] = this.entity[f.key];
      });

    // convert empty string to undefined
    Object.keys(dataResult).forEach(key => {
      let val = dataResult[key];
      if ('string' === (typeof dataResult[key]) && "" == dataResult[key]) {
        dataResult[key] = undefined;
      }
    });

    return dataResult;
  }

  private cancel(entity: IEntity, index: number): void {
    entity['editing'] = false;
    if (!this.entity.id || this.entity.id === 0) {
      this.entities.splice(0, 1);
    }
    this.entity = undefined;

    this.dataForm.reset();

    // const keys = Object.keys(this.dataForm.controls);
    // keys.forEach(key => {
    //   this.dataForm.removeControl(key)
    // });
    // this.dataForm = undefined;
    // this.dataForm = this.buildFormGroup(this.entityManager.getEntity() ?? new Entity());

    // this.subscriptionForm.unsubscribe();
    // delete this.dataForm.controls;
    // delete this.dataFormArray.controls[index];
  }

  setActionMenuItems(event, cmdFields: CmdField[]) {
    this.actionMenuItems = [];
    cmdFields.forEach(field => {
      this.actionMenuItems.push({
        label: field.label,
        title: field.title,
        icon: field.icon,
        command: () => this.onCmd(field),
        disabled: (this.entityManager.isCmdFieldDisabled(field, {})),
        styleClass: field.class,
      })
    });
    this.actionMenuItems;
  }

  showToastMessage(field: CmdField) {
    let message: string;
    switch (field.key) {
      case CmdFieldKey.delete:
        message = this.translateItems?.['generic.deletesuccessmsg'];
        break;
      case CmdFieldKey.save:
        message = this.translateItems?.['generic.savesuccessmsg'];
        break;
    }

    if (message) {
      this.appManagerService.showToastMessage(new ToastMessageData('success', message));
    }
  }

}
