import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { GenEntityLogDto } from '../../../../../app/models/gestione/dto/gen-entity-log-dto';
import { EntityLogPars } from '../../../../../app/models/gestione/entity-log-pars';
import { DataService } from '../../../../../app/services/data.service';
import { GenLogService } from '../../../../../app/services/genlog.service';
import { Resources, TranslateService } from '../../../../../app/services/translate.service';
import { dateTimeFormatWithSec } from '../../../../../app/utils/util';
import { diffTrimmedLines } from 'diff'

@Component({
  selector: 'app-entity-log',
  templateUrl: './entity-log.component.html',
  styleUrls: ['./entity-log.component.scss']
})
export class EntityLogComponent implements OnInit {
  T$: Observable<Resources>;
  moadlEntityLog$: Observable<EntityLogPars>;
  entityLog$: Observable<GenEntityLogDto[]>;
  dateTimeFormatWithSec = dateTimeFormatWithSec;
  isExpandedView: boolean = false;
  visible: boolean = false;

  constructor(
    private dataService: DataService,
    private translateService: TranslateService,
    private logService: GenLogService) {
  }

  ngOnInit(): void {
    this.T$ = this.translateService.translatedItems$(['generic.', 'entitylog.']);
    this.moadlEntityLog$ = this.logService.getEntityLogSubject();

    this.entityLog$ = this.moadlEntityLog$.pipe(
      tap((data: EntityLogPars) => {
        this.visible = data.open;
        this.isExpandedView = false;
      }),
      filter((data: EntityLogPars) => data.open),
      switchMap((data: EntityLogPars) => this.dataService.getGeneric(`${this.dataService.configSettings.restCommonUrl}/GetEntityLog`, `tableName=${data.tableName}&rowId=${data.rowId}`)),
      map((data: GenEntityLogDto[]) => {
        return data.map(d => {
          const diff = diffTrimmedLines(d.prevRowDb ?? '', d.rowDb ?? '');

          let span;

          const prevRowHtml = document.createElement('div');
          diff.filter(d => !d.added).forEach((part) => {
            span = document.createElement('span');
            if (part.removed) {
              span.className = 'hilight-log-text-removed';
            }
            span.appendChild(document.createTextNode(part.value));
            prevRowHtml.appendChild(span);
          });
          d['prevRowHtml'] = prevRowHtml;

          const rowHtml = document.createElement('div');
          diff.filter(d => !d.removed).forEach((part) => {
            span = document.createElement('span');
            if (part.added) {
              span.className = 'hilight-log-text-added';
            }
            span.appendChild(document.createTextNode(part.value));
            rowHtml.appendChild(span);
          });
          d['rowHtml'] = rowHtml;

          return d;
        })
      })
    );
  }

  hideModal(): void {
    this.logService.closeModalEntityLog();
  }
}