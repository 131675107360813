import { Entity } from "../entity";

export class GiudStatoAssistentato extends Entity {
  cod: string;
  visible: boolean;
  system: boolean;
  livello: number;
  flRichiedeEsito: boolean;

  des: string;
}

export enum StatoAssistentatoLivello {
  Inserito = 0,
  AccettatoEnci = 1,
  AccettatoGiudice = 2,
  RifiutatoEnci = 3,
  RifiutatoGiudice = 3,
  Annullato = 3
}

export enum StatoAssistentatoCod {
  Inserito = 'INS',
  AccettatoEnci = 'ACC_ENCI',
  AccettatoGiudice = 'ACC_GIUD',
  RifiutatoEnci = 'RIF_ENCI',
  RifiutatoGiudice = 'RIF_GIUD',
  Annullato = 'ANN'
}
