<ng-container *ngIf="T$ | async as T">
  <div class="surface-50 border-1 border-300 border-solid widget-card flex flex-column h-full">
    <div
      class="flex flex-row justify-content-between align-items-center column-gap-2 surface-200 text-2xl font-medium text-primary p-3 header">
      <span>{{T | translateItem: 'anomaliewidget.title'}}</span>
      <span [title]="T | translateItem: 'anomaliewidget.unmanagedanomaliescount'"
        class="badge-danger text-base">{{unmanagedAnomaliesCount}}</span>
    </div>
    <div class=" flex flex-column justify-content-between  px-4 py-3 flex-grow-1">
      <div *ngIf="data$ | async as dataList" class="flex-grow-1">
        <div *ngIf="dataList?.length === 0; else list" class="flex flex-column align-items-center mt-2">
          <i class="fa-solid fa-circle-check text-3xl success"></i>
          <p class="font-medium text-center mt-2 text-600">
            {{T | translateItem: 'anomaliewidget.nounmanagedanomalies'}}
          </p>
        </div>
        <ng-template #list>
          <div *ngFor="let row of dataList; let i = index" class="my-3">
            <p-divider *ngIf="i > 0" class="my-0"></p-divider>
            <div class="flex flex-row justify-content-between align-items-center column-gap-2">
              <div class="flex-grow-1">
                <div
                  class="flex flex-row flex-wrap justify-content-end align-items-center column-gap-5 row-gap-2 text-sm">
                  <span [title]="T | translateItem: 'anomaliewidget.data'">{{row.anomaliaData | date:
                    dateTimeFormat}}</span>
                </div>

                <div [title]="T | translateItem: 'anomaliewidget.anomaliades'" class="font-medium mt-2">
                  {{row.anomaliaDes}}
                </div>
                <div [title]="T | translateItem: 'anomaliewidget.manifestazione'" class="mt-2">
                  {{row.caleManiDes}}
                </div>
              </div>
              <button pButton type="button" [title]="T | translateItem: 'generic.showmore'"
                icon="fa-solid fa-chevron-right" iconPos="right" (click)="navigateToAnomaly(row.caleManiId, row.tipo)"
                class="p-button p-button-sm p-button-text"></button>
            </div>
          </div>
        </ng-template>
        <p-divider class="my-0"></p-divider>
      </div>

      <div class="flex flex-row justify-content-end">
        <!-- <div class="buttons-cnt">
          <button pButton type="button" [label]="T | translateItem: 'anomaliewidget.vedianomaliekm'"
            [title]="T | translateItem: 'anomaliewidget.vedianomaliekm'" icon="fa-solid fa-chevron-right"
            iconPos="right" (click)="navigateToAnomalies('KM')" class="p-button p-button-sm p-button-text"></button>
          <button pButton type="button" [label]="T | translateItem: 'anomaliewidget.vedianomalievarie'"
            [title]="T | translateItem: 'anomaliewidget.vedianomalievarie'" icon="fa-solid fa-chevron-right"
            iconPos="right" (click)="navigateToAnomalies('VA')" class="p-button p-button-sm p-button-text"></button>
        </div> -->
        <p-menu #actionmenu [popup]="true" appendTo="body" [model]="actionMenuItems"> </p-menu>
        <button type="button" pButton icon="fa-solid fa-chevron-right" iconPos="right"
          [label]="T | translateItem: 'generic.showmore'" [title]="T | translateItem: 'generic.showmore'"
          class="p-button p-button-sm p-button-text" (click)="setActionMenuItems();actionmenu.toggle($event)"></button>
      </div>
    </div>
  </div>
</ng-container>