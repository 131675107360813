import { ComparisonOperator, DataSearch, DataSortValue, SortMode } from '../data-search';
import { Entity } from '../entity';
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, CmdFieldStyle, Icons, CmdField, FormFieldStyle, } from '../entity-config';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { Validators } from '@angular/forms';
import { optionsSINO } from 'src/app/utils/util';

export class GenNotificaTipoInvio extends Entity {
  cod: string;
  visible: boolean;
  system: boolean;
  des: string;
  genTipoInvioNotificaLangList: GenNotificaTipoInvioLang[];
}

export class GenNotificaTipoInvioLang extends Entity {
  notificaTipoInvioId: number;
  lang: string;
  des: string;
  langDes: string;
}

export class GenNotificaTipoInvioEMC extends EntityManagerConfig {
  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = {
      windowListHeader: `${this.name}.windowListHeader`,
    };
    this.dataSortValue = [new DataSortValue('cod', SortMode.Asc)];
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        type: 'submit',
        label: 'generic.search',
        title: 'generic.search',
        icon: Icons.search,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.reset,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset',
        title: 'generic.searchReset',
        icon: Icons.reset,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // Editable
      {
        key: CmdFieldKey.add,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listHeader,
        label: 'generic.add',
        title: 'generic.add',
        icon: Icons.plus,
        class: CmdFieldStyle.btnPrimary,
      },
      {
        key: CmdFieldKey.edit,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.edit',
        icon: Icons.edit,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.cancel,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.cancel',
        icon: Icons.back,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.modalListEditable, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.translations', icon: Icons.globe, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenNotificaTipoInvioLangList,
        fieldsBind: { id: 'notificaTipoInvioId' }, fieldsBindSearch: { id: 'notificaTipoInvioId' }
      },
      {
        key: CmdFieldKey.delete,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.delete',
        icon: Icons.delete,
        class: CmdFieldStyle.btnSecondaryDanger,
      },
      {
        key: CmdFieldKey.save,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.save',
        icon: Icons.save,
        class: CmdFieldStyle.btnPrimary,
      },
    ];

    this.listFields = [
      {
        key: 'id',
        type: FormFieldType.number,
        sortable: true,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'cod',
        type: FormFieldType.string,
        label: `${this.name}.cod`,
        sortable: true,
      },
      {
        key: 'visible',
        type: FormFieldType.boolean,
        label: `${this.name}.visible`,
        sortable: true,
        format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } },
      },
      {
        key: 'system',
        type: FormFieldType.boolean,
        label: `${this.name}.system`,
        sortable: true,
        format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } },
      },
      {
        key: 'des',
        type: FormFieldType.string,
        label: `${this.name}.des`,
        sortable: true,
      }
    ];

    this.detailFields = [
      {
        key: 'id',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'dtIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'userIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'cod',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.cod`,
        placeholder: `${this.name}.cod`,
        validators: [Validators.required],
      },
      {
        key: 'visible',
        type: FormFieldType.boolean,
        typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.visible`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' },
        validators: [Validators.required],
      },
      {
        key: 'system',
        type: FormFieldType.boolean,
        typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.system`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' },
        validators: [Validators.required],
      },
      {
        key: 'des',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`,
        label: `${this.name}.des`,
        validators: [Validators.required],
      }
    ];

    this.searchFields = [
      {
        key: 'search',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: {
          searchFields: 'cod,des',
          searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.full
      },
    ];

    this.searchAdvancedFields = [
      {
        key: 'cod',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.cod`,
        options: {
          searchFields: 'cod',
          searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.default
      },
      {
        key: 'des',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`,
        options: {
          searchFields: 'des',
          searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.default
      },
    ];
  }
}

export class GenNotificaTipoInvioLangModalEMC extends EntityManagerConfig {

  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none }

  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = {};
    this.dataSortValue = [new DataSortValue('lang', SortMode.Asc)];

    const ds: DataSearch = new DataSearch();
    ds.dataSortValues = [new DataSortValue('id', SortMode.Asc)];

    this.attributes['genlang'] = this.getEntityService('genlang').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {
    this.cmdFields = [
      // List search
      {
        key: CmdFieldKey.search,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        type: 'submit',
        label: 'generic.search',
        title: 'generic.search',
        icon: Icons.search,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.reset,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset',
        title: 'generic.searchReset',
        icon: Icons.reset,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
        // hEvent: () => {
        //   let field = this.getCmdField(
        //     CmdFieldTarget.listSearch,
        //     CmdFieldMode.btn,
        //     CmdFieldKey.toggle
        //   );
        //   this.toggleField(
        //     field,
        //     false,
        //     true,
        //     'generic.searchAdvanced',
        //     'generic.searchBasic'
        //   );
        // },
      },

      // Editable
      {
        key: CmdFieldKey.add,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listHeader,
        label: 'generic.add',
        title: 'generic.add',
        icon: Icons.plus,
        class: CmdFieldStyle.btnPrimary,
      },
      {
        key: CmdFieldKey.edit,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.edit',
        icon: Icons.edit,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.delete,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.delete',
        icon: Icons.delete,
        class: CmdFieldStyle.btnSecondaryDanger,
      },
      {
        key: CmdFieldKey.cancel,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.cancel',
        icon: Icons.back,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.save,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.save',
        icon: Icons.save,
        class: CmdFieldStyle.btnPrimary,
      },
    ];

    this.listFields = [
      {
        key: 'lang',
        type: FormFieldType.string,
        label: `${this.name}.lang`,
        sortable: true,
      },
      {
        key: 'des',
        type: FormFieldType.string,
        label: `${this.name}.des`,
        sortable: true,
      },
    ];

    this.detailFields = [
      {
        key: 'id',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'dtIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'userIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'notificaTipoInvioId',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'lang',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.select,
        label: `${this.name}.lang`,
        placeholder: `${this.name}.lang`,
        options: {
          data: this.attributes['genlang'],
          dataKey: 'lang',
          dataLabel: 'des',
        },
        validators: [Validators.required],
      },
      {
        key: 'des',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`,
        label: `${this.name}.des`,
        validators: [Validators.required],
      },
    ];

    this.searchFields = [
      {
        key: 'notificaTipoInvioId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'notificaTipoInvioId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'search',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: {
          searchFields: 'des',
          searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.full
      },
    ];

    this.searchAdvancedFields = [
      {
        key: 'notificaTipoInvioId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'notificaTipoInvioId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'lang',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.lang`,
        options: {
          data: this.attributes['genlang'],
          dataKey: 'lang',
          dataLabel: 'des',
          searchFields: 'lang',
          searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'des',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`,
        options: {
          searchFields: 'des',
          searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.default
      },
    ];
  }
}

// export class GenNotificaTipoInvioModalEMC extends EntityManagerConfig {
//   init() {
//     this.paginatorRows = 10;
//     this.loadLazy = true;
//     this.textComponent = {
//       windowListHeader: `${this.name}.windowListHeader`,
//     };
//     this.dataSortValue = [new DataSortValue('cod', SortMode.Asc)];
//   }

//   initFields() {
//     this.cmdFields = [
//       // List search
//       {
//         key: CmdFieldKey.search,
//         mode: CmdFieldMode.btn,
//         target: CmdFieldTarget.listSearch,
//         type: 'submit',
//         label: 'generic.search',
//         title: 'generic.search',
//         icon: Icons.search,
//         class: CmdFieldStyle.btnSecondary,
//       },
//       {
//         key: CmdFieldKey.reset,
//         mode: CmdFieldMode.btn,
//         target: CmdFieldTarget.listSearch,
//         label: 'generic.searchReset',
//         title: 'generic.searchReset',
//         icon: Icons.reset,
//         class: CmdFieldStyle.btnSecondary,
//       },
//       {
//         key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
//         title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
//         state: false
//         // hEvent: () => {
//         //   let field = this.getCmdField(
//         //     CmdFieldTarget.listSearch,
//         //     CmdFieldMode.btn,
//         //     CmdFieldKey.toggle
//         //   );
//         //   this.toggleField(
//         //     field,
//         //     false,
//         //     true,
//         //     'generic.searchAdvanced',
//         //     'generic.searchBasic'
//         //   );
//         // },
//       },

//       // Editable
//       {
//         key: CmdFieldKey.add,
//         mode: CmdFieldMode.btn,
//         target: CmdFieldTarget.listHeader,
//         label: 'generic.add',
//         title: 'generic.add',
//         icon: Icons.plus,
//         class: CmdFieldStyle.btnPrimary,
//       },
//       {
//         key: CmdFieldKey.edit,
//         mode: CmdFieldMode.btn,
//         target: CmdFieldTarget.listBody,
//         title: 'generic.edit',
//         icon: Icons.edit,
//         class: CmdFieldStyle.btnSecondary,
//       },
//       {
//         key: CmdFieldKey.cancel,
//         mode: CmdFieldMode.btn,
//         target: CmdFieldTarget.listBody,
//         title: 'generic.cancel',
//         icon: Icons.back,
//         class: CmdFieldStyle.btnSecondary,
//       },
//       {
//         key: CmdFieldKey.delete,
//         mode: CmdFieldMode.btn,
//         target: CmdFieldTarget.listBody,
//         title: 'generic.delete',
//         icon: Icons.delete,
//         class: CmdFieldStyle.btnSecondaryDanger,
//       },
//       {
//         key: CmdFieldKey.save,
//         mode: CmdFieldMode.btn,
//         target: CmdFieldTarget.listBody,
//         title: 'generic.save',
//         icon: Icons.save,
//         class: CmdFieldStyle.btnPrimary,
//       },
//     ];

//     this.listFields = [
//       {
//         key: 'id',
//         type: FormFieldType.number,
//         typeHtml: FormFieldTypeHtml.hidden,
//       },
//       {
//         key: 'cod',
//         type: FormFieldType.string,
//         label: `${this.name}.cod`,
//         sortable: true,
//       },
//       {
//         key: 'visible',
//         type: FormFieldType.boolean,
//         label: `${this.name}.visible`,
//         sortable: true,
//         format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } },
//       },
//       {
//         key: 'system',
//         type: FormFieldType.boolean,
//         label: `${this.name}.system`,
//         sortable: true,
//         format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } },
//       },
//       {
//         key: 'des',
//         type: FormFieldType.string,
//         label: `${this.name}.des`,
//         sortable: true,
//       }
//     ];

//     this.detailFields = [
//       {
//         key: 'id',
//         type: FormFieldType.number,
//         typeHtml: FormFieldTypeHtml.hidden
//       },
//       {
//         key: 'dtIns',
//         type: FormFieldType.number,
//         typeHtml: FormFieldTypeHtml.hidden
//       },
//       {
//         key: 'userIns',
//         type: FormFieldType.number,
//         typeHtml: FormFieldTypeHtml.hidden
//       },
//       {
//         key: 'cod',
//         type: FormFieldType.string,
//         typeHtml: FormFieldTypeHtml.text,
//         label: `${this.name}.cod`,
//         placeholder: `${this.name}.cod`,
//         class: FormFieldStyle.default,
//         validators: [Validators.required],
//       },
//       {
//         key: 'visible',
//         type: FormFieldType.boolean,
//         typeHtml: FormFieldTypeHtml.radio,
//         label: `${this.name}.visible`,
//         options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' }, class: FormFieldStyle.default,
//         validators: [Validators.required],
//       },
//       {
//         key: 'system',
//         type: FormFieldType.boolean,
//         typeHtml: FormFieldTypeHtml.radio,
//         label: `${this.name}.system`,
//         options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' }, class: FormFieldStyle.default,
//         validators: [Validators.required],
//       },
//       {
//         key: 'des',
//         type: FormFieldType.string,
//         typeHtml: FormFieldTypeHtml.text,
//         placeholder: `${this.name}.des`,
//         label: `${this.name}.des`, class: FormFieldStyle.default,
//         validators: [Validators.required],
//       },
//     ];

//     this.searchFields = [
//       {
//         key: 'search',
//         type: FormFieldType.string,
//         typeHtml: FormFieldTypeHtml.text,
//         placeholder: `${this.name}.simpleSearchPlaceholder`,
//         options: {
//           searchFields: 'cod,des',
//           searchCompOp: ComparisonOperator.Contains,
//         }, class: FormFieldStyle.full
//       },
//     ];

//     this.searchAdvancedFields = [
//       {
//         key: 'cod',
//         type: FormFieldType.string,
//         typeHtml: FormFieldTypeHtml.text,
//         placeholder: `${this.name}.cod`,
//         options: {
//           searchFields: 'cod',
//           searchCompOp: ComparisonOperator.Contains,
//         }, class: FormFieldStyle.default
//       },
//       {
//         key: 'des',
//         type: FormFieldType.string,
//         typeHtml: FormFieldTypeHtml.text,
//         placeholder: `${this.name}.des`,
//         options: {
//           searchFields: 'des',
//           searchCompOp: ComparisonOperator.Contains,
//         }, class: FormFieldStyle.default
//       },
//     ];
//   }
// }