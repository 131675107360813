<ng-container *ngIf="T$ | async as T">
  <p-dialog *ngIf="columnList$ | async as listExport" [(visible)]="visible" [modal]="true"
    styleClass="modal-window-size-small" [resizable]="true" [closeOnEscape]="true" (onHide)="hideModal()">

    <ng-template pTemplate="header">
      <span class="p-dialog-title">
        {{T | translateItem: 'listexport.windowheadertitle'}}
      </span>
    </ng-template>

    <ng-template pTemplate="content">
      <form novalidate [formGroup]="dataForm" class="formgrid grid">
        <span class="field col-12">
          <label class="text-sm required">{{T | translateItem: 'listexport.filename'}}</label>
          <input pInputText type="text" formControlName="fileName" class="inputfield p-inputtext-sm">
        </span>
        <ng-container *ngIf="exportTemplateList$ | async as templates">
          <span *ngIf="templates.length > 0" class="field col-12">
            <label class="text-sm">{{T | translateItem: 'listexport.template'}}</label>
            <p-dropdown [options]="templates" formControlName="exportTemplateId"
              [placeholder]="T | translateItem: 'listexport.template'" [showClear]="true" optionLabel="name"
              class="inputfield p-inputtext-sm" [emptyMessage]="T | translateItem: 'generic.dropdownempty'"
              appendTo="body" (onClear)="onTemplateClear()">
            </p-dropdown>
          </span>
        </ng-container>

        <span class="field col-12">
          <p-listbox [options]="listExport" [checkbox]="true" [multiple]="true" formControlName="columns" dataKey="name"
            optionLabel="label">
            <ng-template pTemplate="header">
              {{T | translateItem: 'listexport.colonne'}}
            </ng-template>
          </p-listbox>
        </span>
      </form>
    </ng-template>

    <ng-template pTemplate="footer">
      <button pButton type="button" class="p-button p-button-outlined" (click)="hideModal()" icon="fa-solid fa-xmark"
        [label]="T | translateItem: 'generic.back'">
      </button>

      <button pButton type="button" class="p-button" (click)="exportExcel()" icon="fa-solid fa-file-arrow-down"
        [disabled]="elaboration || !dataForm.valid" [label]="T | translateItem: 'generic.export'">
      </button>
    </ng-template>
  </p-dialog>
</ng-container>