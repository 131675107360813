import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { CalTipoMani } from 'src/app/models/anagrafiche/calendario/cal-tipo-mani';
import { CalPrvSpConfig } from 'src/app/models/calendario/cal-prv-sp-config';
import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from 'src/app/models/data-search';
import { FormModeType } from 'src/app/models/entity-config';
import { GiudBloccoPrv } from 'src/app/models/giudici/giud-blocco-prv';
import { ToastMessageData } from 'src/app/models/message';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { BaseDataService } from 'src/app/services/base-data.service';
import { DataService } from 'src/app/services/data.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { dateGreatherThan } from 'src/app/utils/formValidators';
import { dateFormat, dateFormatPrNg } from 'src/app/utils/util';

@Component({
  selector: 'app-giud-blocco-prv-edit',
  templateUrl: './giud-blocco-prv-edit.component.html',
  styleUrls: ['./giud-blocco-prv-edit.component.scss']
})
export class GiudBloccoPrvEditComponent implements OnInit, OnDestroy {
  private resourceList: Resources;
  private subscription: Subscription;

  dateFormat: string = dateFormat;
  dateFormatPrNg: string = dateFormatPrNg;
  T$: Observable<Resources>;
  dataForm: FormGroup;
  formModeType: typeof FormModeType = FormModeType;
  formMode: number = FormModeType.insert;
  elaboration: boolean = false;
  tipiManiList$: Observable<CalTipoMani[]>;
  prvSpConfigListFiltered$: Observable<CalPrvSpConfig[]>;

  @Input() visible: boolean = false;
  @Input() entity: GiudBloccoPrv;
  @Input() giudiceId: number;

  @Output() onHide = new EventEmitter<boolean>();

  constructor(
    private translateService: TranslateService,
    private baseDataService: BaseDataService,
    private fb: FormBuilder,
    private dataService: DataService,
    private appManagerService: AppManagerService,
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    // Etichette
    this.T$ = this.translateService.translatedItems$(AppManagerRoutes.GiudBloccoPrv.translateSuffixs ?? ['generic.']).pipe(
      tap(data => this.resourceList = data)
    );

    // Mode
    this.formMode = (this.entity.id ?? 0) === 0 ? FormModeType.insert : FormModeType.edit;

    // Form
    this.dataForm = this.buildForm(this.entity, this.giudiceId);

    // Carico le info per le dropdown
    this.tipiManiList$ = this.baseDataService.getCalTipoManiPrvList();

    // Carico le configurazioni delle sottoprove e le filtro per il tipo di manifestazione selezionato
    this.prvSpConfigListFiltered$ = combineLatest([
      this.getPrvSpConfigList(),

      this.dataForm.get('tipoManiId').valueChanges.pipe(
        startWith(this.entity.tipoManiId),
        distinctUntilChanged()
      )
    ]).pipe(
      map(([list, filter]: [CalPrvSpConfig[], number]) => list.filter(c => c.tipoManiId === filter)),
      tap(data => {
        const field = this.dataForm.get('codProtipo');

        if (!data.some(c => c.cod === field.value)) {
          field.reset();
        }

        if (data.length > 0) {
          field.enable();
        }
        else {
          field.disable();
        }
      })
    )
  }

  hideModal(reloadData: boolean = false): void {
    this.visible = false;
    this.onHide.emit(reloadData);
  }

  save(): void {
    const data = this.dataForm.value as GiudBloccoPrv;

    if (this.formMode === FormModeType.insert) {
      this.dataService.insertElement<GiudBloccoPrv>(`${this.dataService.configSettings.restCommonUrl}/giudbloccoprv`, data).subscribe(
        () => {
          this.elaboration = false;

          this.appManagerService.showToastMessage(
            new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.savesuccessmsg')));

          this.dataForm.markAsPristine();
          this.hideModal(true);
        },
        () => {
          this.elaboration = false;
        }
      );
    }
    else if (this.formMode === FormModeType.edit) {
      this.dataService.updateElement<GiudBloccoPrv>(`${this.dataService.configSettings.restCommonUrl}/giudbloccoprv`, data, this.entity.id.toString()).subscribe(
        () => {
          this.elaboration = false;

          this.appManagerService.showToastMessage(
            new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.savesuccessmsg')));

          this.dataForm.markAsPristine();
          this.hideModal(true);
        },
        () => {
          this.elaboration = false;
        }
      );
    }
  }

  private buildForm(blocco: GiudBloccoPrv, giudiceId: number): FormGroup {
    const form = this.fb.group({
      id: this.fb.control<number | null>(0, [Validators.required]),
      giudiceId: this.fb.control<number | null>(giudiceId, [Validators.required]),
      tipoManiId: this.fb.control<number | null>(null, [Validators.required]),
      codProtipo: this.fb.control<string | null>({ value: null, disabled: true }, [Validators.required]),
      dataInizio: this.fb.control<Date | null>(null, [Validators.required]),
      dataFine: this.fb.control<Date | null>(null, [Validators.required]),
      note: this.fb.control<string | null>(null),
      temaIdPratica: this.fb.control<number | null>(null),
      userIns: this.fb.control<string | null>(null),
      dtIns: this.fb.control<Date | null>(null),
      userUpd: this.fb.control<string | null>(null),
      dtUpd: this.fb.control<Date | null>(null)
    }, {
      validators: [dateGreatherThan('dataInizio', 'dataFine')]
    });

    if (blocco) {
      form.patchValue(blocco);
    }

    return form;
  }

  private getPrvSpConfigList(): Observable<CalPrvSpConfig[]> {
    const prvSpConfigFilter = new DataSearch(
      [new DataSearchValue(['1'], ['livello'], ComparisonOperator.Equals)],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.dataService.searchElements<CalPrvSpConfig>(`${this.dataService.configSettings.restCalendarioUrl}/calprvspconfig`, prvSpConfigFilter).pipe(
      shareReplay(),
      map(data => data.entities)
    );
  }

}
