<ng-container *ngIf="T$ | async as T">
  <form [formGroup]="dataForm" novalidate>
    <app-list-search [(advancedSearch)]="advancedSearch" [elaboration]="elaboration"
      [searchButtonLabel]="T | translateItem: 'generic.search'"
      [searchButtonTooltip]="T | translateItem: 'generic.search'"
      [resetButtonLabel]="T | translateItem: 'generic.searchReset'"
      [resetButtonTooltip]="T | translateItem: 'generic.searchReset'"
      [toggleButtonAdvancedLabel]="T | translateItem: 'generic.searchAdvanced'"
      [toggleButtonAdvancedTooltip]="T | translateItem: 'generic.searchAdvanced'"
      [toggleButtonSimpleLabel]="T | translateItem: 'generic.searchBasic'"
      [toggleButtonSimpleTooltip]="T | translateItem: 'generic.searchBasic'" (onSearch)="search()"
      (onReset)="resetSearch()">
      <!-- Advanced search -->
      <ng-container advanced>
        <app-search-field-inputtext [formGroup]="dataForm" controlName="caleManiLabel"
          [placeholder]="T | translateItem: 'giudassistentato.caleMani'" ngDefaultControl />

        <app-search-field-calendar [formGroup]="dataForm" controlName="caleManiDataInizio"
          [placeholder]="T | translateItem: 'giudassistentato.caleManiDataInizio'" ngDefaultControl />

        <app-search-field-calendar [formGroup]="dataForm" controlName="caleManiDataFine"
          [placeholder]="T | translateItem: 'giudassistentato.calemaniDataFine'" ngDefaultControl />

        <app-search-field-inputtext [formGroup]="dataForm" controlName="giudiceNome"
          [placeholder]="T | translateItem: 'giudassistentato.giudiceNome'" ngDefaultControl />

        <app-search-field-inputtext [formGroup]="dataForm" controlName="giudiceCognome"
          [placeholder]="T | translateItem: 'giudassistentato.giudiceCognome'" ngDefaultControl />
      </ng-container>

      <!-- Simple search -->
      <ng-container simple>
        <div class="col-12">
          <input pInputText formControlName="simpleSearch"
            [placeholder]="T | translateItem : 'giudassistentato.simpleSearchPlaceholderGrouped'"
            class="p-inputtext-sm" />
        </div>
      </ng-container>
    </app-list-search>
  </form>
</ng-container>