import { BehaviorSubject, EMPTY, Observable, of, ReplaySubject, Subscription } from "rxjs";
import { DataService } from "./data.service";
// import { SubscriptionManager } from "../utils/subscription-manager";
import { DataSearch, DataSortValue } from "../models/data-search";
import { FileData } from "../models/file-data";
import { base64ToBlob, compare } from "../utils/util";
// import { ConfigField } from "../models/view-config";
import { isNullOrUndefined } from "../utils/util";
import { IEntityList } from "../models/entity";
import { switchMap } from "rxjs/operators";


export const ENTITY_ERROR_NOT_FOUND = { status: 0, statusText: 'Not Found Error', message: 'Item not found!: Not Found Error', name: 'TEST ERROR', ok: false };

/**
 * Info about the url of api rest service
 */
export interface EntityUrl {
  // base url of the service
  baseUrl: string;
  // relative path of url used to get a list of entities
  searchEntities?: string;
  // relative path of url used to get a list of entities
  entities?: string;
  // relative path of url used to get single entity
  entity?: string;
  // relative path of url used to insert new entity
  insert?: string;
  // relative path of url used to upsert entity
  upsert?: string;
  // relative path of url used to update entity
  update?: string;
  // relative path of url used to delete entity
  delete?: string;
  // relative path of url used to clone entities
  clone?: string;
  // relative path of url used to get info about new instance (new instance used to add/insert new record)
  newInstance?: string;
  // relative path of url used to export entities
  exportFile?: string;
  // relative path of url used to import entities
  importFile?: string;
  // relative path of url used to get the print of entities
  printEntities?: string;
  // relative path of url used to upload/deventload file to repository
  repositoryFile?: string;
  // relative path of url used to retrieve url of resource file in the repository
  repositoryResourceURL?: string;
  // relative path of url used to launch a process
  launchProcess?: string;
}

export interface EntityServiceInfo {
  name: string;
  // url: EntityUrl;
  serviceName: 'restCommon' | 'restCalendario';
  keyFields?: string[];
}

export interface EntityRefresh {
  event: any,
  refreshing: boolean
}

export interface EntityEvent<T> {
  event: any,
  entity: T,
}

export interface EntitiesEvent<T> {
  event: any,
  entities: T[],
  numRowsTot?: number
}

/**
 * Service interface that define the generic service used to perform the essential operations on entity
 */
export interface IEntityService<T> {
  // name of the entity
  entityTypeName: string;
  // fields used to compose the entity key
  keyFields: string[];
  // observable notifies that the list is changing    
  entityRefresh$: Observable<EntityRefresh>;
  // observable of list of entities
  entityEvent$: Observable<EntitiesEvent<T>>;

  // // total number of records listing in a paginator table
  // paginatorRowsTot: number;
  // // number of elements in entities
  // entitiesCount: number;

  destroy(): void;

  /**
   * Return the entities that match the fields of param entity
   *
   * @param entity fields used to compose the query filter
   */
  getEntities(event: any, entity?: T): void;

  /**
   * Return the entities that match the fields of param entity
   *
   * @param entity fields used to compose the query filter
   * as an observable
   */
  getEntitiesAsync(event: any, entity?: T): Observable<EntitiesEvent<T>>;

  /**
   * Return the entities that match the fields of param entity
   * and are not in the "excluded list" excludedEntities.
   *
   * @param entity fields used to compose the query filter
   * @param excludedEntities list of entities to exclude
   */
  getFilteredEntities(event: any, entity?: T, excludedEntities?: any[]): void;

  /**
   * Return the entities that match the fields in DataSearch
   * 
   * @param dataSearch contains fields, values and comparison operator
   */
  searchEntities(event: any, dataSearch: DataSearch): void;

  /**
   * Return the entities that match the fields in DataSearch
   * as an observable
   * 
   * @param dataSearch contains fields, values and comparison operator
   */
  searchEntitiesAsync(event: any, dataSearch: DataSearch): Observable<EntitiesEvent<T>>;

  /**
   * Return the entities that match the fields in DataSearch
  * and are not in the "excluded list" excludedEntities.
  *
  * @param dataSearch contains fields, values and comparison operator
  * @param excludedEntities list of entities to exclude
   */
  searchEntitiesFiltered(event: any, dataSearch: DataSearch, excludedEntities?: any[]): void;

  /**
   * Return entity that match the param id
   * 
   * @param id id of the entity
   */
  getEntityAsync(event: any, id: number | string): Observable<EntityEvent<T>>;

  /**
   * Return new instance used as base entity to add/insert new record
   * 
   * @param id id of the entity
   */
  getNewInstance(event: any, queryStringParam: string): Observable<EntityEvent<T>>;

  /**
   * Insert new entity and add new item to the entities collection.
   *      
   * @param entity
   */
  insertEntity(event: any, entity: T, dataSortValue?: DataSortValue): void;

  /**
   * Insert new entities and add new items to the entities collection.
   * 
   * @param entity
   */
  insertEntities(event: any, entity: T[], dataSortValue?: DataSortValue): void;

  /**
   * Insert/Update entities and add/update items to the entities collection.
   * 
   * @param entity
   */
  upsertEntities(event: any, entity: T[], dataSortValue?: DataSortValue): void;

  /**
   * Update the entity and merge the item in the entities collection.
   * All fields of the entity are replaced.
   *      
   * @param entity
   */
  updateEntity(event: any, entity: T): void;

  /**
   * Update the entities and merge the items in the entities collection.
   * All fields of the entities are replaced.
   * 
   * @param entities
   */
  updateEntities(event: any, entities: T[], dataSortValue?: DataSortValue): void;

  /**
  * Delete the entity and remove the item from the entities collection.
  *      
  * @param entity
  */
  deleteEntity(event: any, id: number | string): void;

  /**
   * Delete the entities and remove the item from the entities collection.
   * 
   * @param ids
   */
  deleteEntities(event: any, ids: number[] | string[]): void;

  /**
   * Generate print of the entities
   * 
   * @param ids
   */
  printEntities(event: any, ids: number[] | string[]): Observable<File>;

  /**
   * Add new entity to the list "entities"
   * but does not make any changes to the database
   *      
   * @param entity
   */
  insertEntitiesOffline(event: any, entities: T[], dataSortValue?: DataSortValue): void;

  /**
   * Update the entities
   * but does not make any changes to the database
   * 
   * @param field 
   * @param entities 
   * @param dataSortValue 
   */
  updateEntitiesOffline(event: any, entities: T[], dataSortValue?: DataSortValue): void;

  /**
   * Remove items from the list "entities"
   * but does not make any changes to the database
   * 
   * @param ids
   */
  deleteEntitiesOffline(event: any, ids: number[] | string[]): void;

  /**
   * Get file from repository
   * 
   * @param fileUpload contains info about file to retrieve
   */
  getResourceRepository(event: any, fileData: FileData): Observable<File>;

  /**
   * Get URL of resources from repository
   * 
   * @param fileUpload contains info about file to retrieve
   */
  getUrlResourceRepository(event: any, fileData: FileData): Observable<string>;

  /**
  * Delete file from repository
  * 
  * @param fileUpload contains info about file to retrieve
  */
  deleteResourceRepository(event: any, fileData: FileData): Observable<boolean>;

}

/**
 * Generic entity service used to perform the essential operations on entity
 */
export class EntityService<T> implements IEntityService<T> {
  subscription: Subscription = new Subscription();

  entityTypeName: string;
  entityUrl: EntityUrl;
  keyFields: string[];
  protected _numRowsTot: number;
  protected _entityRefresh: BehaviorSubject<EntityRefresh>;
  protected _entityEvent: ReplaySubject<EntitiesEvent<T>>;
  protected entities: T[];

  constructor(
    private vDataService: DataService,
    private entityServiceInfo: EntityServiceInfo
  ) {
    this.entityTypeName = entityServiceInfo.name.toLocaleLowerCase();


    this.entityUrl = {
      baseUrl: this.vDataService.getConfigUrl(entityServiceInfo.serviceName)
    }
    // this.entityUrl = entityServiceInfo.url;

    if (!this.entityUrl.baseUrl.endsWith('/')) {
      this.entityUrl.baseUrl += '/';
    }

    if (isNullOrUndefined(this.entityUrl.entity))
      this.entityUrl.entity = this.entityUrl.baseUrl + this.entityTypeName;

    if (isNullOrUndefined(this.entityUrl.entities))
      this.entityUrl.entities = this.entityUrl.baseUrl + this.entityTypeName + 'ls';

    if (isNullOrUndefined(this.entityUrl.searchEntities))
      this.entityUrl.searchEntities = this.entityUrl.baseUrl + this.entityTypeName; // + 'se';

    if (isNullOrUndefined(this.entityUrl.insert))
      this.entityUrl.insert = this.entityUrl.baseUrl + this.entityTypeName;

    if (isNullOrUndefined(this.entityUrl.upsert))
      this.entityUrl.upsert = this.entityUrl.baseUrl + this.entityTypeName + 'us';

    if (isNullOrUndefined(this.entityUrl.clone))
      this.entityUrl.clone = this.entityUrl.baseUrl + this.entityTypeName + 'cl';

    if (isNullOrUndefined(this.entityUrl.newInstance))
      this.entityUrl.newInstance = this.entityUrl.baseUrl + this.entityTypeName + 'ni';

    if (isNullOrUndefined(this.entityUrl.exportFile))
      this.entityUrl.exportFile = this.entityUrl.baseUrl + this.entityTypeName + 'ef';

    if (isNullOrUndefined(this.entityUrl.importFile))
      this.entityUrl.importFile = this.entityUrl.baseUrl + this.entityTypeName + 'if';

    if (isNullOrUndefined(this.entityUrl.printEntities))
      this.entityUrl.printEntities = this.entityUrl.baseUrl + this.entityTypeName + 'pr';

    if (isNullOrUndefined(this.entityUrl.repositoryFile))
      this.entityUrl.repositoryFile = this.entityUrl.baseUrl + this.entityTypeName + 'rf';

    if (isNullOrUndefined(this.entityUrl.repositoryResourceURL))
      this.entityUrl.repositoryResourceURL = this.entityUrl.baseUrl + this.entityTypeName + 'uf';

    if (isNullOrUndefined(this.entityUrl.update))
      this.entityUrl.update = this.entityUrl.baseUrl + this.entityTypeName;

    if (isNullOrUndefined(this.entityUrl.delete))
      this.entityUrl.delete = this.entityUrl.baseUrl + this.entityTypeName;

    if (isNullOrUndefined(this.entityUrl.launchProcess))
      this.entityUrl.launchProcess = this.entityUrl.baseUrl + this.entityTypeName + 'lc';

    this.keyFields = entityServiceInfo.keyFields ?? ['id'];

    this._entityRefresh = new BehaviorSubject<any>({ event: undefined, refreshing: false });
    this._entityEvent = new ReplaySubject<EntitiesEvent<T>>(1);
    this.entities = [];
  }

  destroy() {
    this.subscription.unsubscribe();

    if (!isNullOrUndefined(this._entityRefresh))
      this._entityRefresh.unsubscribe

    if (!isNullOrUndefined(this._entityEvent))
      this._entityEvent.unsubscribe
  }

  get entityEvent$(): Observable<EntitiesEvent<T>> {
    return this._entityEvent.asObservable();
  }

  get entityRefresh$(): Observable<EntityRefresh> {
    return this._entityRefresh.asObservable();
  }

  /**
   * Return the entities that match the fields of param entity
   * 
   * @param entity fields used to compose the query filter
   */
  public getEntities(event: any, entity?: T): void {
    this._entityRefresh.next({ event: event, refreshing: true });
    this.subscription.add(this.vDataService.getElements<T>(this.entityUrl.entities, toQueryString(entity)).subscribe(
      (entityList: IEntityList<T>) => {
        this._numRowsTot = entityList.numRowsTot;
        this.entities = entityList.entities ?? [];
        this._entityEvent.next({ event: event, entities: this.entities, numRowsTot: this._numRowsTot });
        this._entityRefresh.next({ event: event, refreshing: false });
      },
      error => {
        this._entityEvent.error(error);
        this._entityRefresh.next({ event: event, refreshing: false });
      }
    ));
  }

  /**
   * Return the entities that match the fields of param entity
   * 
   * @param entity fields used to compose the query filter
   */
  public getEntitiesAsync(event: any, entity?: T): Observable<EntitiesEvent<T>> {
    return this.vDataService.getElements<T>(this.entityUrl.entities, toQueryString(entity))
      .pipe(
        switchMap(entityList => {
          const entityEvent: EntitiesEvent<T> = {
            entities: entityList.entities,
            numRowsTot: entityList.numRowsTot,
            event: event
          }
          return of(entityEvent)
        })
      );
  }

  /**
   * Return the entities that match the fields of param entity
   * and are not in the "excluded list" excludedEntities.
   * 
   * @param entity fields used to compose the query filter
   * @param excludedEntities list of entities to exclude
   */
  public getFilteredEntities(event: any, entity?: T, excludedEntities?: any[]): void {
    this._entityRefresh.next({ event: event, refreshing: true });
    this.subscription.add(this.vDataService.getElements<T>(this.entityUrl.entities, toQueryString(entity)).subscribe(
      (entityList: IEntityList<T>) => {
        this.entities = entityList.entities ?? [];
        this.entities = filterExcludedEntities(this.entities, excludedEntities);
        this._numRowsTot = this.entities.length;
        this._entityEvent.next({ event: event, entities: this.entities, numRowsTot: this._numRowsTot });
        this._entityRefresh.next({ event: event, refreshing: false });
      },
      error => {
        this._entityEvent.error(error);
        this._entityRefresh.next({ event: event, refreshing: false });
      }
    ));
  }

  /**
  * Return the entities that match the fields in DataSearch
  *
  * @param dataSearch contains fields, values and comparison operator
  */
  public searchEntities(event: any, dataSearch: DataSearch): void {
    this._entityRefresh.next({ event: event, refreshing: true });
    this.subscription.add(this.vDataService.searchElements<T>(this.entityUrl.searchEntities, dataSearch)
      .subscribe(
        (entityList: IEntityList<T>) => {
          this._numRowsTot = entityList.numRowsTot;
          this.entities = entityList.entities ?? [];
          this._entityEvent.next({ event: event, entities: this.entities, numRowsTot: this._numRowsTot });
          this._entityRefresh.next({ event: event, refreshing: false });
        },
        error => {
          this._entityEvent.error(error);
          this._entityRefresh.next({ event: event, refreshing: false });
        }
      ));
  }

  /**
  * Return the entities that match the fields in DataSearch
  *
  * @param dataSearch contains fields, values and comparison operator
  */
  public searchEntitiesAsync(event: any, dataSearch: DataSearch): Observable<EntitiesEvent<T>> {
    return this.vDataService.searchElements<T>(this.entityUrl.searchEntities, dataSearch)
      .pipe(
        switchMap(entityList => {
          const entityEvent: EntitiesEvent<T> = {
            entities: entityList.entities,
            numRowsTot: entityList.numRowsTot,
            event: event
          }
          return of(entityEvent)
        })
      );
  }

  /**
  * Return the entities that match the fields in DataSearch
  * and are not in the "excluded list" excludedEntities.
  *
  * @param dataSearch contains fields, values and comparison operator
  * @param excludedEntities list of entities to exclude
  */
  public searchEntitiesFiltered(event: any, dataSearch: DataSearch, excludedEntities?: any[]): void {
    this._entityRefresh.next({ event: event, refreshing: true });
    this.subscription.add(this.vDataService.searchElements<T>(this.entityUrl.searchEntities, dataSearch).subscribe(
      (entityList: IEntityList<T>) => {
        this.entities = entityList.entities ?? [];
        this.entities = filterExcludedEntities(this.entities, excludedEntities);
        this._numRowsTot = this.entities.length;
        this._entityEvent.next({ event: event, entities: this.entities, numRowsTot: this._numRowsTot });
        this._entityRefresh.next({ event: event, refreshing: false });
      },
      error => {
        this._entityEvent.error(error);
        this._entityRefresh.next({ event: event, refreshing: false });
      }
    ));
  }

  // /**
  //  * Return entity that match the param id
  //  * 
  //  * @param id id of the entity
  //  */
  // public getEntity(event: any, id: number | string): Observable<T> {
  //   let result$: ReplaySubject<T> = new ReplaySubject(1);
  //   this._entityRefresh.next({ event: event, refreshing: true });
  //   this.subscription.add(this.vDataService.getElementById<T>(this.entityUrl.entity, id).subscribe(
  //     entity => {
  //       result$.next(entity);
  //       this._entityRefresh.next({ event: event, refreshing: false });
  //     },
  //     error => {
  //       this._entityRefresh.next({ event: event, refreshing: false });
  //       result$.error(error);
  //     }
  //   ));
  //   return result$.asObservable();
  // }


  /**
  * Return the entities that match the fields in DataSearch
  *
  * @param dataSearch contains fields, values and comparison operator
  */
  public getEntityAsync(event: any, id: number | string): Observable<EntityEvent<T>> {
    return this.vDataService.getElementById<T>(this.entityUrl.entity, id)
      .pipe(
        switchMap(entity => {
          const entityEvent: EntityEvent<T> = {
            entity: entity,
            event: event
          }
          return of(entityEvent)
        })
      );
  }

  /**
   * Return new instance used as base entity to add/insert new record
   * Id refers to the entity associated with the new entity instance to be returned
   * 
   * @param id id of the reference entity (associated entity)
   */
  getNewInstance(event: any, queryStringParam: string): Observable<EntityEvent<T>> {
    return this.vDataService.getElementByQuery<T>(this.entityUrl.newInstance, queryStringParam)
      .pipe(
        switchMap(entity => {
          const entityEvent: EntityEvent<T> = {
            entity: entity,
            event: event
          }
          return of(entityEvent)
        })
      );
  }

  /**
   * Insert new entity using the service
   * and add new item to the entities collection.
   *      
   * @param entity
   */
  public insertEntity(event: any, entity: T, dataSortValue?: DataSortValue): void {
    this._entityRefresh.next({ event: event, refreshing: true });
    this.subscription.add(this.vDataService.insertElement<T>(this.entityUrl.insert, entity).subscribe(
      entity => {
        this.entities.unshift(entity); // this.entities.push(entity);
        this._numRowsTot += 1;

        // sort entities if required
        if (!isNullOrUndefined(dataSortValue)) {
          this.entities.sort((data1, data2) => {
            return compare(data1[dataSortValue.field], data2[dataSortValue.field], dataSortValue.sortMode);
          });
        }

        this._entityEvent.next({ event: event, entities: this.entities, numRowsTot: this._numRowsTot });
        this._entityRefresh.next({ event: event, refreshing: false });
      },
      error => {
        this._entityEvent.error(error);
        this._entityRefresh.next({ event: event, refreshing: false });
      }
    ));
  }

  /**
   * Insert new entities using the service
   * and add new items to the entities collection.
   * 
   * @param entities
   */
  public insertEntities(event: any, entities: T[], dataSortValue?: DataSortValue): void {
    this._entityRefresh.next({ event: event, refreshing: true });
    this.subscription.add(this.vDataService.insertElements<T>(this.entityUrl.insert, entities).subscribe(
      (entityList: IEntityList<T>) => {
        this.entities.push(...entityList.entities);
        this._numRowsTot += entityList.entities.length;

        // sort entities if required
        if (!isNullOrUndefined(dataSortValue)) {
          this.entities.sort((data1, data2) => {
            return compare(data1[dataSortValue.field], data2[dataSortValue.field], dataSortValue.sortMode);
          });
        }

        this._entityEvent.next({ event: event, entities: this.entities, numRowsTot: this._numRowsTot });
        this._entityRefresh.next({ event: event, refreshing: false });
      },
      error => {
        this._entityEvent.error(error);
        this._entityRefresh.next({ event: event, refreshing: false });
      }
    ));
  }

  /**
   * Insert/Update entities using the service
   * and add/update items to the entities collection.
   * 
   * @param entity
   */
  public upsertEntities(event: any, entities: T[], dataSortValue?: DataSortValue): void {
    this._entityRefresh.next({ event: event, refreshing: true });
    this.subscription.add(this.vDataService.upsertElements<T>(this.entityUrl.upsert, entities).subscribe(
      (entityList: IEntityList<T>) => {
        let newEntities: T[] = [];

        // discard all entities which have not a valid "key fields" value (entities inserted in offline mode)
        this.entities = getEntitiesWithValidValueInFields(this.entities, this.keyFields);

        // update existing entities
        entityList.entities.forEach(entity => {
          let index = this.entities.findIndex(t => compareEntityFields(t, entity, this.keyFields));
          if (index >= 0) {
            this.entities[index] = entity;
          }
          else {
            newEntities.push(entity);
          }
        });

        // add new entities
        if (newEntities.length > 0) {
          this.entities.push(...newEntities);
          this._numRowsTot += newEntities.length;
        }

        // sort entities if required
        if (!isNullOrUndefined(dataSortValue)) {
          this.entities.sort((data1, data2) => {
            return compare(data1[dataSortValue.field], data2[dataSortValue.field], dataSortValue.sortMode);
          });
        }

        this._entityEvent.next({ event: event, entities: this.entities, numRowsTot: this._numRowsTot });
        this._entityRefresh.next({ event: event, refreshing: false });
      },
      error => {
        this._entityEvent.error(error);
        this._entityRefresh.next({ event: event, refreshing: false });
      }
    ));
  }

  /**
   * Update the entity using the service
   * and merge the item in the entities collection.
   * All fields of the entity are replaced.
   *      
   * @param entity
   */
  public updateEntity(event: any, entity: T): void {
    this._entityRefresh.next({ event: event, refreshing: true });
    this.subscription.add(this.vDataService.updateElement<T>(this.entityUrl.update, entity, entity['id']).subscribe(
      entity => {
        let index = this.entities.findIndex(t => compareEntityFields(t, entity, this.keyFields));
        if (index >= 0) {
          this.entities[index] = entity;
        }
        // else {
        //   this.entities.push(entity);
        // }

        this._entityEvent.next({ event: event, entities: this.entities, numRowsTot: this._numRowsTot });
        this._entityRefresh.next({ event: event, refreshing: false });
      },
      error => {
        this._entityEvent.error(error);
        this._entityRefresh.next({ event: event, refreshing: false });
      }
    ));
  }

  /**
   * Update the entities using the service
   * and merge the items in the entities collection.
   * All fields of the entities are replaced.
   * 
   * @param entities
   */
  public updateEntities(event: any, entities: T[], dataSortValue?: DataSortValue): void {
    this._entityRefresh.next({ event: event, refreshing: true });
    this.subscription.add(this.vDataService.updateElements<T>(this.entityUrl.update, entities).subscribe(
      (entityList: IEntityList<T>) => {
        let index;
        entityList.entities.forEach(entity => {
          index = this.entities.findIndex(t => compareEntityFields(t, entity, this.keyFields));
          if (index >= 0) {
            this.entities[index] = entity;
          }
          else {
            this.entities.push(entity);
          }
        });

        // sort entities if required
        if (!isNullOrUndefined(dataSortValue)) {
          this.entities.sort((data1, data2) => {
            return compare(data1[dataSortValue.field], data2[dataSortValue.field], dataSortValue.sortMode);
          });
        }

        this._entityEvent.next({ event: event, entities: this.entities, numRowsTot: this._numRowsTot });
        this._entityRefresh.next({ event: event, refreshing: false });
      },
      error => {
        this._entityEvent.error(error);
        this._entityRefresh.next({ event: event, refreshing: false });
      }
    ));
  }

  /**
   * Delete the entity using the service
   * and remove the item from the entities collection.
   *      
   * @param entity
   */
  public deleteEntity(event: any, id: number | string): void {
    this._entityRefresh.next({ event: event, refreshing: true });
    this.subscription.add(this.vDataService.deleteElement<T>(this.entityUrl.delete, id).subscribe(
      numRows => {
        if (numRows == 1) {
          let index = this.entities.findIndex(t => t['id'] === id);
          if (index >= 0) {
            this.entities.splice(index, 1);
          }
          this._numRowsTot -= numRows;
        }
        this._entityEvent.next({ event: event, entities: this.entities, numRowsTot: this._numRowsTot });
        this._entityRefresh.next({ event: event, refreshing: false });
      },
      error => {
        this._entityEvent.error(error);
        this._entityRefresh.next({ event: event, refreshing: false });
      }
    ));
  }

  /**
   * Detete all entities that the id is in the ids array
   * 
   * @param ids array of ids of the entities to delete
   */
  public deleteEntities(event: any, ids: number[] | string[]): void {
    this._entityRefresh.next({ event: event, refreshing: true });
    this.subscription.add(this.vDataService.deleteElements<T>(this.entityUrl.delete, ids).subscribe(
      numRows => {
        if (numRows > 0) {
          let index;
          for (let i = ids.length - 1; i >= 0; i--) {
            index = this.entities.findIndex(t => t['id'] === ids[i]);
            if (index >= 0) {
              this.entities.splice(index, 1);
              this._numRowsTot--;
            }
          }
        }
        this._entityEvent.next({ event: event, entities: this.entities, numRowsTot: this._numRowsTot });
        this._entityRefresh.next({ event: event, refreshing: false });
      },
      error => {
        this._entityEvent.error(error);
        this._entityRefresh.next({ event: event, refreshing: false });
      }
    ));
  }

  /**
   * Return file from repository
   * 
   * @param fileUpload contains info about file to retrieve
   */
  public printEntities(event: any, ids: number[] | string[]): Observable<File> {
    let result$: ReplaySubject<File> = new ReplaySubject(1);

    this._entityRefresh.next({ event: event, refreshing: true });
    this.subscription.add(this.vDataService.printElements(this.entityUrl.printEntities, ids).subscribe(
      fileData => {
        if (fileData) {
          let blob = base64ToBlob(fileData.fileStringData);
          let file: File = new File([blob], fileData.fileName);
          result$.next(file);
        }
        this._entityRefresh.next({ event: event, refreshing: false });
      },
      error => {
        this._entityRefresh.next({ event: event, refreshing: false });
        result$.error(error);
      }
    ));

    return result$.asObservable();
  }

  /**
   * Add new entity to the list "entities"
   * but does not make any changes to the database
   *
   * @param entity
   */
  public insertEntitiesOffline(event: any, entities: T[], dataSortValue?: DataSortValue) {
    this._entityRefresh.next({ event: event, refreshing: true });

    this.entities.push(...entities);
    this._numRowsTot += entities.length;

    // sort entities if required
    if (!isNullOrUndefined(dataSortValue)) {
      this.entities.sort((data1, data2) => {
        return compare(data1[dataSortValue.field], data2[dataSortValue.field], dataSortValue.sortMode);
      });
    }

    this._entityEvent.next({ event: event, entities: this.entities, numRowsTot: this._numRowsTot });

    setTimeout(() => { this._entityRefresh.next({ event: event, refreshing: false }) }, 300);
  }

  /**
   * Update the entities
   * but does not make any changes to the database
   * 
   * @param field 
   * @param entities 
   * @param dataSortValue 
   */
  updateEntitiesOffline(event: any, entities: T[], dataSortValue?: DataSortValue) {
    this._entityRefresh.next({ event: event, refreshing: true });

    let index;
    this.entities.forEach(entity => {
      index = this.entities.findIndex(t => compareEntityFields(t, entity, this.keyFields));
      if (index >= 0) {
        this.entities[index] = entity;
      }
    });

    // sort entities if required
    if (!isNullOrUndefined(dataSortValue)) {
      this.entities.sort((data1, data2) => {
        return compare(data1[dataSortValue.field], data2[dataSortValue.field], dataSortValue.sortMode);
      });
    }

    this._entityEvent.next({ event: event, entities: this.entities, numRowsTot: this._numRowsTot });

    setTimeout(() => { this._entityRefresh.next({ event: event, refreshing: false }) }, 300);
  }

  /**
   * Remove items from the list "entities"
   * but does not make any changes to the database
   * 
   * @param ids
   */
  public deleteEntitiesOffline(event: any, ids: number[] | string[]) {
    this._entityRefresh.next({ event: event, refreshing: true });

    let result$: ReplaySubject<number[] | string[]> = new ReplaySubject(1);

    let index;
    for (let i = ids.length - 1; i >= 0; i--) {
      index = this.entities.findIndex(t => t['id'] === ids[i]);
      if (index >= 0) {
        this.entities.splice(index, 1);
        this._numRowsTot--;
      }
    }

    this._entityEvent.next({ event: event, entities: this.entities, numRowsTot: this._numRowsTot });

    setTimeout(() => { this._entityRefresh.next({ event: event, refreshing: false }) }, 300);
  }

  /**
   * Return file from repository
   * 
   * @param fileUpload contains info about file to retrieve
   */
  public getResourceRepository(event: any, fileData: FileData): Observable<File> {
    let result$: ReplaySubject<File> = new ReplaySubject(1);

    this._entityRefresh.next({ event: event, refreshing: true });
    this.subscription.add(this.vDataService.getResourceRepository(this.entityUrl.repositoryFile, fileData).subscribe(
      fileData => {
        let blob = base64ToBlob(fileData.fileStringData);
        let file: File = new File([blob], fileData.fileName);
        result$.next(file);
        this._entityRefresh.next({ event: event, refreshing: false });
      },
      error => {
        this._entityRefresh.next({ event: event, refreshing: false });
        result$.error(error);
      }
    ));

    return result$.asObservable();
  }

  /**
   * Return URl of resource from repository
   * 
   * @param fileUpload contains info about file to retrieve
   */
  public getUrlResourceRepository(event: any, fileData: FileData): Observable<string> {
    let result$: ReplaySubject<string> = new ReplaySubject(1);

    this._entityRefresh.next({ event: event, refreshing: true });
    this.subscription.add(this.vDataService.getUrlResourceRepository(this.entityUrl.repositoryResourceURL, fileData).subscribe(
      resourceUrl => {
        result$.next(resourceUrl);
        this._entityRefresh.next({ event: event, refreshing: false });
      },
      error => {
        this._entityRefresh.next({ event: event, refreshing: false });
        result$.error(error);
      }
    ));

    return result$.asObservable();
  }

  /**
   * Delete file from repository
   * 
   * @param fileUpload contains info about file to retrieve
   */
  public deleteResourceRepository(event: any, fileData: FileData): Observable<boolean> {
    let result$: ReplaySubject<boolean> = new ReplaySubject(1);

    this._entityRefresh.next({ event: event, refreshing: true });
    // this.clear();
    this.subscription.add(this.vDataService.deleteResourceRepository(this.entityUrl.repositoryFile, fileData).subscribe(
      result => {
        result$.next(result);
        this._entityRefresh.next({ event: event, refreshing: false });
      },
      error => {
        this._entityRefresh.next({ event: event, refreshing: false });
        result$.error(error);
      }
    ));

    return result$.asObservable();
  }

}

/**
 * Create the query string as field/value pair based on the fields of the item
 * 
 * @param entity item of type T that contains the fields use to compese the query string
 */
export function toQueryString<T>(obj: T): string {
  if (!obj) {
    return "";
  }

  let arrOut = [];

  Object.keys(obj).forEach(k => {
    let value = obj[k];

    if (!isNullOrUndefined(value) && !(value instanceof Array)) {
      if (value instanceof Date) {
        value = value.toUTCString();
      }

      arrOut.push(`${encodeURIComponent(k)}=${encodeURIComponent(value)}`);
    }
  });

  return arrOut.join('&');
}

/**
 * Return element in which each empty field or field equal to 0
 * is replaced with undefined
 * 
 * @param element element to match
 */
export function patchEntity<T>(element: T, includeNull: boolean = true): T {

  let result: T = element;
  let numField = 0;
  Object.keys(element).forEach(k => {
    let value = element[k];
    if (isNullOrUndefined(value) || value == '' || value == 0) {
      includeNull ? result[k] = undefined : delete result[k];
    }
    else {
      result[k] = value;
      numField++;
    }
  });

  if (numField === 0)
    result = undefined;

  return result;
}

/**
 * Compare two entity.
 * The comparison is perfomed on each field in the parameter <fields>
 * 
 * @param obj1
 * @param obj2
 * @param fields
 */
export function compareEntityFields<T>(element1: T, element2: T, fields: string[]): boolean {
  if (isNullOrUndefined(element1) || isNullOrUndefined(element2))
    return false;

  let result: boolean = true;
  fields.forEach(field => {
    result = result && (element1[field] === element2[field]);
  });

  return result;
}

/**
 * Check all fields of entity and return true if all fields are null
 * 
 * @param entity
 */
export function checkEntityFieldsNull<T>(entity: T): boolean {
  let result: boolean = true;

  let objectKeys = Object.keys(entity);
  let key;
  for (let i = 0; i < objectKeys.length; i++) {
    key = objectKeys[i];
    result = result && (isNullOrUndefined(entity[key]) || ((typeof entity[key] == "string") && entity[key].trim() === ""));
  }
  return result;
}

/**
 * Compare two entity of same type field by field
 * 
 * @param entity1
 * @param entity2
 * @param onlyExistingFields
 */
export function compareEntity<T>(entity1: T, entity2: T, keys: string[] = undefined, skipNull: boolean = false): boolean {
  if (isNullOrUndefined(entity1) && isNullOrUndefined(entity2)) {
    return true;
  }

  let result: boolean = true;
  let objectKeys = keys ?? (entity1 ? Object.keys(entity1) : Object.keys(entity2));

  if (isNullOrUndefined(entity1) || isNullOrUndefined(entity2)) {
    objectKeys.forEach(key => result = result && isNullOrUndefined(entity1?.[key] && isNullOrUndefined(entity2?.[key])));
    return result;
  }

  let key;
  for (let i = 0; i < objectKeys.length; i++) {
    key = objectKeys[i];

    // console.log(`field: ${key} value1: ${entity1[key]} value2: ${entity2[key]}` );
    // console.log(`1: ${isNullOrUndefined(entity1[key]) && isNullOrUndefined(entity2[key])}` );
    // console.log(`2: ${(isNullOrUndefined(entity1[key]) || isNullOrUndefined(entity2[key])) && skipNull}` );
    // console.log(`3: ${entity1[key] instanceof Array && JSON.stringify(entity1[key])==JSON.stringify(entity2[key])}` );
    // console.log(`4: ${entity1[key] instanceof Object && JSON.stringify(entity1[key])==JSON.stringify(entity2[key])}` );
    // console.log(`5: ${(entity1[key] instanceof Date && entity1[key].getTime() == (new Date(entity2[key])).getTime())}`);
    // console.log(`6: ${entity1[key] === entity2[key]}` );

    result = result && (
      (isNullOrUndefined(entity1[key]) && isNullOrUndefined(entity2[key]))
      || ((isNullOrUndefined(entity1[key]) || isNullOrUndefined(entity2[key])) && skipNull)
      // || (entity1[key] instanceof Object && compareEntity(entity1[key], entity2[key], skipNull))
      || (entity1[key] instanceof Array && JSON.stringify(entity1[key]) == JSON.stringify(entity2[key]))
      || (entity1[key] instanceof Object && JSON.stringify(entity1[key]) == JSON.stringify(entity2[key]))
      || (entity1[key] instanceof Date && entity1[key].getTime() == (new Date(entity2[key])).getTime())
      || (entity1[key] === entity2[key])
    );
    // console.log(`result: ${result}` );
    if (!result) { break; }
  }

  return result;
}

/**
 * Check if the element has the values in elementSearch
 * 
 * @param element element to compare
 * @param elementSearch element to search, with the fields aspected
 */

export function matchEntity<T>(element: T, elementSearch: any): boolean {
  let result: boolean = false;

  if (isNullOrUndefined(elementSearch))
    return result;

  let objectKeys = Object.keys(elementSearch);
  if (objectKeys.length === 0)
    return result;

  objectKeys.forEach(key => {
    if (typeof elementSearch[key] === "string") {
      result = result && (element[key] as string).toLocaleLowerCase() === (elementSearch[key] as string).toLocaleLowerCase();
    }
    else {
      result = result && element[key] === elementSearch[key];
    }
  });

  return result;
}

/**
 * Return list of entities in source (entities) that are not in "excluded list" (excludedEntities)
 * 
 * @param entities list of entities to filter (source)
 * @param excludedEntities list of entities excluded
 */
export function filterExcludedEntities<T>(entities: T[], excludedEntities: any[]): T[] {
  if (isNullOrUndefined(excludedEntities) || excludedEntities.length == 0)
    return entities;

  let result = entities.filter(entity => {
    let exists = false;
    for (let i = 0; i < excludedEntities.length; i++) {

      let excludedEntity = excludedEntities[i];
      Object.keys(excludedEntity).forEach(key => {
        exists = exists || (excludedEntity[key] === entity[key]);
      });

      if (exists)
        break;
    }

    return !exists;
  });

  return result;
}

/**
 * Return only the entities that have a valid value int he fileds.
 * Value is not valid if it is undefined, null, empty string or 0.
 * 
 * @param entities list to filter
 * @param fields fields of entity, which must have a valid value
 */
export function getEntitiesWithValidValueInFields<T>(entities: T[], fields: string[]): T[] {
  if (isNullOrUndefined(entities) || entities.length === 0)
    return entities;

  let result = entities.filter(entity => {
    let hasField = true;
    let fieldValue;
    fields.forEach(field => {
      fieldValue = entity[field] ? entity[field].toString().trim() : undefined;
      hasField = hasField && (fieldValue && fieldValue.length > 0 && fieldValue != '0');
    });
    return hasField;
  });

  return result;
}
