import { Component } from '@angular/core';
import { BaseWindowListSearchComponent } from 'src/app/components/base-window-list/base-window-list-search.component';

@Component({
  selector: 'app-giud-assistentato-grouped-list-search',
  templateUrl: './giud-assistentato-grouped-list-search.component.html',
  styleUrls: ['./giud-assistentato-grouped-list-search.component.scss']
})
export class GiudAssistentatoGroupedListSearchComponent extends BaseWindowListSearchComponent {
  // Form
  dataForm = this.fb.group({
    giudiceId: this.fb.control<number | null>(null),
    tipoCalCod: this.fb.control<string | null>(null),
    simpleSearch: this.fb.control<string | null>(null),
    richiestaId: this.fb.control<number | null>(null),
    caleManiLabel: this.fb.control<string | null>(null),
    caleManiDataInizio: this.fb.control<Date | null>(null),
    caleManiDataFine: this.fb.control<Date | null>(null),
    giudiceNome: this.fb.control<string | null>(null),
    giudiceCognome: this.fb.control<string | null>(null)
  });

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
