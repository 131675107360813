import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, EntityManagerConfig, CmdField } from "../../entity-config";
import { AppManagerRoutes } from "../../../app-manager-routes";

export class AnaCalEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalDefExpNumList, label: 'menu.CalDefExpNumList.label', title: 'menu.CalDefExpNumList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalDefRadMosNumList, label: 'menu.CalDefRadMosNumList.label', title: 'menu.CalDefRadMosNumList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalDefPrvSpecNumList, label: 'menu.CalDefPrvSpecNumList.label', title: 'menu.CalDefPrvSpecNumList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalDefSociFamiRazzeList, label: 'menu.CalDefSociFamiRazzeList.label', title: 'menu.CalDefSociFamiRazzeList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalDefVincoloList, label: 'menu.CalDefVincoloList.label', title: 'menu.CalDefVincoloList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalFamiRazzeList, label: 'menu.CalFamiRazzeList.label', title: 'menu.CalFamiRazzeList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalMotivoBloccoDataList, label: 'menu.CalMotivoBloccoDataList.label', title: 'menu.CalMotivoBloccoDataList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalPrvSpConfigList, label: 'menu.CalPrvSpConfigList.label', title: 'menu.CalPrvSpConfigList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalCircuitoList, label: 'menu.CalCircuitoList.label', title: 'menu.CalCircuitoList.title'
      }
    ];
  }

}
