import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EntityLogPars } from '../models/gestione/entity-log-pars';

@Injectable({
  providedIn: 'root'
})
export class GenLogService {
  private _modalEntityLogSubject: Subject<EntityLogPars> = new Subject();

  constructor() { }

  getEntityLogSubject() {
    return this._modalEntityLogSubject.asObservable();
  }

  openModalEntityLog(tableName: string, rowId: number) {
    const pars = new EntityLogPars();
    pars.open = true;
    pars.tableName = tableName;
    pars.rowId = rowId;

    this._modalEntityLogSubject.next(pars);
  }

  closeModalEntityLog() {
    this._modalEntityLogSubject.next({
      open: false,
      rowId: null,
      tableName: null
    });
  }
}