import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormField, FormFieldType, FormFieldTypeHtml, CmdFieldKey, CmdFieldTarget, CmdFieldMode, EntityManager } from '../../../models/entity-config';
import { isObservable, Observable, Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { dataBind, dataBindReverse, isNullOrUndefined } from '../../../utils/util';
import { AppManagerService } from '../../../services/app-manager.service';
import { Resources, TranslateService } from '../../../services/translate.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'entity-form',
  templateUrl: './entity-form.component.html',
  styleUrls: ['./entity-form.component.scss']
})

export class EntityFormComponent implements OnInit, OnDestroy, OnChanges {

  cmdFieldTarget = CmdFieldTarget;
  cmdFieldMode = CmdFieldMode;
  cmdFieldKey = CmdFieldKey;
  formFieldType = FormFieldType;
  formFieldTypeHtml = FormFieldTypeHtml;
  
  @Input() entityManager: EntityManager;
  @Input() formFields: FormField[];
  @Input() dataForm: FormGroup;

  @Input() mode: "form" | "search" = 'form';
  // private _mode: "form" | "search" = 'form';
  // @Input()
  // set mode(value: any) {
  //   this._mode = value;    
  // }

  @Input() data: any;
  // private _data: any;
  // @Input()
  // set data(value: any) {
  //   this._data = this.entityManager.convertDateFieldToLocalDate(value);
  //   this.buildFormGroup();
  // }

  // translateItems: Resources;
  detailFields: FormField[]; // detail fields displayed on the page
  entityModal: any;

  // translateItems: Resources;
  translatedItems$: Observable<Resources>;

  private subscription: Subscription;

  constructor(
    private appManagerService: AppManagerService,
    private translateService: TranslateService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].isFirstChange || changes['data'].currentValue != changes['data'].previousValue) {
      this.buildFormGroup();
    }
  }

  ngOnInit() {
    // translations
    this.translatedItems$ = this.translateService.translatedItems$(this.entityManager.translateSuffixs)
    // .pipe(
    //   tap(items => this.translateItems = items)
    // );

    // this.buildFormGroup();
  }

  private buildFormGroup() {
    this.detailFields = this.formFields;
    // this.detailFields = this.formFields.filter(field => field.typeHtml != FormFieldTypeHtml.hidden);

    // build dataForm
    this.detailFields.forEach(field => {
      // remove the control if already exists
      // because buildFormGroup is executed every time data is set
      if (this.dataForm.contains(field.key)) {
        this.dataForm.removeControl(field.key);
      }

      field['disabled'] = this.entityManager.isFormFieldDisabled(field, { entity: this.data }) || field.typeHtml === FormFieldTypeHtml.readonly;

      let fieldValue = this.data?.[field.key];

      // for modalList and modalListEditable the fieldValue is a projection of fields "fieldsBind" in entity
      if (this.data) {
        if (field.typeHtml === FormFieldTypeHtml.modalList || field.typeHtml === FormFieldTypeHtml.modalListEditable) {
          fieldValue = isNullOrUndefined(this.data) ? undefined : dataBind(this.data, field.options.fieldsBind);
        }
      }

      // add control to the dataForm
      this.dataForm.addControl(field.key, new FormControl({ value: fieldValue, disabled: field['disabled'] }, field.validators));

      if (field.typeHtml != FormFieldTypeHtml.hidden) {
        // attach listener to the valueChange event
        this.subscription.add(
          this.dataForm.get(field.key).valueChanges.subscribe(
            value => this.onChange(field, value)
          )
        )

        if (field?.options?.data) {
          // add temporary control to the dataForm with suffix 'Obj'
          // this control is used with the field field.options['dataList']
          // for selection (select, radio, checkbox, autocomplete)
          field.options['dataObj'] = `${field.key}Obj`;
          this.dataForm.addControl(field.options['dataObj'], new FormControl({ value: undefined, disabled: field['disabled'] }));
          // attach listener to the valueChange event
          this.subscription.add(
            this.dataForm.get(field.options['dataObj']).valueChanges.subscribe(
              value => this.onChange(field, value)
            )
          )
        }
      }
    });

    // set field.options['dataList'] of field that use data
    // as select, radio, checkbox, autocomplete 
    this.detailFields.filter(field => field.options?.data)
      .forEach(field => {
        if (isObservable(field.options.data)) {
          this.subscription.add(
            field.options.data.subscribe(
              (entityEvent: any) => {
                // set dataList with values from observable
                field.options['dataList'] = entityEvent.entities;
                this.setDataObj(field, this.dataForm);

                // retrieve the value selected by dataKey from the dataList
                // let val = entityEvent.entities.find(t => t[field.options.dataKey] === this.dataForm.controls[field.key].value);
                // this.dataForm.controls[field.options['dataObj']].setValue(val, { emitEvent: false });
              },
              error => { }
            )
          );
        }
        else {
          // set dataList with values in field.options.data
          field.options['dataList'] = field.options.data;
          this.setDataObj(field, this.dataForm);

          // retrieve the value selected by dataKey from the dataList
          // let val = field.options.data.find(t => t[field.options.dataKey] === this.dataForm.controls[field.key].value);
          // this.dataForm.controls[field.options['dataObj']].setValue(val, { emitEvent: false });
        }
      });

    // inject Form Control into EntityManager
    if ('form' === this.mode) {
      this.entityManager.setFormControl(this.dataForm);
    }

  }

  private setDataObj(field: FormField, formGroup: FormGroup) {
    const data = <any[]>field.options['dataList'];
    if (field.options.multiselect) {
      if (field.type === FormFieldType.object) {
        const val = data.filter(t => formGroup.controls[field.key].value.map(v => v[field.options.dataKey]).includes(t[field.options.dataKey]));
        formGroup.controls[field.options['dataObj']].setValue(val, { emitEvent: false });
      }
      else {
        const val = data.filter(t => formGroup.controls[field.key].value.includes(t));
        formGroup.controls[field.options['dataObj']].setValue(val, { emitEvent: false });
      }
    }
    else {
      if (field.type === FormFieldType.object) {
        const val = data.find(t => t[field.options.dataKey] === formGroup.controls[field.key][field.options.dataKey].value);
        formGroup.controls[field.options['dataObj']].setValue(val, { emitEvent: false });
      }
      else {
        const val = data.find(t => t[field.options.dataKey] === formGroup.controls[field.key].value);
        formGroup.controls[field.options['dataObj']].setValue(val, { emitEvent: false });
      }
    }
  }

  /**
   * Set form control value.
   * In case of field?.options?.data (select, radio, checkbox, autocomplete )
   * set the value of form control with the item selected (object any)
   * or with a field value of an item selected (item[field.key])
   * 
   * @param field field changed
   * @param value new value
   */
  onChange(field: FormField, value: any) {
    switch (field.typeHtml) {
      case FormFieldTypeHtml.modalList:
      case FormFieldTypeHtml.modalListEditable: {
        const data = dataBindReverse(value, field.options.fieldsBind);
        this.dataForm.patchValue(data, { emitEvent: false });
      }
        break;

      default: {
        if (field?.options?.data) {
          const valObj = this.dataForm.controls[field.options['dataObj']].value;
          if (field.type === FormFieldType.object) {
            this.dataForm.controls[field.key].setValue(valObj, { emitEvent: false });
          }
          else {
            if (field.options.multiselect) {
              let arr = valObj.map(t => t[field.options.dataKey]);
              this.dataForm.controls[field.key].setValue(arr.join(","), { emitEvent: false });
            }
            else {
              this.dataForm.controls[field.key].setValue(isNullOrUndefined(valObj) ? undefined : valObj[field.options.dataKey], { emitEvent: false });
            }
          }
        }
      }
        break;
    }

    if (field.hChange) {
      field.hChange({ field: field, value: value });
    }
  }

  onClearDropDown(field: FormField, event) {
    this.dataForm.controls[field.key].setValue(null, { emitEvent: false });
  }

  onAutoComplete(field: FormField, value: any) {
    // if (field.hComplete) {
    //   field.hComplete({ field: field, event: event });
    // }
  }

  onEditorTextChange(field: FormField, event) {
    // this.dataForm.controls[field.key].setValue('prova', { emitEvent: false });
  }

  onDeselectFile(field: FormField) {
    if (field.hFileDeselect) {
      field.hFileDeselect(field);
    }
    else {
      this.dataForm.controls[field.key].reset();
    }
  }

  onDownload(field: FormField) {
    if (field.hFileDownload) {
      field.hFileDownload(field);
    }
  }

  getData(): any {
    let dataResult = this.dataForm.value;

    // delete all temporary fields with key = ${f.key}Obj
    this.detailFields.filter(field => field?.options?.data)
      .forEach(f => {
        delete dataResult[`${f.key}Obj`];
      });

    // copy original values for field of type Hidden or Readonly
    if (this.data) {
      // this.formFields.filter(field => field.typeHtml === FormFieldTypeHtml.hidden || field.typeHtml === FormFieldTypeHtml.readonly)
      this.formFields.filter(field => field.typeHtml === FormFieldTypeHtml.readonly)
        .forEach(f => {
          dataResult[f.key] = this.data[f.key];
        });
    }

    // convert empty string to undefined
    Object.keys(dataResult).forEach(key => {
      let val = dataResult[key];
      if ('string' === (typeof dataResult[key]) && "" == dataResult[key]) {
        dataResult[key] = undefined;
      }
    });

    return dataResult;
  }

  // onChange(field: FormField, value?: any) {
  //   let valObj;
  //   switch (field.typeHtml) {
  //     case FormFieldTypeHtml.radio:
  //       valObj = this.dataForm.controls[field.options['dataObj']].value;
  //       if (field.type === FormFieldType.object) {
  //         this.dataForm.controls[field.key].setValue(valObj, { emitEvent: false });
  //       }
  //       else {
  //         this.dataForm.controls[field.key].setValue(isNullOrUndefined(valObj) ? undefined : valObj[field.options.dataKey], { emitEvent: false });
  //       }
  //       break;

  //     case FormFieldTypeHtml.checkbox:
  //     case FormFieldTypeHtml.select:
  //       valObj = this.dataForm.controls[field.options['dataObj']].value;
  //       if (field.type === FormFieldType.object) {
  //         this.dataForm.controls[field.key].setValue(valObj, { emitEvent: false });
  //       }
  //       else {
  //         if (isNullOrUndefined(valObj)) {
  //           this.dataForm.controls[field.key].setValue(undefined, { emitEvent: false });
  //         }
  //         else {
  //           if (field.options.multiselect) {
  //             let arr = valObj.map(t => t[field.options.dataKey]);
  //             this.dataForm.controls[field.key].setValue(arr.join(","), { emitEvent: false });
  //           }
  //           else {
  //             this.dataForm.controls[field.key].setValue(valObj[field.options.dataKey], { emitEvent: false });
  //           }
  //         }
  //       }
  //       break;

  //     case FormFieldTypeHtml.modalList:
  //     case FormFieldTypeHtml.modalListEditable: {
  //       this.dataForm.patchValue(value, { emitEvent: false });
  //     }
  //     break;

  //     default:
  //       if (field?.options?.data) {
  //         let valObj = this.dataForm.controls[field.options['dataObj']].value;
  //         if (field.type === FormFieldType.object) {
  //           this.dataForm.controls[field.key].setValue(valObj, { emitEvent: false });
  //         }
  //         else {
  //           this.dataForm.controls[field.key].setValue(isNullOrUndefined(valObj) ? undefined : valObj[field.options.dataKey], { emitEvent: false });
  //         }
  //       }

  //       break;
  //   }

  //   if (field.hChange) {
  //     field.hChange({field: field, formControl: this.dataForm, value: value });
  //   }

  // }

}
