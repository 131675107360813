<ng-container *ngIf="T$ | async as T">
  <p-dialog *ngIf="tipiNoteList$ | async as tipiNoteList;" [(visible)]="visible" [modal]="true"
    styleClass="modal-window-size-small" [resizable]="true" [closeOnEscape]="true" (onHide)="hideModal()">

    <ng-template pTemplate="header">
      <span class="p-dialog-title">
        {{T | translateItem: 'gennote.windowHeaderTitle'}}
      </span>
    </ng-template>

    <ng-template pTemplate="content">
      <form novalidate [formGroup]="dataForm" class="formgrid grid">

        <span class="field col-12 sm:col-6 md:col-4 lg:col-2">
          <label class="text-sm required">{{T | translateItem: 'gennote.tipoNota'}}</label>
          <p-dropdown [options]="tipiNoteList" formControlName="notaTipoId"
            [placeholder]="T | translateItem: 'gennote.tipoNota'" optionValue="id" optionLabel="des"
            class="inputfield p-inputtext-sm" [emptyMessage]="T | translateItem: 'generic.dropdownEmpty'">
          </p-dropdown>
        </span>

        <span class="field col-12 sm:col-6 md:col-8 lg:col-10">
          <label class="text-sm">{{T | translateItem: 'gennote.oggetto'}}</label>
          <input pInputTextarea formControlName="oggetto" [placeholder]="T | translateItem: 'gennote.oggetto'"
            class="inputfield p-inputtext-sm">
        </span>

        <span class="field col-12">
          <label class="text-sm required">{{T | translateItem: 'gennote.nota'}}</label>
          <textarea pInputTextarea formControlName="nota" [placeholder]="T | translateItem: 'gennote.nota'"
            class="inputfield"></textarea>
        </span>
      </form>
    </ng-template>

    <ng-template pTemplate="footer">
      <button pButton type="button" class="p-button p-button-outlined" (click)="hideModal()"
        [icon]="elaboration ? 'fa-solid fa-circle-notch fa-spin' : 'fa-solid fa-xmark'" [disabled]="elaboration"
        [label]="T | translateItem: 'generic.cancel'">
      </button>
      <button pButton type="button" class="p-button p-button-primary" (click)="save()"
        [icon]="elaboration ? 'fa-solid fa-circle-notch fa-spin' : 'fa-solid fa-floppy-disk'"
        [disabled]="elaboration || dataForm.pristine || !dataForm.valid" [label]="T | translateItem: 'generic.save'">
      </button>
    </ng-template>
  </p-dialog>
</ng-container>