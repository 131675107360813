import { Component, HostBinding, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-search-field-inputtext',
  templateUrl: './search-field-inputtext.component.html',
  styleUrls: ['./search-field-inputtext.component.scss']
})

export class SearchFieldInputtextComponent {
  @Input()
  formGroup: FormGroup;

  @Input()
  controlName: string;

  @Input()
  placeholder: string = '';

  @Input()
  defaultValue = null;

  @Input()
  type: string = 'text';

  @Input()
  styleClass: string = 'search-field-size-base';

  @HostBinding('class') get class() {
    return this.styleClass;
  }

  resetFilterControl(controlName: string, defaultValue: [] | null = null): void {
    this.formGroup.get(controlName)?.reset(defaultValue);
  }
}
