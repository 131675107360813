import { Validators } from "@angular/forms";
import { ComparisonOperator, DataSearch, DataSortValue, SortMode } from "../data-search";
import { Entity } from "../entity";
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, CmdFieldStyle, Icons, CmdField, FormFieldStyle } from "../entity-config";


export class GenHelp extends Entity {
  sezione: string;
  des: string;
  lang: string;
}

export class GenHelpLang extends Entity {
  helpId: number;
  lang: string;
  des: string;

  sezione: string;
}

// export class GenHelpEMC extends EntityManagerConfig {
//   fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.none, target: CmdFieldTarget.none };

//   init() {
//     this.textComponent = {
//       windowListHeader: `${this.name}.windowListHeader`, windowDetailHeader: `${this.name}.windowDetailHeader`
//     };

//     let dataSearch: DataSearch = new DataSearch();
//     dataSearch.dataSortValues = [
//       { field: 'id', sortMode: SortMode.Asc }
//     ];
//     this.attributes['genlang'] = this.getEntityService('genlang').searchEntitiesAsync(this.fLoad, dataSearch);
//   }

//   initFields() {

//     this.cmdFields = [
//       // search
//       {
//         key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
//         label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
//       },      
//       // {
//       //   key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.viewHeader,
//       //   title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary
//       // },
//       // {
//       //   key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
//       //   title: 'generic.cancel', icon: Icons.back, class: CmdFieldStyle.btnSecondary
//       // },
//       {
//         key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
//         title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary
//       }
//     ];

//     this.listFields = [
//       {
//         key: 'id', type: FormFieldType.number, label: `${this.name}.id`, sortable: true, typeHtml: FormFieldTypeHtml.hidden
//       },
//       {
//         key: 'des', type: FormFieldType.string, 
//         label: `${this.name}.des`, sortable: true
//       }      
//     ];

//     this.detailFields = [
//       {
//         key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
//       },
//       {
//         key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
//       },
//       {
//         key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden
//       },
//       {
//         key: 'sezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
//       },      
//       {
//         key: 'lang', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly
//       },
//       {
//         key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.editor, 
//         label: `${this.name}.des`, placeholder: `${this.name}.des`,
//         validators: [Validators.required]
//       }
//     ];

//     this.searchFields = [
//       {
//         key: 'lang', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, 
//         label: `${this.name}.lang`, //placeholder: `${this.name}.lang`,
//         options: { 
//           data: this.attributes['genlang'], dataKey: 'lang', dataLabel: 'des',
//           searchFields: `${FIELD_EXCEPT_PREFIX}lang`, searchCompOp: ComparisonOperator.Equals
//         }
//       }
//     ];

//   }

// }

export class GenHelpLangEMC extends EntityManagerConfig {
  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.none, target: CmdFieldTarget.none };

  init() {
    this.textComponent = {
      windowListHeader: `${this.name}.windowListHeader`, windowDetailHeader: `${this.name}.windowDetailHeader`
    };

    const ds: DataSearch = new DataSearch();
    ds.dataSortValues = [new DataSortValue('id', SortMode.Asc)];

    this.attributes['genlang'] = this.getEntityService('genlang').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {

    this.cmdFields = [
      // search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.load', title: 'generic.load', icon: Icons.load, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader, label: 'generic.edit',
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader, label: 'generic.back',
        title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader, label: 'generic.save',
        title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      {
        key: 'id', type: FormFieldType.number, label: `${this.name}.id`, sortable: true, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'des', type: FormFieldType.string,
        label: `${this.name}.des`, sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'helpId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'sezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'lang', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.editor,
        label: `${this.name}.des`, placeholder: `${this.name}.des`,
        validators: [Validators.required], class: FormFieldStyle.full
      }
    ];

    this.searchFields = [
      {
        key: 'sezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden,
        options: {
          searchFields: 'sezione', searchCompOp: ComparisonOperator.Equals
        }
      },
      {
        key: 'lang', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.lang`,
        options: {
          data: this.attributes['genlang'], dataKey: 'lang', dataLabel: 'des',
          searchFields: 'lang', searchCompOp: ComparisonOperator.Equals, class: FormFieldStyle.default
        }
      }
    ];

  }

}
