<ng-container *ngIf="T$ | async as T">
  <form [formGroup]="dataForm" novalidate>
    <app-list-search [(advancedSearch)]="advancedSearch" [elaboration]="elaboration"
      [searchButtonLabel]="T | translateItem: 'generic.search'"
      [searchButtonTooltip]="T | translateItem: 'generic.search'"
      [resetButtonLabel]="T | translateItem: 'generic.searchReset'"
      [resetButtonTooltip]="T | translateItem: 'generic.searchReset'"
      [toggleButtonAdvancedLabel]="T | translateItem: 'generic.searchAdvanced'"
      [toggleButtonAdvancedTooltip]="T | translateItem: 'generic.searchAdvanced'"
      [toggleButtonSimpleLabel]="T | translateItem: 'generic.searchBasic'"
      [toggleButtonSimpleTooltip]="T | translateItem: 'generic.searchBasic'" (onSearch)="search()"
      (onReset)="resetSearch()">
      <!-- Advanced search -->
      <ng-container advanced>
        <app-search-field-inputtext [formGroup]="dataForm" controlName="protocollo"
          [placeholder]="T | translateItem: 'giudrichiesta.protocollo'" ngDefaultControl />

        <ng-container *ngIf="entityManager.id != appManagerRoutes.GiudRichiestaGiudiceList.id">
          <app-search-field-inputtext [formGroup]="dataForm" controlName="giudiceNome"
            [placeholder]="T | translateItem: 'giudrichiesta.giudiceNome'" ngDefaultControl />

          <app-search-field-inputtext [formGroup]="dataForm" controlName="giudiceCognome"
            [placeholder]="T | translateItem: 'giudrichiesta.giudiceCognome'" ngDefaultControl />
        </ng-container>

        <app-search-field-multiselect [options]="tipoRichiestaList$ | async" [formGroup]="dataForm"
          controlName="tipoRichiestaId" [placeholder]="T | translateItem: 'giudrichiesta.tipoRichiesta'"
          [selectedItemsLabel]="T | translateItem: 'generic.selecteditems'" ngDefaultControl />

        <app-search-field-multiselect [options]="subTipoRichiestaList$ | async" [formGroup]="dataForm"
          controlName="subTipoRichiestaId" [placeholder]="T | translateItem: 'giudrichiesta.subTipoRichiesta'"
          [selectedItemsLabel]="T | translateItem: 'generic.selecteditems'" ngDefaultControl />

        <app-search-field-multiselect [options]="statoRichiestaList$ | async" [formGroup]="dataForm"
          controlName="statoRichiestaId" [placeholder]="T | translateItem: 'giudrichiesta.statoRichiesta'"
          [selectedItemsLabel]="T | translateItem: 'generic.selecteditems'" ngDefaultControl />
      </ng-container>

      <!-- Simple search -->
      <ng-container simple>
        <div class="col-12">
          <input pInputText formControlName="simpleSearch"
            [placeholder]="T | translateItem : (this.entityManager.id | i18nSelect : simpleSearchMap)"
            class="p-inputtext-sm" />
        </div>
      </ng-container>
    </app-list-search>
  </form>
</ng-container>