import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { ButtonModule } from 'primeng/button';
import { MapSearchDirective } from './map-search.directive';
import { MapViewerComponent } from './map-viewer/map-viewer.component';

@NgModule({
  declarations: [
    MapViewerComponent,
    MapSearchDirective
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    ButtonModule
  ],
  exports: [
    MapViewerComponent,
    MapSearchDirective
  ]
})
export class MapModule { }
