<ng-container *ngIf="messageData && translateItems">
  <p-dialog [(visible)]="visible" [modal]="true" [position]="'top'" [style]="{width: '40vw'}" [closeOnEscape]="true">
    <ng-template pTemplate="header" *ngIf="messageData.title">
      <span [class]="messageData.classMessage" class="font-medium"
        [innerHTML]="messageData.options?.notTranslateTitle ? messageData.title : (translateItems | translateItem: messageData.title)">
      </span>
    </ng-template>

    <div *ngIf="messageData">
      <span [innerHTML]="translateItems | translateItem: messageData.message: messageData.data"
        *ngIf="messageData.data; else simpleMessage"></span>
      <ng-template #simpleMessage>
        <span
          [innerHTML]="messageData.options?.notTranslateMessage ? messageData.message : (translateItems | translateItem: messageData.message) "></span>
      </ng-template>
    </div>

    <ng-template pTemplate="footer">
      <div class="buttons-cnt buttons-cnt-end">
        <ng-container [ngSwitch]="messageData.mode">
          <ng-container *ngSwitchCase="messageMode.OkKo">
            <button pButton class="p-button-outlined p-button-sm" [class]="messageData.options?.btnClassKo"
              (click)="ko()"
              [label]="translateItems | translateItem: (messageData.options?.btnLabelKo ? messageData.options?.btnLabelKo : 'generic.cancel')"
              [icon]="messageData.options?.btnIconKo? messageData.options?.btnIconKo : 'fa-solid fa-xmark'"> </button>
            <button pButton class="p-button-sm" [class]="messageData.options?.btnClassOk" (click)="ok()"
              [label]="translateItems | translateItem: (messageData.options?.btnLabelOk ? messageData.options?.btnLabelOk : 'generic.prosegui')"
              [icon]="messageData.options?.btnIconOk ? messageData.options?.btnIconOk : 'fa-solid fa-check'"> </button>
          </ng-container>
          <button pButton *ngSwitchCase="messageMode.Close" (click)="close()"
            [label]="translateItems | translateItem: (messageData.options?.btnLabelClose ? messageData.options?.btnLabelClose : 'generic.close')"
            iconPos="left" icon="fa-solid fa-xmark"></button>
        </ng-container>
      </div>
    </ng-template>
  </p-dialog>
</ng-container>