import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { CmdField, CmdFieldKey, CmdFieldMode, CmdFieldTarget, EntityManager } from '../../../../models/entity-config';
import { AppManagerService, SESSION_STORAGE_GLOBAL } from '../../../../services/app-manager.service';

@Component({
  selector: 'app-fatture-home',
  templateUrl: './fatture-home.component.html',
  styleUrls: ['./fatture-home.component.scss']
})
export class FattureHomeComponent {
  cmdFieldTarget = CmdFieldTarget;

  cmdFieldMode = CmdFieldMode;

  cmdFieldKey = CmdFieldKey;

  menuExpanded: boolean = false;

  menuSticky: boolean = this.appManagerService.getStorageData(SESSION_STORAGE_GLOBAL, 'menu')?.menuSticky ?? true;

  entityManager: EntityManager = this.appManagerService.getEntityManager(this.activatedRoute);;

  readonly T$: Observable<Resources> = this.translateService.translatedItems$(['generic.', 'menu.']);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appManagerService: AppManagerService,
    private translateService: TranslateService
  ) { }

  onCmd(field: CmdField) {
    switch (field.key) {
      case CmdFieldKey.back:
        const navItem = this.appManagerService.getBackUrl();
        this.router.navigate([navItem.url], { queryParams: navItem.queryParams });
        break;

      case CmdFieldKey.navigate:
        if (field.entityManagerInfo.id) {
          this.router.navigate([field.entityManagerInfo.id, field.entityManagerInfo.url], { relativeTo: this.activatedRoute });
        }
        else {
          this.router.navigate([field.entityManagerInfo.url], { relativeTo: this.activatedRoute });
        }
        break;
    }
  }

  toggleMenu(event: any): void {
    this.menuExpanded = !this.menuExpanded;
  }

  onMenuStickyEvent(event: any): void {
    this.menuSticky = event;
  }

  onMenuExpandedEvent(event: any): void {
    this.menuExpanded = event;
  }
}
