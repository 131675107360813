import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, EntityManagerConfig, CmdField } from "../../entity-config";
import { AppManagerRoutes } from "../../../app-manager-routes";

export class AnaCalTipiEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalTipoCalList, label: 'menu.CalTipoCalList.label', title: 'menu.CalTipoCalList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalTipoManiList, label: 'menu.CalTipoManiList.label', title: 'menu.CalTipoManiList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalTipoPeriodoCalList, label: 'menu.CalTipoPeriodoCalList.label', title: 'menu.CalTipoPeriodoCalList.title'
      },
    ];
  }

}
