import { Validators } from "@angular/forms";
import { AppManagerRoutes } from "src/app/app-manager-routes";
import { optionsSINO } from "src/app/utils/util";
import { ComparisonOperator, DataSearch, DataSortValue, SortMode } from "../data-search";
import { Entity } from "../entity";
import { CmdField, CmdFieldKey, CmdFieldMode, CmdFieldStyle, CmdFieldTarget, EntityManagerConfig, FormFieldStyle, FormFieldType, FormFieldTypeHtml, Icons } from "../entity-config";

export class GenCartellino extends Entity {
  tipo: string;
  prioritaRisultati: number;
  denominazioniCartellino: string;
  displayTitoloStampaCartellino: string;
  displaySottotitoloStampaCartellino: string;
  codModelloStampaCartellino: string;
  flAbilitatoPerStampaCartellini: boolean;
  codImmagineBandiera: string;
  piattaforma: string;


  des: string;
}

export class GenCartellinoLang extends Entity {
  cartellinoId: number;
  lang: string;
  des: string;
}

export class GenCartellinoEMC extends EntityManagerConfig {
  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = {
      windowListHeader: `${this.name}.windowlistheader`,
      windowInsertHeader: `${this.name}.windowinsertheader`,
      windowEditHeader: `${this.name}.windoweditheader`
    };

    this.dataSortValue = [new DataSortValue('tipo', SortMode.Asc)];
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.containerTools,
        title: 'generic.help',
        icon: Icons.help,
        class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        type: 'submit',
        label: 'generic.search',
        title: 'generic.search',
        icon: Icons.search,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.reset,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset',
        title: 'generic.searchReset',
        icon: Icons.reset,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.toggle,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        label: 'generic.searchBasic',
        title: 'generic.searchBasic',
        icon: Icons.filters,
        class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // List
      {
        key: CmdFieldKey.add,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listHeader,
        entityManagerInfo: AppManagerRoutes.GenCartellinoNew,
        label: 'generic.add',
        title: 'generic.add',
        icon: Icons.plus,
        class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        entityManagerInfo: AppManagerRoutes.GenCartellinoEdit,
        title: 'generic.edit',
        icon: Icons.edit,
        class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.modalListEditable,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.translations',
        icon: Icons.globe,
        class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenCartellinoLangList,
        fieldsBind: { id: 'cartellinoId' },
        fieldsBindSearch: { id: 'cartellinoId' }
      },
      // {
      //   key: CmdFieldKey.delete,
      //   mode: CmdFieldMode.btn,
      //   target: CmdFieldTarget.listBody,
      //   title: 'generic.delete',
      //   icon: Icons.delete,
      //   class: CmdFieldStyle.btnSecondaryDanger
      // },

      // Detail
      {
        key: CmdFieldKey.back,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.detailHeader,
        label: 'generic.back',
        title: 'generic.back',
        icon: Icons.back,
        class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.save,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.detailHeader,
        label: 'generic.save',
        title: 'generic.save',
        icon: Icons.save,
        class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      {
        key: 'id',
        type: FormFieldType.number,
        sortable: true,
        typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'tipo',
        type: FormFieldType.string,
        label: `${this.name}.tipo`,
        sortable: true
      },
      {
        key: 'prioritaRisultati',
        type: FormFieldType.number,
        label: `${this.name}.prioritaRisultati`,
        sortable: true
      },
      {
        key: 'denominazioniCartellino',
        type: FormFieldType.string,
        label: `${this.name}.denominazioniCartellino`,
        sortable: true
      },
      {
        key: 'displayTitoloStampaCartellino',
        type: FormFieldType.string,
        label: `${this.name}.displayTitoloStampaCartellino`,
        sortable: true
      },
      {
        key: 'displaySottotitoloStampaCartellino',
        type: FormFieldType.string,
        label: `${this.name}.displaySottotitoloStampaCartellino`,
        sortable: true
      },
      {
        key: 'codModelloStampaCartellino',
        type: FormFieldType.string,
        label: `${this.name}.codModelloStampaCartellino`,
        sortable: true
      },
      {
        key: 'flAbilitatoPerStampaCartellini',
        type: FormFieldType.boolean,
        label: `${this.name}.flAbilitatoPerStampaCartellini`,
        sortable: true
      },
      {
        key: 'codImmagineBandiera',
        type: FormFieldType.string,
        label: `${this.name}.codImmagineBandiera`,
        sortable: true
      },
      {
        key: 'des',
        type: FormFieldType.string,
        label: `${this.name}.des`,
        sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'userIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'tipo',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.tipo`,
        placeholder: `${this.name}.tipo`,
        validators: [Validators.required, Validators.maxLength(50)],
        class: FormFieldStyle.default
      },
      {
        key: 'prioritaRisultati',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.prioritaRisultati`,
        placeholder: `${this.name}.prioritaRisultati`,
        validators: [Validators.required, Validators.min(0)],
        class: FormFieldStyle.default
      },
      {
        key: 'denominazioniCartellino',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.denominazioniCartellino`,
        placeholder: `${this.name}.denominazioniCartellino`,
        class: FormFieldStyle.default
      },
      {
        key: 'displayTitoloStampaCartellino',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.displayTitoloStampaCartellino`,
        placeholder: `${this.name}.displayTitoloStampaCartellino`,
        class: FormFieldStyle.default
      },
      {
        key: 'displaySottotitoloStampaCartellino',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.displaySottotitoloStampaCartellino`,
        placeholder: `${this.name}.displaySottotitoloStampaCartellino`,
        class: FormFieldStyle.default
      },
      {
        key: 'codModelloStampaCartellino',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.codModelloStampaCartellino`,
        placeholder: `${this.name}.codModelloStampaCartellino`,
        class: FormFieldStyle.default
      },
      {
        key: 'flAbilitatoPerStampaCartellini',
        type: FormFieldType.boolean,
        typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.flAbilitatoPerStampaCartellini`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' },
        validators: [Validators.required],
        class: FormFieldStyle.default
      },
      {
        key: 'des',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`,
        label: `${this.name}.des`,
        validators: [Validators.required],
        class: FormFieldStyle.default
      },

    ];

    this.searchFields = [
      {
        key: 'search',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: {
          searchFields: 'tipo,prioritaRisultati,denominazioniCartellino,displayTitoloStampaCartellino,displaySottotitoloStampaCartellino,codModelloStampaCartellino,codImmagineBandiera,des',
          searchCompOp: ComparisonOperator.Contains,
        },
        class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'tipo',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.tipo`,
        options: {
          searchFields: 'tipo',
          searchCompOp: ComparisonOperator.Contains,
        },
        class: FormFieldStyle.default
      },
      {
        key: 'prioritaRisultati',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.prioritaRisultati`,
        options: {
          searchFields: 'prioritaRisultati',
          searchCompOp: ComparisonOperator.Contains,
        },
        class: FormFieldStyle.default
      },
      {
        key: 'denominazioniCartellino',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.denominazioniCartellino`,
        options: {
          searchFields: 'denominazioniCartellino',
          searchCompOp: ComparisonOperator.Contains,
        },
        class: FormFieldStyle.default
      },
      {
        key: 'displayTitoloStampaCartellino',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.displayTitoloStampaCartellino`,
        options: {
          searchFields: 'displayTitoloStampaCartellino',
          searchCompOp: ComparisonOperator.Contains,
        },
        class: FormFieldStyle.default
      },
      {
        key: 'displaySottotitoloStampaCartellino',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.displaySottotitoloStampaCartellino`,
        options: {
          searchFields: 'displaySottotitoloStampaCartellino',
          searchCompOp: ComparisonOperator.Contains,
        },
        class: FormFieldStyle.default
      },
      {
        key: 'codModelloStampaCartellino',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.codModelloStampaCartellino`,
        options: {
          searchFields: 'codModelloStampaCartellino',
          searchCompOp: ComparisonOperator.Contains,
        },
        class: FormFieldStyle.default
      },
      {
        key: 'codImmagineBandiera',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.codImmagineBandiera`,
        options: {
          searchFields: 'codImmagineBandiera',
          searchCompOp: ComparisonOperator.Contains,
        },
        class: FormFieldStyle.default
      },
      {
        key: 'des',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`,
        options: {
          searchFields: 'des',
          searchCompOp: ComparisonOperator.Contains,
        },
        class: FormFieldStyle.default
      }
    ];
  }
}

export class GenCartellinoLangModalEMC extends EntityManagerConfig {

  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none }

  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = {};
    this.dataSortValue = [new DataSortValue('des', SortMode.Asc)];

    const ds: DataSearch = new DataSearch();
    ds.dataSortValues = [new DataSortValue('id', SortMode.Asc)];

    this.attributes['genlang'] = this.getEntityService('genlang').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {
    this.cmdFields = [
      // List search
      {
        key: CmdFieldKey.search,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        type: 'submit',
        label: 'generic.search',
        title: 'generic.search',
        icon: Icons.search,
        class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset',
        title: 'generic.searchReset',
        icon: Icons.reset,
        class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        label: 'generic.searchBasic',
        title: 'generic.searchBasic',
        icon: Icons.filters,
        class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // Editable
      {
        key: CmdFieldKey.add,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listHeader,
        label: 'generic.add',
        title: 'generic.add',
        icon: Icons.plus,
        class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.edit',
        icon: Icons.edit,
        class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.delete,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.delete',
        icon: Icons.delete,
        class: CmdFieldStyle.btnSecondaryDanger
      },
      {
        key: CmdFieldKey.cancel,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.cancel',
        icon: Icons.back,
        class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.save,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.save',
        icon: Icons.save,
        class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      {
        key: 'lang',
        type: FormFieldType.string,
        label: `${this.name}.lang`,
        sortable: true
      },
      {
        key: 'des',
        type: FormFieldType.string,
        label: `${this.name}.des`,
        sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'dtIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'userIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'cartellinoId',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'lang',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.select,
        label: `${this.name}.lang`,
        placeholder: `${this.name}.lang`,
        options: {
          data: this.attributes['genlang'],
          dataKey: 'lang',
          dataLabel: 'des',
        },
        validators: [Validators.required],
      },
      {
        key: 'des',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`,
        label: `${this.name}.des`,
        validators: [Validators.required],

      },
    ];

    this.searchFields = [
      {
        key: 'cartellinoId',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
        options: {
          searchFields: 'cartellinoId',
          searchCompOp: ComparisonOperator.Equals
        }
      },
      {
        key: 'search',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: {
          searchFields: 'des',
          searchCompOp: ComparisonOperator.Contains,
        },
        class: FormFieldStyle.full
      },
    ];

    this.searchAdvancedFields = [
      {
        key: 'cartellinoId',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
        options: {
          searchFields: 'cartellinoId',
          searchCompOp: ComparisonOperator.Equals
        }
      },
      {
        key: 'lang',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.lang`,
        options: {
          data: this.attributes['genlang'],
          dataKey: 'lang',
          dataLabel: 'des',
          searchFields: 'lang',
          searchCompOp: ComparisonOperator.Equals,
        },
        class: FormFieldStyle.default
      },
      {
        key: 'des',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`,
        options: {
          searchFields: 'des',
          searchCompOp: ComparisonOperator.Contains,
        },
        class: FormFieldStyle.default
      },
    ];
  }
}
