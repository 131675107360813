import { Severity } from "src/app/enums/severity";

export class MessageApp {
  message: string;
  severity: `${Severity}`;
  icon?: string;
  action?: () => void

  constructor(message: string, options?: { severity?: `${Severity}`, icon?: string, action?: () => void }) {
    this.message = message;
    this.severity = options.severity ?? Severity.Info;
    this.icon = options.icon;
    this.action = options.action;
  }
}
