<ng-container *ngIf="T$ | async as T">
  <p-dialog [(visible)]="visible" [modal]="true" styleClass="modal-window-size-small" [resizable]="true"
    [closeOnEscape]="true" (onHide)="hideModal()">

    <ng-template pTemplate="header">
      <span class="p-dialog-title">
        {{T | translateItem: rilascioEsito ? 'giudassistentato.assistentatoEsitoModalTitle' :
        'giudassistentato.assistentatoEditModalTitle'}}
      </span>
    </ng-template>

    <ng-template pTemplate="content">
      <form [formGroup]="dataForm">
        <div class="grid p-fluid">
          <ng-container *ngIf="!rilascioEsito">
            <app-text-block [label]="T | translateItem: 'giudassistentato.dataRichiesta'"
              [value]="this.dataForm.value.dataRichiesta" layout="date" [dateFormat]="dateFormat"
              styleClass="col-12 md:col-6" />

            <ng-container *ngIf="statoAssistentatoList$ | async as statiList">
              <div *ngIf="!utente?.isAdmin" class="col-12 md:col-6">
                <label class="block">&nbsp;</label>
                <span entityState classPrefix="giud-assistentato-state-badge"
                  [entityStateCod]="entity.statoAssistentatoCod" [label]="entity.statoAssistentatoDes"></span>
              </div>

              <div *ngIf="utente?.isAdmin" class="col-12 md:col-6">
                <label class="text-sm">{{T | translateItem: 'giudassistentato.statoAssistentato'}}</label>
                <p-dropdown [options]="statiList " formControlName="statoAssistentatoId"
                  [placeholder]="T | translateItem: 'giudassistentato.statoAssistentato'" optionValue="id"
                  optionLabel="des" class="p-inputtext-sm" [emptyMessage]="T | translateItem: 'generic.dropdownEmpty'"
                  appendTo="body" requiredField />
              </div>
            </ng-container>

            <app-text-block *ngIf="this.dataForm.value.tipoRichiestaCod === giudTipoRichiestaCod.AmpliamentoEsposizioni"
              [label]="T | translateItem: 'giudassistentato.razza'" [value]="this.dataForm.value.razzaDes"
              styleClass="col-12 md:col-6" />

            <ng-container *ngIf="this.dataForm.value.tipoRichiestaCod === giudTipoRichiestaCod.AmpliamentoProve">
              <app-text-block [label]="T | translateItem: 'giudassistentato.tipoMani'"
                [value]="this.dataForm.value.tipoManiDes" styleClass="col-12" />

              <app-text-block [label]="T | translateItem: 'giudassistentato.codProtipo'"
                [value]="this.dataForm.value.codProtipo" styleClass="col-12 md:col-6" />
            </ng-container>

            <app-text-block [label]="T | translateItem: 'giudassistentato.giudice'"
              [value]="this.dataForm.value.giudiceNomeCompleto" styleClass="col-12 md:col-6" />

            <div class="col-12 md:col-6">
              <label class="text-sm">{{T | translateItem: 'giudassistentato.dataInizio'}}</label>
              <p-calendar formControlName="caleManiDataInizio" [showIcon]="true" [dateFormat]="dateFormatPrNg"
                [firstDayOfWeek]="1" class="p-inputtext-sm"
                [placeholder]="T | translateItem: 'giudassistentato.dataInizio'" appendTo="body"
                [minDate]="dataInizioMin" requiredField />
            </div>

            <div class="col-12 md:col-6">
              <label class="text-sm">{{T | translateItem: 'giudassistentato.dataFine'}}</label>
              <p-calendar formControlName="caleManiDataFine" [showIcon]="true" [dateFormat]="dateFormatPrNg"
                [firstDayOfWeek]="1" class="p-inputtext-sm"
                [placeholder]="T | translateItem: 'giudassistentato.dataFine'" appendTo="body" [minDate]="dataFineMin"
                requiredField />
            </div>

            <!--  Selettore manifestazioni -->
            <div class="col-12">
              <label class="text-sm">{{T | translateItem: 'giudassistentato.caleMani'}}</label>
              <p-dropdown [options]="maniSuggestions$ | async" formControlName="caleManiId"
                [placeholder]="T | translateItem: 'giudassistentato.caleMani'" optionValue="id" optionLabel="label"
                optionDisabled="disabled" class="p-inputtext-sm"
                [emptyMessage]="T | translateItem: 'generic.dropdownEmpty'" appendTo="body" requiredField />
            </div>
          </ng-container>

          <!-- Esito -->
          <ng-container
            *ngIf="(rilascioEsito && this.entity?.statoAssistentatoLivello >= statoAssistentatoLivello.AccettatoGiudice && this.entity?.statoAssistentatoLivello < statoAssistentatoLivello.Annullato) && (utente?.isAdmin || (utente.livelloProfiloCod === 'GIUDICE' && utente.enciIdAnag === this.entity?.giudiceId))">
            <div *ngIf="!rilascioEsito" class="col-12 py-0">
              <p-divider>{{T | translateItem: 'giudassistentato.esito'}}</p-divider>
            </div>

            <div class="col-12">
              <label class="text-sm">{{T | translateItem: 'giudassistentato.esitoAssistentato'}}</label>
              <p-dropdown [options]="esitoAssistentatoList$ | async" formControlName="esitoAssistentatoId"
                [placeholder]="T | translateItem: 'giudassistentato.esitoAssistentato'" optionValue="id"
                optionLabel="des" class="p-inputtext-sm" [emptyMessage]="T | translateItem: 'generic.dropdownEmpty'"
                [filter]="true" [autofocusFilter]="true" [resetFilterOnHide]="true" appendTo="body" requiredField />
            </div>

            <div class="col-12">
              <label class="text-sm">{{T | translateItem: 'giudassistentato.esitoNote'}}</label>
              <textarea pInputTextarea formControlName="esitoNote"
                [placeholder]="T | translateItem: 'giudassistentato.esitoNote'" class="inputfield"></textarea>
            </div>
          </ng-container>
        </div>
      </form>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="flex justify-content-end">
        <div class="buttons-cnt">
          <app-button size="medium" [outlined]="true" [disabled]="elaboration" (onClick)="hideModal()"
            [tooltip]="T | translateItem: 'generic.back'" [label]="T | translateItem: 'generic.back'"
            icon="fa-solid fa-xmark" />

          <app-button size="medium" [disabled]="elaboration || dataForm.pristine || !dataForm.valid" (onClick)="save()"
            [tooltip]="T | translateItem: 'generic.save'" [label]="T | translateItem: 'generic.save'"
            icon="fa-solid fa-floppy-disk" />
        </div>
      </div>
    </ng-template>
  </p-dialog>
</ng-container>