<ng-container *ngIf="translatedItems$ | async as translateItems">
    <div class="flex flex-column h-full">
        <form [formGroup]="dataForm" (reset)="reset()" novalidate class="h-full">
            <div *ngIf="textHeader">
                <div class="flex flex-row justify-content-between">
                    <p class="text-header" [innerHtml]="translateItems | translateItem: textHeader: data"> </p>
                    <div *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.detailHeader, fieldType:cmdFieldMode.btn}) as cmdFields"
                        class="buttons-cnt">
                        <ng-container *ngFor="let field of cmdFields">
                            <button pButton [type]="field.type || 'button'" [class]="field.class"
                                [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field"
                                (click)="onCmd(field)"
                                [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{formValid: dataForm.valid, formPristine: dataForm.pristine})"
                                *ngIf="entityManager | isCmdFieldVisible:field" [icon]="field.icon"
                                [title]="translateItems | translateItem: field.title"
                                [label]="translateItems | translateItem: field.label">
                            </button>
                        </ng-container>
                    </div>
                </div>
                <p-divider></p-divider>
            </div>
            <div class="py-2 page-detail-container">
                <entity-form [entityManager]="entityManager" [formFields]="detailFields" [dataForm]="dataForm"
                    [data]="data">
                </entity-form>
            </div>

            <ng-container
                *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.detailFooter, fieldType:cmdFieldMode.btn}) as cmdFields">
                <p-divider *ngIf="cmdFields.length > 0"></p-divider>
                <div class="flex flex-row justify-content-end">
                    <div class="buttons-cnt">
                        <ng-container *ngFor="let field of cmdFields">
                            <button pButton [type]="field.type || 'button'" [class]="field.class"
                                [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field"
                                (click)="onCmd(field)"
                                [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{formValid: dataForm.valid, formPristine: dataForm.pristine})"
                                *ngIf="entityManager | isCmdFieldVisible:field" [icon]="field.icon"
                                [title]="translateItems | translateItem: field.title"
                                [label]="translateItems | translateItem: field.label">
                            </button>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <div
                *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.containerFooter, fieldType:cmdFieldMode.btn}) as cmdFields">
                <div class="buttons-cnt">
                    <ng-container *ngFor="let field of cmdFields">
                        <button pButton [type]="field.type || 'button'" [class]="field.class"
                            [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field" (click)="onCmd(field)"
                            [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{field: field})"
                            *ngIf="entityManager | isCmdFieldVisible:field" [icon]="field.icon"
                            [title]="translateItems | translateItem: field.title"
                            [label]="translateItems | translateItem: field.label">
                        </button>
                    </ng-container>
                </div>
            </div>
        </form>
    </div>


    <div class="modal-spinner" *ngIf="entityRefresh$ | async | refreshing">
        <p-progressSpinner></p-progressSpinner>
    </div>
</ng-container>