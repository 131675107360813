<div class="bg-white p-3 border-round" style="border: 1px solid rgba(0, 0, 0, 0.38);">
  <ng-container *ngIf="options; else loading">
    <loading-spinner [showSpinner]="elaboration">
      <div *ngIf="options.length; else noValue" class="flex flex-column row-gap-2">
        <div *ngFor="let caleMani of options" class="app-card flex flex-column row-gap-2">
          <div class="flex align-items-center gap-3">
            <!-- <p-triStateCheckbox class="p-tristatecheckbox-label" [(ngModel)]="caleMani.selected"
                            (ngModelChange)="onCaleManiSelectionChange($event, caleMani.id)" [inputId]="caleMani.id.toString()"
                            [disabled]="caleMani.disabled ">
                            <ng-template pTemplate="uncheckicon">
                              <i class="pi pi-minus block"></i>
                            </ng-template>
                          </p-triStateCheckbox> -->

            <div>
              <div class="font-medium">
                {{caleMani.label}}
              </div>

              <div class="block text-sm mt-1">
                {{caleMani.dataInizio | date: dateFormat}} - {{caleMani.dataFine | date: dateFormat}} |
                {{caleMani.parteGiorno | parteGiorno: parteGiornoResources}}
              </div>
            </div>
          </div>

          <p-divider />

          <!-- Giorni -->
          <div *ngFor="let giorno of caleMani.children" class="badge-outlined-gray py-3">
            <div class="flex justify-content-between align-items-center gap-2">
              <div class="font-medium text-md">
                {{giorno.data | date: dateFormat }}: {{giornoLabel}} {{giorno.giorno}}
              </div>
              <div *ngIf="giorno.flEsisteAssistentatoGiudiceRichiedenteManiGiorno" class="badge-warning">
                {{assistAlreadyRequestedDayMessage}}
              </div>

              <div
                *ngIf="giorno.flEsisteAssistentatiGiudiceRichiedenteParteGiorno && !giorno.flEsisteAssistentatoGiudiceRichiedenteManiGiorno"
                class="badge-warning">
                {{assistSameTimeMessage}}
              </div>
            </div>

            <p-divider />

            <!-- Sottoprove -->
            <div class="flex flex-column row-gap-2">
              <div *ngFor="let r of giorno.children" class="flex justify-content-between align-items-center gap-2">
                <div class="flex align-items-center gap-3">
                  <p-checkbox [binary]="true" [(ngModel)]="r.selected" [disabled]="r.disabled"
                    (ngModelChange)="onChildSelectionChange($event, r)" [inputId]="caleMani.id.toString()" />

                  {{r.label}}

                  <i *ngIf="r.flSuPiuGiorni" class="fa-solid fa-calendar-week warning"
                    [title]="razzaPiuGiorniMessage"></i>
                </div>

                <div class="flex gap-2">
                  <div *ngIf="r.flEsisteAssistentatoGiudiceRichiedente" class="badge-warning">
                    {{assistAlreadyRequestedMessage}}
                  </div>

                  <div *ngIf="r.flRaggiuntoLimiteAssistentatiGiudiceMani && !r.flEsisteAssistentatoGiudiceRichiedente"
                    class="badge-warning">
                    {{assistLimitReachedMessage}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-template #noValue>
        <p class="tfont-medium text-center p-3 text-600">
          {{emptyListMessage}}
        </p>
      </ng-template>
    </loading-spinner>
  </ng-container>

  <ng-template #loading>
    <p class="tfont-medium text-center p-3 text-600">
      {{startMessage}}
    </p>
  </ng-template>
</div>