import { Entity } from "../entity";

export class GiudTipoRichiesta extends Entity {
  cod: string;
  tipoCalId: number;
  visible: boolean;
  system: boolean;

  des: string;
  tipoCalCod: string;
}

export enum GiudTipoRichiestaCod {
  AmpliamentoProve = 'AMP_PRV',
  AmpliamentoEsposizioni = 'AMP_EXP',
  PrimaAbilitazioneProve = 'PRIMA_PRV',
  PrimaAbilitazioneEsposizioni = 'PRIMA_PRV'
}
