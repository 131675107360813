import { AppManagerRoutes } from "../../app-manager-routes";
import { CmdField, CmdFieldKey, CmdFieldMode, CmdFieldTarget, EntityManagerConfig, } from "../entity-config";

export class FattureEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.FattFatturaList, label: `menu.${AppManagerRoutes.FattFatturaList.id}.label`, title: `menu.${AppManagerRoutes.FattFatturaList.id}.title`
      }
    ]
  }
}
