import { Entity } from "../entity";

export class GiudSospensione extends Entity {
  giudiceId: number;
  tipoSospensioneId: number;
  disponenteSospensioneId: number;
  dataInizio: Date;
  dataFine: Date;
  note: string;

  tipoSospensioneCod: string;
  tipoSospensioneDes: string;
  disponenteSospensioneCod: string;
  disponenteSospensioneDes: string;
  flSospensioneIncorso: boolean;
  giudiceNome: string;
  giudiceCognome: string;
  giudiceNomeCompleto: string;
}
