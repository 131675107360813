<ng-container *ngIf="T$ | async as T">
  <div class="surface-50 border-1 border-300 border-solid widget-card flex flex-column h-full">
    <div
      class="flex flex-row justify-content-between align-items-center column-gap-2 surface-200 text-2xl font-medium text-primary p-3 header">
      <span>{{T | translateItem: 'notifichewidget.title'}}</span>
      <span [title]="T | translateItem: 'notifichewidget.unreadmessagescount'"
        class="badge-danger text-base">{{unreadMessagesCount}}</span>
    </div>
    <div class=" flex flex-column justify-content-between  px-4 py-3 flex-grow-1">
      <div class="flex-grow-1">
        <div *ngIf="unreadMessagesList?.length === 0; else list" class="flex flex-column align-items-center mt-2">
          <i class="fa-solid fa-circle-check text-3xl success"></i>
          <p class="font-medium text-center mt-2 text-600">
            {{T | translateItem: 'notifichewidget.nounreadmessages'}}
          </p>
        </div>
        <ng-template #list>
          <div *ngFor="let msg of unreadMessagesList; let i = index" class="my-3">
            <p-divider *ngIf="i > 0" class="my-0"></p-divider>
            <div class="flex flex-row justify-content-between align-items-center column-gap-2">
              <div class="flex-grow-1">
                <div
                  class="flex flex-row flex-wrap justify-content-between align-items-center column-gap-5 row-gap-2 text-sm">

                  <span [title]="T | translateItem: 'notifichewidget.utentefromemail'">{{msg.utenteFromEmail}}</span>
                  <span [title]="T | translateItem: 'notifichewidget.data'">{{msg.data | date:
                    dateTimeFormat}}</span>
                </div>
                <div [title]="T | translateItem: 'notifichewidget.subject'" class="font-medium mt-2">
                  {{msg.subject}}
                </div>
              </div>
              <button pButton type="button" [title]="T | translateItem: 'generic.showmore'"
                icon="fa-solid fa-chevron-right" iconPos="right" (click)="navigateToMessage(msg.id)"
                class="p-button p-button-sm p-button-text"></button>
            </div>
          </div>

        </ng-template>

        <p-divider class="my-0"></p-divider>
      </div>

      <div class="flex flex-row justify-content-end">
        <button pButton type="button" [label]="T | translateItem: 'generic.showmore'"
          [title]="T | translateItem: 'generic.showmore'" icon="fa-solid fa-chevron-right" iconPos="right"
          (click)="navigateToMessages()" class="p-button p-button-sm p-button-text"></button>
      </div>
    </div>
  </div>
</ng-container>