import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})

export class ButtonComponent {
  private _size: 'small' | 'large' | null;

  @Input()
  type: 'button' | 'submit' = 'button';

  @Input()
  icon: string;

  @Input()
  loadingIcon: string;

  @Input()
  severity: string;

  @Input()
  outlined: boolean = false;

  @Input('size')
  set sizeRaw(val: 'small' | 'medium' | 'large') {
    this._size = val === 'medium' ? null : val;
  };

  @Input()
  iconPos: 'left' | 'right';

  @Input()
  label: string;

  @Input()
  disabled: boolean = false;

  @Input()
  tooltip: string;

  @Input()
  tooltipPosition: 'right' | 'left' | 'top' | 'bottom' = 'top';

  @Input()
  styleClass: string;

  @Input()
  loading: boolean;

  get size(): 'small' | 'large' | null {
    // Di default sono small
    return this._size === undefined ? 'small' : this._size;
  }

  @Output()
  onClick: EventEmitter<MouseEvent> = new EventEmitter();

  constructor() { }

  onClickAction(event: MouseEvent): void {
    this.onClick.emit(event);
  }
}
