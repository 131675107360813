
import { ComparisonOperator, DataSortValue, SortMode } from "../data-search";
import { Entity } from "../entity";
import { CmdFieldKey, CmdFieldMode, CmdFieldStyle, CmdFieldTarget, EntityManagerConfig, FormFieldStyle, FormFieldType, FormFieldTypeHtml, Icons } from "../entity-config";

export class VuxSociDeleGruppi extends Entity {
  enciIdSocio: number;
  enciIdAnag: number;
  codTipo: string;
  tipo: string;
  ragioneSociale: string;
  codFiscale: string;
  partitaIva: string;
  indirizzo: string;
  localita: string;
  cap: string;
  provinciaCod: string;
  provinciaId: number;
  telefono: string;
  telefonoBis: string;
  enciCodNazione: string;
  eMail: string;
  fax: string;
  note: string;
  segnoMeno: string;
  presso: string;
  tipoSocio: string;
  tipoSocioCollettivo: string;
  flSocUtna: boolean;
  enciIdSocioPadre: boolean;
  enciIdAnagPadre: boolean;
  flSocio: boolean;
  statoDomanda?: string;
  dataDomanda?: Date;
  dataApprovazione?: Date;
  dataInizio?: Date;
  dataFine?: Date;

  regioneId: number;
  label: string;

  isAlreadyAdded: boolean;
}

export const ENCI_ORG_NON_SOC_ENCI_ID_ANAG: number = 8175475;
export const AS_TYPE_LABEL: string = 'SOCIETA\' SPECIALIZZATA';
export const ORG_NON_SOC_LABEL: string = 'ORGANIZZATORI NON SOCI';
export const ORG_SOC_LABEL: string = 'ORGANIZZATORI SOCI';
export const CO_NAZIONALI: number[] = [1034782, 1077819, 1260752, 1495314, 8529233, 9445754, 9677009, 9827686];

export class VuxSociDeleGruppiEMC extends EntityManagerConfig {

  init() {
    this.textComponent = {};
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('enciIdAnag', SortMode.Asc)];
  }

  initFields() {
    this.cmdFields = [
      // Container

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
        // hEvent: () => {
        //   let field = this.getCmdField(CmdFieldTarget.listSearch, CmdFieldMode.btn, CmdFieldKey.toggle);
        //   this.toggleField(field, false, true, 'generic.searchAdvanced', 'generic.searchBasic')
        // }
      },

      // List
      {
        key: CmdFieldKey.select, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.select', icon: Icons.select, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      // { key: 'id', type: FormFieldType.number, label: `${this.name}.id`, sortable: true },
      { key: 'ragioneSociale', type: FormFieldType.string, label: `${this.name}.ragioneSociale`, sortable: true },
      { key: 'codFiscale', type: FormFieldType.string, label: `${this.name}.codFiscale`, sortable: true },
      { key: 'partitaIva', type: FormFieldType.string, label: `${this.name}.partitaIva`, sortable: true }
    ];

    this.detailFields = [
    ];

    this.searchFields = [
      {
        key: 'hiddenEnciIdAnag', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: {
          searchFields: 'enciIdAnag', searchCompOp: ComparisonOperator.NotIn,
        }
      },
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'ragioneSociale,codFiscale,partitaIva', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'ragioneSociale', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.ragioneSociale`,
        options: { searchFields: 'ragioneSociale', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.default
      },
      {
        key: 'codFiscale', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.codFiscale`,
        options: { searchFields: 'codFiscale', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.default
      },
      {
        key: 'partitaIva', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.partitaIva`,
        options: { searchFields: 'partitaIva', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.default
      }
    ];
  }

}

export class VuxSociDeleGruppiModalEMC extends EntityManagerConfig {

  init() {
    this.textComponent = {};
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('enciIdAnag', SortMode.Asc)];
  }

  initFields() {
    this.cmdFields = [
      // Container

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
        // hEvent: () => {
        //   let field = this.getCmdField(CmdFieldTarget.listSearch, CmdFieldMode.btn, CmdFieldKey.toggle);
        //   this.toggleField(field, false, true, 'generic.searchAdvanced', 'generic.searchBasic')
        // }
      },

      // List
      {
        key: CmdFieldKey.select, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.select', icon: Icons.select, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      { key: 'ragioneSociale', type: FormFieldType.string, label: `${this.name}.ragioneSociale`, sortable: true },
      { key: 'tipo', type: FormFieldType.string, label: `${this.name}.tipo`, sortable: true },
      { key: 'enciIdAnag', type: FormFieldType.number, label: `${this.name}.enciIdAnag`, sortable: true },
      { key: 'codFiscale', type: FormFieldType.string, label: `${this.name}.codFiscale`, sortable: true },
      { key: 'partitaIva', type: FormFieldType.string, label: `${this.name}.partitaIva`, sortable: true }
    ];

    this.detailFields = [
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'enciIdAnag,ragioneSociale,tipo,codFiscale,partitaIva', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'ragioneSociale', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.ragioneSociale`,
        options: { searchFields: 'ragioneSociale', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'tipo', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.tipo`,
        options: { searchFields: 'tipo', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'enciIdAnag', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.number, placeholder: `${this.name}.enciIdAnag`,
        options: { searchFields: 'enciIdAnag', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'codFiscale', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.codFiscale`,
        options: { searchFields: 'codFiscale', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'partitaIva', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.partitaIva`,
        options: { searchFields: 'partitaIva', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }
}
