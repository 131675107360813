<ng-container *ngIf="translatedItems$ | async as translateItems">
    <div class="h-full flex flex-column">
        <div *ngIf="textHeader">
            <div class="flex flex-row justify-content-between">
                <p class="text-header" [innerHtml]="translateItems | translateItem: textHeader: entityInfo"> </p>
                <div *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.containerTools, fieldType:cmdFieldMode.btn}) as cmdFields"
                    class="buttons-cnt">
                    <ng-container *ngFor="let field of cmdFields">
                        <button pButton [type]="field.type || 'button'" [class]="field.class"
                            [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field" (click)="onCmd(field)"
                            [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{field: field})"
                            *ngIf="entityManager | isCmdFieldVisible:field" [icon]="field.icon"
                            [title]="translateItems | translateItem: field.title"
                            [label]="translateItems | translateItem: field.label">
                        </button>
                    </ng-container>
                </div>
            </div>
            <p-divider></p-divider>
        </div>

        <ng-container
            *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.containerHeader, fieldType:cmdFieldMode.btn}) as cmdFields">
            <div class="buttons-cnt">
                <ng-container *ngFor="let field of cmdFields">
                    <button pButton [type]="field.type || 'button'" [class]="field.class"
                        [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field"
                        *ngIf="entityManager | isCmdFieldVisible:field"
                        [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{field: field})"
                        (click)="onCmd(field)" [icon]="field.icon" [title]="translateItems | translateItem: field.title"
                        [label]="translateItems | translateItem: field.label">
                    </button>
                </ng-container>
            </div>
        </ng-container>
        <app-entity-search [entityManager]="entityManager" [dataSearch]="dataSearch" (searchEvent)="onSearch($event)"
            class="mb-3">
        </app-entity-search>
        <div class="flex-grow-1 overflow-y-hidden">
            <div class="h-full overflow-y-auto">
                <entity-list-editable [entityManager]="entityManager" [dataSearch]="dataSearch"
                    (selectEvent)="onSelectEvent($event)" (deleteEvent)="onDeleteEntities($event)">
                </entity-list-editable>
            </div>
        </div>
    </div>

    <div class="modal-spinner" *ngIf="entityRefresh$ | async | refreshing">
        <p-progressSpinner></p-progressSpinner>
    </div>
</ng-container>