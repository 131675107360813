import { GiudGiudiceSuggestionDto } from "./giud-giudice-suggestion-dto";

export class GiudAssistentatoNewDto {
  richiestaDetailIds: number[];
  giudiceId: number;
  caleManiGiudRazzeIds?: number[];
  caleManiSpGiudIds?: number[];
  assistentatoIdPadre?: number;
  giudiceRaw: Partial<GiudGiudiceSuggestionDto>;
}
