import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { MenuItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { Severity } from 'src/app/enums/severity';
import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from 'src/app/models/data-search';
import { CmdField, CmdFieldTarget, EntityManagerGrant } from 'src/app/models/entity-config';
import { GenRilascio } from 'src/app/models/gestione/gen-rilascio';
import { MessageApp } from "src/app/models/gestione/message-app";
import { AppManagerService } from 'src/app/services/app-manager.service';
import { DataService } from 'src/app/services/data.service';
import { MessageAppService } from 'src/app/services/message-app.service';
import { SecUtente } from '../../models/security/sec-utente';
import { Resources, TranslateService } from '../../services/translate.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {
  cmdFields: CmdField[];
  cardMenuItems: MenuItem[];
  translateItems: Resources;
  translatedItems$: Observable<Resources>;
  grants: EntityManagerGrant;

  private subscription: Subscription;
  public utente: SecUtente;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appManagerService: AppManagerService,
    private translateService: TranslateService,
    private messageAppService: MessageAppService,
    private dataService: DataService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.appManagerService.utente$.pipe(
        tap((utente) => this.utente = utente),
        mergeMap(() => this.translateService.translatedItems$(['generic.', 'menu.'])),
        tap((translateItems) => {
          this.translateItems = translateItems;
          this.cardMenuItems = this.buildMenuItems();
        }),
        mergeMap(() => this.appManagerService.getGrants(AppManagerRoutes.Home.section ?? AppManagerRoutes.Home.id)),
        tap((grants) => this.grants = grants),
        mergeMap(() => this.getRilascio$())
      ).subscribe((data) => {
        const text = this.translateService.translate(this.translateItems, 'generic.messageApp.rilascio', data);
        const action = () => {
          this.router.navigate([AppManagerRoutes.ProfiloUtente.url, AppManagerRoutes.GenRilascioList.url]);
        };
        const options = {
          severity: Severity.Info,
          icon: 'fa-solid fa-circle-exclamation',
          action: data.flPertinente ? action : null
        };
        const msg = new MessageApp(text, options);

        this.messageAppService.reportMessage(msg);
      })
    );
  }

  private buildMenuItems(): MenuItem[] {
    const result: MenuItem[] = [];
    const homeEntityManager = this.appManagerService.instantiateEntityManager(AppManagerRoutes.Home);
    homeEntityManager.getCmdFields(CmdFieldTarget.menu, null, false).forEach(field => {
      const entityManagerInfo = field.entityManagerInfo;
      const item: MenuItem = {
        label: field.label,
        title: field.title,
        icon: field.icon,
        command: () => {
          this.router.navigate([entityManagerInfo.url], { relativeTo: this.activatedRoute });
        }
      };
      result.push(item);
    })

    return result;
  }

  private getRilascio$(): Observable<GenRilascio> {
    const ds = new DataSearch(
      [
        new DataSearchValue(['0'], ['flRilasciato'], ComparisonOperator.Equals),
        new DataSearchValue([DateTime.now().toISODate()], ['dtAvviso'], ComparisonOperator.LessThanOrEqual),
        new DataSearchValue([DateTime.now().toISODate()], ['dtRilascio'], ComparisonOperator.GreaterThan)
      ],
      [
        new DataSortValue('dtRilascio', SortMode.Asc)
      ]
    );

    return this.dataService.searchElements<GenRilascio>(`${this.dataService.configSettings.restCommonUrl}/genrilascio`, ds).pipe(
      filter((data) => data.entities.length > 0),
      map((data) => data.entities[0])
    );
  }
}
