import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { EntityManager, EntityManagerGrant } from 'src/app/models/entity-config';
import { GenNotifica } from 'src/app/models/gestione/gen-notifica';
import { ToastMessageData } from 'src/app/models/message';
import { SecProfilo } from 'src/app/models/security/sec-profilo';
import { AppInitialized, AppManagerService, } from 'src/app/services/app-manager.service';
import { environment } from '../../../environments/environment';
import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from '../../models/data-search';
import { DataService } from '../../services/data.service';
import { MessageHubService, MESSAGE_GENERIC, MESSAGE_NOTIFICA, MESSAGE_NOTIFICA_LIST, NotificationEvent } from '../../services/message-hub.service';
import { Resources, TranslateService } from '../../services/translate.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  appInitialized: AppInitialized;
  notificationNotReadCount$: Observable<number>;
  entityManager: EntityManager;
  appTitle: string = 'Cinofilia';
  appVersion: string = environment.appVersion;
  userProfiles: SecProfilo[];
  currentProfile: SecProfilo;
  notificationNotReadCount: number = 0;
  translateItems: Resources;
  translatedItems$: Observable<Resources>;
  grants: Observable<EntityManagerGrant>;

  private subscription: Subscription;

  constructor(
    private router: Router,
    private oidcSecurityService: OidcSecurityService,
    private appManagerService: AppManagerService,
    private translateService: TranslateService,
    private messageHubService: MessageHubService,
    private dataService: DataService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    // Recupera le grants di questa sezione
    this.grants = this.appManagerService.getGrants(AppManagerRoutes.Home.section ?? AppManagerRoutes.Home.id);

    // Request to notification NOT READ
    const ds = new DataSearch(
      [new DataSearchValue(['null'], ['dataAccettazione'], ComparisonOperator.IsNull),
      new DataSearchValue(['1'], ['notificaTipoInvioId'], ComparisonOperator.Equals)],
      [new DataSortValue('data', SortMode.Desc)]
    );

    this.notificationNotReadCount$ = this.dataService.searchElements<GenNotifica>(`${this.dataService.configSettings.restCommonUrl}/gennotifica`, ds).pipe(
      map(data => data.numRowsTot)
    );

    this.subscription.add(
      this.appManagerService.initialized$.pipe(
        switchMap(appInitialized => {
          if (!appInitialized.initialized) {
            return EMPTY;
          }

          this.entityManager = this.appManagerService.getEntityManagerByUrl(this.router.url.split('/'), 0);
          const defaultProfileId = appInitialized.dtoUtente.utente.currentProfiloId;
          this.appInitialized = appInitialized;
          this.userProfiles = appInitialized.dtoUtente.profiloList;
          this.currentProfile = defaultProfileId === 0 ? this.userProfiles?.[0] : this.userProfiles.find(p => p.id === defaultProfileId);

          this.translatedItems$ = this.translateService.translatedItems$(['generic.', 'menu.']).pipe(
            tap(translateItems => {
              this.translateItems = translateItems;
            })
          );

          // request to notification NOT READ
          return this.notificationNotReadCount$;
        })
      ).subscribe(data => this.notificationNotReadCount = data)
    );

    // Listener of new notifications
    this.subscription.add(
      this.messageHubService.notificationEvent$.subscribe(
        event => {
          if (!event) return;
          this.receiveMessage(event);
        }
      )
    );
  }

  logout(): void {
    this.oidcSecurityService.logoff().subscribe();
  }

  navigateToHome(): void {
    this.router.navigate(['']);
  }

  navigateToProfiloUtente(): void {
    this.router.navigate([AppManagerRoutes.ProfiloUtente.url]);
  }

  notificationsEvent(event): void {
    this.router.navigate([AppManagerRoutes.Gestione.url, AppManagerRoutes.GenNotificaList.url], { queryParams: AppManagerRoutes.GenNotificaList.queryParams });
  }

  onProfileChange(event): void {
    this.subscription.add(
      this.dataService.updateElement(`${this.dataService.configSettings.restCommonUrl}/currentprofile`, {}, event.value)
        .subscribe(
          data => {
            this.appManagerService.showToastMessage(new ToastMessageData('success', this.translateItems['generic.profilesuccesschange']));
            this.currentProfile = data;
            this.appManagerService.initUser({ profiloId: event.value }, 'profileChange');

            this.dataService.updateElement(`${this.dataService.configSettings.restCalendarioUrl}/clrchu`, null, 'xut');
          }
        )
    );

    this.navigateToHome();
  }

  private receiveMessage(event: NotificationEvent): void {
    switch (event.type) {
      case MESSAGE_NOTIFICA_LIST:
        this.notificationNotReadCount = event.data.numRowsTot;
        break;
      case MESSAGE_NOTIFICA:
        this.notificationNotReadCount = this.notificationNotReadCount++;

        const message = this.translateService.translate(this.translateItems, 'generic.notifica.new');
        this.appManagerService.showToastMessage(new ToastMessageData('success', message));
        break;
      case MESSAGE_GENERIC:
        if (event.data.allNotificationsRead) {
          this.notificationNotReadCount = 0;
        }
        break;
    }
  }
}
