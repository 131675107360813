import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { FormModeType } from 'src/app/models/entity-config';
import { GiudDisponenteSospensione } from 'src/app/models/giudici/giud-disponente-sospensione';
import { GiudNotaSospensione } from 'src/app/models/giudici/giud-nota-sospensione';
import { GiudSospensione } from 'src/app/models/giudici/giud-sospensione';
import { GiudTipoSospensione } from 'src/app/models/giudici/giud-tipo-sospensione';
import { ToastMessageData } from 'src/app/models/message';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { BaseDataService } from 'src/app/services/base-data.service';
import { DataService } from 'src/app/services/data.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { dateGreatherThan } from 'src/app/utils/formValidators';
import { dateFormat, dateFormatPrNg } from 'src/app/utils/util';

@Component({
  selector: 'app-giud-sospensione-edit',
  templateUrl: './giud-sospensione-edit.component.html',
  styleUrls: ['./giud-sospensione-edit.component.scss']
})
export class GiudSospensioneEditComponent implements OnInit, OnDestroy {
  private resourceList: Resources;
  private subscription: Subscription;

  dateFormat: string = dateFormat;
  dateFormatPrNg: string = dateFormatPrNg;
  T$: Observable<Resources>;
  dataForm: FormGroup;
  formModeType: typeof FormModeType = FormModeType;
  formMode: number = FormModeType.insert;
  elaboration: boolean = false;
  tipoSospensioniList$: Observable<GiudTipoSospensione[]> = this.baseDataService.getGiudTipoSospensioneList();
  disponenteSospensioniList$: Observable<GiudDisponenteSospensione[]> = this.baseDataService.getGiudDisponenteSospensioneList();
  notaSospensioniList$: Observable<GiudNotaSospensione[]> = this.baseDataService.getGiudNotaSospensioneList();
  nota;

  @Input() visible: boolean = false;
  @Input() entity: GiudSospensione;
  @Input() giudiceId: number;

  @Output() onHide = new EventEmitter<boolean>();

  constructor(
    private translateService: TranslateService,
    private baseDataService: BaseDataService,
    private fb: FormBuilder,
    private dataService: DataService,
    private appManagerService: AppManagerService,
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    // Etichette
    this.T$ = this.translateService.translatedItems$(AppManagerRoutes.GiudSospensione.translateSuffixs ?? ['generic.']).pipe(
      tap(data => this.resourceList = data)
    );

    // Mode
    this.formMode = (this.entity.id ?? 0) === 0 ? FormModeType.insert : FormModeType.edit;

    // Form
    this.dataForm = this.buildForm(this.entity, this.giudiceId);
  }

  hideModal(reloadData: boolean = false): void {
    this.visible = false;
    this.onHide.emit(reloadData);
  }

  save(): void {
    const data = this.dataForm.value as GiudSospensione;

    if (this.formMode === FormModeType.insert) {
      this.dataService.insertElement<GiudSospensione>(`${this.dataService.configSettings.restCommonUrl}/giudsospensione`, data).subscribe(
        () => {
          this.elaboration = false;

          this.appManagerService.showToastMessage(
            new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.savesuccessmsg')));

          this.dataForm.markAsPristine();
          this.hideModal(true);
        },
        () => {
          this.elaboration = false;
        }
      );
    }
    else if (this.formMode === FormModeType.edit) {
      this.dataService.updateElement<GiudSospensione>(`${this.dataService.configSettings.restCommonUrl}/giudsospensione`, data, this.entity.id.toString()).subscribe({
        next: () => {
          this.elaboration = false;

          this.appManagerService.showToastMessage(
            new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.savesuccessmsg')));

          this.dataForm.markAsPristine();
          this.hideModal(true);
        },
        error: () => {
          this.elaboration = false;
        }
      });
    }
  }

  private buildForm(sospensione: GiudSospensione, giudiceId: number): FormGroup {
    const form = this.fb.group({
      id: this.fb.control<number | null>(0, [Validators.required]),
      giudiceId: this.fb.control<number | null>(giudiceId, [Validators.required]),
      tipoSospensioneId: this.fb.control<number | null>(null, [Validators.required]),
      disponenteSospensioneId: this.fb.control<number | null>(null, [Validators.required]),
      dataInizio: this.fb.control<Date | null>(null, [Validators.required]),
      dataFine: this.fb.control<Date | null>(null, [Validators.required]),
      note: this.fb.control<string | null>(null),
      userIns: this.fb.control<string | null>(null),
      dtIns: this.fb.control<Date | null>(null),
      userUpd: this.fb.control<string | null>(null),
      dtUpd: this.fb.control<Date | null>(null)
    }, {
      validators: [dateGreatherThan('dataInizio', 'dataFine')]
    });

    if (sospensione) {
      form.patchValue(sospensione);
    }

    return form;
  }
}

