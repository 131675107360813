<ng-container *ngIf="T$ | async as T">
  <div class="surface-50 border-1 border-300 border-solid h-full widget-card">
    <div class="surface-200 text-2xl font-medium text-primary p-3 header">
      {{T | translateItem: 'alertGiudWidget.titolo'}}
    </div>
    <div class="flex flex-column justify-content-center">
      <div class="px-4 flex-grow-1">
        <div class="pt-5">{{T | translateItem: 'alertGiudWidget.descrizionewidget'}}</div>
        <p-table [value]="crediti" styleClass="pt-5">
          <ng-template pTemplate="header">
            <tr>
              <th>Sezione</th>
              <th style="text-align: center;">{{annoInizio}}</th>
              <th style="text-align: center;">{{annoInizio+1}}</th>
              <th style="text-align: center;">{{annoFine}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-credito>
            <tr>
              <td>{{credito.des}}</td>
              <td style="text-align: center;">
                <span *ngIf="credito.csezAnno1">
                  <div>{{credito.csezAnno1}}</div>
                  <div>
                    <!-- <i class="fa-solid fa-circle" [class.danger]="!credito.flSezAnno1"
                      [class.success]="credito.flSezAnno1"></i> -->
                  </div>
                </span>
              </td>
              <td style="text-align: center;">
                <span *ngIf="credito.csezAnno2">
                  <div>{{credito.csezAnno2}}</div>
                  <!-- <div>
                    <i class="fa-solid fa-circle" [class.danger]="!credito.flSezAnno2"
                      [class.success]="credito.flSezAnno2"></i>
                  </div> -->
                </span>
              </td>
              <td style="text-align: center;">
                <span *ngIf="credito.csezAnno3">
                  <div>{{credito.csezAnno3}}</div>
                  <!-- <div>
                    <i class="fa-solid fa-circle" [class.danger]="!credito.flSezAnno3"
                      [class.success]="credito.flSezAnno3"></i>
                  </div> -->
                </span>
            </tr>

          </ng-template>
        </p-table>




      </div>
    </div>

  </div>
</ng-container>