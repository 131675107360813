<ng-container *ngIf="translatedItems$ | async as translateItems">

  <div *ngIf="textHeader">
    <p class="text-header" [innerHtml]="translateItems | translateItem: textHeader"></p>
  </div>

  <form [formGroup]="dataForm" class="h-full">
    <p-table #dataTableEdit [value]="entities" [totalRecords]="paginatorRowsTot" [lazy]="entityManager.loadLazy"
      [paginator]="true" [first]="paginatorFirst" [rows]="paginatorRows" [rowsPerPageOptions]="[10, 25, 50, 100]"
      styleClass="p-datatable-striped p-datatable-md" [resizableColumns]="false" selectionMode="multiple"
      [(selection)]="entitiesSelected" (onPage)="onPage($event)" (onLazyLoad)="onLazyLoad($event)" [customSort]="true"
      (sortFunction)="sortFunction($event)" dataKey="id" [showCurrentPageReport]="true"
      [currentPageReportTemplate]="translateItems | translateItem: (paginatorRowsTot ? 'generic.listReport' : 'generic.listEmpty')"
      [sortField]="baseDataSearch?.dataSortValues[0].field" [sortOrder]="baseDataSearch?.dataSortValues[0].sortMode"
      responsiveLayout="stack" breakpoint="960px" styleClass="p-datatable-striped p-datatable-md"
      paginatorPosition="bottom">

      <ng-container
        *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.listCaption, fieldType:cmdFieldMode.btn}) as cmdFields">
        <ng-template pTemplate="caption" *ngIf="cmdFields.length > 0">
          <div class="buttons-cnt">
            <ng-container *ngFor="let field of cmdFields">
              <button pButton [type]="field.type || 'button'" [class]="field.class"
                [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field" (click)="onCmd(field)"
                [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{dataNull: entity })"
                *ngIf="entityManager | isCmdFieldVisible:field" [icon]="field.icon"
                [title]="translateItems | translateItem: field.title"
                [label]="translateItems | translateItem: field.label">
              </button>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>

      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngFor="let field of listFields">
            <ng-container *ngIf="field.sortable; else nosort">
              <th class="white-space-nowrap" [pSortableColumn]="field.key">
                {{ translateItems | translateItem: field.label}} <p-sortIcon [field]="field.key">
                </p-sortIcon>
              </th>
            </ng-container>
            <ng-template #nosort>
              <th>
                {{ translateItems | translateItem: field.label}}
              </th>
            </ng-template>
          </ng-container>

          <th class="white-space-nowrap buttons-column">
            <div class="buttons-cnt">
              <ng-container
                *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.listHeader, fieldType:cmdFieldMode.btn}) as cmdFields">
                <ng-container *ngFor="let field of cmdFields">
                  <button pButton [type]="field.type || 'button'" [class]="field.class"
                    [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field" (click)="onCmd(field)"
                    [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{dataNull: entity })"
                    *ngIf="entityManager | isCmdFieldVisible:field" [icon]="field.icon"
                    [title]="translateItems | translateItem: field.title"
                    [label]="translateItems | translateItem: field.label">
                  </button>
                </ng-container>
              </ng-container>
              <ng-container
                *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.listHeaderMenu, fieldType:cmdFieldMode.btn}) as cmdFieldsMenu">
                <ng-container *ngIf="cmdFieldsMenu.length > 0">
                  <p-menu #tableactionmenu [popup]="true" appendTo="body" [model]="actionMenuItems"> </p-menu>
                  <button *ngIf="cmdFieldsMenu.length > 0" type="button" pButton icon="fa-solid fa-ellipsis-vertical"
                    [title]="translateItems | translateItem: 'generic.commands'"
                    class="p-button-sm p-button-outlined ml-2"
                    (click)="setActionMenuItems($event, cmdFieldsMenu); tableactionmenu.toggle($event)"></button>
                </ng-container>
              </ng-container>
            </div>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
        <!-- formArrayName="tableRows" -->
        <tr [class.highlight]="ri === highlightedRowIndex" #firstRow tabindex="-1">
          <ng-container *ngIf="rowData.editing; else output">
            <ng-container *ngFor="let field of detailFields">
              <td *ngIf="field.typeHtml != formFieldTypeHtml.hidden">
                <span class="p-column-title">{{translateItems | translateItem: field.label}}</span>
                <ng-container [ngSwitch]="field.typeHtml">
                  <!-- [formGroupName]="ri" -->
                  <!-- hidden -->
                  <span *ngSwitchCase="formFieldTypeHtml.readonly" [class]="field.class" class="field">
                    <input type="hidden" [formControlName]="field.key"
                      [placeholder]="translateItems | translateItem: field.placeholder">
                  </span>
                  <!-- readonly -->
                  <span *ngSwitchCase="formFieldTypeHtml.readonly" [class]="field.class" class="field flex flex-row">
                    <input pInputText type="text" [formControlName]="field.key" class="inputfield p-inputtext-sm">
                    <!-- [attr.disabled]="true" -->
                  </span>
                  <!-- text -->
                  <span *ngSwitchCase="formFieldTypeHtml.text" [class]="field.class" class="field flex flex-row">
                    <input pInputText type="text" [formControlName]="field.key"
                      [placeholder]="translateItems | translateItem: field.placeholder"
                      class="inputfield p-inputtext-sm flex-grow-1">
                    <span *ngIf="field | isRequiredField" class="ml-1 required"></span>
                  </span>
                  <!-- textarea -->
                  <span *ngSwitchCase="formFieldTypeHtml.textarea" [class]="field.class" class="field flex flex-row">
                    <textarea pInputTextarea [formControlName]="field.key"
                      [placeholder]="translateItems | translateItem: field.placeholder"
                      class="inputfield p-inputtext-sm flex-grow-1" style="min-height: 5rem;">
                  </textarea>
                    <span *ngIf=" field | isRequiredField" class="ml-1 required"></span>
                  </span>
                  <!-- number -->
                  <span *ngSwitchCase="formFieldTypeHtml.number" [class]="field.class" class="field flex flex-row">
                    <input pInputText type="number" [formControlName]="field.key"
                      [placeholder]="translateItems | translateItem: field.placeholder"
                      class="inputfield p-inputtext-sm flex-grow-1">
                    <span *ngIf="field | isRequiredField" class="ml-1 required"></span>
                  </span>
                  <!-- password -->
                  <span *ngSwitchCase="formFieldTypeHtml.password" [class]="field.class" class="field flex flex-row">
                    <input pInputText type="password" [formControlName]="field.key"
                      [placeholder]="translateItems | translateItem: field.placeholder"
                      class="inputfield p-inputtext-sm flex-grow-1">
                    <span *ngIf="field | isRequiredField" class="ml-1 required"></span>
                  </span>
                  <!-- radio -->
                  <span *ngSwitchCase="formFieldTypeHtml.radio" [class]="field.class" class="field flex flex-row">
                    <ng-container *ngFor="let item of field.options?.['dataList']">
                      <p-radioButton [formControlName]="field.options['dataObj']"
                        [label]="translateItems | translateItem: item[field.options?.dataLabel]" [value]="item">
                      </p-radioButton>
                    </ng-container>
                    <span *ngIf="field | isRequiredField" class="ml-1 required"></span>
                  </span>
                  <!-- checkbox -->
                  <span *ngSwitchCase="formFieldTypeHtml.checkbox" [class]="field.class" class="field flex flex-row">
                    <ng-container *ngFor="let item of field.options?.['dataList']">
                      <p-checkbox [label]="translateItems | translateItem: item[field.options?.dataLabel]"
                        [formControlName]="field.options['dataObj']"
                        [label]="translateItems | translateItem: item[field.options?.dataLabel]" [value]="item">
                      </p-checkbox>
                      <!-- [formControl]="$any(dataFormArray.controls[ri]).controls[field.options['dataObj']]" -->
                    </ng-container>
                    <span *ngIf="field | isRequiredField" class="ml-1 required"></span>
                  </span>
                  <!-- select -->
                  <span *ngSwitchCase="formFieldTypeHtml.select" [class]="field.class" class="field flex flex-row">
                    <ng-container *ngIf="field.options.multiselect; else singleselect">
                      <p-multiSelect [formControlName]="field.options['dataObj']"
                        [placeholder]="translateItems | translateItem: field.placeholder"
                        [options]="field.options?.['dataList']" [optionLabel]="field.options?.dataLabel"
                        [dataKey]="field.options.dataKey" appendTo="body" class="inputfield multiselect-sm flex-grow-1"
                        [emptyMessage]="translateItems | translateItem: 'generic.dropdownempty'" [maxSelectedLabels]="1"
                        [selectedItemsLabel]="translateItems | translateItem: 'generic.selecteditems'">
                      </p-multiSelect>
                      <span *ngIf="field | isRequiredField" class="ml-1 required"></span>
                    </ng-container>
                    <ng-template #singleselect>
                      <p-dropdown [formControlName]="field.options['dataObj']" [showClear]="true"
                        [placeholder]="translateItems | translateItem: field.placeholder"
                        [options]="field.options?.['dataList']" [optionLabel]="field.options?.dataLabel"
                        [dataKey]="field.options.dataKey" appendTo="body" class="inputfield p-inputtext-sm flex-grow-1"
                        [emptyMessage]="translateItems | translateItem: 'generic.dropdownEmpty'"
                        [resetFilterOnHide]="true">
                      </p-dropdown>
                      <span *ngIf="field | isRequiredField" class="ml-1 required"></span>
                    </ng-template>
                  </span>
                  <!-- date -->
                  <span *ngSwitchCase="formFieldTypeHtml.date" [class]="field.class" class="field flex flex-row">
                    <p-calendar [formControlName]="field.key" [dateFormat]="field.format?.format"
                      [placeholder]="translateItems | translateItem: field.placeholder"
                      class="inputfield p-inputtext-sm flex-grow-1" [firstDayOfWeek]="1">
                    </p-calendar>
                    <span *ngIf="field | isRequiredField" class="ml-1 required"></span>
                  </span>
                  <!-- autoComplete -->
                  <span *ngSwitchCase="formFieldTypeHtml.autocomplete" [class]="field.class"
                    class="field flex flex-row">
                    <p-autoComplete [formControlName]="field.options['dataObj']"
                      [placeholder]="translateItems | translateItem: field.placeholder" [showEmptyMessage]="true"
                      [dropdown]="true" [minLength]="2" appendTo="body" [suggestions]="field.options?.['dataList']"
                      [field]="field.options?.dataLabel" (completeMethod)="onAutoComplete(field, $event)"
                      class="inputfield p-inputtext-sm flex-grow-1">
                    </p-autoComplete> <!-- [dataKey] = "field.options?.dataKey"  -->
                    <span *ngIf="field | isRequiredField" class="ml-1 required"></span>
                  </span>
                  <!-- modalList or modalListEditable -->
                  <ng-container *ngSwitchCase="formFieldTypeHtml.modalList">
                    <ng-container *ngTemplateOutlet="modalWindow"></ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="formFieldTypeHtml.modalListEditable">
                    <ng-container *ngTemplateOutlet="modalWindow"></ng-container>
                  </ng-container>
                  <ng-template #modalWindow>
                    <list-detail-control [formControlName]="field.key" [fieldLabel]="field.options.dataLabel"
                      [icon]="field.options.icon" [modalHeader]="field.options.title" [modalResizable]="true"
                      [modalCloseOnEscape]="true" [modalClass]="'modal-window-size'"
                      [entityManagerInfo]="field.options.entityManagerInfo"
                      [fieldsBindSearch]="field.options.fieldsBindSearch" [translatedItems]="translateItems">
                    </list-detail-control>
                  </ng-template>
                  <!--
                  <ng-template #modalWindow>
                    <span [class]="field.class" class="field flex flex-row">
                      <span *ngIf="field.options?.entityManagerInfo" class="block p-input-icon-right flex-grow-1">
                        <input pInputText type="text" [formControlName]="field.key" [attr.disabled]="true"
                          [placeholder]="translateItems | translateItem: field.placeholder"
                          class="inputfield p-inputtext-sm">
                        <i [class]="field.options.icon" *ngIf="field.options.icon && (!field['disabled'])"
                          (click)="modalEMShow(field, rowData)"></i>
                      </span>
                      <span *ngIf="field | isRequiredField" class="ml-1 required"></span>
                    </span>
                  </ng-template>
                  -->
                </ng-container>
              </td>
            </ng-container>
          </ng-container>

          <ng-template #output>
            <td *ngFor="let field of listFields">
              <span class="p-column-title">{{translateItems | translateItem: field.label}}</span>
              <entity-field-view [entityManager]="entityManager" [field]="field" [data]="rowData"></entity-field-view>
            </td>
          </ng-template>

          <td class="buttons-column">
            <div class="buttons-cnt">
              <ng-container
                *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.listBody, fieldType:cmdFieldMode.btn}) as cmdFields">
                <ng-container *ngFor="let field of cmdFields">
                  <button pButton [type]="field.type || 'button'" [class]="field.class"
                    [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field"
                    (click)="onTbl(field, rowData, ri)"
                    [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{dataNull: entity, formValid: dataForm?.valid})"
                    *ngIf="(entityManager | isCmdFieldVisible:field:{editing: rowData.editing ?? false, entity: rowData})"
                    [icon]="field.icon" [title]="translateItems | translateItem: field.title"
                    [label]="translateItems | translateItem: field.label">
                  </button>
                  <!-- [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{dataNull: entity, formValid: dataFormArray.controls[ri]?.valid})" -->
                </ng-container>
              </ng-container>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="100%" class="text-center danger">
            {{translateItems | translateItem: 'generic.norecordsfound'}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </form>

  <p-dialog *ngIf="entityModal?.['entityManager']" [header]="translateItems | translateItem: entityModal['header']"
    styleClass="modal-window-size" [resizable]="true" [(visible)]="entityModal['visible']" (onHide)="modalEMHide()"
    [modal]="true" [closeOnEscape]="true" [transitionOptions]="'0ms'">
    <ng-container [ngSwitch]="entityModal['field'].key">
      <window-list-detail-search *ngSwitchCase="cmdFieldKey.modalList" [entityManager]="entityModal['entityManager']"
        [dataSearch]="entityModal['dataSearch']">
      </window-list-detail-search>
      <window-list-editable-search *ngSwitchCase="cmdFieldKey.modalListEditable"
        [entityManager]="entityModal['entityManager']" [dataSearch]="entityModal['dataSearch']">
      </window-list-editable-search>
    </ng-container>
  </p-dialog>
  <!-- <ng-container [ngSwitch]="entityModal?.['mode']">
    <ng-container *ngSwitchCase="'form'">
      <p-dialog *ngIf="entityModal?.['entityManager']" [header]="translateItems | translateItem: entityModal['header']"
        styleClass="modal-window-size" [resizable]="true" [(visible)]="entityModal['visible']" (onHide)="modalEMHide()"
        [modal]="true" [closeOnEscape]="true" [transitionOptions]="'0ms'">
        <ng-container [ngSwitch]="entityModal['field'].typeHtml">
          <window-list-detail-search *ngSwitchCase="formFieldTypeHtml.modalList"
            [entityManager]="entityModal['entityManager']" [dataSearch]="entityModal['dataSearch']"
            (selectEvent)="modalEMSelectEvent($event)">
          </window-list-detail-search>
          <window-list-editable-search *ngSwitchCase="formFieldTypeHtml.modalListEditable"
            [entityManager]="entityModal['entityManager']" [dataSearch]="entityModal['dataSearch']"
            (selectEvent)="modalEMSelectEvent($event)">
          </window-list-editable-search>
        </ng-container>
      </p-dialog>
    </ng-container>
    <ng-container *ngSwitchCase="'cmd'">
      <p-dialog *ngIf="entityModal?.['entityManager']" [header]="translateItems | translateItem: entityModal['header']"
        styleClass="modal-window-size" [resizable]="true" [(visible)]="entityModal['visible']"
        (onHide)="modalEMHideCmd()" [modal]="true" [closeOnEscape]="true" [transitionOptions]="'0ms'">
        <ng-container [ngSwitch]="entityModal['field'].key">
          <window-list-detail-search *ngSwitchCase="cmdFieldKey.modalList"
            [entityManager]="entityModal['entityManager']" [dataSearch]="entityModal['dataSearch']">
          </window-list-detail-search>
          <window-list-editable-search *ngSwitchCase="cmdFieldKey.modalListEditable"
            [entityManager]="entityModal['entityManager']" [dataSearch]="entityModal['dataSearch']">
          </window-list-editable-search>
        </ng-container>
      </p-dialog>
    </ng-container>
  </ng-container>
</ng-container> -->