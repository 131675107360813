import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { addTimezoneOffsetToDate, isAllowedInterval, mailregex, pwStrengthRegex } from './util';
export interface BooleanFn {
  (): boolean;
}

/**
 * A conditional validator generator. Assigns a validator to the form control if the predicate function returns true on the moment of validation
 * @example
 * Here if the myCheckbox is set to true, the myEmailField will be required and also the text will have to have the word 'mason' in the end.
 * If it doesn't satisfy these requirements, the errors will placed to the dedicated `illuminatiError` namespace.
 * Also the myEmailField will always have `maxLength`, `minLength` and `pattern` validators.
 * ngOnInit() {
 *   this.myForm = this.fb.group({
 *    myCheckbox: [''],
 *    myEmailField: ['', [
 *       Validators.maxLength(250),
 *       Validators.minLength(5),
 *       Validators.pattern(/.+@.+\..+/),
 *       conditionalValidator(() => this.myForm.get('myCheckbox').value,
 *                            Validators.compose([
 *                            Validators.required,
 *                            Validators.pattern(/.*mason/)
 *         ]),
 *        'illuminatiError')
 *        ]]
 *     })
 * }
 * @param predicate
 * @param validator
 * @param errorNamespace optional argument that creates own namespace for the validation error
 */

export function conditionalValidator(predicate: BooleanFn,
  validator: ValidatorFn,
  errorNamespace?: string): ValidatorFn {
  return (formControl => {
    if (!formControl.parent) {
      return null;
    }
    let error = null;
    if (predicate()) {
      error = validator(formControl);
    }
    if (errorNamespace && error) {
      const customError = {};
      customError[errorNamespace] = error;
      error = customError
    }
    return error;
  })
}

export function totalValidator(totalFieldName: string, fieldsNames: string[]): ValidatorFn {
  return (form: FormGroup): { [key: string]: { max: number, sum: number } } | null => {
    const total = form.get(totalFieldName).value;
    const fields = fieldsNames.map((f: string) => form.get(f));
    const sum = fields.reduce((s: number, f: FormControl) => s + (f.value ?? 0), 0);

    if (sum > total) {
      const error = { sumIsHigherThanTotal: { max: total, sum: sum } };
      fields.forEach((f: FormControl) => f.setErrors({ ...f.errors, ...error }));

      return error;
    }

    fields.forEach((f: FormControl) => {
      if (f.hasError('sumIsHigherThanTotal')) {
        delete f.errors['sumIsHigherThanTotal'];
        f.updateValueAndValidity();
      }
    });

    return null;
  }
}

export function dateLessThan(firstDateFieldName: string, secondDateFieldName: string): ValidatorFn {
  return (form: FormGroup): { [key: string]: { firstDate: number, secondDate: number } } | { [key: string]: boolean } | null => {
    const firstField = form.get(firstDateFieldName);
    const secondField = form.get(secondDateFieldName);
    const firstDate = firstField.value;
    const secondDate = secondField.value;
    if (!firstDate || !secondDate) {
      return { invalidDates: true };
    }

    if (firstDate.getTime() > secondDate.getTime()) {
      const error = { dateLessThen: { firstDate: firstDate, secondDate: secondDate } };
      firstField.setErrors({ ...firstField.errors, ...error });

      return error;
    }

    if (firstField.hasError('dateLessThen')) {
      delete firstField.errors['dateLessThen'];
      firstField.updateValueAndValidity();
    }

    return null;
  }
}

export function dateGreatherThan(firstDateFieldName: string, secondDateFieldName: string): ValidatorFn {
  return (form: FormGroup): { [key: string]: { firstDate: number, secondDate: number } } | { [key: string]: boolean } | null => {
    const firstField = form.get(firstDateFieldName);
    const secondField = form.get(secondDateFieldName);
    const firstDate = firstField.value;
    const secondDate = secondField.value;
    if (!firstDate || !secondDate) {
      return { invalidDates: true };
    }

    if (secondDate.getTime() < firstDate.getTime()) {
      const error = { dateGreatherThan: { firstDate: firstDate, secondDate: secondDate } };
      secondField.setErrors({ ...secondField.errors, ...error });

      return error;
    }

    if (secondField.hasError('dateGreatherThan')) {
      delete secondField.errors['dateGreatherThan'];
      secondField.updateValueAndValidity();
    }

    return null;
  }
}


// export function allowedIntervalValidator(blockedDates: Date[], startDateFieldName: string, endDateFieldName: string): ValidatorFn {
//   return function allowedIntervalValidator(form: FormGroup): { [key: string]: { start: Date, end: Date } | null } {
//     const startField = form.get(startDateFieldName);
//     const endField = form.get(endDateFieldName);
//     if (!startField.value || !endField.value) {
//       return null;
//     }

//     const startDate = addTimezoneOffsetToDate(startField.value);
//     const endDate = addTimezoneOffsetToDate(endField.value);
//     const isAllowed = isAllowedInterval(blockedDates, startDate, endDate);

//     if (!isAllowed) {
//       const error = { intervalNotAllowed: { start: startDate, end: endDate } };
//       startField.setErrors({ ...startField.errors, ...error });
//       endField.setErrors({ ...endField.errors, ...error });
//       return error;
//     }

//     if (startField.hasError('intervalNotAllowed')) {
//       delete startField.errors['intervalNotAllowed'];
//       startField.updateValueAndValidity();
//     }
//     if (endField.hasError('intervalNotAllowed')) {
//       delete endField.errors['intervalNotAllowed'];
//       endField.updateValueAndValidity();
//     }

//     return null;
//   }
// }

export function allowedDateRangeValidator(blockedDates: Date[]): ValidatorFn {
  return function allowedDateRangeValidator(control: AbstractControl): ValidationErrors | null {
    const val = control.value;

    if (!val) {
      return null;
    }

    if (!(val?.length === 2 && val[0] && val[1])) {
      return { invalidRange: true };
    }

    const startDate = addTimezoneOffsetToDate(val[0]);
    const endDate = addTimezoneOffsetToDate(val[1]);
    const isAllowed = isAllowedInterval(blockedDates, startDate, endDate);

    if (!isAllowed) {
      return { notAllowedDateRange: { start: startDate, end: endDate } };
    }

    return null;
  }
}



// export function allowedDateValidator(blockedDates: Date[]): ValidatorFn {
//   return function allowedDateValidator(field: FormGroup): { [key: string]: { [key: string]: { date: number } } | null } {
//     if (!field.value) {
//       return null;
//     }
//     const isAllowed = isAllowedDate(blockedDates, addTimezoneOffsetToDate(field.value));
//     return isAllowed ? null : { dateNotAllowed: { date: field.value } };
//   }
// }

export function notEmptyArrayValidator(): ValidatorFn {
  return function notEmptyArrayValidator(field: FormGroup): { [key: string]: boolean | null } {
    return field.value?.length > 0 ? null : { emptyArray: true };
  }
}

export function arrayFilledValidator(): ValidatorFn {
  return function arrayFilledValidator(field: FormGroup): { [key: string]: boolean | null } {
    return field.value?.every(v => v !== null && v !== undefined) ? null : { arrayNotFilled: true };
  }
}

export function arrayLengthValidator(requestedLength: number): ValidatorFn {
  return function arrayLengthValidator(field: FormGroup): { [key: string]: boolean | number | null } {
    return field.value?.length === requestedLength ? null : {
      arrayLengthNotMatched: true,
      requestedLength: requestedLength
    };
  }
}

export function emailArrayValidator(): ValidatorFn {
  return function emailArrayValidator(field: FormGroup): { [key: string]: string[] | null } {
    const values = field.value;
    const invalidEmail = values.filter(a => !a.match(mailregex));
    return invalidEmail.length === 0 ? null : { invalidEmail };
  }
}

export function emailValidator(): ValidatorFn {
  return function emailValidator(field: FormControl): { [key: string]: boolean | null } {
    const value = field.value;
    const validEmail = value?.match(mailregex);
    return validEmail ? null : { invalidEmail: !validEmail };
  }
}

export function pwMatchesValidator(pwFieldName: string, confirmPwFieldName: string): ValidatorFn {
  return (form: FormGroup): { [key: string]: { firstDate: number, secondDate: number } } | { [key: string]: boolean } | null => {
    const pwField = form.get(pwFieldName);
    const confirmPwField = form.get(confirmPwFieldName);
    const pw = pwField.value;
    const confirmPw = confirmPwField.value;

    if (pw !== confirmPw) {
      return { pwNotMatches: true };
    }

    return null;
  }
}

export function pwStrengthValidator(): ValidatorFn {
  return (control: FormControl): { [key: string]: any } | null => {
    const regex = pwStrengthRegex;
    const valid = regex.test(control.value);

    return valid ? null : { 'invalidPw': true };
  };
}

// Ritorna un errore se i campi dell'array non sono valorizzati consecutivamente
export function consequentFieldsFilledValidator(fieldsNames: string[]): ValidatorFn {
  return (form: FormGroup): { [key: string]: { invalidFields: string[] } } | null => {
    if (!fieldsNames?.length) {
      return null;
    }
    const fields = fieldsNames.map(f => form.get(f));
    const values = fields.map(f => f.value);
    const firstInvalid = values.findIndex(v => !v);
    const lastValid = Math.abs([...values].reverse().findIndex(v => !!v) - values.length + 1);

    if (values.some(v => !!v) && firstInvalid > 0 && lastValid > firstInvalid) {
      const invalidFieldsNames = fieldsNames.slice(firstInvalid, lastValid + 1);
      const invalidFields = invalidFieldsNames.map(f => form.get(f));
      const error = { notConsequentFieldsFilled: { invalidFields: invalidFieldsNames } };
      invalidFields.forEach((f: FormControl) => f.setErrors({ ...f.errors, ...error }));

      return error;
    }

    fields.forEach((f: FormControl) => {
      if (f.hasError('notConsequentFieldsFilled')) {
        delete f.errors['notConsequentFieldsFilled'];
        f.updateValueAndValidity();
      }
    });

    return null;
  }
}
