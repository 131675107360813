<ng-container *ngIf="T$|async as T">
  <div class="flex flex-column h-full">
    <ng-container *ngIf="!isInHome">
      <div class="flex flex-row justify-content-between">
        <p class="text-header">{{T | translateItem: 'genrilascio.windowListHeader'}}</p>

        <div class="buttons-cnt">
          <button pButton type="button" class="p-button-outlined p-button-sm" (click)="help()"
            icon="fa-solid fa-circle-question" [title]="T | translateItem: 'generic.help'" button></button>
        </div>
      </div>

      <p-divider></p-divider>
    </ng-container>

    <div class="flex-grow-1 overflow-y-auto align-content-start page-detail-container">
      <div class="striped-fieldsets" style="margin: 0 1px">

        <app-message-app [styleClasses]="'mb-5 mt-3'" *ngIf="utente?.flHoRilasciDaAccettare;"></app-message-app>

        <p-fieldset *ngFor="let rilascio of entities" [toggleable]="true"
          [collapsed]="rilascio.dtAccettazione != null || (rilascio.flRilasciato && !rilascio.flHasProfili)"
          [styleClass]="'mb-5'">

          <ng-template pTemplate="header">
            <h3 class="flex align-items-center m-0">
              <p-chip [label]="rilascio.versione" styleClass="font-bold mr-2"></p-chip>
              <span class="mr-2 text-primary">{{rilascio.des}}</span>
              <p-tag *ngIf="rilascio.flRilasciato && !rilascio.dtAccettazione && rilascio.flHasProfili"
                styleClass="p-mr-2" [severity]="'warning'" value="NEW"></p-tag>
              <i *ngIf="!rilascio.flRilasciato && !rilascio.dtAccettazione  && !rilascio.flInRitardo"
                class="fa-solid fa-calendar-days text-blue-400" style="font-size: 1.5rem"></i>
              <i *ngIf="!rilascio.flRilasciato && !rilascio.dtAccettazione && rilascio.flInRitardo"
                class="fa-solid fa-calendar-xmark text-blue-400" style="font-size: 1.5rem"></i>
              <i *ngIf="rilascio.dtAccettazione && rilascio.flHasProfili" class="fa-solid fa-check text-green-500"></i>
            </h3>
          </ng-template>

          <ng-template pTemplate="content">
            <div class="grid">
              <div class="col flex" data-coll-type="ListaDettagliRilascio">
                <h4 *ngIf="!rilascio.dettagliCount" class="text-600">
                  {{T | translateItem: 'genrilascio.emptyDetailMessage'}}
                </h4>

                <div>
                  <div *ngFor="let gruppoRilasci of rilascio.dettagli | keyvalue">
                    <h4>{{gruppoRilasci.key}}</h4>
                    <ul>
                      <li *ngFor="let dettaglio of $any(gruppoRilasci.value)" [ngClass]="!dettaglio.flPertinente ? 'non-pertinente' :
                  rilascio.flRilasciato && !rilascio.dtAccettazione ? 'pertinente border-left-3 border-yellow-500 list-none' :
                  rilascio.flRilasciato && rilascio.dtAccettazione ? 'pertinente border-left-3 border-green-500 list-none' :
                  'pertinente border-left-3 border-blue-500 list-none'"
                        [style]="!dettaglio.flPertinente ? '' : 'margin-left: -1em; padding-left: calc(1em - 3px)'">
                        <p>{{dettaglio.des}}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-6 sm:col-5 md:col-4 lg:col-3 xl:col-2 ml-8 mr-4 flex flex-column"
                data-coll-type="StatoRilascio">
                <ng-container #rilasciatoDaAccettare
                  *ngIf="rilascio.flRilasciato && !rilascio.dtAccettazione; else accettato">
                  <div class="border-gray-400 border-left-1 pl-5 mt-0 h-full">
                    <button pButton type="button" *ngIf="rilascio.flHasProfili"
                      [label]="T|translateItem: 'genrilascio.accettarilascio.button'"
                      [title]="T|translateItem: 'genrilascio.accettarilascio.button'"
                      (click)="accettaRilascio([rilascio.id])"
                      class="p-button p-button-sm p-button-outlined p-button-lg p-button-rounded bg-white w-full mb-3"></button>
                    <span class="font-light">{{T|translateItem:
                      'genrilascio.rilascioeseguito.label'}}</span><br /><span
                      class="text-lg font-medium">{{rilascio.dtRilascio|
                      date: dateTimeFormat}}</span>
                  </div>
                </ng-container>

                <ng-template #accettato>
                  <ng-container *ngIf="rilascio.flRilasciato && rilascio.dtAccettazione; else comingsoon">
                    <p class="border-gray-400 border-left-1 pl-5 mt-0 h-full">
                      <span class="font-light">{{T|translateItem:'genrilascio.rilascioaccettato.label'}}</span><i
                        class="fa-solid fa-check text-green-400 ml-3" style="font-size: 2rem"></i><br />
                      <span class="text-lg font-medium">{{rilascio.dtAccettazione| date:
                        dateTimeFormat}}</span><br /><br />
                      <span
                        class="font-light">{{T|translateItem:'genrilascio.rilascioeseguito.label'}}</span><br /><span
                        class="text-lg font-medium">{{rilascio.dtRilascio|
                        date: dateTimeFormat}}</span>
                    </p>
                  </ng-container>
                </ng-template>

                <ng-template #comingsoon>
                  <p class="border-gray-400 border-left-1 pl-5 mt-0 h-full text-blue-600">
                    <p-chip *ngIf="rilascio.flInRitardo"
                      styleClass="mb-3 text-blue-600 text-center uppercase w-full justify-content-center"
                      pi-chip-remove-icon
                      [label]="T|translateItem:'genrilascio.rilascioprevistoinritardo.label'"></p-chip>
                    <span
                      class="text-lg font-light">{{T|translateItem:'genrilascio.previsionerilascio.label'}}</span><br />
                    <span class="text-lg font-medium">{{rilascio.dtRilascio | date: dateTimeFormat}}</span>
                  </p>
                </ng-template>
              </div>
            </div>
          </ng-template>
        </p-fieldset>
      </div>
    </div>
  </div>
</ng-container>