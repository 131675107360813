import { Entity } from "../entity";

export class GiudAssistentato extends Entity {
  richiestaDetailId: number;
  dataRichiesta: Date;
  statoAssistentatoId: number;
  giudiceId: number;
  caleManiId: number;
  caleManiSpId?: number
  esitoAssistentatoId?: number;
  esitoNote?: string;
  assistentatoIdPadre?: number;

  richiestaId: number;
  statoAssistentatoCod: string;
  statoAssistentatoDes: string;
  statoAssistentatoLivello: number;
  esitoAssistentatoCod?: string;
  esitoAssistentatoDes?: string;
  dtRichiesta: Date;
  tipoRichiestaId: number;
  tipoRichiestaCod: string;
  gruppoProveId: number;
  giudiceNome: string;
  giudiceCognome: string;
  giudiceNomeCompleto: string;
  caleManiLabel: string;
  caleManiDataInizio: Date;
  caleManiDataFine: Date;
  caleManiGiorno?: number;
  data?: Date;
  flSuPiuGiorni: boolean;
  tipoCalId: number;
  tipoCalCod: string;
  giudiceRichiedenteId: number;
  giudiceRichiedenteNome: string;
  giudiceRichiedenteCognome: string;
  giudiceRichiedenteNomeCompleto: string;
  flRipetizioneConGiudiceDiverso: boolean;
  flPossibileRipetizione: boolean;
  flRaggiuntoLimiteAssistentatiMani: boolean;
  assistentatiManiCount: number;
  gruppoFciId?: number;
  gruppoFciDes?: string;
  gruppoFciLabel?: string;
  razzaId?: number;
  razzaDes?: string;
  tipoManiId?: number;
  tipoManiCod?: string;
  tipoManiDes?: string;
  codProtipo?: string;
  codProtSuddDes?: string;
  giudAssistentato: string;
  sottoProvaLabel?: string;
}
