import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, EntityManagerConfig, CmdField } from "../entity-config";
import { AppManagerRoutes } from "../../app-manager-routes";

export class AnagraficheEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GenResourceList, label: 'menu.GenResourceList.label', title: 'menu.GenResourceList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GenLinguaList, label: 'menu.GenLangList.label', title: 'menu.GenLangList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GenNotificaTipoList, label: 'menu.GenNotificaTipoList.label', title: 'menu.GenNotificaTipoList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GenNotificaTipoInvioList, label: 'menu.GenNotificaTipoInvioList.label', title: 'menu.GenNotificaTipoInvioList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GenAnomaliaList, label: 'menu.GenAnomaliaList.label', title: 'menu.GenAnomaliaList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GenAnomaliaStatoList, label: 'menu.GenAnomaliaStatoList.label', title: 'menu.GenAnomaliaStatoList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GenNotaTipoList, label: 'menu.GenNotaTipoList.label', title: 'menu.GenNotaTipoList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GenAllegatoTipoList, label: 'menu.GenAllegatoTipoList.label', title: 'menu.GenAllegatoTipoList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.AnaCal, label: 'menu.AnaCal.label', title: 'menu.AnaCal.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.AnaCalStati, label: 'menu.AnaCalStati.label', title: 'menu.AnaCalStati.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.AnaCalTipi, label: 'menu.AnaCalTipi.label', title: 'menu.AnaCalTipi.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.AnaRazze, label: 'menu.AnaRazze.label', title: 'menu.AnaRazze.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.AnaRelazione, label: 'menu.AnaRelazione.label', title: 'menu.AnaRelazione.title'
      }
    ];
  }

}
