<ng-container *ngIf="T$ | async as T">
  <p-dialog [(visible)]="visible" [modal]="true" styleClass="modal-window-size-small" [resizable]="true"
    [closeOnEscape]="true" (onHide)="hideModal()">

    <ng-template pTemplate="header">
      <span class="p-dialog-title">
        {{T | translateItem: (formMode === formModeType.insert ? 'giudicisospensioni.sospensioneInsertModalTitle' :
        'giudicisospensioni.sospensioneEditModalTitle')}}
      </span>
    </ng-template>

    <ng-template pTemplate="content">
      <form [formGroup]="dataForm">
        <div class="grid formgrid my-0">
          <div class="field col-12 md:col-6">
            <label class="text-sm required">{{T | translateItem: 'giudicisospensioni.tipoSospensione'}}</label>
            <p-dropdown [options]="tipoSospensioniList$ | async" formControlName="tipoSospensioneId"
              [placeholder]="T | translateItem: 'giudicisospensioni.tipoSospensione'" optionValue="id" optionLabel="des"
              class="inputfield p-inputtext-sm" [emptyMessage]="T | translateItem: 'generic.dropdownEmpty'"
              [filter]="true" [autofocusFilter]="true" [resetFilterOnHide]="true" appendTo="body" />
          </div>

          <div class="field col-12 md:col-6">
            <label class="text-sm required">{{T | translateItem: 'giudicisospensioni.disponenteSospensione'}}</label>
            <p-dropdown [options]="disponenteSospensioniList$ | async" formControlName="disponenteSospensioneId"
              [placeholder]="T | translateItem: 'giudicisospensioni.disponenteSospensione'" optionValue="id"
              optionLabel="des" class="inputfield p-inputtext-sm"
              [emptyMessage]="T | translateItem: 'generic.dropdownEmpty'" [filter]="true" [autofocusFilter]="true"
              [resetFilterOnHide]="true" appendTo="body" />
          </div>

          <div class="field col-12 md:col-6">
            <label class="text-sm required">{{T | translateItem: 'giudicisospensioni.dataInizio'}}</label>
            <p-calendar formControlName="dataInizio" [showIcon]="true" [dateFormat]="dateFormatPrNg"
              [readonlyInput]="true" [placeholder]="T | translateItem: 'giudicisospensioni.dataInizio'"
              [firstDayOfWeek]="1" class="inputfield p-inputtext-sm" appendTo="body" />
          </div>

          <div class="field col-12 md:col-6">
            <label class="text-sm required">{{T | translateItem: 'giudicisospensioni.dataFine'}}</label>
            <p-calendar formControlName="dataFine" [showIcon]="true" [dateFormat]="dateFormatPrNg"
              [readonlyInput]="true" [placeholder]="T | translateItem: 'giudicisospensioni.dataFine'"
              [firstDayOfWeek]="1" class="inputfield p-inputtext-sm" appendTo="body" />
          </div>

          <app-template-note ngDefaultControl [formGroup]="dataForm" controlName="note"
            [templates]="notaSospensioniList$ | async" [noteLabel]="T | translateItem: 'giudicisospensioni.note'"
            [notePlaceholder]="T | translateItem: 'giudicisospensioni.note'" [okLabel]="T | translateItem: 'generic.ok'"
            [cancelLabel]="T | translateItem: 'generic.cancel'"
            [templatePlaceholder]="T | translateItem: 'giudicisospensioni.selectTemplate'"
            [warningMessage]="T | translateItem: 'giudicisospensioni.relpaceNotaWarningMessage'"
            styleClass="field col-12" />
        </div>
      </form>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="flex justify-content-end">
        <div class="buttons-cnt">
          <app-button size="medium" [outlined]="true" [disabled]="elaboration" (onClick)="hideModal()"
            [tooltip]="T | translateItem: 'generic.back'" [label]="T | translateItem: 'generic.back'"
            icon="fa-solid fa-xmark" />

          <app-button size="medium" [disabled]="elaboration || dataForm.pristine || !dataForm.valid" (onClick)="save()"
            [tooltip]="T | translateItem: 'generic.save'" [label]="T | translateItem: 'generic.save'"
            icon="fa-solid fa-floppy-disk" />
        </div>
      </div>
    </ng-template>
  </p-dialog>
</ng-container>