<ng-container [formGroup]="dataForm" *ngIf="translatedItems$ | async as translateItems">
  <div class="formgrid grid align-content-start h-full overflow-y-auto search-row-gap-2">
    <ng-container *ngFor="let field of detailFields">
      <ng-container *ngIf="entityManager | isFormFieldVisible:field:dataForm.value">
        <ng-container [ngSwitch]="field.typeHtml">
          <!-- hidden -->
          <!-- <span *ngSwitchCase="formFieldTypeHtml.hidden" [class]="field.class" class="field" class="field hidden">
          <input type="hidden" [formControlName]="field.key"
            [placeholder]="translateItems | translateItem: field.placeholder">
        </span> -->
          <!-- readonly -->
          <span *ngSwitchCase="formFieldTypeHtml.readonly" [class]="field.class" class="field">
            <label class="text-sm" [ngClass]="{'required': field | isRequiredField}"
              *ngIf="field.label">{{translateItems
              | translateItem: field.label}}</label>
            <input pInputText type="text" [formControlName]="field.key" class="inputfield p-inputtext-sm">
            <!-- [attr.disabled]="true" -->
          </span>
          <!-- view -->
          <span *ngSwitchCase="formFieldTypeHtml.view" [class]=" field.class" class="field">
            <label class="text-sm" [ngClass]="{'required': field | isRequiredField}">{{translateItems | translateItem:
              field.label}}</label>
            <span class="block font-medium mt-1">
              <entity-field-view [entityManager]="entityManager" [field]="field" [data]="$any(dataForm).value">
              </entity-field-view>
              <!-- {{($any(dataForm)).controls[field.key]?.value}} -->
            </span>
          </span>
          <!-- htmlView -->
          <span *ngSwitchCase="formFieldTypeHtml.htmlView" [class]="field.class" class="field">
            <label class="text-sm" [ngClass]="{'required': field | isRequiredField}">{{translateItems | translateItem:
              field.label}}</label>
            <span class="block font-medium html-container"
              [innerHTML]="($any(dataForm)).controls[field.key]?.value"></span>
          </span>
          <!-- text -->
          <span *ngSwitchCase="formFieldTypeHtml.text" [class]="field.class" class="field">
            <label class="text-sm" [ngClass]="{'required': field | isRequiredField}"
              *ngIf="field.label">{{translateItems
              | translateItem: field.label}}</label>
            <input pInputText type="text" [formControlName]="field.key"
              [placeholder]="translateItems | translateItem: field.placeholder" class="inputfield p-inputtext-sm">
          </span>
          <!-- textarea -->
          <span *ngSwitchCase="formFieldTypeHtml.textarea" [class]="field.class" class="field">
            <label class="text-sm" [ngClass]="{'required': field | isRequiredField}"
              *ngIf="field.label">{{translateItems
              |
              translateItem: field.label}}</label>
            <textarea pInputTextarea [formControlName]="field.key"
              [placeholder]="translateItems | translateItem: field.placeholder" class="inputfield">
          </textarea>
          </span>
          <!-- editor -->
          <span *ngSwitchCase="formFieldTypeHtml.editor" [class]="field.class" class="field">
            <label class="text-sm" [ngClass]="{'required': field | isRequiredField}"
              *ngIf="field.label">{{translateItems
              |
              translateItem: field.label}}</label>
            <p-editor [formControlName]="field.key" [style]="{'height':'320px'}"
              [placeholder]="translateItems | translateItem: field.placeholder"
              (onTextChange)="onEditorTextChange(field, $event)">
            </p-editor>
          </span>
          <!-- number -->
          <span *ngSwitchCase="formFieldTypeHtml.number" [class]="field.class" class="field">
            <label class="text-sm" [ngClass]="{'required': field | isRequiredField}"
              *ngIf="field.label">{{translateItems
              |
              translateItem: field.label}}</label>
            <p-inputNumber [formControlName]="field.key" [showButtons]="true" [useGrouping]="false"
              [min]="field?.options?.min" [max]="field?.options?.max"
              [placeholder]="translateItems | translateItem: field.placeholder" class="inputfield p-inputtext-sm">
            </p-inputNumber>
          </span>
          <!-- password -->
          <span *ngSwitchCase="formFieldTypeHtml.password" [class]="field.class" class="field">
            <label class="text-sm" [ngClass]="{'required': field | isRequiredField}"
              *ngIf="field.label">{{translateItems
              |
              translateItem: field.label}}</label>
            <input pInputText type="password" [formControlName]="field.key"
              [placeholder]="translateItems | translateItem: field.placeholder" class="inputfield p-inputtext-sm">
          </span>
          <!-- radio -->
          <span *ngSwitchCase="formFieldTypeHtml.radio" [class]="field.class" class="field">
            <label class="text-sm" [ngClass]="{'required': field | isRequiredField}"
              *ngIf="field.label">{{translateItems
              | translateItem: field.label}}</label>
            <div class="radio-cnt">
              <ng-container *ngFor="let item of field.options?.['dataList']">
                <p-radioButton [formControlName]="field.options['dataObj']"
                  [label]="translateItems | translateItem: item[field.options?.dataLabel]" [value]="item"
                  class="inputfield">
                </p-radioButton>
              </ng-container>
            </div>
          </span>
          <!-- checkbox -->
          <span *ngSwitchCase="formFieldTypeHtml.checkbox" [class]="field.class" class="field">
            <label class="text-sm" [ngClass]="{'required': field | isRequiredField}"
              *ngIf="field.label">{{translateItems
              |
              translateItem: field.label}}</label>
            <ng-container *ngFor="let item of field.options?.['dataList']">
              <p-checkbox [name]="field.key" [formControl]="($any(dataForm)).controls[field.options['dataObj']]"
                [label]="translateItems | translateItem: item[field.options?.dataLabel]" [value]="item"
                class="inputfield">
              </p-checkbox>
            </ng-container>
          </span>
          <!-- select -->
          <span *ngSwitchCase="formFieldTypeHtml.select" [class]="field.class" class="field">
            <label class="text-sm" [ngClass]="{'required': field | isRequiredField}"
              *ngIf="field.label">{{translateItems
              |
              translateItem: field.label}}</label>
            <ng-container *ngIf="field.options.multiselect; else singleselect">
              <p-multiSelect [formControlName]="field.options['dataObj']" appendTo="body"
                [placeholder]="translateItems | translateItem: field.placeholder"
                [options]="field.options?.['dataList']" [optionLabel]="field.options.dataLabel"
                [dataKey]="field.options.dataKey" class="inputfield p-inputtext-sm">
              </p-multiSelect>
            </ng-container>
            <ng-template #singleselect>
              <p-dropdown [formControlName]="field.options['dataObj']" [showClear]="true" appendTo="body"
                [placeholder]="translateItems | translateItem: field.placeholder"
                [options]="field.options?.['dataList']" [optionLabel]="field.options.dataLabel"
                [dataKey]="field.options.dataKey" (onClear)="onClearDropDown(field,$event)"
                class="inputfield p-inputtext-sm"
                [emptyMessage]="translateItems | translateItem: 'generic.dropdownEmpty'">
              </p-dropdown> <!-- [optionValue]="field.options?.dataKey" -->
            </ng-template>
          </span>
          <!-- file -->
          <span *ngSwitchCase="formFieldTypeHtml.file" [class]="field.class" class="field">
            <label class="text-sm" [ngClass]="{'required': field | isRequiredField}"
              *ngIf="field.label">{{translateItems
              |
              translateItem: field.label}}</label>
            <p-fileUpload [name]="field.key" [auto]="true" [accept]="field.options?.fileAccept"
              chooseLabel="{{translateItems | translateItem: 'generic.fileSelect'}}" [customUpload]="true"
              (uploadHandler)="onChange(field, $event.files)" [styleClass]="field.class" [disabled]="field['disabled']"
              class="inputfield p-inputtext-sm">
            </p-fileUpload>
          </span>
          <!-- date -->
          <span *ngSwitchCase="formFieldTypeHtml.date" [class]="field.class" class="field">
            <label class="text-sm" [ngClass]="{'required': field | isRequiredField}"
              *ngIf="field.label">{{translateItems
              |
              translateItem: field.label}}</label>
            <p-calendar [formControlName]="field.key" [dateFormat]="field.format?.format"
              [placeholder]="translateItems | translateItem: field.placeholder" class="inputfield p-inputtext-sm"
              [firstDayOfWeek]="1">
            </p-calendar>
          </span>
          <!-- autoComplete -->
          <span *ngSwitchCase="formFieldTypeHtml.autocomplete" [class]="field.class" class="field">
            <label class="text-sm" [ngClass]="{'required': field | isRequiredField}"
              *ngIf="field.label">{{translateItems
              |
              translateItem: field.label}}</label>
            <p-autoComplete [formControlName]="field.options['dataObj']"
              [placeholder]="translateItems | translateItem: field.placeholder" [showEmptyMessage]="true"
              [dropdown]="true" [minLength]="2" appendTo="body" [suggestions]="field.options?.['dataList']"
              [field]="field.options?.dataLabel" (completeMethod)="onAutoComplete(field, $event)"
              class="inputfield p-inputtext-sm">
            </p-autoComplete><!--  [dataKey] = "field.options?.dataKey"  -->
          </span>
          <!-- modalList or modalListEditable -->
          <ng-container *ngSwitchCase="formFieldTypeHtml.modalList">
            <ng-container *ngTemplateOutlet="modalWindow"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="formFieldTypeHtml.modalListEditable">
            <ng-container *ngTemplateOutlet="modalWindow"></ng-container>
          </ng-container>
          <ng-template #modalWindow>
            <list-detail-control [formControlName]="field.key" [fieldLabel]="field.options.dataLabel"
              [icon]="field.options.icon" [modalHeader]="field.options.title" [modalResizable]="true"
              [modalCloseOnEscape]="true" [modalClass]="'modal-window-size'"
              [entityManagerInfo]="field.options.entityManagerInfo" [fieldsBindSearch]="field.options.fieldsBindSearch">
            </list-detail-control>
          </ng-template>
          <!-- <ng-template #modalWindow>
            <span [class]="field.class" class="field">
              <label class="text-sm" [ngClass]="{'required': field| isRequiredField}"
                *ngIf="field.label">{{translateItems
                | translateItem: field.label}}</label>
              <span *ngIf="field.options?.entityManagerInfo" class="block p-input-icon-right">
                <input pInputText type="text" [formControlName]="field.key" [attr.disabled]="true"
                  [placeholder]="translateItems | translateItem: field.placeholder" class="inputfield p-inputtext-sm">
                <i [class]="field.options.icon" *ngIf="field.options.icon && (!field['disabled'])"
                  (click)="modalEMShow(field)"></i>
              </span>
            </span>
          </ng-template> -->

          <!-- divider -->
          <p-divider *ngSwitchCase="formFieldTypeHtml.divider" class="col-12 mb-0" [class]="field.class" class="field">
            <span *ngIf="field.label">{{translateItems | translateItem: field.label}}</span>
          </p-divider>

        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <!-- <p-dialog *ngIf="entityModal?.['entityManager']" [header]="translateItems | translateItem: entityModal['header']"
    styleClass="modal-window-size" [resizable]="true" [(visible)]="entityModal['visible']" (onHide)="modalEMHide()"
    [modal]="true" [closeOnEscape]="true">
    <ng-container [ngSwitch]="entityModal['field'].typeHtml">
      <window-list-detail-search *ngSwitchCase="formFieldTypeHtml.modalList"
        [entityManager]="entityModal['entityManager']" [dataSearch]="entityModal['dataSearch']"
        (selectEvent)="modalEMSelectEvent($event)">
      </window-list-detail-search>
      <window-list-editable-search *ngSwitchCase="formFieldTypeHtml.modalListEditable"
        [entityManager]="entityModal['entityManager']" [dataSearch]="entityModal['dataSearch']"
        (selectEvent)="modalEMSelectEvent($event)">
      </window-list-editable-search>
    </ng-container>
  </p-dialog> -->

</ng-container>