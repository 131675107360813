<div class="h-screen surface-200 p-2">
  <div class="bg-white px-4 py-3 round-border shadow flex flex-column justify-content-between gap-3  h-full">
    <div class="flex justify-content-between">
      <img src="assets/images/enci-logo.svg" alt="enci logo" class="h-6rem">
      <div class="flex gap-2 align-items-start">
        <div class="overflow-hidden border-round border-2 border-200 h-2rem"
          [ngClass]="{'border-primary': currentLanguage === 'it-IT'}">
          <img src="assets/flags/it.svg" alt="flag italia" class="border-round h-full" (click)="setlanguage('it-IT')">
        </div>

        <div class="overflow-hidden border-round border-2 border-200 h-2rem"
          [ngClass]="{'border-primary': currentLanguage === 'en-GB'}">
          <img src="assets/flags/gb.svg" alt="flag great britain" class="border-round h-full"
            (click)="setlanguage('en-GB')">
        </div>
      </div>
    </div>

    <div class="flex flex-column align-items-center justify-content-center gap-3 flex-grow-1">
      <i class="fa-solid fa-triangle-exclamation text-8xl warning"></i>

      <div class="text-xl font-medium">
        {{currentLanguage | i18nSelect : messageMap}}
      </div>

      <div class="text-600">
        {{currentLanguage | i18nSelect : secondaryMessageMap}}
      </div>

      <div class="mt-4">
        <app-button [outlined]="true" size="medium" icon="fa-solid fa-arrow-right-from-bracket"
          [tooltip]="currentLanguage | i18nSelect : logoutButtonTitle"
          [label]="currentLanguage | i18nSelect : logoutButtonLabel" (onClick)="logout()" />
      </div>
    </div>
  </div>
</div>
