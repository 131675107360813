<ng-container *ngIf="translatedItems$ | async as translateItems">
  <!-- <div *ngIf="textHeader">
        <p class="text-header" [innerHtml]="translateItems | translateItem: textHeader"></p>
    </div> -->

  <div class="buttons-cnt">
    <ng-container
      *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.viewHeader, fieldType:cmdFieldMode.btn}) as cmdFields">
      <ng-container *ngFor="let field of cmdFields">
        <button pButton [type]="field.type || 'button'" [class]="field.class"
          [disabled]="entityManager | isCmdFieldDisabled:field:{field: field}"
          *ngIf="entityManager | isCmdFieldVisible:field" [icon]="field.icon"
          [title]="translateItems | translateItem: field.title" [label]="translateItems | translateItem: field.label"
          (click)="onCmd(field)">
        </button>
      </ng-container>
    </ng-container>
  </div>

  <div>
    <ng-container *ngFor="let field of listFields">
      <entity-field-view [entityManager]="entityManager" [field]="field" [data]="data"></entity-field-view>
    </ng-container>
  </div>

  <div class="buttons-cnt">
    <ng-container
      *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.viewFooter, fieldType:cmdFieldMode.btn}) as cmdFields">
      <ng-container *ngFor="let field of cmdFields">
        <button pButton [type]="field.type || 'button'" [class]="field.class"
          [disabled]="entityManager | isCmdFieldDisabled:field:{field: field}"
          *ngIf="entityManager | isCmdFieldVisible:field" [icon]="field.icon"
          [title]="translateItems | translateItem: field.title" [label]="translateItems | translateItem: field.label"
          (click)="onCmd(field)">
        </button>
      </ng-container>
    </ng-container>
  </div>

</ng-container>