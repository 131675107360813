import { Entity } from "../../entity";

export class CalTipoStampaMani extends Entity {
  cod: string;
  serviceName: string;
  tipoCalId: number;
  tipoManiId: number;
  visible: boolean;
  system: boolean;
  firmatarioDocumentoDefaultId: number;
  flFirma: boolean;
  flProtocollo: boolean;
  flPratica: boolean;
  flModifica: boolean;
  flDataConsiglio: boolean;
  flDelegato: boolean;
  flStampaRisultati: boolean;
  flContieneDatiMani: boolean;
  flBrevetto: boolean;

  des: string;
  defaultFileName: string;
  tipoCalCod: string;
  tipoManiCod: string;
}

export enum TipoStampaManiCod {
  /* necessario per individuare le stampe che devono avere comportamenti particolari ad esempio obbligo di presenza di giuria */

  OmologazioneProva = 'OMOL_PRV', /* non utizzato per ora */
  OmologazioneEsposizione = 'OMOL_EXP',  /* non utizzato per ora */
  RatificaDelegatiProva = 'RAT_DELE_PRV', /* non utizzato per ora */
  RatificaDelegatiEsposizione = 'RAT_DELE_EXP',  /* non utizzato per ora */
  StampaRelazioneDelegatoProva = 'REL_DELE_PRV', /* non utizzato per ora */
  StampaRelazioneDelegatoEsposizione = 'REL_DELE_EXP',  /* non utizzato per ora */

  DistanzeKmDelegatiProva = 'DIST_DELE_PRV', /* non utizzato per ora */
  DistanzeKmDelegatiEsposizione = 'DIST_DELE_EXP',  /* non utizzato per ora */

  RatificaGiudiciProva = 'RAT_GIUD_PRV',
  RatificaGiudiciEsposizione = 'RAT_GIUD_EXP',
  DistanzeKmGiudiciProva = 'DIST_KM_PRV',
  DistanzeKmGiudiciEsposizione = 'DIST_KM_EXP',
}
