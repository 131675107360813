import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { DataService } from 'src/app/services/data.service';
import { dateTimeFormat } from 'src/app/utils/util';
import { GenNotifica } from 'src/app/models/gestione/gen-notifica';
import { IEntityList } from 'src/app/models/entity';
import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from 'src/app/models/data-search';
import { Router } from '@angular/router';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { MessageHubService, MESSAGE_GENERIC, MESSAGE_NOTIFICA, MESSAGE_NOTIFICA_LIST, NotificationEvent } from 'src/app/services/message-hub.service';

@Component({
  selector: 'app-notifiche-widget',
  templateUrl: './notifiche-widget.component.html',
  styleUrls: ['./notifiche-widget.component.scss']
})

export class NotificheWidgetComponent implements OnInit, OnDestroy {
  dateTimeFormat: string = dateTimeFormat;
  unreadMessagesList: GenNotifica[] = [];
  T$: Observable<Resources> = this.translateService.translatedItems$(['generic.', 'notifichewidget.']);
  notificationNotRead$: Observable<IEntityList<GenNotifica>>;
  messageQty: number = 3;
  elaboration: boolean = false;
  unreadMessagesCount: number;

  private subscription: Subscription;

  constructor(
    private router: Router,
    private dataService: DataService,
    private translateService: TranslateService,
    private messageHubService: MessageHubService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.elaboration = true;

    const ds = new DataSearch(
      [new DataSearchValue(['null'], ['dataAccettazione'], ComparisonOperator.IsNull),
      new DataSearchValue(['1'], ['notificaTipoInvioId'], ComparisonOperator.Equals)],
      [new DataSortValue('data', SortMode.Desc)],
      1,
      this.messageQty
    );

    this.notificationNotRead$ = this.dataService.searchElements<GenNotifica>(`${this.dataService.configSettings.restCommonUrl}/gennotifica`, ds)

    this.subscription.add(
      this.notificationNotRead$.subscribe(
        (data: IEntityList<GenNotifica>) => {
          this.unreadMessagesCount = data.numRowsTot;
          this.unreadMessagesList = data.entities.slice(0, this.messageQty);
        }
      )
    );

    // listener of new notifications
    this.subscription.add(
      this.messageHubService.notificationEvent$.subscribe(
        (event: any) => {
          if (!event) {
            return;
          }
          this.receiveMessage(event);
        }
      )
    );
  }

  private receiveMessage(event: NotificationEvent) {
    switch (event.type) {
      case MESSAGE_NOTIFICA_LIST:
        this.unreadMessagesCount = event.data.numRowsTot;
        this.unreadMessagesList = event.data.entities.slice(0, this.messageQty);
        break;
      case MESSAGE_NOTIFICA:
        this.unreadMessagesCount++;
        this.unreadMessagesList.unshift(event.data);
        this.unreadMessagesList.pop();
        break;
      case MESSAGE_GENERIC:
        if (event.data.allNotificationsRead) {
          this.unreadMessagesCount = 0;
        }
        break;
    }
  }

  navigateToMessages(): void {
    this.router.navigate([AppManagerRoutes.Gestione.url, AppManagerRoutes.GenNotificaList.url], { queryParams: { notificaTipoInvioId: 1 } });
  }

  navigateToMessage(genNotificaId: number): void {
    this.router.navigate([AppManagerRoutes.Gestione.url, AppManagerRoutes.GenNotificaList.url, genNotificaId.toString(), AppManagerRoutes.GenNotificaDetail.url]);
  }

}
