import { AppManagerRoutes } from "../../app-manager-routes";
import { CmdField, CmdFieldKey, CmdFieldMode, CmdFieldTarget, EntityManagerConfig, } from "../entity-config";

export class GiudiciEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      // Giudici
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudList, label: 'menu.giudList.label', title: 'menu.giudList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudExpItaList, label: 'menu.giudExpItaList.label', title: 'menu.giudExpItaList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudExpStrList, label: 'menu.giudExpStrList.label', title: 'menu.giudExpStrList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudPrvItaList, label: 'menu.giudPrvItaList.label', title: 'menu.giudPrvItaList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudPrvStrList, label: 'menu.giudPrvStrList.label', title: 'menu.giudPrvStrList.title'
      },

      // Conferma abilitazioni
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudAbilitazioneExpApprovazioneList, label: 'menu.giudAbilitazioneExpApprovazioneList.label', title: 'menu.giudAbilitazioneExpApprovazioneList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudAbilitazionePrvApprovazioneList, label: 'menu.giudAbilitazionePrvApprovazioneList.label', title: 'menu.giudAbilitazionePrvApprovazioneList.title'
      },

      // Corsi
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.EduCorsoList, label: 'menu.eduCorsoList.label', title: 'menu.eduCorsoList.title'
      },

      // Situazione corsi
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudSituazioneCorsiList, label: 'menu.giudSituazioneCorsiList.label', title: 'menu.giudSituazioneCorsiList.title'
      },

      // Giudici con ampliamenti
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudAmpliamentiExpList, label: 'menu.giudAmpliamentiExpList.label', title: 'menu.giudAmpliamentiExpList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudAmpliamentiPrvList, label: 'menu.giudAmpliamentiPrvList.label', title: 'menu.giudAmpliamentiPrvList.title'
      },

      // Richieste
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudRichiestaList, label: 'menu.giudRichiestaList.label', title: 'menu.giudRichiestaList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudRichiestaExpList, label: 'menu.giudRichiestaExpList.label', title: 'menu.giudRichiestaExpList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudRichiestaPrvList, label: 'menu.giudRichiestaPrvList.label', title: 'menu.giudRichiestaPrvList.title'
      },

      // Assistentati
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudAssistentatoGroupedList, label: 'menu.giudAssistentatoGroupedList.label', title: 'menu.giudAssistentatoGroupedList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudAssistentatoGroupedExpList, label: 'menu.giudAssistentatoGroupedExpList.label', title: 'menu.giudAssistentatoGroupedExpList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudAssistentatoGroupedPrvList, label: 'menu.giudAssistentatoGroupedPrvList.label', title: 'menu.giudAssistentatoGroupedPrvList.title'
      },

      // Assistentati esame
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudAssistentatoEsameList, label: 'menu.giudAssistentatoEsameList.label', title: 'menu.giudAssistentatoEsameList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudAssistentatoEsameExpList, label: 'menu.giudAssistentatoEsameExpList.label', title: 'menu.giudAssistentatoEsameExpList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudAssistentatoEsamePrvList, label: 'menu.giudAssistentatoEsamePrvList.label', title: 'menu.giudAssistentatoEsamePrvList.title'
      },

      // Esami sessioni
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudEsameSessioneList, label: 'menu.giudEsameSessioneList.label', title: 'menu.giudEsameSessioneList.title'
      },
    ];
  }
}
