<ng-container *ngIf="translatedItems$ | async as translateItems">
    <div class="flex flex-column h-full">
        <ng-container *ngIf="edit; else entityview">
            <form [formGroup]="dataForm" novalidate class="h-full">
                <div *ngIf="textHeader">
                    <div class="flex flex-row justify-content-between">
                        <p class="text-header" [innerHtml]="translateItems | translateItem: textHeader: entityInfo">
                        </p>
                        <div *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.detailHeader, fieldType:cmdFieldMode.btn}) as cmdFields"
                            class="buttons-cnt">
                            <ng-container *ngFor="let field of cmdFields">
                                <button pButton [type]="field.type || 'button'" [class]="field.class"
                                    [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field"
                                    (click)="onCmd(field)"
                                    [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{formValid: dataForm.valid, formPristine: dataForm.pristine})"
                                    *ngIf="entityManager | isCmdFieldVisible:field" [icon]="field.icon"
                                    [title]="translateItems | translateItem: field.title"
                                    [label]="translateItems | translateItem: field.label">
                                </button>
                            </ng-container>
                        </div>
                    </div>
                    <p-divider></p-divider>
                </div>
                <div class="py-2 page-detail-container">
                    <div class="mb-3">
                        <app-entity-search [entityManager]="entityManager" [dataSearch]="dataSearch"
                            (searchEvent)="onSearchEvent($event)">
                        </app-entity-search>
                    </div>
                    <div *ngIf="entity">
                        <form [formGroup]="dataForm" novalidate>
                            <entity-form [entityManager]="entityManager" [formFields]="detailFields"
                                [dataForm]="dataForm" [data]="entity"></entity-form>
                        </form>
                    </div>
                </div>
            </form>
        </ng-container>

        <ng-template #entityview>
            <div class="h-full">
                <div *ngIf="textHeader">
                    <div class="flex flex-row justify-content-between">
                        <p class="text-header" [innerHtml]="translateItems | translateItem: textHeader: entityInfo">
                        </p>
                        <div *ngIf="(entityManager | cmdFields:{fieldTarget:  cmdFieldTarget.listHeader, fieldType:cmdFieldMode.btn}) as cmdFields"
                            class="buttons-cnt">
                            <ng-container *ngFor="let field of cmdFields">
                                <button pButton [type]="field.type || 'button'" [class]="field.class"
                                    [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field"
                                    (click)="onCmd(field)"
                                    [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{formValid: dataForm.valid, formPristine: dataForm.pristine})"
                                    *ngIf="entityManager | isCmdFieldVisible:field" [icon]="field.icon"
                                    [title]="translateItems | translateItem: field.title"
                                    [label]="translateItems | translateItem: field.label">
                                </button>
                            </ng-container>
                        </div>
                    </div>
                    <p-divider></p-divider>
                </div>
                <!-- <ng-container
                        *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.listHeader, fieldType:cmdFieldMode.btn}) as cmdFields">
                    </ng-container> -->
                <div class="py-2 page-detail-container overflow-y-auto">
                    <entity-view *ngIf="entity" [entityManager]="entityManager" [data]="entity"
                        (editEvent)="onEditEvent($event)">
                    </entity-view>
                </div>
            </div>
        </ng-template>
    </div>
</ng-container>