<ng-container *ngIf="translatedItems$ | async as translateItems">
    <div class="h-full flex flex-column">
        <div *ngIf="textHeader">
            <div class="flex flex-row justify-content-between">
                <p class="text-header" [innerHtml]="translateItems | translateItem: textHeader: entityInfo"> </p>
                <div *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.containerHeader, fieldType:cmdFieldMode.btn}) as cmdFields"
                    class="buttons-cnt">
                    <ng-container *ngFor="let field of cmdFields">
                        <button pButton [type]="field.type || 'button'" [class]="field.class"
                            [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field"
                            *ngIf="entityManager | isCmdFieldVisible:field"
                            [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{field: field})"
                            (click)="onCmd(field)" [icon]="field.icon"
                            [title]="translateItems | translateItem: field.title"
                            [label]="translateItems | translateItem: field.label"></button>
                    </ng-container>
                </div>
            </div>
            <p-divider></p-divider>
        </div>

        <ng-container *ngIf="entity; else entitieslist">
            <div *ngIf="textHeaderDetail">
                <p class="text-header" [innerHtml]="translateItems | translateItem: textHeaderDetail: entityInfo"></p>
            </div>
            <div *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.detailHeader, fieldType:cmdFieldMode.btn}) as cmdFields"
                class="flex flex-row justify-content-end">
                <div class="buttons-cnt">
                    <ng-container *ngFor="let field of cmdFields">
                        <button pButton [type]="field.type || 'button'" [class]="field.class"
                            [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field" (click)="onCmd(field)"
                            [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{formValid: dataForm.valid, formPristine: dataForm.pristine})"
                            *ngIf="entityManager | isCmdFieldVisible:field" [icon]="field.icon"
                            [title]="translateItems | translateItem: field.title"
                            [label]="translateItems | translateItem: field.label"></button>
                    </ng-container>
                </div>
            </div>
            <div>
                <form [formGroup]="dataForm" (reset)="onReset()" novalidate>
                    <entity-form [entityManager]="entityManager" [formFields]="detailFields" [dataForm]="dataForm"
                        [data]="entity"></entity-form>
                </form>
            </div>
            <ng-container
                *ngIf="(entityManager | cmdFields:{fieldTarget: cmdFieldTarget.detailFooter, fieldType:cmdFieldMode.btn}) as cmdFields">
                <p-divider *ngIf="cmdFields.length > 0"></p-divider>
                <div class="buttons-cnt">
                    <ng-container *ngFor="let field of cmdFields">
                        <button pButton [type]="field.type || 'button'" [class]="field.class"
                            [class.is-loading]="entityRefresh$ | async | isCmdFieldActive:field" (click)="onCmd(field)"
                            [disabled]="(entityRefresh$ | async | refreshing) || (entityManager | isCmdFieldDisabled:field:{formValid: dataForm.valid, formPristine: dataForm.pristine})"
                            *ngIf="entityManager | isCmdFieldVisible:field" [icon]="field.icon"
                            [title]="translateItems | translateItem: field.title"
                            [label]="translateItems | translateItem: field.label"></button>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>

        <ng-template #entitieslist>
            <app-entity-search [entityManager]="entityManager" [dataSearch]="dataSearch"
                (searchEvent)="onSearch($event)" class="mb-3">
            </app-entity-search>
            <div class="flex-grow-1 overflow-y-hidden">
                <div class="h-full overflow-y-auto">
                    <entity-list [entityManager]="entityManager" [dataSearch]="dataSearch"
                        (addEvent)="onAddEntity($event)" (editEvent)="onEditEntity($event)"
                        (deleteEvent)="onDeleteEntities($event)" (selectEvent)="onSelectEntity($event)"
                        (printEvent)="onPrintEntities($event)">
                    </entity-list>
                </div>
            </div>
        </ng-template>
    </div>

    <div class="modal-spinner" *ngIf="entityRefresh$ | async | refreshing">
        <p-progressSpinner></p-progressSpinner>
    </div>
</ng-container>