<ng-template #showMoreButton let-logRow="logRow" let-T="T">
  <button pButton type="button" iconPos="right" class="p-button-sm p-button-text"
    (click)="logRow['expandedPanel'] = !logRow['expandedPanel']"
    [title]="T | translateItem: logRow['expandedPanel'] ? 'entitylog.closedetail' : 'entitylog.showdetail'"
    [label]="T | translateItem: logRow['expandedPanel'] ? 'entitylog.closedetail' : 'entitylog.showdetail'"
    [icon]="logRow['expandedPanel'] ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-right'">
  </button>
</ng-template>

<ng-container *ngIf="T$ | async as T">
  <p-dialog *ngIf="entityLog$ | async as entityLog;" [(visible)]="visible" [modal]="true"
    styleClass="modal-window-size-small" [resizable]="true" [closeOnEscape]="true" (onHide)="hideModal()">

    <ng-template pTemplate="header">
      <span class="p-dialog-title">
        {{T | translateItem: 'entitylog.windowheadertitle'}}
      </span>
    </ng-template>

    <ng-template pTemplate="content">
      <div *ngIf="entityLog.length > 0; else emptylist" class="flex flex-column gap-2 pt-1">
        <ng-container *ngFor="let logRow of entityLog; let i = index; let isFirst = first;  let isLast= last">
          <div *ngIf="isLast && !isExpandedView && entityLog.length > 2">
            <button pButton type="button" class="p-button-sm p-button-text" (click)="isExpandedView = !isExpandedView"
              [title]="T | translateItem: 'entitylog.showall'" [label]="T | translateItem: 'entitylog.showall'"
              icon="fa-solid fa-bars"></button>
          </div>
          <div *ngIf="isFirst || isLast || isExpandedView" class="p-3 badge-outlined-gray">
            <ng-container [ngSwitch]="logRow.type">
              <div class="flex flex-row align-content-center">
                <!-- Insert -->
                <ng-container *ngSwitchCase="'I'">
                  <i class="text-xl mr-3 my-auto fa-solid fa-circle-plus success"></i>
                  <div class="flex-grow-1 flex flex-row justify-content-between">
                    <div>
                      <span class="font-medium">
                        {{T | translateItem: 'entitylog.insertmessage' : {userEmail:logRow.userEmail} }}
                      </span>
                      <label class="block text-sm mt-1">
                        {{logRow.date | date: dateTimeFormatWithSec}}
                      </label>
                    </div>

                    <ng-container *ngTemplateOutlet="showMoreButton; context: {T:T, logRow:logRow}"></ng-container>
                  </div>
                </ng-container>
                <!-- Update -->
                <ng-container *ngSwitchCase="'U'">
                  <i class="text-xl mr-3 my-auto fa-solid fa-pencil primary"></i>
                  <div class="flex-grow-1 flex flex-row justify-content-between">
                    <div>
                      <span class="font-medium">
                        {{T | translateItem: 'entitylog.updatemessage' : {userEmail:logRow.userEmail} }}
                      </span>
                      <label class="block text-sm mt-1">
                        {{logRow.date | date: dateTimeFormatWithSec}}
                      </label>
                    </div>

                    <ng-container *ngTemplateOutlet="showMoreButton; context: {T:T, logRow:logRow}"></ng-container>
                  </div>
                </ng-container>
                <!-- Delete -->
                <ng-container *ngSwitchCase="'D'">
                  <i class="text-xl mr-3 my-auto fa-solid fa-trash alert danger"></i>
                  <div class="flex-grow-1 flex flex-row justify-content-between">
                    <div>
                      <span class="font-medium">
                        {{T | translateItem: 'entitylog.deletemessage' : {userEmail:logRow.userEmail} }}
                      </span>
                      <label class="block text-sm mt-1">
                        {{logRow.date | date: dateTimeFormatWithSec}}
                      </label>
                    </div>

                    <ng-container *ngTemplateOutlet="showMoreButton; context:{logRow: logRow, T: T}"></ng-container>
                  </div>
                </ng-container>
              </div>
              <!-- Detail -->
              <div *ngIf="logRow['expandedPanel']">
                <p-divider></p-divider>
                <div class="grid">
                  <div *ngIf="logRow.type !== 'I'" class="col-12" [ngClass]="{'md:col-6': logRow.type === 'U'}">
                    <div *ngIf="logRow.type === 'U'" class="mb-2 mx-2">{{T | translateItem: 'entitylog.before'}}</div>
                    <pre class="bg-white p-3 overflow-x-auto border-round my-0">
                    <div [innerHTML]="logRow?.['prevRowHtml'].innerHTML"></div>
                    </pre>
                  </div>
                  <div *ngIf="logRow.type !== 'D'" class="col-12" [ngClass]="{'md:col-6': logRow.type === 'U'}">
                    <div *ngIf="logRow.type === 'U'" class="mb-2 mx-2">{{T | translateItem: 'entitylog.after'}}</div>
                    <pre class="bg-white p-3 overflow-x-auto border-round my-0">
                          <div [innerHTML]="logRow?.['rowHtml'].innerHTML"></div>
                    </pre>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <ng-template #emptylist>
        <p class="font-medium text-center p-3 text-600">
          {{T | translateItem: 'generic.listEmpty'}}
        </p>
      </ng-template>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="flex flex-row justify-content-between">
        <div> <button *ngIf="isExpandedView" pButton type="button" class="p-button-sm p-button-text"
            (click)="isExpandedView = !isExpandedView" [title]="T | translateItem: 'entitylog.showless'"
            [label]="T | translateItem: 'entitylog.showless'" icon="fa-solid fa-grip-lines"></button>
        </div>

        <button pButton type="button" class="p-button p-button-outlined" (click)="hideModal()" icon="fa-solid fa-xmark"
          [label]="T | translateItem: 'generic.back'">
        </button>
      </div>
    </ng-template>
  </p-dialog>
</ng-container>