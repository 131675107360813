import { Validators } from "@angular/forms";
import { AppManagerRoutes } from "src/app/app-manager-routes";
import { ComparisonOperator, DataSearch, DataSortValue, SortMode } from "../data-search";
import { Entity } from "../entity";
import { CmdField, CmdFieldKey, CmdFieldMode, CmdFieldStyle, CmdFieldTarget, EntityManagerConfig, FormFieldStyle, FormFieldType, FormFieldTypeHtml, Icons } from "../entity-config";


// GenResource
export class GenResource extends Entity {
  lang: string
  name: string;
  des: string;
  tipologia: string;
}

export class GenResourceEMC extends EntityManagerConfig {

  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = { "windowListHeader": `${this.name}.windowListHeader` };
    this.dataSortValue = [new DataSortValue('name', SortMode.Asc)];

    const ds: DataSearch = new DataSearch();
    ds.dataSortValues = [new DataSortValue('id', SortMode.Asc)];

    this.attributes['genlang'] = this.getEntityService('genlang').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // List
      // //{ key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader, entityManagerInfo: AppManagerRoutes.GenResourceDetail, label: 'generic.add', title: 'generic.add',icon: Icons.plus, class: CmdFieldStyle.btnPrimary },
      // { key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody, entityManagerInfo: AppManagerRoutes.GenResourceDetail, title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary },
      // //{ key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listFooter, label: 'generic.delete', title: 'generic.delete', icon: Icons.delete, class: 'CmdFieldStyle.btnSecondaryDanger' },

      // Detail
      // { key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader, label: 'generic.save', title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary }

      // Editable
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'tipologia', type: FormFieldType.string, label: `${this.name}.tipologia`, sortable: true },
      { key: 'lang', type: FormFieldType.string, label: `${this.name}.lang`, sortable: true },
      { key: 'name', type: FormFieldType.string, label: `${this.name}.name`, sortable: true },
      { key: 'des', type: FormFieldType.string, label: `${this.name}.des`, sortable: true }
    ];

    this.detailFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'dtIns', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'userIns', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'tipologia', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, label: `${this.name}.tipologia`, placeholder: `${this.name}.tipologia`, validators: [Validators.required] },

      {
        key: 'lang', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.lang`, placeholder: `${this.name}.lang`,
        options: { data: this.attributes['genlang'], dataKey: 'lang', dataLabel: 'des' },
        validators: [Validators.required]
      },
      { key: 'name', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, label: `${this.name}.name`, placeholder: `${this.name}.name`, validators: [Validators.required] },
      { key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.textarea, label: `${this.name}.des`, placeholder: `${this.name}.des`, validators: [Validators.required] }
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'lang,name,des,tipologia', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'lang', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.lang`, options: {
          data: this.attributes['genlang'], dataKey: 'lang', dataLabel: 'des', searchFields: 'lang', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'tipologia', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.tipologia`,
        options: { searchFields: 'tipologia', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'name', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.name`,
        options: { searchFields: 'name', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.des`,
        options: { searchFields: 'des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }

  // initContent() {
  //   this.attributes['genlang'] = this.getEntityService('genlang').searchEntitiesAsync(this.fLoad, new DataSearch());
  // }

}

// GenLang
export class GenLang extends Entity {
  lang: string
  des: string;
}

export class GenLangEMC extends EntityManagerConfig {

  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = { windowListHeader: `${this.name}.windowListHeader` };
    this.dataSortValue = [new DataSortValue('lang', SortMode.Asc)];

    const ds: DataSearch = new DataSearch();
    ds.dataSortValues = [new DataSortValue('id', SortMode.Asc)];

    this.attributes['genlang'] = this.getEntityService('genlang').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // List
      // { key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader, entityManagerInfo: AppManagerRoutes.GenLangDetail, label: 'generic.add', title: 'generic.add',icon: Icons.plus, class: CmdFieldStyle.btnPrimary },
      // { key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody, entityManagerInfo: AppManagerRoutes.GenLangDetail, title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary },
      // { key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listFooter, label: 'generic.delete', title: 'generic.delete', icon: Icons.delete, class: 'CmdFieldStyle.btnSecondaryDanger' },

      // Detail
      // { key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader, label: 'generic.save', title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary }

      // Editable
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'lang', type: FormFieldType.string, label: `${this.name}.lang`, sortable: true },
      { key: 'des', type: FormFieldType.string, label: `${this.name}.des`, sortable: true }
    ];

    this.detailFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'lang', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, label: `${this.name}.lang`, placeholder: `${this.name}.lang`, validators: [Validators.required] },
      { key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, label: `${this.name}.des`, placeholder: `${this.name}.des`, validators: [Validators.required] }
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'lang,des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'lang', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.lang`, options: {
          data: this.attributes['genlang'], dataKey: 'lang', dataLabel: 'des', searchFields: 'lang', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.des`,
        options: { searchFields: 'des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }

}

