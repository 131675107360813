import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from "../data-search";
import { Entity } from "../entity";
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, CmdFieldStyle, Icons, CmdField, FormFieldStyle } from "../entity-config";
import { AppManagerRoutes } from "../../app-manager-routes";
import { Validators } from "@angular/forms";
import { optionsSINO } from "src/app/utils/util";
import { SecProfilo } from "../security/sec-profilo";

export class AnaKennelClub extends Entity {
  nazioneId: number;
  email: string;
  telefono: string;
  indirizzo: string;
  localita: string;

  nazioneDes: string;
}

export class AnaKennelClubEMC extends EntityManagerConfig {

  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = {
      windowListHeader: `${this.name}.windowlistheader`, 
      windowInsertHeader: `${this.name}.windowinsertheader`,
      windowEditHeader: `${this.name}.windoweditheader`
    };
    this.dataSortValue = [new DataSortValue('ragioneSociale', SortMode.Asc)];

    const dsNazione: DataSearch = new DataSearch();
    dsNazione.dataSortValues = [new DataSortValue('nazioneDes', SortMode.Asc)];

    this.attributes['geoNazione'] = this.getEntityService('GetGeoNazioneList').searchEntitiesAsync(this.fLoad, dsNazione);
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchReset',
        title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // Editable
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader, entityManagerInfo: AppManagerRoutes.AnaKennelClubNew,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary,
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody, entityManagerInfo: AppManagerRoutes.AnaKennelClubEdit,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger,
      },

      // Detail
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        label: 'generic.back', title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        label: 'generic.save', title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },

      { key: 'ragioneSociale', type: FormFieldType.string, label: `${this.name}.ragioneSociale`, sortable: true, },

      { key: 'email', type: FormFieldType.string, label: `${this.name}.email`, sortable: true },

      { key: 'telefono', type: FormFieldType.string, label: `${this.name}.telefono`, sortable: true },

      { key: 'indirizzo', type: FormFieldType.string, label: `${this.name}.indirizzo`, sortable: true },

      { key: 'localita', type: FormFieldType.string, label: `${this.name}.localita`, sortable: true },

      { key: 'nazioneDes', type: FormFieldType.string, label: `${this.name}.nazioneId`, sortable: true }
    ];

    this.detailFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'dtIns', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      { key: 'userIns', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden },
      {
        key: 'nazioneId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.select,
        label: `${this.name}.nazioneid`, placeholder: `${this.name}.nazioneid`,
        options: { data: this.attributes['geoNazione'], dataKey: 'id', dataLabel: 'nazioneDes' }, class: FormFieldStyle.default,
        validators: [Validators.required]
      },
      {
        key: 'ragioneSociale', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.ragioneSociale`, placeholder: `${this.name}.ragioneSociale`, validators: [Validators.required],
        class: FormFieldStyle.default
      },
      {
        key: 'email', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.email`, placeholder: `${this.name}.email`, validators: [Validators.required],
        class: FormFieldStyle.default
      },
      {
        key: 'telefono', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.telefono`, placeholder: `${this.name}.telefono`, 
        class: FormFieldStyle.default
      },
      {
        key: 'indirizzo', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.indirizzo`, placeholder: `${this.name}.indirizzo`, 
        class: FormFieldStyle.default
      },
      {
        key: 'localita', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.localita`, placeholder: `${this.name}.localita`, 
        class: FormFieldStyle.default
      }
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'ragioneSociale,email', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'ragioneSociale', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.ragioneSociale`,
        options: { searchFields: 'ragioneSociale', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'email', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.email`,
        options: { searchFields: 'email', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'telefono', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.telefono`,
        options: { searchFields: 'telefono', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'indirizzo', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.indirizzo`,
        options: { searchFields: 'indirizzo', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'localita', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.localita`,
        options: { searchFields: 'localita', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'nazioneId', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.nazioneid`,
        options: {
          data: this.attributes['geoNazione'], dataKey: 'id', dataLabel: 'nazioneDes',
          searchFields: 'nazioneId', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      }
    ];
  }
}
