<ng-container *ngIf="T$ | async as T">
  <form [formGroup]="dataForm" novalidate>
    <app-list-search [(advancedSearch)]="advancedSearch" [elaboration]="elaboration"
      [searchButtonLabel]="T | translateItem: 'generic.search'"
      [searchButtonTooltip]="T | translateItem: 'generic.search'"
      [resetButtonLabel]="T | translateItem: 'generic.searchReset'"
      [resetButtonTooltip]="T | translateItem: 'generic.searchReset'"
      [toggleButtonAdvancedLabel]="T | translateItem: 'generic.searchAdvanced'"
      [toggleButtonAdvancedTooltip]="T | translateItem: 'generic.searchAdvanced'"
      [toggleButtonSimpleLabel]="T | translateItem: 'generic.searchBasic'"
      [toggleButtonSimpleTooltip]="T | translateItem: 'generic.searchBasic'" (onSearch)="search()"
      (onReset)="resetSearch()">
      <!-- Advanced search -->
      <ng-container advanced>
        <app-search-field-inputnumber [formGroup]="dataForm" controlName="id"
          [placeholder]="T | translateItem: 'giudassistentato.id'" ngDefaultControl />

        <ng-container *ngIf="this.entityManager.id !== appManagerRoutes.GiudRichiestaDetail.id">
          <app-search-field-inputtext [formGroup]="dataForm" controlName="giudiceRichiedenteNome"
            [placeholder]="T | translateItem: 'giudassistentato.giudiceRichiedenteNome'" ngDefaultControl />

          <app-search-field-inputtext [formGroup]="dataForm" controlName="giudiceRichiedenteCognome"
            [placeholder]="T | translateItem: 'giudassistentato.giudiceRichiedenteCognome'" ngDefaultControl />
        </ng-container>

        <ng-container *ngIf="tipoCalCod === 'EXP'">
          <app-search-field-multiselect [options]="gruppoFciList$ | async" [formGroup]="dataForm"
            controlName="gruppoFciId" optionLabel="label"
            [placeholder]="T | translateItem: 'giudassistentato.gruppoFci'"
            [selectedItemsLabel]="T | translateItem: 'generic.selectedItems'" ngDefaultControl />

          <app-search-field-multiselect [options]="razzeList$ | async" [formGroup]="dataForm" controlName="razzaId"
            optionLabel="razzaDes" [placeholder]="T | translateItem: 'giudassistentato.razza'"
            [selectedItemsLabel]="T | translateItem: 'generic.selectedItems'" ngDefaultControl />
        </ng-container>

        <ng-container *ngIf="tipoCalCod === 'PRV'">
          <app-search-field-multiselect [options]="tipoManiList$ | async" [formGroup]="dataForm"
            controlName="tipoManiId" [placeholder]="T | translateItem: 'giudassistentato.tipoMani'"
            [selectedItemsLabel]="T | translateItem: 'generic.selectedItems'" ngDefaultControl />

          <app-search-field-inputtext [formGroup]="dataForm" controlName="codProtipo"
            [placeholder]="T | translateItem: 'giudassistentato.codProtipo'" ngDefaultControl />
        </ng-container>

        <app-search-field-inputtext *ngIf="this.entityManager.id !== appManagerRoutes.CalCaleManiSettings.id"
          [formGroup]="dataForm" controlName="caleManiLabel"
          [placeholder]="T | translateItem: 'giudassistentato.caleMani'" ngDefaultControl />

        <ng-container *ngIf="this.entityManager.id !== appManagerRoutes.GiudAssistentatoGiudiceList.id">
          <app-search-field-inputtext [formGroup]="dataForm" controlName="giudiceNome"
            [placeholder]="T | translateItem: 'giudassistentato.giudiceNome'" ngDefaultControl />

          <app-search-field-inputtext [formGroup]="dataForm" controlName="giudiceCognome"
            [placeholder]="T | translateItem: 'giudassistentato.giudiceCognome'" ngDefaultControl />
        </ng-container>

        <app-search-field-multiselect [options]="esitoAssistentatoList$ | async" [formGroup]="dataForm"
          controlName="esitoAssistentatoId" [placeholder]="T | translateItem: 'giudassistentato.esitoAssistentato'"
          [selectedItemsLabel]="T | translateItem: 'generic.selecteditems'" ngDefaultControl />

        <app-search-field-multiselect [options]="statoAssistentatoList$ | async" [formGroup]="dataForm"
          controlName="statoAssistentatoId" [placeholder]="T | translateItem: 'giudassistentato.statoAssistentato'"
          [selectedItemsLabel]="T | translateItem: 'generic.selectedItems'" ngDefaultControl />
      </ng-container>

      <!-- Simple search -->
      <ng-container simple>
        <div class="col-12">
          <input pInputText formControlName="simpleSearch"
            [placeholder]="T | translateItem : (this.entityManager.id | i18nSelect : simpleSearchMap)"
            class="p-inputtext-sm" />
        </div>
      </ng-container>
    </app-list-search>
  </form>
</ng-container>