import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { AnaClubHomeComponent } from 'src/app/modules/ana-club/components/home/ana-club-home.component';
import { AppManagerRoutes } from './app-manager-routes';
import { AuthCallbackComponent } from './authorization/auth-callback.component';
import { AnagraficheHomeComponent } from './modules/anagrafiche/components/home/anagrafiche-home.component';
import { CalendarioHomeComponent } from './modules/calendario/components/home/calendario-home.component';
import { UnauthorizedUserComponent } from './modules/components/unauthorized-user/unauthorized-user.component';
import { FattureHomeComponent } from './modules/fatture/components/home/fatture-home.component';
import { FormazioneHomeComponent } from './modules/formazione/components/home/formazione-home.component';
import { BrowserWindowHomeComponent } from './modules/gestione/components/browser-window/browser-window-home.component';
import { GestioneHomeComponent } from './modules/gestione/components/home/gestione-home.component';
import { GiudiceProfiloHomeComponent } from './modules/giudice-profilo/components/home/giudice-profilo-home.component';
import { GiudiciHomeComponent } from './modules/giudici/components/home/giudici-home.component';
import { HomeComponent } from './modules/home/home.component';
import { ManualiHomeComponent } from './modules/manuali/components/home/manuali-home.component';
import { NormalizzazioneHomeComponent } from './modules/normalizzazione/components/home/normalizzazione-home.component';
import { ProfiloUtenteHomeComponent } from './modules/profilo-utente/components/home/profilo-utente-home.component';
import { ZetaHomeComponent } from './modules/zeta/components/home/zeta-home.component';

const routes: Routes = [
  // { path: '', component: HomeComponent, pathMatch: 'full' },
  // { path: AppManagerRoutes.Autologin.url, component: AutoLoginComponent },

  { path: '', redirectTo: AppManagerRoutes.Home.url, pathMatch: 'full' },
  { path: 'callback', component: AuthCallbackComponent },
  { path: AppManagerRoutes.Home.url, component: HomeComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  {
    path: AppManagerRoutes.Calendario.url, component: CalendarioHomeComponent, canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/calendario/calendario.module').then(m => m.CalendarioModule)
  },
  {
    path: AppManagerRoutes.Security.url, // component: SecurityHomeComponent
    canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/security/security.module').then(m => m.SecurityModule),
  },
  {
    path: AppManagerRoutes.Anagrafiche.url, component: AnagraficheHomeComponent, canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/anagrafiche/anagrafiche.module').then(m => m.AnagraficheModule)
  },
  {
    path: AppManagerRoutes.Gestione.url, component: GestioneHomeComponent, canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/gestione/gestione.module').then(m => m.GestioneModule),
  },
  {
    path: AppManagerRoutes.BrowserWindow.url, component: BrowserWindowHomeComponent, canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/gestione/gestione.module').then(m => m.GestioneModule),
  },
  {
    path: AppManagerRoutes.Zeta.url, component: ZetaHomeComponent, canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/zeta/zeta.module').then(m => m.ZetaModule)
  },
  {
    path: AppManagerRoutes.Normalizzazione.url, component: NormalizzazioneHomeComponent, canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/normalizzazione/normalizzazione.module').then(m => m.NormalizzazioneModule)
  },
  {
    path: AppManagerRoutes.Giudici.url, component: GiudiciHomeComponent, canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/giudici/giudici.module').then(m => m.GiudiciModule)
  },
  {
    path: AppManagerRoutes.ProfiloUtente.url, component: ProfiloUtenteHomeComponent, canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/profilo-utente/profilo-utente.module').then(m => m.ProfiloUtenteModule)
  },
  {
    path: AppManagerRoutes.Formazione.url, component: FormazioneHomeComponent, canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/formazione/formazione.module').then(m => m.FormazioneModule)
  },
  {
    path: AppManagerRoutes.GiudiceProfilo.url, component: GiudiceProfiloHomeComponent, canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/giudice-profilo/giudice-profilo.module').then(m => m.GiudiceProfiloModule)
  },
  {
    path: AppManagerRoutes.Manuali.url, component: ManualiHomeComponent, canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/manuali/manuali.module').then(m => m.ManualiModule)
  },
  {
    path: AppManagerRoutes.AnaClub.url, component: AnaClubHomeComponent, canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/ana-club/ana-club.module').then(m => m.AnaClubModule)
  },
  {
    path: AppManagerRoutes.Fatture.url, component: FattureHomeComponent, canActivate: [AutoLoginPartialRoutesGuard],
    loadChildren: () => import('./modules/fatture/fatture.module').then(m => m.FattureModule)
  },
  {
    path: AppManagerRoutes.UnauthorizedUser.url, component: UnauthorizedUserComponent
  }
];

//relativeLinkResolution: 'legacy'
export const AppRoutingModule = RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' });
