import { ComparisonOperator, DataSortValue, SortMode } from "../data-search";
import { Entity } from "../entity";
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, CmdFieldStyle, Icons } from "../entity-config";


export class VuxRazzeVarieta extends Entity {
  idRazzaUmbx: number;
  razzaCod: string;
  varietaCod: string;
  razzaDes: string;
  varietaDes: string;
  razzaVarietaDes: string;
  tipoCod?: number;
  tipoDes: string;
  gruppoCod?: number;
  gruppoDes: string;
  sezioneCod: string;
  sezioneDes: string;
}

export class VuxRazzeVarietaEMC extends EntityManagerConfig {

  init() {
    this.textComponent = {};
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('idRazzaUmbx', SortMode.Asc)];
  }

  initFields() {
    this.cmdFields = [
      // Container

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
        // hEvent: () => {
        //   let field = this.getCmdField(CmdFieldTarget.listSearch, CmdFieldMode.btn, CmdFieldKey.toggle);
        //   this.toggleField(field, false, true, 'generic.searchAdvanced', 'generic.searchBasic')
        // }
      },

      // List
      {
        key: CmdFieldKey.select, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.select', icon: Icons.select, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      // { key: 'id', type: FormFieldType.number, label: `${this.name}.id`, sortable: true },
      { key: 'razzaCod', type: FormFieldType.string, label: `${this.name}.razzaCod`, sortable: true },
      { key: 'razzaDes', type: FormFieldType.string, label: `${this.name}.razzaDes`, sortable: true },
      { key: 'varietaCod', type: FormFieldType.string, label: `${this.name}.varietaCod`, sortable: true }
    ];

    this.detailFields = [
      // {
      //   key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      // },
      // {
      //   key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      // },
      // {
      //   key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden
      // }
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'razzaCod,razzaDes,varietaCod', searchCompOp: ComparisonOperator.Contains }
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'razzaCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.razzaCod`,
        options: { searchFields: 'razzaCod', searchCompOp: ComparisonOperator.Equals },
      },
      {
        key: 'razzaDes', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.razzaDes`,
        options: { searchFields: 'razzaDes', searchCompOp: ComparisonOperator.Equals },
      },
      {
        key: 'varietaCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.varietaCod`,
        options: { searchFields: 'varietaCod', searchCompOp: ComparisonOperator.Equals },
      }
    ];
  }

}
