<ng-container *ngIf="(isAuthenticated$ | async).isAuthenticated">
  <div class="flex flex-column">
    <app-header class="shadow-400" *ngIf="!isHiddenHeader"></app-header>
    <div class="overflow-y-auto h-full">
      <router-outlet></router-outlet>
    </div>

    <ng-container *ngIf="(appInitialized$ | async)?.initialized">
      <message-data></message-data>

      <p-toast></p-toast>

      <!-- Modale visualizzazione log -->
      <app-entity-log></app-entity-log>

      <!-- Modale aggiunta nota -->
      <app-gen-nota-add></app-gen-nota-add>

      <!-- Modale export griglia -->
      <app-list-export></app-list-export>
    </ng-container>
  </div>
</ng-container>