import { FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { AppManagerRoutes } from "../../app-manager-routes";
import { ComparisonOperator, DataSortValue, SortMode } from "../data-search";
import { Entity } from "../entity";
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, CmdFieldStyle, Icons, CmdEvent, FormFieldStyle } from "../entity-config";


export class SecUtenteProfili extends Entity {
  utenteId: number;
  profiloId: number;
  enciIdAnag: number;

  utenteEMail: string;
  profiloCod: string;
  profiloPrevedeClub: boolean;
  ragioneSociale: string;
  tipo: string;

  utenteNome: string;
  utenteCognome: string;
  utenteCodFiscale: string;

  // utenteEMail: string;
  // regioneId: number;
  // regioneCod: string;
  // regioneDes: string;
  // provinciaId: number;
  // provinciaCod: string;
  // provinciaDes: string;
  // profiloCod: string;
  // profiloDes: string;
  // ruoloId: number;
  // ruoloCod: string;
  // tagCod: string;
}

export class SecUtenteProfiliEMC extends EntityManagerConfig {
  // router: Router;
  // activatedRoute: ActivatedRoute;
  subscription: Subscription;

  dispose(): void {
    this.subscription.unsubscribe();
  }

  init() {
    this.subscription = new Subscription;

    this.newInstanceFields = ['utenteId'];
    this.textComponent = {
      windowListHeader: `${this.name}.windowListHeader`, windowDetailHeader: `${this.name}.windowDetailHeader`
    };
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('utenteEMail', SortMode.Asc)];

    // this.router = AppInjector.get(Router);
    // this.activatedRoute = AppInjector.get(ActivatedRoute);
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        label: 'generic.back', title: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // List
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: `${this.name}.SecUtenteProfiliRuoliList`, icon: Icons.userSecurity, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.SecUtenteProfiliRuoliList
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: `${this.name}.SecUtenteProfilAnagList`, icon: Icons.userCheck, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.SecUtenteProfiliAnagList
      }
    ];

    this.listFields = [
      // {
      //   key: 'id', type: FormFieldType.number, label: `${this.name}.id`, sortable: true
      // },

      {
        key: 'enciIdAnag', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'utenteEMail', type: FormFieldType.string, label: `${this.name}.utenteEMail`, sortable: true
      },
      {
        key: 'profiloCod', type: FormFieldType.string, label: `${this.name}.profiloCod`, sortable: true
      },
      {
        key: 'ragioneSociale', type: FormFieldType.string, label: `${this.name}.ragioneSociale`, sortable: true
      },
      {
        key: 'tipo', type: FormFieldType.string, label: `${this.name}.tipo`, sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'userIns', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns', type: FormFieldType.date, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'utenteId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'enciIdAnag', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'profiloId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'utenteEMail', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly
      },
      {
        key: 'objProfilo', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.modalListEditable,
        label: `${this.name}.profiloCod`, placeholder: `${this.name}.profiloCod`,
        options: {
          entityManagerInfo: AppManagerRoutes.SecProfiloModal, title: `${this.name}.profiloSelectionModalHeader`,
          dataLabel: 'cod', fieldsBind: { profiloId: 'id', profiloCod: 'cod', profiloPrevedeClub: 'flPrevedeClub' }, fieldsBindSearch: { id: 'hiddenId' },
          icon: Icons.lookup
        },
        validators: [Validators.required],
        hChange: (event) => { this.onProfiloChange(event) }
      },
      {
        key: 'objRagioneSociale', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.modalListEditable,
        label: `${this.name}.ragioneSociale`, placeholder: `${this.name}.ragioneSociale`,
        options: {
          entityManagerInfo: AppManagerRoutes.VuxSociDeleGruppiModal, title: `${this.name}.VuxSociDeleGruppiModal`,
          dataLabel: 'ragioneSociale', fieldsBind: { ragioneSociale: 'ragioneSociale', tipo: 'tipo', enciIdAnag: 'enciIdAnag' },
          icon: Icons.lookup
        }
        //validators: [Validators.required]
      },
      {
        key: 'tipo', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly,
        label: `${this.name}.tipo`, placeholder: `${this.name}.tipo`
      },
    ];

    this.searchFields = [
      {
        key: 'utenteId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'utenteId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'profiloCod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'utenteId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'utenteId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'profiloCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.profiloCod`,
        options: { searchFields: 'profiloCod', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }

  initFormControl(): void {
    const formGroup = <FormGroup>this.formControl;
    const objProfilo = formGroup.controls['objProfilo']?.value;
    if (objProfilo?.flPrevedeClub) {
      formGroup.controls['objRagioneSociale'].addValidators(Validators.required);
      formGroup.controls['objRagioneSociale'].enable({ onlySelf: true, emitEvent: false });
    }
    else {
      formGroup.controls['objRagioneSociale'].removeValidators(Validators.required);
      formGroup.controls['objRagioneSociale'].disable({ onlySelf: true, emitEvent: false });
    }
  }

  // se il profilo selezionato prevede il socio
  // il campo ragione sociale deve essere abilitato ed obbligatorio
  // altrimenti deve essere disabilitato e non obbligatorio
  onProfiloChange(event) {
    if (event.value) {
      const formGroup = <FormGroup>this.formControl;
      if (event.value.flPrevedeClub) {
        formGroup.controls['objRagioneSociale'].addValidators(Validators.required);
        formGroup.controls['objRagioneSociale'].enable({ onlySelf: true, emitEvent: false });
      }
      else {
        formGroup.controls['objRagioneSociale'].removeValidators(Validators.required);
        formGroup.controls['objRagioneSociale'].disable({ onlySelf: true, emitEvent: false });
        formGroup.controls['enciIdAnag'].setValue(undefined, { emitEvent: false });
        formGroup.controls['tipo'].setValue(undefined, { emitEvent: false });
        formGroup.controls['objRagioneSociale'].setValue(undefined, { emitEvent: false });
      }
    }
  }

  // initFormControl(): void {
  //   const formGroup = <FormGroup>this.formControl;
  //   const objProfilo = formGroup.controls['objProfilo']?.value;
  //   if(objProfilo?.flPrevedeClub){
  //     formGroup.controls['objRagioneSociale'].addValidators(Validators.required);
  //     formGroup.controls['objRagioneSociale'].enable({onlySelf: true, emitEvent: false});
  //   }
  //   else{
  //     formGroup.controls['objRagioneSociale'].removeValidators(Validators.required);
  //     formGroup.controls['objRagioneSociale'].disable({onlySelf: true, emitEvent: false});
  //   }

  //   this.subscription.add(
  //     formGroup.valueChanges.subscribe(value => {
  //       if(value.objProfilo){
  //         if(value.objProfilo.flPrevedeClub){
  //           formGroup.controls['objRagioneSociale'].addValidators(Validators.required);
  //           formGroup.controls['objRagioneSociale'].enable({onlySelf: true, emitEvent: false});
  //         }
  //         else{
  //           formGroup.controls['objRagioneSociale'].removeValidators(Validators.required);
  //           formGroup.controls['objRagioneSociale'].disable({onlySelf: true, emitEvent: false});
  //         }
  //       }
  //     })
  //   );
  // }

}
