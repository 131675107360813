import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { DataSearch } from 'src/app/models/data-search';
import { IEntityList } from 'src/app/models/entity';
import { EduSituazioneFormazioneGiudici } from 'src/app/models/formazione/edu-situazione-formazione-giudici';
import { SecUtente } from 'src/app/models/security/sec-utente';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { DataService } from 'src/app/services/data.service';
import { MessageHubService } from 'src/app/services/message-hub.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-alert-giud-widget',
  templateUrl: './alert-giud-widget.component.html',
  styleUrls: ['./alert-giud-widget.component.scss']
})

export class AlertGiudWidgetComponent implements OnInit {
  // Etichette
  T$: Observable<Resources> = this.translateService.translatedItems$(['generic.', 'alertgiudwidget.']);



  chartOptions: any;


  entities$: Observable<IEntityList<EduSituazioneFormazioneGiudici>>;

  situazioneGiudice: EduSituazioneFormazioneGiudici[] = [];

  annoInizio: number;
  annoFine: number;

  data: any;
  crediti: any[] = [];

  sez1A1: string;
  sez2A1: string;
  sez1A2: string;
  sez2A2: string;
  sez1A3: string;
  sez2A3: string;

  v1: number;
  v2: number;
  v3: number;
  v4: number;
  v5: number;
  v6: number;


  vF1: boolean;
  vF3: boolean;
  vF5: boolean;
  vF2: boolean;
  vF4: boolean;
  vF6: boolean;




  private subscription: Subscription;
  public utente: SecUtente;

  constructor(
    private router: Router,
    private dataService: DataService,
    private translateService: TranslateService,
    private messageHubService: MessageHubService,
    private appManagerService: AppManagerService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    //this.elaboration = true;
    // Utente
    this.subscription.add(
      this.appManagerService.utente$.subscribe(
        (utente) => {
          this.utente = utente;
        }
      )
    );

    const ds = new DataSearch(
      //  [new DataSearchValue([this.utente.enciIdAnag.toString()], ['giudiceId'], ComparisonOperator.Equals)]
    );

    this.entities$ = this.dataService.searchElements<EduSituazioneFormazioneGiudici>(`${this.dataService.configSettings.restCommonUrl}/giudformazionelist`, ds);


    this.subscription.add(
      this.entities$.subscribe(
        (data: IEntityList<EduSituazioneFormazioneGiudici>) => {

          this.situazioneGiudice = data.entities.filter(x => x.giudiceId == this.utente.enciIdAnag);

          this.annoInizio = this.situazioneGiudice[0].annoInizio;
          this.annoFine = this.situazioneGiudice[0].annoFine;
          this.loadData();
        }
      )
    );

  }

  loadData() {
    this.data = [];

    this.data.des = 'Sezione 1 (Expo)';
    this.data.flSezAnno1 = this.situazioneGiudice[0].flSez1Anno1;
    this.data.csezAnno1 = this.situazioneGiudice[0].csez1Anno1;
    this.data.flSezAnno2 = this.situazioneGiudice[0].flSez1Anno2;
    this.data.csezAnno2 = this.situazioneGiudice[0].csez1Anno2;
    this.data.flSezAnno3 = this.situazioneGiudice[0].flSez1Anno3;
    this.data.csezAnno2 = this.situazioneGiudice[0].csez1Anno3;

    this.crediti.push(this.data);

    this.data = [];

    this.data.des = 'Sezione 2 (Prove)';
    this.data.flSezAnno1 = this.situazioneGiudice[0].flSez2Anno1;
    this.data.csezAnno1 = this.situazioneGiudice[0].csez2Anno1;
    this.data.flSezAnno2 = this.situazioneGiudice[0].flSez2Anno2;
    this.data.csezAnno2 = this.situazioneGiudice[0].csez2Anno2;
    this.data.flSezAnno3 = this.situazioneGiudice[0].flSez2Anno3;
    this.data.csezAnno2 = this.situazioneGiudice[0].csez2Anno3;

    this.crediti.push(this.data);



  }


}
