import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, EntityManagerConfig, CmdField } from "../../entity-config";
import { AppManagerRoutes } from "../../../app-manager-routes";

export class AnaCalStatiEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalStatoCalList, label: 'menu.CalStatoCalList.label', title: 'menu.CalStatoCalList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalStatoEventoList, label: 'menu.CalStatoEventoList.label', title: 'menu.CalStatoEventoList.title'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.CalStatoManiList, label: 'menu.CalStatoManiList.label', title: 'menu.CalStatoManiList.title'
      },
    ];
  }

}
