import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { AppManagerService } from '../services/app-manager.service';


@Component({
  selector: 'authCallback',
  templateUrl: './auth-callback.component.html',
})
export class AuthCallbackComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private oidcSecurityService: OidcSecurityService,
    private appManagerService: AppManagerService
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  
  ngOnInit() {

    // this.oidcSecurityService.checkAuth()
    // .pipe(
    //   filter(t => t.isAuthenticated),
    //   takeUntil(this.destroy$)
    // )
    // .subscribe({
    //   next: loginResponse => {       
    //     this.appManagerService.initUser(loginResponse.userData, 'init')
    //     console.log("AppComponent - user authenticated");
    //   }
    // });
  }

}
