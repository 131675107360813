import { AppManagerRoutes } from "../../app-manager-routes";
import { ComparisonOperator, DataSortValue, SortMode } from "../data-search";
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, CmdField, CmdFieldStyle, Icons, FormFieldStyle } from "../entity-config";

export class ZetaEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.ZetaUtenteList, label: 'menu.ZetaUtenteList', title: 'menu.ZetaUtenteList'
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.ZetaWindowTab, label: 'menu.ZetaWindowTab', title: 'menu.ZetaWindowTab'
      }
    ];
  }

}

export class ZetaUtenteEMC extends EntityManagerConfig {

  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = { listFooter: `${this.name}.listFooter`, windowListHeader: `${this.name}.windowListHeader`, windowDetailHeader: `${this.name}.windowDetailHeader` };
    this.dataSortValue = [new DataSortValue('id', SortMode.Asc)];
  }

  initFields() {
    this.cmdFields = [
      // Container
      //{ key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerHeader, label: 'generic.back', class: CmdFieldStyle.btnPrimary },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      //list
      // {
      //   key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader, entityManagerInfo: AppManagerRoutes.SecUtenteDetail,
      //   label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      // },
      // {
      //   key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody, entityManagerInfo: AppManagerRoutes.SecUtenteDetail,
      //   title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      // },
      // {
      //   key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody, entityManagerInfo: AppManagerRoutes.SecUtenteConfigList,
      //   fieldsBind: { id: 'utenteId' }, title: 'Configura', icon: Icons.user, class: CmdFieldStyle.btnSecondary
      // }
      // { key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listFooter, label: 'generic.delete', title: 'generic.delete', icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger },
      // { key: CmdFieldKey.print, mode: CmdFieldMode.btn, target: CmdFieldTarget.listFooter, label: 'generic.print', title: 'generic.print', icon: Icons.print, class: CmdFieldStyle.btnPrimary },
    ];

    this.listFields = [
      { key: 'id', type: FormFieldType.number, label: 'id', sortable: true },
      { key: 'email', type: FormFieldType.string, label: 'email', sortable: true }
    ];

    this.detailFields = [
      { key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden, label: 'id' },
      { key: 'email', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly, label: 'email', placeholder: 'email', }
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: 'codice/descrizione',
        options: { searchFields: 'email,guid', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.number, placeholder: 'id',
        options: { searchFields: 'id', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.default
      },
      {
        key: 'email', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: 'email',
        options: { searchFields: 'email', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'guid', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: 'guid',
        options: { searchFields: 'guid', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      }
    ];
  }

}

export class ZetaWindowTabEMC extends EntityManagerConfig {

  initFields() {
    this.cmdFields = [
      {
        key: CmdFieldKey.mngList, mode: CmdFieldMode.tab, target: CmdFieldTarget.tab,
        label: 'window 1', title: 'window 1', entityManagerInfo: AppManagerRoutes.SecTagList
      },
      {
        key: CmdFieldKey.mngList, mode: CmdFieldMode.tab, target: CmdFieldTarget.tab,
        label: 'window 2', title: 'window 2', entityManagerInfo: AppManagerRoutes.SecProfiloList
      }
    ];
  }

}