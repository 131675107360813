import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  CmdEvent,
  CmdField,
  CmdFieldKey,
  CmdFieldTarget,
  CmdFieldMode,
  EntityManager,
  FormField,
  FormFieldTypeHtml
} from '../../../models/entity-config';
import { Observable } from 'rxjs';
import { IEntity } from '../../../models/entity';
import { EntityRefresh, IEntityService } from '../../../services/entity.service';
import { FormGroup } from '@angular/forms';
import { EntityFormComponent } from '../entity-form/entity-form.component';
import { Resources, TranslateService } from '../../../services/translate.service';


@Component({
  selector: 'app-entity-search',
  templateUrl: './entity-search.component.html',
  styleUrls: ['./entity-search.component.scss']
})
export class EntitySearchComponent implements OnInit, OnDestroy {
  cmdFieldTarget = CmdFieldTarget;
  cmdFieldMode = CmdFieldMode;
  // cmdFieldKey = CmdFieldKey;

  @Input() entityManager: EntityManager;
  @Input() dataSearch: any;
  @Output() searchEvent = new EventEmitter<CmdEvent>();

  private entityService: IEntityService<IEntity>;
  entityRefresh$: Observable<EntityRefresh>;
  dataForm: FormGroup;
  searchFields: FormField[];
  searchAdvancedFields: FormField[];

  // translateItems: Resources;
  translatedItems$: Observable<Resources>;

  @ViewChild(EntityFormComponent) entityFormComponent;


  constructor(private translateService: TranslateService) {
    this.dataForm = new FormGroup({});
  }

  ngOnDestroy(): void { }

  ngOnInit() {
    // translations
    this.translatedItems$ = this.translateService.translatedItems$(this.entityManager.translateSuffixs);
    // .pipe(
    //   tap(items => this.translateItems = items)
    // );

    this.entityService = this.entityManager.getEntityService();
    this.entityRefresh$ = this.entityService.entityRefresh$;
    this.searchFields = this.entityManager.getSearchFields();
    this.searchAdvancedFields = this.entityManager.getSearchAdvancedFields();
  }

  onCmd(field: CmdField): void {
    if (this.entityManager.onCmd({ field: field })) return;

    // if (field.hEvent) {
    //   field.hEvent();
    // }

    switch (field.key) {
      case CmdFieldKey.search:
        this.search();
        break;

      case CmdFieldKey.reset:
        this.reset();
        this.search();
        break;

      case CmdFieldKey.toggle:
        this.reset();
        this.entityManager.advancedSearch = !this.entityManager.advancedSearch;
        break;
    }
  }

  search(): void {
    if (this.searchEvent.observers.length > 0) {
      this.searchEvent.emit({ field: undefined, data: { data: this.entityFormComponent.getData(), advancedSearch: this.entityManager.advancedSearch } });
    }
  }

  private reset() {

    this.entityManager.getSearchFields

    let searchFieldsFlat: FormField[];
    if (this.entityManager.advancedSearch) {
      searchFieldsFlat = this.entityManager.getSearchAdvancedFields().reduce((res, field) => res.concat(field), []);
    }
    else {
      searchFieldsFlat = this.entityManager.getSearchFields().reduce((res, field) => res.concat(field), []);
    }

    const fieldsHidden = searchFieldsFlat.filter(field => field.typeHtml == FormFieldTypeHtml.hidden);
    const data = {};
    fieldsHidden.forEach(field => data[field.key] = this.dataForm.controls[field.key].value);
    this.dataForm.reset();
    fieldsHidden.forEach(field => this.dataForm.controls[field.key].setValue(data[field.key], { emitEvent: false }));

    // searchFieldsFlat.filter(field => field.typeHtml != FormFieldTypeHtml.hidden).forEach(t => {
    //   this.dataForm.controls[t.key].reset();
    //   this.dataForm.controls[t.key].setValue(undefined, {emitEvent: false});
    // });

    // this.dataForm.reset();

  }

  // toggleAdvancedSearch(){
  //   this.dataForm.reset();
  //   this.advancedSearch = !this.advancedSearch;
  // }

  // onSearch(){
  //   if (this.searchEvent.observers.length > 0) {
  //     this.searchEvent.emit({ field: undefined, data: { data: this.dataForm.value, advancedSearch: this.advancedSearch } });
  //   }
  // }

}
