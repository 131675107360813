import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TabView } from 'primeng/tabview';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { MessageDataFromConfig, MessageDataConfig } from 'src/app/models/message';
import { IEntity } from '../../../models/entity';
import { CmdField, CmdFieldKey, CmdFieldTarget, CmdFieldMode, CmdEvent, EntityManager } from '../../../models/entity-config';
import { AppManagerService } from '../../../services/app-manager.service';
import { EntityRefresh, IEntityService } from '../../../services/entity.service';
import { Resources, TranslateService } from '../../../services/translate.service';
import { dataBind } from '../../../utils/util';
import { EntityListEditableComponent } from '../../entity/entity-list-editable/entity-list-editable.component';
import { EntityListComponent } from '../../entity/entity-list/entity-list.component';


@Component({
  selector: 'window-tab',
  templateUrl: './window-tab.component.html',
  styleUrls: ['./window-tab.component.scss']
})

export class WindowTabComponent implements OnInit, OnDestroy {
  cmdFieldTarget = CmdFieldTarget;
  cmdFieldMode = CmdFieldMode;
  cmdFieldKey = CmdFieldKey;

  private entityService: IEntityService<IEntity>;
  entityRefresh$: Observable<EntityRefresh>;
  entityManager: EntityManager;
  dataSearch: any;
  tabList: any = [];
  tabIndex = 0;
  textHeader: string;


  // translateItems: Resources;
  translatedItems$: Observable<Resources>;

  _tabsToShow: string[] = [];
  @Input() filterTabs: boolean = false;
  @Input() entityInfo: any;
  @Input()
  set tabsToShow(tabsToShow: string[]) {
    this._tabsToShow = tabsToShow
    if (this.filterTabs) {
      const data: any = { data: this.dataSearch };
      const field: CmdField = { key: CmdFieldKey.none, mode: CmdFieldMode.none, target: CmdFieldTarget.none, fieldsBind: { caleId: 'id' } };
      this.onSearchEvent({ data: data, field: field });
    }
  }

  @ViewChild('tabview') tabView: TabView;
  @ViewChildren(EntityListComponent) entityListComponents: QueryList<EntityListComponent>;
  @ViewChildren(EntityListEditableComponent) entityListEditableComponents: QueryList<EntityListEditableComponent>;

  private subscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appManagerService: AppManagerService,
    private translateService: TranslateService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {

    this.subscription.add(
      this.appManagerService.initialized$.subscribe(appInitialized => {
        if (!appInitialized.initialized) {
          return EMPTY;
        }

        if (!this.entityManager) {
          this.entityManager = this.appManagerService.getEntityManager(this.activatedRoute);
        }
        this.entityService = this.entityManager.getEntityService();
        this.entityRefresh$ = this.entityService.entityRefresh$;
        if (!this.dataSearch) {
          this.dataSearch = this.entityManager.getEntity();
        }
        // this.entityInfo = this.dataSearch ?? this.entityManager.getEntity();
        this.textHeader = this.entityManager.getTextComponent("windowDetailHeader");

        this.translatedItems$ = this.translateService.translatedItems$(this.entityManager.translateSuffixs);

        if (!this.filterTabs && this.entityManager.getSearchFields().length === 0) {
          const data: any = { data: this.dataSearch };
          const field: CmdField = { key: CmdFieldKey.none, mode: CmdFieldMode.none, target: CmdFieldTarget.none, fieldsBind: { caleId: 'id' } };
          this.onSearchEvent({ data: data, field: field });
        }
      })
    );

    // this.subscription.add(
    //   this.appManagerService.initialized$
    //   .pipe(
    //     switchMap(appInitialized => {
    //       if (!appInitialized.initialized) {
    //         return EMPTY;
    //       }

    //       if (!this.entityManager) {
    //         this.entityManager = this.appManagerService.getEntityManager(this.activatedRoute);
    //       }
    //       this.entityService = this.entityManager.getEntityService();
    //       this.entityRefresh$ = this.entityService.entityRefresh$;
    //       if (!this.dataSearch) {
    //         this.dataSearch = this.entityManager.getEntity();
    //       }
    //       this.entityInfo = this.dataSearch ?? this.entityManager.getEntity();
    //       this.textHeader = this.entityManager.getTextComponent("windowListHeader");

    //       return this.translateService.translatedItems$(this.entityManager.translateSuffixs);
    //     })
    //   )
    //   .subscribe(translateItems => {
    //     this.translateItems = translateItems;

    //     if (this.entityManager.getSearchFields().length === 0) {
    //       const data: any = { data: this.dataSearch };
    //       const field: CmdField = { key: CmdFieldKey.none, mode: CmdFieldMode.none, target: CmdFieldTarget.none, fieldsBind: { caleId: 'id' } };
    //       this.onSearchEvent({ data: data, field: field });
    //     }
    //   })
    // );

  }

  onCmd(field: CmdField) {
    if (this.entityManager.onCmd({ field: field })) return;

    switch (field.key) {
      case CmdFieldKey.back:
        const navItem = this.appManagerService.getBackUrl();
        this.router.navigate([navItem.url], { queryParams: navItem.queryParams });
        break;
    }
  }

  onChangeTab(event) {
    this.tabIndex = event.index;
    const tab = this.tabList[this.tabIndex];
    this.onSearchTab(tab, { field: tab.field, data: { data: this.dataSearch } });
  }

  onSearchTab(tab: any, event: CmdEvent) {
    let data = event.data.data ?? {};
    let entityListComponent;
    switch (tab.field.key) {
      case CmdFieldKey.mngList:
        if (tab.dataSearch) {
          Object.keys(tab.dataSearch).forEach(key => data[key] = tab.dataSearch[key]);
        }
        entityListComponent = this.entityListComponents.find(t => t.entityManager.id == tab.entityManager.id);
        break;

      case CmdFieldKey.mngListEditable:
        if (tab.dataSearch) {
          Object.keys(tab.dataSearch).forEach(key => data[key] = tab.dataSearch[key]);
        }
        entityListComponent = this.entityListEditableComponents.find(t => t.entityManager.id == tab.entityManager.id);
        break;
    }

    if (entityListComponent) {
      entityListComponent.search(data, false);
    }
  }

  onSearchEvent(event: CmdEvent) {
    if (event.data.data) {
      if (this.tabList.length == 0) {
        this.entityManager.getCmdFields(CmdFieldTarget.tab)
          .forEach((field, index) => {
            if (this._tabsToShow.length === 0 || this._tabsToShow.includes(field.entityManagerInfo.id)) {
              const data = dataBind(event.data.data, field.fieldsBind);
              const entityManager = this.appManagerService.instantiateEntityManager(field.entityManagerInfo);
              entityManager.setEntity(data);
              this.tabList.push({
                field: field,
                entityManager: entityManager,
                dataSearch: data,
                headerTextKey: `${this.entityManager.name}.${entityManager.name}Tab`
              });
            }
          });

      }
      else {
        this.tabList.forEach((tab, index) => {
          const data = dataBind(event.data.data, tab.field.fieldsBind);
          tab.dataSearch = data;
        });

        const tab = this.tabList[this.tabIndex];
        this.dataSearch = dataBind(event.data.data, tab.field.fieldsBind);
        this.onSearchTab(tab, { field: tab.field, data: { data: this.dataSearch } });
      }
    }
  }

  onDeleteEntities(tab: any, event: CmdEvent) {
    const entityService = tab.entityManager.getEntityService();
    const messageData = new MessageDataFromConfig(MessageDataConfig.Delete, () => {
      entityService.deleteEntities(event.field, [event.data.id]);
    });

    this.appManagerService.showMessage(messageData);
  }
}
