import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IEntity } from '../../../models/entity';
import { CmdField, CmdFieldKey, CmdFieldTarget, CmdFieldMode, EntityManager, FormField, FormFieldTypeHtml, CmdEvent } from '../../../models/entity-config';
import { AppManagerService } from '../../../services/app-manager.service';
import { Resources, TranslateService } from '../../../services/translate.service';


@Component({
  selector: 'entity-view',
  templateUrl: './entity-view.component.html',
  styleUrls: ['./entity-view.component.scss']
})
export class EntityViewComponent implements OnInit, OnDestroy {
  cmdFieldTarget = CmdFieldTarget;
  cmdFieldMode = CmdFieldMode;
  cmdFieldKey = CmdFieldKey;

  @Input() entityManager: EntityManager;
  @Input() data: IEntity;

  listFields: FormField[];

  // translateItems: Resources;
  translatedItems$: Observable<Resources>;

  private subscription: Subscription;

  @Output() editEvent = new EventEmitter<CmdEvent>();


  constructor(
    private router: Router,
    // private activatedRoute: ActivatedRoute,
    private appManagerService: AppManagerService,
    private translateService: TranslateService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    // translations
    this.translatedItems$ = this.translateService.translatedItems$(this.entityManager.translateSuffixs);
    // .pipe(
    //   tap(items => this.translateItems = items)
    // );

    this.listFields = this.entityManager.getListFields().filter(field => field.typeHtml != FormFieldTypeHtml.hidden);
  }

  onCmd(field: CmdField) {
    if (this.entityManager.onCmd({ field: field })) return;

    switch (field.key) {
      case CmdFieldKey.back:
      case CmdFieldKey.cancel:
        const navItem = this.appManagerService.getBackUrl();
        this.router.navigate([navItem.url], { queryParams: navItem.queryParams });
        break;

      case CmdFieldKey.edit:
        if (this.editEvent.observers.length > 0) {
          this.editEvent.emit({ field: field, data: this.data });
        }
        break;
    }
  }

}
