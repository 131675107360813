import { ComparisonOperator, DataSearch, DataSortValue, SortMode } from '../data-search';
import { Entity } from '../entity';
import { CmdFieldKey, CmdFieldTarget, CmdFieldMode, FormFieldTypeHtml, FormFieldType, EntityManagerConfig, CmdFieldStyle, Icons, CmdField, FormFieldStyle } from '../entity-config';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { Validators } from '@angular/forms';
import { optionsSINO } from 'src/app/utils/util';

export class GenAnomalia extends Entity {
  sezione: string;
  cod: string;
  flBloccante: number;
  flUtilizzabile: boolean;
  flGestione: boolean;
  ordine: number;
  queryAnom?: string;
  queryAdd?: string;

  des: string;
  genAnomaliaLangList: GenAnomaliaLang[];
}

export class GenAnomaliaLang extends Entity {
  anomaliaId: number;
  lang: string;
  des: string;
  langDes: string;
}

export class GenAnomaliaEMC extends EntityManagerConfig {
  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = {
      windowListHeader: `${this.name}.windowListHeader`,
    };
    this.dataSortValue = [new DataSortValue('cod', SortMode.Asc)];

    this.attributes['gensezione'] = AppManagerRoutes.getSezioneList();
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },
      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        type: 'submit', label: 'generic.search', title: 'generic.search',
        icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset',
        icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchBasic', title: 'generic.searchBasic',
        icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // Editable
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add',
        icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit',
        icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel',
        icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.modalListEditable, mode: CmdFieldMode.btn,
        title: 'generic.translations',
        icon: Icons.globe, class: CmdFieldStyle.btnSecondary,
        target: CmdFieldTarget.listBody,
        entityManagerInfo: AppManagerRoutes.GenAnomaliaLangList,
        fieldsBind: { id: 'anomaliaId' }, fieldsBindSearch: { id: 'anomaliaId' }
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: `${this.name}.GenAnomaliaProfiloList`, icon: Icons.userCheck, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenAnomaliaProfiloList
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete',
        icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger
      },
      // {
      //   key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
      //   title: `${this.name}.GenAnomaliaCalDefVincoliList`, icon: Icons.nodes, class: CmdFieldStyle.btnSecondary,
      //   entityManagerInfo: AppManagerRoutes.GenAnomaliaCalDefVincoliList
      // },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save',
        icon: Icons.save, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        sortable: true
      },
      {
        key: 'sezione', type: FormFieldType.string,
        label: `${this.name}.sezione`,
        sortable: true
      },
      {
        key: 'cod', type: FormFieldType.string,
        label: `${this.name}.cod`,
        sortable: true
      },
      {
        key: 'flBloccante',
        type: FormFieldType.boolean,
        label: `${this.name}.flBloccante`,
        sortable: true,
        format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } }
      },
      {
        key: 'flUtilizzabile',
        type: FormFieldType.boolean,
        label: `${this.name}.flUtilizzabile`,
        sortable: true,
        format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } }
      },
      {
        key: 'flGestione',
        type: FormFieldType.boolean,
        label: `${this.name}.flGestione`,
        sortable: true,
        format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } }
      },
      {
        key: 'des', type: FormFieldType.string,
        label: `${this.name}.des`,
        sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'userIns', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'ordine', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'queryAnom', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'queryAdd', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'sezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, label: `${this.name}.sezione`, placeholder: `${this.name}.sezione`,
        options: { data: this.attributes['gensezione'], dataKey: 'cod', dataLabel: 'des' },
        validators: [Validators.required]
      },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.cod`, placeholder: `${this.name}.cod`,
        validators: [Validators.required]
      },
      {
        key: 'flBloccante',
        type: FormFieldType.boolean,
        typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.flBloccante`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' }, class: FormFieldStyle.default,
        validators: [Validators.required]
      },
      {
        key: 'flUtilizzabile',
        type: FormFieldType.boolean,
        typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.flUtilizzabile`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' }, class: FormFieldStyle.default,
        validators: [Validators.required]
      },
      {
        key: 'flGestione',
        type: FormFieldType.boolean,
        typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.flGestione`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' }, class: FormFieldStyle.default,
        validators: [Validators.required]
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.des`, placeholder: `${this.name}.des`,
        validators: [Validators.required]
      }
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: {
          searchFields: 'sezione,cod,des',
          searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'sezione', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.sezione`,
        options: { data: this.attributes['gensezione'], dataKey: 'cod', dataLabel: 'des', searchFields: 'sezione', searchCompOp: ComparisonOperator.Equals }, class: FormFieldStyle.default
      },
      {
        key: 'cod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.cod`,
        options: {
          searchFields: 'cod',
          searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.default
      },
      // {
      //   key: 'livello', type: formfieldtype.number, typehtml: formfieldtypehtml.number,
      //   placeholder: `${this.name}.livello`,
      //   options: {
      //     searchfields: 'livello',
      //     searchcompop: comparisonoperator.equals,
      //   }, class: formfieldstyle.default
      // },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`,
        options: {
          searchFields: 'des',
          searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.default
      }
    ];
  }
}

export class GenAnomaliaLangModalEMC extends EntityManagerConfig {

  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = {};
    this.dataSortValue = [new DataSortValue('lang', SortMode.Asc)];

    const ds: DataSearch = new DataSearch();
    ds.dataSortValues = [new DataSortValue('id', SortMode.Asc)];

    this.attributes['genlang'] = this.getEntityService('genlang').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {
    this.cmdFields = [
      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        type: 'submit', label: 'generic.search', title: 'generic.search',
        icon: Icons.search,
        class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset',
        icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // Editable
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader,
        label: 'generic.add', title: 'generic.add',
        icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit',
        icon: Icons.edit, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.delete, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.delete',
        icon: Icons.delete, class: CmdFieldStyle.btnSecondaryDanger
      },
      {
        key: CmdFieldKey.cancel, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.cancel',
        icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.save',
        icon: Icons.save, class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      {
        key: 'lang', type: FormFieldType.string, label: `${this.name}.lang`,
        sortable: true
      },
      {
        key: 'des', type: FormFieldType.string, label: `${this.name}.des`,
        sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'userIns', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'anomaliaId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'lang', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        label: `${this.name}.lang`, placeholder: `${this.name}.lang`,
        options: {
          data: this.attributes['genlang'], dataKey: 'lang', dataLabel: 'des',
        },
        validators: [Validators.required]
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`, label: `${this.name}.des`,
        validators: [Validators.required]
      }
    ];

    this.searchFields = [
      {
        key: 'anomaliaId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'anomaliaId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: {
          searchFields: 'des',
          searchCompOp: ComparisonOperator.Contains,
        }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'anomaliaId', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden,
        options: { searchFields: 'anomaliaId', searchCompOp: ComparisonOperator.Equals }
      },
      {
        key: 'lang', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.lang`, class: FormFieldStyle.default,
        options: {
          data: this.attributes['genlang'], dataKey: 'lang', dataLabel: 'des', searchFields: 'lang', searchCompOp: ComparisonOperator.Equals
        }
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`, class: FormFieldStyle.default,
        options: {
          searchFields: 'des', searchCompOp: ComparisonOperator.Contains
        }
      }
    ];
  }
}
