import { EntityManagerConfig, CmdField } from "../entity-config";

export class GestioneEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [];
  }

}
