import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { FormModeType } from 'src/app/models/entity-config';
import { GiudBloccoExp } from 'src/app/models/giudici/giud-blocco-exp';
import { ToastMessageData } from 'src/app/models/message';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { BaseDataService } from 'src/app/services/base-data.service';
import { DataService } from 'src/app/services/data.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { dateGreatherThan } from 'src/app/utils/formValidators';
import { dateFormat, dateFormatPrNg } from 'src/app/utils/util';

import { VeeRazze } from 'src/app/models/vee/vee-razze';

@Component({
  selector: 'app-giud-blocco-exp-edit',
  templateUrl: './giud-blocco-exp-edit.component.html',
  styleUrls: ['./giud-blocco-exp-edit.component.scss']
})
export class GiudBloccoExpEditComponent implements OnInit, OnDestroy {
  private resourceList: Resources;
  private subscription: Subscription;

  dateFormat: string = dateFormat;
  dateFormatPrNg: string = dateFormatPrNg;
  T$: Observable<Resources>;
  dataForm: FormGroup;
  formModeType: typeof FormModeType = FormModeType;
  formMode: number = FormModeType.insert;
  elaboration: boolean = false;
  razzeList$: Observable<VeeRazze[]>;

  @Input() visible: boolean = false;
  @Input() entity: GiudBloccoExp;
  @Input() giudiceId: number;

  @Output() onHide = new EventEmitter<boolean>();

  constructor(
    private translateService: TranslateService,
    private baseDataService: BaseDataService,
    private fb: FormBuilder,
    private dataService: DataService,
    private appManagerService: AppManagerService,
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    // Etichette
    this.T$ = this.translateService.translatedItems$(AppManagerRoutes.GiudBloccoExp.translateSuffixs ?? ['generic.']).pipe(
      tap(data => this.resourceList = data)
    );

    // Mode
    this.formMode = (this.entity.id ?? 0) === 0 ? FormModeType.insert : FormModeType.edit;

    // Form
    this.dataForm = this.buildForm(this.entity, this.giudiceId);

    // Carico le info per le dropdown
    this.razzeList$ = this.baseDataService.getVeeRazzeList();
  }

  hideModal(reloadData: boolean = false): void {
    this.visible = false;
    this.onHide.emit(reloadData);
  }

  save(): void {
    const data = this.dataForm.value as GiudBloccoExp;

    if (this.formMode === FormModeType.insert) {
      this.dataService.insertElement<GiudBloccoExp>(`${this.dataService.configSettings.restCommonUrl}/giudbloccoexp`, data).subscribe(
        () => {
          this.elaboration = false;

          this.appManagerService.showToastMessage(
            new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.savesuccessmsg')));

          this.dataForm.markAsPristine();
          this.hideModal(true);
        },
        () => {
          this.elaboration = false;
        }
      );
    }
    else if (this.formMode === FormModeType.edit) {
      this.dataService.updateElement<GiudBloccoExp>(`${this.dataService.configSettings.restCommonUrl}/giudbloccoexp`, data, this.entity.id.toString()).subscribe(
        () => {
          this.elaboration = false;

          this.appManagerService.showToastMessage(
            new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.savesuccessmsg')));

          this.dataForm.markAsPristine();
          this.hideModal(true);
        },
        () => {
          this.elaboration = false;
        }
      );
    }
  }

  private buildForm(blocco: GiudBloccoExp, giudiceId: number): FormGroup {
    const form = this.fb.group({
      id: this.fb.control<number | null>(0, [Validators.required]),
      giudiceId: this.fb.control<number | null>(giudiceId, [Validators.required]),
      razzaId: this.fb.control<number | null>(null, [Validators.required]),
      dataInizio: this.fb.control<Date | null>(null, [Validators.required]),
      dataFine: this.fb.control<Date | null>(null, [Validators.required]),
      note: this.fb.control<string | null>(null),
      temaIdPratica: this.fb.control<number | null>(null),
      userIns: this.fb.control<string | null>(null),
      dtIns: this.fb.control<Date | null>(null),
      userUpd: this.fb.control<string | null>(null),
      dtUpd: this.fb.control<Date | null>(null)
    }, {
      validators: [dateGreatherThan('dataInizio', 'dataFine')]
    });

    if (blocco) {
      form.patchValue(blocco);
    }

    return form;
  }
}
