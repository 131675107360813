import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { WindowSaveData } from 'src/app/guards/data-saved-guard';
import { EntityManager } from 'src/app/models/entity-config';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { GenNotificheService } from 'src/app/services/gennotifiche.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { GenNotificaWizardComponent } from '../gennotifica-wizard/gennotifica-wizard.component';


@Component({
  selector: 'app-gennotifica-new',
  templateUrl: './gennotifica-new.component.html',
  styleUrls: ['./gennotifica-new.component.scss']
})

export class GenNotificaNewComponent implements AfterViewInit, WindowSaveData, OnDestroy {
  T$: Observable<Resources>;
  resourceList: Resources = [];
  entityManager: EntityManager;

  wizardActiveIndex: number = 1;
  wizardCanGoNext: boolean = false;
  wizardNotificationSended: boolean = false;
  wizardLoading: boolean = false;

  @ViewChild(GenNotificaWizardComponent) wizard: GenNotificaWizardComponent;

  private subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appManagerService: AppManagerService,
    private translateService: TranslateService,
    private genNotificheService: GenNotificheService,
  ) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.entityManager = this.appManagerService.getEntityManager(this.activatedRoute);

    // Etichette
    this.T$ = this.translateService.translatedItems$(AppManagerRoutes.GenNotificaNew.translateSuffixs)
      .pipe(
        tap((translateItems) => {
          this.resourceList = translateItems;
        })
      );

    this.entityManager.isCompleted = () => {
      return true; //this.dataForm.pristine;
      // TO-DO:gestire i casi in cui si deve vedere il messaggio di perdita dei dati 
    }
  }

  wizardBack(event): void {
    if (this.wizard?.activeIndex > 0) {
      this.wizard.indietro();
    }
    else {
      const navItem = this.appManagerService.getBackUrl();
      this.router.navigate([navItem.url], { queryParams: navItem.queryParams });
    }
  }

  wizardNext(event): void {
    this.wizard?.procedi();
  }

  wizardSend(event) {
    this.wizard?.invia();
  }

  onWizardStateChange(event: any) {
    this.wizardActiveIndex = event.activeIndex;
    this.wizardCanGoNext = event.canGoNext;
    this.wizardNotificationSended = event.notificationSended;
    this.wizardLoading = event.loading;

    if (this.wizardNotificationSended) {
      const navItem = this.appManagerService.getBackUrl();
      this.router.navigate([navItem.url], { queryParams: navItem.queryParams });
    }

  }

  help() {
    this.appManagerService.openBrowserTab(AppManagerRoutes.GenHelpLang, this.entityManager.getSection());
  }

}
