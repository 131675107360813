import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { dateFormat } from 'src/app/utils/util';
import { CalCaleAnomalieWidgetDto } from 'src/app/models/calendario/dto/cal-cale-anomalie-widget-dto';


@Component({
  selector: 'app-calendario-anomalie-widget',
  templateUrl: './calendario-anomalie-widget.component.html',
  styleUrls: ['./calendario-anomalie-widget.component.scss']
})

export class CalendarioAnomalieWidgetComponent implements OnInit {
  dateFormat = dateFormat;
  data$: Observable<CalCaleAnomalieWidgetDto[]>;
  T$: Observable<Resources>;
  yearsQty: number = 3;
  currYear: number;
  years: number[] = [];
  elaboration: boolean = false;
  chartOptions: any;
  datasets: any;
  statesColors: string[] = [
    '#f25d5a',
    '#006ad1',
    '#00bfb2'
  ];

  // 10% darken colour
  statesHoverColors: string[] = [
    '#ee2f2b',
    '#00509e',
    '#008c82'
  ];

  constructor(
    private dataService: DataService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    // Etichette
    this.T$ = this.translateService.translatedItems$(['generic.', 'calendarioanomaliewidget.']);

    // Recuperol'anno corrente e creo l'arrayy degli anni
    this.currYear = new Date().getFullYear();

    for (let i = 0; i < this.yearsQty; i++) {
      this.years.push(this.currYear + i);
    }

    this.chartOptions = {
      plugins: {
        legend: {
          display: true,
          position: 'right',
        },
        tooltips: {
          mode: 'index',
          intersect: false
        }
      }
    };

    this.elaboration = true;

    this.data$ = this.dataService.getElements<CalCaleAnomalieWidgetDto>(`${this.dataService.configSettings.restCalendarioUrl}/GetCalCaleAnomalieWidget`, `startYear=${this.currYear.toString()}&yearsQty=${this.yearsQty.toString()}`).pipe(
      tap(
        (data: any) => {
          this.datasets = this.generateDataSet(data, 'anomalieStatoList', 'id', 'anomaliaStatoDes', 'totAnomalie', this.statesColors, this.statesHoverColors);
        }
      )
    );
  }

  private generateDataSet(data: any, dataNodeKey: string, idKey: string, labelKey: string, valueKey: string, colors: string[], hoverColors: string[]) {
    const datasets = [];

    data.forEach(d => {
      const dtset = {
        id: d[idKey],
        labels: d[dataNodeKey].map(a => a[labelKey]),
        datasets: [{
          data: d[dataNodeKey].map(a => a[valueKey]),
          backgroundColor: colors,
          hoverBackgroundColor: hoverColors
        }]
      };

      datasets.push(dtset);
    });

    return datasets;
  }

}
